import { Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoadingButton, TextInput } from 'components/common';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { TEST_ID } from 'config/test-ids';
import { URLS } from 'pages/urls';
import { isValidEmail, validate } from 'utils/validation';

export interface FormData {
  email: string;
}

interface Props {
  onSubmit: (data: FormData) => void;
  isLoading: boolean;
}

export const ResetRequestForm: React.FC<Props> = ({ onSubmit, isLoading }) => {
  const { register, errors, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
  });

  return (
    <form name="request-password-reset" onSubmit={handleSubmit(onSubmit)}>
      <Stack width="400px" pb="40px">
        <TextInput
          name="email"
          type="email"
          label="Email"
          autoComplete="email"
          placeholder="Type your email here"
          ref={register({
            required: 'Required',
            validate: {
              email: validate(isValidEmail, 'Incorrect email format'),
            },
          })}
          errorMessage={errors.email?.message}
          disabled={isLoading}
          mb="0"
        />
      </Stack>
      <Columns display="flex" justifyContent="center">
        <Button variant="outlined" color="secondary" component={Link} to={URLS.root}>
          Go back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          isLoading={isLoading}
          data-testid={TEST_ID.resetPasswordButton}
        >
          Reset password
        </LoadingButton>
      </Columns>
    </form>
  );
};
