import { useApolloClient } from '@apollo/react-hooks';
import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoadingButton, TextInput } from 'components/common';
import { PasswordInput } from 'components/common/TextInput';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { TEST_ID } from 'config/test-ids';
import { IS_USER_LOGGED_IN } from 'context/queries';
import { useLogin } from 'hooks/mutation';
import { useIsMounted } from 'hooks/useIsMounted';
import { URLS } from 'pages/urls';
import { LocalStorage } from 'utils/storage';
import { isValidEmail, validate } from 'utils/validation';

import * as Styled from './LoginPage.styles';

interface FormData {
  email: string;
  password: string;
}

interface Props {
  isExclusive: boolean;
  setIsExclusive: (isExclusive: boolean) => void;
}

export const LoginForm: React.FC<Props> = ({ isExclusive, setIsExclusive }) => {
  const isMounted = useIsMounted();

  const { register, errors, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
  });

  const client = useApolloClient();
  const [login, { loading: isLoginLoading }, [errorMessage], resetErrorMessage] = useLogin({
    onCompleted: (data) => {
      const token = data?.tokenAuth?.token;
      if (token) {
        LocalStorage.set('token', token);
        client.writeQuery({
          query: IS_USER_LOGGED_IN,
          data: { isLoggedIn: true },
        });
      }
    },
  });

  React.useEffect(() => {
    if (errorMessage && isMounted.current) {
      setIsExclusive(true);
    }
  }, [errorMessage, isMounted, setIsExclusive]);

  const resetForm = () => {
    setIsExclusive(false);
    resetErrorMessage();
  };

  const handleFormSubmit = ({ email, password }: FormData) => {
    login({ variables: { email, password } });
  };

  return (
    <form name="login" onSubmit={handleSubmit(handleFormSubmit)}>
      {errorMessage ? (
        <Styled.LoginErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
      ) : null}
      <Box px={isExclusive ? '180px' : 0}>
        <Stack width="400px" mb="40px">
          <TextInput
            name="email"
            type="email"
            label="Email"
            autoComplete="email"
            placeholder="Type your email here"
            ref={register({
              required: 'Required',
              validate: {
                email: validate(isValidEmail, 'Incorrect email format'),
              },
            })}
            errorMessage={errors.email?.message}
            mb="0"
          />
          <PasswordInput
            name="password"
            label="Password"
            autoComplete="current-password"
            placeholder="Type your password here"
            ref={register({
              required: 'Required',
            })}
            errorMessage={errors.password?.message}
            mb="0"
          />
        </Stack>
      </Box>
      <Columns display="flex" justifyContent="center">
        {isExclusive && (
          <Button variant="outlined" color="secondary" onClick={resetForm}>
            Go back
          </Button>
        )}
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          isLoading={isLoginLoading}
          data-testid={TEST_ID.logInButton}
        >
          Log in
        </LoadingButton>
      </Columns>
      <Stack mt="32px" rowGap="4px" display="flex" flexDirection="column" alignItems="center">
        <Box display="inline-flex" alignItems="center">
          <Box mr="1ch" fontSize="14px" fontWeight={600}>
            Forgot password?
          </Box>
          <Button
            component={Link}
            to={URLS.resetPassword.root}
            variant="text"
            color="primary"
            data-testid={TEST_ID.resetPasswordButton}
          >
            Reset
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
