import { Box, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { Link } from 'components/common';
import { FormLabel, InfoTooltip } from 'components/common';
import { useEndCardsQuery } from 'hooks/query';
import { generateEndCardEditLink, URLS } from 'pages/urls';
import { noop } from 'utils/common';
import { prioritizeEndCards } from 'utils/endCards';

interface EndCardPickerProps {
  disabled?: boolean;
  selected?: string;
  control: Control;
  handleChange?: (id: string | undefined) => void;
  backButtonPlaceholder?: string;
  tooltipContent?: string;
  videoId?: string;
  createButtonLabel?: string;
  hideCreateButton?: boolean;
  mt?: string;
}

export const EndCardPicker: React.FC<EndCardPickerProps> = ({
  disabled,
  control,
  selected,
  handleChange = noop,
  backButtonPlaceholder,
  videoId,
  tooltipContent,
  createButtonLabel = 'Create New',
  hideCreateButton = false,
  mt = '32px',
}) => {
  const { pathname } = useLocation();
  const [selectedEndCard, setSelectedEndCard] = useState(selected);
  const { data, loading } = useEndCardsQuery({
    variables: {
      pageSize: 100,
    },
  });
  const allCards = data?.endCards?.nodes;
  const normalizedEndCards = React.useMemo(() => {
    return prioritizeEndCards(allCards, videoId);
  }, [allCards, videoId]);
  const defaultValueCard = normalizedEndCards?.find(({ id }) => id === selected);
  const currentURL = {
    destinationURL: pathname,
    backButtonPlaceholder: backButtonPlaceholder,
  };

  return (
    <Box mt={mt}>
      <FormLabel>
        End Card
        {tooltipContent ? <InfoTooltip content={tooltipContent} /> : null}
      </FormLabel>
      <Box display="flex" alignItems="center">
        <Box mb="15px" width="100%" mr="25px">
          {normalizedEndCards?.length ? (
            <Controller
              render={({ onChange, value, ...props }) => (
                <Autocomplete
                  disabled={disabled}
                  style={{ width: '100%', height: '52px' }}
                  options={normalizedEndCards}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => {
                    return <TextField {...params} variant="outlined" />;
                  }}
                  onChange={(e, data, reason) => {
                    if (reason === 'clear') {
                      onChange(null);
                      setSelectedEndCard(undefined);
                      handleChange(undefined);
                    } else if (data) {
                      onChange(data.id);
                      setSelectedEndCard(data.id);
                      handleChange(data.id);
                    }
                  }}
                  value={normalizedEndCards.find((endCard) => endCard.id === value) || null}
                  {...props}
                />
              )}
              defaultValue={defaultValueCard?.id || null}
              name="endCardId"
              control={control}
            />
          ) : (
            <Autocomplete
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" />;
              }}
              options={[]}
              loading={loading}
            />
          )}
        </Box>

        {selectedEndCard && !disabled ? (
          <Link
            to={{
              pathname: generateEndCardEditLink(selectedEndCard),
              state: currentURL,
            }}
            style={{ marginTop: '-15px' }}
          >
            <EditIcon />
          </Link>
        ) : null}
      </Box>
      {!disabled && !hideCreateButton ? (
        <Button
          component={Link}
          to={{
            pathname: URLS.endCard.new,
            state: currentURL,
          }}
          variant="text"
          color="primary"
        >
          {createButtonLabel}
        </Button>
      ) : null}
    </Box>
  );
};
