import React from 'react';

import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { Link } from 'components/common';

import * as Styled from './BackButton.styles';

interface BackButtonProps {
  label?: string;
  link: string;
}

const BackButton = ({ label, link }: BackButtonProps) => (
  <Link to={link}>
    <Styled.BackButton>
      <Arrow />
      <Styled.Text>{label}</Styled.Text>
    </Styled.BackButton>
  </Link>
);

export default BackButton;
