import {
  VideoMonetizationAdDensity,
  VideoMonetizationAdLocation,
  VideoMonetizationAdType,
} from 'models';

export const ADS_TYPES = [
  {
    value: VideoMonetizationAdType.Skippable,
    label: 'Skippable video ads',
  },
  {
    value: VideoMonetizationAdType.NonSkippable,
    label: 'Non-skippable video ads',
  },
  {
    value: VideoMonetizationAdType.Both,
    label: 'Skippable and non-skippable video ads',
  },
];

export const ADS_DENSITY = [
  {
    label: 'Automatic',
    value: VideoMonetizationAdDensity.AUTO,
  },
  {
    label: 'Every 1 minute',
    value: VideoMonetizationAdDensity.EVERY_1_MIN,
  },
  {
    label: 'Every 2 minutes',
    value: VideoMonetizationAdDensity.EVERY_2_MIN,
  },
  {
    label: 'Every 3 minutes',
    value: VideoMonetizationAdDensity.EVERY_3_MIN,
  },
  {
    label: 'Every 4 minutes',
    value: VideoMonetizationAdDensity.EVERY_4_MIN,
  },
  {
    label: 'Every 5 minutes',
    value: VideoMonetizationAdDensity.EVERY_5_MIN,
  },
  {
    label: 'Every 6 minutes',
    value: VideoMonetizationAdDensity.EVERY_6_MIN,
  },
  {
    label: 'Every 7 minutes',
    value: VideoMonetizationAdDensity.EVERY_7_MIN,
  },
  {
    label: 'Every 8 minutes',
    value: VideoMonetizationAdDensity.EVERY_8_MIN,
  },
  {
    label: 'Every 9 minutes',
    value: VideoMonetizationAdDensity.EVERY_9_MIN,
  },
  {
    label: 'Every 10 minutes',
    value: VideoMonetizationAdDensity.EVERY_10_MIN,
  },
];

export const DISABLED_MONETIZATION_FOR_VIDEO_TOOLTIP =
  'Enable monetization for this video to run ads.';

export const ENABLED_MONETIZATION_FOR_VIDEO_TOOLTIP = 'Monetization for this video is enabled.';

export const AD_LOCATION_TOGGLES = [
  {
    label: 'Before video (pre-roll)',
    name: 'preroll',
    value: VideoMonetizationAdLocation.Preroll,
    require: true,
  },

  {
    label: 'After video (post-roll)',
    name: 'postroll',
    value: VideoMonetizationAdLocation.Postroll,
  },

  {
    label: 'During video (mid-roll)',
    name: 'midroll',
    value: VideoMonetizationAdLocation.midroll,
  },
];
