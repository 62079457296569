import debounce from 'lodash.debounce';
import React from 'react';

import { ReactComponent as Loupe } from 'assets/icons/loupe.svg';

import * as Styled from './Search.styles';

export interface OwnProps {
  placeholder: string;
  onSearch: (inputText: string) => void;
  iconPlacement?: 'left' | 'right';
  searchOnInput?: boolean;
  debounceSearch?: boolean;
  fullWidth?: boolean;
}

const Search = ({
  placeholder,
  onSearch,
  iconPlacement = 'right',
  searchOnInput = false,
  debounceSearch = false,
  fullWidth,
}: OwnProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSearch = React.useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    onSearch(inputRef.current.value);
  }, [onSearch]);

  const debouncedHandleSearch = React.useMemo(() => {
    return debounce(handleSearch, 300);
  }, [handleSearch]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSearch();
      }
    },
    [handleSearch],
  );

  const icon = (
    <Styled.IconButton type="button" onClick={handleSearch}>
      <Loupe />
    </Styled.IconButton>
  );

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {iconPlacement === 'left' ? icon : null}
      <Styled.Input
        placeholder={placeholder}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        onChange={
          searchOnInput ? (debounceSearch ? debouncedHandleSearch : handleSearch) : undefined
        }
      />
      {iconPlacement === 'right' ? icon : null}
    </Styled.Wrapper>
  );
};

export const useSearch = (initialText?: string) => {
  const [text, setText] = React.useState(initialText);
  const trimmedText = text?.trim();
  const normalizedText = trimmedText === '' ? undefined : trimmedText;
  return [normalizedText, setText] as const;
};

export default React.memo(Search);
