import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Typography } from 'components/common';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { Permission } from 'config/permissions';
import { useTangleMainBar } from 'context/mainBar';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import MissingMediaPage from 'pages/404';
import { URLS } from 'pages/urls';

import { BrandingTab } from './BrandingTab';
import { MembersTab } from './MembersTab';
import { MonetizationTab } from './MonetizationTab';
import { OrganizationTab } from './OrganizationTab';
import { OrganizationTabs } from './OrganizationTabs';
import { PaymentPlanTab } from './PaymentPlanTab';
import { ShareSocialsTab } from './ShareSocialsTab';
import { SocialMediaTab } from './SocialMediaTab';

export const OrganizationPage: React.VFC = () => {
  useTangleMainBar(false);

  const { hasPermissions, isLoading: arePermissionsLoading } = useUserPermissions();
  const canViewOrganization = hasPermissions([Permission.ManageOrganization]);
  const canViewUsers = hasPermissions([Permission.ViewOrganizationMembers]);
  const canCreateSubscription = hasPermissions([Permission.CreatePaymentSession]);
  const [tabsActionContentWidth, setTabsActionContentWidth] = useState(0);

  const getTabsActionContentWidth = (width: number) => {
    setTabsActionContentWidth(width);
  };

  if (!arePermissionsLoading && !canViewOrganization) {
    return <MissingMediaPage />;
  }

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">Manage my Organization</Typography>
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <OrganizationTabs tabsActionContentWidth={tabsActionContentWidth} />
          <Switch>
            <Route path={[URLS.organization.root]} exact>
              <OrganizationTab />
            </Route>
            {canCreateSubscription && (
              <Route path={[URLS.organization.paymentPlan]} exact>
                <PaymentPlanTab
                  successUrl={window.location.href}
                  cancelUrl={window.location.href}
                />
              </Route>
            )}
            {canViewUsers && (
              <Route path={[URLS.organization.members]} exact>
                <MembersTab getActionContentWidth={getTabsActionContentWidth} />
              </Route>
            )}
            <Route path={[URLS.organization.socialMedia]} exact>
              <SocialMediaTab getActionContentWidth={getTabsActionContentWidth} />
            </Route>
            <Route path={[URLS.organization.monetization]} exact>
              <MonetizationTab getActionContentWidth={getTabsActionContentWidth} />
            </Route>
            <Route path={[URLS.organization.branding]} exact>
              <BrandingTab getActionContentWidth={getTabsActionContentWidth} />
            </Route>
            <Route path={[URLS.organization.shareSocials]} exact>
              <ShareSocialsTab />
            </Route>
          </Switch>
        </BigCard>
      </Box>
    </MainLayout>
  );
};
