import {
  Facebook,
  Instagram,
  TikTok,
  Twitter,
  YouTube,
} from '@entertainment-ai/react-component-library';
import React, { ReactNode } from 'react';

import { SetSorting, Sorting } from 'context/sorting';
import { SocialMediaI, SocialMediaPlatforms } from 'models';

export type AddSocialMediaForm = Pick<SocialMediaI, 'platformName' | 'label' | 'accountUrl'>;

export interface AddSocialMediaProps {
  onClose: () => void;
  isOpen: boolean;
  social?: SocialMediaI;
}

export interface SocialMediaTableProps {
  socialsLoading: boolean;
  socials?: SocialMediaI[];
  sorting: Sorting<any>;
  setSorting: SetSorting<any>;
  onSearch: (searchText: string) => void;
}

export const socialMediaPlatformMap: Record<SocialMediaPlatforms, ReactNode> = {
  [SocialMediaPlatforms.FACEBOOK]: <Facebook />,
  [SocialMediaPlatforms.TWITTER]: <Twitter />,
  [SocialMediaPlatforms.YOUTUBE]: <YouTube />,
  [SocialMediaPlatforms.TIKTOK]: <TikTok />,
  [SocialMediaPlatforms.INSTAGRAM]: <Instagram />,
};

export interface SocialMediaTableRowProps {
  social: SocialMediaI;
}

export interface EditSocialMediaProps {
  social: SocialMediaI;
}
