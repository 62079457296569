import { Box } from '@material-ui/core';
import React, { useReducer } from 'react';

import { LoadingButton } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import PaginationFooter from 'components/PaginationFooter';
import { Table } from 'components/Table';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useD2CServiceUsageQuery } from 'hooks/query';
import { D2CTranscriptionOrderBy, DialogToClipPlanLimit } from 'models';
import { formatDate } from 'utils/date';
import { msToHMSString, msToSeconds } from 'utils/time';

import { TranscriptionStatusBadge } from '../TranscriptionStatusBadge';
import { formatDuration } from './formatDuration';
import { UsageLimits } from './UsageLimits';

import * as Styled from 'components/common/Table/Table.styles';
const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

const initialPaginationSettings = {
  page: 1,
  pageSize: ROWS_PER_PAGE_OPTIONS[1],
  changingCount: 0,
};

export const ServiceUsageTab: React.VFC = () => {
  const { orderBy, sorting, setSorting } = useSorting<D2CTranscriptionOrderBy>('-created');
  const [pagination, dispatch] = useReducer(paginationReducer, initialPaginationSettings);
  const { loading, data, refetch } = useD2CServiceUsageQuery({
    variables: {
      ...pagination,
      orderBy,
    },
  });

  const transcriptions = data?.d2cTranscriptions?.nodes || [];
  const limitsUsage = data?.me?.organization?.subscription?.limitsUsage?.find(({ limit }) => {
    return limit.limit === DialogToClipPlanLimit.D2CTranscriptionDuration;
  });

  return (
    <Box position="relative">
      {limitsUsage ? <UsageLimits limitsUsage={limitsUsage} /> : null}
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={loading} />
        <Styled.TableHorizontalScroll>
          <Table>
            <Table.Head>
              <Table.HeadCell width="100%">
                <SortInterface
                  activeSorting={sorting}
                  setSorting={setSorting}
                  columnName="inputName"
                >
                  File name
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface
                  activeSorting={sorting}
                  setSorting={setSorting}
                  columnName="duration"
                >
                  File duration
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="status">
                  Status
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
                  Date created
                </SortInterface>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {transcriptions.map(({ id, inputName, created, duration, status }) => {
                return (
                  <Table.Row key={id}>
                    <Table.Cell>
                      <strong>{inputName}</strong>
                    </Table.Cell>
                    <Table.Cell title={formatDuration(msToSeconds(duration))}>
                      {msToHMSString(duration, { skipMilliseconds: false })}
                    </Table.Cell>
                    <Table.Cell>
                      <TranscriptionStatusBadge status={status} />
                    </Table.Cell>
                    <Table.Cell>{formatDate(new Date(created))}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
            <PaginationFooter
              totalCount={data?.d2cTranscriptions?.totalCount ?? 0}
              visibleCount={transcriptions.length}
              dispatch={dispatch}
              pageInfo={data?.d2cTranscriptions?.pageInfo}
            />
          </Table>
        </Styled.TableHorizontalScroll>
      </Styled.TableWrapper>
      <Styled.ActionsContainer>
        <LoadingButton
          isLoading={loading}
          variant="outlined"
          color="secondary"
          onClick={() => refetch()}
        >
          Refresh
        </LoadingButton>
      </Styled.ActionsContainer>
    </Box>
  );
};
