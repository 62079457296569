import styled from 'styled-components';

import { materialTheme } from 'components/App/materialTheme';

export const ColorInput = styled.input`
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${materialTheme.palette.grey[200]};
  height: 42px;
  margin-right: 10px;
  padding: 4px 6px;
  width: 42px;
  flex-shrink: 0;
`;

export const Label = styled.label``;

export const Wrapper = styled.div`
  align-items: center;
  color: ${materialTheme.palette.text.secondary};
  display: flex;
`;
