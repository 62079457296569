import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const SwitchBox = styled(Box)`
  .CustomSwitch .Mui-checked {
    color: ${materialTheme.palette.primary.main};
  }

  .CustomSwitch .Mui-checked.Mui-disabled {
    opacity: 0.5;
  }

  .CustomSwitch .Mui-checked + .MuiSwitch-track,
  .CustomSwitch .Mui-checked.Mui-disabled + .MuiSwitch-track {
    background-color: ${materialTheme.palette.primary.light};
    opacity: 1;
  }

  .CustomSwitch .Mui-checked.Mui-disabled + .MuiSwitch-track {
    opacity: 0.5;
  }
`;
