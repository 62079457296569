import { RESET_PASSWORD_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import {
  authorizationTokenErrorMap,
  AuthorizationTokenErrorType,
  passwordRequirementsErrorMap,
  PasswordRequirementsErrorType,
} from 'utils/errors';
import { Nullable } from 'utils/types';

type Errors = AuthorizationTokenErrorType | PasswordRequirementsErrorType;

const errorMap: Record<Errors, string> = {
  ...authorizationTokenErrorMap,
  ...passwordRequirementsErrorMap,
};

interface ResponseData {
  passwordReset: Nullable<{
    ok: boolean;
    errors: ErrorType[];
  }>;
}

interface Variables {
  newPassword: string;
  token: string;
}

interface UsePasswordResetOptions extends UseMutationOptions<ResponseData, Variables> {}

export const usePasswordReset = (options: UsePasswordResetOptions = {}) => {
  return useMutation<ResponseData, Variables>(RESET_PASSWORD_MUTATION, {
    pathToErrors: 'passwordReset.errors',
    errorMap,
    skipGlobalErrorHandling: true,
    ...options,
  });
};
