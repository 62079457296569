import { CREATE_OBJECT_MUTATION, QUERY_VIDEO_STATUS } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { CreateObjectInput, ErrorType, ObjectMetadataFragment } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  createObjectAnnotation: Nullable<{
    objectAnnotation: Nullable<ObjectMetadataFragment>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  objectAnnotation: CreateObjectInput;
}

const pathToErrors = 'createObjectAnnotation.errors';

export const useCreateObject = (videoId: string, onCompleted: () => void) => {
  return useMutation<ResponseData, Variables>(CREATE_OBJECT_MUTATION, {
    pathToErrors,
    refetchQueries: () => {
      onCompleted();
      return videoId
        ? ['GetObjects', { query: QUERY_VIDEO_STATUS, variables: { id: videoId } }]
        : [];
    },
  });
};
