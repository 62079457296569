import { QUERY_VIDEO_PUBLICATIONS } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { ExternalPublicationUnion, SelfPublication } from 'models';
import { Nullable } from 'utils/types';

type ExternalPublicationPick = Pick<
  ExternalPublicationUnion,
  'id' | 'stale' | 'uploadStatus' | '__typename'
>;
type SelfPublicationPick = Pick<
  SelfPublication,
  'id' | 'publishingTargetId' | 'stale' | '__typename'
>;

interface ResponseData {
  videoById: Nullable<{
    externalPublications: Nullable<ExternalPublicationPick[]>;
    id: string;
    publications: Nullable<SelfPublicationPick[]>;
  }>;
}

interface Variables {
  videoId: string;
}

interface UseVideoPublicationsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useVideoPublications = (options: UseVideoPublicationsQueryOptions) => {
  const { data, loading: isLoading } = useQuery<ResponseData, Variables>(
    QUERY_VIDEO_PUBLICATIONS,
    options,
  );
  const video = data?.videoById;

  const selfPublications = video?.publications ?? [];
  const externalPublications = video?.externalPublications ?? [];

  return {
    isLoading,
    selfPublications,
    externalPublications,
  };
};
