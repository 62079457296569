import { Box } from '@material-ui/core';
import React from 'react';

import { useElementSizeObservable, UseSizeProps } from 'hooks/useElementSizeObservable';
import { CTAButton } from 'pages/CTAButton';
import { convertButtonDataFromServer } from 'pages/CTAButton/helpers';
import { ButtonCTAResponse } from 'pages/CTAButton/types';
import { CTAImage } from 'pages/CTAImage';
import { ImageCTAResponse } from 'pages/CTAImage/types';
import { CTAItemType } from 'pages/Overlays/CTAsTab/types';
import { extractNumbers } from 'utils/common';
import { Nullable } from 'utils/types';

import { percentageToPx } from '../helpers';
import { CTATimestampCtas } from '../type';

interface Props {
  config: CTATimestampCtas['styles'];
  width: CTATimestampCtas['width'];
  type: Nullable<CTAItemType>;
  videoWidth: number;
  getCTASize: UseSizeProps;
}

export const HiddenElements: React.FC<Props> = ({
  config,
  width,
  type,
  videoWidth,
  getCTASize,
}) => {
  const { elementRef } = useElementSizeObservable(getCTASize);

  return type === CTAItemType.Button ? (
    <Box position="absolute" zIndex="-99999999" style={{ opacity: 0 }}>
      <div ref={elementRef}>
        <CTAButton config={convertButtonDataFromServer(config as ButtonCTAResponse)} />
      </div>
    </Box>
  ) : (
    <Box position="absolute" zIndex="-99999999" style={{ opacity: 0 }}>
      <div
        ref={elementRef}
        style={{
          width: percentageToPx(extractNumbers(width || 0), videoWidth),
        }}
      >
        <CTAImage config={config as ImageCTAResponse} />
      </div>
    </Box>
  );
};
