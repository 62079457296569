import { SubscriptionCurrency } from 'models';
import { Optional } from 'utils/types';

type Value = string | number;

const currencyMap: Record<SubscriptionCurrency, (value: Value) => string> = {
  usd: (value) => `$${value}`,
};

export function formatCurrency(currency: Optional<string>, value: Optional<Value>) {
  if (typeof currency !== 'string' || !(typeof value === 'number' || typeof value === 'string')) {
    return '';
  }

  const existingFormatter = currencyMap[currency as SubscriptionCurrency];

  if (existingFormatter) {
    return existingFormatter(value);
  }

  return `${currency.toLocaleUpperCase()} ${value}`;
}
