import React from 'react';
import styled from 'styled-components/macro';

import config from 'config';
import { pushError, pushMessage } from 'context/globalStream';
import { attachGetParams } from 'utils/common';
import { LocalStorage } from 'utils/storage';

function getAuthorizeUrl(authToken: string, videoId: string) {
  const url = attachGetParams(config.youtube.authorizeUrl, {
    state: `publish-${videoId}`,
  });
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }).then((response) => response.json());
}

function authorize(videoId: string) {
  const token = LocalStorage.get('token');
  if (!token) {
    pushError({ message: 'Invalid session. Try to log in again.' });
    return;
  }
  getAuthorizeUrl(token, videoId).then((response) => {
    if (response.code === 'ALREADY_AUTHORIZED') {
      pushMessage({ message: 'You are already authorized. Refresh the page.' });
      return;
    }

    if (response.authorization_url) {
      window.location.href = response.authorization_url;
    }
  });
}

interface LinkYoutubeAccountProps {
  videoId: string;
}

export function LinkYoutubeAccount({ videoId }: LinkYoutubeAccountProps) {
  return (
    <AuthButton onClick={() => authorize(videoId)} type="button">
      Link your account…
    </AuthButton>
  );
}

const AuthButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  font-weight: 600;
  text-decoration: underline;
  color: #327dff;
  cursor: pointer;
`;
