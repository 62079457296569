import { Button } from '@material-ui/core';
import React, { useMemo } from 'react';

import useGoogleFont from 'hooks/useGoogleFont';

import { getAllIconOptions, useStyles } from './helpers';
import { OverlaysCTAButton } from './types';

import { IconsWrapper, IconWrapper } from './CTAButton.styles';

interface Props {
  config: OverlaysCTAButton;
}

export const CTAButton: React.FC<Props> = ({ config }) => {
  useGoogleFont(config.fontFamily?.fontFamily, config.fontFamily?.fontWeight);
  const classes = useStyles(config)();

  const iconOption = useMemo(
    () => getAllIconOptions().find(({ value }) => value === config.iconValue),
    [config.iconValue],
  );
  const ShapeIcon = iconOption?.ShapeComponent || null;
  const Icon = iconOption?.Component || null;

  return (
    <Button
      component={config.url ? 'a' : 'p'}
      href={config.url}
      classes={config.text ? classes : {}}
      target="_blank"
      rel="noreferrer noopener"
      color="inherit"
    >
      {config.text && Icon ? (
        <IconsWrapper color={config.iconColor}>
          {ShapeIcon ? <ShapeIcon /> : null}
          <IconWrapper color={ShapeIcon ? config.backgroundColor : config.iconColor}>
            <Icon />
          </IconWrapper>
        </IconsWrapper>
      ) : null}
      {config.text}
    </Button>
  );
};
