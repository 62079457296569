import React from 'react';
import { useFormContext } from 'react-hook-form';

import type { TextInputProps } from 'components/common/TextInput';
import TextInput from 'components/common/TextInput';

interface GA4MeasurementIDInputProps extends Omit<TextInputProps, 'ref'> {
  name: string;
}

/**
 * GA4MeasurementIDInput is a predefined input field for Google Analytics Measurement ID
 * The component requires to be inside FormProvider tree because of useFormContext hook,
 * as otherwise will cause hard to track error "Cannot destructure property of Object null"
 * @param name - field's name which will be used for API call's data.
 */
export const GA4MeasurementIDInput = React.forwardRef<HTMLInputElement, GA4MeasurementIDInputProps>(
  function GA4MeasurementIDInput({ name, ...rest }, ref) {
    const { register, errors } = useFormContext();

    return (
      <TextInput
        ref={
          ref ||
          register({
            pattern: {
              value: /^G-[A-Za-z0-9]{4,16}$/,
              message: 'Provided ID must match G-XXXXXXX format',
            },
          })
        }
        label="Google Analytics (GA4) Measurement ID"
        placeholder="Type or paste your Google Analytics (GA4) Measurement ID"
        info="Only Google Analytics Measurement ID v4 is accepted. The correct format is G-XXXXXXX"
        infoPlacement="top"
        error={!!errors[name]}
        errorMessage={errors[name]?.message}
        name={name}
        {...rest}
      />
    );
  },
);
