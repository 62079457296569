import { SwitchProps } from '@material-ui/core';
import React from 'react';

import { Toggle } from 'components/common';
import { AnyObject, ReactMemo } from 'utils/types';

interface CheckedProps {
  checked: boolean;
  isLabelDisabled?: boolean;
  size?: SwitchProps['size'];
}

type MemoToggleProps<Args extends AnyObject> = CheckedProps &
  Args & {
    disabled?: boolean;
    onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
    onChange: (args: Args & CheckedProps) => void;
  };

function MemoToggle<Args extends AnyObject>({
  checked,
  disabled,
  onChange,
  onClick,
  isLabelDisabled,
  size,
  ...onChangeArgs
}: MemoToggleProps<Args>) {
  return (
    <Toggle
      checked={checked}
      disabled={disabled}
      onClick={onClick}
      isLabelDisabled={isLabelDisabled}
      size={size}
      onChange={() => {
        // @ts-ignore: ts(2345)
        onChange({ checked, ...onChangeArgs });
      }}
    />
  );
}

export default (React.memo as ReactMemo)(MemoToggle);
