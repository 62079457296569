import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;

  > span {
    margin-right: 15px;
    font-family: monospace;
  }
`;
