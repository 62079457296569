import { useApolloClient } from '@apollo/react-hooks';
import { Box, Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { LoadingButton } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { UNPUBLISH_ALLOWED_STATUSES } from 'config/mutationRules';
import { pushMessage } from 'context/globalStream';
import { unpublishVideo } from 'hooks/mutation';
import { useSafeState } from 'hooks/useSafeState';
import { DashboardVideoNode } from 'models';
import {
  parseUnpublishingResults,
  prepareVideoForUnpublish,
} from 'pages/PublishVideo/UnpublishVideoModal.utils';
import { generateVideoLink } from 'pages/urls';
import { capitalize } from 'utils/common';
import { canVideoBeUnpublished } from 'utils/videos';

interface Props {
  onClose: () => void;
  onUnpublish: () => void;
  isOpen: boolean;
  selectedVideos: DashboardVideoNode[];
}

export const BulkUnpublishModal: React.FC<Props> = ({
  onClose,
  onUnpublish,
  isOpen,
  selectedVideos,
}) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const isClosingAfterSuccess = React.useRef(false);
  const client = useApolloClient();

  const validVideos = selectedVideos.filter(({ status }) => canVideoBeUnpublished(status));
  const allowedStatuses = UNPUBLISH_ALLOWED_STATUSES.map(capitalize).join(', ');

  const [frozenVideos, setFrozenVideos] = React.useState(validVideos);

  const handleUnpublish = async () => {
    setIsLoading(true);
    setFrozenVideos(validVideos);
    const promises = validVideos.map((video) => {
      const { targetTypes } = prepareVideoForUnpublish(video);
      return unpublishVideo(client, targetTypes, { videoId: video.id });
    });
    const results = await Promise.all(promises);
    const { isAllSuccess, successfulResultCount } = parseUnpublishingResults(results);
    if (successfulResultCount > 0) {
      if (isAllSuccess) {
        pushMessage({
          message: `${successfulResultCount} ${
            successfulResultCount > 1 ? 'videos' : 'video'
          } has been unpublished.`,
        });
      } else {
        pushMessage({
          message: `${successfulResultCount} ${
            successfulResultCount > 1 ? 'videos' : 'video'
          } has been unpublished with warnings.`,
        });
      }
      scheduleClose();
    } else {
      pushMessage({
        message: `No videos were unpublished. Try again.`,
      });
      setIsLoading(false);
    }
  };

  const scheduleClose = () => {
    onClose();
    isClosingAfterSuccess.current = true;
  };

  const handleExited = () => {
    if (!isClosingAfterSuccess.current) {
      return;
    }
    isClosingAfterSuccess.current = false;
    onUnpublish();
    setIsLoading(false);
  };

  const displayedVideos = isLoading ? frozenVideos : validVideos;

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpen}
      heading={`Unpublish ${selectedVideos.length > 1 ? 'videos' : 'video'}`}
      width={600}
    >
      <Stack>
        <Stack rowGap={10}>
          {displayedVideos.length > 0 && (
            <>
              <Dialog.Text>
                Are you sure you wish to unpublish{' '}
                {displayedVideos.length === 1
                  ? 'selected video'
                  : `${displayedVideos.length} videos`}
                ?
              </Dialog.Text>
              <ul>
                {displayedVideos.map((video) => {
                  return (
                    <li key={video.id}>
                      <Link to={generateVideoLink(video.id)}>
                        <strong>{video.title}</strong>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          {displayedVideos.length !== selectedVideos.length && (
            <Dialog.Text>
              Only videos with following statuses can be unpublished: {allowedStatuses}.
            </Dialog.Text>
          )}
        </Stack>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Columns>
            <Button variant="outlined" onClick={onClose} color="secondary">
              Cancel
            </Button>
            <LoadingButton
              isLoading={isLoading}
              disabled={validVideos.length === 0}
              variant="contained"
              onClick={handleUnpublish}
              color="primary"
            >
              Unpublish
            </LoadingButton>
          </Columns>
        </Box>
      </Stack>
    </Dialog>
  );
};
