import { UPDATE_USER_EMAIL_REQUEST } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

export interface UpdateEmailRequestProps {
  updateEmailRequest: Nullable<{
    user: {
      email: string;
    };
    errors: ErrorType[];
  }>;
}

interface Variables {
  email: string;
}

interface UseUpdateEmailRequest extends UseMutationOptions<UpdateEmailRequestProps, Variables> {}

export const useUpdateEmailRequest = (options: UseUpdateEmailRequest = {}) => {
  return useMutation<UpdateEmailRequestProps, Variables>(UPDATE_USER_EMAIL_REQUEST, {
    pathToErrors: 'updateEmailRequest.errors',
    ...options,
  });
};
