import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { FormLabel } from 'components/common';

export const AdsLocationBox = styled(Box)`
  border: 1px solid ${materialTheme.palette.grey[200]};
  border-radius: 10px;
`;

export const AdsLocationFormLabel = styled(FormLabel)`
  margin-bottom: 0;
`;

export const AdTypeButton = styled.button`
  color: inherit;
  font-weight: 600;
  text-decoration: underline;
`;

export const DisabledMonitizationLabel = styled(FormLabel)<{ $disabled: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  margin-bottom: 0;
`;

export const MonetizationInfoTooltip = styled.div`
  & a {
    text-decoration: underline;
  }
`;
