import { Except, LiteralUnion } from 'type-fest';

import { Nullable } from 'utils/types';

export interface Microsite {
  id: string;
  htmlCode: Nullable<string>;
  title: string;
  description: Nullable<string>;
  googleAnalyticsId: Nullable<string>;
  googleAnalytics4Id: Nullable<string>;
  domain: string;
  mainColor: string;
  logoUrl: Nullable<string>;
  faviconUrl: Nullable<string>;
  sections: Nullable<MicrositeSection[]>;
  created: string;
  healthy: boolean;
  momentsLocationTop: boolean;
}

export interface MicrositeSection {
  id: string;
  title: string;
  internalTag: Nullable<string>;
  regularTag: Nullable<string>;
  sortBy: string;
  contentType: MicrositeSectionTypes;
}

export interface CreateMicrositeInput {
  title: string;
  domain: string;
  momentsLocationTop: boolean;
  description?: Nullable<string>;
  logoBase64?: Nullable<string>;
  faviconBase64?: Nullable<string>;
  mainColor?: Nullable<string>;
  googleAnalyticsId?: Nullable<string>;
  googleAnalytics4Id?: Nullable<string>;
  htmlCode?: Nullable<string>;
}

export type UpdateMicrositeInput = Partial<Except<CreateMicrositeInput, 'domain' | 'title'>> & {
  title: string;
};

export interface UpdateMicrositeSectionInput {
  title: string;
  internalTag?: Nullable<string>;
  regularTag?: Nullable<string>;
  sortBy: string;
  contentType: MicrositeSectionTypes;
}

export enum TagType {
  internalTag = 'internalTag',
  regularTag = 'regularTag',
}

export interface TEMP_MicrositeSection extends MicrositeSection {
  tag: Nullable<string>;
  tagType: TagType;
}
export interface TEMP_Microsite extends Microsite {
  sections: TEMP_MicrositeSection[];
}

export enum MicrositeSectionTypes {
  Videos = 'VIDEOS',
  Moments = 'MOMENTS',
  VideosMoments = 'VIDEOS_MOMENTS',
}

export enum MicrositeSectionOrdering {
  PublicationDate = 'PUBLICATION_DATE',
  PublicationDateDesc = 'PUBLICATION_DATE_DESC',
  Views = 'VIEWS',
  ViewsDesc = 'VIEWS_DESC',
  Stars = 'STARS',
  StarsDesc = 'STARS_DESC',
}

/**
 * Order string can be one (or a combination, joined with `,`) of given values.
 *
 * `column` -> ascending,
 * `-column` -> descending
 */
export type MicrositeOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'created'
  | '-created'
  | 'title'
  | '-title'
  | 'domain'
  | '-domain',
  string
>;
