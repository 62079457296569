import {
  FONT_FAMILY_OPTIONS,
  FONT_WEIGHT,
  FONT_WEIGHT_LABEL,
  FontFamily,
  FontWeight,
  OverlaysFontFamilyOption,
} from 'constants/fonts';

const formatFontFamilyOption = (fontWeight: FontWeight, label: string, name: FontFamily) => {
  return {
    fontWeight,
    fontFamily: label,
    label: `${label} ${FONT_WEIGHT_LABEL[fontWeight]}`,
    value: `${name}_${fontWeight}`,
  };
};

export const getAllFontFamilyOptions = () => {
  const options: OverlaysFontFamilyOption[] = [];

  FONT_FAMILY_OPTIONS.forEach(({ label, name }) => {
    FONT_WEIGHT[name].map((item) => options.push(formatFontFamilyOption(item, label, name)));
  });

  return options;
};
