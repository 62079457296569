import { cover } from 'polished';
import React from 'react';
import styled from 'styled-components/macro';

import { Spinner } from 'components/common';

const Wrapper = styled.div<SpinnerProps>`
  ${cover()}
  position: absolute;
  pointer-events: none;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  color: #ddd;
`;

interface SpinnerProps {
  isVisible: boolean;
}

export function LoadingSpinner({ isVisible }: SpinnerProps) {
  return (
    <Wrapper isVisible={isVisible}>
      <Spinner color="inherit" size={70} thickness={4.5} />
    </Wrapper>
  );
}
