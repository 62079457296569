import { CONFIRM_LOCAL_VIDEO_UPLOAD_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, VideoI } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  confirmLocalVideoUpload: Nullable<{
    video: Nullable<Pick<VideoI, 'id'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  s3Key: string;
  videoId: string;
}

export const useConfirmLocalVideoUpload = () => {
  return useMutation<ResponseData, Variables>(CONFIRM_LOCAL_VIDEO_UPLOAD_MUTATION, {
    pathToErrors: 'confirmLocalVideoUpload.errors',
    refetchQueries: ['GetVideos'],
  });
};
