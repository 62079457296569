import { IconButton } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as Chevron } from 'assets/icons/chevron-up.svg';
import { materialTheme } from 'components/App/materialTheme';

interface PanelWrapperProps {
  opened: boolean;
  side: 'left' | 'right';
}

export const CloseButton = styled((props) => (
  <IconButton {...props}>
    <Chevron />
  </IconButton>
))`
  && {
    align-items: center;
    background-color: ${materialTheme.palette.common.black};
    color: ${materialTheme.palette.common.white};
    display: flex;
    height: 24px;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 54px;
    transition: all 0.3s ease-in;
    width: 24px;

    &:hover {
      background-color: ${materialTheme.palette.text.secondary};
    }
  }
`;

export const PanelWrapper = styled.div<PanelWrapperProps>`
  background-color: ${materialTheme.palette.common.white};
  display: flex;
  flex-direction: column;
  max-width: 496px;
  min-width: 20px;
  position: relative;
  transition: all 1s ease-in-out, width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

  ${CloseButton} {
    ${(props) =>
      props.side === 'left' &&
      css`
        right: 0;
        transform: translate(50%, 0) rotate(270deg);
      `}

    ${(props) =>
      props.side === 'right' &&
      css`
        left: 0;
        transform: translate(-50%, 0) rotate(90deg);
      `}
  }

  ${(props) =>
    props.opened &&
    css`
      width: 42%;
    `}

  ${(props) =>
    !props.opened &&
    css`
      width: 20px;
      ${CloseButton} {
        ${(props: any) =>
          props.side === 'left' &&
          css`
            transform: translate(50%, 0) rotate(90deg);
          `}

        ${(props: any) =>
          props.side === 'right' &&
          css`
            transform: translate(-50%, 0) rotate(270deg);
          `}
      }
    `};
`;

export const InnerWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  min-width: 496px;
  padding: 34px 40px;
  width: 100%;
`;
