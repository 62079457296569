import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { URLS } from 'pages/urls';

export const ResetPasswordSuccess: React.FC = () => {
  return (
    <Stack textAlign="center">
      <div>Your password has been successfully changed!</div>
      <Columns display="flex" justifyContent="center">
        <Button variant="contained" color="primary" component={Link} to={URLS.root}>
          Log in
        </Button>
      </Columns>
    </Stack>
  );
};
