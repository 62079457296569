import styled from 'styled-components/macro';

export const Body = styled.div`
  height: 25px;
  display: flex;
  align-items: flex-end;
  position: relative;
  cursor: grab;
  user-select: none;
`;
