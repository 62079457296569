import { CardSizeOptions, OrientationOptions } from './types';

export const orientationOptions = [
  { label: 'Horizontal', value: OrientationOptions.HORIZONTAL },
  { label: 'Vertical', value: OrientationOptions.VERTICAL },
];

export const cardSizeOptionsHorizontal = [
  { label: 'FULLSCREEN', value: CardSizeOptions.FULLSCREEN },
  { label: 'XL', value: CardSizeOptions.XL },
  { label: 'L', value: CardSizeOptions.L },
  { label: 'M', value: CardSizeOptions.M },
  { label: 'S', value: CardSizeOptions.S },
];

export const cardSizeOptionsVerical = [
  { label: 'FULLSCREEN', value: CardSizeOptions.FULLSCREEN },
  { label: 'XL', value: CardSizeOptions.XL },
  { label: 'L', value: CardSizeOptions.L },
];

export const ACCEPTED_IMAGE_FORMATS = ['.png', '.jpg'];

export const MAX_IMAGE_SIZE = 10;

export const VALIDATION_FILE_MESSAGE = `Accepted format: ${ACCEPTED_IMAGE_FORMATS.join(
  ', ',
)}, max. size: ${MAX_IMAGE_SIZE}MB`;

export const ACCEPTED_FORMATS_ERROR_MESSAGE = `Accepted format: ${ACCEPTED_IMAGE_FORMATS.join(
  ', ',
)}`;
export const MAX_SIZE_ERROR_MESSAGE = `Max. size: ${MAX_IMAGE_SIZE}MB`;
