import { InputBase } from '@material-ui/core';
import React, { useState } from 'react';

import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { ReactComponent as Remove } from 'assets/icons/x.svg';
import { FormLabel, InfoTooltip } from 'components/common';
import { TagNode, TagTypes, VideoStatus } from 'models';
import { SetState } from 'utils/types';

import { CopyPasteTags } from './CopyPasteTags';

import * as Styled from './TagsField.styles';

export const useTagsState = (rawTags?: TagNode[]) => {
  return React.useState(rawTags ? rawTags.map((tag) => tag.value) : []);
};

const MAX_TAG_LENGTH = 60;

interface TagProps {
  tag: string;
  onClose(): void;
  disabled?: boolean;
}

const storageKeyMap: Record<TagTypes, string> = {
  [TagTypes.Internal]: 'internal-tags-copy',
  [TagTypes.Regular]: 'regular-tags-copy',
};

const Tag = ({ tag, onClose, disabled }: TagProps) => {
  return (
    <Styled.TagWrapper>
      {tag}
      <Styled.TagButton onClick={onClose} disabled={disabled} type="button">
        <Remove />
      </Styled.TagButton>
    </Styled.TagWrapper>
  );
};

interface OwnProps {
  tags: string[];
  setTags: SetState<string[]>;
  disabled?: boolean;
  sectionLabel: string;
  type: TagTypes;
  canUserUpdate: boolean;
  videoStatus?: VideoStatus;
  tooltipText?: string;
  placeholder?: string;
  onTagEdited?: () => void;
  hasUnpublishedChanges?: boolean;
}

const TagsField = ({
  tags,
  setTags,
  disabled,
  sectionLabel,
  type,
  canUserUpdate,
  videoStatus,
  tooltipText,
  placeholder = 'Add tag...',
  onTagEdited,
  hasUnpublishedChanges,
}: OwnProps) => {
  const [inputValue, setInputValue] = useState('');
  const id = `new-${sectionLabel.replace(' ', '-')}`;

  const handleSubmit = () => {
    if (inputValue.trim().length === 0) {
      return;
    }

    // TODO: add an error message
    if (tags.includes(inputValue)) {
      return;
    }

    setTags((oldTags) => oldTags.concat(inputValue));
    onTagEdited && onTagEdited();
    setInputValue('');
  };

  const handleDelete = (tagToDelete: string) => {
    setTags((oldTags) => oldTags.filter((tag) => tag !== tagToDelete));
    onTagEdited && onTagEdited();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div>
      <FormLabel>
        {sectionLabel}
        {tooltipText ? <InfoTooltip content={tooltipText} /> : null}
        {hasUnpublishedChanges ? (
          <Styled.TitleLabel>(you have unpublished changes)</Styled.TitleLabel>
        ) : null}
      </FormLabel>
      <Styled.FormField disabled={disabled}>
        <Styled.TagsContainer>
          {tags.map((tag) => (
            <Tag tag={tag} onClose={() => handleDelete(tag)} key={tag} disabled={disabled} />
          ))}
          {!disabled && (
            <Styled.InputWrapper as="label" htmlFor={id}>
              <InputBase
                id={id}
                value={inputValue}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder={placeholder}
                inputProps={{ maxLength: MAX_TAG_LENGTH }}
              />
              <Styled.TagButton onClick={handleSubmit} type="button">
                <Checkmark />
              </Styled.TagButton>
            </Styled.InputWrapper>
          )}
          <CopyPasteTags
            tags={tags}
            tagsType={storageKeyMap[type]}
            type={type}
            canUserUpdate={canUserUpdate}
            videoStatus={videoStatus}
            setTags={setTags}
          />
        </Styled.TagsContainer>
      </Styled.FormField>
      {!disabled && (
        <Styled.MaxLengthInfo>{`(max. ${MAX_TAG_LENGTH} characters per ${
          type === TagTypes.Internal ? 'category' : 'tag'
        })`}</Styled.MaxLengthInfo>
      )}
    </div>
  );
};

export default TagsField;
