import { transparentize } from 'polished';
import styled from 'styled-components/macro';

import { customPalette, materialTheme } from 'components/App/materialTheme';

export const SpeakerLabel = styled.span`
  font-weight: 500;
`;

export const SpeakerLabelWrapper = styled.p`
  margin-bottom: 10px;
  user-select: none;
`;

interface SegmentItemProps {
  isHighlighted?: boolean;
}

// TODO: add more colors based on unique speakers
export const SegmentItem = styled.li<SegmentItemProps>`
  border-radius: 6px;
  overflow: hidden;

  ${(props) =>
    props.isHighlighted && `box-shadow: 0 0 0 2px ${materialTheme.palette.primary.main}`};
`;

export const MomentActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  user-select: none;

  background: ${materialTheme.palette.background.default};
  border-radius: 15px;
  margin-top: 10px;
`;

export const SelectedLabel = styled.span`
  font-weight: 500;
`;

export const SegmentWrapper = styled.div`
  padding: 15px;
  background-color: #f9f9f9;
`;

// The token functionality is too complex to be accessible by keyboard, so there is no point in
// making it a button.
export const Token = styled.span`
  cursor: pointer;

  &::selection {
    background-color: ${transparentize(0.9, customPalette.seeenRed)};
  }
`;

export const InfiniteScrollMark = styled.div`
  position: absolute;
  height: 400px;
  bottom: 0;
  pointer-events: none;
`;
