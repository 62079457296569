import { PUBLIC_MOMENT_URL_QUERY } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { Nullable } from 'utils/types';

interface ResponseData {
  momentById: Nullable<{
    publicMomentUrl: Nullable<string>;
  }>;
}

interface Variables {
  id: string;
  publishingTargetId: string;
}

interface usePublicMomentUrlQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const usePublicMomentUrl = (options?: usePublicMomentUrlQueryOptions) => {
  const { data, loading: isLoading } = useQuery<ResponseData>(PUBLIC_MOMENT_URL_QUERY, options);
  return {
    publicMomentUrl: data?.momentById?.publicMomentUrl,
    isLoading,
  };
};
