import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { updateSource } from 'context/VideoMetadataStream';
import { useUpdateVideoMonetization } from 'hooks/mutation/useUpdateVideoMonetization';
import { VideoMonetizationAdLocation } from 'models';
import { Monetization } from 'pages/Organization/MonetizationTab/types';

import { VideoMonetizationForm, VideoMonetizationViewProps } from './types';
import { VideoMonetizationMain } from './VideoMonetizationMain';
import { VideoMonetizationSidebar } from './VideoMonetizationSidebar';

interface Props {
  monetizationSettings?: Monetization[];
  isMonetizationEnabled: boolean;
}

export const VideoMonetizationView = ({
  video,
  monetizationSettings,
  isMonetizationEnabled,
}: VideoMonetizationViewProps & Props) => {
  useEffect(() => updateSource({ duration: video.duration || 0 }), [video.duration]);

  const [updateVideoMonetization] = useUpdateVideoMonetization({ refetchQueries: ['GetVideo'] });

  const { handleSubmit: onSubmit, control, formState, setValue } = useForm<VideoMonetizationForm>();
  const { isDirty } = formState;

  useEffect(() => {
    setValue(
      'midroll',
      video.monetization?.adLocation.includes(VideoMonetizationAdLocation.midroll),
    );
  }, [video.monetization, setValue]);

  const handleFormSubmit = (data: VideoMonetizationForm) => {
    const { preroll, postroll, midroll, ...monetization } = data;
    const adLocation = [];

    if (preroll) adLocation.push(VideoMonetizationAdLocation.Preroll);
    if (postroll) adLocation.push(VideoMonetizationAdLocation.Postroll);
    if (midroll) adLocation.push(VideoMonetizationAdLocation.midroll);

    updateVideoMonetization({
      variables: {
        videoId: video.id,
        monetization: {
          ...monetization,
          adLocation,
        },
      },
    });
  };

  return (
    <ExtensibleSidebar
      sideContent={
        <VideoMonetizationSidebar
          videoId={video.id}
          onSubmit={onSubmit(handleFormSubmit)}
          control={control}
          monetizationSettings={monetizationSettings}
          monetization={video.monetization}
          isMonetizationEnabled={isMonetizationEnabled}
        />
      }
      mainContent={<VideoMonetizationMain video={video} hasUnsavedChanges={isDirty} />}
    />
  );
};
