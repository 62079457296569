import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

import { FormLabel } from '../FormLabel';
import { useWidgetPresetsEmbed } from './useWidgetPresetsEmbed';

export interface Option {
  value: string;
  label: string;
}

interface Props {
  value?: Option;
  onChange: (option: Option | undefined) => void;
}

export const WidgetPresetPicker = React.forwardRef<any, Props>(function WidgetPresetPicker(
  { value, onChange },
  ref,
) {
  const { data, loading } = useWidgetPresetsEmbed({
    variables: {
      pageSize: 100,
      page: 1,
      changingCount: 0,
      search: '',
      orderBy: '-created',
    },
    fetchPolicy: 'cache-first',
  });

  const options =
    data?.widgetPresets?.nodes?.map(
      (node): Option => ({
        value: node.id,
        label: node.name,
      }),
    ) || [];

  return (
    <div>
      <FormLabel>Widget Preset (optional)</FormLabel>
      <Autocomplete
        innerRef={ref}
        style={{ width: '100%' }}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => {
          return option.value === value.value;
        }}
        renderInput={(params) => {
          return <TextField {...params} variant="outlined" />;
        }}
        value={value || null}
        onChange={(e, data, reason) => {
          if (reason === 'clear') {
            onChange(undefined);
          } else if (data) {
            onChange(data);
          }
        }}
        disabled={loading}
      />
    </div>
  );
});
