import { Box, IconButton, Tooltip } from '@material-ui/core';
import copyToClipboard from 'copy-to-clipboard';
import { cover, ellipsis } from 'polished';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { Spinner, Typography } from 'components/common';
import { Link } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { useSorting } from 'context/sorting';
import { useMicrositeDashboardQuery } from 'hooks/query';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useSafeState } from 'hooks/useSafeState';
import { MicrositeOrderBy } from 'models';
import { generateMicrositeEditLink } from 'pages/urls';
import { formatDate } from 'utils/date';

import { Information } from './Information';
import { MicroSiteStatus } from './MicroSiteStatus';

import * as Styled from 'components/common/Table/Table.styles';
import { CopyButton } from 'components/common/TextInput/TextInput.styles';

export const DashboardMicrositePage: React.VFC = () => {
  const { orderBy, sorting, setSorting } = useSorting<MicrositeOrderBy>('-id');

  const { loading, data } = useMicrositeDashboardQuery({
    variables: { orderBy },
  });
  const microsites = data?.microsites?.nodes || [];

  const [isCopyTooltipVisible, toggleTooltip] = useSafeState(false);
  const handleCopy = React.useCallback(
    (value: string) => () => {
      toggleTooltip(true);
      copyToClipboard(value);
      setTimeout(() => {
        toggleTooltip(false);
      }, 2000);
    },
    [toggleTooltip],
  );

  const { hasPermissions } = useUserPermissions();
  const canEditMicrosite = hasPermissions([Permission.ChangeMicrosite]);

  // initial loading
  if (loading) {
    return (
      <MainLayout>
        <Styled.TableSpinnerBox>
          <Spinner />
        </Styled.TableSpinnerBox>
      </MainLayout>
    );
  }

  if (microsites.length === 0) {
    return <Information />;
  }

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">Microsite</Typography>
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <Styled.TableWrapper>
            <LoadingOverlay isVisible={loading} />
            <Table>
              <Table.Head>
                <Table.HeadCell width="50%">
                  <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="title">
                    Title
                  </SortInterface>
                </Table.HeadCell>
                <Table.HeadCell width="50%">
                  <SortInterface
                    activeSorting={sorting}
                    setSorting={setSorting}
                    columnName="domain"
                  >
                    URL
                  </SortInterface>
                </Table.HeadCell>
                <Table.HeadCell width="50%">
                  <SortInterface
                    activeSorting={sorting}
                    setSorting={setSorting}
                    columnName="created"
                  >
                    Date Created
                  </SortInterface>
                </Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
                <Table.HeadCell>Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {microsites.map((microsite) => (
                  <Table.Row key={microsite.id}>
                    <Table.Cell>
                      <EllipsisParent>
                        <Content>
                          <EllipsisContent>
                            {canEditMicrosite ? (
                              <Link to={generateMicrositeEditLink(microsite.id)}>
                                <strong>{microsite.title}</strong>
                              </Link>
                            ) : (
                              <strong>{microsite.title}</strong>
                            )}
                          </EllipsisContent>
                        </Content>
                      </EllipsisParent>
                    </Table.Cell>
                    <Table.Cell>
                      <CopyButton
                        type="button"
                        onClick={handleCopy(microsite.domain)}
                        tooltipVisible={isCopyTooltipVisible}
                      >
                        {microsite.domain}
                      </CopyButton>
                    </Table.Cell>
                    <Table.Cell>{formatDate(new Date(microsite.created))}</Table.Cell>
                    <Table.Cell>
                      <MicroSiteStatus healthy={microsite.healthy} />
                    </Table.Cell>
                    <Table.Cell>
                      <Tooltip title="Edit" arrow placement="top">
                        <IconButton
                          color="inherit"
                          edge="end"
                          component={Link}
                          to={generateMicrositeEditLink(microsite.id)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Styled.TableWrapper>
        </BigCard>
      </Box>
    </MainLayout>
  );
};

const EllipsisParent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 200px;
`;

const EllipsisContent = styled.div`
  ${ellipsis()};
`;

const Content = styled.div`
  ${cover()};
  display: flex;
  align-items: center;
`;
