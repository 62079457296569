import { VideoStreamsResponse } from 'models';

const SUPPORTED_STREAM = 'APPLE_HLS';

export const getStreamURL = (
  videoFileURL: string,
  videoStreams?: VideoStreamsResponse[],
): string => {
  if (!videoStreams) return videoFileURL;

  const supportedStreamURL = videoStreams?.find((item) => item.standard === SUPPORTED_STREAM);

  return supportedStreamURL?.manifestFileUrl || videoFileURL;
};
