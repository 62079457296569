import { VideoObjectNode } from 'models';

export const normalizedPromoteContent = (objects: VideoObjectNode[]) => {
  return objects.map(({ id, title, url, thumbnailId, thumbnails }) => {
    return {
      id,
      title,
      url,
      imageUrl: thumbnails.find(({ id }) => id === thumbnailId)?.imageUrl || '',
    };
  });
};
