import { Tooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

interface TooltipWrapperProps {
  children: React.ReactNode;
  text: string;
}

const TooltipContentWrapper = styled.div`
  cursor: pointer;
  display: inline-block;

  :not(:last-child) {
    margin-right: 14px;
  }
`;

const TooltipWrapper = ({ children, text }: TooltipWrapperProps) => (
  <Tooltip title={text} arrow placement="top">
    <TooltipContentWrapper>{children}</TooltipContentWrapper>
  </Tooltip>
);

export default TooltipWrapper;
