import React, { useState } from 'react';

import { LoadingButton } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { LabelledCheckbox } from 'components/common/LabelledCheckbox';
import { Stack } from 'components/common/Wrapper/Layout';
import { VideoStatus } from 'models';
import { generateVideoLink } from 'pages/urls';

import * as Styled from './PublishVideo.styles';

interface Props {
  video: {
    id: string;
    title: string;
    status: string;
  };
  canUnpublishFromYoutube: boolean;
  onConfirm: (isYoutubeSelected: boolean) => void;
  onClose: () => void;
  isUnpublishing: boolean;
}

export function UnpublishVideoConfirm({
  video,
  canUnpublishFromYoutube,
  onConfirm,
  onClose,
  isUnpublishing,
}: Props) {
  const [isYoutubeSelected, setIsYoutubeSelected] = useState(true);

  const isScheduled: boolean = video.status === VideoStatus.Scheduled;

  return (
    <Stack>
      <Stack rowGap="12px">
        <Dialog.Text>
          {isScheduled
            ? 'Are you sure you want to cancel scheduled publish '
            : 'Are you sure you want to unpublish '}
          <Styled.VideoLink to={generateVideoLink(video.id)} onClick={onClose}>
            “{video.title}”
          </Styled.VideoLink>{' '}
          from all targets?
        </Dialog.Text>
        {canUnpublishFromYoutube && !isScheduled ? (
          <LabelledCheckbox
            name="youtube"
            onChange={(checked) => setIsYoutubeSelected(checked)}
            value={isYoutubeSelected}
            label="Unpublish from YouTube"
          />
        ) : null}
      </Stack>
      <LoadingButton
        variant="contained"
        color="primary"
        isLoading={isUnpublishing}
        onClick={() => onConfirm(canUnpublishFromYoutube && isYoutubeSelected)}
      >
        {isUnpublishing ? 'Unpublishing…' : 'Unpublish'}
      </LoadingButton>
    </Stack>
  );
}
