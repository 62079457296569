import { Box, TextField, TooltipProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FormLabel, InfoTooltip, Link } from 'components/common';
import { CustomSelect } from 'components/common/CustomSelect';
import { NumberInput } from 'components/common/NumberInput';
import { MeasurementUnit } from 'components/common/NumberInput/types';
import { TimestampBlock } from 'components/common/TimestampBlock';
import { useButtonCTAQuery } from 'hooks/query/useButtonCTAQuery';
import { useCTAQuery } from 'hooks/query/useCTAQuery';
import { useImageCTAQuery } from 'hooks/query/useImageCTAQuery';
import { usePlayerSizeObservable } from 'hooks/usePlayerSize';
import { CTAItem, CTAItemType } from 'pages/Overlays/CTAsTab/types';
import { URLS } from 'pages/urls';
import { isNotEmptyString, validate } from 'utils/validation';

import { CTATypeOptions } from '../constants';
import { CTATimestampCtas, CTATimestampCtasAnimation, CTATimestampCtasPosition } from '../type';
import { CTAAlignment } from './CTAAlignment';
import CTAAnimations from './CTAAnimations';

interface Props {
  defaultValues: CTATimestampCtas;
  onSubmit: (data: CTATimestampCtas) => void;
  onCancel: () => void;
  onUpdate: (updatedItem: CTATimestampCtas) => void;
}

export const CTAForm: React.FC<Props> = ({ defaultValues, onSubmit, onCancel, onUpdate }) => {
  const { handleSubmit, control, errors, watch, setValue } = useForm<CTATimestampCtas>({
    defaultValues,
  });
  const typeField = watch('type');
  const templateField = watch('template');
  const widthField = watch('width');
  const templateFieldId = templateField?.id || '';
  const videoSize = usePlayerSizeObservable();

  const onCompleted = (ctaStyles: CTATimestampCtas['styles']) => {
    if (templateFieldId !== defaultValues.template?.id) {
      onUpdate({
        ...defaultValues,
        type: typeField,
        template: templateField,
        width: widthField,
        styles: ctaStyles,
      });
    }
  };

  const { loading, data } = useCTAQuery({
    variables: {
      orderBy: 'created',
      search: '',
      typeFilter: typeField || '',
    },
  });

  const templeteOptions: CTAItem[] = data?.ctas.items?.nodes || [];

  useImageCTAQuery({
    variables: { id: typeField === CTAItemType.Image ? templateFieldId : '' },
    onCompleted: ({ imageCtaById }) => {
      onCompleted(imageCtaById);
    },
  });

  useButtonCTAQuery({
    variables: { id: typeField === CTAItemType.Button ? templateFieldId : '' },
    onCompleted: ({ buttonCtaById }) => {
      onCompleted(buttonCtaById);
    },
  });

  const onSave = (data: CTATimestampCtas) => {
    return onSubmit({
      ...defaultValues,
      ...data,
      config: defaultValues.config,
    });
  };

  const updateCtaPosition = (position: Partial<CTATimestampCtasPosition>) => {
    onUpdate({
      ...defaultValues,
      width: widthField,
      config: {
        ...defaultValues.config,
        position: {
          ...defaultValues.config.position,
          ...position,
        },
        animation: defaultValues.config.animation,
      },
    });
  };

  const updateCtaAnimation = (newAnimation: CTATimestampCtasAnimation) => {
    onUpdate({
      ...defaultValues,
      width: widthField,
      config: {
        ...defaultValues.config,
        position: {
          ...defaultValues.config.position,
        },
        animation: newAnimation,
      },
    });
  };

  return (
    <Box id="cta-form">
      <TimestampBlock
        title={defaultValues?.template?.name || 'New CTA'}
        saveButtonText="Save CTA"
        hasCancelButton
        hasSaveButton={!!typeField && !!templateField}
        onCancel={onCancel}
        onSave={handleSubmit(onSave)}
      >
        <Controller
          render={({ onChange, ...field }) => (
            <CustomSelect
              {...field}
              id="cta-type"
              options={CTATypeOptions}
              label="Type *"
              mb="40px"
              info="Display Button or Image"
              infoPlacement="top"
              onChange={(data) => {
                onChange(data);
                setValue('template', null);
              }}
            />
          )}
          name="type"
          control={control}
        />
        <Box mb="40px">
          <FormLabel>
            Template *
            <InfoTooltip
              content={
                <div>
                  You can add template on <Link to={URLS.overlays.ctas}>Overlays</Link> page
                </div>
              }
              options={{ interactive: true } as TooltipProps}
              placement="top"
            />
          </FormLabel>
          <Controller
            render={({ onChange, value, ...props }) => (
              <Autocomplete
                options={templeteOptions}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                onChange={(e, data) => onChange(data)}
                value={value}
                loading={loading}
                disabled={!typeField}
                {...props}
              />
            )}
            name="template"
            control={control}
          />
        </Box>

        {typeField === CTAItemType.Image ? (
          <Controller
            name="width"
            control={control}
            rules={{ validate: validate(isNotEmptyString, 'Width cannot be empty') }}
            render={({ onChange, ...field }) => (
              <NumberInput
                {...field}
                onChange={(data) => {
                  onChange(data);
                  onUpdate({ ...defaultValues, width: data });
                }}
                measurement={MeasurementUnit.Percentage}
                label="Width *"
                error={!!errors.width}
                errorMessage={errors.width?.message}
                placeholder="Enter width"
                mb="40px"
                max={99}
              />
            )}
          />
        ) : null}
        <CTAAlignment updateCtaPosition={updateCtaPosition} />

        {videoSize.height && videoSize.width && typeField && templateField && (
          <CTAAnimations updateCtaAnimation={updateCtaAnimation} />
        )}
      </TimestampBlock>
    </Box>
  );
};
