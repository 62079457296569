import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';

interface EditObjectProps {
  onClick: () => void;
  edge?: 'start' | 'end';
}

export const EditObject: React.FC<EditObjectProps> = ({ onClick, edge }) => {
  return (
    <Tooltip title="Edit" arrow placement="top">
      <IconButton color="inherit" edge={edge} onClick={onClick}>
        <Edit />
      </IconButton>
    </Tooltip>
  );
};
