import gql from 'graphql-tag';

import { OBJECT_METADATA_FRAGMENT } from '.';
import { ERRORS_PART } from './_errors';

export const CREATE_OBJECT_MUTATION = gql`
  ${OBJECT_METADATA_FRAGMENT}

  mutation CreateObjectAnnotation($videoId: ID!, $objectAnnotation: CreateObjectAnnotationInput!) {
    createObjectAnnotation(videoId: $videoId, objectAnnotation: $objectAnnotation) {
      objectAnnotation {
        ...ObjectMetadata
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_OBJECT_MUTATION = gql`
  ${OBJECT_METADATA_FRAGMENT}

  mutation UpdateObjectAnnotation($objectId: ID!, $objectAnnotation: UpdateObjectAnnotationInput!) {
    updateObjectAnnotation(id: $objectId, objectAnnotation: $objectAnnotation) {
      objectAnnotation {
        ...ObjectMetadata
      }
      ${ERRORS_PART}
    }
  }
`;

export const TOGGLE_OBJECT_MUTATION = gql`
  mutation ToggleObjectAnnotation($action: ToggleAction!, $id: ID!) {
    toggleObjectAnnotation(action: $action, id: $id) {
      objectAnnotation {
        id
        video {
          id
        }
        visible
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_OBJECT_MUTATION = gql`
  mutation DeleteObject($id: ID!) {
    deleteObjectAnnotation(id: $id) {
      deletedObjectAnnotationId
      ${ERRORS_PART}
    }
  }
`;
