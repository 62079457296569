import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import {
  PlaylistContentType,
  PlaylistFilterBy,
  PlaylistI,
  PlaylistItemType,
  PlaylistOrdering,
} from 'models/Playlist';
import { extractOperationErrors } from 'utils/errors';
import { Nullable } from 'utils/types';

type Errors =
  | 'PLAYLIST_LIMIT_EXCEEDED'
  | 'PLAYLIST_NAME_TAKEN'
  | 'NO_ITEMS_PROVIDED'
  | 'ITEM_NOT_FOUND';

const errorMap: Record<Errors, string> = {
  PLAYLIST_LIMIT_EXCEEDED: 'Maximum number of playlists per organization reached.',
  PLAYLIST_NAME_TAKEN: 'Playlist name must be unique within the organization',
  NO_ITEMS_PROVIDED: 'No videos or moments were selected in manual ordering',
  ITEM_NOT_FOUND: 'Playlist contains unpublished or non-existent videos or moments.',
};

const QUERY = gql`
  mutation CreatePlaylist($playlist: CreatePlaylistInput!) {
    createPlaylist(playlist: $playlist) {
      playlist {
        id
        name
        contentType
        orderBy
        filterBy
        filterValue
        items {
          nodes {
            ... on PublicVideoType {
              id
            }
            ... on PublicMomentType {
              id
            }
          }
        }
      }
      ${ERRORS_PART}
    }
  }
`;

type CreatedPlaylist = Pick<
  PlaylistI,
  'id' | 'name' | 'contentType' | 'orderBy' | 'filterBy' | 'filterValue'
> & {
  items: {
    nodes: Nullable<Pick<PlaylistItemType, 'id'>[]>;
  };
};

interface ResponseData {
  createPlaylist: Nullable<{
    playlist: Nullable<CreatedPlaylist>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  playlist: {
    name: string;
    items: string[];
    orderBy: PlaylistOrdering;
    filterBy: PlaylistFilterBy;
    filterValue: string;
    contentType: PlaylistContentType;
  };
}

const pathToErrors = 'createPlaylist.errors';

interface UseCreatePlaylistMutationOptions extends UseMutationOptions<ResponseData, Variables> {}

export function useCreatePlaylist(options: UseCreatePlaylistMutationOptions) {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors,
    errorMap,
    ...options,
    onCompleted: (data) => {
      const hasError = !!extractOperationErrors(pathToErrors, data);
      if (hasError) {
        return;
      }

      options.onCompleted?.(data);
    },
  });
}
