import { Box, InputAdornment, OutlinedInputProps, TooltipProps } from '@material-ui/core';
import copyToClipboard from 'copy-to-clipboard';
import React, { ChangeEvent } from 'react';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { FormLabel } from 'components/common/FormLabel';
import { InfoTooltip, Placement } from 'components/common/InfoTooltip';
import { useShareForwardedRef } from 'hooks/useShareForwardedRef';

import * as Styled from './TextInput.styles';

export interface TextInputProps extends Omit<OutlinedInputProps, 'onChange'> {
  label?: string;
  name: string;
  copyable?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?(value: string): void;
  placeholder?: string;
  maxLength?: number;
  readOnly?: boolean;
  errorMessage?: any;
  error?: boolean;
  mb?: string;
  info?: string | React.ReactNode;
  infoPlacement?: Placement;
  infoOptions?: TooltipProps;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  {
    label,
    name,
    placeholder,
    copyable,
    disabled,
    onChange,
    maxLength,
    readOnly,
    errorMessage,
    error,
    mb = '32px',
    endAdornment,
    info,
    infoPlacement,
    infoOptions,
    ...rest
  },
  ref,
) {
  const inputRef = useShareForwardedRef(ref);
  const [inputValue, setInputValue] = React.useState(inputRef.current?.value ?? '');

  // update internal value on mount, needed to handle `props.defaultValue`
  React.useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    setInputValue(inputRef.current.value);
  }, [inputRef]);

  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setInputValue(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const [isCopyTooltipVisible, toggleTooltip] = React.useState(false);
  const handleCopy = React.useCallback(() => {
    toggleTooltip(true);
    copyToClipboard(inputValue);
    setTimeout(() => {
      toggleTooltip(false);
    }, 2000);
  }, [inputValue]);

  const hasError = !!errorMessage || error;

  return (
    <Box mb={mb} position="relative" width="100%">
      {label && (
        <FormLabel htmlFor={name} error={hasError}>
          {label}
          {info ? (
            <InfoTooltip content={info} placement={infoPlacement} options={infoOptions} />
          ) : null}
        </FormLabel>
      )}
      <Styled.OutlinedInput
        color="secondary"
        fullWidth
        id={name}
        name={name}
        onChange={handleChange}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        inputProps={{ maxLength }}
        multiline={rest.multiline}
        endAdornment={
          endAdornment ? (
            endAdornment
          ) : copyable ? (
            <InputAdornment position="end">
              <Styled.CopyButton
                type="button"
                onClick={handleCopy}
                tooltipVisible={isCopyTooltipVisible}
              >
                <Copy />
              </Styled.CopyButton>
            </InputAdornment>
          ) : null
        }
        inputRef={inputRef}
        error={hasError}
        {...rest}
      />
      {errorMessage && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
      {maxLength && (
        <Styled.CharsCounter>
          {inputValue.length}/{maxLength}
        </Styled.CharsCounter>
      )}
    </Box>
  );
});

export default TextInput;
