import React from 'react';

import { MomentPlayerPickI } from 'models';

import { MarkerBox } from '../VideoPlayerElements';
import Tooltip from './Tooltip';

interface OwnProps {
  marker: MomentPlayerPickI;
  handleSeeking(seek: number): void;
  barWidth: number;
  duration: number;
}

const Marker = ({ marker, handleSeeking, barWidth, duration }: OwnProps) => {
  return (
    <Tooltip imageUrl={marker.thumbnailUrl} description={marker.description}>
      <MarkerBox
        key={marker.title}
        onClick={() => handleSeeking(marker.timeRange[0] / duration)}
        leftPosition={(marker.timeRange[0] * barWidth) / duration || 0}
      />
    </Tooltip>
  );
};

export default React.memo(Marker);
