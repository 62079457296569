import { Box, Button } from '@material-ui/core';
import React from 'react';
import { Control } from 'react-hook-form';

import { FormLabel, InfoTooltip, Link } from 'components/common';
import { Badge } from 'components/common/Badge';
import { LogoPositionPicker } from 'components/common/LogoPositionPicker';
import { VideoDetailsNode } from 'models';

import { OVERLAYS_BUTTONS } from './constants';

import { FormField } from 'components/common/TagsField/TagsField.styles';

interface Props {
  video: VideoDetailsNode;
  control: Control;
  onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  dirtyFields?: { [key: string]: boolean };
}

export const OverlaysButtons = ({ video, control, onInputBlur, dirtyFields }: Props) => {
  const activeBadgeProps = { color: '#038247', backgroundColor: '#E1FEF1' };
  const inactiveBadgeProps = { backgroundColor: '#EEEEEE' };

  return (
    <FormField>
      <LogoPositionPicker
        tooltipContent="Display your logo while video is playing"
        control={control}
        id={video.id}
        isLogoEnabled={video.orgLogoEnabled}
        logoPosition={video.orgLogoPosition}
        onInputBlur={onInputBlur}
        dirtyFields={dirtyFields}
      />
      {OVERLAYS_BUTTONS.map((button, index) => {
        const isAdded = button.getBadgeValue(video);

        return (
          <Box mb={button.mb} key={index}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel>
                {button.title}
                <InfoTooltip placement="top" content={button.info} />
              </FormLabel>
              <Box mb="8px">
                <Badge size="small" {...(isAdded ? activeBadgeProps : inactiveBadgeProps)}>
                  {isAdded ? 'Added' : 'None'}
                </Badge>
              </Box>
            </Box>

            <Button
              component={Link}
              to={{
                pathname: button.getPathname(video.id),
                state: '',
              }}
              variant="contained"
              color="secondary"
            >
              Edit
            </Button>
          </Box>
        );
      })}
    </FormField>
  );
};
