import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, Microsite } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation DeleteMicrositeLogo($id: ID!) {
    deleteMicrositeLogo(id: $id) {
      microsite {
        id
        logoUrl
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  deleteMicrositeLogo: Nullable<{
    microsite: Nullable<Pick<Microsite, 'id' | 'logoUrl'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteMicrositeLogoOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useDeleteMicrositeLogo = (options?: UseDeleteMicrositeLogoOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'deleteMicrositeLogo.errors',
    ...options,
  });
};
