import { Button } from '@material-ui/core';
import { TableCell as MaterialTableCell } from '@material-ui/core';
import styled from 'styled-components/macro';

import { Link } from 'components/common';

export const CarouselName = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1a;
`;

export const PublishButton = styled(Button)`
  font-size: 14px;
  height: 36px;
`;

export const NameCell = styled(MaterialTableCell)`
  height: 80px;
  padding-left: 16px !important;
  width: 50%;
`;
