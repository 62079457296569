export interface OverlaysFontFamilyOption {
  fontFamily: string;
  fontWeight: FontWeight;
  label: string;
  value: string;
}

export enum FontFamily {
  Roboto = 'Roboto',
  RobotoCondensed = 'Roboto+Condensed',
  Sora = 'Sora',
  Oswald = 'Oswald',
  Raleway = 'Raleway',
  SpaceGrotesk = 'Space+Grotesk',
  FiraSans = 'Fira+Sans',
  Montserrat = 'Montserrat',
  Poppins = 'Poppins',
  Nunito = 'Nunito',
  JosefinSans = 'Josefin+Sans',
  Orbitron = 'Orbitron',
  Exo2 = 'Exo+2',
  Arvo = 'Arvo',
  EBGaramond = 'EB+Garamond',
  Lora = 'Lora',
  PlayfairDisplay = 'Playfair+Display',
  Pacifico = 'Pacifico',
  Lobster = 'Lobster',
}

export enum FontWeight {
  Regular = 400,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Black = 900,
}

export const FONT_FAMILY_OPTIONS = [
  { label: 'Roboto', name: FontFamily.Roboto },
  { label: 'Roboto Condensed', name: FontFamily.RobotoCondensed },
  { label: 'Sora', name: FontFamily.Sora },
  { label: 'Oswald', name: FontFamily.Oswald },
  { label: 'Raleway', name: FontFamily.Raleway },
  { label: 'Space Grotesk', name: FontFamily.SpaceGrotesk },
  { label: 'Fira Sans', name: FontFamily.FiraSans },
  { label: 'Montserrat', name: FontFamily.Montserrat },
  { label: 'Poppins', name: FontFamily.Poppins },
  { label: 'Nunito', name: FontFamily.Nunito },
  { label: 'Josefin Sans', name: FontFamily.JosefinSans },
  { label: 'Orbitron', name: FontFamily.Orbitron },
  { label: 'Exo2', name: FontFamily.Exo2 },
  { label: 'Arvo', name: FontFamily.Arvo },
  { label: 'EB Garamond', name: FontFamily.EBGaramond },
  { label: 'Lora', name: FontFamily.Lora },
  { label: 'Playfair Display', name: FontFamily.PlayfairDisplay },
  { label: 'Pacifico', name: FontFamily.Pacifico },
  { label: 'Lobster', name: FontFamily.Lobster },
];

export const FONT_WEIGHT_LABEL = {
  [FontWeight.Regular]: 'Regular',
  [FontWeight.SemiBold]: 'Semibold',
  [FontWeight.Bold]: 'Bold',
  [FontWeight.ExtraBold]: 'Extrabold',
  [FontWeight.Black]: 'Black',
};

export const FONT_WEIGHT = {
  [FontFamily.Roboto]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.Black],
  [FontFamily.RobotoCondensed]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.Black],
  [FontFamily.Sora]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.ExtraBold],
  [FontFamily.Oswald]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.Bold],
  [FontFamily.Raleway]: [FontWeight.Regular, FontWeight.Bold, FontWeight.Black],
  [FontFamily.SpaceGrotesk]: [FontWeight.Regular, FontWeight.Bold],
  [FontFamily.FiraSans]: [FontWeight.Regular, FontWeight.Bold, FontWeight.Black],
  [FontFamily.Montserrat]: [FontWeight.Regular, FontWeight.Bold, FontWeight.Black],
  [FontFamily.Poppins]: [FontWeight.Regular, FontWeight.Bold, FontWeight.Black],
  [FontFamily.Nunito]: [FontWeight.Regular, FontWeight.Bold, FontWeight.Black],
  [FontFamily.Lora]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.Bold],
  [FontFamily.EBGaramond]: [FontWeight.Regular, FontWeight.Bold],
  [FontFamily.PlayfairDisplay]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.ExtraBold],
  [FontFamily.Pacifico]: [FontWeight.Regular],
  [FontFamily.JosefinSans]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.Bold],
  [FontFamily.Arvo]: [FontWeight.Regular, FontWeight.Bold],
  [FontFamily.Lobster]: [FontWeight.Regular],
  [FontFamily.Orbitron]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.Black],
  [FontFamily.Exo2]: [FontWeight.Regular, FontWeight.SemiBold, FontWeight.ExtraBold],
};
