export enum OAuthService {
  YouTube = 'YOUTUBE',
}

export interface AuthorizedServices {
  accountName: string | null;
  service: OAuthService;
}

export enum UserRole {
  OrgOwner = 'ORG_OWNER',
  OrgAdmin = 'ORG_ADMIN',
  VideoEditor = 'VIDEO_EDITOR',
  Curator = 'CURATOR',
}

export interface UserI {
  avatarUrl?: string;
  dateJoined: string;
  email: string;
  id: string;
  name: string;
  role: UserRole;
}

export interface LegalAgreements {
  acceptsTermsOfUse: boolean;
  acceptsPrivacyPolicy: boolean;
}
