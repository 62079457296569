import { Checkbox } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { TEXT_FONT } from 'utils/styling';

interface Props {
  children: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  name?: string;
  onChange: (checked: boolean) => void;
}

export function CheckboxEntry({ checked, disabled, onChange, children, name }: Props) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.currentTarget.checked);
  };

  return (
    <CheckboxWrapper $disabled={disabled}>
      <Checkbox
        color="primary"
        name={name}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <CheckboxLabel>{children}</CheckboxLabel>
    </CheckboxWrapper>
  );
}

interface CheckboxWrapperProps {
  $disabled?: boolean;
}

const CheckboxWrapper = styled.label<CheckboxWrapperProps>`
  border: 1px solid ${materialTheme.palette.grey[200]};
  border-radius: 10px;
  display: grid;
  grid-template-columns: 56px auto;
  text-align: left;
  align-items: center;
  min-height: 58px;
  padding: 20px 20px 20px 0;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${materialTheme.palette.grey[500]};
    `}
`;

const CheckboxLabel = styled.span`
  font-family: ${TEXT_FONT};
  font-weight: 500;
`;
