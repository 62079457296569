import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, OrganizationMonetization, OrganizationMonetizationInput } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation UpdateOrganizationMonetization($id: ID!, $monetization: OrganizationMonetizationInput!) {
    updateOrganizationMonetization(id: $id, monetization: $monetization) {
      monetization {
        id
        name
        monetizationUrl
        default
        active
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  updateOrganizationMonetization: Nullable<{
    monetization: Nullable<OrganizationMonetization>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  monetization: OrganizationMonetizationInput;
}

interface UseUpdateOrganizationMonetizationQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateOrganizationMonetization = (
  options?: UseUpdateOrganizationMonetizationQueryOptions,
) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'updateOrganizationMonetization.errors',
    skipGlobalErrorHandling: true,
    refetchQueries: ['GetOrganization'],
    ...options,
  });
};
