import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetWidgetApiKey {
    me {
      id
      organization {
        id
        widgetApiKey {
          key
        }
      }
    }
  }
`;

interface ResponseData {
  me: Nullable<{
    id: string;
    organization: Nullable<{
      id: string;
      widgetApiKey: {
        key: string;
      };
    }>;
  }>;
}

interface Variables {}

interface UseWidgetApiKeyOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useWidgetApiKey = (options?: UseWidgetApiKeyOptions) => {
  const response = useQuery<ResponseData, Variables>(QUERY, options);
  return {
    ...response,
    widgetApiKey: response.data?.me?.organization?.widgetApiKey.key,
  };
};
