import { ReactComponent as ArrowRight } from 'assets/icons/overlays/arrow-right.svg';
import { ReactComponent as Bookmark } from 'assets/icons/overlays/bookmark.svg';
import { ReactComponent as Cart } from 'assets/icons/overlays/cart.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/overlays/check-circle.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/overlays/chevron-right.svg';
import { ReactComponent as Coffee } from 'assets/icons/overlays/coffee.svg';
import { ReactComponent as Discount } from 'assets/icons/overlays/discount.svg';
import { ReactComponent as DollarSign } from 'assets/icons/overlays/dollar-sign.svg';
import { ReactComponent as Gift } from 'assets/icons/overlays/gift.svg';
import { ReactComponent as Heart } from 'assets/icons/overlays/heart.svg';
import { ReactComponent as InfoCircle } from 'assets/icons/overlays/info-circle.svg';
import { ReactComponent as PlayCircle } from 'assets/icons/overlays/play-circle.svg';
import { ReactComponent as Share } from 'assets/icons/overlays/share.svg';
import { ReactComponent as ShoppingBag } from 'assets/icons/overlays/shopping-bag.svg';
import { ReactComponent as Star } from 'assets/icons/overlays/star.svg';
import { ReactComponent as ShapeCircle } from 'assets/icons/shapes/shape-circle.svg';
import { ReactComponent as ShapeStar } from 'assets/icons/shapes/shape-star.svg';
import { EMPTY_INPUT_VALUE } from 'components/common/NumberInput/constants';

import { Icons, IconShapes } from './types';

export const FORM_ID = 'new-cta-button';

export const MIN_FONT_SIZE = 12;

export const MAX_FONT_SIZE = 18;

export const MIN_BACKGROUND_OPACITY = 80;

export const MAX_BORDER_WIDTH = 10;

export const DEFAULT_VALUES = {
  fontSize: '14px',
  fontColor: '#FFFFFF',
  fontFamily: null,
  iconValue: '',
  hasIcon: false,
  iconColor: '#FFFFFF',
  backgroundColor: '#CE0000',
  backgroundOpacity: '100%',
  borderWidth: EMPTY_INPUT_VALUE,
  borderColor: '#FFFFFF',
  borderRadius: EMPTY_INPUT_VALUE,
};

export const ICON_OPTIONS = [
  { label: 'Dollar sign', name: Icons.DollarSign },
  { label: 'Info', name: Icons.InfoCircle },
  { label: 'Arrow right', name: Icons.ArrowRight },
  { label: 'Bookmark', name: Icons.Bookmark },
  { label: 'Gift', name: Icons.Gift },
  { label: 'Shopping bag', name: Icons.ShoppingBag },
  { label: 'Chevron right', name: Icons.ChevronRight },
  { label: 'Check', name: Icons.CheckCircle },
  { label: 'Star', name: Icons.Star },
  { label: 'Cart', name: Icons.Cart },
  { label: 'Coffee', name: Icons.Coffee },
  { label: 'Heart', name: Icons.Heart },
  { label: 'Discount', name: Icons.Discount },
  { label: 'Share', name: Icons.Share },
  { label: 'Play', name: Icons.PlayCircle },
];

export const SHAPE_ICON = {
  [IconShapes.Circle]: ShapeCircle,
  [IconShapes.Star]: ShapeStar,
  [IconShapes.Plain]: null,
};

export const SHAPE_LABEL = {
  [IconShapes.Circle]: 'on circle',
  [IconShapes.Star]: 'on star',
  [IconShapes.Plain]: '',
};

export const ICONS = {
  [Icons.DollarSign]: DollarSign,
  [Icons.InfoCircle]: InfoCircle,
  [Icons.ArrowRight]: ArrowRight,
  [Icons.Bookmark]: Bookmark,
  [Icons.Gift]: Gift,
  [Icons.ShoppingBag]: ShoppingBag,
  [Icons.ChevronRight]: ChevronRight,
  [Icons.CheckCircle]: CheckCircle,
  [Icons.Star]: Star,
  [Icons.Cart]: Cart,
  [Icons.Coffee]: Coffee,
  [Icons.Heart]: Heart,
  [Icons.Discount]: Discount,
  [Icons.Share]: Share,
  [Icons.PlayCircle]: PlayCircle,
};
