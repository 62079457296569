import gql from 'graphql-tag';

export const UPDATE_SHARE_SOCIAL_ICONS = gql`
  mutation UpdateShareSocialMediaIconsMuation($id: ID!, $icons: [ShareSocialMediaIconInput!]!) {
    updateShareSocialMediaIcons(id: $id, icons: $icons) {
      organization {
        shareSocialMediaIcons {
          platformName
          visible
        }
      }
    }
  }
`;
