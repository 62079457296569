import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import { materialTheme } from 'components/App/materialTheme';
import { Columns } from 'components/common/Wrapper/Layout';
import { Permission } from 'config/permissions';
import { useDeleteVideoCaptions } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useLanguageName } from 'hooks/useLanguageList';
import { VideoCaptions, VideoCaptionSource } from 'models';
import { PartialRecord } from 'utils/types';

import { ReactComponent as IconJetStream } from './caption-icon-jetstream.svg';
import { ReactComponent as IconUser } from './caption-icon-user.svg';
import { ReactComponent as IconYouTube } from './caption-icon-youtube.svg';

interface Props {
  caption: VideoCaptions;
  videoId: string;
}

export const CaptionsItem: React.VFC<Props> = ({ caption, videoId }) => {
  const [deleteCaptions] = useDeleteVideoCaptions(videoId);
  const languageName = useLanguageName(caption.languageCode);

  const { hasPermissions } = useUserPermissions();
  const canDelete = hasPermissions([Permission.DeleteVideoCaption]);

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete captions for ${languageName}?`)) {
      deleteCaptions({ variables: { id: caption.id } });
    }
  };

  const icon = useIcon(caption.source);

  return (
    <Box component="li" display="flex" alignItems="center">
      <Columns columnGap={10} display="flex" alignItems="stretch">
        <Outlined as="a" href={caption.captionFileUrl} target="_blank" rel="noreferrer noopener">
          {icon ? <Icon>{icon}</Icon> : null}
          {caption.name}
          {`: ${languageName}`}
        </Outlined>
      </Columns>
      {canDelete && (
        <Box ml="auto">
          <IconButton color="inherit" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const Icon = styled.span`
  font-size: 0;
  margin-left: -3px;
  padding-right: 5px;
`;

const Outlined = styled.div`
  padding: 10px;
  border: 1px solid ${materialTheme.palette.grey[200]};
  border-radius: 10px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;

const iconMap: PartialRecord<VideoCaptionSource, JSX.Element | null> = {
  [VideoCaptionSource.System]: <IconJetStream width="20px" height="20px" />,
  [VideoCaptionSource.User]: <IconUser />,
  [VideoCaptionSource.YouTube]: <IconYouTube />,
};

const useIcon = (source: VideoCaptionSource) => {
  return iconMap[source];
};
