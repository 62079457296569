import { DELETE_VIDEO_CAPTIONS, REFETCH_VIDEO_CAPTIONS } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  deleteVideoCaption: Nullable<{
    deletedVideoCaptionId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

export const useDeleteVideoCaptions = (videoId: string) => {
  return useMutation<ResponseData, Variables>(DELETE_VIDEO_CAPTIONS, {
    pathToErrors: 'deleteVideoCaption.errors',
    refetchQueries: [{ query: REFETCH_VIDEO_CAPTIONS, variables: { videoId } }],
  });
};
