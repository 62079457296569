import { NEW_VIDEO_UPLOAD_PART } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, VideoI } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  newLocalVideoUploadPart: Nullable<{
    videoUploadUrl: Nullable<string>;
    video: Nullable<Pick<VideoI, 'id'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  partNumber: number;
}

export const useNewLocalVideoUploadPart = () => {
  return useMutation<ResponseData, Variables>(NEW_VIDEO_UPLOAD_PART, {
    pathToErrors: 'newLocalVideoUploadPart.errors',
  });
};
