import { IconButton } from '@material-ui/core';
import React from 'react';

import { Spinner } from 'components/common';

interface Props {
  edge?: 'start' | 'end';
}

export const LoadingAction: React.FC<Props> = ({ edge }) => {
  return (
    <IconButton color="inherit" edge={edge} disabled>
      <Spinner size={20} />
    </IconButton>
  );
};
