import React from 'react';

import { ImageCTAResponse } from './types';

interface Props {
  config: ImageCTAResponse;
}

export const CTAImage: React.FC<Props> = ({ config }) => {
  return (
    <a href={config.url} target="_blank" rel="noreferrer noopener">
      <img alt="wde" src={config.imageUrl} width="100%" />
    </a>
  );
};
