import { RefetchQueryDescription } from 'apollo-client/core/watchQueryOptions';
import React from 'react';
import { Except } from 'type-fest';

import { Dialog } from 'components/common/Dialog';
import PublishVideoPage from 'pages/PublishVideo';

type PublishModalProps = Except<React.ComponentPropsWithoutRef<typeof Dialog>, 'children'> & {
  videoId?: string;
  handlePublished?: () => void;
  refetchQueries?: RefetchQueryDescription;
};

export function PublishModal({
  videoId,
  handlePublished,
  refetchQueries,
  ...props
}: PublishModalProps) {
  return (
    <Dialog {...props}>
      <PublishVideoPage
        isInModal
        onClose={props.onClose}
        handlePublished={handlePublished}
        refetchQueries={refetchQueries}
        videoId={videoId}
      />
    </Dialog>
  );
}
