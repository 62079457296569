import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { noop } from 'utils/common';
import { As, PropsWithAs } from 'utils/types';

import * as Styled from './Table.styles';

const ExpandContext = React.createContext({
  isExpanded: false,
  isExpandable: false,
  toggleIsExpanded: noop,
});

interface ExpandableRowProps {
  children: React.ReactNode;
  extraContent: React.ReactNode;
  /**
   * Control `isExpanded` state from upstream and trigger a rerender.
   * `true` - autoexpand, `false` - autocollapse
   */
  updateExpandedTo?: boolean;
  isExpandable?: boolean;
}

export function ExpandableRow<Type extends As = 'tr'>({
  children,
  extraContent,
  updateExpandedTo,
  isExpandable = true,
  ...props
}: PropsWithAs<ExpandableRowProps, Type>) {
  const [isExpanded, setIsExpanded] = React.useState(!!updateExpandedTo);

  // update value from upstream
  React.useEffect(() => {
    if (isExpandable && typeof updateExpandedTo === 'boolean') {
      setIsExpanded(updateExpandedTo);
    }
  }, [isExpandable, updateExpandedTo]);

  const { as, ...rowProps } = props;
  const Row = (props.as ?? Styled.Row) as React.ComponentType;

  return (
    <ExpandContext.Provider
      value={{
        isExpanded,
        isExpandable,
        toggleIsExpanded: () => setIsExpanded((wasExpanded) => !wasExpanded),
      }}
    >
      <Row {...rowProps}>{children}</Row>
      {isExpanded && extraContent}
    </ExpandContext.Provider>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    height: '24px !important',
    width: '24px !important',
    borderRadius: '4px !important',
    minWidth: '0 !important',
    padding: '0 !important',
    borderColor: '#e3e8ee !important',
  },
}));

const Chevron = styled(({ isExpanded, ...props }) => <ChevronUpIcon {...props} />)<{
  isExpanded: boolean;
}>`
  ${(props) => !props.isExpanded && 'transform: rotate(180deg)'};
`;

function ExpandButton(props: ButtonProps) {
  const classes = useStyles();
  const { isExpanded, isExpandable, toggleIsExpanded } = React.useContext(ExpandContext);

  return (
    <Button
      {...props}
      aria-expanded={isExpanded}
      className={classes.root}
      size="small"
      variant="outlined"
      style={{ visibility: isExpandable ? undefined : 'hidden' }}
      onClick={toggleIsExpanded}
    >
      <Chevron isExpanded={isExpanded} />
    </Button>
  );
}

ExpandableRow.ExpandButton = ExpandButton;
