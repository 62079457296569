import React from 'react';

import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { ReactComponent as Remove } from 'assets/icons/x.svg';
import { Nullable } from 'utils/types';

import * as Styled from './ThumbnailSelect.styles';

interface Props {
  url: Nullable<string>;
  selected: boolean;
  onSelect?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Thumbnail: React.FC<Props> = ({ url, selected, onSelect, onRemove }) => {
  return (
    <Styled.Thumbnail disabled={!onSelect}>
      <Styled.ThumbnailImage
        url={url}
        selected={selected}
        disabled={!onSelect}
        onClick={onSelect}
      />
      {onRemove && (
        <Styled.RemoveThumbnailButton onClick={onRemove}>
          <Remove />
        </Styled.RemoveThumbnailButton>
      )}
      {selected && (
        <Styled.Checkmark>
          <Checkmark />
        </Styled.Checkmark>
      )}
    </Styled.Thumbnail>
  );
};
