import { Box, BoxProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { pxOrString } from 'utils/styling';

interface StackProps extends BoxProps {
  rowGap?: number | string;
}

export const Stack = styled(({ rowGap, ...props }: StackProps) => <Box {...props} />)<StackProps>`
  && > * + * {
    margin-top: ${({ rowGap = 32 }) => pxOrString(rowGap)};
  }
`;

interface ColumnProps extends BoxProps {
  columnGap?: number | string;
}

export const Columns = styled(({ columnGap, ...props }: ColumnProps) => (
  <Box {...props} />
))<ColumnProps>`
  && > * + * {
    margin-left: ${({ columnGap = 20 }) => pxOrString(columnGap)};
  }
`;
