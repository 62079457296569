import styled from 'styled-components/macro';

export const Label = styled.label`
  color: #323343;
  display: block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.32px;
  margin-bottom: 8px;
`;
