import styled from 'styled-components/macro';

export const SetupModalDescription = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 143%;

  ol {
    padding-left: 20px;
  }
`;
