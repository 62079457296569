import React from 'react';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useUpdateButtonCTA } from 'hooks/mutation/useUpdateButtonCTA';
import { useButtonCTAQuery } from 'hooks/query/useButtonCTAQuery';

import { CTAButtonPageView } from './CTAButtonPageView';
import { convertButtonDataFromServer, convertButtonDataToServer } from './helpers';
import { OverlaysCTAButton } from './types';

export const CTAButtonPage: React.FC = () => {
  const { ctaId } = useParams<{ ctaId: string }>();
  const { data, loading } = useButtonCTAQuery({
    variables: { id: ctaId },
  });

  const [updateButtonCta, { loading: isLoadingUpdate }] = useUpdateButtonCTA();

  const onSubmit = async (formData: OverlaysCTAButton) => {
    await updateButtonCta({
      variables: {
        id: ctaId,
        button_cta: convertButtonDataToServer(formData, data?.buttonCtaById?.name),
      },
    });
  };

  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }
  if (data?.buttonCtaById) {
    return (
      <MainLayout>
        <CTAButtonPageView
          buttonData={convertButtonDataFromServer(data?.buttonCtaById)}
          handleSave={onSubmit}
          isLoading={isLoadingUpdate}
        />
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
};
