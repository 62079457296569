import { Except, LiteralUnion } from 'type-fest';

import {
  ExternalPublicationUnion,
  MomentI,
  ObjectI,
  OrganizationMonetization,
  SelfPublication,
  TagI,
  VideoCaptions,
} from 'models';
import { EndCardResponse } from 'pages/EndCard/types';
import { VideoCTATimestampsResponse } from 'pages/VideoCTA/type';
import { Nullable } from 'utils/types';

import { ThumbnailsPartI } from './ThumbnailI';
import { VideoTranscription } from './VideoTranscription';

export enum VideoStatus {
  Uploading = 'UPLOADING',
  Uploaded = 'UPLOADED',
  Processing = 'PROCESSING',
  Created = 'CREATED',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED',
  Edited = 'EDITED',
}

export enum VideoOrigin {
  Direct = 'DIRECT',
  Remote = 'REMOTE',
  YouTube = 'YOUTUBE',
  Vimeo = 'VIMEO',
}

export enum TagTypes {
  Internal,
  Regular,
}

export interface VideoStreamsResponse {
  id: string;
  manifestFileUrl: string;
  standard: string;
}

export interface PromoteContentSettingsTypes {
  promoteObjects: boolean;
  promoteAtStart: boolean;
  promoteAtEnd: boolean;
  promotedObjectsLabel?: string;
}

export enum ProcessingPipelineType {
  Default = 'DEFAULT',
  Basic = 'BASIC',
}

export interface VideoI extends ThumbnailsPartI {
  id: string;
  captions: VideoCaptions[];
  created: string;
  title: string;
  description: Nullable<string>;
  duration: Nullable<number>;
  origin: VideoOrigin;
  views: number;
  status: VideoStatus;
  objects: ObjectI[];
  objectsCount: number;
  moments: MomentI[];
  momentsCount: number;
  tags: TagI[];
  internalTags?: TagI[];
  videoFileUrl: string;
  publications: SelfPublication[];
  externalPublications: ExternalPublicationUnion[];
  endCard?: EndCardResponse;
  videoStreams?: VideoStreamsResponse[];
  /** integer in range 0-100 (inclusive) */
  processingProgress: number;
  promotedContentSettings?: PromoteContentSettingsTypes;
  thumbnailUrl: Nullable<string>;
  transcription: Nullable<VideoTranscription>;
  scheduledDatetime?: Nullable<string> | undefined;
  monetization: Nullable<VideoMonetization>;
  ctaTimestamps: VideoCTATimestampsResponse['ctaTimestamps'];
  orgLogoEnabled?: boolean;
  orgLogoPosition?: VideoLogoPositionType;
}

export type VideoMetadataFragment = Pick<
  VideoI,
  | 'created'
  | 'description'
  | 'id'
  | 'moments'
  | 'momentsCount'
  | 'objectsCount'
  | 'origin'
  | 'status'
  | 'title'
  | 'views'
  | 'thumbnailUrl'
  | 'monetization'
>;

export type DashboardVideoNode = VideoMetadataFragment & {
  duplicates?: Nullable<Except<DashboardVideoNode, 'duplicates'>[]>;
  externalPublications: Nullable<
    Pick<ExternalPublicationUnion, 'id' | 'studioUrl' | '__typename'>[]
  >;
  processingProgress: VideoI['processingProgress'];
  publications: Nullable<
    Pick<SelfPublication, 'id' | 'publishingTargetId' | 'publicUrl' | '__typename'>[]
  >;
};

export type VideoDetailsNode = VideoMetadataFragment &
  Pick<
    VideoI,
    | 'captions'
    | 'duration'
    | 'tags'
    | 'internalTags'
    | 'videoFileUrl'
    | 'endCard'
    | 'videoStreams'
    | 'publications'
    | 'promotedContentSettings'
    | 'scheduledDatetime'
    | 'ctaTimestamps'
    | 'orgLogoEnabled'
    | 'orgLogoPosition'
  > & {
    moments: Pick<MomentI, 'description' | 'id' | 'timeRange' | 'title' | 'thumbnailUrl'>[];
  } & ThumbnailsPartI;

export type EditableVideoI = Pick<VideoI, 'title' | 'description' | 'orgLogoPosition'> & {
  tags?: string[];
  internalTags?: string[];
  thumbnailId?: string;
  endCardId?: string | null;
};

/**
 * Order string can be one (or a combination, joined with `,`) of given values.
 *
 * `column` -> ascending,
 * `-column` -> descending
 */
export type VideoOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'status'
  | '-status'
  | 'views'
  | '-views'
  | 'momentsCount'
  | '-momentsCount'
  | 'objectsCount'
  | '-objectsCount'
  | 'title'
  | '-title'
  | 'duration'
  | '-duration'
  | 'created'
  | '-created',
  string
>;

export enum VideoMonetizationAdType {
  Skippable = 'SKIPPABLE',
  NonSkippable = 'NON_SKIPPABLE',
  Both = 'BOTH',
}

export enum VideoMonetizationAdDensity {
  AUTO = 'AUTO',
  EVERY_1_MIN = 'EVERY_1_MIN',
  EVERY_2_MIN = 'EVERY_2_MIN',
  EVERY_3_MIN = 'EVERY_3_MIN',
  EVERY_4_MIN = 'EVERY_4_MIN',
  EVERY_5_MIN = 'EVERY_5_MIN',
  EVERY_6_MIN = 'EVERY_6_MIN',
  EVERY_7_MIN = 'EVERY_7_MIN',
  EVERY_8_MIN = 'EVERY_8_MIN',
  EVERY_9_MIN = 'EVERY_9_MIN',
  EVERY_10_MIN = 'EVERY_10_MIN',
}

export enum VideoMonetizationAdLocation {
  Preroll = 'PREROLL',
  Postroll = 'POSTROLL',
  midroll = 'MIDROLL',
}
export interface VideoMonetization {
  orgMonetization: OrganizationMonetization;
  useDefaultMonetization: boolean;
  isMonetizationEnabled: boolean;
  adType: VideoMonetizationAdType;
  adDensity: VideoMonetizationAdDensity;
  adLocation: VideoMonetizationAdLocation[];
}

export type VideoMonetizationInput = Partial<
  VideoMonetization & { orgMonetization: OrganizationMonetization['id'] }
>;

export type VideoLogoPositionType = 'TOP_LEFT' | 'TOP_RIGHT' | 'BOTTOM_LEFT' | 'BOTTOM_RIGHT';
