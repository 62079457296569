import { Button, TooltipProps } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { InformationalModal } from 'components/common/Modals';
import { ModalProps } from 'components/common/Modals/types';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { useCreateOrganizationMonetization } from 'hooks/mutation/useCreateMonetization';
import { useSafeState } from 'hooks/useSafeState';
import { ErrorType } from 'models';

import { useUpdateOrganizationMonetization } from '../../../../hooks/mutation/useUpdateMonetization';
import { validateAdTagURL } from '../helpers';
import { SetupButton } from '../MonetizationButtons/SetupButton';
import { Monetization } from '../types';
import { SetupModal } from './SetupModal';

import { ModalDescription } from 'components/common/Modals/MonetizationModal.styles';
interface Props {
  monetization?: Monetization;
  isEmptyMonetization?: boolean;
}

export const UpdateMonetizationModal: React.VFC<ModalProps & Props> = ({
  onClose,
  isOpen,
  monetization,
  isEmptyMonetization,
}) => {
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [isAddedTagModalOpen, setIsAddedTagModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useSafeState(false);

  const { handleSubmit, register, errors, setError, formState } = useForm<Monetization>({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;

  const handleModalClose = useCallback(() => {
    setIsSetupModalOpen(false);
  }, []);

  const setFieldsErrors = (errors: ErrorType[]) => {
    const { field, messages } = errors[0];

    if (field === 'name' || field === 'monetizationUrl') {
      const errorKey: keyof Monetization = field;
      const errorMessage = messages.join();

      setError(errorKey, { message: errorMessage });
    }
  };

  const [createOrganizationMonetization] = useCreateOrganizationMonetization({
    onCompleted: ({ createOrganizationMonetization }) => {
      if (!createOrganizationMonetization?.errors.length) {
        setIsAddedTagModalOpen(true);
        onClose();
      } else {
        setFieldsErrors(createOrganizationMonetization?.errors);
      }
    },
  });

  const [updateOrganizationMonetization] = useUpdateOrganizationMonetization({
    onCompleted: ({ updateOrganizationMonetization }) => {
      if (!updateOrganizationMonetization?.errors.length) {
        onClose();
      } else {
        setFieldsErrors(updateOrganizationMonetization?.errors);
      }
    },
  });

  const onSubmit = async (data: Monetization) => {
    const isAdTagURLValid = await validateAdTagURL(data.monetizationUrl);

    if (!isAdTagURLValid) {
      setError('monetizationUrl', { message: 'Enter a valid Ad tag URL' });
      return;
    }

    setIsLoading(true);

    if (monetization) {
      const updatedMonetization: Partial<Monetization> = {};

      if (monetization.name !== data.name) {
        updatedMonetization.name = data.name;
      }

      if (monetization.monetizationUrl !== data.monetizationUrl) {
        updatedMonetization.monetizationUrl = data.monetizationUrl;
      }

      await updateOrganizationMonetization({
        variables: {
          id: monetization.id,
          monetization: updatedMonetization,
        },
      });
    } else {
      await createOrganizationMonetization({
        variables: {
          monetization: {
            ...data,
            active: true,
            default: isEmptyMonetization,
          },
        },
      });
    }

    setIsLoading(false);
  };

  const handleExited = () => {
    setIsLoading(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        TransitionProps={{
          onExited: handleExited,
        }}
        open={isOpen}
        heading={`${monetization ? 'Edit' : 'Add'} Google Ad tag url`}
        width={680}
      >
        <div>
          <ModalDescription>
            You can {monetization ? 'change' : 'edit'} this information later.
          </ModalDescription>
          <form name="add-ad-tag-url" onSubmit={handleSubmit(onSubmit)}>
            <Stack display="flex" alignItems="center" flexDirection="column" mt="50px" rowGap={50}>
              <Stack textAlign="left" width="400px">
                <TextInput
                  name="name"
                  label="Ad tag URL name"
                  ref={register({ required: true })}
                  defaultValue={monetization?.name}
                  error={!!errors.name}
                  errorMessage={errors.name?.message}
                  placeholder="Enter Ad tag URL name"
                  mb="0"
                  info="Name displayed on the list"
                />
                <TextInput
                  name="monetizationUrl"
                  label="Ad tag URL"
                  defaultValue={monetization?.monetizationUrl}
                  error={!!errors.monetizationUrl}
                  errorMessage={errors.monetizationUrl?.message}
                  ref={register({ required: true })}
                  placeholder="https://securepubads.g.doubleclick.net/gampad/ads?gdfp_req=1&correlator=[CORRELATOR]&output=vast&sz=[AD_SIZE]&iu=[AD_UNIT_ID]&cust_params=[CUSTOM_PARAMETERS]&impl=s&env=vp&unviewed_position_start=1 "
                  multiline
                  mb="0"
                  info={
                    <div>
                      Your Ad tag url <br />
                      <SetupButton setIsModalOpen={setIsSetupModalOpen} />
                    </div>
                  }
                  infoOptions={{ interactive: true } as TooltipProps}
                />
              </Stack>
              <Columns display="flex" flexDirection="row" justifyContent="center">
                <Button variant="outlined" onClick={onClose} color="secondary" type="button">
                  Cancel
                </Button>
                <LoadingButton
                  disabled={!isValid || !isDirty}
                  isLoading={isLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </LoadingButton>
              </Columns>
            </Stack>
          </form>
        </div>
      </Dialog>
      <SetupModal isOpen={isSetupModalOpen} onClose={handleModalClose} />
      <InformationalModal
        isOpen={isAddedTagModalOpen}
        onClose={() => setIsAddedTagModalOpen(false)}
        heading="Ad tag URL added"
        description="Ads are ready to be enabled. Enable ads for all videos on the monetization tab. Monetization can be managed per-video."
      />
    </>
  );
};
