import React, { useState } from 'react';

import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { TooltipWrapper } from 'components/common';
import { CarouselI } from 'models';
import ConfirmDeleteCarouselModal from 'pages/Widget/ConfirmDeleteCarouselModal';

import * as Styled from 'components/common/Table/Table.styles';

interface CreateCarouselModalButtonProps {
  carousel: CarouselI;
  onDelete: (carouselId: string) => void;
}

const ConfirmDeleteCarouselButton = ({ carousel, onDelete }: CreateCarouselModalButtonProps) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  return (
    <>
      <TooltipWrapper text="Delete">
        <Styled.ActionsCellBtn onClick={() => setShowConfirmDeleteModal(true)}>
          <Trash />
        </Styled.ActionsCellBtn>
      </TooltipWrapper>
      <ConfirmDeleteCarouselModal
        open={showConfirmDeleteModal}
        handleClose={() => setShowConfirmDeleteModal(false)}
        handleDelete={() => onDelete(carousel.id)}
        carousel={carousel}
      />
    </>
  );
};

export default ConfirmDeleteCarouselButton;
