import React from 'react';

import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { useUpdateOrganizationMonetization } from 'hooks/mutation/useUpdateMonetization';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { isDeepEqual } from 'utils/common';
import { formatDate } from 'utils/date';

import { MonetizationActions } from './MonetizationActions';
import { ActiveToggle } from './MonetizationToggles/ActiveToggle';
import { DefaultToggle } from './MonetizationToggles/DefaultToggle';
import { Monetization } from './types';

import * as Styled from '../../Dashboard/Tables/DashboardTable.styles';
import * as StyledTable from './MonetizationTab.styles';

interface Props {
  monetization: Monetization;
  isOnlyDefaultAdTag: boolean;
  isMonetizationEnabled: boolean;
}

export const MonetizationTableRow = React.memo(function ({
  monetization,
  isOnlyDefaultAdTag,
  isMonetizationEnabled,
}: Props) {
  const { hasPermissions } = useUserPermissions();
  const canEditMonetization = hasPermissions([Permission.ChangeOrganizationMonetization]);
  const canRemoveMonetization = hasPermissions([Permission.DeleteOrganizationMonetization]);

  const [updateOrganizationMonetization] = useUpdateOrganizationMonetization();
  const isActionDisabled = monetization.default && !isOnlyDefaultAdTag;

  const handleDefaultToggleChange = () =>
    updateOrganizationMonetization({
      variables: {
        id: monetization.id,
        monetization: {
          default: !monetization.default,
        },
      },
    });

  const handleActiveToggleChange = () =>
    updateOrganizationMonetization({
      variables: {
        id: monetization.id,
        monetization: {
          active: !monetization.active,
        },
      },
    });

  return (
    <StyledTable.DisabledTableRow $disabled={!isMonetizationEnabled}>
      <Table.Cell>
        <Styled.EllipsisParent>
          <Styled.Content>
            <StyledTable.CellEllipsisContent>
              <>
                <strong>{monetization.name}</strong>
                <br />
                {monetization.monetizationUrl}
              </>
            </StyledTable.CellEllipsisContent>
          </Styled.Content>
        </Styled.EllipsisParent>
      </Table.Cell>
      <Table.Cell>{formatDate(new Date(monetization.created))}</Table.Cell>
      <Table.Cell>
        <DefaultToggle
          checked={monetization.default}
          onChange={handleDefaultToggleChange}
          disabled={monetization.default || !canEditMonetization}
        />
      </Table.Cell>
      <Table.Cell>
        <ActiveToggle
          checked={monetization.active}
          onChange={handleActiveToggleChange}
          disabled={isActionDisabled || !canEditMonetization}
          isDefaultTag={monetization.default}
        />
      </Table.Cell>
      {canEditMonetization || canRemoveMonetization ? (
        <Table.Cell>
          <MonetizationActions
            monetization={monetization}
            isRemoveActionDisabled={isActionDisabled}
          />
        </Table.Cell>
      ) : null}
    </StyledTable.DisabledTableRow>
  );
},
isDeepEqual);
