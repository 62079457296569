import { Tooltip } from '@material-ui/core';
import React, { KeyboardEvent, MouseEvent } from 'react';

import { TextInput } from 'components/common';
import { useShareForwardedRef } from 'hooks/useShareForwardedRef';

import { hmsStringToMs, msToHMSString } from '../../utils/time';

import * as Styled from './TimePanel.styles';

interface Props {
  value: string;
  disabled?: boolean;
  label: string;
  name: string;
  onChange: (value: string, commit?: boolean) => void;
  onCommit: (range?: string[]) => void;
  isError: boolean;
}

const CONTROLS_DESCRIPTION = (
  <>
    Arrow up / down: increases / decreases the timestamp by 100ms
    <br />
    Shift + Arrow up / down: increases / decreases the timestamp by 1s
    <br />
    Alt + Arrow up / down: increases / decreases the timestamp by 16ms (~1 frame)
  </>
);

const getStep = (event: KeyboardEvent | MouseEvent) => {
  if (event.shiftKey) return 1000;
  if (event.altKey) return 16;
  return 100;
};

export const TimestampInput = React.forwardRef<HTMLInputElement, Props>(function TimestampInput(
  { value, disabled, label, name, onCommit, onChange, isError },
  ref,
) {
  const inputRef = useShareForwardedRef(ref);

  const increaseValue = (step: number) => {
    const ms = hmsStringToMs(value) + step;
    const result = msToHMSString(ms);

    onChange(result, true);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp') {
      increaseValue(getStep(event));
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      increaseValue(-getStep(event));
      event.preventDefault();
    } else if (event.key === 'Enter' && inputRef.current) {
      inputRef.current.blur();
    }
  };

  const onIncreaseClick = (event: MouseEvent) => {
    increaseValue(getStep(event));
  };

  const onDecreaseClick = (event: MouseEvent) => {
    increaseValue(-getStep(event));
  };

  return (
    <Tooltip title={!disabled ? CONTROLS_DESCRIPTION : ''} arrow placement="right">
      <Styled.InputWrapper onKeyDown={onKeyPress}>
        <TextInput
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          onBlur={() => onCommit()}
          disabled={disabled}
          data-testid="timestampInput"
          ref={inputRef}
          error={isError}
          mb="0"
        />
        <Styled.ButtonsWrapper disabled={disabled}>
          <Styled.InputButton onClick={onIncreaseClick} data-testid="timestampInputIncrease">
            +
          </Styled.InputButton>
          <Styled.InputButton onClick={onDecreaseClick} data-testid="timestampInputDecrease">
            -
          </Styled.InputButton>
        </Styled.ButtonsWrapper>
      </Styled.InputWrapper>
    </Tooltip>
  );
});
