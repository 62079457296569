import React from 'react';
import { useForm } from 'react-hook-form';

import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';

import { DEFAULT_VALUES, FORM_ID } from './constants';
import { CTAButtonPagePreview } from './CTAButtonPagePreview';
import { CTAButtonPageSidebar } from './CTAButtonPageSidebar';
import { CTAButtonViewProps, OverlaysCTAButton } from './types';

export const CTAButtonPageView: React.FC<CTAButtonViewProps> = ({
  buttonData,
  handleSave,
  isLoading,
  isCreated,
}) => {
  const { control, handleSubmit, register, errors, formState, watch } = useForm<OverlaysCTAButton>({
    mode: 'onChange',
    defaultValues: buttonData || DEFAULT_VALUES,
  });
  const fields = watch();

  return (
    <ExtensibleSidebar
      sideContent={
        <form id={FORM_ID} onSubmit={handleSubmit(handleSave)}>
          <CTAButtonPageSidebar control={control} errors={errors} register={register} />
        </form>
      }
      mainContent={
        <CTAButtonPagePreview
          isLoading={isLoading}
          hasUnsavedChanges={!isCreated && formState.isDirty}
          disabled={!formState.isDirty}
          fields={fields}
        />
      }
    />
  );
};
