import { QUERY_VIDEO_STATUS, TOGGLE_MOMENT_MUTATION } from 'context/queries';
import { ErrorType, MomentI, VideoI } from 'models';
import { Nullable } from 'utils/types';

import { ToggleAction, useToggleMutation } from './_useToggleMutation';

interface ResponseData {
  toggleMoment: {
    moment: Nullable<Pick<MomentI, 'id' | 'visible'> & { video: Pick<VideoI, 'id'> }>;
    errors: ErrorType[];
  };
}

export function useToggleMoment(handleToggled?: () => void) {
  return useToggleMutation<ResponseData>(TOGGLE_MOMENT_MUTATION, {
    pathToErrors: 'toggleMoment.errors',
    optimisticResponse: ({ id, videoId, action }) => ({
      toggleMoment: {
        moment: {
          id,
          video: {
            id: videoId,
            __typename: 'VideoType',
          },
          visible: action === ToggleAction.enable,
          __typename: 'MomentType',
        },
        errors: [],
        __typename: 'ToggleMomentMutation',
      },
    }),
    refetchQueries: ({ data }) => {
      handleToggled && handleToggled();
      const videoId = (data as ResponseData)?.toggleMoment.moment?.video.id;
      return videoId ? [{ query: QUERY_VIDEO_STATUS, variables: { id: videoId } }] : [];
    },
  });
}
