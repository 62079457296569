import { Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';

import logo from 'assets/images/seeen-logo--white.png';
import { Typography } from 'components/common';
import { useLogout } from 'hooks/mutation';
import { useUpdateUserEmail } from 'hooks/mutation';
import { URLS } from 'pages/urls';

export const ConfirmNewEmailPage: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [logout] = useLogout({ redirectToRoot: false });
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('id');
  const [updateUserEmail, { loading: updateUserEmailIsLoading }] = useUpdateUserEmail();

  useEffect(() => {
    if (token) {
      updateUserEmail({
        variables: {
          token,
        },
      });
      logout();
    } else {
      history.push(URLS.root);
    }
  }, [history, logout, token, updateUserEmail]);

  return (
    <Box
      p="40px 40px"
      lineHeight="normal"
      bgcolor="rgb(15, 19, 29)"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <img src={logo} alt="SEEEN" style={{ width: '200px' }} />
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="#f6f7fa"
        mt="50px"
        p={'50px 0'}
        borderRadius="5px"
      >
        <Typography variant="h1">Email address has been changed</Typography>
        <Box p="10px 0">You can log in with your new email address.</Box>
        <Box pt="20px">
          <Button
            component={Link}
            to={URLS.root}
            variant="contained"
            color="primary"
            disabled={updateUserEmailIsLoading}
          >
            Log in
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
