import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Modal, TextInput } from 'components/common';
import { isNotEmptyString, validate } from 'utils/validation';

export interface FormData {
  name: string;
}

interface OwnProps {
  open: boolean;
  handleClose: () => void;
  onSave: (data: FormData) => void;
}

const FORM_ID = 'create-carousel-form';

const CreateCarouselForm = styled.form`
  height: 100%;
  width: 400px;
  margin: 0 auto;
`;

const CreateCarouselModal = ({ open, handleClose, onSave }: OwnProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onBlur',
  });

  return (
    <Modal open={open} handleClose={handleClose} heading="Create widget">
      <CreateCarouselForm id={FORM_ID} onSubmit={handleSubmit(onSave)}>
        <Box textAlign="left">
          <TextInput
            label="Widget name"
            name="name"
            placeholder="Write a name for your widget. This won't be visible for the user."
            ref={register({
              validate: validate(isNotEmptyString, 'Name cannot be empty'),
            })}
            error={!!errors.name}
            errorMessage={errors.name?.message}
          />
        </Box>
        <Button variant="contained" color="primary" form={FORM_ID} type="submit">
          Next
        </Button>
      </CreateCarouselForm>
    </Modal>
  );
};

export default CreateCarouselModal;
