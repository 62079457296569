import React, { useState } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

import { Spinner, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Columns } from 'components/common/Wrapper/Layout';
import { usePublicMomentUrl } from 'hooks/query/usePublicMomentUrlQuery';
import { MomentI, SelfPublication, VideoI } from 'models';
import { Nullable } from 'utils/types';

import { PublishModal } from '../../PublishVideo';
import { PublicationRequiredModal } from './PublicationRequiredModal';
import * as Styled from './ShareModal.styled';

type ShareVideoPick = Pick<VideoI, 'id' | 'title' | 'thumbnailUrl'> & {
  publications: Nullable<Pick<SelfPublication, 'publicUrl' | 'publishingTargetId'>[]>;
};

type ShareMomentPick = Pick<MomentI, 'id' | 'title' | 'thumbnailUrl'> & {
  video: ShareVideoPick;
};

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

interface ShareModalProps extends ModalProps {
  title: string;
  url?: Nullable<string>;
  thumbnailUrl?: Nullable<string>;
  isLoading?: boolean;
  isMoment?: boolean;
}

const ShareModal: React.FC<ShareModalProps> = ({
  onClose,
  open,
  title,
  url,
  thumbnailUrl,
  isLoading,
  isMoment,
}) => {
  return (
    <Dialog
      width={600}
      onClose={onClose}
      open={open}
      heading={`Share ${isMoment ? 'moment' : 'video'}`}
    >
      <ShareModalBody url={url} title={title} thumbnailUrl={thumbnailUrl} isLoading={isLoading} />
    </Dialog>
  );
};

interface ShareModalBodyProps {
  title: string;
  url?: Nullable<string>;
  thumbnailUrl?: Nullable<string>;
  isLoading?: boolean;
}

const ShareModalBody: React.FC<ShareModalBodyProps> = ({ url, title, thumbnailUrl, isLoading }) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (!url) {
    return <div>Error: could not retrieve the public URL</div>;
  }

  return (
    <Styled.Wrapper>
      <Styled.Details>
        {thumbnailUrl ? <Styled.Thumbnail image={thumbnailUrl} /> : null}
        <Styled.Title>{title}</Styled.Title>
      </Styled.Details>
      <Columns display="flex" alignItems="center" justifyContent="flex-start" marginBottom="25px">
        <FacebookShareButton url={url}>
          <FacebookIcon size={40} borderRadius={8} />
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={40} borderRadius={8} />
        </LinkedinShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon size={40} borderRadius={8} />
        </TwitterShareButton>
      </Columns>
      <TextInput copyable value={url} readOnly name={'publicUrl'} />
    </Styled.Wrapper>
  );
};

interface ShareVideoModalProps extends ModalProps {
  video: ShareVideoPick;
}

const ShareVideoModal: React.FC<ShareVideoModalProps> = ({ video, ...props }) => {
  if (!video.publications?.length) {
    return null;
  }

  return (
    <ShareModal
      title={video.title}
      url={video.publications[0].publicUrl}
      thumbnailUrl={video.thumbnailUrl}
      {...props}
    />
  );
};

interface ShareMomentModalProps extends ModalProps {
  moment: ShareMomentPick;
}

const ShareMomentModal: React.FC<ShareMomentModalProps> = ({ moment, ...props }) => {
  const publishingTargetId = moment.video.publications?.length
    ? moment.video.publications[0].publishingTargetId
    : null;

  const { publicMomentUrl, isLoading } = usePublicMomentUrl({
    variables: {
      id: moment.id,
      publishingTargetId: publishingTargetId || '',
    },
  });

  return (
    <ShareModal
      title={moment.title}
      url={publicMomentUrl}
      thumbnailUrl={moment.thumbnailUrl}
      isLoading={isLoading}
      isMoment
      {...props}
    />
  );
};

interface ShareOrPublishModalProps extends ModalProps {
  video?: Nullable<ShareVideoPick>;
  moment?: Nullable<ShareMomentPick>;
}

export const ShareOrPublishModal: React.FC<ShareOrPublishModalProps> = ({
  video,
  moment,
  ...props
}) => {
  const [publishStepVisible, setPublishStepVisible] = useState(false);
  if (!video && !moment) return null;

  const publications = video?.publications || moment?.video?.publications || [];
  const videoId = video?.id || moment?.video?.id;
  const videoTitle = video?.title || moment?.video?.title;

  if (!publications.length) {
    if (publishStepVisible) {
      return (
        <PublishModal
          videoId={videoId}
          open={props.open}
          onClose={() => {
            props.onClose();
            setPublishStepVisible(false);
          }}
        />
      );
    }

    return (
      <PublicationRequiredModal
        videoTitle={videoTitle}
        onPublishClick={() => setPublishStepVisible(true)}
        {...props}
      />
    );
  }

  if (video) {
    return <ShareVideoModal video={video} {...props} />;
  }

  if (moment) {
    return <ShareMomentModal moment={moment} {...props} />;
  }

  return null;
};
