import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as Plus } from 'assets/icons/plus-circle.svg';
import { URLS } from 'pages/urls';

import { CTAItemType } from './types';

export const CTALinks = [
  {
    label: 'Button',
    icon: <Plus />,
    href: URLS.cta.newButton,
  },
  {
    label: 'Image',
    icon: <Image />,
    href: URLS.cta.newImage,
  },
];

export const CTATypes = {
  [CTAItemType.Button]: 'Button',
  [CTAItemType.Image]: 'Image',
};
