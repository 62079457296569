import styled from 'styled-components';

interface AvatarProps {
  url: string;
  dropdownAvatar?: boolean;
  size?: number;
}

export const Avatar = styled.div<AvatarProps>`
  background-image: url(${({ url }) => url});
  background-size: cover;
  border-radius: 12px;
  display: inline-block;
  height: ${({ size = 48 }) => size}px;
  min-width: ${({ size = 48 }) => size}px;
  width: ${({ size = 48 }) => size}px;
  background-position: center;
  margin-right: ${({ dropdownAvatar }) => (dropdownAvatar ? '15px' : '0')};
`;
