import styled from 'styled-components/macro';

import { Table } from 'components/Table';

import * as Styled from '../../Dashboard/Tables/DashboardTable.styles';

export const Row = styled(Table.Row)`
  height: 80px;
`;

export const DisabledTableRow = styled(Row)<{ $disabled: boolean }>`
  & > *:not(:last-child) {
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  }
`;

export const CellEllipsisContent = styled(Styled.EllipsisContent)`
  max-width: 250px;
`;
