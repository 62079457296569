import {
  CREATE_MOMENT_THUMBNAIL_MUTATION,
  CREATE_OBJECT_THUMBNAIL_MUTATION,
  CREATE_VIDEO_THUMBNAIL_MUTATION,
  GET_MOMENT_THUMBNAILS,
  GET_OBJECT_THUMBNAILS,
  GET_VIDEO_THUMBNAILS,
  QUERY_VIDEO_STATUS,
} from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, ThumbnailI } from 'models';
import { Nullable } from 'utils/types';

// Video

interface VideoResponseData {
  createVideoThumbnail: Nullable<{
    thumbnail: Nullable<ThumbnailI>;
    errors: ErrorType[];
  }>;
}

interface VideoVariables {
  imageBase64: string;
  videoId: string;
}

export const useCreateVideoThumbnail = (videoId: string) => {
  return useMutation<VideoResponseData, VideoVariables>(CREATE_VIDEO_THUMBNAIL_MUTATION, {
    pathToErrors: 'createVideoThumbnail.errors',
    refetchQueries: ({ data }: { data: VideoResponseData }) => {
      const isSuccess = !!data.createVideoThumbnail?.thumbnail;
      return isSuccess ? [{ query: GET_VIDEO_THUMBNAILS, variables: { videoId } }] : [];
    },
  });
};

// Moment

interface MomentResponseData {
  createMomentThumbnail: Nullable<{
    thumbnail: ThumbnailI;
    errors: ErrorType[];
  }>;
}

interface MomentVariables {
  imageBase64: string;
  momentId: string;
}

export const useCreateMomentThumbnail = (momentId: string, videoId: string) => {
  return useMutation<MomentResponseData, MomentVariables>(CREATE_MOMENT_THUMBNAIL_MUTATION, {
    pathToErrors: 'createMomentThumbnail.errors',
    refetchQueries: ({ data }: { data: MomentResponseData }) => {
      const isSuccess = !!data.createMomentThumbnail?.thumbnail;
      return isSuccess
        ? [
            { query: GET_MOMENT_THUMBNAILS, variables: { momentId } },
            { query: QUERY_VIDEO_STATUS, variables: { id: videoId } },
          ]
        : [];
    },
  });
};

// Object

interface ObjectResponseData {
  createObjectAnnotationThumbnail: Nullable<{
    thumbnail: ThumbnailI;
    errors: ErrorType[];
  }>;
}

interface ObjectVariables {
  imageBase64: string;
  objectId: string;
}

export const useCreateObjectThumbnail = (objectId: string) => {
  return useMutation<ObjectResponseData, ObjectVariables>(CREATE_OBJECT_THUMBNAIL_MUTATION, {
    pathToErrors: 'createObjectAnnotationThumbnail.errors',
    refetchQueries: ({ data }: { data: ObjectResponseData }) => {
      const isSuccess = !!data.createObjectAnnotationThumbnail?.thumbnail;
      return isSuccess ? [{ query: GET_OBJECT_THUMBNAILS, variables: { objectId } }] : [];
    },
  });
};
