import { Button } from '@material-ui/core';
import React, { useState } from 'react';

import { Search } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import { Columns } from 'components/common/Wrapper/Layout';
import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import useElementSize from 'hooks/useElementSize';

import { ActionContentWidthHandler } from '../types';
import { AddSocialMedia } from './AddSocialMedia';
import { SocialMediaTableRow } from './SocialMediaTableRow';
import { SocialMediaTableProps } from './types';

import * as Styled from 'components/common/Table/Table.styles';

export const SocialMediaTable: React.FC<SocialMediaTableProps & ActionContentWidthHandler> = ({
  socials,
  sorting,
  setSorting,
  onSearch,
  getActionContentWidth,
}) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const { elementRef } = useElementSize(getActionContentWidth);

  const { hasPermissions } = useUserPermissions();
  const canUserCreateSocialMediaPlatform = hasPermissions([Permission.CreateSocialMedia]);

  return (
    <>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={false} />
        {socials?.length ? (
          <Styled.TableHorizontalScroll>
            <Table>
              <Table.Head>
                <Table.HeadCell width="20%">
                  <SortInterface
                    activeSorting={sorting}
                    setSorting={setSorting}
                    columnName="platformName"
                  >
                    Platform
                  </SortInterface>
                </Table.HeadCell>
                <Table.HeadCell width="30%">
                  <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="label">
                    Name
                  </SortInterface>
                </Table.HeadCell>
                <Table.HeadCell width="50%">Account URL</Table.HeadCell>
                <Table.HeadCell>Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {socials.map((social) => {
                  return <SocialMediaTableRow key={social.id} social={social} />;
                })}
              </Table.Body>
            </Table>
          </Styled.TableHorizontalScroll>
        ) : null}
        {canUserCreateSocialMediaPlatform ? (
          <Styled.ActionsContainer ref={elementRef}>
            <Columns display="flex" alignItems="center">
              <Search placeholder="Search platforms" onSearch={onSearch} />
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsAddModalVisible(true)}
              >
                Add Social Media Platform
              </Button>
            </Columns>
          </Styled.ActionsContainer>
        ) : null}
      </Styled.TableWrapper>
      <AddSocialMedia isOpen={isAddModalVisible} onClose={() => setIsAddModalVisible(false)} />
    </>
  );
};
