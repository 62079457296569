import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import React from 'react';

interface Props {
  name: string;
  label: React.ReactNode;
  onChange: (value: boolean) => void;
  value: boolean;
  errorMessage?: string;
  flex?: boolean;
  disabled?: boolean;
}

export const LabelledCheckbox = React.forwardRef<HTMLInputElement, Props>(function LabelledCheckbox(
  { name, label, onChange, value, errorMessage, flex, disabled },
  ref,
) {
  return (
    <FormControl style={{ display: flex ? 'flex' : 'block' }} error={!!errorMessage}>
      <FormControlLabel
        name={name}
        inputRef={ref}
        onChange={(event, checked) => onChange(checked)}
        checked={value}
        control={<Checkbox color="primary" />}
        label={label}
        disabled={disabled}
      />
      {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
});
