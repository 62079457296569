import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PageInfo, PaginationVariables } from 'models';
import { DashboardPlaylist, PlaylistsOrderBy } from 'models/Playlist';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetPlaylists($search: String, $orderBy: String, $page: Int, $pageSize: Int) {
    playlists(search: $search, orderBy: $orderBy, page: $page, pageSize: $pageSize) {
      nodes {
        created
        id
        name
        contentType
        orderBy
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        pageNumber
        pageSize
      }
    }
  }
`;

interface ResponseData {
  playlists: Nullable<{
    nodes: Nullable<DashboardPlaylist[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends PaginationVariables {
  search?: string;
  orderBy?: PlaylistsOrderBy;
}

interface UsePlaylistsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export function usePlaylists(options?: UsePlaylistsQueryOptions) {
  return useQuery<ResponseData, Variables>(QUERY, options);
}
