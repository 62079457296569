import { Box, Button } from '@material-ui/core';
import React from 'react';

import { Dialog } from 'components/common/Dialog';

import { Text } from 'components/common/Dialog/Dialog.styles';

interface DeleteVideoModalProps {
  handleClose(): void;
  open: boolean;
  count: number;
  type: 'video' | 'moment' | 'object' | 'platform' | 'preset' | 'playlist';
  handleDelete(): void;
  customTitle?: string;
  customDescription?: string;
}

const DeleteModal = ({
  handleClose,
  open,
  count,
  type,
  handleDelete,
  customTitle,
  customDescription,
}: DeleteVideoModalProps) => {
  const modalHeading = customTitle ? customTitle : `Delete ${type}${count > 1 ? 's' : ''}`;

  return (
    <Dialog onClose={handleClose} open={open} heading={modalHeading} width={600}>
      {customDescription ? (
        <Text>{customDescription}</Text>
      ) : (
        <Text>
          Are you sure you wish to delete {count === 1 ? `selected ${type}` : `${count} ${type}s`}?
        </Text>
      )}
      <Box display="flex" flexDirection="row" justifyContent="center" mt="32px">
        <Box mr="18px">
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            handleDelete();
            handleClose();
          }}
          color="primary"
        >
          Remove
        </Button>
      </Box>
    </Dialog>
  );
};

export default DeleteModal;
