import { useApolloClient } from '@apollo/react-hooks';
import React from 'react';

import { SpinnerBox } from 'components/common/Spinner';
import { useUserQuery } from 'hooks/query';
import { ChildrenProps } from 'models';
import { Intercom } from 'utils/intercom';

const AuthProvider = ({ children }: ChildrenProps) => {
  const client = useApolloClient();
  const { loading } = useUserQuery({
    onCompleted: (data) => {
      const isLoggedIn = !!data?.me?.id;
      client.writeData({ data: { isLoggedIn } });
      if (isLoggedIn) {
        Intercom.setup(data.me);
      }
    },
    onError: () => {
      client.writeData({ data: { isLoggedIn: false } });
    },
    // Needed to prevent auth error to be thrown across the whole application.
    // Source: https://github.com/apollographql/react-apollo/issues/3571#issuecomment-540800036
    fetchPolicy: 'cache-and-network',
    refetchOnVariablesChange: false,
  });

  if (loading) {
    return <SpinnerBox />;
  }

  return <>{children}</>;
};

export default AuthProvider;
