import { Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

interface WrapperProps {
  isEditing: boolean;
  disabled?: boolean;
}

export const Content = styled.span<WrapperProps>`
  border-radius: 4px;
  padding: 0 5px;
  margin-right: 12px;
  display: flex;
  align-items: center;

  ${({ isEditing }) =>
    isEditing &&
    css`
      background-color: #fff;
      border: 1px solid #e0e1e8;
    `}
`;

export const InlineInput = styled.input`
  width: auto;
  background-color: transparent;
  border: none;
  text-align: center;

  &:disabled {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export const Wrapper = styled.span<WrapperProps>`
  display: inline-flex;
  align-items: center;

  ${({ isEditing, disabled }) =>
    !isEditing &&
    !disabled &&
    css`
      &:hover > span {
        background-color: #e6e8f1;
      }
      &:hover svg.edit-icon {
        display: block;
      }
    `}

  svg.edit-icon {
    display: none;
  }
`;

export const ActionButton = styled(Button)`
  && {
    min-width: 0;
    padding: 2px;
    height: 14px;
    min-width: 14px;
    border-radius: 4px;
    margin: 0 3px;
    color: #fff;
  }
`;
