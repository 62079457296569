import { ReactComponent as AlignBottom } from 'assets/icons/align-bottom.svg';
import { ReactComponent as AlignCenterHorizontal } from 'assets/icons/align-center-horizontal.svg';
import { ReactComponent as AlignCenterVertical } from 'assets/icons/align-center-vertical.svg';
import { ReactComponent as AlignLeft } from 'assets/icons/align-left.svg';
import { ReactComponent as AlignRight } from 'assets/icons/align-right.svg';
import { ReactComponent as AlignTop } from 'assets/icons/align-top.svg';

import { Direction, HorizontalAlignment, VerticalAlignment } from './types';

export const VERTICAL_BUTTONS = [
  { direction: Direction.Vertical, icon: <AlignBottom />, value: VerticalAlignment.Bottom },
  {
    direction: Direction.Vertical,
    icon: <AlignCenterVertical />,
    value: VerticalAlignment.Center,
  },
  { direction: Direction.Vertical, icon: <AlignTop />, value: VerticalAlignment.Top },
];

export const HORIZONTAL_BUTTONS = [
  { direction: Direction.Horizontal, icon: <AlignLeft />, value: HorizontalAlignment.Left },
  {
    direction: Direction.Horizontal,
    icon: <AlignCenterHorizontal />,
    value: HorizontalAlignment.Center,
  },
  { direction: Direction.Horizontal, icon: <AlignRight />, value: HorizontalAlignment.Right },
];
