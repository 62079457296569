import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { serializeError } from 'serialize-error';

import config from 'config';
import { pushError } from 'context/globalStream';
import { generateVideoPublishLink, URLS } from 'pages/urls';
import { attachGetParams } from 'utils/common';
import Sentry from 'utils/sentry';
import { LocalStorage } from 'utils/storage';

function saveYoutubeToken(authToken: string, code: string) {
  const url = attachGetParams(config.youtube.saveTokenUrl, {
    code,
  });
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }).then((response) => response.json());
}

function parseSuccessResponse(query: string) {
  return query
    .slice(1)
    .split('&')
    .reduce((result, part) => {
      const [key, value] = part.split('=');
      result[key] = decodeURIComponent(value);
      return result;
    }, {} as Record<string, string | undefined>);
}

function AuthorizeYoutube() {
  const { search } = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const token = LocalStorage.get('token');
    if (!token) {
      pushError({ message: 'Invalid session. Try to log in again.' });
      history.push(URLS.root);
      return;
    }

    const authResponse = parseSuccessResponse(search);

    if ('error' in authResponse) {
      const { error } = authResponse;
      const errorMessage = `[AuthorizeYoutube] Error during authorization process: ${error}`;
      console.warn(errorMessage);
      Sentry.captureMessage(errorMessage, Sentry.Severity.Warning);
      pushYoutubeAuthError();
      return;
    }

    const state = authResponse.state ?? '';
    const publishId = /^publish-(.+)$/.exec(state);

    if (publishId && authResponse.code) {
      const videoId = publishId[1];
      saveYoutubeToken(token, authResponse.code)
        .catch((error) => {
          const errorMessage = `[AuthorizeYoutube] Error during submitting YouTube auth code`;
          const serializedError = serializeError(error);
          console.warn(errorMessage, serializedError);
          Sentry.withScope((scope) => {
            scope.setExtras(serializedError);
            Sentry.captureMessage(errorMessage, Sentry.Severity.Warning);
          });
          pushYoutubeAuthError();
        })
        .finally(() => {
          history.push(generateVideoPublishLink(videoId));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function pushYoutubeAuthError() {
  pushError({ message: 'YouTube authorization failed. Try again.' });
}

export default AuthorizeYoutube;
