import { PaginationVariables } from 'models';

export type PaginationAction =
  | { type: 'next' }
  | { type: 'previous' }
  | { type: 'first' }
  | { type: 'changing' }
  | { type: 'update_size'; value: number };

export type DispatchPagination = (action: PaginationAction) => void;

export const paginationReducer = (state: PaginationVariables, action: PaginationAction) => {
  switch (action.type) {
    case 'next':
      return { ...state, page: state.page + 1 };
    case 'previous':
      return { ...state, page: state.page - 1 };
    case 'first':
      return { ...state, page: 1 };
    case 'update_size':
      return { pageSize: action.value, page: 1, changingCount: 0 };
    // FIXME: this is here as a workaround to refetch data when deleting rows,
    // there should be a better way to do this
    case 'changing':
      return { ...state, changingCount: state.changingCount + 1 };
    default:
      throw new Error();
  }
};
