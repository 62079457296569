import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { DetailsWidgetPreset } from 'models/Widget';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetWidgetPresetById($id: ID!) {
    widgetPresetById(id: $id) {
      id
      name
      googleAnalyticsTrackingId
      googleAnalytics4MeasurementId
      style {
        mainColor
        colorTheme
      }
      endCard {
        id
        name
      }
    }
  }
`;

interface ResponseData {
  widgetPresetById: Nullable<DetailsWidgetPreset>;
}

interface Variables {
  id: string;
}

interface UseExistingPresetQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export function useExistingPreset(options?: UseExistingPresetQueryOptions) {
  return useQuery<ResponseData, Variables>(QUERY, options);
}
