import React from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { Columns, Stack } from 'components/common/Wrapper/Layout';

export interface FormData {
  organization: string;
}

interface Props {
  onSubmit: (data: FormData) => void;
  isSubmitting: boolean;
  disabled?: boolean;
}

export const OrganizationForm: React.VFC<Props> = ({
  onSubmit,
  isSubmitting,
  disabled = false,
}) => {
  const { register, errors, handleSubmit } = useForm<FormData>({
    mode: 'onChange',
  });

  return (
    <form name="register-organization" onSubmit={handleSubmit(onSubmit)}>
      <Stack width="400px" mb="40px">
        <TextInput
          data-testid="organization-input"
          name="organization"
          label="Organization name (optional)"
          placeholder="Type your organization name here"
          autoComplete="organization"
          ref={register()}
          info="The organization name will be used in email communication when inviting other users. It will allow them to recognize from who the invite is coming from"
          errorMessage={errors.organization?.message}
          mb="0"
        />
      </Stack>
      <Columns display="flex" justifyContent="center">
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={disabled}
          isLoading={isSubmitting}
        >
          Next
        </LoadingButton>
      </Columns>
    </form>
  );
};
