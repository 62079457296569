import { ArrowRight, lightTheme } from '@entertainment-ai/react-component-library';
import React from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components/macro';

import { useEditorContext } from '../MicrositeEditorContext';
import { ItemTypeLabel, LayoutContainer, SectionTitle } from './Layout';
import { VideoBox } from './VideoBox';

export const VideosCarousel: React.VFC = () => {
  const { color } = useEditorContext();
  const { initialMicrositeData } = useEditorContext();
  const sectionTitle = useWatch({
    name: 'sections[0].title',
    defaultValue: initialMicrositeData.sections[0]?.title,
  });
  return (
    <>
      <Wrapper $color={color}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            {sectionTitle ? <SectionTitle>{sectionTitle}</SectionTitle> : null}
          </div>

          <List>
            <Item active>
              <ItemTypeLabel>Moment</ItemTypeLabel>
              <Title>Moment 1</Title>
              <Watch>
                Watch moment <ArrowRight height={10} />
              </Watch>
            </Item>
            <Item>
              <ItemTypeLabel>Moment</ItemTypeLabel>
              <Title>Moment 2</Title>
            </Item>
            <Item>
              <ItemTypeLabel>Moment</ItemTypeLabel>
              <Title>Moment 3</Title>
            </Item>
          </List>
        </div>
        <div style={{ marginTop: '64px' }}>
          <VideoBox />
        </div>
      </Wrapper>
    </>
  );
};

const List = styled.ul`
  border-left: 2px solid ${lightTheme.outline};
  display: flex;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  margin-right: 40px;
  height: 100%;
`;

const Title = styled.h4`
  font-size: 20px;
`;

const Watch = styled.span`
  margin-top: 8px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.28px;
`;

const Item = styled.li<{ active?: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 10px 25px;
  position: relative;

  &::before {
    border-radius: 2px;
    content: '';
    display: block;
    height: 100%;
    left: -3px;
    opacity: ${({ active }) => (active ? '1' : '0')};
    position: absolute;
    top: 0;
    transition: all 0.2s;
    width: 4px;
  }
`;

export const Wrapper = styled(LayoutContainer)<{ $color: string }>`
  display: grid;
  grid-template-columns: 45% 1fr;
  align-items: center;

  ${Item}::before {
    background-color: ${({ $color }) => $color};
  }

  ${Watch} {
    color: ${({ $color }) => $color};
  }
`;
