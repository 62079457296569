import React from 'react';
import styled from 'styled-components/macro';

import { BigCard } from 'components/common/Wrapper/Card';
import { Stack } from 'components/common/Wrapper/Layout';

import { Header } from './Header';
import { VideosCarousel } from './VideosCarousel';
import { VideosGrid } from './VideosGrid';
import { VideoWithThumbnailsSection } from './VideoWithThumbnailsSection';

export const Preview: React.FC = ({ children }) => {
  return (
    <Stack>
      <div>{children}</div>

      <BigCard>
        <Body>
          <Stack rowGap="50px">
            <Header />
            <VideosCarousel />
            <VideoWithThumbnailsSection />
            <VideosGrid />
          </Stack>
        </Body>
      </BigCard>
    </Stack>
  );
};

const Body = styled.div`
  user-select: none;
  padding-bottom: 80px;
`;
