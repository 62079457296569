import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
  position: relative;
`;

export const DropdownMenu = styled(Box)`
  transform: translateY(100%);
`;

export const DropdownItemBox = styled(Box)`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;

  :not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }

  :hover {
    background-color: #f5f5f5;
  }
  svg {
    margin-right: 10px;
  }
`;

export const ChevronWrapper = styled.div`
  align-items: center;
  display: flex;
  transform: rotate(180deg);
`;

export const DropdownItemDescription = styled.span`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.55);
  padding: 5px 0;
`;
