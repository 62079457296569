import 'videojs-contrib-quality-levels';

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { ExtendedVideoJsPlayerProps, HlsLevel, HlsQualityList } from './VideoPlayer';

interface PlayerContextValue {
  streamLevels?: HlsLevel[];
  selectedLevel: number;
  onLevelChange: (levelIndex: number) => void;
  handleLoaded: (player: ExtendedVideoJsPlayerProps) => void;
  videoPlaybackRate: number;
  setVideoPlaybackRate: (rate: number) => void;
}

export const VideoPlayerContext = createContext<PlayerContextValue | null>(null);

export const VideoPlayerWrapper: React.FC = ({ children }) => {
  const hlsPlayer = useRef<HlsQualityList>();
  const [streamLevels, setStreamLevels] = useState<HlsLevel[]>();
  const [selectedLevel, setSelectedLevel] = useState(-1);
  const [videoPlaybackRate, setVideoPlaybackRate] = useState(1);

  useEffect(() => {
    const player = hlsPlayer.current;
    if (player && streamLevels) {
      for (let i = 0; i < player.length; i++) {
        let qualityLevel = player[i];
        qualityLevel.enabled = i === selectedLevel ? true : false;
      }
      player.selectedIndex_ = selectedLevel;
      // @ts-ignore  https://github.com/videojs/videojs-contrib-quality-levels/blob/main/README.md#triggering-the-change-event
      player.trigger({ type: 'change', selectedIndex: selectedLevel });
    }
  }, [selectedLevel, streamLevels]);

  const handleLoaded = (player: ExtendedVideoJsPlayerProps) => {
    if (!player.qualityLevels) return;
    const playerQualityLevels = player.qualityLevels();
    hlsPlayer.current = playerQualityLevels;

    playerQualityLevels.on('change', () => {
      if (playerQualityLevels.levels_ && playerQualityLevels.levels_.length > 0) {
        setStreamLevels(playerQualityLevels.levels_);
        setSelectedLevel(playerQualityLevels.selectedIndex_);
      }
    });
  };

  return (
    <VideoPlayerContext.Provider
      value={{
        streamLevels,
        selectedLevel,
        onLevelChange: setSelectedLevel,
        handleLoaded,
        videoPlaybackRate,
        setVideoPlaybackRate,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayerContext = () => {
  const value = useContext(VideoPlayerContext);
  if (!value) {
    throw new Error('useVideoPlayerContext needs to be used within VideoPlayerContext tree');
  }
  return value;
};
