import React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { HTMLHead } from 'components/common/HTMLHead';
import { SpinnerBox } from 'components/common/Spinner';
import { Permission } from 'config/permissions';
import { useIsUserLoggedIn } from 'hooks/query';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useHasActiveSubscription } from 'hooks/useHasActiveSubscription';
import { authorizedPages, sharedPages, unauthorizedPages } from 'pages/Pages';
import { URLS } from 'pages/urls';

import AuthProvider from './AuthProvider';

const App = () => {
  const { data, isLoggedIn, loading } = useIsUserLoggedIn();
  const { hasPermissions } = useUserPermissions();

  const history = useHistory();
  const isOnPaymentPage = !!useRouteMatch(URLS.payNow);

  const handleActiveSubscription = React.useCallback(
    (hasActiveSubscription: boolean) => {
      if (!isOnPaymentPage && !hasActiveSubscription) {
        history.push(URLS.payNow);
      }
    },
    [isOnPaymentPage, history],
  );

  const { isSubscriptionLoading } = useHasActiveSubscription(handleActiveSubscription);

  if (!data || loading || isSubscriptionLoading) {
    return <SpinnerBox />;
  }

  const appTitle = hasPermissions([Permission.ViewVideoDashboard])
    ? 'Seeen Creator Suite'
    : 'Seeen';

  return (
    <AuthProvider>
      <HTMLHead title={appTitle} />
      <Switch>
        {isLoggedIn ? authorizedPages(hasPermissions) : unauthorizedPages}
        {sharedPages}
      </Switch>
    </AuthProvider>
  );
};

export default App;
