import { REFETCH_VIDEO_CAPTIONS, UPDATE_VIDEO_CAPTIONS } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, VideoCaptions } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateVideoCaption: Nullable<{
    captions: Nullable<VideoCaptions>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  videoCaption: {
    captionBase64: string;
    name: string;
  };
}

export const useUpdateVideoCaptions = (videoId: string) => {
  return useMutation<ResponseData, Variables>(UPDATE_VIDEO_CAPTIONS, {
    pathToErrors: 'updateVideoCaption.errors',
    refetchQueries: [{ query: REFETCH_VIDEO_CAPTIONS, variables: { videoId } }],
  });
};
