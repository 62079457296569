import { Button } from '@material-ui/core';
import React from 'react';

import * as Styled from './CreateCarouselModalButton.styled';

interface CreateCarouselModalButtonProps {
  handle: () => void;
}

const CreateCarouselModalButton = ({ handle }: CreateCarouselModalButtonProps) => {
  return (
    <Styled.ButtonWrapper>
      <Button variant="contained" color="primary" onClick={handle}>
        Create widget
      </Button>
    </Styled.ButtonWrapper>
  );
};

export default CreateCarouselModalButton;
