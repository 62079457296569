import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

import { Box, Button } from '@material-ui/core';
import React, { useRef } from 'react';

import { env } from 'config/env';
import { CarouselI } from 'models';

import { Code } from 'components/common/WidgetEmbedCode/WidgetEmbedCode.styles';

interface OwnProps {
  handleClose: () => void;
  carousel: CarouselI;
}

/**
 * @deprecated To be removed after introducing playlist embed widgets
 */
const WidgetEmbedCode = ({ carousel, handleClose }: OwnProps) => {
  const codeRef = useRef<HTMLTextAreaElement>(null);
  const options = JSON.stringify({
    carouselId: carousel.id,
    carouselPublicId: carousel.publicId,
    apiUrl: env.SEEEN_APP_BACKEND_URL,
    apiKey: carousel.apiKey,
  });

  const appEnv = env.SEEEN_APP_ENV;
  let sourceUrl;
  if (['local', 'prd'].includes(appEnv)) {
    sourceUrl = 'https://code.seeen.com/carousel-widget/widget.js';
  } else {
    sourceUrl = `https://${appEnv}.code.seeen.com/carousel-widget/widget.js`;
  }
  sourceUrl = JSON.stringify(sourceUrl);

  // this code is using carousel widget from this repo: https://gitlab.com/entertainment-ai/creator-suite/carousel-widget
  const code = `<script>
                  (function (w,d,s,o,f,js,fjs) {
                      w['seeenWidgetJS${carousel.id}']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
                      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                      js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
                  }(window, document, 'script', 'seeenWidget${carousel.id}', ${sourceUrl}));
                  seeenWidget${carousel.id}('init', ${options});
                </script>
                <div id="seeen-widget-sd-${carousel.id}" class="seeen-widget"></div>
               <div id="seeen-widget-${carousel.id}" class="seeen-widget"></div>`;

  const onClose = () => {
    if (null !== codeRef.current) {
      codeRef.current.select();
      document.execCommand('copy');
      handleClose();
    }
  };

  return (
    <>
      <Box>
        Copy the code and embed it on your page:
        <Box mt="16px">
          <Code value={code} ref={codeRef} readOnly={true} />
        </Box>
      </Box>
      <Box mt="16px">
        <Button variant="contained" color="primary" type="submit" onClick={onClose}>
          Copy to Clipboard
        </Button>
      </Box>
    </>
  );
};

export default WidgetEmbedCode;
