import styled from 'styled-components/macro';

interface AvatarProps {
  url: string;
}

export const Avatar = styled.div<AvatarProps>`
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  display: inline-block;
  height: 48px;
  width: 48px;
  margin-right: 20px;
`;

export const Trash = styled.button`
  margin-left: 20px;
`;
