import gql from 'graphql-tag';

import { PendingPublishingTargetPick, PublishingTargetType } from 'models';

const targetMap: Record<PublishingTargetType, string> = {
  [PublishingTargetType.SelfPublication]: `
    publications {
      ... on SelfPublicationType {
        id
        publicUrl
        publishingTargetId
        stale
      }
      __typename
    }
  `,
  [PublishingTargetType.YouTube]: `
    externalPublications {
      ... on YouTubePublicationType {
        id
        publicUrl
        stale
        studioUrl
        uploadStatus
      }
      __typename
    }
  `,
};

export function getVideoPublishStatusQuery(targets: PendingPublishingTargetPick[]) {
  const videoPart = targets.reduce((body, target) => {
    const query = targetMap[target.type];
    return body + query;
  }, '');

  return gql`
    query GetPublishVideoStatus($videoId: ID!) {
      videoById(id: $videoId) {
        id
        ${videoPart}
      }
    }
  `;
}

export const QUERY_PUBLISHING_TARGETS = gql`
  query GetPublishingTargets {
    me {
      id
      organization {
        id
        publishingTargets {
          id
          name
        }
        externalPublishingTargets {
          id
          name
        }
      }
    }
  }
`;
