import { CREATE_CAROUSEL_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { CarouselI, CreateCarouselInput, ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  createCarousel: Nullable<{
    carousel: Nullable<Pick<CarouselI, 'id' | 'name'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  carousel: CreateCarouselInput;
}

export const useCreateCarousel = () => {
  return useMutation<ResponseData, Variables>(CREATE_CAROUSEL_MUTATION, {
    pathToErrors: 'createCarousel.errors',
    refetchQueries: ({ data }) => {
      return data?.createCarousel?.carousel ? ['getCarousels'] : [];
    },
  });
};
