import React from 'react';

import { useDeepCompareEffect } from './useDeepCompareEffect';

export function useFormAutosave(deps: any[], isEnabled = true) {
  const isFirstRender = React.useRef(true);
  const submitRef = React.useRef<HTMLInputElement>(null);

  const triggerFormSubmit = React.useCallback(() => {
    submitRef.current?.click();
  }, []);

  // re-submit the form when deps change
  useDeepCompareEffect(() => {
    if (!isEnabled) {
      return;
    }

    // ignore mount render, data is being set to initial values
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    triggerFormSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, triggerFormSubmit]);

  return {
    submitRef,
    triggerFormSubmit,
  };
}
