import React from 'react';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useVideoQuery } from 'hooks/query';
import { MomentsEditorWrapper } from 'pages/Moments/context';
import { navigateTo404 } from 'utils/navigation';

import VideoContent from './Content';

interface RouteParams {
  videoId: string;
}

function Video() {
  const { videoId } = useParams<RouteParams>();
  const { loading, data } = useVideoQuery({
    variables: { id: videoId },
    onCompleted: ({ videoById }) => {
      if (!videoById) {
        navigateTo404();
      }
    },
  });

  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (data?.videoById) {
    return (
      <MainLayout>
        <MomentsEditorWrapper allowEditing={false} videoId={videoId}>
          <VideoContent video={data.videoById} />
        </MomentsEditorWrapper>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
}

export default Video;
