import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

import { Box, Button } from '@material-ui/core';
import React from 'react';

import { Modal } from 'components/common';
import { CarouselI } from 'models';

import { Text } from 'components/common/Modal/Modal.styles';

interface DeleteVideoModalProps {
  handleClose(): void;
  open: boolean;
  handleDelete(): void;
  carousel: CarouselI;
}

const ConfirmDeleteCarouselModal = ({
  handleClose,
  open,
  handleDelete,
  carousel,
}: DeleteVideoModalProps) => {
  return (
    <Modal
      handleClose={handleClose}
      open={open}
      heading={`Delete widget: "${carousel.name}"`}
      width={600}
    >
      <Text>Are you sure you wish to delete this widget?</Text>
      <Box display="flex" flexDirection="row" justifyContent="center" mt="32px">
        <Box mr="18px">
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            handleDelete();
            handleClose();
          }}
          color="secondary"
        >
          Delete
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteCarouselModal;
