import { MICROSITE_DASHBOARD_QUERY } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { Microsite, MicrositeOrderBy } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  microsites: Nullable<{
    nodes: Nullable<Microsite[]>;
  }>;
}

interface Variables {
  pageSize?: number;
  orderBy?: MicrositeOrderBy;
  search?: string;
}

interface UseMicrositeDashboardOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useMicrositeDashboardQuery = (options?: UseMicrositeDashboardOptions) => {
  return useQuery<ResponseData, Variables>(MICROSITE_DASHBOARD_QUERY, options);
};
