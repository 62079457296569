import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { BulkChanges, DeleteModal } from 'components/common';
import { BackButton, Typography } from 'components/common';
import { AddButton } from 'components/common/AddButton';
import { Permission } from 'config/permissions';
import { QUERY_VIDEO_STATUS } from 'context/queries';
import { BulkResource } from 'context/queries/bulk';
import { seekToMomentStart, source$, togglePlayback } from 'context/VideoMetadataStream';
import { useBulkDeleteMutation, useDeleteMoment, useToggleMoment } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useWidgetApiKey } from 'hooks/query/useWidgetApiKey';
import { TimeRange, VideoMomentNode } from 'models';
import { ShareOrPublishModal } from 'pages/Dashboard/ShareModal';
import { generateVideoLink } from 'pages/urls';
import { Nullable } from 'utils/types';

import { useMomentsEditorContext } from './context';
import { MomentsSidebarSingle } from './MomentsSidebarSingle';
import * as Styled from './styled';
interface MomentsSidebarProps {
  videoId: string;
  moments?: VideoMomentNode[];
  videoDuration: Nullable<number>;
}
interface SelectedBulkMomentsProps {
  [key: string]: boolean;
}

export const MomentsSidebar: React.FC<MomentsSidebarProps> = ({
  videoId,
  moments,
  videoDuration,
}) => {
  const {
    setShowCreateMoment,
    setIsTranscription,
    showCreateMoment,
    handleSetChangesSaved,
    initialMomentData,
    resetInitialMomentData,
  } = useMomentsEditorContext();
  const videoEditorUrl = generateVideoLink(videoId);
  const [selected, setSelected] = useState<SelectedBulkMomentsProps>({});
  const [selectedIndexes, setSelectedIndexes] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sharedMoment, setSharedMoment] = useState<Nullable<VideoMomentNode>>(null);
  const [bulkPosition, setBulkPosition] = useState(0);
  const allMomentsTimestamps =
    moments?.map((moment) => {
      return {
        id: moment.id,
        ranges: [moment.timeRange[0], moment.timeRange[1]] as TimeRange,
      };
    }) || [];

  const handleCreateCompleted = () => {
    setShowCreateMoment(false);
    handleSetChangesSaved(true);
    resetInitialMomentData();
  };

  // When adding new moment through Moments Editor setIsTranscription to false so that new moment has start and end times suggested
  const handleCreateNewMoment = () => {
    setIsTranscription(false);
    setShowCreateMoment(!showCreateMoment);
  };

  const selectedList = Object.keys(selected).filter((key) => selected[key]);
  const selectedLength = selectedList.length;
  const lastSelectedMoment = selectedIndexes[selectedIndexes.length - 1];

  const handleCheckboxClick = React.useCallback(
    (id: string) => setSelected((prevSelected) => ({ ...prevSelected, [id]: !prevSelected[id] })),
    [],
  );

  const updateSelectedIndexes = (id: string) => {
    setSelectedIndexes(
      selectedIndexes.includes(id)
        ? selectedIndexes.filter((momentId) => momentId !== id)
        : [...selectedIndexes, id],
    );
  };

  const [bulkDelete] = useBulkDeleteMutation(
    BulkResource.Moment,
    selectedList,
    ['GetMomentsForVideo'],
    () => {
      setSelected({});
    },
  );

  const [deleteMoment] = useDeleteMoment({
    awaitRefetchQueries: true,
    refetchQueries: [
      'GetMomentsForVideo',
      { query: QUERY_VIDEO_STATUS, variables: { id: videoId } },
    ],
  });
  const [toggleMoment] = useToggleMoment(() => handleSetChangesSaved(true));

  const { hasPermissions } = useUserPermissions();
  const canUserChangeMoment = hasPermissions([Permission.ChangeMoment]);
  const canUserDeleteMoment = hasPermissions([Permission.DeleteMoment]);

  const { widgetApiKey } = useWidgetApiKey();

  const {
    currentMomentEndTime,
    setCurrentMomentEndTime,
    currentMomentStartTime,
    currentMomentID,
    setCurrentMomentID,
  } = useMomentsEditorContext();

  useEffect(() => {
    if (source$.getValue().playing && currentMomentEndTime <= source$.getValue().played) {
      seekToMomentStart(currentMomentStartTime + 1);
      togglePlayback();
      setCurrentMomentID('');
    } else if (!source$.getValue().playing) {
      setCurrentMomentEndTime(source$.getValue().duration + 1000);
      setCurrentMomentID('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source$.getValue().played]);

  return (
    <Box position="relative">
      <BackButton label="Back to video editor" link={videoEditorUrl} />
      <Box m="22px 0 32px 0">
        <Typography variant="h1" color="textPrimary">
          Moments Editor
        </Typography>
        {canUserChangeMoment ? (
          <Box mt="15px">
            <AddButton label="New moment" onClick={handleCreateNewMoment} />
          </Box>
        ) : null}
      </Box>
      <ul>
        {showCreateMoment ? (
          <MomentsSidebarSingle
            handleCreateCompleted={handleCreateCompleted}
            isSelected={false}
            videoId={videoId}
            newMomentStyle={true}
            videoDuration={videoDuration}
            disableAutoSave={true}
            formId="new-moment"
            allMomentsTimestamps={allMomentsTimestamps}
            isLastSelected={false}
            setBulkPosition={setBulkPosition}
            initialMomentData={initialMomentData}
          />
        ) : null}
        {moments?.map((moment) => (
          <MomentsSidebarSingle
            key={moment.id}
            isSelected={selected[moment.id]}
            moment={moment}
            canUserChangeMoment={canUserChangeMoment}
            deleteMoment={deleteMoment}
            handleCheckboxClick={(id) => {
              handleCheckboxClick(id);
              updateSelectedIndexes(id);
            }}
            canUserDeleteMoment={canUserDeleteMoment}
            videoId={videoId}
            videoDuration={videoDuration}
            toggleMoment={toggleMoment}
            setSharedMoment={setSharedMoment}
            widgetApiKey={widgetApiKey}
            allMomentsTimestamps={allMomentsTimestamps}
            isLastSelected={moment.id === lastSelectedMoment}
            setBulkPosition={setBulkPosition}
            isActive={moment.id === currentMomentID && source$.getValue().playing}
          />
        ))}
      </ul>
      {selectedLength > 0 && moments?.length ? (
        <Styled.BulkWrapper bulkPosition={bulkPosition}>
          <BulkChanges
            type={BulkResource.Moment}
            count={selectedLength}
            handleDeleteClick={() => setShowDeleteModal(true)}
            selected={selected}
            items={moments}
            refetchQueries={['GetVideo']}
            canToggle={false}
            canDelete={canUserDeleteMoment}
          />
        </Styled.BulkWrapper>
      ) : null}
      {canUserDeleteMoment && (
        <DeleteModal
          type="moment"
          handleClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          count={selectedLength}
          handleDelete={bulkDelete}
        />
      )}
      <ShareOrPublishModal
        moment={sharedMoment}
        open={sharedMoment !== null}
        onClose={() => setSharedMoment(null)}
      />
    </Box>
  );
};
