import { REPROCESS_FAILED_VIDEO_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, VideoI } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  reprocessFailedVideo: Nullable<{
    video: Nullable<Pick<VideoI, 'id' | 'status'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

export const useReprocessFailedVideo = () => {
  return useMutation<ResponseData, Variables>(REPROCESS_FAILED_VIDEO_MUTATION, {
    pathToErrors: 'reprocessFailedVideo.errors',
  });
};
