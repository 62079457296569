import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ReactComponent as Chevron } from 'assets/icons/chevron-up.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Split } from 'assets/icons/split.svg';
import { Link } from 'components/common';
import { DEFAULT_TIMESTAMP_LENGTH } from 'config/constants';
import { Permission } from 'config/permissions';
import { seekToMomentStart, source$, togglePlayback } from 'context/VideoMetadataStream';
import { useCreateMoment, useUpdateMoment } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { TimeRange, VideoMomentNode } from 'models';
import { useMomentsEditorContext } from 'pages/Moments/context';
import { generateVideoMomentsLink } from 'pages/urls';
import { SetState } from 'utils/types';

import { rescaleRange } from './Timeline.utils';

import * as Styled from './TimePanel.styles';

interface OwnProps {
  videoDuration: number;
  rulerRange: TimeRange;
  setRulerRange: SetState<TimeRange>;
  playedDuration: number;
  moments: VideoMomentNode[];
}

const SCALE_VALUE = 50;
// needs to be smaller than DEFAULT_TIMESTAMP_LENGTH
const MINIMUM_RANGE_SIZE = 1000;

export function TimePanel({
  videoDuration,
  rulerRange,
  setRulerRange,
  playedDuration,
  moments,
}: OwnProps) {
  const {
    allowEditing,
    videoId,
    handleSetChangesSaved,
    currentMomentStartTime,
    currentMomentEndTime,
    setCurrentMomentEndTime,
    setCurrentMomentID,
    playSelectedMoment,
    pauseSelectedMoment,
    selectedMomentId: selectedMoment,
  } = useMomentsEditorContext();
  const [currentScale, setCurrentScale] = useState(0);
  const [isSplitDisabled, setIsSplitDisabled] = useState(true);
  const [inProgressSplitting, setInProgressSplitting] = useState(false);
  const momentsRanges = moments.map((moment) => [moment.timeRange[0], moment.timeRange[1]]);

  const [updateMoment] = useUpdateMoment();
  const [createMoment] = useCreateMoment(() => setInProgressSplitting(false));

  useEffect(() => {
    setIsSplitDisabled(
      !momentsRanges.some(
        (moment) =>
          moment[0] + DEFAULT_TIMESTAMP_LENGTH < playedDuration &&
          playedDuration < moment[1] - DEFAULT_TIMESTAMP_LENGTH,
      ),
    );
  }, [momentsRanges, playedDuration]);

  const updateScale = (newScale: number) => {
    setCurrentScale(newScale);

    const newRange = rescaleRange({
      maxRange: videoDuration,
      range: rulerRange,
      scale: newScale,
    });

    if (newRange[1] - newRange[0] > MINIMUM_RANGE_SIZE) {
      setRulerRange(newRange);
    }
  };

  const handleZoom = (action: 'plus' | 'minus') => {
    const newScale =
      action === 'plus'
        ? currentScale > 0
          ? -SCALE_VALUE
          : currentScale - SCALE_VALUE
        : currentScale < 0
        ? SCALE_VALUE
        : currentScale + SCALE_VALUE;

    updateScale(newScale);
  };

  const handleMoveTo = (direction: 'start' | 'end') => {
    const newRange =
      direction === 'start'
        ? [0, rulerRange[1] - rulerRange[0]]
        : [videoDuration - (rulerRange[1] - rulerRange[0]), videoDuration];

    setRulerRange(
      rescaleRange({
        maxRange: videoDuration,
        range: newRange as TimeRange,
        scale: 0,
      }),
    );
  };

  const handleSplitMoment = async () => {
    const timeSplitPosition = playedDuration;
    const momentToSplit = moments.find(
      (moment) =>
        moment.timeRange[0] + DEFAULT_TIMESTAMP_LENGTH < playedDuration &&
        playedDuration < moment.timeRange[1] - DEFAULT_TIMESTAMP_LENGTH,
    );

    if (momentToSplit?.id) {
      setInProgressSplitting(true);
      await updateMoment({
        variables: {
          id: momentToSplit.id,
          moment: {
            endTimestamp: timeSplitPosition,
          },
        },
      });

      await createMoment({
        variables: {
          videoId,
          moment: {
            title: `Copy - ${momentToSplit.title.trim()}`,
            description: momentToSplit.description,
            startTimestamp: timeSplitPosition + 100,
            endTimestamp: momentToSplit.timeRange[1],
            tags: momentToSplit.tags.map((tag) => tag.value),
            internalTags: momentToSplit.internalTags?.length
              ? momentToSplit.internalTags.map((tag) => tag.value)
              : undefined,
            thumbnailBase64:
              momentToSplit.thumbnails.find((thumb) => thumb.id === momentToSplit.thumbnailId)
                ?.imageBase64 || undefined,
            endCardId: momentToSplit.endCard?.id,
          },
        },
      });

      handleSetChangesSaved(true);
    }
  };

  const { hasPermissions } = useUserPermissions();

  const canUserUpdateVideo = hasPermissions([Permission.ChangeVideo]);

  useEffect(() => {
    if (source$.getValue().playing && currentMomentEndTime <= source$.getValue().played) {
      seekToMomentStart(currentMomentStartTime + 1);
      togglePlayback();
      setCurrentMomentID('');
    } else if (!source$.getValue().playing) {
      setCurrentMomentEndTime(source$.getValue().duration + 1000);
      setCurrentMomentID('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source$.getValue().played]);

  const handlePlaySelectedMoment = () => {
    const currentMoment = moments.find((moment) => moment.id === selectedMoment);

    if (currentMoment) playSelectedMoment(currentMoment);
    setCurrentMomentID(currentMoment?.id ? currentMoment?.id : '');
  };

  const handlePauseSelectedMoment = () => {
    const currentMoment = moments.find((moment) => moment.id === selectedMoment);
    if (currentMoment) pauseSelectedMoment(currentMoment);
  };

  return (
    <Styled.Wrapper>
      <Styled.TimelineHead>
        <Styled.TimelineTitle>{allowEditing ? 'Moments Editor' : 'Moments'}</Styled.TimelineTitle>
        {!allowEditing ? (
          <Button
            component={Link}
            to={generateVideoMomentsLink(videoId)}
            variant="contained"
            color="secondary"
            disabled={!canUserUpdateVideo}
            size="medium"
          >
            Edit Moments
          </Button>
        ) : null}
      </Styled.TimelineHead>
      <Styled.TimelineTools>
        <Styled.TimelineSection>
          <Styled.Button onClick={() => handleMoveTo('start')}>
            <Chevron className="start-icon" />
            Start
          </Styled.Button>
          <Styled.Button onClick={() => handleMoveTo('end')}>
            End
            <Chevron className="end-icon" />
          </Styled.Button>
          {allowEditing ? (
            <Styled.Button
              onClick={() => handleSplitMoment()}
              disabled={isSplitDisabled}
              isSplitDisabled={isSplitDisabled}
            >
              <Split className="split-icon" />
              {inProgressSplitting ? 'Splitting...' : `Split Moment on Playhead`}
            </Styled.Button>
          ) : null}
          {selectedMoment !== '' ? (
            source$.getValue().playing ? (
              <Styled.Button onClick={() => handlePauseSelectedMoment()}>Stop Moment</Styled.Button>
            ) : (
              <Styled.Button onClick={() => handlePlaySelectedMoment()}>Play Moment</Styled.Button>
            )
          ) : null}
        </Styled.TimelineSection>
        <Styled.TimelineSection>
          <Styled.Button onClick={() => handleZoom('plus')}>
            <Plus className="scale-icon" />
          </Styled.Button>
          <Styled.Button onClick={() => handleZoom('minus')}>
            <Minus className="scale-icon" />
          </Styled.Button>
        </Styled.TimelineSection>
      </Styled.TimelineTools>
    </Styled.Wrapper>
  );
}
