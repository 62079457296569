import React, { useReducer } from 'react';

import { Spinner } from 'components/common';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useCarouselsQuery } from 'hooks/query';
import { CarouselOrderBy } from 'models/CarouselQueryVariables';

import CarouselsTable from '../Tables/CarouselsTable';

import * as Styled from 'components/common/Table/Table.styles';

const Carousels = () => {
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.Widgets),
  );
  const { orderBy, sorting, setSorting } = useSorting<CarouselOrderBy>('-created');

  const { loading, data } = useCarouselsQuery({
    variables: {
      ...pagination,
      orderBy,
    },
  });

  // initial loading
  if (loading && !data?.carousels?.nodes) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (data?.carousels?.nodes) {
    const { nodes, totalCount, pageInfo } = data.carousels;
    return (
      <CarouselsTable
        carouselsLoading={loading} // subsequent loading
        carousels={nodes}
        totalCount={totalCount}
        pageInfo={pageInfo}
        dispatch={dispatch}
        sorting={sorting}
        setSorting={setSorting}
      />
    );
  }

  return <div>ERROR HERE</div>;
};

export default Carousels;
