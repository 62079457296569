import { useApolloClient } from '@apollo/react-hooks';
import React from 'react';

import { Intercom } from 'utils/intercom';
import { navigateToRoot } from 'utils/navigation';
import { LocalStorage } from 'utils/storage';

interface Options {
  redirectToRoot?: boolean;
}

export const useLogout = ({ redirectToRoot = true }: Options = {}) => {
  const client = useApolloClient();

  const logout = React.useCallback(async () => {
    LocalStorage.remove('token');
    await client.cache.reset();
    client.writeData({ data: { isLoggedIn: false, isMainBarTangled: false } });
    Intercom.shutdown();
    if (redirectToRoot) {
      navigateToRoot();
    }
  }, [client, redirectToRoot]);

  return [logout] as const;
};
