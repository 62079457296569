import { CREATE_MICROSITE_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { CreateMicrositeInput, ErrorType, Microsite } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  createMicrosite: Nullable<{
    microsite: Nullable<Microsite>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  microsite: CreateMicrositeInput;
}

export const useCreateMicrosite = () => {
  return useMutation<ResponseData, Variables>(CREATE_MICROSITE_MUTATION, {
    pathToErrors: 'createMicrosite.errors',
  });
};
