import { Except } from 'type-fest';

import { PublishVideoResponseData } from 'hooks/mutation';
import { VideoPublishStatusResponseData } from 'hooks/query';
import {
  PendingPublishingTargetPick,
  PublishingTarget,
  PublishingTargetType,
  VideoI,
} from 'models';
import { extractOperationErrors, getErrorMessage } from 'utils/errors';
import { AnyObject } from 'utils/types';

export type PublishStatus =
  | {
      ok: true;
      isInProgress: boolean;
      shareLink: string | undefined;
    }
  | {
      ok: false;
      errorMessage: string;
    };

export type PublishStatusWithMeta = {
  id: string;
  label: string;
  type: PublishingTargetType;
  ok: boolean;
  isInProgress?: boolean;
  shareLink?: string | undefined;
  scheduledPublishDate?: string;
  errorMessage?: string;
};

export type VideoPick = Pick<VideoI, 'id' | 'status' | 'title' | 'origin'>;

export interface TargetHandlerProps {
  id: string;
  video: VideoPick;
  label: string;
  isLocked?: boolean;
  isSelected: boolean;
  onSelect: (event: React.SyntheticEvent<HTMLElement>) => void;
  setValidationMessage: (message: string | undefined) => void;
}

export interface PublishTargetHandler {
  id: string;
  type: PublishingTargetType;
  label: string;
  isLocked?: boolean;
  initiallyChecked?: boolean;
  Handler: React.ComponentType<TargetHandlerProps>;
  onPublish: (targetId: string, data: PublishVideoResponseData | undefined) => PublishStatus;
  onPoll: (targetId: string, data: VideoPublishStatusResponseData) => PublishStatus;
}

export type TargetHandlerEntry = Except<PublishTargetHandler, 'id'>;

export type HandlePublishResponse = (
  data: PublishVideoResponseData,
  selectedTargets: PublishingTarget[],
) => void;

export type HandlePollResponse = (
  data: VideoPublishStatusResponseData,
  updatedTargets: PendingPublishingTargetPick[],
) => void;

export function extractErrorMessage(pathToErrors: string, data: AnyObject | undefined) {
  const operationErrors = extractOperationErrors(pathToErrors, data);
  return operationErrors ? getErrorMessage(operationErrors[0]) : undefined;
}
