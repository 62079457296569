import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { CTAsOrderBy, CTAsResponse } from 'pages/Overlays/CTAsTab/types';
import { Nullable } from 'utils/types';

export const QUERY = gql`
  query GetCTAs($orderBy: String, $search: String, $page: Int, $pageSize: Int) {
    ctas(orderBy: $orderBy, search: $search) {
      items(page: $page, pageSize: $pageSize) {
        totalCount
        nodes {
          id
          name
          created
          type
          usedInCards
          usedInVideos
          publicUuid
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          totalPages
          pageNumber
          pageSize
        }
      }
    }
  }
`;

interface ResponseData {
  ctas: {
    items: Nullable<CTAsResponse>;
  };
}

interface Variables {
  orderBy?: CTAsOrderBy;
  search: string;
}

interface UseCTAsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useCTAsQuery = (options?: UseCTAsQueryOptions) => {
  return useQuery<ResponseData, Variables>(QUERY, {
    ...options,
  });
};
