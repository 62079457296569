import { Button } from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Columns, Stack } from 'components/common/Wrapper/Layout';
import MainLayout from 'components/layouts/MainLayout';
import { useMicrositeQuery } from 'hooks/query/useMicrositeQuery';
import { usePolling } from 'hooks/usePolling';
import { generateMicrositeEditLink } from 'pages/urls';

import { getMicrositeOrigin } from '../helpers';
import { FlowStep, MicrositeLayout, MicrositeLayoutContent } from '../MicrositeLayout';

const HEALTHCHECK_INTERVAL = 60000; // 1 minute

interface RouteParams {
  micrositeId: string;
}

export const MicrositeCreated: React.VFC = () => {
  const micrositeId = useParams<RouteParams>().micrositeId;
  const { data, startPolling, stopPolling } = useMicrositeQuery({
    variables: {
      id: micrositeId,
    },
  });

  const isHealthy = data?.micrositeById?.healthy;

  usePolling({
    isEnabled: !isHealthy,
    startPolling,
    stopPolling,
    interval: HEALTHCHECK_INTERVAL,
  });

  const domain = data?.micrositeById?.domain;
  if (!domain) {
    return null;
  }

  const origin = getMicrositeOrigin(domain);

  return (
    <MainLayout>
      <MicrositeLayout currentStep={FlowStep.Complete} footer={null}>
        <MicrositeLayoutContent>
          <Stack rowGap={50} display="flex" flexDirection="column" alignItems="center">
            <Stack rowGap={20} mt="10%">
              <Title>Your microsite has been successfully created.</Title>
              <Description>
                It takes around 10 minutes to set up your microsite, in the mean time, you can
                customize it with selected videos and branding!
              </Description>
            </Stack>
            <Columns>
              <Button
                variant="outlined"
                color="secondary"
                component="a"
                href={origin}
                target="_blank"
                rel="noreferrer noopener"
                disabled={!isHealthy}
              >
                Go to your microsite
              </Button>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={generateMicrositeEditLink(micrositeId)}
                replace={true}
              >
                Customize
              </Button>
            </Columns>
          </Stack>
        </MicrositeLayoutContent>
      </MicrositeLayout>
    </MainLayout>
  );
};

const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
`;
