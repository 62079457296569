import React from 'react';

import { MicrositeLayoutContent } from 'pages/MicroSite/MicrositeLayout';

import SectionAccordion from './SectionAccordion';

const SECTIONS = ['Top section', 'Middle section', 'Bottom section'];

const SectionHeadings: React.VFC = () => {
  return (
    <MicrositeLayoutContent>
      {SECTIONS.map((sectionName, index) => (
        <SectionAccordion title={sectionName} key={sectionName} namePrefix={`sections[${index}]`} />
      ))}
    </MicrositeLayoutContent>
  );
};

export default SectionHeadings;
