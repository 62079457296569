import React from 'react';

import { InformationalModal } from 'components/common/Modals';
import { ModalProps } from 'components/common/Modals/types';

import { SetupModalDescription } from './MonetizationModal.styles';

export const SetupModal: React.VFC<ModalProps> = ({ isOpen, onClose }) => {
  const renderSetupDescription = () => (
    <SetupModalDescription>
      <p>
        You can find the link to the ad tag URL in Google Ad Manager when you create or edit an ad
        unit. Here's how to locate the link:
      </p>
      <ol>
        <li>
          <strong>Sign in</strong> to Google Ad Manager.
        </li>
        <li>
          Click <strong>Inventory &gt; Ad units.</strong>
        </li>
        <li>
          Find the ad unit for which you want to create an ad tag and click its name to see details.
        </li>
        <li>
          Select the <strong>Tags</strong> tab on the ad unit details page.
        </li>
        <li>
          Select the Tag type to:
          <strong>Google publisher tag for video and audio and press Continue.</strong>
        </li>
        <li>
          Under the <strong>Tag options</strong> section select Player SDK type to Google IMA SDK
          and press continue.
        </li>
        <li>
          In the <strong>Additional tag parameters</strong> section fill the required settings (you
          can also set the optional settings) and press Continue.
        </li>
        <li>
          Copy the <strong>Video tag URL</strong> displayed in the Tag results section.
        </li>
      </ol>
    </SetupModalDescription>
  );

  return (
    <InformationalModal
      isOpen={isOpen}
      onClose={onClose}
      heading="How do I find the ad tag URL"
      description={renderSetupDescription()}
    />
  );
};
