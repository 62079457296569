import { Uppy } from '@uppy/react/src/CommonTypes';
import { createContext, RefObject, useContext } from 'react';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';

import { FileMap } from 'components/App/UppyProvider/models';
import { ProcessingPipelineType } from 'models';

interface ProgressUpdate {
  fileId: string;
  progress: number;
}

type UpdateAction = 'upload' | 'completed' | 'canceled';

export type UppyUpdateStream =
  | {
      type: 'progress';
      value: ProgressUpdate;
    }
  | {
      type: 'action';
      value: UpdateAction;
    };

interface UppyContextI {
  uppy: Uppy;
  uploadStream: BehaviorSubject<UppyUpdateStream>;
  refMap: RefObject<FileMap>;
  cancelVideoUpload: (videoId: string) => void;
  pipelineType: ProcessingPipelineType;
  setPipelineType: (type: ProcessingPipelineType) => void;
}

export const UppyContext = createContext<UppyContextI | undefined>(undefined);

export const useUppy = () => {
  const context = useContext(UppyContext);
  if (context === undefined) {
    throw new Error('useUppy could not be used outside Uppy Provider');
  }
  return context as UppyContextI;
};

export const useUppyProgress = (id: string) => {
  const context = useUppy();
  return useObservable(() =>
    context.uploadStream.pipe(
      filter((message) => message.type === 'progress' && message.value.fileId === id),
      map((e) => (e.value as ProgressUpdate).progress),
    ),
  );
};
