import { Box, IconButton } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { ReactComponent as Repeat } from 'assets/icons/alignment/repeat.svg';
import { NumberInput } from 'components/common/NumberInput';
import { MeasurementUnit } from 'components/common/NumberInput/types';
import { extractNumbers } from 'utils/common';

import { VIDEO_PROGRESS_BAR_HEIGHT } from '../constants';
import { percentageToPx, pxToPercentage } from '../helpers';
import { CTATimestampCtasPosition } from '../type';

interface Props {
  isButton: boolean;
  position: CTATimestampCtasPosition;
  ctaSizeHeight: number;
  videoSizeHeight: number;
  updateCtaPosition: (position: Partial<CTATimestampCtasPosition>) => void;
  control: Control;
  setValue: (field: string, value: any) => void;
}

export const TopInputs: React.FC<Props> = ({
  control,
  position,
  isButton,
  ctaSizeHeight,
  videoSizeHeight,
  updateCtaPosition,
  setValue,
}) => {
  const [isTopPositionDisabled, setIsTopPositionDisabled] = useState(false);

  const height = Math.floor(videoSizeHeight) + VIDEO_PROGRESS_BAR_HEIGHT;

  const MAX_HEIGHT = height - ctaSizeHeight - VIDEO_PROGRESS_BAR_HEIGHT;

  const MIN_TOP_VALUE = isButton ? 0 : -ctaSizeHeight;
  const MAX_TOP_VALUE = isButton ? MAX_HEIGHT : MAX_HEIGHT + ctaSizeHeight;

  const setHeightValue = useCallback(
    (value: number, isOpposite?: boolean) => {
      const inputValue = percentageToPx(value, videoSizeHeight - ctaSizeHeight);

      if (isOpposite) {
        const size = MAX_HEIGHT - Math.floor(inputValue);
        return size + MeasurementUnit.Pixel;
      }
      return Math.floor(inputValue) + MeasurementUnit.Pixel;
    },
    [MAX_HEIGHT, ctaSizeHeight, videoSizeHeight],
  );

  const onTopChange = (value: string | number, isOpposite?: boolean) => {
    let updatedTop = Math.floor(extractNumbers(value));

    if (isOpposite) {
      updatedTop = MAX_HEIGHT - updatedTop;
    }

    const calculatedTop = pxToPercentage(updatedTop, videoSizeHeight - ctaSizeHeight);
    updateCtaPosition({ calculatedTop, isAlignment: false });
  };

  const reverseTopValues = () => {
    const inputValue = percentageToPx(position.calculatedTop, videoSizeHeight - ctaSizeHeight);
    const updatedTop = MAX_HEIGHT - inputValue;
    const calculatedTop = pxToPercentage(updatedTop, videoSizeHeight - ctaSizeHeight);

    setIsTopPositionDisabled((isDisabled) => !isDisabled);

    updateCtaPosition({ calculatedTop });

    // change input values
    setValue('position-top', setHeightValue(calculatedTop));
    setValue('position-bottom', setHeightValue(calculatedTop, true));
  };

  useEffect(() => {
    // change input values when alignment position is changed
    if (position.isAlignment) {
      setValue('position-top', setHeightValue(position.calculatedTop));
      setValue('position-bottom', setHeightValue(position.calculatedTop, true));
    }
  }, [position, setHeightValue, setValue]);

  useEffect(() => {
    // change input values for disabled input value
    if (!isTopPositionDisabled) {
      setValue('position-bottom', setHeightValue(position.calculatedTop, true));
    } else {
      setValue('position-top', setHeightValue(position.calculatedTop));
    }
  }, [isTopPositionDisabled, position, setHeightValue, setValue]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb="25px"
      style={{ gap: '8px' }}
    >
      <Controller
        name="position-top"
        control={control}
        defaultValue={setHeightValue(position.calculatedTop)}
        render={({ onChange, ...field }) => (
          <NumberInput
            {...field}
            onChange={(data) => {
              onChange(data);
              onTopChange(data);
            }}
            measurement={MeasurementUnit.Pixel}
            step={5}
            label="Position top *"
            info="Position top"
            min={MIN_TOP_VALUE}
            max={MAX_TOP_VALUE}
            mb="16px"
            disabled={isTopPositionDisabled}
          />
        )}
      />
      <IconButton color="inherit" onClick={reverseTopValues}>
        <Repeat />
      </IconButton>
      <Controller
        name="position-bottom"
        control={control}
        defaultValue={setHeightValue(position.calculatedTop, true)}
        render={({ onChange, ...field }) => (
          <NumberInput
            {...field}
            onChange={(data) => {
              onChange(data);
              onTopChange(data, true);
            }}
            measurement={MeasurementUnit.Pixel}
            step={5}
            label="Position bottom *"
            info="Position bottom"
            min={MIN_TOP_VALUE}
            max={MAX_TOP_VALUE}
            mb="16px"
            disabled={!isTopPositionDisabled}
          />
        )}
      />
    </Box>
  );
};
