import React from 'react';
import styled from 'styled-components/macro';

import { Stack } from 'components/common/Wrapper/Layout';
import { pushError, pushMessage } from 'context/globalStream';
import { useUserServicesQuery } from 'hooks/query/useUserServicesQuery';
import { useVideoPublications } from 'hooks/query/useVideoPublications';
import { OAuthService, PublicationTypename, VideoOrigin, YouTubePublication } from 'models';
import { createValidationMessage } from 'pages/PublishVideo/publishingTargets';
import { TargetHandlerProps } from 'pages/PublishVideo/PublishVideo.utils';

import { Target } from '../Target';
import { EditYouTubeAccount } from './EditYouTubeAccount';
import { LinkYoutubeAccount } from './LinkYouTubeAccount';
import { YouTubeOptions } from './YouTubeOptions';
import { useYouTubeOptionsContext } from './YouTubeOptionsContext';

export function PlatformHandler({
  id,
  video,
  label,
  isLocked,
  isSelected,
  onSelect,
  setValidationMessage,
}: TargetHandlerProps) {
  const { data, loading: isAccountLoading, refetch } = useUserServicesQuery({
    variables: {
      service: OAuthService.YouTube,
    },
  });

  const {
    youtubeOptions: { category },
  } = useYouTubeOptionsContext();

  const linkedAccount =
    (data?.me.authorizedServices && data?.me.authorizedServices[0]?.accountName) ?? undefined;

  React.useEffect(() => {
    setValidationMessage(undefined);
    if (isSelected) {
      if (!linkedAccount) {
        setValidationMessage(createValidationMessage(label, `Link your account before publishing`));
      } else if (category === undefined) {
        setValidationMessage(
          createValidationMessage(label, `Video category must be set before publishing`),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, category, video]);

  const handleRevoke = React.useCallback(async () => {
    const { data } = await refetch();
    const authorizedServices = data?.me.authorizedServices ?? [];

    if (authorizedServices.every(({ service }) => service !== OAuthService.YouTube)) {
      pushMessage({ message: 'YouTube account has been successfully unlinked!' });
    } else {
      pushError({ message: 'Error during unlinking your YouTube account. Try again.' });
    }
  }, [refetch]);

  const { externalPublications, isLoading: isPublicationLoading } = useVideoPublications({
    variables: {
      videoId: video.id,
    },
    fetchPolicy: 'cache-first',
  });

  const publication = externalPublications.find(
    (publication): publication is YouTubePublication =>
      publication.__typename === PublicationTypename.YouTube,
  );
  const isFresh = publication && !publication.stale;
  const isFromYoutube = video.origin === VideoOrigin.YouTube;
  const isTargetLocked = isLocked || isPublicationLoading || isFresh || isFromYoutube;

  return (
    <Target
      id={id}
      label={label}
      isLocked={isTargetLocked}
      isSelected={isSelected}
      onSelect={onSelect}
      description={
        <Description
          isFresh={isFresh}
          isFromYoutube={isFromYoutube}
          isAccountLoading={isAccountLoading}
          linkedAccount={linkedAccount}
          videoId={video.id}
          isSelected={isSelected}
        />
      }
      editButton={
        linkedAccount && !isFromYoutube ? (
          <EditYouTubeAccount onRevoke={handleRevoke} disabled={isTargetLocked} />
        ) : null
      }
    />
  );
}

interface DescriptionProps {
  isFresh: boolean | undefined;
  isFromYoutube: boolean;
  isAccountLoading: boolean;
  linkedAccount: string | undefined;
  videoId: string;
  isSelected: boolean;
}

function Description({
  isFresh,
  isFromYoutube,
  isAccountLoading,
  linkedAccount,
  videoId,
  isSelected,
}: DescriptionProps) {
  if (isFresh) {
    return <span>Most recent video version is already published</span>;
  }
  if (isFromYoutube) {
    return (
      <span>
        Due to YouTube&rsquo;s Terms &amp; Conditions, it&rsquo;s not possible to publish a Video on
        YouTube when it was uploaded from YouTube as a source.
      </span>
    );
  }
  if (isAccountLoading) {
    return <span>Loading…</span>;
  }
  if (linkedAccount) {
    return (
      <Stack rowGap="24px">
        <div>
          Linked account: <Account>{linkedAccount}</Account>
        </div>
        {isSelected && <YouTubeOptions />}
      </Stack>
    );
  }
  return <LinkYoutubeAccount videoId={videoId} />;
}

const Account = styled.em`
  font-weight: 600;
  font-style: normal;
`;
