import React, { useState } from 'react';

import { BulkChanges, DeleteModal, Search } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import PaginationFooter from 'components/PaginationFooter';
import { PostsPerPage } from 'components/PaginationFooter/constants';
import { CheckboxHeadCell, Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { PaginationAction } from 'context/pagination';
import { BulkResource } from 'context/queries/bulk';
import { useBulkDeleteMutation, useDeleteMoment, useToggleMoment } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useWidgetApiKey } from 'hooks/query/useWidgetApiKey';
import { DashboardMomentNode, PageInfo } from 'models';
import { MomentsTableSetSorting, MomentsTableSorting } from 'pages/Dashboard/MomentsTab/types';
import { Nullable } from 'utils/types';

import { ShareOrPublishModal } from '../../ShareModal';
import { MomentTableRow } from './MomentTableRow';

import * as Styled from 'components/common/Table/Table.styles';

interface OwnProps {
  momentsLoading: boolean;
  moments: DashboardMomentNode[];
  totalCount: number;
  pageInfo: Nullable<PageInfo>;
  dispatch(value: PaginationAction): void;
  sorting: MomentsTableSorting;
  setSorting: MomentsTableSetSorting;
  onSearch: (searchText: string) => void;
}

const MomentsTable = ({
  momentsLoading,
  moments,
  totalCount,
  pageInfo,
  dispatch,
  sorting,
  setSorting,
  onSearch,
}: OwnProps) => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sharedMoment, setSharedMoment] = useState<Nullable<DashboardMomentNode>>(null);

  const selectedList = Object.keys(selected).filter((key) => selected[key]);
  const selectedLength = selectedList.length;

  const [deleteMoment] = useDeleteMoment({
    onCompleted: (data) => {
      if (data.deleteMoment?.deletedMomentId) {
        dispatch({ type: 'changing' });
      }
    },
  });

  const [bulkDelete, bulkDeletingLoading] = useBulkDeleteMutation(
    BulkResource.Moment,
    selectedList,
    ['GetMoments'],
    () => {
      setSelected({});
    },
  );

  const [toggleMoment] = useToggleMoment();

  const handleCheckboxClick = React.useCallback(
    (id: string) => setSelected((prevSelected) => ({ ...prevSelected, [id]: !prevSelected[id] })),
    [],
  );

  const handleMultiCheckboxClick = () => {
    if (selectedLength > 0) {
      setSelected({});
    } else {
      const payload = moments.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {});
      setSelected(payload);
    }
  };

  const momentsLength = moments.length;

  const isLoading = momentsLoading || bulkDeletingLoading;

  const { hasPermissions } = useUserPermissions();
  const canUserToggleMoment = hasPermissions([Permission.ChangeMoment]);
  const canUserDeleteMoment = hasPermissions([Permission.DeleteMoment]);

  const hasCheckboxCell = canUserToggleMoment || canUserDeleteMoment;

  const { widgetApiKey } = useWidgetApiKey();

  return (
    <>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={isLoading} />
        <Styled.TableHorizontalScroll>
          <Table>
            <Table.Head>
              {hasCheckboxCell && (
                <CheckboxHeadCell
                  checked={momentsLength > 0 && selectedLength > 0}
                  onChange={handleMultiCheckboxClick}
                  color="secondary"
                />
              )}
              <Table.HeadCell width="100%">
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="title">
                  Moment Title
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface
                  activeSorting={sorting}
                  setSorting={setSorting}
                  columnName="videoTitle"
                >
                  Video Title
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="source">
                  Created By
                </SortInterface>
              </Table.HeadCell>
              {canUserToggleMoment && (
                <Table.HeadCell>
                  <SortInterface
                    activeSorting={sorting}
                    setSorting={setSorting}
                    columnName="visible"
                  >
                    Visibility
                  </SortInterface>
                </Table.HeadCell>
              )}
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
                  Date Added
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {moments.map((moment) => (
                <MomentTableRow
                  key={moment.id}
                  moment={moment}
                  isSelected={selected[moment.id] || false}
                  hasCheckboxCell={hasCheckboxCell}
                  handleCheckboxClick={handleCheckboxClick}
                  canToggleMoment={canUserToggleMoment}
                  toggleMoment={toggleMoment}
                  canDeleteMoment={canUserDeleteMoment}
                  deleteMoment={deleteMoment}
                  setSharedMoment={setSharedMoment}
                  widgetApiKey={widgetApiKey}
                />
              ))}
            </Table.Body>
            <PaginationFooter
              totalCount={totalCount}
              visibleCount={moments.length}
              pageInfo={pageInfo}
              dispatch={dispatch}
              tableType={PostsPerPage.DashboardMoments}
            />
          </Table>
        </Styled.TableHorizontalScroll>
        <Styled.ActionsContainer>
          {selectedLength > 0 ? (
            <BulkChanges
              type={BulkResource.Moment}
              count={selectedLength}
              handleDeleteClick={() => setShowDeleteModal(true)}
              selected={selected}
              items={moments}
              refetchQueries={['GetMoments']}
              canToggle={canUserToggleMoment}
              canDelete={canUserDeleteMoment}
            />
          ) : (
            <Search placeholder="Search moments" onSearch={onSearch} />
          )}
        </Styled.ActionsContainer>
      </Styled.TableWrapper>
      {canUserToggleMoment && (
        <DeleteModal
          type="moment"
          handleClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          count={selectedLength}
          handleDelete={bulkDelete}
        />
      )}
      <ShareOrPublishModal
        moment={sharedMoment}
        open={sharedMoment !== null}
        onClose={() => setSharedMoment(null)}
      />
    </>
  );
};

export default MomentsTable;
