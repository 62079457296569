import { Box, Button, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  BackButton,
  FormLabel,
  InfoTooltip,
  Link,
  TextInput,
  Toggle,
  Typography,
} from 'components/common';
import AlignmentInput from 'components/common/AlignmentInput/AlignmentInput';
import CustomColor from 'components/common/CustomColor';
import { NumberInput } from 'components/common/NumberInput';
import { MeasurementUnit } from 'components/common/NumberInput/types';
import { UploadInput } from 'components/common/UploadInput';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { useCTAQuery } from 'hooks/query/useCTAQuery';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { CTAItemType } from 'pages/Overlays/CTAsTab/types';
import { URLS } from 'pages/urls';
import { getAllFontFamilyOptions } from 'utils/fonts';
import {
  exceedsMaxAllowedFileSize,
  isBiggerThan,
  isValueBetween,
  validateColor,
} from 'utils/validation';

import {
  ACCEPTED_FORMATS_ERROR_MESSAGE,
  ACCEPTED_IMAGE_FORMATS,
  cardSizeOptionsHorizontal,
  cardSizeOptionsVerical,
  MAX_IMAGE_SIZE,
  MAX_SIZE_ERROR_MESSAGE,
  orientationOptions,
  VALIDATION_FILE_MESSAGE,
} from './const';
import { EndCardPreview } from './EditEndCardPreview';
import { ButtonTemplateSelect } from './inputs/ButtonTemplateSelect';
import * as Styled from './styled';
import { EditEndCardProps, EndCardFormData, OrientationOptions } from './types';

const selectStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: '15px',
    height: '56px',
  },
}));

interface UseLocationProps {
  destinationURL?: string;
  backButtonPlaceholder?: string;
}

export const EditEndCardView: React.FC<EditEndCardProps> = ({
  endCard,
  handleSave,
  isLoading = false,
}) => {
  const fontFamilyOptions = useMemo(() => getAllFontFamilyOptions(), []);

  const [showPromoCode, setShowPromoCode] = useState(!!endCard?.promoCodeButton);
  const [showImage, setShowImage] = useState(!!endCard?.backgroundImageUrl || false);
  const [showSubline, setShowSubline] = useState(!!endCard?.subline?.text || false);
  const [showButton1, setShowButton1] = useState(!!endCard?.ctaButton1 || false);
  const [showButton2, setShowButton2] = useState(!!endCard?.ctaButton2 || false);
  const [showNextMomentBtn, setShowNextMomentBtn] = useState(!!endCard?.watchNextButtonVisible);
  const FORM_ID = 'edit-end-card';
  const { state } = useLocation<UseLocationProps>();
  const backButtonURL = state?.destinationURL || URLS.dashboard.root;
  const backButtonPlaceholder = state?.backButtonPlaceholder || 'dashboard';

  const { data } = useOrganizationQuery();
  const socialMedia = data?.me?.organization?.socialMedia;

  const { data: ctaData } = useCTAQuery({
    variables: {
      search: '',
      typeFilter: CTAItemType.Button,
    },
  });

  const ctaButtonOptions = useMemo(() => {
    const { nodes: ctas = [] } = ctaData?.ctas?.items || {};
    return ctas.map((cta) => ({ label: cta.name, value: cta.id }));
  }, [ctaData]);

  const {
    handleSubmit,
    register,
    errors,
    watch,
    control,
    setError,
    clearErrors,
  } = useForm<EndCardFormData>({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const cardOrientation = watch('cardOrientation', endCard?.cardOrientation);
  const cardSize = watch('cardSize', endCard?.cardSize);
  const overlay = watch('overlay', endCard?.overlay);
  const background = watch('background', endCard?.background);
  const backgroundImageSettings = watch(
    'backgroundImageSettings',
    endCard?.backgroundImageSettings,
  );
  const border = watch('border', endCard?.border);
  const videoControlButtons = watch('videoControlButtons', endCard?.videoControlButtons);
  const headline = watch('headline', endCard?.headline);
  const subline = watch('subline', endCard?.subline);
  const promoCodeButton = watch('promoCodeButton', endCard?.promoCodeButton);
  const backgroundImageUrl = watch('backgroundImageUrl', endCard?.backgroundImageUrl);

  const handleFileValidation = React.useCallback(
    async (file: File) => {
      if (exceedsMaxAllowedFileSize(MAX_IMAGE_SIZE, file.size)) {
        setError('backgroundImageUrl', { message: MAX_SIZE_ERROR_MESSAGE });
        return false;
      }

      if (file.name) {
        const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
        if (!ACCEPTED_IMAGE_FORMATS.includes(fileExtension)) {
          setError('backgroundImageUrl', { message: ACCEPTED_FORMATS_ERROR_MESSAGE });
          return false;
        }
      }

      clearErrors('backgroundImageUrl');
      return true;
    },
    [setError, clearErrors],
  );

  const cardSizeOptions = useMemo(() => {
    if (cardOrientation === OrientationOptions.HORIZONTAL) {
      return cardSizeOptionsHorizontal;
    }

    return cardSizeOptionsVerical;
  }, [cardOrientation]);

  const watchPromoCode = watch('promoCode', endCard?.promoCodeButton?.label);
  const watchPromoCodeText = watch('promoCodeText', endCard?.promoCodeButton?.text);
  const ctaButton1 = watch('ctaButton1', endCard?.ctaButton1?.id);
  const ctaButton2 = watch('ctaButton2', endCard?.ctaButton2?.id);

  const selectClasses = selectStyles();

  const handleCreate = (data: EndCardFormData) => {
    handleSave(data, showImage, showNextMomentBtn, showPromoCode);
  };

  const handleButton1Toggle = () => setShowButton1((show) => !show);
  const handleButton2Toggle = () => setShowButton2((show) => !show);

  const handleImageToggle = () => setShowImage((show) => !show);

  return (
    <ExtensibleSidebar
      sideContent={
        <form id={FORM_ID} onSubmit={handleSubmit(handleCreate)}>
          <BackButton label={`Back to ${backButtonPlaceholder}`} link={backButtonURL} />
          <Box m="22px 0 32px 0">
            <Typography variant="h1" color="textPrimary">
              End Card Visual Builder
            </Typography>
          </Box>
          <TextInput
            label="Template name*"
            name="name"
            placeholder="Type template name"
            defaultValue={endCard?.name}
            ref={register({ required: true })}
            error={!!errors?.name}
            errorMessage={errors.name?.message}
            maxLength={200}
          />
          <FormLabel id="cardOrientation">
            Card orientation* <InfoTooltip content="Select the card orientation" placement="top" />
          </FormLabel>
          <Box display="flex" mb="25px">
            <Controller
              render={({ onChange, ...props }) => (
                <Select
                  variant="outlined"
                  className={selectClasses.root}
                  onChange={onChange}
                  {...props}
                >
                  {orientationOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              name="cardOrientation"
              defaultValue={endCard?.cardOrientation || orientationOptions[0].value}
            />
          </Box>
          <FormLabel id="cardSize">
            Card size* <InfoTooltip content="Select the size of the card" placement="top" />
          </FormLabel>
          <Box display="flex" mb="25px">
            <Controller
              render={({ onChange, ...props }) => (
                <Select
                  variant="outlined"
                  className={selectClasses.root}
                  onChange={onChange}
                  {...props}
                >
                  {cardSizeOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              name="cardSize"
              defaultValue={endCard?.cardSize || cardSizeOptions[0].value}
            />
          </Box>
          <FormLabel>
            Overlay*
            <InfoTooltip
              content="The overlay beneath the card helps with the visibility"
              placement="top"
            />
          </FormLabel>
          <Box
            display="flex"
            flexDirection="column"
            p="15px 20px"
            mb="15px"
            border="1px solid #e6e6e6"
            borderRadius="10px"
          >
            <Stack>
              <Controller
                name="overlay.color"
                control={control}
                defaultValue={endCard?.overlay?.color || '#000000'}
                rules={{ validate: validateColor }}
                render={({ value, onChange }) => (
                  <CustomColor
                    onColorChange={onChange}
                    selectedColor={value}
                    label="Overlay color"
                    mb="40px"
                    error={!!errors.overlay?.color}
                    errorMessage={errors.overlay?.color?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="overlay.opacity"
                defaultValue={endCard?.overlay?.opacity || '90%'}
                rules={{ validate: isValueBetween(80, 100) }}
                render={(field) => (
                  <NumberInput
                    {...field}
                    measurement={MeasurementUnit.Percentage}
                    label="Overlay opacity"
                    step={5}
                    error={!!errors.overlay?.opacity?.message}
                    min={80}
                    max={100}
                    validationMessage="Min. 80%"
                  />
                )}
              />
            </Stack>
          </Box>
          <FormLabel>Card background*</FormLabel>
          <Box
            display="flex"
            flexDirection="column"
            p="15px 20px"
            mb="15px"
            border="1px solid #e6e6e6"
            borderRadius="10px"
          >
            <Stack>
              <Controller
                name="background.color"
                control={control}
                defaultValue={endCard?.background?.color || '#FFFFFF'}
                rules={{ validate: validateColor }}
                render={({ value, onChange }) => (
                  <CustomColor
                    onColorChange={onChange}
                    selectedColor={value}
                    label="Background color"
                    mb="40px"
                    error={!!errors.background?.color}
                    errorMessage={errors.background?.color?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="background.opacity"
                defaultValue={endCard?.background?.opacity || '90%'}
                rules={{ validate: isValueBetween(80, 100) }}
                render={(field) => (
                  <NumberInput
                    {...field}
                    measurement={MeasurementUnit.Percentage}
                    label="Background opacity"
                    step={5}
                    error={!!errors.background?.opacity?.message}
                    min={80}
                    max={100}
                    validationMessage="Min. 80%"
                  />
                )}
              />
            </Stack>
          </Box>
          <Styled.InlineCheckbox style={{ marginBottom: '15px' }}>
            <FormLabel className="checkbox-label">Background image</FormLabel>
            <Toggle checked={showImage} color="primary" onChange={handleImageToggle} />
          </Styled.InlineCheckbox>
          {showImage && (
            <Box p="15px 20px" border="1px solid #e6e6e6" borderRadius="10px" mt="20px" mb="15px">
              <Stack>
                <Controller
                  name="backgroundImageUrl"
                  control={control}
                  rules={{ required: 'Image cannot be empty', validate: handleFileValidation }}
                  defaultValue={endCard?.backgroundImageUrl || ''}
                  render={(field) => (
                    <UploadInput
                      {...field}
                      label="Image *"
                      onFileValidation={handleFileValidation}
                      error={!!errors.backgroundImageUrl}
                      errorMessage={errors.backgroundImageUrl?.message}
                      validationMessage={VALIDATION_FILE_MESSAGE}
                      mb="40px"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="backgroundImageSettings.size"
                  defaultValue={endCard?.backgroundImageSettings?.size || '100%'}
                  render={(field) => (
                    <NumberInput
                      {...field}
                      measurement={MeasurementUnit.Percentage}
                      label="Image size"
                      step={5}
                      error={!!errors.backgroundImageSettings?.size?.message}
                      min={0}
                      max={100}
                    />
                  )}
                />
                <AlignmentInput
                  defaultHorizontalValue={endCard?.backgroundImageSettings?.horizontal}
                  defaultVerticalValue={endCard?.backgroundImageSettings?.vertical}
                  control={control}
                  fieldName="backgroundImageSettings"
                />
              </Stack>
            </Box>
          )}
          <FormLabel>Card border</FormLabel>
          <Box
            display="flex"
            flexDirection="column"
            p="15px 20px"
            mb="15px"
            border="1px solid #e6e6e6"
            borderRadius="10px"
          >
            <Stack>
              <Controller
                control={control}
                name="border.width"
                defaultValue={endCard?.border?.width || '0px'}
                render={(field) => (
                  <NumberInput
                    {...field}
                    measurement={MeasurementUnit.Pixel}
                    label="Border width"
                    step={1}
                    error={!!errors.border?.width?.message}
                    min={0}
                    max={20}
                    validationMessage="Max. 20px"
                  />
                )}
              />
              <Controller
                name="border.color"
                control={control}
                defaultValue={endCard?.border?.color || '#000000'}
                rules={{ validate: validateColor }}
                render={({ value, onChange }) => (
                  <CustomColor
                    onColorChange={onChange}
                    selectedColor={value}
                    label="Border color"
                    mb="40px"
                    error={!!errors.border?.color}
                    errorMessage={errors.border?.color?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="border.radius"
                defaultValue={endCard?.border?.radius || '15px'}
                render={(field) => (
                  <NumberInput
                    {...field}
                    measurement={MeasurementUnit.Pixel}
                    label="Border radius"
                    step={1}
                    error={!!errors.border?.radius?.message}
                    max={20}
                    min={0}
                  />
                )}
              />
            </Stack>
          </Box>
          <FormLabel>
            Video controls buttons
            <InfoTooltip content="Round icon buttons displayed on the End Card" placement="top" />
          </FormLabel>
          <Box
            display="flex"
            flexDirection="column"
            p="15px 20px"
            mb="15px"
            border="1px solid #e6e6e6"
            borderRadius="10px"
          >
            <Stack>
              <Controller
                name="videoControlButtons.iconsColor"
                control={control}
                defaultValue={endCard?.videoControlButtons?.iconsColor || '#000000'}
                rules={{ validate: validateColor }}
                render={({ value, onChange }) => (
                  <CustomColor
                    onColorChange={onChange}
                    selectedColor={value}
                    label="Icon color"
                    mb="40px"
                    error={!!errors.videoControlButtons?.iconsColor}
                    errorMessage={errors.videoControlButtons?.iconsColor?.message}
                  />
                )}
              />
              <Controller
                name="videoControlButtons.backgroundColor"
                control={control}
                defaultValue={endCard?.videoControlButtons?.backgroundColor || '#E6E6E6'}
                rules={{ validate: validateColor }}
                render={({ value, onChange }) => (
                  <CustomColor
                    onColorChange={onChange}
                    selectedColor={value}
                    label="Background color"
                    mb="40px"
                    error={!!errors.videoControlButtons?.backgroundColor}
                    errorMessage={errors.videoControlButtons?.backgroundColor?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="videoControlButtons.backgroundOpacity"
                defaultValue={endCard?.videoControlButtons?.backgroundOpacity || '90%'}
                render={(field) => (
                  <NumberInput
                    {...field}
                    measurement={MeasurementUnit.Percentage}
                    label="Background opacity"
                    step={5}
                    error={!!errors.videoControlButtons?.backgroundOpacity?.message}
                  />
                )}
              />
            </Stack>
          </Box>
          <FormLabel>Headline *</FormLabel>
          <Box
            display="flex"
            flexDirection="column"
            p="15px 20px"
            mb="15px"
            border="1px solid #e6e6e6"
            borderRadius="10px"
          >
            <TextInput
              label="Text *"
              name="headline.text"
              placeholder="Placeholder"
              defaultValue={endCard?.headline?.text || ''}
              ref={register({ required: true })}
              error={!!errors?.headline}
              errorMessage={errors.headline?.text?.message}
              maxLength={200}
            />
            <FormLabel>
              Font family
              <InfoTooltip
                content="Please choose a Google font that aligns well with your brand identity"
                placement="top"
              />
            </FormLabel>
            <Controller
              defaultValue={endCard?.headline?.font || null}
              render={({ onChange, value, ...props }) => (
                <Autocomplete
                  options={fontFamilyOptions}
                  className={selectClasses.root}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  onChange={(e, data) => onChange(data)}
                  value={value}
                  {...props}
                />
              )}
              name="headline.font"
              control={control}
            />
            <Controller
              control={control}
              name="headline.fontSize"
              defaultValue={endCard?.headline?.fontSize || '24px'}
              rules={{ validate: isBiggerThan(10) }}
              render={(field) => (
                <NumberInput
                  {...field}
                  measurement={MeasurementUnit.Pixel}
                  label="Font size"
                  step={1}
                  error={!!errors.headline?.fontSize?.message}
                  validationMessage="Recommended value: from 14px to 36px"
                  min={10}
                />
              )}
            />
            <Controller
              name="headline.color"
              control={control}
              defaultValue={endCard?.headline?.color || '#000000'}
              rules={{ validate: validateColor }}
              render={({ value, onChange }) => (
                <CustomColor
                  onColorChange={onChange}
                  selectedColor={value}
                  label="Font color"
                  mb="40px"
                  error={!!errors.headline?.color}
                  errorMessage={errors.headline?.color?.message}
                />
              )}
            />
          </Box>
          <Styled.InlineCheckbox style={{ marginBottom: '15px' }}>
            <FormLabel className="checkbox-label">Subline</FormLabel>
            <Toggle
              checked={showSubline}
              color="primary"
              onChange={() => {
                setShowSubline(!showSubline);
              }}
            />
          </Styled.InlineCheckbox>
          {showSubline && (
            <Box
              display="flex"
              flexDirection="column"
              p="15px 20px"
              mb="15px"
              border="1px solid #e6e6e6"
              borderRadius="10px"
            >
              <TextInput
                label="Text *"
                name="subline.text"
                placeholder="Placeholder"
                defaultValue={endCard?.subline?.text || ''}
                ref={register({ required: true })}
                error={!!errors?.subline}
                errorMessage={errors.subline?.text?.message}
                maxLength={200}
              />
              <FormLabel>Font family</FormLabel>
              <Controller
                defaultValue={endCard?.subline?.font || null}
                render={({ onChange, value, ...props }) => (
                  <Autocomplete
                    options={fontFamilyOptions}
                    className={selectClasses.root}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    onChange={(e, data) => onChange(data)}
                    value={value}
                    {...props}
                  />
                )}
                name="subline.font"
                control={control}
              />
              <Controller
                control={control}
                name="subline.fontSize"
                defaultValue={endCard?.subline?.fontSize || '24px'}
                rules={{ validate: isBiggerThan(10) }}
                render={(field) => (
                  <NumberInput
                    {...field}
                    measurement={MeasurementUnit.Pixel}
                    label="Font size"
                    step={1}
                    error={!!errors.headline?.fontSize?.message}
                    validationMessage="Recommended value: from 10px to 16px"
                    min={10}
                  />
                )}
              />
              <Controller
                name="subline.color"
                control={control}
                defaultValue={endCard?.subline?.color || '#000000'}
                rules={{ validate: validateColor }}
                render={({ value, onChange }) => (
                  <CustomColor
                    onColorChange={onChange}
                    selectedColor={value}
                    label="Font color"
                    mb="40px"
                    error={!!errors.subline?.color}
                    errorMessage={errors.subline?.color?.message}
                  />
                )}
              />
            </Box>
          )}
          <ButtonTemplateSelect
            name="ctaButton1"
            visible={showButton1}
            options={ctaButtonOptions}
            label="Button 1"
            onToggle={handleButton1Toggle}
            control={control}
            defaultValue={endCard?.ctaButton1?.id || ''}
          />
          <ButtonTemplateSelect
            name="ctaButton2"
            visible={showButton2}
            options={ctaButtonOptions}
            label="Button 2"
            onToggle={handleButton2Toggle}
            control={control}
            defaultValue={endCard?.ctaButton2?.id || ''}
          />
          <Styled.InlineCheckbox>
            <FormLabel className="checkbox-label">Promocode</FormLabel>
            <Toggle
              checked={showPromoCode}
              color="primary"
              onChange={() => {
                setShowPromoCode(!showPromoCode);
              }}
            />
          </Styled.InlineCheckbox>
          {showPromoCode && (
            <Box p="15px 20px" border="1px solid #e6e6e6" borderRadius="10px" mt="20px">
              <TextInput
                label="Promo code text"
                name="promoCodeButton.text"
                placeholder="Buy now"
                defaultValue={endCard?.promoCodeButton?.text || ''}
                ref={register()}
                error={!!errors?.promoCodeText}
                errorMessage={errors.promoCodeText?.message}
                mb={'20px'}
                maxLength={30}
              />
              <TextInput
                label="Promo code"
                name="promoCodeButton.label"
                placeholder="Buy now"
                defaultValue={endCard?.promoCodeButton?.label || ''}
                ref={register()}
                error={!!errors?.promoCode}
                errorMessage={errors.promoCode?.message}
                mb={'10px'}
                maxLength={30}
              />
            </Box>
          )}
          <Styled.InlineCheckbox>
            <FormLabel className="checkbox-label">Watch next Moment button</FormLabel>
            <Toggle
              checked={showNextMomentBtn}
              color="primary"
              onChange={() => {
                setShowNextMomentBtn(!showNextMomentBtn);
              }}
            />
          </Styled.InlineCheckbox>
        </form>
      }
      mainContent={
        <>
          <Columns display="flex" alignItems="center" justifyContent="flex-end" pb="20px">
            {isLoading && <Typography variant="h6">{'Saving...'}</Typography>}
            <Button
              component={Link}
              to={URLS.overlays.endCards}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!!isLoading}
              color="primary"
              form={FORM_ID}
              type="submit"
            >
              Save
            </Button>
          </Columns>
          <EndCardPreview
            overlay={overlay}
            background={background}
            cardType={endCard?.cardType || ''}
            backgroundImageSettings={backgroundImageSettings}
            border={border}
            videoControlButtons={videoControlButtons}
            headline={headline}
            // @ts-ignore
            subline={showSubline ? subline : null}
            promoCodeButton={showPromoCode ? promoCodeButton : null}
            backgroundImageUrl={showImage ? backgroundImageUrl : ''}
            name={endCard?.name || ''}
            cardOrientation={cardOrientation}
            cardSize={cardSize}
            promoCodeText={showPromoCode ? watchPromoCodeText : ''}
            promoCode={showPromoCode ? watchPromoCode : ''}
            watchNextButtonVisible={showNextMomentBtn}
            socialMedia={socialMedia || []}
            ctaButton1={showButton1 ? ctaButton1 : ''}
            ctaButton2={showButton2 ? ctaButton2 : ''}
          />
        </>
      }
    />
  );
};
