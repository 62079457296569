import { ExecutionResult, MutationFunctionOptions } from '@apollo/react-common';
import { MutationHookOptions, useMutation as useApolloMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import React from 'react';

import { ErrorMap, extractOperationErrors, getErrorMessage } from 'utils/errors';
import { AnyObject } from 'utils/types';

export interface UseMutationOptions<TData = any, TVariables = AnyObject>
  extends MutationHookOptions<TData, TVariables> {
  // TODO: make pathToErrors required, as it stores information about general errors, such as missing auth or permissions
  pathToErrors?: string;
  skipGlobalErrorHandling?: boolean;
  errorMap?: ErrorMap;
}

type MutationFn<TData, TVariables> = (
  options?: MutationFunctionOptions<TData, TVariables> | undefined,
) => Promise<
  ExecutionResult<TData> & {
    operationErrors?: string[];
  }
>;

/**
 * Wrapper for Apollo's `useMutation`.
 *
 * It uses global error handling by default. You can opt out of this behavior using
 * `skipGlobalErrorHandling: true` option.
 */
export function useMutation<TData = any, TVariables = AnyObject>(
  mutation: DocumentNode,
  {
    pathToErrors,
    skipGlobalErrorHandling = false,
    errorMap,
    ...mutationOptions
  }: UseMutationOptions<TData, TVariables> = {},
) {
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const resetErrorMessages = React.useCallback(() => {
    setErrorMessages([]);
  }, []);

  const [rawMutate, mutationResults] = useApolloMutation(mutation, {
    ...mutationOptions,
    context: {
      pathToErrors,
      isErrorHandledLocally: skipGlobalErrorHandling,
      errorMap,
      ...mutationOptions.context,
    },
  });

  const mutate = React.useCallback<MutationFn<TData, TVariables>>(
    async (...args) => {
      try {
        const result = await rawMutate(...args);
        let messages: string[] | undefined;
        if (pathToErrors) {
          const operationErrors = extractOperationErrors(pathToErrors, result.data);
          if (operationErrors) {
            messages = operationErrors.map((error) => {
              return getErrorMessage(error, errorMap);
            });
            setErrorMessages(messages);
          }
        }
        return {
          ...result,
          operationErrors: messages,
        };
      } catch (error) {
        // empty catching is required until this is resolved: https://github.com/apollographql/apollo-client/issues/6070
        return {};
      }
    },
    [errorMap, pathToErrors, rawMutate],
  );

  return [mutate, mutationResults, errorMessages, resetErrorMessages] as const;
}
