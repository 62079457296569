import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

import { FormLabel } from 'components/common';
import { useYouTubeCategoriesQuery } from 'hooks/query/useYouTubeCategoriesQuery';

export interface Option {
  value: number;
  label: string;
}

interface Props {
  selectedCategoryId: number | undefined;
  onChange: (categoryId: number | undefined) => void;
}

export function YouTubeCategoryPicker({ selectedCategoryId, onChange }: Props) {
  const { availableCategories, loading } = useYouTubeCategoriesQuery({
    variables: {},
  });

  return (
    <div>
      <FormLabel>Video Category</FormLabel>
      <Autocomplete
        style={{ width: '100%' }}
        options={availableCategories}
        getOptionLabel={(option) => option.title}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        renderInput={(params) => {
          return <TextField {...params} variant="outlined" placeholder="Select category..." />;
        }}
        value={availableCategories.find(({ id }) => id === selectedCategoryId) || null}
        onChange={(e, data, reason) => {
          if (reason === 'clear') {
            onChange(undefined);
          } else if (data) {
            onChange(data.id);
          }
        }}
        disabled={loading}
      />
    </div>
  );
}
