import {
  makeStyles,
  Select as MaterialSelect,
  SelectProps as MaterialSelectProps,
} from '@material-ui/core';
import React from 'react';

import * as Styled from './Select.styles';

const selectStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

interface Option<TValue extends string> {
  label: string;
  value: TValue;
  disabled?: boolean;
}

interface SelectProps<TValue extends string> {
  id: string;
  label?: string;
  options: Option<TValue>[];
}

function Select<TValue extends string>({
  id,
  label,
  options,
  ...selectProps
}: SelectProps<TValue> & MaterialSelectProps) {
  const selectClasses = selectStyles();

  return (
    <div>
      {label && <Styled.Label id={id}>{label}</Styled.Label>}
      <MaterialSelect
        labelId={id}
        className={selectClasses.root}
        inputProps={{ 'aria-label': label }}
        native
        variant="outlined"
        {...selectProps}
      >
        {options.map(({ label, value, disabled }) => (
          <option key={value} value={value} disabled={disabled}>
            {label}
          </option>
        ))}
      </MaterialSelect>
    </div>
  );
}

export default Select;
