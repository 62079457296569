import { lightTheme } from '@entertainment-ai/react-component-library';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Play } from 'assets/icons/play.svg';

export const VideoBox: React.VFC = () => {
  return (
    <Box>
      <Wrapper>
        <StyledPlay />
      </Wrapper>
    </Box>
  );
};

const StyledPlay = styled(Play)`
  width: 15%;
  height: 15%;
  min-width: 24px;
  min-height: 24px;
`;

const Box = styled.div`
  position: relative;
  background-color: grey;
  border-radius: ${lightTheme.gridBorderRadius};
  padding-bottom: 56.25%; // aspect ratio 16:9
  height: 0;
  color: #fff;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
