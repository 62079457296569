import { Button } from '@material-ui/core';
import React from 'react';

import { Search } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import { Columns } from 'components/common/Wrapper/Layout';
import PaginationFooter from 'components/PaginationFooter';
import { PostsPerPage } from 'components/PaginationFooter/constants';
import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { PaginationAction } from 'context/pagination';
import { SetSorting, Sorting } from 'context/sorting';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import useElementSize from 'hooks/useElementSize';
import { useSafeState } from 'hooks/useSafeState';
import { OrganizationMembersOrderBy, PageInfo, UserRole } from 'models';
import { Nullable } from 'utils/types';

import { ActionContentWidthHandler } from '../types';
import { InviteMemberModal } from './InviteMemberModal';
import { MemberTableRow } from './MemberTableRow';
import { OrganizationMemberPick } from './useOrganizationMembers';
import { useToggleOrganizationMember } from './useToggleOrganizationMember';

import * as Styled from 'components/common/Table/Table.styles';

interface Props {
  membersLoading: boolean;
  members: OrganizationMemberPick[];
  totalCount: number;
  pageInfo: Nullable<PageInfo>;
  dispatch: (value: PaginationAction) => void;
  sorting: Sorting<OrganizationMembersOrderBy>;
  setSorting: SetSorting<OrganizationMembersOrderBy>;
  onSearch: (searchText: string) => void;
  loggedInUserId?: string;
}

export const MemberTable: React.FC<Props & ActionContentWidthHandler> = ({
  membersLoading: isLoading,
  members,
  totalCount,
  pageInfo,
  dispatch,
  sorting,
  setSorting,
  onSearch,
  loggedInUserId,
  getActionContentWidth,
}) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useSafeState(false);
  const { elementRef } = useElementSize(getActionContentWidth);

  const { hasPermissions } = useUserPermissions();
  const canEditMembers = hasPermissions([Permission.ChangeOrganizationMembers]);
  const canInviteMembers = hasPermissions([Permission.InviteOrganizationMembers]);

  const [toggleMemberAccount] = useToggleOrganizationMember();

  return (
    <>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={isLoading} />
        <Styled.TableHorizontalScroll>
          <Table>
            <Table.Head>
              <Table.HeadCell />
              <Table.HeadCell width="50%">
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="name">
                  Name
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell width="50%">
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="email">
                  Email
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="role">
                  Role
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
                  Date Created
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell align="center">
                <SortInterface
                  activeSorting={sorting}
                  setSorting={setSorting}
                  columnName="isActive"
                >
                  Active
                </SortInterface>
              </Table.HeadCell>
              {canEditMembers && <Table.HeadCell>Actions</Table.HeadCell>}
            </Table.Head>
            <Table.Body>
              {members.map((member) => {
                return (
                  <MemberTableRow
                    key={member.id}
                    member={member}
                    onAccountToggle={toggleMemberAccount}
                    isCurrentlyLoggedInUser={member.id === loggedInUserId}
                    isOrganizationOwner={member.role === UserRole.OrgOwner}
                  />
                );
              })}
            </Table.Body>
            <PaginationFooter
              totalCount={totalCount}
              visibleCount={members.length}
              pageInfo={pageInfo}
              dispatch={dispatch}
              tableType={PostsPerPage.OrganizationMembers}
            />
          </Table>
        </Styled.TableHorizontalScroll>
        <Styled.ActionsContainer ref={elementRef}>
          <Columns display="flex" alignItems="center">
            <Search placeholder="Search members" onSearch={onSearch} />
            {canInviteMembers && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsInviteModalOpen(true)}
              >
                Invite member
              </Button>
            )}
          </Columns>
        </Styled.ActionsContainer>
      </Styled.TableWrapper>
      {canInviteMembers && (
        <InviteMemberModal isOpen={isInviteModalOpen} onClose={() => setIsInviteModalOpen(false)} />
      )}
    </>
  );
};
