import React from 'react';

import { FormLabel } from 'components/common';
import Select from 'components/common/Select';
import { Columns } from 'components/common/Wrapper/Layout';
import { ProcessingPipelineType } from 'models';

const options = [
  { value: ProcessingPipelineType.Default, label: 'Full' },
  { value: ProcessingPipelineType.Basic, label: 'Basic' },
];

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (type: ProcessingPipelineType) => void;
  disabled?: boolean;
}

export const ProcessingPipelineDropdown = React.forwardRef<any, Props>(
  function ProcessingPipelineDropdown({ id, name, value, onChange, disabled }, ref) {
    return (
      <Columns display="flex" alignItems="center" columnGap="14px">
        <FormLabel>Processing pipeline</FormLabel>
        <Select
          innerRef={ref}
          id={id}
          name={name}
          options={options}
          value={value}
          onChange={({ currentTarget }) => {
            onChange(currentTarget.value as ProcessingPipelineType);
          }}
          disabled={disabled}
        />
      </Columns>
    );
  },
);
