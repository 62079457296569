import React from 'react';

import { Spinner } from 'components/common';

import * as Styled from './Table.styles';

interface OwnProps {
  isVisible: boolean;
}

function LoadingOverlay({ isVisible }: OwnProps) {
  return (
    <Styled.TableOverlayBox isVisible={isVisible}>
      <Spinner />
    </Styled.TableOverlayBox>
  );
}

export default LoadingOverlay;
