import { Box, Button, InputAdornment, OutlinedInputProps, TooltipProps } from '@material-ui/core';
import React, { ChangeEvent, FocusEvent, useCallback } from 'react';

import { ReactComponent as Minus } from 'assets/icons/minus-circle.svg';
import { ReactComponent as Plus } from 'assets/icons/plus-circle.svg';
import { FormLabel } from 'components/common/FormLabel';
import { InfoTooltip, Placement } from 'components/common/InfoTooltip';
import { extractNumbers } from 'utils/common';

import { formatNumericValue } from './helpers';
import { MeasurementUnit } from './types';

import * as Styled from './NumberInput.styles';

export interface NumberInputProps extends Omit<OutlinedInputProps, 'onChange'> {
  label?: string;
  name: string;
  disabled?: boolean;
  value: string | number;
  step?: number;
  measurement?: MeasurementUnit;
  onChange(value: string): void;
  placeholder?: string;
  readOnly?: boolean;
  errorMessage?: any;
  validationMessage?: any;
  error?: boolean;
  mb?: string;
  info?: string | React.ReactNode;
  infoPlacement?: Placement;
  infoOptions?: TooltipProps;
  min?: number;
  max?: number;
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(function (
  {
    label,
    name,
    value,
    step = 1,
    measurement = MeasurementUnit.Pixel,
    placeholder,
    disabled,
    onChange,
    readOnly,
    validationMessage,
    errorMessage,
    error,
    mb = '32px',
    info,
    infoPlacement = 'top',
    infoOptions,
    min = 0,
    max = 100,
    ...rest
  },
  ref,
) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      onChange(value);
    },
    [onChange],
  );

  const setUpdatedValue = useCallback(
    (value: number) => {
      let inputValue = extractNumbers(value);
      if (inputValue < min) {
        inputValue = min;
      }
      if (inputValue > max) {
        inputValue = max;
      }
      onChange(formatNumericValue(inputValue, measurement));
    },
    [measurement, onChange, min, max],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      const inputValue = extractNumbers(value);
      setUpdatedValue(inputValue);
    },
    [setUpdatedValue],
  );

  const handleDecreaseNumber = () => {
    const inputValue = extractNumbers(value) - step;
    setUpdatedValue(inputValue);
  };

  const handleIncreaseNumber = () => {
    const inputValue = extractNumbers(value) + step;
    setUpdatedValue(inputValue);
  };

  const hasError = !!errorMessage || error;

  return (
    <Box mb={mb} position="relative">
      {label && (
        <FormLabel htmlFor={name} error={hasError}>
          {label}
          {info ? (
            <InfoTooltip content={info} placement={infoPlacement} options={infoOptions} />
          ) : null}
        </FormLabel>
      )}
      <Styled.OutlinedInput
        color="secondary"
        fullWidth
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <Button onClick={handleIncreaseNumber} disabled={disabled}>
              <Plus />
            </Button>
          </InputAdornment>
        }
        startAdornment={
          <InputAdornment position="start">
            <Button onClick={handleDecreaseNumber} disabled={disabled}>
              <Minus />
            </Button>
          </InputAdornment>
        }
        inputRef={ref}
        error={hasError}
        inputProps={{
          onBlur: handleBlur,
        }}
        {...rest}
      />
      {(errorMessage || validationMessage) && (
        <Styled.WarningMessage error={error}>
          {errorMessage || validationMessage}
        </Styled.WarningMessage>
      )}
    </Box>
  );
});
