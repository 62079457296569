import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoadingButton, TextInput } from 'components/common';
import { PasswordInput, PasswordRequirementsNote } from 'components/common/TextInput';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { URLS } from 'pages/urls';
import { isNotEmptyString, isValidEmail, validate } from 'utils/validation';

import { LegalAgreements } from './LegalAgreements';

export interface FormData {
  name: string;
  email: string;
  password: string;
  terms: boolean;
  privacy: boolean;
}

interface Props {
  onSubmit: (data: FormData) => void;
  isSubmitting: boolean;
  isD2CPlugin?: boolean;
}

export const AccountForm: React.VFC<Props> = ({ onSubmit, isSubmitting, isD2CPlugin = false }) => {
  const { register, errors, handleSubmit, control } = useForm<FormData>({
    mode: 'onChange',
  });

  return (
    <form name="register-account" onSubmit={handleSubmit(onSubmit)}>
      <Stack width="400px" mb="40px">
        <TextInput
          name="name"
          label="Full name"
          autoComplete="name"
          placeholder="Type your name here"
          ref={register({
            required: 'Required',
            validate: {
              empty: validate(isNotEmptyString, 'Full name cannot be empty'),
            },
          })}
          errorMessage={errors.name?.message}
          mb="0"
        />
        <TextInput
          name="email"
          type="email"
          label="Email"
          autoComplete="email"
          placeholder="Type your email here"
          ref={register({
            required: 'Required',
            validate: {
              email: validate(isValidEmail, 'Incorrect email format'),
            },
          })}
          errorMessage={errors.email?.message}
          mb="0"
        />
        <Stack rowGap={16}>
          <PasswordRequirementsNote />
          <PasswordInput
            name="password"
            label="Password"
            autoComplete="new-password"
            placeholder="Type your password here"
            ref={register({
              required: 'Required',
            })}
            errorMessage={errors.password?.message}
            mb="0"
          />
        </Stack>
        <LegalAgreements
          control={control}
          errors={errors}
          termsOfUseUrl={isD2CPlugin ? URLS.termsOfUseD2C : URLS.termsOfUse}
        />
      </Stack>
      <Columns display="flex" justifyContent="center">
        <LoadingButton variant="contained" color="primary" type="submit" isLoading={isSubmitting}>
          Next
        </LoadingButton>
      </Columns>
      <Box mt="32px" textAlign="center">
        <Box display="inline-flex" alignItems="center">
          <Box mr="1ch" fontSize="14px" fontWeight={600}>
            Already have an account?
          </Box>
          <Button component={Link} to={URLS.root} variant="text" color="primary">
            Login here
          </Button>
        </Box>
      </Box>
    </form>
  );
};
