import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { TEST_ID } from 'config/test-ids';
import { UpdateEmailRequestProps, useUpdateEmailRequest } from 'hooks/mutation';
import { useUserQuery } from 'hooks/query';
import { isNotSameAs, isValidEmail, validate } from 'utils/validation';

interface FormData {
  currentEmail: string;
  newEmail: string;
}

export const EmailTab = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data } = useUserQuery();
  const [newEmail, saveNewEmail] = useState('');

  const { register, errors, handleSubmit, watch } = useForm<FormData>({
    mode: 'onSubmit',
  });

  const [updateEmailRequest, { loading: updateEmailRequestIsLoading }] = useUpdateEmailRequest({
    onCompleted: (data: UpdateEmailRequestProps) => {
      if (data.updateEmailRequest?.errors.length === 0) {
        setIsModalVisible(true);
      }
    },
  });

  const onSubmit = (data: FormData): void => {
    sendChangeEmailRequest(data.newEmail.trim());
  };

  const sendChangeEmailRequest = (updateEmail: string = newEmail) => {
    saveNewEmail(updateEmail);
    updateEmailRequest({
      variables: {
        email: updateEmail,
      },
    });
  };

  return (
    <>
      <Box component="form" p="40px" onSubmit={handleSubmit(onSubmit)}>
        <Box maxWidth="400px">
          <TextInput
            name="currentEmail"
            label="Current Email address"
            autoComplete="current-password"
            value={data?.me.email}
            disabled={true}
            ref={register({ required: 'Required' })}
            mb="40px"
          />
          <TextInput
            name="newEmail"
            label="New Email address"
            autoComplete="new-email"
            placeholder="Type in new email address"
            ref={register({
              required: 'New email address is required',
              validate: {
                numeric: validate(isValidEmail, 'Email address is invalid'),
                notSame: validate(
                  isNotSameAs(watch('currentEmail')),
                  'Set other email address than current',
                ),
              },
            })}
            errorMessage={errors.newEmail?.message}
            mb="40px"
          />
        </Box>
        <Box p="30px 0 0 0" display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            isLoading={updateEmailRequestIsLoading}
            data-testid={TEST_ID.savePasswordButton}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
      <Dialog
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        heading="Check your inbox"
        width={600}
      >
        <Box p="20px">
          We have sent you an e-mail to confirm your change. <br /> Please follow the instructions
          in the e-mail.
        </Box>
        <Box marginTop={'25px'} display="flex" justifyContent="center">
          <Button variant="contained" color="secondary" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>
          <Box marginLeft={'20px'}>
            <Button variant="contained" color="primary" onClick={() => sendChangeEmailRequest()}>
              Re-send
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
