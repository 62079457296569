import { LiteralUnion } from 'type-fest';

import { EndCardResponse } from 'pages/EndCard/types';
import { Nullable } from 'utils/types';

export interface WidgetPresetI {
  created: string;
  id: string;
  name: string;
  googleAnalyticsTrackingId: Nullable<string>;
  googleAnalytics4MeasurementId: Nullable<string>;
  style: WidgetStyle;
  endCard: Nullable<EndCardResponse>;
}

export type DashboardWidgetPreset = Pick<
  WidgetPresetI,
  'created' | 'id' | 'name' | 'googleAnalyticsTrackingId' | 'googleAnalytics4MeasurementId'
> & {
  endCard: Nullable<Pick<EndCardResponse, 'id' | 'name'>>;
};

export type DetailsWidgetPreset = Pick<
  WidgetPresetI,
  'id' | 'name' | 'googleAnalyticsTrackingId' | 'googleAnalytics4MeasurementId' | 'style'
> & {
  endCard: Nullable<Pick<EndCardResponse, 'id' | 'name'>>;
};

export enum WidgetColorTheme {
  Light = 'LIGHT',
  Dark = 'DARK',
}

export interface WidgetStyle {
  /** in hex format */
  mainColor: string;
  colorTheme: WidgetColorTheme;
}

/**
 * Order string can be one (or a combination, joined with `,`) of given values.
 *
 * `column` -> ascending,
 * `-column` -> descending
 */
export type WidgetPresetsOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'name'
  | '-name'
  | 'created'
  | '-created'
  | 'endCardName'
  | '-endCardName',
  string
>;
