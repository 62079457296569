import { UPDATE_PROMOTE_CONTENT } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, PromoteContentSettingsTypes } from 'models';
import { PromoteContentForm } from 'pages/PromoteContent/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  updatePromotedContent: Nullable<{
    promotedContentSettings: Nullable<PromoteContentSettingsTypes>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  promotedContentSettings: PromoteContentForm;
}

interface UseUpdatePromoteContentOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdatePromoteContent = (options: UseUpdatePromoteContentOptions = {}) => {
  return useMutation<ResponseData, Variables>(UPDATE_PROMOTE_CONTENT, {
    pathToErrors: 'updatePromoteContent.errors',
    ...options,
  });
};
