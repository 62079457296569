import { Box } from '@material-ui/core';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { materialTheme } from 'components/App/materialTheme';
import { Typography } from 'components/common';
import { StyledTabs, TabsWrapper } from 'components/common/Tab/Tab';
import { TabLink, useTabsWithRouter } from 'components/common/TabLink';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { URLS } from 'pages/urls';

import { AccountTab } from './AccountTab';
import { EmailTab } from './EmailTab';
import { PasswordTab } from './PasswordTab';

const { root, account, password, email } = URLS.settings;

export const SettingsPage = () => {
  const tabValue = useTabsWithRouter([account, password, email], account);

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">Settings</Typography>
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <TabsWrapper
            px="32px"
            borderBottom={`1px solid ${materialTheme.palette.background.default}`}
          >
            <StyledTabs value={tabValue}>
              <TabLink value={account} label="Account" />
              <TabLink value={password} label="Password" />
              <TabLink value={email} label="E-mail" />
            </StyledTabs>
          </TabsWrapper>
          <Switch>
            <Route path={[root, account]} exact>
              <AccountTab />
            </Route>
            <Route path={password} exact>
              <PasswordTab />
            </Route>
            <Route path={email} exact>
              <EmailTab />
            </Route>
          </Switch>
        </BigCard>
      </Box>
    </MainLayout>
  );
};
