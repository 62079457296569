import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const CREATE_VIDEO_CTA_MUTATION = gql`
  mutation CreateVideoCTAs($videoId: ID!, $video_ctas: CreateVideoCTAsInput!) {
    createVideoCtas(videoId: $videoId, videoCtas: $video_ctas) {
      videoCtas {
        id
        name
        startTimestamp
        endTimestamp
        ctas {
          id
          button {
            id
          }
          image {
            id
          }
        }
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_VIDEO_CTA_MUTATION = gql`
  mutation UpdateVideoCTAs($videoId: ID!, $video_ctas: CreateVideoCTAsInput!) {
    updateVideoCtas(videoId: $videoId, videoCtas: $video_ctas) {
      videoCtas {
        id
        name
        startTimestamp
        endTimestamp
        ctas {
          id
          button {
            id
          }
          image {
            id
          }
        }
      }
      ${ERRORS_PART}
    }
  }
`;
