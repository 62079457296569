import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const LoginErrorMessage = styled.div`
  background-color: ${materialTheme.palette.grey[901]};
  border-radius: 10px;
  color: ${materialTheme.palette.common.white};
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 1.5;
  margin-bottom: 42px;
  padding: 18px 0;
  text-align: center;
`;
