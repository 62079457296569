import { Nullable } from 'utils/types';

export interface PublishingTargetBase {
  id: string;
  name: string;
  scheduledPublishDate: string;
}

export interface PublishingTarget extends PublishingTargetBase {
  type: PublishingTargetType;
}

export type PendingPublishingTargetPick = Pick<PublishingTarget, 'id' | 'type'>;

export enum PublishingTargetType {
  SelfPublication = 'SelfPublication',
  YouTube = 'YouTube',
}

export enum PublicationTypename {
  Self = 'SelfPublicationType',
  YouTube = 'YouTubePublicationType',
}

export interface PublicationI {
  id: string;
  publicUrl: Nullable<string>;
  stale: boolean;
  __typename: PublicationTypename;
}

export type ExternalPublicationUnion = YouTubePublication;

export interface SelfPublication extends PublicationI {
  publishingTargetId: string;
  __typename: PublicationTypename.Self;
}

export enum YouTubeUploadStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Lost = 'LOST',
}

export interface YouTubePublication extends PublicationI {
  studioUrl: Nullable<string>;
  uploadStatus: YouTubeUploadStatus;
  __typename: PublicationTypename.YouTube;
}
