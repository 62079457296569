import React from 'react';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useVideoQuery } from 'hooks/query';
import { navigateTo404 } from 'utils/navigation';

import { VideoEndCardPageView } from './VideoEndCardPageView';

export const VideoEndCardPage = () => {
  const { videoId } = useParams<{ videoId: string }>();

  const { loading, data } = useVideoQuery({
    variables: { id: videoId },
    onCompleted: ({ videoById }) => {
      if (!videoById) {
        navigateTo404();
      }
    },
  });

  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (data?.videoById) {
    return (
      <MainLayout>
        <VideoEndCardPageView video={data.videoById} />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
};
