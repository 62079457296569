import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import { useCreateButtonCTA } from 'hooks/mutation/useCreateButtonCTA';
import { generateCTAButtonEditLink } from 'pages/urls';

import { CTAButtonPageView } from './CTAButtonPageView';
import { convertButtonDataToServer } from './helpers';
import { CreateButtonCTAInput, OverlaysCTAButton } from './types';

export const NewCTAButton: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [isCreated, setIsCreated] = useState<boolean>(false);

  const [createButtonCta, { loading: isLoading }] = useCreateButtonCTA({
    onCompleted: (data) => {
      setIsCreated(true);
      const id = data.createButtonCta?.buttonCta?.id;
      if (id) {
        history.replace(generateCTAButtonEditLink(id), state);
      }
    },
  });

  const onSubmit = async (formData: OverlaysCTAButton) => {
    await createButtonCta({
      variables: {
        button_cta: convertButtonDataToServer(formData) as CreateButtonCTAInput,
      },
    });
  };

  return (
    <MainLayout>
      <CTAButtonPageView handleSave={onSubmit} isLoading={isLoading} isCreated={isCreated} />
    </MainLayout>
  );
};
