import { LiteralUnion } from 'type-fest';

import { Monetization } from 'pages/Organization/MonetizationTab/types';
import { Nullable } from 'utils/types';

import { CreatorSuitePlanLimit, DialogToClipPlanLimit } from './Limits';
import { UserI } from './User';

export enum SubscriptionLimitPeriod {
  Total = 'TOTAL',
  Month = 'MONTH',
}

export type SubscriptionCurrency = 'usd';

export interface SubscriptionLimit {
  limit: CreatorSuitePlanLimit | DialogToClipPlanLimit;
  period: SubscriptionLimitPeriod;
  value: Nullable<number>;
  meteredLimit: Nullable<{
    unitsCap: Nullable<number>;
    unitCost: string;
    unitCurrency: SubscriptionCurrency;
  }>;
}

export interface SubscriptionPlan {
  name: string;
}

export enum SubscriptionStatus {
  Trial = 'TRIAL',
  Premium = 'PREMIUM',
  Unlimited = 'UNLIMITED',
  Inactive = 'INACTIVE',
  D2CTrial = 'D2C_TRIAL',
  D2C = 'D2C_PREMIUM',
  D2CUnlimited = 'D2C_UNLIMITED',
}

export enum SocialMediaPlatforms {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
  INSTAGRAM = 'INSTAGRAM',
}

export enum ShareSocialMediaPlatforms {
  MAIL = 'MAIL',
  TWITTER = 'TWITTER',
  REDDIT = 'REDDIT',
  WHATSAPP = 'WHATSAPP',
  LINKEDIN = 'LINKEDIN',
  FACEBOOK = 'FACEBOOK',
}
export const platformMap = {
  [ShareSocialMediaPlatforms.MAIL]: 'Mail',
  [ShareSocialMediaPlatforms.TWITTER]: 'X (Twitter)',
  [ShareSocialMediaPlatforms.REDDIT]: 'Reddit',
  [ShareSocialMediaPlatforms.WHATSAPP]: 'WhatsApp',
  [ShareSocialMediaPlatforms.LINKEDIN]: 'LinkedIn',
  [ShareSocialMediaPlatforms.FACEBOOK]: 'Facebook',
};

export interface LimitsUsage {
  value: Nullable<number>;
  meteredValue: Nullable<number>;
  usage: Nullable<number>;
  meteredUsage: Nullable<number>;
  limit: SubscriptionLimit;
}

export interface Subscription {
  id: string;
  cost: number;
  endsAt: string;
  plan?: SubscriptionPlan;
  status: SubscriptionStatus;
  limitsUsage: Nullable<LimitsUsage[]>;
}

export interface SocialMediaI {
  created: string;
  id: string;
  label: string;
  accountUrl: string;
  platformName: SocialMediaPlatforms;
}

export interface ShareSocialMediaInput {
  visible: boolean;
  platformName: ShareSocialMediaPlatforms;
}

export interface SocialMediaPlatformInput {
  label: string;
  accountUrl: string;
  platformName: SocialMediaPlatforms;
}

export interface OrganizationI {
  id: string;
  name: string;
  isMonetizationEnabled: boolean;
  logoUrl: string;
  owner: Pick<OrganizationMemberI, 'id'>;
  subscription?: Subscription;
  socialMedia?: SocialMediaI[];
  shareSocialMediaIcons?: ShareSocialMediaInput[];
  monetizationSettings: Monetization[];
}

export interface OrganizationMemberI extends UserI {
  dateJoined: string;
  isActive: boolean;
  isVerified: boolean;
}

/**
 * Order string can be one (or a combination, joined with `,`) of given values.
 *
 * `column` -> ascending,
 * `-column` -> descending
 */
export type OrganizationMembersOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'email'
  | '-email'
  | 'name'
  | '-name'
  | 'created'
  | '-created'
  | 'role'
  | '-role'
  | 'isActive'
  | '-isActive'
  | 'isVerified'
  | '-isVerified',
  string
>;

export interface OrganizationMonetization {
  id: string;
  active: Boolean;
  default: Boolean;
  monetizationUrl: String;
  name: String;
}

export type OrganizationMonetizationInput = Partial<OrganizationMonetization>;
