import { UPDATE_MICROSITE_MUTATION, UPDATE_MICROSITE_SECTION_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, Microsite, UpdateMicrositeInput, UpdateMicrositeSectionInput } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateMoment: Nullable<{
    moment: Nullable<Microsite>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  microsite: UpdateMicrositeInput;
}

export const useUpdateMicrosite = () => {
  return useMutation<ResponseData, Variables>(UPDATE_MICROSITE_MUTATION, {
    pathToErrors: 'updateMicrosite.errors',
  });
};

interface SectionResponseData {
  updateMoment: {
    moment: Nullable<Microsite>;
    errors: ErrorType[];
  };
}

export interface SectionVariables {
  id: string;
  sections: UpdateMicrositeSectionInput[];
}

export const useUpdateMicrositeSection = () => {
  return useMutation<SectionResponseData, SectionVariables>(UPDATE_MICROSITE_SECTION_MUTATION, {
    pathToErrors: 'updateSections.errors',
  });
};
