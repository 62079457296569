import React from 'react';

import { DashboardMomentNode } from 'models';
import { generateMomentLink } from 'pages/urls';

import { TitleCell } from '../TitleCell';

interface TitleCellProps {
  moment: DashboardMomentNode;
}

export const MomentTitleCell: React.FC<TitleCellProps> = ({ moment }) => {
  const momentPageUrl = generateMomentLink(moment.video.id, moment.id);

  return (
    <TitleCell
      title={moment.title}
      description={moment.description}
      link={momentPageUrl}
      thumbnailUrl={moment.thumbnailUrl}
    />
  );
};
