import { darken, lighten } from 'polished';
import React from 'react';

import { customPalette, materialTheme } from 'components/App/materialTheme';
import { Badge, BadgeProps } from 'components/common/Badge';
import { UserRole } from 'models';

import { formatUserRole } from './MemberTable.utils';

interface Props {
  role: UserRole;
}

export const UserRoleBadge: React.VFC<Props> = ({ role }) => {
  const props = colorMap[role];
  return <Badge {...props}>{formatUserRole(role)}</Badge>;
};

const colorMap: Record<UserRole, Partial<BadgeProps>> = {
  [UserRole.OrgOwner]: {
    backgroundColor: lighten(0.5, customPalette.purple),
    color: customPalette.purple,
  },
  [UserRole.Curator]: {
    backgroundColor: lighten(0.52, customPalette.lightBlue),
    color: darken(0.13, customPalette.lightBlue),
  },
  [UserRole.OrgAdmin]: {
    backgroundColor: lighten(0.58, materialTheme.palette.success.main),
    color: darken(0.1, materialTheme.palette.success.main),
  },
  [UserRole.VideoEditor]: {
    backgroundColor: lighten(0.85, materialTheme.palette.text.primary),
    color: materialTheme.palette.text.primary,
  },
};
