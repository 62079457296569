import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

import React from 'react';

import { Modal } from 'components/common';
import WidgetEmbedCode from 'components/common/WidgetEmbedCode/WidgetEmbedCode.legacy';
import { CarouselI } from 'models';

interface OwnProps {
  open: boolean;
  handleClose: () => void;
  carousel: CarouselI;
}

const AfterSaveModal = ({ open, handleClose, carousel }: OwnProps) => {
  return (
    <Modal open={open} handleClose={handleClose} heading="Your widget is saved!">
      <WidgetEmbedCode handleClose={handleClose} carousel={carousel} />
    </Modal>
  );
};

export default AfterSaveModal;
