import styled from 'styled-components/macro';

export const EmptyTabTitle = styled.p`
  font-weight: 600;
  margin-bottom: 32px;
`;

export const EmptyTabAddButton = styled.div`
  margin-bottom: 16px;
`;

export const EmptyTabInformationButton = styled.button`
  color: inherit;
  font-weight: 600;
  text-decoration: underline;
`;
