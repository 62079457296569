import { Range } from 'models';

export function moveRangeOnTimeline<TRange extends Range>(
  range: TRange,
  newStartTimestamp: number,
): TRange {
  const diff = newStartTimestamp - range.startTimestamp;
  return {
    ...range,
    startTimestamp: newStartTimestamp,
    endTimestamp: range.endTimestamp + diff,
    blocks: range.blocks
      ? range.blocks.map((block) => {
          return {
            ...block,
            timestamp: block.timestamp + diff,
          };
        })
      : undefined,
  };
}
