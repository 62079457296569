import { PublishingTargetType, SelfPublication } from 'models';
import {
  extractErrorMessage,
  PublishStatus,
  TargetHandlerEntry,
} from 'pages/PublishVideo/PublishVideo.utils';
import { Optional } from 'utils/types';

import { PlatformHandler } from './SelfPublication';

export const SelfPublicationHandler: TargetHandlerEntry = {
  type: PublishingTargetType.SelfPublication,
  label: 'Self Publication',
  Handler: PlatformHandler,
  onPublish: (targetId, data) => {
    const selfPublications =
      data && data[PublishingTargetType.SelfPublication]?.video?.publications;
    const errorMessage = extractErrorMessage(
      `${PublishingTargetType.SelfPublication}.errors`,
      data,
    );
    return getPublishStatus(targetId, selfPublications, errorMessage);
  },
  onPoll: (targetId, data) => {
    return getPublishStatus(targetId, data.videoById?.publications);
  },
};

function getPublishStatus(
  targetId: string,
  publications: Optional<SelfPublication[]>,
  errorMessage?: string,
): PublishStatus {
  if (errorMessage) {
    return {
      ok: false,
      errorMessage,
    };
  }

  const publication = publications?.find(
    ({ publishingTargetId }) => publishingTargetId === targetId,
  );

  if (!publication) {
    return { ok: true, isInProgress: true, shareLink: undefined };
  }

  return {
    ok: true,
    isInProgress: publication.stale,
    shareLink: publication.publicUrl ?? undefined,
  };
}
