import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { Stack } from 'components/common/Wrapper/Layout';
import { ModalPageLayout } from 'components/layouts/ModalPageLayout';
import { Permission } from 'config/permissions';
import { useLogout } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useHasActiveSubscription } from 'hooks/useHasActiveSubscription';
import { LoadingSubscription, PaymentPlan } from 'pages/Organization/PaymentPlanTab/PaymentPlan';
import { URLS } from 'pages/urls';

export const PayNowPage: React.VFC = () => {
  const { hasActiveSubscription, isSubscriptionLoading } = useHasActiveSubscription();
  const { hasPermissions, isLoading } = useUserPermissions();
  const canCreateSubscription = hasPermissions([Permission.CreatePaymentSession]);
  const [logOut] = useLogout();

  const successUrl = new URL(URLS.organization.paymentPlan, window.location.origin);
  const cancelUrl = new URL(URLS.payNow, window.location.origin);

  return (
    <ModalPageLayout>
      <Stack p="40px 35px" minWidth="800px" lineHeight="normal">
        {isLoading || isSubscriptionLoading ? (
          <LoadingSubscription />
        ) : canCreateSubscription ? (
          <PaymentPlan successUrl={successUrl.toString()} cancelUrl={cancelUrl.toString()} />
        ) : hasActiveSubscription ? (
          <div>
            Your organization is active. You can go to the{' '}
            <Link to={URLS.root}>
              <strong>dashboard</strong>
            </Link>
            .
          </div>
        ) : (
          <div>Your organization has been deactivated. Please contact with your admin.</div>
        )}
        <Button onClick={logOut}>Logout</Button>
      </Stack>
    </ModalPageLayout>
  );
};
