import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

import React from 'react';

import { Modal } from 'components/common';
import WidgetEmbedCode from 'components/common/WidgetEmbedCode/WidgetEmbedCode.legacy';
import { CarouselI } from 'models';

interface OwnProps {
  open: boolean;
  handleClose: () => void;
  carousel: CarouselI | null;
}

const EmbedCarouselModal = ({ open, handleClose, carousel }: OwnProps) => {
  if (!carousel) {
    return null;
  }

  return (
    <Modal open={open} handleClose={handleClose} heading="Embed code">
      <WidgetEmbedCode handleClose={handleClose} carousel={carousel} />
    </Modal>
  );
};

export default EmbedCarouselModal;
