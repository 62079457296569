import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { TagsField, TextInput, Typography } from 'components/common';
import { useTagsState } from 'components/common/TagsField/TagsField';
import { Stack } from 'components/common/Wrapper/Layout';
import ThumbnailSelect from 'components/ThumbnailSelect';
import { MAX_THUMBNAILS_PER_VIDEO } from 'config/constants';
import { Permission } from 'config/permissions';
import { pushMessage } from 'context/globalStream';
import { useOrganizationLogoPreview } from 'context/OrgLogoPositionContext';
import { useCreateVideoThumbnail, useDeleteVideoThumbnail } from 'hooks/mutation';
import { useVideoThumbnailsQuery } from 'hooks/query/useThumbnailsQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useFormAutosave } from 'hooks/useFormAutosave';
import { TagTypes, VideoDetailsNode, VideoLogoPositionType } from 'models';
import { LocalStorage } from 'utils/storage';
import { pushMaxThumbnailCountError } from 'utils/thumbnails';
import { isNotEmptyString, validate } from 'utils/validation';
import { canVideoMetadataBeMutated } from 'utils/videos';

import { CaptionsUpload } from '../CaptionsUpload';
import { MonetizationButton } from './MonetizationButton';
import { OverlaysButtons } from './OverlaysButtons';

interface FormData {
  title: string;
  description: string;
  endCardId?: string | null;
  orgLogoPosition?: VideoLogoPositionType;
}

interface ExtendedFormData extends FormData {
  tags: string[];
  internalTags: string[];
  thumbnailId?: string;
}

export type { ExtendedFormData as FormData };

const TAGS_EDITED = (id: string) => `videoTagsEdited${id}`;
const INTERNAL_TAGS_EDITED = (id: string) => `videoInternalTagsEdited${id}`;

interface OwnProps {
  video: VideoDetailsNode;
  onSave: (data: ExtendedFormData) => void;
}

const VideoDetailsPanel = ({ video, onSave }: OwnProps) => {
  const [selectedThumbnailId, setSelectedThumbnailId] = React.useState(video.thumbnailId);
  const [tags, setTags] = useTagsState(video.tags);
  const [previousLogoPosition, setPreviousLogoPosition] = React.useState<
    VideoLogoPositionType | undefined
  >(undefined);
  const [internalTags, setInternalTags] = useTagsState(video.internalTags);
  const { setOrgLogoPosition, isLogoEnabled } = useOrganizationLogoPreview();
  const [tagsChanged, setTagsChanged] = useState(false);
  const [internalTagsChanged, setInternalTagsChanged] = useState(false);

  const { submitRef, triggerFormSubmit } = useFormAutosave([
    tags,
    selectedThumbnailId,
    internalTags,
  ]);

  React.useEffect(() => {
    const { id, status } = video;
    const tagsEditedValue = LocalStorage.get(TAGS_EDITED(id));
    const internalTagsEditedValue = LocalStorage.get(INTERNAL_TAGS_EDITED(id));

    if (status === 'EDITED' && tagsEditedValue) {
      setTagsChanged(true);
    } else {
      LocalStorage.remove(TAGS_EDITED(id));
      setTagsChanged(false);
    }

    if (video.status === 'EDITED' && internalTagsEditedValue) {
      setInternalTagsChanged(true);
    } else {
      LocalStorage.remove(INTERNAL_TAGS_EDITED(id));
      setInternalTagsChanged(false);
    }
  }, [video]);

  const { register, handleSubmit, reset, errors, formState, control, watch } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  // Read the `formState` before render to subscribe to the form state proxy
  const { dirtyFields } = formState;

  const onSubmit = (data: FormData) => {
    onSave({ ...data, tags, internalTags, thumbnailId: selectedThumbnailId ?? undefined });
    reset(data);
  };

  const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.currentTarget;
    if (name in dirtyFields) {
      triggerFormSubmit();
    }
  };

  const organizationLogoPosition = watch('orgLogoPosition');
  if (organizationLogoPosition) {
    setOrgLogoPosition(organizationLogoPosition);
  }

  const onSelectInputBlur = async () => {
    if (isLogoEnabled && previousLogoPosition !== organizationLogoPosition) {
      triggerFormSubmit();
      setPreviousLogoPosition(organizationLogoPosition);
    }
  };

  const { thumbnails } = useVideoThumbnailsQuery({ variables: { videoId: video.id } });
  const [createThumbnailMutation] = useCreateVideoThumbnail(video.id);
  const addNewThumbnail = React.useCallback(
    async (imageBase64: string) => {
      if (thumbnails.length + 1 > MAX_THUMBNAILS_PER_VIDEO) {
        pushMaxThumbnailCountError(MAX_THUMBNAILS_PER_VIDEO);
        return;
      }

      const { data } = await createThumbnailMutation({
        variables: { videoId: video.id, imageBase64 },
      });

      if (data?.createVideoThumbnail?.thumbnail) {
        setSelectedThumbnailId(data.createVideoThumbnail.thumbnail.id);
        pushMessage({ message: 'Thumbnail successfully uploaded' });
      }
    },
    [createThumbnailMutation, video.id, thumbnails.length],
  );

  const [deleteThumbnailMutation] = useDeleteVideoThumbnail(video.id);
  const removeThumbnail = React.useCallback(
    async (id: string) => {
      const { data } = await deleteThumbnailMutation({
        variables: { id },
      });

      if (data?.deleteVideoThumbnail?.deletedVideoThumbnailId) {
        pushMessage({ message: 'Thumbnail successfully deleted' });
      }
    },
    [deleteThumbnailMutation],
  );

  const handleTagEdited = () => {
    LocalStorage.set(TAGS_EDITED(video.id), true);
    setTagsChanged(true);
  };

  const handleInternalTagEdited = () => {
    LocalStorage.set(INTERNAL_TAGS_EDITED(video.id), true);
    setInternalTagsChanged(true);
  };

  const isMetadataEditable = canVideoMetadataBeMutated(video);
  const { hasPermissions } = useUserPermissions();

  const canUserUpdateVideo = hasPermissions([Permission.ChangeVideo]);
  const isVideoMetadataDisabled = !isMetadataEditable || !canUserUpdateVideo;

  const canUserCreateThumbnail = hasPermissions([Permission.CreateVideoThumbnail]);
  const isThumbnailUploadDisabled = !isMetadataEditable || !canUserCreateThumbnail;

  const canUserDeleteThumbnail = hasPermissions([Permission.DeleteVideoThumbnail]);
  const isThumbnailDeleteDisabled = !isMetadataEditable || !canUserDeleteThumbnail;

  return (
    <VideoForm onSubmit={handleSubmit(onSubmit)}>
      <input ref={submitRef} type="submit" style={{ display: 'none' }} />
      <Stack>
        <Typography variant="h1" color="textPrimary">
          Video Editor
        </Typography>
        <ThumbnailSelect
          thumbnails={thumbnails}
          selectedId={selectedThumbnailId}
          onSelect={setSelectedThumbnailId}
          onRemove={removeThumbnail}
          onThumbnailAdded={addNewThumbnail}
          isUploadHidden={isThumbnailUploadDisabled}
          isUploadDisabled={!isMetadataEditable}
          isSelectDisabled={isVideoMetadataDisabled}
          isDeleteDisabled={isThumbnailDeleteDisabled}
        />
        <TextInput
          label="Title (required)"
          name="title"
          placeholder="Write a title here…"
          ref={register({
            validate: validate(isNotEmptyString, 'Title cannot be empty'),
          })}
          defaultValue={video.title}
          onBlur={onInputBlur}
          readOnly={isVideoMetadataDisabled}
          error={!!errors.title}
          errorMessage={errors.title?.message}
          mb="0"
          maxLength={256}
        />
        <TextInput
          multiline
          label="Description"
          name="description"
          placeholder="Write a video description here…"
          ref={register()}
          defaultValue={video.description}
          onBlur={onInputBlur}
          readOnly={isVideoMetadataDisabled}
          maxLength={5000}
          mb="0"
        />
        <TagsField
          tags={tags}
          setTags={setTags}
          disabled={isVideoMetadataDisabled}
          sectionLabel="Tags"
          type={TagTypes.Regular}
          canUserUpdate={canUserUpdateVideo}
          videoStatus={video.status}
          onTagEdited={handleTagEdited}
          hasUnpublishedChanges={tagsChanged}
        />
        <TagsField
          tags={internalTags}
          setTags={setInternalTags}
          disabled={isVideoMetadataDisabled}
          sectionLabel="Categories"
          type={TagTypes.Internal}
          canUserUpdate={canUserUpdateVideo}
          videoStatus={video.status}
          tooltipText="Used as filtering keyword in Microsite’s creator."
          placeholder="Add category..."
          onTagEdited={handleInternalTagEdited}
          hasUnpublishedChanges={internalTagsChanged}
        />
        <CaptionsUpload videoId={video.id} captions={video.captions} />
        <OverlaysButtons
          video={video}
          control={control}
          onInputBlur={onSelectInputBlur}
          dirtyFields={dirtyFields}
        />
        <MonetizationButton videoId={video.id} monetization={video.monetization} />
      </Stack>
    </VideoForm>
  );
};

const VideoForm = styled.form`
  height: 100%;
  width: 100%;
`;

export default VideoDetailsPanel;
