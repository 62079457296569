import { Box } from '@material-ui/core';
import React from 'react';

import { FormLabel } from 'components/common';
import { UploadDropBox } from 'components/common/UploadDropBox';
import { MAX_IMAGE_SIZE } from 'config/constants';
import { pushError } from 'context/globalStream';
import { ThumbnailI } from 'models';
import { Nullable } from 'utils/types';
import { exceedsMaxAllowedFileSize } from 'utils/validation';

import Slides from './Slides';

const THUMBNAIL_ID = 'thumbnail-select';

interface OwnProps {
  thumbnails?: ThumbnailI[];
  selectedId?: Nullable<string>;
  onSelect?: (id: string) => void;
  onRemove: (id: string) => void;
  onThumbnailAdded?: (imageBase64: string) => void;
  isUploadHidden?: boolean;
  isUploadDisabled?: boolean;
  isSelectDisabled?: boolean;
  isDeleteDisabled?: boolean;
}

function ThumbnailSelect({
  thumbnails,
  selectedId,
  onSelect,
  onRemove,
  onThumbnailAdded,
  isUploadHidden,
  isUploadDisabled,
  isSelectDisabled,
  isDeleteDisabled,
}: OwnProps) {
  const handleFileValidation = React.useCallback((file: File) => {
    if (exceedsMaxAllowedFileSize(MAX_IMAGE_SIZE, file.size)) {
      pushError({
        message: `Thumbnail exceeds maximum file size (${MAX_IMAGE_SIZE} MiB).`,
      });
      return false;
    }
    return true;
  }, []);

  return (
    <div>
      <FormLabel htmlFor={THUMBNAIL_ID}>Thumbnail</FormLabel>
      <Box display="flex">
        {!isUploadHidden && (
          <UploadDropBox
            id={THUMBNAIL_ID}
            validateFile={handleFileValidation}
            onFileProcessed={onThumbnailAdded}
            disabled={isUploadDisabled}
          />
        )}
        <Slides
          selectedId={selectedId}
          onSelect={onSelect}
          onRemove={onRemove}
          thumbnails={thumbnails}
          isSelectDisabled={isSelectDisabled}
          isDeleteDisabled={isDeleteDisabled}
        />
      </Box>
    </div>
  );
}
export default ThumbnailSelect;
