import { Box, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';

import { ReactComponent as Expand } from 'assets/icons/expand.svg';
import { Avatar } from 'components/common';
import { TEST_ID } from 'config/test-ids';
import { useUserQuery } from 'hooks/query';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';

import { UserDropdown } from './UserDropdown';

import {
  ExpandDropdown,
  OrganizationName,
  ProfileButton,
  ProfileWrapper,
  Username,
} from './UserProfile.styles';

const useStyles = makeStyles(() => ({
  paper: {
    background: 'none',
    boxShadow: 'none',
    borderRadius: '15px',
    margin: '-5px 0 0 -5px',
  },
}));

interface UserProfileProps {
  miniNavbar?: boolean;
}

export const UserProfile: React.FC<UserProfileProps> = ({ miniNavbar }) => {
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>();
  const classes = useStyles();
  const { data } = useUserQuery();
  const { data: organization } = useOrganizationQuery();
  const name = data?.me.name;
  const organizationName = organization?.me.organization?.name;

  const handleProfileClick = (target: HTMLElement) => {
    setDropdownVisible(!dropdownVisible);
    setAnchorElement(target || null);
  };

  return (
    <Box width="100%" position="relative">
      <div ref={dropdownRef}>
        <ProfileButton
          onClick={(event) => handleProfileClick(event.currentTarget)}
          data-testid={TEST_ID.profileButton}
        >
          <Avatar url={data?.me.avatarUrl} />
          <ProfileWrapper miniNavbar={miniNavbar}>
            <Username title={name}>{name}</Username>
            <OrganizationName title={organizationName}>{organizationName}</OrganizationName>
          </ProfileWrapper>
          <ExpandDropdown dropdownVisible={dropdownVisible} miniNavbar={miniNavbar}>
            <Expand className="dropdown-icon" />
          </ExpandDropdown>
        </ProfileButton>
        <Popover
          classes={classes}
          anchorEl={anchorElement}
          open={dropdownVisible}
          onClose={handleProfileClick}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transitionDuration={0}
        >
          <UserDropdown avatarUrl={data?.me.avatarUrl} displayName={name || ''} />
        </Popover>
      </div>
    </Box>
  );
};
