import React from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { pushMessage } from 'context/globalStream';
import { useCreateD2CApiKey } from 'hooks/mutation/useCreateD2CApiKey';
import { useSafeState } from 'hooks/useSafeState';
import { isValidApiKeyName, validate } from 'utils/validation';

import * as Styled from './NewKeyModal.styles';

interface FormData {
  name: string;
}

interface NewKeyModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const NewKeyModal: React.VFC<NewKeyModalProps> = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onChange',
  });

  const [createApiKey, , [errorMessage], resetErrorMessage] = useCreateD2CApiKey();

  const onSubmit = async ({ name }: FormData) => {
    setIsLoading(true);

    const { data } = await createApiKey({
      variables: {
        name,
      },
    });

    if (data?.createD2CApiKey?.apiKey?.name === name) {
      pushMessage({ message: 'API Key has been successfully created.' });
      onClose();
    } else {
      setIsLoading(false);
    }
  };

  const handleExited = () => {
    resetErrorMessage();
    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpen}
      heading={`Generate New Key`}
      width={600}
    >
      <form name="create-api-key" onSubmit={handleSubmit(onSubmit)}>
        <Stack display="flex" alignItems="center" flexDirection="column" mt="50px" rowGap={50}>
          {errorMessage ? (
            <Styled.ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
          ) : null}
          <Stack textAlign="left" width="400px">
            <TextInput
              name="name"
              label="Key Name"
              placeholder="Enter key name here, this is only used to label keys"
              ref={register({
                validate: validate(
                  isValidApiKeyName,
                  'Api Key name must only consist of letters, numbers, dashes and underscores',
                ),
              })}
              error={!!errors.name}
              errorMessage={errors.name?.message}
              mb="0"
            />
          </Stack>
          <Columns display="flex" flexDirection="row" justifyContent="center">
            <LoadingButton isLoading={isLoading} variant="contained" color="primary" type="submit">
              Generate
            </LoadingButton>
          </Columns>
        </Stack>
      </form>
    </Dialog>
  );
};
