export const DISABLED_TOOLTIP_TITLE =
  'Default tag cannot be deactivated, to deactivate this tag you need to select different default tag';

export const DEACTIVATION_MODAL_HEADING = 'Deactivate ad tag';
export const DEACTIVATION_MODAL_DESCRIPTION = `Deactivating tag will impact existing per-video settings - all videos using this tag will switch to "default" tag.
  Are you sure you want to deactivate this ad tag?`;

export const DEACTIVATION_MODAL_DEFAULT_HEADING = 'Deactivate default ad tag';
export const DEACTIVATION_MODAL_DEFAULT_DESCRIPTION = `Are you sure you want to deactivate default ad tag?
  Deactivating the default tag will disable monetization for your organization. We will store this ad tag and per-video settings.`;

export const DELETE_MODAL_HEADING = 'Delete tag';
export const DELETE_MODAL_DESCRIPTION = `Deleting tag will impact existing per-video settings - all videos using this tag will switch to "default" tag.
  Are you sure you want to delete this ad tag?`;

export const DELETE_MODAL_DEFAULT_HEADING = 'Delete default ad tag';
export const DELETE_MODAL_DEFAULT_DESCRIPTION = `Are you sure you want to delete default ad tag?
  Deleting the default tag will disable monetization for your organization. We will store your per-video settings.`;
