import { Box, Typography } from '@material-ui/core';
import React from 'react';

import { ModalPageLayout } from 'components/layouts/ModalPageLayout';
import { useRequestPasswordReset } from 'hooks/mutation';

import { FormData, ResetRequestForm } from './ResetRequestForm';
import { ResetRequestSuccess } from './ResetRequestSuccess';

import * as Styles from './ResetPassword.styles';

export const RequestPage: React.FC = () => {
  const [successEmail, setSuccessEmail] = React.useState<string>();

  const [requestReset, { loading: isLoading }, [errorMessage]] = useRequestPasswordReset();

  const handleSubmit = async ({ email }: FormData) => {
    const { data } = await requestReset({ variables: { email } });
    if (data?.requestPasswordReset?.ok) {
      setSuccessEmail(email);
    }
  };

  return (
    <ModalPageLayout>
      <Box display="flex" alignItems="center" flexDirection="column" py="64px" px="180px">
        <Box mb="40px">
          <Typography variant="h1" align="center">
            Reset password
          </Typography>
        </Box>
        {successEmail ? (
          <ResetRequestSuccess
            email={successEmail}
            onRetry={() => handleSubmit({ email: successEmail })}
            onGoBack={() => setSuccessEmail(undefined)}
            isLoading={isLoading}
          />
        ) : (
          <>
            {errorMessage ? (
              <Styles.ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
            ) : null}
            <ResetRequestForm onSubmit={handleSubmit} isLoading={isLoading} />
          </>
        )}
      </Box>
    </ModalPageLayout>
  );
};
