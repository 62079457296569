import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { ButtonCTAResponse } from 'pages/CTAButton/types';
import { Nullable } from 'utils/types';

export const QUERY = gql`
  query GetButtonCta($id: ID!) {
    buttonCtaById(id: $id) {
      id
      created
      name
      url
      text
      background
      border
      icon
    }
  }
`;

interface ResponseData {
  buttonCtaById: Nullable<ButtonCTAResponse>;
}

interface Variables {
  id?: string;
}

interface UseButtonCTAQuery extends UseQueryOptions<ResponseData, Variables> {}

export const useButtonCTAQuery = (options?: UseButtonCTAQuery) => {
  return useQuery<ResponseData, Variables>(QUERY, {
    ...options,
    fetchPolicy: 'cache-first',
  });
};
