import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

interface LogoProps {
  miniNavbar?: boolean;
}

export const Logo = styled.img<LogoProps>`
  width: 100%;

  ${({ miniNavbar }) =>
    !miniNavbar &&
    `
    width: calc(100% - 12px);
    margin-left: 6px;
  `}
`;

interface MenuItemProps {
  active?: boolean;
  miniNavbar?: boolean;
}

export const Menu = styled.ul<MenuItemProps>`
  list-style: none;
  margin-top: 15px;
  padding: ${(props) => (props.miniNavbar ? '0 0 0 10px' : '0 10px')};
  width: 100%;
`;

export const MenuItem = styled.li<MenuItemProps>`
  align-items: center;
  background-color: ${({ active, miniNavbar }) =>
    active && !miniNavbar && materialTheme.palette.grey[800]};
  border-radius: 15px;
  color: ${({ active }) =>
    active ? materialTheme.palette.common.white : materialTheme.palette.text.secondary};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: ${({ active }) => active && 'bold'};
  letter-spacing: 0.5px;
  margin-bottom: 25px;
  padding: 10px;
  width: 100%;
  transition: 0.25s ease-out;

  &:hover {
    color: ${materialTheme.palette.common.white};
  }
`;

export const ExternalLink = styled.a`
  text-decoration: none;
`;
