import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PaginationVariables } from 'models';
import { PlaylistContentType, PlaylistFilterBy } from 'models/Playlist';
import { Nullable } from 'utils/types';

const VIDEO_QUERY = gql`
  query GetAutomaticPlaylistVideos(
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $publicTags: [String!]
    $internalTags: [String!]
    $isPublished: Boolean
  ) {
    items: videos(
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      publicTags: $publicTags
      internalTags: $internalTags
      isPublished: $isPublished
    ) {
      nodes {
        id
        title
        description
        thumbnailUrl
      }
    }
  }
`;

const MOMENT_QUERY = gql`
  query GetAutomaticPlaylistMoments(
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $publicTags: [String!]
    $internalTags: [String!]
    $isPublished: Boolean
  ) {
    items: moments(
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      publicTags: $publicTags
      internalTags: $internalTags
      isPublished: $isPublished
    ) {
      nodes {
        id
        title
        description
        thumbnailUrl
        video {
          id
        }
      }
    }
  }
`;

interface ResponseData {
  items: Nullable<{
    nodes: Nullable<
      {
        id: string;
        title: string;
        description: string;
        thumbnailUrl: Nullable<string>;
        video?: {
          id: string;
        };
      }[]
    >;
  }>;
}

interface Variables extends Partial<PaginationVariables> {
  search?: string;
  orderBy?: string;
  publicTags?: string[];
  internalTags?: string[];
  isPublished?: boolean;
}

interface UseAutomaticPlaylistItemsQueryOptions extends UseQueryOptions<ResponseData, Variables> {
  type: PlaylistContentType;
  filterBy: PlaylistFilterBy;
  filterValue: string;
}

export function useAutomaticPlaylistItems({
  type,
  filterBy,
  filterValue,
  ...options
}: UseAutomaticPlaylistItemsQueryOptions) {
  const tagFilter =
    filterBy === PlaylistFilterBy.Null || filterValue === ''
      ? null
      : {
          [filterBy === PlaylistFilterBy.InternalTag ? 'internalTags' : 'publicTags']: [
            filterValue,
          ],
        };

  const QUERY = type === PlaylistContentType.Moments ? MOMENT_QUERY : VIDEO_QUERY;
  const results = useQuery<ResponseData, Variables>(QUERY, {
    ...options,
    variables: {
      ...options.variables,
      ...tagFilter,
    },
  });

  return {
    ...results,
    items: results.data?.items?.nodes ?? [],
  };
}
