import React from 'react';
import styled from 'styled-components';

import { ModalPageLayout } from 'components/layouts/ModalPageLayout';

const TermsWrapper = styled.div`
  width: 80vw;
  padding: 20px;

  strong {
    display: block;
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    font-weight: normal;
  }

  ol > li {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
`;

export const TermsOfUse: React.FC = ({ children }) => {
  return (
    <ModalPageLayout>
      <TermsWrapper>{children}</TermsWrapper>
    </ModalPageLayout>
  );
};
