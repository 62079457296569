import styled from 'styled-components/macro';

import { Stack } from 'components/common/Wrapper/Layout';

export const Container = styled.div`
  padding: 30px;
`;

export const YourPlanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const YourPlanBox = styled.div`
  border: 1px solid #e0e1e8;
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-flex;
  padding: 12px;
  margin-top: 10px;
  align-items: center;
`;

export const PlanName = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #323343;
  margin-left: 10px;
`;

export const PlanPrice = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
  background: #000000;
  padding: 15px;
  border-radius: 10px;
`;

export const EndsAt = styled.div`
  font-size: 90%;
  padding-top: 3px;
`;

export const ExpiredPlanContainer = styled(Stack)`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;
