import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  &::after {
    background-color: rgb(151, 151, 151, 0.3);
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
  }
`;

const Content = styled.div`
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  z-index: 1;
`;

export const Divider: React.FC = ({ children }) => {
  return <Wrapper>{children ? <Content>{children}</Content> : null}</Wrapper>;
};
