import React from 'react';

import VideoPlayer from 'components/VideoPlayer';
import VideoControls from 'components/VideoPlayer/VideoControls';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  MomentPlayerPickI,
  PromoteContentSettingsTypes,
  VideoLogoPositionType,
  VideoObjectNode,
} from 'models';
import { CTATimestampCtas } from 'pages/VideoCTA/type';

import { VideoPlayerWrapper } from './context';
import { FullscreenPlayerWrapper, FullscreenWrapper } from './VideoPlayerElements';

interface OwnProps {
  url?: string;
  moments?: MomentPlayerPickI[];
  promoteObjectsList?: VideoObjectNode[];
  promoteContentSettings?: PromoteContentSettingsTypes;
  ctas?: CTATimestampCtas[];
  isLogoEnabled?: boolean;
  logoPosition?: VideoLogoPositionType;
}

const BasePlayer = ({
  url,
  moments = [],
  promoteObjectsList = [],
  promoteContentSettings,
  isLogoEnabled,
  logoPosition,
  ctas = [],
}: OwnProps) => {
  const playerRef = React.useRef<HTMLDivElement>(null);
  const [volume, setVolume] = useLocalStorage('volume', 1);

  return (
    <VideoPlayerWrapper>
      <FullscreenWrapper ref={playerRef}>
        <FullscreenPlayerWrapper>
          <VideoPlayer
            url={url}
            volume={volume}
            promoteObjectsList={promoteObjectsList}
            promoteContentSettings={promoteContentSettings}
            setVolume={setVolume}
            ctas={ctas}
            isLogoEnabled={isLogoEnabled || false}
            logoPosition={logoPosition}
          />
        </FullscreenPlayerWrapper>
        <VideoControls
          playerRef={playerRef}
          moments={moments}
          setVolume={setVolume}
          volume={volume}
        />
      </FullscreenWrapper>
    </VideoPlayerWrapper>
  );
};

export default BasePlayer;
