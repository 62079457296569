import { POLL_VIDEO_STATUS } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { VideoI } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  videoById: Nullable<
    Pick<
      VideoI,
      | 'id'
      | 'description'
      | 'processingProgress'
      | 'status'
      | 'title'
      | 'thumbnailId'
      | 'thumbnails'
    >
  >;
}

interface Variables {
  id: string;
}

interface UseVideoStatusQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useVideoStatusQuery = (options: UseVideoStatusQueryOptions) => {
  return useQuery<ResponseData, Variables>(POLL_VIDEO_STATUS, options);
};
