import gql from 'graphql-tag';

export const SECTION_METADATA_FRAGMENT = gql`
  fragment SectionsMetadata on SectionType {
    id
    title
    internalTag
    regularTag
    sortBy
    contentType
  }
`;

export const MICROSITE_METADATA_FRAGMENT = gql`
  fragment MicrositeMetadata on MicrositeType {
    id
    htmlCode
    title
    description
    googleAnalyticsId
    googleAnalytics4Id
    domain
    mainColor
    logoUrl
    faviconUrl
    created
    healthy
    momentsLocationTop
  }
`;

export const MICROSITE_QUERY = gql`
  ${MICROSITE_METADATA_FRAGMENT}
  ${SECTION_METADATA_FRAGMENT}

  query GetMicrosite($id: ID!) {
    micrositeById(id: $id) {
      ...MicrositeMetadata
      sections {
        ...SectionsMetadata
      }
    }
  }
`;

export const MICROSITE_DASHBOARD_QUERY = gql`
  ${MICROSITE_METADATA_FRAGMENT}

  query GetMicrosite($pageSize: Int, $orderBy: String = "", $search: String = "") {
    microsites(pageSize: $pageSize, orderBy: $orderBy, search: $search) {
      nodes {
        ...MicrositeMetadata
      }
    }
  }
`;
