import { ButtonBase } from '@material-ui/core';
import React from 'react';
import SlickSlider from 'react-slick';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { Optional } from 'utils/types';

export const Slider = styled(SlickSlider)`
  .slick-list {
    margin: -20px -20px -20px 0;
    padding: 20px;
  }

  .slick-track {
    margin-left: 0;
  }

  .slick-arrow {
    ::before,
    ::after {
      color: #1a1a1a;
    }

    &.slick-prev {
      left: 0;
      z-index: 2;
    }
  }
`;

export const thumbnailItem = css`
  height: 64px;
  margin-right: 20px;
  width: 85px;
`;

interface ThumbnailProps {
  url?: Optional<string>;
  selected?: boolean;
}

export const Thumbnail = styled.div<{ disabled: boolean }>`
  ${thumbnailItem};
  transform: scale(1);
  transition: 0.25s ease-out;
  z-index: 1;
  position: relative;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        transform: scale(1.45) translateX(0);
        z-index: 2;
      }
    `}
`;

export const ThumbnailImage = styled(({ url, ...props }) => (
  <ButtonBase {...props} />
)).attrs<ThumbnailProps>(({ url }) => ({
  style: {
    backgroundImage: url && `url(${url})`,
  },
}))<ThumbnailProps>`
  && {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    z-index: -1;
  }
`;

const iconWrapper = css`
  align-items: center;
  border-radius: 50%;
  border: none;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  position: absolute;
  width: 20px;
`;

export const Checkmark = styled.div`
  ${iconWrapper};
  background-color: ${materialTheme.palette.primary.main};
  bottom: 3px;
  left: 3px;
  pointer-events: none;
`;

export const RemoveThumbnailButton = styled(ButtonBase)`
  && {
    ${iconWrapper};
    position: absolute;
    top: 3px;
    right: 3px;
    background-color: ${materialTheme.palette.secondary.main};
    color: ${materialTheme.palette.common.white};
  }
`;
