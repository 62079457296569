import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as ArrowDown } from 'assets/icons/triangle-down.svg';
import { SetSorting, Sorting, SortingDirection } from 'context/sorting';

export enum TestId {
  arrowUp = 'sort-interface:arrow-up',
  arrowDown = 'sort-interface:arrow-down',
}

export interface Props<Columns extends string> {
  children: React.ReactNode;
  columnName: Columns;
  activeSorting: Sorting<Columns>;
  setSorting: SetSorting<Columns>;
}

function SortInterface<Columns extends string>({
  children,
  columnName,
  activeSorting,
  setSorting,
}: Props<Columns>) {
  const [isActive, setIsActive] = React.useState(activeSorting.column === columnName);
  const [isDescending, setIsDescending] = React.useState(
    activeSorting.direction === SortingDirection.Descending,
  );

  // handle activeSorting change from upstream
  React.useEffect(() => {
    setIsActive(activeSorting.column === columnName);
    setIsDescending(activeSorting.direction === SortingDirection.Descending);
  }, [activeSorting, columnName]);

  const onClick = React.useCallback(() => {
    setSorting({
      column: columnName,
      direction: isActive
        ? isDescending
          ? SortingDirection.Ascending
          : SortingDirection.Descending
        : SortingDirection.Descending,
    });
  }, [isActive, isDescending, columnName, setSorting]);

  return (
    <SortButton isActive={isActive} onClick={onClick}>
      {children}
      <SortIndicator>
        {/* show only one arrow when sorting is active, otherwise display both */}
        {isActive && isDescending ? null : <ArrowUp data-testid={TestId.arrowUp} />}
        {isActive && !isDescending ? null : <ArrowDown data-testid={TestId.arrowDown} />}
      </SortIndicator>
    </SortButton>
  );
}

const ArrowUp = styled(ArrowDown)`
  transform: rotate(180deg);
`;

const SortButton = styled.button<{ isActive: boolean }>`
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-weight: inherit;
  text-transform: inherit;
  line-height: inherit;

  display: flex;
  align-items: center;
  color: ${(props) => (props.isActive ? '#1a1a1a' : 'inherit')};
`;

const SortIndicator = styled.span`
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: -2px;
  margin-left: 4px;

  > *:not(:last-child) {
    margin-bottom: 2px;
  }
`;

export default SortInterface;
