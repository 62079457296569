import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const ErrorMessage = styled.div`
  align-self: stretch;
  background-color: ${materialTheme.palette.grey[901]};
  border-radius: 10px;
  color: ${materialTheme.palette.common.white};
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 1.5;
  margin: 0 -60px;
  padding: 18px 40px;
  text-align: center;
  flex-grow: 1;
`;
