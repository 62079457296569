import React from 'react';

import { PUBLISH_ALLOWED_STATUSES } from 'config/mutationRules';
import { useVideoPublications } from 'hooks/query/useVideoPublications';
import { createValidationMessage } from 'pages/PublishVideo/publishingTargets';
import { TargetHandlerProps } from 'pages/PublishVideo/PublishVideo.utils';
import { capitalize } from 'utils/common';
import { canVideoBePublished } from 'utils/videos';

import { Target } from '../Target';

export function PlatformHandler({
  video,
  id,
  label,
  isLocked,
  isSelected,
  onSelect,
  setValidationMessage,
}: TargetHandlerProps) {
  React.useEffect(() => {
    if (isSelected && !canVideoBePublished(video.status)) {
      const status = capitalize(video.status);
      const allowedStatuses = PUBLISH_ALLOWED_STATUSES.map(capitalize).join(', ');
      setValidationMessage(
        createValidationMessage(
          label,
          `Video has incorrect status: ${status}. Allowed statuses: ${allowedStatuses}.`,
        ),
      );
    } else {
      setValidationMessage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, video]);

  const { selfPublications, isLoading: isPublicationLoading } = useVideoPublications({
    variables: {
      videoId: video.id,
    },
    fetchPolicy: 'cache-first',
  });

  const publication = selfPublications.find(({ publishingTargetId }) => id === publishingTargetId);
  const isFresh = publication && !publication.stale;

  return (
    <Target
      id={id}
      label={label}
      isLocked={isLocked || isPublicationLoading || isFresh}
      description={isFresh && 'Most recent video version is already published'}
      isSelected={isSelected}
      onSelect={onSelect}
    />
  );
}
