import { useCallback } from 'react';

import { hasPermission, Permission } from 'config/permissions';
import { QUERY_USER_PERMISSIONS } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';

interface ResponseData {
  me: {
    permissions: Permission[];
  };
}

interface UseUserPermissionsQueryOptions extends UseQueryOptions<ResponseData> {}

export const useUserPermissions = (options?: UseUserPermissionsQueryOptions) => {
  const { data, loading: isLoading } = useQuery<ResponseData>(QUERY_USER_PERMISSIONS, {
    fetchPolicy: 'cache-first',
    refetchOnVariablesChange: false,
    ...options,
  });

  const userPermissions = data?.me.permissions;

  const hasPermissions = useCallback(
    (permissions: Permission[]) => {
      return (
        !isLoading &&
        permissions.length > 0 &&
        permissions.every((permission) => hasPermission(permission, userPermissions))
      );
    },
    [isLoading, userPermissions],
  );

  return {
    isLoading,
    userPermissions,
    hasPermissions,
  };
};
