import React from 'react';

import { Spinner } from 'components/common';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';

import { ActionContentWidthHandler } from '../types';
import { MonetizationEmptyData } from './MonetizationEmptyData';
import { MonetizationTable } from './MonetizationTable';

import * as Styled from 'components/common/Table/Table.styles';

export const MonetizationTab: React.FC<ActionContentWidthHandler> = ({ getActionContentWidth }) => {
  const { data, loading } = useOrganizationQuery();
  const organization = data?.me?.organization;
  const monetizationSettings = organization?.monetizationSettings;

  if (loading && !organization) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (monetizationSettings?.length && organization?.id) {
    return (
      <MonetizationTable
        monetizationSettings={monetizationSettings}
        organization={organization}
        getActionContentWidth={getActionContentWidth}
      />
    );
  } else {
    return <MonetizationEmptyData />;
  }
};
