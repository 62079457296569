import React from 'react';

import { useIsMounted } from './useIsMounted';

/**
 * Used as a drop-in replacement for `React.useState`.
 *
 * Useful when setting state in async callback, where the owner component
 * may no longer be mounted in the VDOM, resulting in React warning.
 *
 * Note: Cancelling async callbacks may be preferred instead. Use with caution
 * to prevent any unexpected memory leaks. [More info](https://youtu.be/8BNdxFzMeVg).
 */
export function useSafeState<S>(
  initialState: S | (() => S),
): [S, React.Dispatch<React.SetStateAction<S>>];
export function useSafeState<S = undefined>(): [
  S | undefined,
  React.Dispatch<React.SetStateAction<S | undefined>>,
];
export function useSafeState<S = undefined>(initialState?: S) {
  const [state, setState] = React.useState(initialState);
  const isMounted = useIsMounted();

  const safeSetState = React.useCallback<typeof setState>(
    (...args) => {
      if (!isMounted.current) return;
      setState(...args);
    },
    [isMounted],
  );

  return [state, safeSetState];
}
