import { Box, Button } from '@material-ui/core';
import { last } from 'ramda';
import React from 'react';

import { FileUpload, Select } from 'components/common';
import { Columns } from 'components/common/Wrapper/Layout';
import { Permission } from 'config/permissions';
import { pushError } from 'context/globalStream';
import { useCreateVideoCaptions, useUpdateVideoCaptions } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useLanguageList } from 'hooks/useLanguageList';
import { useSafeState } from 'hooks/useSafeState';
import { VideoCaptions } from 'models';
import { blobToBase64 } from 'utils/files';

import * as Styled from './UploadField.styles';

type AllowedExtensions = 'vtt';

const allowedMimeTypes: Record<AllowedExtensions, string> = {
  vtt: 'text/vtt',
};

const setMimeType = (blob: Blob, mimeType: string) => {
  return blob.slice(0, blob.size, mimeType);
};

interface Props {
  id: string;
  videoId: string;
  captions: VideoCaptions[];
}

export const UploadField: React.FC<Props> = ({ id, videoId, captions }) => {
  const [, setIsLoading] = useSafeState(false);
  const [captionBase64, setCaptionBase64] = useSafeState<string>();
  const [filename, setFilename] = useSafeState<string>();
  const [languageCode, setLanguageCode] = React.useState('en');
  const [isInvalidExtension, setIsInvalidExtension] = React.useState(false);

  const { hasPermissions } = useUserPermissions();
  const canCreate = hasPermissions([Permission.CreateVideoCaption]);
  const canChange = hasPermissions([Permission.ChangeVideoCaption]);

  const [createVideoCaptions] = useCreateVideoCaptions(videoId);
  const [updateVideoCaptions] = useUpdateVideoCaptions(videoId);

  const onDrop = React.useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        setIsLoading(true);
        setFilename(file.name);

        const extension = getExtension(file.name);
        if (!isExtensionAllowed(extension)) {
          setIsInvalidExtension(true);
          // TODO: close warning after correct file upload - requires modifications to globalStream
          pushError({ message: `Unsupported file extension: ".${extension}"` });
          return;
        }

        setIsInvalidExtension(false);
        const mimeType = allowedMimeTypes[extension];

        blobToBase64(setMimeType(file, mimeType))
          .then((base64) => {
            setCaptionBase64(base64);
          })
          .catch(() => {
            pushError({ message: 'File conversion failed. Try to upload again.' });
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    },
    [setCaptionBase64, setFilename, setIsLoading],
  );

  const onUpload = async () => {
    if (!captionBase64 || !filename || !languageCode) return;

    const existingCaptions = captions.find((caption) => caption.languageCode === languageCode);
    if (existingCaptions) {
      if (!canChange) {
        pushError({ message: "You don't have required permissions to update existing captions." });
        return;
      }
      await updateVideoCaptions({
        variables: {
          videoCaption: {
            captionBase64: captionBase64,
            name: filename,
          },
          id: existingCaptions.id,
        },
      });
    } else {
      if (!canCreate) {
        pushError({ message: "You don't have required permissions to upload new captions." });
        return;
      }
      await createVideoCaptions({
        variables: {
          videoCaption: {
            captionBase64: captionBase64,
            languageCode,
            name: filename,
          },
          videoId,
        },
      });
    }

    setCaptionBase64(undefined);
    setFilename(undefined);
  };

  const languageList = useLanguageList();

  if (!(canCreate && canChange)) {
    return null;
  }

  return (
    <Styled.Wrapper $isInvalidExtension={isInvalidExtension}>
      <Box display="flex" alignItems="center">
        <Columns columnGap={10} display="flex" alignItems="center">
          <FileUpload id={id} onDrop={onDrop}>
            {(rootProps) => {
              return (
                <Styled.UploadButton {...rootProps} title={filename}>
                  <Styled.BoldAndCut>
                    {captionBase64 && filename ? filename : 'Select file…'}
                  </Styled.BoldAndCut>
                </Styled.UploadButton>
              );
            }}
          </FileUpload>
          <Select
            id="captions_language_select"
            placeholder="Select language"
            value={languageCode}
            onChange={({ currentTarget }) => {
              setLanguageCode(currentTarget.value as string);
            }}
            options={languageList.map(({ code, label }) => {
              return { label, value: code };
            })}
          />
        </Columns>
        <Box ml="auto">
          <Button onClick={onUpload} disabled={!captionBase64 || isInvalidExtension}>
            Upload
          </Button>
        </Box>
      </Box>
      <Styled.AcceptedFormats>Accepted format: .vtt</Styled.AcceptedFormats>
    </Styled.Wrapper>
  );
};

function getExtension(filename: string) {
  return last(filename.split('.'));
}

function isExtensionAllowed(extension: string | undefined): extension is AllowedExtensions {
  return extension ? allowedMimeTypes.hasOwnProperty(extension) : false;
}
