import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';

import { ReactComponent as Chevron } from 'assets/icons/chevron-up.svg';
import { ReactComponent as Upload } from 'assets/icons/upload-cloud.svg';
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg';

import * as Styled from './UploadButton.styled';

interface UploadButtonProps {
  handleUploadSelect: () => void;
  handleYoutubeSelect: () => void;
}

interface DropdownItemProps {
  children: React.ReactNode;
  onClick: () => void;
  dataTestId?: string;
  dropdownItemDescription?: string;
}

const DropdownItem = ({
  children,
  onClick,
  dataTestId,
  dropdownItemDescription,
}: DropdownItemProps) => (
  <Styled.DropdownItemBox
    height="50px"
    display="flex"
    justifyContent="center"
    flexDirection="column"
    p="0 20px"
    onClick={onClick}
    data-testid={dataTestId}
  >
    <Box display="flex" flexDirection="row">
      {children}
    </Box>
    {dropdownItemDescription ? (
      <Styled.DropdownItemDescription>{dropdownItemDescription}</Styled.DropdownItemDescription>
    ) : null}
  </Styled.DropdownItemBox>
);

const UploadButton = ({ handleUploadSelect, handleYoutubeSelect }: UploadButtonProps) => {
  const [dropMenuVisible, setDropMenuVisibility] = useState<boolean>(false);

  const handleUploadSelectClick = () => {
    handleUploadSelect();
    setDropMenuVisibility(false);
  };

  const handleYoutubeSelectClick = () => {
    handleYoutubeSelect();
    setDropMenuVisibility(false);
  };

  return (
    <Styled.ButtonWrapper>
      <Button
        variant="contained"
        color="primary"
        data-testid="uploadButton"
        onClick={() => setDropMenuVisibility(!dropMenuVisible)}
        endIcon={
          <Styled.ChevronWrapper>
            <Chevron />
          </Styled.ChevronWrapper>
        }
      >
        Add Video
      </Button>
      {dropMenuVisible && (
        <Styled.DropdownMenu
          bgcolor="common.white"
          boxShadow={5}
          borderRadius="15px"
          position="absolute"
          bottom="0"
          right="0"
          overflow="hidden"
          zIndex="1"
        >
          <DropdownItem onClick={handleUploadSelectClick} dataTestId="dropUpload">
            <Box display="flex" alignItems="center" color="primary.main">
              <Upload />
            </Box>{' '}
            From local disk
          </DropdownItem>
          <DropdownItem
            onClick={handleYoutubeSelectClick}
            dataTestId="dropYoutube"
            dropdownItemDescription="It won’t be possible to republish this Video on Youtube."
          >
            <Box display="flex" alignItems="center" color="primary.main">
              <Youtube />
            </Box>
            From YouTube
          </DropdownItem>
        </Styled.DropdownMenu>
      )}
    </Styled.ButtonWrapper>
  );
};

export default UploadButton;
