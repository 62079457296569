import React from 'react';
import { DeepMap, useForm, useWatch } from 'react-hook-form';

import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { exceedsMaxAllowedFileSize } from 'utils/validation';

import {
  ACCEPTED_FORMATS_ERROR_MESSAGE,
  ACCEPTED_IMAGE_FORMATS,
  FORM_ID,
  MAX_IMAGE_SIZE,
  MAX_SIZE_ERROR_MESSAGE,
} from './constants';
import { CTAImagePagePreview } from './CTAImagePagePreview';
import { CTAImagePageSidebar } from './CTAImagePageSidebar';
import { CreateImageCTAInput } from './types';

interface Props {
  imageCTA?: CreateImageCTAInput;
  isLoading: boolean;
  isCreated?: boolean;
  handleSave: (data: CreateImageCTAInput, dirtyFields: DeepMap<CreateImageCTAInput, true>) => void;
}

export const CTAImagePageView: React.FC<Props> = ({
  imageCTA,
  handleSave,
  isLoading,
  isCreated,
}) => {
  const {
    control,
    handleSubmit,
    errors,
    formState,
    setError,
    clearErrors,
  } = useForm<CreateImageCTAInput>({
    mode: 'onChange',
    defaultValues: imageCTA,
  });
  const { isDirty, dirtyFields } = formState;
  const previewBg: string | undefined = useWatch({ control, name: 'imageBase64' });

  const handleFileValidation = React.useCallback(
    async (file: File) => {
      if (exceedsMaxAllowedFileSize(MAX_IMAGE_SIZE, file.size)) {
        setError('imageBase64', { message: MAX_SIZE_ERROR_MESSAGE });
        return false;
      }

      if (file.name) {
        const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
        if (!ACCEPTED_IMAGE_FORMATS.includes(fileExtension)) {
          setError('imageBase64', { message: ACCEPTED_FORMATS_ERROR_MESSAGE });
          return false;
        }
      }

      clearErrors('imageBase64');
      return true;
    },
    [setError, clearErrors],
  );

  return (
    <ExtensibleSidebar
      sideContent={
        <form id={FORM_ID} onSubmit={handleSubmit((data) => handleSave(data, dirtyFields))}>
          <CTAImagePageSidebar
            control={control}
            errors={errors}
            handleFileValidation={handleFileValidation}
          />
        </form>
      }
      mainContent={
        <CTAImagePagePreview
          previewBg={previewBg || imageCTA?.imageBase64}
          isLoading={isLoading}
          hasUnsavedChanges={!isCreated && isDirty}
          disabled={!isDirty}
        />
      }
    />
  );
};
