import React, { useCallback } from 'react';
import { useEventCallback } from 'rxjs-hooks';

import { handleMouseHover } from 'components/VideoPlayer/ProgressBar/helpers';
import { usePlayerWidthObservable } from 'hooks/usePlayerSize';
import { MomentPlayerPickI } from 'models';

import {
  HoverProgressBar,
  LoadingProgressBar,
  PlayedProgressBar,
  ProgressBar,
  ProgressBarList,
} from '../VideoPlayerElements';
import Marker from './Marker';

export const PROGRESSBAR_TEST_ID = 'progressbar-testid';

interface ProgressProps {
  loadedFraction: number;
  playedFraction: number;
  handleSeeking(seek: number): void;
  moments: MomentPlayerPickI[];
  duration: number;
}

const Progress = ({
  loadedFraction,
  playedFraction,
  handleSeeking,
  moments,
  duration,
}: ProgressProps) => {
  const playerWidth = usePlayerWidthObservable();

  const [hoverCallback, [hoverFraction, showHover]] = useEventCallback(handleMouseHover, [
    0,
    false,
  ] as [number, boolean]);

  const handleMouseDown = useCallback(() => {
    handleSeeking(hoverFraction);
  }, [handleSeeking, hoverFraction]);

  return (
    <ProgressBar
      data-testid={PROGRESSBAR_TEST_ID}
      onMouseOver={hoverCallback}
      onMouseOut={hoverCallback}
      onMouseDown={handleMouseDown}
    >
      <ProgressBarList>
        <LoadingProgressBar scale={loadedFraction} />
        <PlayedProgressBar scale={playedFraction} />
        <HoverProgressBar scale={hoverFraction} show={showHover} />
        {moments.map((marker) => (
          <Marker
            key={marker.id}
            marker={marker}
            handleSeeking={handleSeeking}
            barWidth={playerWidth}
            duration={duration}
          />
        ))}
      </ProgressBarList>
    </ProgressBar>
  );
};

export default Progress;
