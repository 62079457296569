import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { UserI } from 'models';

const USER = gql`
  query UserMeta {
    me {
      avatarUrl
      dateJoined
      email
      id
      name
    }
  }
`;

interface ResponseData {
  me: Pick<UserI, 'avatarUrl' | 'dateJoined' | 'email' | 'id' | 'name'>;
}

interface UseUserQueryOptions extends UseQueryOptions<ResponseData> {}

export const useUserQuery = (options?: UseUserQueryOptions) => {
  return useQuery<ResponseData>(USER, {
    fetchPolicy: 'cache-first',
    ...options,
  });
};
