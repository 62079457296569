import { lightTheme } from '@entertainment-ai/react-component-library';
import styled from 'styled-components/macro';

export const LayoutContainer = styled.div`
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
`;

export const SectionTitle = styled.h2`
  margin-bottom: 30px;
  font-size: 24px;
`;

export const ItemTypeLabel = styled.p`
  margin-bottom: 8px;
  color: ${lightTheme.fontSecondary};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.28px;
`;
