import { TOGGLE_PROMOTE_CONTENT } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, PromoteContentSettingsTypes } from 'models';
import { TogglePromoteContentActions } from 'pages/PromoteContent/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  toggleVideoPromotedContent: Nullable<{
    promotedContentSettings: Nullable<PromoteContentSettingsTypes>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  action: TogglePromoteContentActions;
}

interface UseToggleVideoPromotedContent extends UseMutationOptions<ResponseData, Variables> {}

export const useToggleVideoPromotedContent = (options: UseToggleVideoPromotedContent = {}) => {
  return useMutation<ResponseData, Variables>(TOGGLE_PROMOTE_CONTENT, {
    pathToErrors: 'toggleVideoPromotedContent.errors',
    ...options,
  });
};
