import {
  Facebook,
  Instagram,
  TikTok,
  Twitter,
  YouTube,
} from '@entertainment-ai/react-component-library';
import { Box, Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FormLabel, LoadingButton, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { useCreateSocialMediaPlatform, useUpdateSocialMediaPlatform } from 'hooks/mutation';
import { useSafeState } from 'hooks/useSafeState';
import { isValidUrl, validate } from 'utils/validation';

import { AddSocialMediaForm, AddSocialMediaProps } from './types';

import * as Styled from '../OrganizationTab/ChangeNameModal.styles';

const socialMediaOptions = [
  { label: Facebook, value: 'Facebook' },
  { label: TikTok, value: 'TikTok' },
  { label: Twitter, value: 'Twitter' },
  { label: YouTube, value: 'YouTube' },
  { label: Instagram, value: 'Instagram' },
];

export const AddSocialMedia: React.VFC<AddSocialMediaProps> = ({ onClose, isOpen, social }) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const { register, handleSubmit, errors, formState, control } = useForm<AddSocialMediaForm>({
    mode: 'onChange',
  });
  const [
    createSocialMediaPlatform,
    ,
    [createErrorMessage],
    resetCreateErrorMessage,
  ] = useCreateSocialMediaPlatform({
    onCompleted: ({ createSocialMediaPlatform }) => {
      if (!createSocialMediaPlatform?.errors.length) {
        onClose();
      }
    },
  });
  const [
    updateSocialMediaPlatform,
    ,
    [updateErrorMessage],
    resetUpdateErrorMessage,
  ] = useUpdateSocialMediaPlatform({
    onCompleted: ({ updateSocialMediaPlatform }) => {
      if (!updateSocialMediaPlatform?.errors.length) {
        onClose();
      }
    },
  });

  const { isDirty, isValid } = formState;

  const onSubmit = async (data: AddSocialMediaForm) => {
    setIsLoading(true);
    if (social) {
      await updateSocialMediaPlatform({
        variables: {
          id: social.id,
          platform: data,
        },
      });
    } else {
      await createSocialMediaPlatform({
        variables: {
          platform: data,
        },
      });
    }
    setIsLoading(false);
  };

  const handleExited = () => {
    resetCreateErrorMessage();
    resetUpdateErrorMessage();
    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpen}
      heading={`${social ? 'Edit' : 'Add'} Social Media Platform`}
      width={600}
    >
      <form name="add-social-media" onSubmit={handleSubmit(onSubmit)}>
        <Stack display="flex" alignItems="center" flexDirection="column" mt="50px" rowGap={50}>
          {createErrorMessage || updateErrorMessage ? (
            <Styled.ErrorMessage
              dangerouslySetInnerHTML={{ __html: createErrorMessage || updateErrorMessage }}
            />
          ) : null}
          <Stack textAlign="left" width="400px">
            <Box display="flex">
              <Box>
                <Controller
                  render={({ onChange, ...props }) => (
                    <>
                      <FormLabel id="platformName">Platform</FormLabel>
                      <Select variant="outlined" onChange={onChange} {...props} disabled={!!social}>
                        {socialMediaOptions.map(({ value, label }) => {
                          const Icon = label;
                          return (
                            <MenuItem key={value} value={value.toUpperCase()}>
                              <Icon />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </>
                  )}
                  name="platformName"
                  control={control}
                  defaultValue={social?.platformName.toUpperCase() || ''}
                  rules={{ required: true }}
                />
              </Box>
              <Box width="100%" marginLeft="25px">
                <TextInput
                  name="label"
                  label="Label"
                  ref={register({ required: true })}
                  error={!!errors.label}
                  errorMessage={errors.label?.message}
                  defaultValue={social?.label}
                  mb="0"
                  maxLength={32}
                />
              </Box>
            </Box>
            <TextInput
              name="accountUrl"
              label="URL"
              error={!!errors.accountUrl}
              errorMessage={errors.accountUrl?.message}
              defaultValue={social?.accountUrl}
              mb="0"
              maxLength={2048}
              ref={register({
                required: true,
                validate: validate<string>(
                  (value) => value.length !== 0 && isValidUrl(value),
                  'URL is in incorrect format',
                ),
              })}
            />
          </Stack>
          <Columns display="flex" flexDirection="row" justifyContent="center">
            {!social ? (
              <Button variant="outlined" onClick={onClose} color="secondary" type="button">
                Cancel
              </Button>
            ) : null}
            <LoadingButton
              disabled={!isValid || !isDirty}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              {social ? 'Update' : 'Save'}
            </LoadingButton>
          </Columns>
        </Stack>
      </form>
    </Dialog>
  );
};
