import { Box, Checkbox } from '@material-ui/core';
import React from 'react';

import YoutubeLogo from 'assets/images/yt_logo_rgb_light.png';
import { Stack } from 'components/common/Wrapper/Layout';

import * as Styled from '../PublishVideo.styles';

interface Props {
  id: string;
  label: string;
  isLocked?: boolean;
  isSelected: boolean;
  onSelect: (event: React.SyntheticEvent<HTMLElement>) => void;
  description?: React.ReactNode;
  editButton?: React.ReactNode;
}

export const Target: React.FC<Props> = ({
  id,
  label,
  isLocked = false,
  isSelected,
  onSelect,
  description,
  editButton,
}) => {
  const checkboxId = `publishing-platform-${id}`;
  return (
    <Styled.Platform disabled={isLocked}>
      <Box my="-12px">
        <Checkbox
          onClick={onSelect}
          checked={isSelected}
          disabled={isLocked}
          data-platform={id}
          id={checkboxId}
        />
      </Box>
      <Styled.DetailsWrapper>
        <Stack rowGap={10}>
          {label === 'YouTube' ? (
            <Styled.PlatformName as="label" htmlFor={checkboxId}>
              <img src={YoutubeLogo} style={{ height: '20px' }} alt="YouTube logo" />
            </Styled.PlatformName>
          ) : (
            <Styled.PlatformName as="label" htmlFor={checkboxId}>
              {label}
            </Styled.PlatformName>
          )}
          {description && <Styled.PlatformDetails>{description}</Styled.PlatformDetails>}
        </Stack>
      </Styled.DetailsWrapper>
      {editButton && <Styled.EditButtonWrapper>{editButton}</Styled.EditButtonWrapper>}
    </Styled.Platform>
  );
};
