import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextInput } from 'components/common';
import { TimestampBlock } from 'components/common/TimestampBlock';
import { TimestampInput } from 'components/TimelineEditor/TimestampInput';
import { MIN_TIMESTAMP_LENGTH } from 'config/constants';
import { useTimestampInputValidation } from 'hooks/useTimestampInputValidation';
import { hmsStringToMs, msToHMSString, timeRangeToHMSStrings } from 'utils/time';
import { isNotEmptyString, validate } from 'utils/validation';

import { EMPTY_TIMESTAMP_FIELD_ID } from '../constants';
import { CTAList } from '../CTA/CTAList';
import { CTATimestamp } from '../type';
import { VideoCTAPageContext } from '../useTimestampContext';

import { ErrorMessage } from '../VideoCTAPage.styles';

interface Props {
  item: CTATimestamp;
  onSubmit: (data: CTATimestamp) => void;
  onCancel?: () => void;
}

export const TimestampForm: React.FC<Props> = ({ item, onSubmit, onCancel }) => {
  const { currentCTA, currentCTAs, timestamps, duration } = useContext(VideoCTAPageContext);

  const { register, errors, handleSubmit } = useForm<CTATimestamp>({
    defaultValues: item,
  });

  const allTimestamps = timestamps
    .map(({ ctas, ...field }) => field)
    .filter((timestamp) => timestamp.id !== item?.id);

  const {
    startTimestampOverlap,
    endTimestampOverlap,
    errorMessage,
    setError,
    validateInput,
    range,
    setRange,
    getNextAvailableTimeSlotStartTime,
  } = useTimestampInputValidation({ videoDuration: duration, allTimestamps });

  useEffect(() => {
    if (item.id === EMPTY_TIMESTAMP_FIELD_ID) {
      const timestamps = allTimestamps.sort((a, b) => a.endTimestamp - b.endTimestamp);
      const suggestedStartTime = getNextAvailableTimeSlotStartTime(timestamps);

      setRange(
        timeRangeToHMSStrings([suggestedStartTime, suggestedStartTime + MIN_TIMESTAMP_LENGTH]),
      );
    } else {
      setRange(timeRangeToHMSStrings(item.ranges));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  useEffect(() => {
    const { error } = validateInput({
      newStart: range[0] || msToHMSString(0),
      newEnd: range[1] || msToHMSString(MIN_TIMESTAMP_LENGTH),
    });

    if (error) {
      setError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const onInputChange = (index: number) => (value: string, commit?: boolean) => {
    const newRange = range.slice();
    newRange[index] = value;
    setRange(newRange);
    if (commit) {
      onInputCommit(newRange);
    }
  };

  const onInputCommit = (range?: string[]) => {
    setError(null);

    const { newTimeRange, error } = validateInput(
      range ? { newStart: range[0], newEnd: range[1] } : {},
    );

    if (newTimeRange) {
      setRange(timeRangeToHMSStrings(newTimeRange));
      return;
    }

    setError(error);
  };

  const onSave = (data: CTATimestamp) => {
    return onSubmit({
      ...item,
      ...data,
      ranges: [hmsStringToMs(range[0]), hmsStringToMs(range[1])],
      ctas: currentCTAs,
    });
  };
  return (
    <TimestampBlock
      title={item.name || 'New timestamp'}
      saveButtonText="Save timestamp"
      onCancel={onCancel}
      onSave={handleSubmit(onSave)}
      isEdit
      hasCancelButton
      hasSaveButton={!!currentCTAs.length}
      disabled={!!currentCTA}
    >
      <TextInput
        label="Name *"
        name="name"
        defaultValue={item.name}
        placeholder="Enter name"
        ref={register({
          validate: validate(isNotEmptyString, 'Name cannot be empty'),
        })}
        error={!!errors.name}
        errorMessage={errors.name?.message}
        mb="40px"
      />

      <Box display="flex" style={{ gap: '12px' }} mb="18px">
        <TimestampInput
          name="startTimestamp"
          value={range[0]}
          onChange={onInputChange(0)}
          onCommit={onInputCommit}
          label="Start *"
          ref={register()}
          isError={startTimestampOverlap}
        />
        <TimestampInput
          name="endTimestamp"
          value={range[1]}
          onChange={onInputChange(1)}
          onCommit={onInputCommit}
          label="End *"
          ref={register()}
          isError={endTimestampOverlap}
        />
      </Box>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <CTAList ctas={currentCTAs} isTimestampEdit />
    </TimestampBlock>
  );
};
