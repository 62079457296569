import { Box } from '@material-ui/core';
import React from 'react';

import { DropdownButton } from 'components/common/DropdownButton';
import {
  EmptyTabAddButton,
  EmptyTabInformationButton,
  EmptyTabTitle,
} from 'components/common/EmptyTab';

import { EndCardLinks } from './constants';

export const EndCardEmptyData: React.VFC = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p="30px 40px">
      <EmptyTabTitle>Create Custom End Cards to use them in your videos.</EmptyTabTitle>
      <EmptyTabAddButton>
        <DropdownButton dropdownLinks={EndCardLinks} />
      </EmptyTabAddButton>
      <EmptyTabInformationButton onClick={() => {}}>
        What are Custom End Cards
      </EmptyTabInformationButton>
    </Box>
  );
};
