import { Box, Button } from '@material-ui/core';
import React from 'react';

import { Typography } from 'components/common';
import { Columns } from 'components/common/Wrapper/Layout';

interface VideosBulkChangesProps {
  count: number;
  onDelete: () => void;
  onUnpublish: () => void;
  showUnpublishButton: boolean;
}

const VideosBulkChanges = ({
  count,
  onDelete,
  onUnpublish,
  showUnpublishButton,
}: VideosBulkChangesProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography component="span">
        {count} video{count > 1 && 's'} selected
      </Typography>
      <Columns ml="18px" display="flex">
        {showUnpublishButton ? (
          <Button variant="contained" onClick={onUnpublish} color="secondary">
            Unpublish
          </Button>
        ) : null}
        <Button variant="contained" onClick={onDelete} color="secondary">
          Delete
        </Button>
      </Columns>
    </Box>
  );
};

export default VideosBulkChanges;
