import { Range, TimeRange } from 'models';
import { getTimestampIntersection, msToFloorSeconds, timeRangeToString } from 'utils/time';

import { HORIZONTAL_PADDING } from './TimelineEditor.styles';

const MIN_SCALE_RANGE = 10_000; // ms
const SIDE_PADDING = 10_000; // ms

interface RescaleRangeOptions {
  minRange?: number;
  maxRange: number;
  range: TimeRange;
  scale?: number;
  /** number in range of `-1` and `1` */
  centerOffset?: number;
}

// TODO: check if scaling based on "zoom level" could be more efficient
// TODO: check if we could use bezier instead of inversed hyperbola (where x=0 is min scale, x=1 is video duration)
export function rescaleRange({
  minRange = 0,
  maxRange,
  range,
  scale = 0,
  centerOffset = 0,
}: RescaleRangeOptions): TimeRange {
  if (scale === 0 || minRange === maxRange) {
    return range;
  }

  // our `x` for calculating graphs
  const maxVisibleRange = msToFloorSeconds(range[1] - range[0]);

  const duration = msToFloorSeconds(maxRange - minRange);
  // factor aimed for when maximum range is set
  const maxRangeFactor = 4;
  // value used to move whole graph to make sure we are never reaching asymptotes
  const boundaryVector = 1;
  // scale used to smooth out the hiperbole curve
  const graphScale = duration * 0.15;
  const durationScale = duration / maxRangeFactor;

  // Calculate step using inversed hyperbola.
  // It makes more sense visually: https://www.desmos.com/calculator/fh95nvwrsk
  const rangeStep =
    // normalize graph
    1000 *
    graphScale *
    // inversed hyperbola -1/x
    (-(
      (durationScale / graphScale + boundaryVector) /
      // move zero to the right
      ((maxVisibleRange - duration) / (graphScale * maxRangeFactor) - boundaryVector)
    ) - // move graph downwards
      boundaryVector);

  const scaleStep = Math.sign(scale) * Math.round(rangeStep);

  const leftSideChange = -scaleStep * (1 + centerOffset);
  const rightSideChange = scaleStep * (1 - centerOffset);

  return [
    Math.max(minRange, range[0] + leftSideChange),
    Math.min(maxRange, range[1] + rightSideChange),
  ];
}

export function isContainedInAllowedRange(timeRange: TimeRange, videoDuration: number) {
  const timespan = timeRange[1] - timeRange[0];
  return timespan >= MIN_SCALE_RANGE && timespan <= videoDuration;
}

export function isVerticalScroll(event: WheelEvent) {
  return !!event.deltaY;
}

// TODO: convert to range duration percentage instead of const value
export function padTimeRange(timeRange: TimeRange, videoDuration: number) {
  return getTimestampIntersection(
    [timeRange[0] - SIDE_PADDING, timeRange[1] + SIDE_PADDING],
    [0, videoDuration],
  );
}

export function createNotInRangeWarning(newRange: TimeRange) {
  return `[Timeline] Calculated range "${newRange[0]} - ${
    newRange[1]
  } (${timeRangeToString(newRange, { separator: '-' })})" is not valid. Skipping update.`;
}

export function roundRulerRangeToFullSeconds(range: TimeRange): TimeRange {
  const diff = msToFloorSeconds(range[1] - range[0]);
  return [range[0], diff < 1 ? range[0] + 1000 : range[0] + diff * 1000];
}

/**
 * Returns a number (in range of `-1` and `1`) that represents an offset between mouse position
 * and center of the target element.
 *
 * - `-1` means the cursor is touching the left side of the element.
 * - `0` means the cursor is in the center of the element.
 * - `1` means the cursor is touching the right side of the element.
 */
export function getOffsetFromMousePosition(event: MouseEvent, targetElement: Element) {
  const { x, width } = targetElement.getBoundingClientRect();
  const mousePositionInsideElement = event.pageX - x;
  const positionFraction = mousePositionInsideElement / width;

  if (positionFraction < 0 || positionFraction > 1) {
    // Mouse is outside of the element, which is technically impossible because the event should
    // be targetting the element and should only fire when mouse is on the element.
    return undefined;
  }

  return 2 * positionFraction - 1;
}

/**
 * @param rulerRange - range of the visible area, in ms
 * @param rulerWidth - width of the visible area, in px
 * @param ms - timestamp of needed position
 * @returns position of `ms` on the timeline, in px
 */
export function getPositionOnTimeline(rulerRange: TimeRange, rulerWidth: number, ms: number) {
  const visibleDuration = rulerRange[1] - rulerRange[0];
  const msPerPx = rulerWidth / visibleDuration;
  // round to prevent artifacts
  return Math.round((ms - rulerRange[0]) * msPerPx) + HORIZONTAL_PADDING;
}

/**
 * @param rulerRange - range of the visible area, in ms
 * @param rulerWidth - width of the visible area, in px
 * @param px - position of needed timestamp
 * @returns timestamp of `px` on the timeline, in ms
 */
export function getTimestampOnTimeline(rulerRange: TimeRange, rulerWidth: number, px: number) {
  const visibleDuration = rulerRange[1] - rulerRange[0];
  const pxPerMs = visibleDuration / rulerWidth;
  // round to 1ms for backend compatibility
  return Math.round((px - HORIZONTAL_PADDING) * pxPerMs + rulerRange[0]);
}

export function getTimeBoundsForRange(
  targetRange: Range | undefined,
  allRanges: Range[],
  videoDuration: number,
): TimeRange {
  if (!targetRange) {
    return [0, videoDuration];
  }

  const index = allRanges.findIndex((segment) => segment.id === targetRange.id);

  if (index < 0) {
    return [0, videoDuration];
  }

  return [
    allRanges[index - 1]?.endTimestamp ?? 0,
    // end timestamp is inclusive, so it needs to be less than start of the following range
    allRanges[index + 1] ? allRanges[index + 1].startTimestamp - 1 : videoDuration,
  ];
}
