import { Box, Button } from '@material-ui/core';
import React from 'react';

import { Spinner, Typography } from 'components/common';
import { BulkResource, ToggleAction } from 'context/queries/bulk';
import { useBulkToggleMutation } from 'hooks/mutation';
import { UseMutationOptions } from 'hooks/useMutation';
import { Item, useSelectedListItemsVisibility } from 'hooks/useSelectedListItemsVisibility';

type BulkType = BulkResource.Moment | BulkResource.ObjectAnnotation;

const labelMap: Record<BulkType, string> = {
  [BulkResource.Moment]: 'moment',
  [BulkResource.ObjectAnnotation]: 'object',
};

interface BulkChangesProps {
  type: BulkType;
  count: number;
  handleDeleteClick: () => void;
  selected: { [key: string]: boolean };
  items: Item[];
  refetchQueries: UseMutationOptions['refetchQueries'];
  canToggle: boolean;
  canDelete: boolean;
}

const BulkChanges = ({
  type,
  count,
  handleDeleteClick,
  selected,
  items,
  refetchQueries,
  canToggle,
  canDelete,
}: BulkChangesProps) => {
  const [selectedVisible, selectedInvisible, allSelected] = useSelectedListItemsVisibility(
    selected,
    items,
  );

  const [disableAll, loadingDisabling] = useBulkToggleMutation(
    ToggleAction.disable,
    type,
    allSelected,
    refetchQueries,
  );

  const [enableAll, loadingEnabling] = useBulkToggleMutation(
    ToggleAction.enable,
    type,
    allSelected,
    refetchQueries,
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {loadingDisabling || loadingEnabling ? (
        <Spinner />
      ) : (
        <>
          <Typography component="span">
            {count} {labelMap[type]}
            {count > 1 && 's'} selected
          </Typography>
          {canToggle && selectedVisible.length > 0 && (
            <Box ml="18px">
              <Button variant="contained" onClick={() => disableAll()} color="secondary">
                Disable
              </Button>
            </Box>
          )}
          {canToggle && selectedInvisible.length > 0 && (
            <Box ml="18px">
              <Button variant="contained" onClick={() => enableAll()} color="secondary">
                Enable
              </Button>
            </Box>
          )}
          {canDelete && (
            <Box ml="18px">
              <Button variant="contained" onClick={handleDeleteClick} color="primary">
                Delete
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BulkChanges;
