import { IconButton, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { DeleteModal } from 'components/common';
import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { useDeleteSocialMediaPlatform } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';

import { AddSocialMedia } from './AddSocialMedia';
import { EditSocialMediaProps, socialMediaPlatformMap, SocialMediaTableRowProps } from './types';

export const SocialMediaTableRow = ({ social }: SocialMediaTableRowProps) => {
  return (
    <Table.Row key={social.id}>
      <Table.Cell style={{ verticalAlign: 'bottom' }}>
        {socialMediaPlatformMap[social.platformName]}
      </Table.Cell>
      <Table.Cell>{social.label}</Table.Cell>
      <Table.Cell>{social.accountUrl}</Table.Cell>
      <Table.Cell>
        <EditSocialMedia social={social} />
      </Table.Cell>
    </Table.Row>
  );
};

const EditSocialMedia: React.VFC<EditSocialMediaProps> = ({ social }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const [deleteSocialMediaPlatform] = useDeleteSocialMediaPlatform();

  const { hasPermissions } = useUserPermissions();
  const canUserUpdateSocialMediaPlatform = hasPermissions([Permission.ChangeSocialMedia]);
  const canUserDeleteSocialMediaPlatform = hasPermissions([Permission.DeleteSocialMedia]);

  const handleRemovePlatform = () => {
    deleteSocialMediaPlatform({
      variables: {
        id: social.id,
      },
    });
  };

  return (
    <>
      {canUserUpdateSocialMediaPlatform ? (
        <>
          <Tooltip title="Edit" arrow placement="top">
            <IconButton color="inherit" edge="start" onClick={() => setIsModalOpen(true)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <AddSocialMedia
            social={social}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </>
      ) : null}
      {canUserDeleteSocialMediaPlatform ? (
        <>
          <Tooltip title="Remove" arrow placement="top">
            <IconButton color="inherit" edge="start" onClick={() => setIsRemoveModalOpen(true)}>
              <Trash />
            </IconButton>
          </Tooltip>
          <DeleteModal
            open={isRemoveModalOpen}
            count={1}
            type="platform"
            handleDelete={handleRemovePlatform}
            handleClose={() => setIsRemoveModalOpen(false)}
          />
        </>
      ) : null}
    </>
  );
};
