import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const THUMBNAILS_PART = `
  thumbnailId
  thumbnails {
    id
    imageUrl
  }
`;

// Video

export const GET_VIDEO_THUMBNAILS = gql`
  query GetVideoThumbnails($videoId: ID!) {
    videoById(id: $videoId) {
      id
      thumbnailUrl
      thumbnailId
      thumbnails {
        id
        imageUrl
      }
    }
  }
`;

export const CREATE_VIDEO_THUMBNAIL_MUTATION = gql`
  mutation CreateVideoThumbnail($imageBase64: Base64Image!, $videoId: ID!) {
    createVideoThumbnail(imageBase64: $imageBase64, videoId: $videoId) {
      thumbnail {
        id
        imageUrl
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_VIDEO_THUMBNAIL_MUTATION = gql`
  mutation DeleteVideoThumbnail($id: ID!) {
    deleteVideoThumbnail(id: $id) {
      deletedVideoThumbnailId
      ${ERRORS_PART}
    }
  }
`;

// Moment

export const GET_MOMENT_THUMBNAILS = gql`
  query GetMomentThumbnails($momentId: ID!) {
    momentById(id: $momentId) {
      id
      thumbnailUrl
      thumbnailId
      thumbnails {
        id
        imageUrl
      }
    }
  }
`;

export const CREATE_MOMENT_THUMBNAIL_MUTATION = gql`
  mutation CreateMomentThumbnail($imageBase64: Base64Image!, $momentId: ID!) {
    createMomentThumbnail(imageBase64: $imageBase64, momentId: $momentId) {
      thumbnail {
        id
        imageUrl
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_MOMENT_THUMBNAIL_MUTATION = gql`
  mutation DeleteMomentThumbnail($id: ID!) {
    deleteMomentThumbnail(id: $id) {
      deletedMomentThumbnailId
      ${ERRORS_PART}
    }
  }
`;

// Object

export const GET_OBJECT_THUMBNAILS = gql`
  query GetObjectThumbnails($objectId: ID!) {
    objectById(id: $objectId) {
      id
      thumbnailUrl
      thumbnailId
      thumbnails {
        id
        imageUrl
      }
    }
  }
`;

export const CREATE_OBJECT_THUMBNAIL_MUTATION = gql`
  mutation CreateObjectThumbnail($imageBase64: Base64Image!, $objectId: ID!) {
    createObjectAnnotationThumbnail(imageBase64: $imageBase64, objectAnnotationId: $objectId) {
      thumbnail {
        id
        imageUrl
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_OBJECT_THUMBNAIL_MUTATION = gql`
  mutation DeleteObjectThumbnail($id: ID!) {
    deleteObjectAnnotationThumbnail(id: $id) {
      deletedObjectAnnotationThumbnailId
      ${ERRORS_PART}
    }
  }
`;
