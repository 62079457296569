import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { FormLabel } from 'components/common';
import CustomColor from 'components/common/CustomColor';
import { NumberInput } from 'components/common/NumberInput';
import { MeasurementUnit } from 'components/common/NumberInput/types';
import { validateColor } from 'utils/validation';

import { validateBackgroundOpacity } from '../helpers';

import { FormField } from 'components/common/TagsField/TagsField.styles';

interface Props {
  errors: FieldErrors;
  control: Control;
}

export const ButtonBackground: React.FC<Props> = ({ errors, control }) => {
  return (
    <>
      <FormLabel>Background</FormLabel>
      <FormField mb="40px">
        <Controller
          name="backgroundColor"
          control={control}
          rules={{ validate: validateColor }}
          render={({ value, onChange }) => (
            <CustomColor
              onColorChange={onChange}
              selectedColor={value}
              label="Background color"
              mb="40px"
              error={!!errors.backgroundColor}
              errorMessage={errors.backgroundColor?.message}
            />
          )}
        />
        <Controller
          name="backgroundOpacity"
          control={control}
          rules={{ validate: validateBackgroundOpacity }}
          render={(field) => (
            <NumberInput
              {...field}
              measurement={MeasurementUnit.Percentage}
              step={5}
              label="Background opacity"
              error={!!errors.backgroundOpacity}
              errorMessage={errors.backgroundOpacity?.message}
              info="Background opacity"
              validationMessage="Min. 80%"
              min={80}
            />
          )}
        />
      </FormField>
    </>
  );
};
