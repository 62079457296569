import { UPDATE_SHARE_SOCIAL_ICONS } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, ShareSocialMediaInput } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateShareSocialIcons: Nullable<{
    shareSocialIcons: Nullable<ShareSocialMediaInput[]>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  icons: ShareSocialMediaInput[];
}

interface UseUpdateSocialMediaPlatform extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateShareSocialIcons = (options: UseUpdateSocialMediaPlatform = {}) => {
  return useMutation<ResponseData, Variables>(UPDATE_SHARE_SOCIAL_ICONS, {
    skipGlobalErrorHandling: true,
    ...options,
  });
};
