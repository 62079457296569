import { LiteralUnion } from 'type-fest';

import { Nullable } from 'utils/types';

export enum PlaylistContentType {
  Videos = 'VIDEOS',
  Moments = 'MOMENTS',
}

export enum PlaylistOrdering {
  PublicationDateAscending = 'PUBLICATION_DATE',
  PublicationDateDescending = 'PUBLICATION_DATE_DESC',
  ViewsAscending = 'VIEWS',
  ViewsDescending = 'VIEWS_DESC',
  TitleAlphabetical = 'TITLE',
  TitleAlphabeticalReversed = 'TITLE_DESC',
  Manual = 'MANUAL',
}

/** video or moment */
export interface PlaylistItemType {
  id: string;
  title: string;
  description: string;
  thumbnailUrl: string | null;
  video?: {
    id: string;
  };
}

export enum PlaylistFilterBy {
  Tag = 'TAG',
  InternalTag = 'INTERNAL_TAG',
  Null = 'NULL',
}

export interface PlaylistI {
  id: string;
  created: string;
  name: string;
  items: PlaylistItemType[];
  contentType: PlaylistContentType;
  orderBy: PlaylistOrdering;
  filterBy: PlaylistFilterBy;
  filterValue: string | null;
}

export type DashboardPlaylist = Pick<
  PlaylistI,
  'id' | 'created' | 'name' | 'contentType' | 'orderBy'
>;

export type PlaylistToAdd = Pick<PlaylistI, 'id' | 'name'> & { alreadyAdded?: boolean };

export type EditorPlaylist = Pick<
  PlaylistI,
  'id' | 'contentType' | 'name' | 'orderBy' | 'filterBy' | 'filterValue'
> & {
  items: {
    nodes: Nullable<
      Pick<PlaylistItemType, 'id' | 'description' | 'thumbnailUrl' | 'title' | 'video'>[]
    >;
  };
};

/**
 * Order string can be one (or a combination, joined with `,`) of given values.
 *
 * `column` -> ascending,
 * `-column` -> descending
 */
export type PlaylistsOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'name'
  | '-name'
  | 'created'
  | '-created',
  string
>;
