import { DELETE_MOMENT_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  deleteMoment: Nullable<{
    deletedMomentId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteMomentOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useDeleteMoment = (options: UseDeleteMomentOptions = {}) => {
  return useMutation<ResponseData, Variables>(DELETE_MOMENT_MUTATION, {
    pathToErrors: 'deleteMoment.errors',
    ...options,
  });
};
