import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const UPDATE_SOCIAL_MEDIA_PLATFORM = gql`
  mutation UpdateSocialMediaPlatform($id: ID!, $platform: SocialMediaPlatformInput!) {
    updateSocialMediaPlatform(platform: $platform, id: $id) {
      platform {
        created
        id
        label
        accountUrl
        platformName
      }
      ${ERRORS_PART}
    }
  }
`;

export const CREATE_SOCIAL_MEDIA_PLATFORM = gql`
  mutation CreateSocialMediaPlatform($platform: SocialMediaPlatformInput!) {
    createSocialMediaPlatform(platform: $platform) {
      platform {
        created
        id
        label
        accountUrl
        platformName
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_SOCIAL_MEDIA_PLATFORM = gql`
  mutation DeleteSocialMediaPlatform($id: ID!) {
    deleteSocialMediaPlatform(id: $id) {
      ${ERRORS_PART}
    }
  }
`;
