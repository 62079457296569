import gql from 'graphql-tag';

import { VIDEO_METADATA_FRAGMENT } from '.';
import { ERRORS_PART } from './_errors';

export const UPDATE_VIDEO_MUTATION = gql`
  ${VIDEO_METADATA_FRAGMENT}

  mutation UpdateVideo($id: ID!, $video: UpdateVideoInput!) {
    updateVideo(id: $id, video: $video) {
      video {
        ...VideoMetadata
        internalTags {
          value
        }
        tags {
          value
        }
      }
      ${ERRORS_PART}
    }
  }
`;

export const REPROCESS_FAILED_VIDEO_MUTATION = gql`
  mutation ReprocessFailedVideo($id: ID!) {
    reprocessFailedVideo(videoId: $id) {
      video {
        id
        status
      }
      ${ERRORS_PART}
    }
  }
`;

export const YOUTUBE_VIDEO_UPLOAD_MUTATION = gql`
  mutation UploadRemoteVideo($url: URL!, $pipelineType: ProcessingPipelineType) {
    uploadRemoteVideo(inputUrl: $url, pipelineType: $pipelineType) {
      video {
        id
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_VIDEO_MUTATION = gql`
  mutation DeleteVideo($id: ID!) {
    deleteVideo(id: $id) {
      deletedVideoId
      ${ERRORS_PART}
    }
  }
`;

export const DUPLICATE_VIDEO_MUTATION = gql`
  mutation DuplicateVideo($id: ID!) {
    duplicateVideo(id: $id) {
      duplicate {
        id
      }
      ${ERRORS_PART}
    }
  }
`;

export const VIDEO_UPLOAD_LOCAL = gql`
  mutation UploadLocalVideo(
    $filename: String!
    $multipart: Boolean = false
    $pipelineType: ProcessingPipelineType
  ) {
    uploadLocalVideo(filename: $filename, multipart: $multipart, pipelineType: $pipelineType) {
      videoUploadUrl
      formFieldsData
      video {
        id
      }
      ${ERRORS_PART}
    }
  }
`;

export const NEW_VIDEO_UPLOAD_PART = gql`
  mutation NewMultipartUploadPart($videoId: ID!, $partNumber: Int!) {
    newLocalVideoUploadPart(videoId: $videoId, partNumber: $partNumber) {
      videoUploadUrl
      video {
        id
      }
      ${ERRORS_PART}
    }
  }
`;

export const CONFIRM_LOCAL_VIDEO_UPLOAD_MUTATION = gql`
  mutation ConfirmLocalVideoUpload($s3Key: String!, $videoId: ID!) {
    confirmLocalVideoUpload(s3Key: $s3Key, videoId: $videoId) {
      video {
        id
      }
      ${ERRORS_PART}
    }
  }
`;

export const CANCEL_LOCAL_VIDEO_UPLOAD_MUTATION = gql`
  mutation CancelLocalVideoUpload($s3Key: String!, $videoId: ID!) {
    cancelLocalVideoUpload(s3Key: $s3Key, videoId: $videoId) {
      video {
        id
      }
      ${ERRORS_PART}
    }
  }
`;
