import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton } from 'components/common';
import { TextInput } from 'components/common';
import { TEST_ID } from 'config/test-ids';
import { useDeleteAvatar } from 'hooks/mutation/useDeleteAvatar';
import { useUpdateAccountDetails } from 'hooks/mutation/useUpdateAccountDetails';
import { useUserQuery } from 'hooks/query';
import { Intercom } from 'utils/intercom';
import { Nullable } from 'utils/types';
import { isNotEmptyString, validate } from 'utils/validation';

import { ProfilePicture } from './ProfilePicture';

interface FormData {
  fullName: string;
}

export const AccountTab = () => {
  const { data } = useUserQuery();
  const [removedAvatar, setRemovedAvatar] = useState(false);
  const [userAvatarBase64, setUserAvatarBase64] = useState<Nullable<string>>(null);
  const [
    updateAccountDetails,
    { loading: updateAccountDetailsIsLoading },
  ] = useUpdateAccountDetails();
  const [deleteAvatar] = useDeleteAvatar();

  const { register, errors, handleSubmit } = useForm<FormData>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    setRemovedAvatar(false);
  }, [data?.me.avatarUrl]);

  const handleRemoveAvatar = () => {
    setUserAvatarBase64(null);
    setRemovedAvatar(true);
  };

  const handleSubmitSettings = (fields: FormData) => {
    updateAccountDetails({
      variables: {
        accountDetails: {
          name: fields.fullName.trim() || data?.me.name,
          avatarBase64: userAvatarBase64 || undefined,
        },
      },
    }).then(({ data }) => {
      const user = data?.updateAccountDetails?.user;
      Intercom.update({
        name: user?.name,
        email: user?.email,
      });
    });

    if (removedAvatar) {
      deleteAvatar();
    }
  };

  const handleThumbnailAdded = (base64: string) => {
    setUserAvatarBase64(base64);
    setRemovedAvatar(false);
  };

  return (
    <Box p="30px">
      <ProfilePicture
        avatarUrl={!removedAvatar ? userAvatarBase64 || data?.me.avatarUrl : undefined}
        removeAvatar={handleRemoveAvatar}
        onThumbnailAdded={handleThumbnailAdded}
      />
      <form onSubmit={handleSubmit(handleSubmitSettings)}>
        <Box p="30px 0 0 0" display="flex" alignItems="center" justifyContent="space-between">
          <TextInput
            label="Set new full name"
            name="fullName"
            placeholder={'Type in new full name'}
            style={{ width: '240px' }}
            defaultValue={data?.me.name}
            ref={register({
              required: 'Full name is required',
              validate: {
                whitespace: validate(isNotEmptyString, 'Full name cannot be empty'),
              },
            })}
            errorMessage={errors.fullName?.message}
          />
        </Box>
        <Box p="30px 0 0 0" display="flex" justifyContent="flex-end">
          <LoadingButton
            disabled={updateAccountDetailsIsLoading}
            variant="contained"
            color="primary"
            isLoading={updateAccountDetailsIsLoading}
            type="submit"
            data-testid={TEST_ID.saveAccountButton}
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};
