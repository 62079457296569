import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { WidgetColorTheme, WidgetPresetI } from 'models/Widget';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation CreateWidgetPreset($style: WidgetStyleInput!, $widgetPreset: CreateWidgetPresetInput!) {
    createWidgetPreset(style: $style, widgetPreset: $widgetPreset) {
      widgetPreset {
        id
        name
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  createWidgetPreset: Nullable<{
    widgetPreset: Nullable<Pick<WidgetPresetI, 'id' | 'name'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  style: {
    mainColor: string;
    colorTheme: WidgetColorTheme;
  };
  widgetPreset: {
    name: string;
    googleAnalyticsTrackingId?: string;
    googleAnalytics4MeasurementId?: string;
    endCardId?: string;
  };
}

interface UseCreateWidgetPresetMutationOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export function useCreateWidgetPreset(options?: UseCreateWidgetPresetMutationOptions) {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'createWidgetPreset.errors',
    ...options,
  });
}
