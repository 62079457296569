import {
  Table as MaterialTable,
  TableCell as MaterialTableCell,
  TableHead as MaterialTableHead,
  TableRow as MaterialTableRow,
} from '@material-ui/core';
import { cover, ellipsis } from 'polished';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { Optional } from 'utils/types';

export const TableSpinnerBox = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 300px;
  width: 100%;
`;

export const TableOverlayBox = styled.div<{ isVisible: boolean }>`
  ${cover()};

  background-color: rgba(255, 255, 255, 0.6);
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;
  padding-top: 20%;
  z-index: 1;
`;

export const Table = styled(MaterialTable)`
  color: #1a1a1a;
  font-size: 14px;
  position: relative;
`;

export const TableRow = styled(MaterialTableRow)`
  background-color: #fff;

  td {
    white-space: nowrap;
  }
`;

interface TableHeadProps {
  variant?: string;
}

export const TableHead = styled(MaterialTableHead)<TableHeadProps>`
  height: 50px;
  text-transform: uppercase;

  tr {
    background-color: ${({ variant }) => (variant === 'gray' ? '#F6F7FA' : '#f5f5f5')};
  }
`;

export const HeadRow = styled(TableRow)`
  height: 50px;

  th {
    color: #999999;
    font-size: 12px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }
`;

export const CheckboxCell = styled(MaterialTableCell)`
  height: 80px;
  padding-left: 16px !important;
  width: 75px;
`;

export const ThumbnailCell = styled(MaterialTableCell)`
  padding: 8px 0 !important;
  width: 53px;

  &:first-child {
    padding-left: 16px !important;
  }
`;

interface ThumbnailProps {
  thumbnailUrl?: Optional<string>;
}

export const Thumbnail = styled.div<ThumbnailProps>`
  ${({ thumbnailUrl }) => thumbnailUrl && `background-image: url(${thumbnailUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  flex: 0 0 auto;
  height: 40px;
  width: 53px;
`;

export const TitleCell = styled(MaterialTableCell)`
  height: 100%;
  overflow: hidden;
  width: 90%;
`;

export const TitleBox = styled.div`
  display: inline-flex;
  height: 58px;
  position: relative;
  width: 100%;
`;

export const TitleCenterBox = styled.div`
  ${ellipsis()};
`;

export const TitleWrapper = styled.div`
  color: #1a1a1a;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const MomentTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const MomentSubtitle = styled.span`
  font-size: 12px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const ActionsCell = styled(MaterialTableCell)`
  color: ${materialTheme.palette.text.primary};
  padding-right: 16px !important;
  white-space: nowrap;
  width: 130px;
`;

export const ActionsCellBtn = styled.button`
  background: none;
  border: 0;
  padding: 0;
`;

export const TableWrapper = styled.div`
  position: relative;
`;

export const TableHorizontalScroll = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;

// TODO: refactor this as a regular container, instead of absolute positioning
export const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 102px;
  justify-content: flex-end;
  padding-right: 32px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  width: max-content;

  /* button wrapper */
  a {
    flex-shrink: 0;
  }
`;

export const DateCell = styled(MaterialTableCell)`
  white-space: nowrap;
`;

export const TableFooter = styled.div`
  align-items: center;
  display: flex;
  height: 52px;
  font-size: 14px;
  padding: 0 26px;
`;

export const FooterResults = styled.div``;

export const FooterPaginationWrapper = styled.div`
  align-items: center;
  color: ${materialTheme.palette.secondary.main};
  display: flex;
  margin-left: auto;
`;

export const FooterPageSize = styled.label`
  align-items: center;
  display: flex;
  margin-right: 32px;
  font-family: inherit;

  .MuiSelect-select {
    text-align: right;
    padding-left: 8px;
    padding-right: 24px;
    text-align-last: right;
  }
`;

export const FooterPageNumber = styled.div`
  align-items: center;
  display: flex;
  margin-right: 32px;
`;

export const PaginationResultsLabel = styled.span`
  line-height: 1.43;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const PaginationActionsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const TableHeading = styled.h2`
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
`;

export const DragCell = styled(MaterialTableCell)`
  padding-right: 50px;

  .drag-icon {
    margin-top: 5px;
  }
`;
