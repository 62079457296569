import { Button } from '@material-ui/core';
import React from 'react';

import { URLS } from 'pages/urls';

import { useCreateCustomerPortalSession } from './useCreateCustomerPortalSession';

export const CustomerPortalLink: React.VFC = () => {
  const [createSession] = useCreateCustomerPortalSession();

  const returnUrl = new URL(URLS.organization.paymentPlan, window.location.origin);

  const handleClick = async () => {
    const { data } = await createSession({
      variables: {
        customerPortalSession: {
          returnUrl: returnUrl.toString(),
        },
      },
    });
    const redirectUrl = data?.createCustomerPortalSession?.customerPortalUrl;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <Button onClick={handleClick} variant="outlined" color="secondary">
      Go to Customer Portal
    </Button>
  );
};
