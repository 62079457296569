import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

interface BodyProps {
  bodyWidth?: number;
}

export const Body = styled.div<BodyProps>`
  background: ${materialTheme.palette.common.white};
  border-radius: 15px;
  margin: auto;
  padding: 27px 20px 35px 20px;
  position: relative;
  text-align: center;
  width: ${({ bodyWidth }) => (bodyWidth ? bodyWidth : '680')}px;
`;

interface HeadingProps {
  wrapped?: boolean;
}

export const Heading = styled.h2<HeadingProps>`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 28px;

  ${({ wrapped }) =>
    wrapped &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
    `}
`;

export const CloseButton = styled.button`
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
`;

export const Text = styled.p`
  font-size: 16px;
`;
