import { innerJoin } from 'ramda';
import React, { useEffect, useState } from 'react';

import { DeleteModal, Search } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import PaginationFooter from 'components/PaginationFooter';
import { PostsPerPage } from 'components/PaginationFooter/constants';
import { CheckboxHeadCell, Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { PaginationAction } from 'context/pagination';
import { BulkResource } from 'context/queries/bulk';
import { useUppy } from 'context/uppy';
import { useBulkDeleteMutation, useDuplicateVideo, useReprocessFailedVideo } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useWidgetApiKey } from 'hooks/query/useWidgetApiKey';
import { DashboardVideoNode, PageInfo, VideoStatus } from 'models';
import VideosBulkChanges from 'pages/Dashboard/BulkChanges/VideosBulkChangesComponent';
import { VideosTableSetSorting, VideosTableSorting } from 'pages/Dashboard/VideosTab/types';
import { notEmpty } from 'utils/common';
import { Nullable } from 'utils/types';

import { ShareOrPublishModal } from '../../ShareModal';
import { BulkUnpublishModal } from './BulkUnpublishModal';
import { VideoTableRow } from './VideoTableRow';

import * as Styled from 'components/common/Table/Table.styles';

interface OwnProps {
  videosLoading: boolean;
  videos: DashboardVideoNode[];
  totalCount: number;
  pageInfo: Nullable<PageInfo>;
  dispatch(value: PaginationAction): void;
  sorting: VideosTableSorting;
  setSorting: VideosTableSetSorting;
  onSearch: (searchText: string) => void;
}

const VideosTable = ({
  videosLoading,
  videos,
  totalCount,
  pageInfo,
  dispatch,
  sorting,
  setSorting,
  onSearch,
}: OwnProps) => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [isSelectedPublished, setIsSelectedPublished] = useState(true);

  const [sharedVideo, setSharedVideo] = useState<Nullable<DashboardVideoNode>>(null);

  const selectedList = Object.keys(selected).filter((key) => selected[key]);
  const selectedLength = selectedList.length;
  const getIsSelected = (videoId: string) => {
    return selected[videoId] || false;
  };
  const unselectAllVideos = () => {
    setSelected({});
  };

  const { cancelVideoUpload } = useUppy();

  const [duplicateVideo] = useDuplicateVideo({
    onCompleted: (data) => {
      if (data.duplicateVideo?.duplicate?.id) {
        dispatch({ type: 'changing' });
      }
    },
  });

  const [reprocessFailedVideo] = useReprocessFailedVideo();

  const [bulkDelete, bulkDeletingLoading] = useBulkDeleteMutation(
    BulkResource.Video,
    selectedList,
    ['GetVideos'],
    (data) => {
      const deletedVideoIds = Object.values(data).map(({ deletedVideoId }) => deletedVideoId);
      deletedVideoIds.forEach((videoId) => {
        cancelVideoUpload(videoId);
      });
      unselectAllVideos();
    },
  );

  const handleCheckboxClick = React.useCallback((id: string) => {
    setSelected((prevSelected) => ({ ...prevSelected, [id]: !prevSelected[id] }));
  }, []);

  useEffect(() => {
    if (selectedLength) {
      const selected = videos.filter(({ id }) => selectedList.includes(id));
      setIsSelectedPublished(!selected.some(({ status }) => status !== VideoStatus.Published));
    }
  }, [selectedLength, selectedList, videos]);

  const handleMultiCheckboxClick = () => {
    if (selectedLength > 0) {
      unselectAllVideos();
    } else {
      const payload = videos.reduce((allChanges, { id, duplicates }) => {
        const changedIds = { [id]: true };
        duplicates?.forEach((duplicate) => {
          changedIds[duplicate.id] = true;
        });
        return { ...allChanges, ...changedIds };
      }, {});
      setSelected(payload);
    }
  };

  const hasDuplicatesInTable = !!videos.find(
    ({ duplicates }) => duplicates && duplicates.length > 0,
  );

  const isLoading = videosLoading || bulkDeletingLoading;

  const { hasPermissions } = useUserPermissions();
  const canUserDeleteVideo = hasPermissions([Permission.DeleteVideo]);

  const { widgetApiKey } = useWidgetApiKey();

  return (
    <>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={isLoading} />
        <Styled.TableHorizontalScroll>
          <Table>
            <Table.Head>
              {canUserDeleteVideo && (
                <CheckboxHeadCell
                  checked={videos.length > 0 && selectedLength > 0}
                  onChange={handleMultiCheckboxClick}
                  color="secondary"
                />
              )}
              <Table.HeadCell width="100%">
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="title">
                  Title
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell align="center">
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="views">
                  Views
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell align="center">
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="status">
                  Status
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>
                <SortInterface
                  activeSorting={sorting}
                  setSorting={setSorting}
                  columnName="monetizationEnabled"
                >
                  Monetization
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>Platforms</Table.HeadCell>
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
                  Date Added
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {videos.map((video) => (
                <VideoTableRow
                  key={video.id}
                  video={video}
                  getIsSelected={getIsSelected}
                  hasDuplicatesInTable={hasDuplicatesInTable}
                  hasCheckboxCell={canUserDeleteVideo}
                  handleCheckboxClick={handleCheckboxClick}
                  duplicateVideo={duplicateVideo}
                  reprocessFailedVideo={reprocessFailedVideo}
                  setSharedVideo={setSharedVideo}
                  widgetApiKey={widgetApiKey}
                />
              ))}
            </Table.Body>
            <PaginationFooter
              totalCount={totalCount}
              visibleCount={videos.length}
              pageInfo={pageInfo}
              dispatch={dispatch}
              tableType={PostsPerPage.DashboardVideos}
            />
          </Table>
        </Styled.TableHorizontalScroll>
        <Styled.ActionsContainer>
          {canUserDeleteVideo && selectedLength > 0 ? (
            <VideosBulkChanges
              count={selectedLength}
              onDelete={() => setShowDeleteModal(true)}
              onUnpublish={() => setShowUnpublishModal(true)}
              showUnpublishButton={isSelectedPublished}
            />
          ) : (
            <Search placeholder="Search videos" onSearch={onSearch} />
          )}
        </Styled.ActionsContainer>
      </Styled.TableWrapper>
      {canUserDeleteVideo && (
        <BulkUnpublishModal
          onClose={() => {
            setShowUnpublishModal(false);
          }}
          onUnpublish={() => {
            unselectAllVideos();
          }}
          isOpen={showUnpublishModal}
          selectedVideos={getSelectedVideosById(videos, selectedList)}
        />
      )}
      {canUserDeleteVideo && (
        <DeleteModal
          type="video"
          handleClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          count={selectedLength}
          handleDelete={bulkDelete}
        />
      )}
      <ShareOrPublishModal
        open={sharedVideo !== null}
        onClose={() => setSharedVideo(null)}
        video={sharedVideo}
      />
    </>
  );
};

function getSelectedVideosById(videos: DashboardVideoNode[], selectedIds: string[]) {
  const topLevelVideos = videos.map((video) => {
    // remove duplicates to avoid circular dependencies
    return { ...video, duplicates: undefined };
  });
  const duplicates = videos.flatMap(({ duplicates }) => duplicates).filter(notEmpty);

  return innerJoin((video, id) => video.id === id, [...topLevelVideos, ...duplicates], selectedIds);
}

export default VideosTable;
