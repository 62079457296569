import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

const useSubject = <T>(input: T) => {
  const [subject] = useState(new BehaviorSubject<T>(input));

  useEffect(() => {
    return () => {
      subject.complete();
      subject.unsubscribe();
    };
  }, [subject]);

  return subject;
};

export default useSubject;
