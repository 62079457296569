import { getVideoPublishStatusQuery } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PendingPublishingTargetPick, VideoI } from 'models';
import { Nullable } from 'utils/types';

export interface VideoPublishStatusResponseData {
  videoById: Nullable<{
    id: VideoI['id'];
    externalPublications?: VideoI['externalPublications'];
    publications?: VideoI['publications'];
  }>;
}

interface Variables {
  videoId: string;
}

interface UseVideoPublishStatusQueryOptions
  extends UseQueryOptions<VideoPublishStatusResponseData, Variables> {
  targets: PendingPublishingTargetPick[];
}

export const useVideoPublishStatusQuery = ({
  targets,
  ...options
}: UseVideoPublishStatusQueryOptions) => {
  return useQuery<VideoPublishStatusResponseData, Variables>(
    getVideoPublishStatusQuery(targets),
    options,
  );
};
