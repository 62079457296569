import React from 'react';

import { CloseButton, Content, InnerWrapper, PanelWrapper } from './SidePanelElements';

interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
  side?: 'left' | 'right';
}

export function SidePanel({ side = 'left', isOpen, onToggle, children }: Props) {
  return (
    <PanelWrapper opened={isOpen} side={side}>
      <CloseButton onClick={onToggle} />
      <InnerWrapper>
        <Content>{children}</Content>
      </InnerWrapper>
    </PanelWrapper>
  );
}
