import { useApolloClient } from '@apollo/react-hooks';
import React from 'react';

import { ACTIVE_SUBSCRIPTION_STATUSES } from 'config/mutationRules';
import { useIsUserLoggedIn } from 'hooks/query/useIsUserLoggedIn';
import { getOrganization } from 'hooks/query/useOrganizationQuery';
import { useIsMounted } from 'hooks/useIsMounted';
import { useSafeState } from 'hooks/useSafeState';
import { noop } from 'utils/common';

type Callback = (hasActiveSubscription: boolean) => void;

export function useHasActiveSubscription(callback: Callback = noop) {
  const client = useApolloClient();
  const isMounted = useIsMounted();
  const [hasActiveSubscription, setHasActiveSubscription] = useSafeState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useSafeState(false);
  const { isLoggedIn } = useIsUserLoggedIn();

  React.useEffect(() => {
    async function updateStatus() {
      setIsSubscriptionLoading(true);
      getOrganization(client)
        .then(({ data }) => {
          const status = data?.me?.organization?.subscription?.status;
          if (!status) {
            return;
          }
          const isPaymentActive = ACTIVE_SUBSCRIPTION_STATUSES.includes(status);
          callback(isPaymentActive);
          setHasActiveSubscription(isPaymentActive);
        })
        .finally(() => {
          setIsSubscriptionLoading(false);
        });
    }

    if (isLoggedIn) {
      updateStatus();
    }
  }, [callback, client, isLoggedIn, isMounted, setHasActiveSubscription, setIsSubscriptionLoading]);

  return { hasActiveSubscription, isSubscriptionLoading };
}
