export const generateVideoLink = (videoId: string) => `/video/${videoId}`;
export const generateVideoMomentsLink = (videoId: string) => `/video/${videoId}/moments`;
export const generateVideoObjectsLink = (videoId: string) => `/video/${videoId}/objects`;
export const generateVideoPublishLink = (videoId: string) => `/video/${videoId}/publish`;
export const generateExternalContentLink = (videoId: string) => `/video/${videoId}/promo-cards`;
export const generateVideoMonetizationLink = (videoId: string) => `/video/${videoId}/monetization`;
export const generateVideoCTALink = (videoId: string) => `/video/${videoId}/cta`;
export const generateVideoEndCardLink = (videoId: string) => `/video/${videoId}/end-card`;

export const generateNewMomentLink = (videoId: string) => `/video/${videoId}/moment/new`;
export const generateMomentLink = (videoId: string, momentId: string) => ({
  pathname: generateVideoMomentsLink(videoId),
  search: `?momentId=${momentId}`,
});

export const generateNewObjectLink = (videoId: string) => `/video/${videoId}/object/new`;
export const generateObjectLink = (videoId: string, objectId: string) =>
  `/video/${videoId}/object/${objectId}`;
export const generateCarouselLink = (carouselId: string) =>
  `${URLS.widget.root}/carousel/${carouselId}`;
export const generateCustomizeCarouselLink = (carouselId: string) =>
  `${URLS.widget.root}/carousel/${carouselId}/customize`;

export const generateMicrositeEditLink = (micrositeId: string) =>
  `${URLS.microsite.root}/${micrositeId}`;
export const generateMicrositeSuccessLink = (micrositeId: string) =>
  `${URLS.microsite.root}/${micrositeId}/success`;

export const generateEndCardEditLink = (endCardId: string) => `/end-card/visual/${endCardId}`;

export const generateCustomCodeEndCardEditLink = (endCardId: string) =>
  `/end-card/custom-code/${endCardId}`;

export const generateCTAImageEditLink = (ctaId: string) => `/cta/image/${ctaId}`;

export const generateCTAButtonEditLink = (ctaId: string) => `/cta/button/${ctaId}`;

export const generatePlaylistEditLink = (playlistId: string) => `/playlists/${playlistId}`;

export const URLS = {
  root: '/',
  dashboard: {
    root: '/dashboard',
    videos: '/dashboard/videos',
    moments: '/dashboard/moments',
  },
  widget: {
    root: '/widget',
    carousel: '/widget/carousel/:carouselId',
    customizeCarousel: '/widget/carousel/:carouselId/customize',
    presets: '/widget/presets',
    editPreset: '/widget/presets/:presetId',
  },
  d2c: {
    root: '/d2c',
    apiKey: '/d2c/api-keys',
    serviceUsage: '/d2c/service-usage',
  },
  video: {
    root: '/video/:videoId',
    moments: '/video/:videoId/moments',
    objects: '/video/:videoId/objects',
    publish: '/video/:videoId/publish',
    promoCards: '/video/:videoId/promo-cards',
    monetization: '/video/:videoId/monetization',
    cta: '/video/:videoId/cta',
    endCard: '/video/:videoId/end-card',
  },
  newMoment: '/video/:videoId/moment/new',
  object: '/video/:videoId/object/:objectId',
  newObject: '/video/:videoId/object/new',
  missingMedia: '/404',
  authorize: {
    youtube: '/authorize/youtube',
  },
  resetPassword: {
    root: '/reset-password',
    change: '/reset-password/change',
  },
  organization: {
    root: '/organization',
    paymentPlan: '/organization/payments',
    members: '/organization/members',
    socialMedia: '/organization/social-media',
    monetization: '/organization/monetization',
    branding: '/organization/branding',
    shareSocials: '/organization/share-socials',
  },
  payNow: '/pay-now',
  verify: '/verify',
  verifyD2C: '/verify/d2c',
  microsite: {
    root: '/microsite',
    newMicrosite: '/microsite/new',
    editMicrosite: '/microsite/:micrositeId',
    micrositeCreated: '/microsite/:micrositeId/success',
  },
  register: {
    root: '/register',
    trial: '/register/trial',
    organization: '/register/organization',
    success: '/register/success',
    successPayment: '/register/success-payment',
    d2c: '/register/d2c',
    d2cTrial: '/register/d2c/trial',
  },
  termsOfUse: '/terms-of-use',
  termsOfUseD2C: '/terms-of-use/d2c',
  privacyPolicy: '/privacy-policy',
  settings: {
    root: '/settings',
    account: '/settings/account',
    password: '/settings/password',
    email: '/settings/email',
  },
  updateEmail: {
    root: '/update-email',
    change: '/update-email/change',
  },
  endCard: {
    new: '/end-card/new',
    newCustomCode: '/end-card/new-custom-code',
    root: '/end-card/visual/:endCardId',
    customCodeRoot: '/end-card/custom-code/:endCardId',
  },
  cta: {
    newButton: '/cta/new-button',
    newImage: '/cta/new-image',
    image: '/cta/image/:ctaId',
    button: '/cta/button/:ctaId',
  },
  overlays: {
    root: '/overlays',
    endCards: '/overlays/end-cards',
    ctas: '/overlays/ctas',
  },
  playlists: {
    list: '/playlists',
    byId: '/playlists/:playlistId',
    new: '/playlists/new',
  },
  help: {
    root: 'https://intercom.help/getseeen/en/collections/2690452-creatorsuite-help',
  },
} as const;
