import { Box, Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Select, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import ThumbnailSelect from 'components/ThumbnailSelect';
import { Permission } from 'config/permissions';
import { useCreateObject } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { ObjectAnnotationCategory } from 'models';
import { capitalize } from 'utils/common';
import { isEmptyString, isNotEmptyString, isValidUrl, validate } from 'utils/validation';

import { CreateObjectModalProps, EditObjectFormProps } from '../types';

export const CreateObjectModal: React.VFC<CreateObjectModalProps> = ({
  onClose,
  isOpen,
  object,
  videoId,
}) => {
  const [thumbnailBase64, setThumbnailBase64] = useState<string>();

  const handleCloseModal = () => {
    onClose();
    setThumbnailBase64(undefined);
  };

  const [createObject, { loading }] = useCreateObject(videoId, handleCloseModal);

  const { register, handleSubmit, errors, control } = useForm<EditObjectFormProps>({
    mode: 'onChange',
  });

  const handleSave = useCallback(
    ({ url, title, category }: EditObjectFormProps) => {
      createObject({
        variables: {
          videoId,
          objectAnnotation: {
            url: isEmptyString(url) ? undefined : url.trim(),
            title: title.trim(),
            category,
            thumbnailBase64,
          },
        },
      });
    },
    [createObject, thumbnailBase64, videoId],
  );

  const addNewThumbnail = React.useCallback((imageBase64: string) => {
    setThumbnailBase64(imageBase64);
  }, []);

  const removeThumbnail = React.useCallback(() => {
    setThumbnailBase64(undefined);
  }, []);

  const { hasPermissions } = useUserPermissions();
  const canChangeMetadata = hasPermissions([Permission.ChangeObject]);

  return (
    <Dialog onClose={handleCloseModal} open={isOpen} width={600} heading="Create Object">
      <form
        onSubmit={handleSubmit(handleSave)}
        style={{ width: '80%', height: '100%', margin: '0 auto' }}
      >
        <Box mb="32px">
          <ThumbnailSelect
            thumbnails={thumbnailBase64 ? [{ imageUrl: thumbnailBase64, id: 'new' }] : undefined}
            onThumbnailAdded={addNewThumbnail}
            onRemove={removeThumbnail}
          />
        </Box>
        <TextInput
          label="URL"
          name="url"
          placeholder="Write a url to your object…"
          defaultValue={object?.url}
          ref={register({
            required: false,
            validate: validate<string>(
              (value) => value.length === 0 || isValidUrl(value),
              'URL is in incorrect format',
            ),
          })}
          error={!!errors.url}
          errorMessage={errors.url?.message}
          readOnly={!canChangeMetadata}
        />
        <TextInput
          label="Title (required)"
          name="title"
          placeholder="Write a title for your object…"
          defaultValue={object?.title}
          ref={register({
            validate: validate(isNotEmptyString, 'Title cannot be empty'),
          })}
          error={!!errors.title}
          errorMessage={errors.title?.message}
          readOnly={!canChangeMetadata}
        />
        <SelectWrapper>
          <Controller
            render={({ onChange, value, ref }) => {
              return (
                <Select
                  innerRef={ref}
                  id="object-type"
                  label="Object type"
                  options={objectCategories}
                  value={value}
                  onChange={({ currentTarget }) => {
                    onChange(currentTarget.value);
                  }}
                />
              );
            }}
            defaultValue={object?.category}
            name="category"
            control={control}
            disabled={!canChangeMetadata}
          />
        </SelectWrapper>
        <Box mt="25px">
          <Button variant="contained" disabled={loading} color="primary" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};

const SelectWrapper = styled.div`
  label {
    text-align: left;
  }
`;

export const objectCategories = Object.values(ObjectAnnotationCategory).map((category) => ({
  value: category,
  label: capitalize(category),
}));
