import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PaginationVariables } from 'models';
import {
  PlaylistContentType,
  PlaylistOrdering,
  PlaylistsOrderBy,
  PlaylistToAdd,
} from 'models/Playlist';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetPlaylistsToAdd(
    $momentId: ID
    $videoId: ID
    $contentType: PlaylistContentType
    $itemsOrderBy: PlaylistOrderBy
    $search: String
    $orderBy: String
    $page: Int
    $pageSize: Int
  ) {
    excludedPlaylists: playlists(
      excludeManualMomentId: $momentId
      excludeManualVideoId: $videoId
      contentType: $contentType
      itemsOrderBy: $itemsOrderBy
      search: $search
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
    ) {
      nodes {
        id
        name
      }
    }

    includedPlaylists: playlists(
      manualMomentId: $momentId
      manualVideoId: $videoId
      contentType: $contentType
      itemsOrderBy: $itemsOrderBy
      search: $search
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

interface ResponseData {
  excludedPlaylists: Nullable<{
    nodes: Nullable<PlaylistToAdd[]>;
  }>;
  includedPlaylists: Nullable<{
    nodes: Nullable<PlaylistToAdd[]>;
  }>;
}

interface Variables extends PaginationVariables {
  itemsOrderBy: PlaylistOrdering;
  contentType: PlaylistContentType;
  search?: string;
  orderBy?: PlaylistsOrderBy;
}

interface UsePlaylistsToAddQueryOptions
  extends Omit<UseQueryOptions<ResponseData, Variables>, 'variables'> {
  itemId: string;
  variables: Variables;
}

export function usePlaylistsToAdd({
  itemId,
  variables,
  ...options
}: UsePlaylistsToAddQueryOptions) {
  const itemKey = variables?.contentType === PlaylistContentType.Moments ? 'momentId' : 'videoId';

  const results = useQuery<ResponseData, Variables>(QUERY, {
    ...options,
    variables: {
      ...variables,
      [itemKey]: itemId,
    },
  });

  const excludedPlaylists = results.data?.excludedPlaylists?.nodes || [];
  const includedPlaylists = results.data?.includedPlaylists?.nodes || [];

  const playlists = [
    ...excludedPlaylists,
    ...includedPlaylists.map((playlist) => {
      return { ...playlist, alreadyAdded: true };
    }),
  ];

  return {
    ...results,
    playlists,
  };
}
