import { Except } from 'type-fest';

import { usePublishingTargets } from 'hooks/query/usePublishingTargets';
import { PublishingTargetType } from 'models';

import { PublishTargetHandler } from './PublishVideo.utils';
import { SelfPublicationHandler } from './targets/SelfPublication';
import { YouTubeHandler, YouTubeTarget } from './targets/YouTube';

const targetMap: Record<PublishingTargetType, Except<PublishTargetHandler, 'id'>> = {
  [PublishingTargetType.SelfPublication]: SelfPublicationHandler,
  [PublishingTargetType.YouTube]: YouTubeHandler,
};

export function usePublishingTargetHandlers(): PublishTargetHandler[] {
  const { publishingTargets } = usePublishingTargets();
  const targets = [...publishingTargets, YouTubeTarget];

  return targets.reduce((allPlatforms, target) => {
    const handler = targetMap[target.type];
    if (handler) {
      return allPlatforms.concat({
        ...handler,
        id: target.id,
        label: target.name,
      });
    }
    return allPlatforms;
  }, [] as PublishTargetHandler[]);
}

export function createValidationMessage(label: string, message: string) {
  const emDash = '—';
  return `${label} ${emDash} ${message}`;
}
