export enum CreatorSuitePlanLimit {
  VideosUpload = 'VIDEOS_UPLOAD_LIMIT',
  VideosPublish = 'VIDEOS_PUBLISH_LIMIT',
  EndCards = 'END_CARDS_LIMIT',
  WidgetsCarousel = 'WIDGETS_CAROUSEL_LIMIT',
  WidgetPresets = 'WIDGET_PRESETS_LIMIT',
  Microsites = 'MICROSITES_LIMIT',
}

export enum DialogToClipPlanLimit {
  D2CTranscriptionDuration = 'D2C_TRANSCRIPTION_DURATION_LIMIT',
}
