import { Box, makeStyles, Tab as MaterialTab, TabProps, withStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { customPalette, materialTheme } from 'components/App/materialTheme';
import { CONDENSED_FONT } from 'utils/styling';

import Tabs from '../Tabs';

export const TabsWrapper = styled(Box)`
  height: 100px;
  display: flex;
  align-items: center;
`;

const tabStyles = makeStyles(() => ({
  root: {
    minWidth: '0',
    padding: '0',
    marginRight: '40px',
    fontSize: '20px',
    fontWeight: 700,
    textTransform: 'none',
    fontFamily: CONDENSED_FONT,
  },
}));

export type Props = TabProps;

export const Tab: React.VFC<Props> = (props) => {
  const tabClasses = tabStyles();
  return <MaterialTab className={tabClasses.root} {...props} />;
};

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: materialTheme.palette.primary.main,
  },
  scrollable: {
    marginLeft: '-40px',
  },
  scrollButtons: {
    backgroundColor: customPalette.lightGrey,
    width: '48px',
    height: '48px',
    margin: '0 12px',
    borderRadius: '50%',
    zIndex: 999,
    opacity: 1,
  },
})(Tabs);

export default Tab;
