import React from 'react';
import { useFormContext } from 'react-hook-form';

import type { TextInputProps } from 'components/common/TextInput';
import TextInput from 'components/common/TextInput';

interface GATrackingIDInputProps extends Omit<TextInputProps, 'ref'> {
  name: string;
}

/**
 * GATrackingIDInput is a predefined input field for Google Analytics Tracking ID
 * The component requires to be inside FormProvider tree because of useFormContext hook,
 * as otherwise will cause hard to track error "Cannot destructure property of Object null"
 * @param name - field's name which will be used for API call's data
 */
export const GATrackingIDInput = React.forwardRef<HTMLInputElement, GATrackingIDInputProps>(
  function GATrackingIDInput({ name, ...rest }, ref) {
    const { register, errors } = useFormContext();

    return (
      <TextInput
        ref={
          ref ||
          register({
            pattern: {
              value: /^UA-[A-Za-z0-9]{6,12}-[0-9]{1,3}$/,
              message: 'Provided ID must match UA-XXXXXX-X format',
            },
          })
        }
        label="Google Analytics (GA3) Tracking ID"
        placeholder="Type or paste your Google Analytics (GA3) Tracking ID"
        info="Only Google Analytics Tracking ID v3 is accepted. The correct format is UA-XXXXXX-X"
        infoPlacement="top"
        error={!!errors[name]}
        errorMessage={errors[name]?.message}
        name={name}
        {...rest}
      />
    );
  },
);
