import {
  BulkResource,
  getBulkDeleteMutation,
  getBulkToggleMutation,
  getPathsToErrors,
  ToggleAction,
} from 'context/queries/bulk';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { pushOperationErrors } from 'utils/errors';
import { AnyObject } from 'utils/types';

const listToVariables = (selectedList: string[]) =>
  selectedList.reduce((acc, current, i) => ({ ...acc, [`id${i}`]: current }), {});

export const useBulkDeleteMutation = (
  resource: BulkResource,
  selectedList: string[],
  refetchQueries: UseMutationOptions['refetchQueries'],
  onCompleted?: (data: AnyObject) => void,
) => {
  const pathsToErrors = getPathsToErrors(selectedList.length);
  const [bulkDelete, { loading: bulkDeletingLoading }] = useMutation(
    getBulkDeleteMutation(resource, selectedList.length),
    {
      variables: listToVariables(selectedList),
      refetchQueries,
      onCompleted: (data) => {
        if (onCompleted) onCompleted(data);

        pathsToErrors.forEach((pathToErrors) => {
          pushOperationErrors(pathToErrors, data);
        });
      },
    },
  );

  return [bulkDelete, bulkDeletingLoading] as const;
};

export const useBulkToggleMutation = (
  action: ToggleAction,
  resource: BulkResource,
  selectedList: string[],
  refetchQueries: UseMutationOptions['refetchQueries'],
) => {
  const pathsToErrors = getPathsToErrors(selectedList.length);
  const [bulkToggle, { loading: bulkToggleLoading }] = useMutation(
    getBulkToggleMutation(action, resource, selectedList.length),
    {
      variables: listToVariables(selectedList),
      refetchQueries,
      onCompleted: (data) => {
        pathsToErrors.forEach((pathToErrors) => {
          pushOperationErrors(pathToErrors, data);
        });
      },
    },
  );

  return [bulkToggle, bulkToggleLoading] as const;
};
