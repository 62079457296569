import { DocumentNode } from 'graphql';
import React from 'react';

import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { AnyObject } from 'utils/types';

export enum ToggleAction {
  enable = 'ENABLE',
  disable = 'DISABLE',
}

interface Variables {
  id: string;
  videoId: string;
  action: ToggleAction;
}

interface UseToggleMutationOptions<ResponseData>
  extends UseMutationOptions<ResponseData, Variables> {}

export interface ToggleMutateOptions {
  id: string;
  videoId: string;
  checked: boolean;
}

export function useToggleMutation<ResponseData extends AnyObject>(
  query: DocumentNode,
  options: UseToggleMutationOptions<ResponseData>,
) {
  const [rawMutate, results] = useMutation<ResponseData, Variables>(query, options);

  const mutate = React.useCallback(
    ({ id, videoId, checked: wasVisible }: ToggleMutateOptions) => {
      return rawMutate({
        variables: {
          id,
          videoId,
          action: wasVisible ? ToggleAction.disable : ToggleAction.enable,
        },
      });
    },
    [rawMutate],
  );

  return [mutate, results] as const;
}
