import styled from 'styled-components/macro';

export const Code = styled.textarea`
  width: 80%;
  height: 163px;
  font-size: 11px;
  font-family: monospace;
  resize: none;
`;

export const StyledBox = styled.div`
  width: 450px;
  display: flex;
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TooltipLink = styled.a`
  text-decoration: underline;
  &:hover {
    color: #ce0000;
  }
`;
