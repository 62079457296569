import { FetchResult } from 'apollo-link';

import {
  cancelScheduledVideoPublishResponseData,
  UnpublishVideoResponseData,
} from 'hooks/mutation';
import { DashboardVideoNode, PublicationTypename, PublishingTargetType } from 'models';
import { notEmpty } from 'utils/common';
import { extractOperationErrors } from 'utils/errors';

export type UnpublishVideoPick = Pick<
  DashboardVideoNode,
  'id' | 'status' | 'title' | 'origin' | 'publications' | 'externalPublications'
>;

export function prepareVideoForUnpublish(video: UnpublishVideoPick) {
  const hasYouTubePublication = !!video.externalPublications?.find(
    ({ __typename }) => __typename === PublicationTypename.YouTube,
  );

  const targetTypes = [
    // Self publication is always enabled, so we can unpublish the video if it's only published
    // to hidden widget target.
    PublishingTargetType.SelfPublication,
    hasYouTubePublication ? PublishingTargetType.YouTube : null,
  ].filter(notEmpty);

  return {
    targetTypes,
    hasYouTubePublication,
  };
}

export function parseUnpublishingResults(
  results: FetchResult<UnpublishVideoResponseData | cancelScheduledVideoPublishResponseData>[],
) {
  const failedResults = results.filter((result) => {
    if (result.errors?.length) return true;

    const data = result.data ?? undefined;
    const errors = [
      extractOperationErrors(`${PublishingTargetType.SelfPublication}.errors`, data),
      extractOperationErrors(`${PublishingTargetType.YouTube}.errors`, data),
    ].filter(notEmpty);
    return errors.length > 0;
  });
  return {
    isAllSuccess: failedResults.length === 0,
    successfulResultCount: results.length - failedResults.length,
  };
}
