import styled from 'styled-components/macro';

import { CONDENSED_FONT } from 'utils/styling';

export const VideoWrapper = styled.div`
  border-radius: 5px 5px 15px 15px;
`;

export const VideoStatus = styled.div`
  font-family: ${CONDENSED_FONT};
  font-size: 18px;
  font-weight: bold;

  .date-time {
    font-size: 12px;
  }
`;
