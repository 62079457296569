import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { HTMLHead } from 'components/common/HTMLHead';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { ModalPageLayout } from 'components/layouts/ModalPageLayout';
import { useSafeState } from 'hooks/useSafeState';
import { URLS } from 'pages/urls';

import { AccountForm, FormData } from './AccountForm';
import {
  isD2CPlan,
  SubscriptionPlan,
  useRegisterOrganizationOwner,
} from './useRegisterOrganizationOwner';

// TODO: extract this into common component
import { ErrorMessage } from 'pages/ResetPassword/ResetPassword.styles';

interface RegisterAccountPageProps {
  subscriptionPlan?: SubscriptionPlan;
}

export const RegisterAccountPage: React.VFC<RegisterAccountPageProps> = ({
  subscriptionPlan = SubscriptionPlan.trial,
}) => {
  const [registerUser, { loading: isSubmitting }, [errorMessage]] = useRegisterOrganizationOwner();
  const [successEmail, setSuccessEmail] = useSafeState<string>();

  const isD2CPlugin = [
    SubscriptionPlan.d2cPremium,
    SubscriptionPlan.d2cTrial,
    SubscriptionPlan.d2cUnlimited,
  ].includes(subscriptionPlan);

  const handleSubmit = async ({ name, email, password, terms, privacy }: FormData) => {
    const { data } = await registerUser({
      variables: {
        legalAgreements: {
          acceptsPrivacyPolicy: privacy,
          acceptsTermsOfUse: terms,
        },
        user: {
          name,
          email,
          password,
        },
        subscriptionPlan,
      },
    });
    if (data?.registerOrganizationOwner?.user?.email === email) {
      setSuccessEmail(email);
    }
  };

  const appTitle = isD2CPlan(subscriptionPlan) ? 'Seeen' : 'Seeen Creator Suite';

  return (
    <ModalPageLayout>
      <HTMLHead title={appTitle} />
      <Stack
        rowGap={40}
        display="flex"
        alignItems="center"
        flexDirection="column"
        py="64px"
        px="180px"
      >
        <Box textAlign="center" width="100%">
          <Typography variant="h1">Create an account</Typography>
        </Box>
        {successEmail ? (
          <EmailSent successEmail={successEmail} />
        ) : (
          <>
            {errorMessage ? (
              <ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
            ) : null}
            <AccountForm
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isD2CPlugin={isD2CPlugin}
            />
          </>
        )}
      </Stack>
    </ModalPageLayout>
  );
};

const EmailSent: React.VFC<{ successEmail: string }> = ({ successEmail }) => {
  return (
    <Stack>
      <Box textAlign="center">
        Email to <strong>{successEmail}</strong> with account confirmation has been sent!
        <br />
        Follow the instructions to proceed.
      </Box>
      <Columns display="flex" justifyContent="center">
        <Button variant="outlined" color="secondary" component={Link} to={URLS.root}>
          Go back
        </Button>
      </Columns>
    </Stack>
  );
};
