import React from 'react';
import { LinkProps } from 'react-router-dom';

import { Link } from 'components/common';
import { Optional, RouterLink } from 'utils/types';

import * as StyledTable from 'components/common/Table/Table.styles';
import * as Styled from './DashboardTable.styles';

interface Props {
  title: string;
  description?: Optional<React.ReactNode>;
  link?: RouterLink;
  thumbnailUrl?: Optional<string>;
}

export const TitleCell: React.FC<Props> = ({
  title,
  description,
  link,
  thumbnailUrl,
  children,
}) => {
  return (
    <Styled.TitleCell>
      <ComputedLink to={link} title={title}>
        <StyledTable.Thumbnail thumbnailUrl={thumbnailUrl} />
      </ComputedLink>
      <Styled.Wrapper>
        <Styled.EllipsisParent>
          <Styled.Content>
            <Styled.EllipsisContent>
              <ComputedLink to={link}>
                <StyledTable.MomentTitle>{title}</StyledTable.MomentTitle>
                <br />
                {description ? (
                  <StyledTable.MomentSubtitle>{description}</StyledTable.MomentSubtitle>
                ) : null}
              </ComputedLink>
            </Styled.EllipsisContent>
          </Styled.Content>
        </Styled.EllipsisParent>
        <Styled.Actions>{children}</Styled.Actions>
      </Styled.Wrapper>
    </Styled.TitleCell>
  );
};

interface ComputedLinkProps extends Omit<LinkProps, 'to'> {
  to?: Optional<RouterLink>;
}

const ComputedLink = ({ to, ...props }: ComputedLinkProps) => {
  return !!to ? <Link to={to} {...props} /> : <React.Fragment>{props.children}</React.Fragment>;
};
