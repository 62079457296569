import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const InstallStepsList = styled.ol`
  text-align: left;
`;

export const InstallStepItem = styled.li`
  margin: 20px 0;
  &::marker {
    color: ${materialTheme.palette.primary.main};
  }
`;
