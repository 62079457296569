import styled from 'styled-components';

import { customPalette } from 'components/App/materialTheme';

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const RadioBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  pointer-events: none;
`;

export const RadioWrapper = styled.label`
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }

  input:checked ~ div {
    background-color: ${customPalette.lightGrey};
  }

  svg {
    pointer-events: none;
    z-index: 10;
  }
`;
