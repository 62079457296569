import { LOGIN_USER_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'AMBIGUOUS_LOGIN' | 'INVALID_CREDENTIALS' | 'ACCOUNT_INACTIVE';

const errorMap: Record<Errors, string> = {
  AMBIGUOUS_LOGIN:
    'Multiple organizations found for the provided email and password.<br />Specify an organization you want to log in to.',
  INVALID_CREDENTIALS:
    'We couldn’t find an account matching the email and password you entered.<br />Please check your email and password and try again.',
  ACCOUNT_INACTIVE: 'Your account is inactive. Contact the support.',
};

interface ResponseData {
  tokenAuth: Nullable<{
    token: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  email: string;
  password: string;
  organizationName?: string;
  organizationId?: string;
}

interface UseLoginOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useLogin = (options: UseLoginOptions = {}) => {
  return useMutation<ResponseData, Variables>(LOGIN_USER_MUTATION, {
    pathToErrors: 'tokenAuth.errors',
    errorMap,
    skipGlobalErrorHandling: true,
    ...options,
  });
};
