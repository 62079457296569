import React, { useCallback, useState } from 'react';

import { ConfirmationModal } from 'components/common/Modals';
import MemoToggle from 'components/common/Table/MemoToggle';
import { useUpdateVideoMonetization } from 'hooks/mutation/useUpdateVideoMonetization';

interface Props {
  checked: boolean;
  disabled: boolean;
  refetchQuery: 'GetVideos' | 'GetVideo';
  videoId: string;
}

export const VideoMonetizationToggle: React.FC<Props> = ({
  checked,
  disabled,
  refetchQuery,
  videoId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateVideoMonetization] = useUpdateVideoMonetization({ refetchQueries: [refetchQuery] });

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleMonetizationDisable = () => {
    updateVideoMonetization({
      variables: {
        videoId,
        monetization: {
          isMonetizationEnabled: !checked,
        },
      },
    });
  };

  const handleToggleChange = () => {
    if (checked) {
      setIsModalOpen(true);
    } else {
      handleMonetizationDisable();
    }
  };

  return (
    <>
      <MemoToggle
        checked={checked}
        onChange={handleToggleChange}
        disabled={disabled}
        size="small"
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleMonetizationDisable}
        heading="Disable monetization"
        description="Are you sure you want to disable monetization for this video? You will no longer be able to earn revenue from this video.We will store your per-video settings."
        confirmationButtonText="Deactivate"
      />
    </>
  );
};
