import { rgba } from 'polished';
import styled from 'styled-components/macro';

import { zIndex } from 'utils/styling';

export const CenteredContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${rgba('#000', 0.2)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.modal + 10};
`;
