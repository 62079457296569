import React from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components/macro';

import { useEditorContext } from '../MicrositeEditorContext';
import { ItemTypeLabel, LayoutContainer, SectionTitle } from './Layout';
import { VideoBox } from './VideoBox';

export const VideosGrid: React.VFC = () => {
  const { initialMicrositeData } = useEditorContext();
  const sectionTitle = useWatch({
    name: 'sections[2].title',
    defaultValue: initialMicrositeData.sections[2]?.title,
  });

  return (
    <Wrapper>
      {sectionTitle ? <SectionTitle>{sectionTitle}</SectionTitle> : null}
      <Container>
        <Thumbnail>Video 1</Thumbnail>
        <Thumbnail>Video 2</Thumbnail>
        <Thumbnail>Video 3</Thumbnail>
        <Thumbnail>Video 4</Thumbnail>
        <Thumbnail>Video 5</Thumbnail>
        <Thumbnail>Video 6</Thumbnail>
        <Thumbnail>Video 7</Thumbnail>
        <Thumbnail>Video 8</Thumbnail>
      </Container>
    </Wrapper>
  );
};

const Thumbnail: React.FC = ({ children }) => {
  return (
    <ThumbnailContainer>
      <VideoBox />
      <VideoWrapper>
        <ItemTypeLabel>Video</ItemTypeLabel>
        <VideoTitle>{children}</VideoTitle>
      </VideoWrapper>
    </ThumbnailContainer>
  );
};

const ThumbnailContainer = styled.li``;

const VideoTitle = styled.h4`
  font-size: 16px;
`;

const Wrapper = styled(LayoutContainer)``;

const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px 30px;
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-top: 16px;
`;
