import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const BackButton = styled.button`
  background: transparent;
  border: 0;
  color: ${materialTheme.palette.primary.main};
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 0;

  svg {
    margin-right: 10px;
  }
`;

export const Text = styled.div`
  display: inline-block;
  color: ${materialTheme.palette.text.primary};
`;
