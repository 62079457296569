import React from 'react';
import { Prompt } from 'react-router-dom';

import { ConfirmationModal } from 'components/common/Modals';
import useCustomPrompt from 'hooks/useCustomPrompt';

interface Props {
  hasUnsavedChanges: boolean;
}

export const CustomPrompt = ({ hasUnsavedChanges }: Props) => {
  const {
    showPrompt,
    handleBlockedNavigation,
    handleConfirmNavigation,
    closeModal,
  } = useCustomPrompt(hasUnsavedChanges);

  return (
    <>
      <Prompt when={hasUnsavedChanges} message={handleBlockedNavigation} />
      <ConfirmationModal
        isOpen={showPrompt}
        onClose={closeModal}
        onConfirm={handleConfirmNavigation}
        heading="Unsaved changes"
        description="Are you sure you want to leave this site? Changes you made will not be saved."
        confirmationButtonText="Leave without saving"
        cancelButtonText="Back to edit"
      />
    </>
  );
};
