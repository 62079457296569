import './polyfills';
import 'modern-normalize/modern-normalize.css';
import 'tippy.js/dist/tippy.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'components/App/App';
import AppProviders from 'components/App/AppProviders';
import { env } from 'config/env';
import { setupAnalyticsPageTracking } from 'utils/analytics';
import { setupIntercomPageTracking } from 'utils/intercom';
import { configureSentry, setupOnlineStatusListener } from 'utils/sentry';

import * as serviceWorker from './serviceWorker';

configureSentry();
setupAnalyticsPageTracking();
setupIntercomPageTracking();
setupOnlineStatusListener();

const rootElement = document.getElementById('root')!;
const BUILD_VERSION = env.SEEEN_APP_BUILD_VERSION;

if (BUILD_VERSION) {
  rootElement.setAttribute('data-version', BUILD_VERSION);
}

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
