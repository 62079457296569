import { CAROUSELS_LIST_QUERY } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { CarouselI, PageInfo } from 'models';
import { CarouselQueryVariables } from 'models/CarouselQueryVariables';
import { Nullable } from 'utils/types';

interface ResponseData {
  carousels: Nullable<{
    nodes: Nullable<CarouselI[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface UseCarouselQueryOptions extends UseQueryOptions<ResponseData, CarouselQueryVariables> {}

export const useCarouselsQuery = (options: UseCarouselQueryOptions) => {
  return useQuery<ResponseData, CarouselQueryVariables>(CAROUSELS_LIST_QUERY, options);
};
