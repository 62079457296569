import React from 'react';

import CustomColor from 'components/common/CustomColor';

import { COLORS } from './MainColors';

interface ColorPickerProps {
  selectedColor?: string;
  label?: string;
  onColorChange: (color: string) => void;
}

export const ColorPicker: React.VFC<ColorPickerProps> = ({
  selectedColor,
  onColorChange,
  label,
}) => {
  const isDefaultColor = selectedColor && COLORS.indexOf(selectedColor) > -1;
  return (
    <>
      <CustomColor
        label={label}
        selectedColor={!isDefaultColor ? selectedColor : undefined}
        onColorChange={onColorChange}
      />
    </>
  );
};
