import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { Avatar } from 'components/common';
import MemoToggle from 'components/common/Table/MemoToggle';
import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { ToggleAction } from 'context/queries/bulk';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useSafeState } from 'hooks/useSafeState';
import { isDeepEqual } from 'utils/common';
import { formatDate } from 'utils/date';

import { UpdateMemberModal } from './UpdateMemberModal';
import { OrganizationMemberPick } from './useOrganizationMembers';
import { UserRoleBadge } from './UserRoleBadge';

import * as Styled from './MemberTable.styles';

interface Props {
  member: OrganizationMemberPick;
  onAccountToggle: (options: { variables: { id: string; action: ToggleAction } }) => Promise<any>;
  isCurrentlyLoggedInUser: boolean;
  isOrganizationOwner: boolean;
}

export const MemberTableRow = React.memo(function MemberTableRow({
  member,
  onAccountToggle,
  isCurrentlyLoggedInUser,
  isOrganizationOwner,
}: Props) {
  const { hasPermissions } = useUserPermissions();
  const canEditMembers = hasPermissions([Permission.ChangeOrganizationMembers]);
  const canToggleMembers = hasPermissions([Permission.ToggleOrganizationMembers]);

  const handleToggleChange = ({ id, checked }: { id: string; checked: boolean }) => {
    onAccountToggle({
      variables: {
        id,
        action: checked ? ToggleAction.disable : ToggleAction.enable,
      },
    });
  };

  return (
    <Styled.Row>
      <Table.Cell>
        <Styled.AvatarCell>
          <Avatar url={member.avatarUrl} size={40} />
        </Styled.AvatarCell>
      </Table.Cell>
      <Table.Cell>{member.name}</Table.Cell>
      <Table.Cell>{member.email}</Table.Cell>
      <Table.Cell>
        <UserRoleBadge role={member.role} />
      </Table.Cell>
      <Table.Cell>{formatDate(new Date(member.dateJoined))}</Table.Cell>
      <Table.Cell>
        <MemoToggle
          id={member.id}
          checked={member.isActive}
          onChange={handleToggleChange}
          disabled={
            !member.isVerified ||
            isCurrentlyLoggedInUser ||
            isOrganizationOwner ||
            !canToggleMembers
          }
        />
      </Table.Cell>
      {canEditMembers && (
        <Table.Cell>
          <EditAction
            member={member}
            canRoleBeChanged={!isCurrentlyLoggedInUser && !isOrganizationOwner}
          />
        </Table.Cell>
      )}
    </Styled.Row>
  );
},
isDeepEqual);

interface EditActionProps {
  member: OrganizationMemberPick;
  canRoleBeChanged: boolean;
}

const EditAction: React.VFC<EditActionProps> = ({ member, canRoleBeChanged }) => {
  const [isModalOpen, setIsModalOpen] = useSafeState(false);
  return (
    <>
      <Tooltip title="Edit" arrow placement="top">
        <IconButton color="inherit" edge="end" onClick={() => setIsModalOpen(true)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <UpdateMemberModal
        member={member}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        canRoleBeChanged={canRoleBeChanged}
      />
    </>
  );
};
