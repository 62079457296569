import gql from 'graphql-tag';

import { pushError } from 'context/globalStream';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { ServerErrorCode, ServerErrorType } from 'models';
import { getErrorMessage } from 'utils/errors';
import { Nullable } from 'utils/types';

const errorMap = {
  [ServerErrorCode.YouTubeCredentialsRefresh]:
    'YouTube credentials seem to be invalid. Try to unlink and link the account again.',
};

const QUERY = gql`
  query GetYouTubeCategories($regionCode: String, $languageCode: LangCode) {
    availableYouTubeCategories(regionCode: $regionCode, languageCode: $languageCode) {
      id: category
      title
    }
  }
`;

export interface YouTubeCategory {
  id: number;
  title: string;
}

interface ResponseData {
  availableYouTubeCategories: Nullable<YouTubeCategory[]>;
}

interface Variables {
  regionCode?: string;
  languageCode?: string;
}

interface UseYouTubeCategoriesOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useYouTubeCategoriesQuery = (options?: UseYouTubeCategoriesOptions) => {
  const response = useQuery<ResponseData, Variables>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
    onError(apolloError) {
      const serverError = apolloError.graphQLErrors[0];
      if (serverError.hasOwnProperty('extensions')) {
        // @ts-expect-error
        const message = getErrorMessage(serverError as ServerErrorType, errorMap);
        pushError({ message });
      }
    },
  });

  const availableCategories = response.data?.availableYouTubeCategories || [];

  return {
    ...response,
    availableCategories,
  };
};
