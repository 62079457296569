import React from 'react';
import { Route } from 'react-router-dom';

import { Permission } from 'config/permissions';
import MissingMediaPage from 'pages/404';
import AuthorizeYoutubePage from 'pages/AuthorizeYoutube';
import { ConfirmNewEmailPage } from 'pages/ConfirmNewEmail';
import { D2CDashboard } from 'pages/D2C';
import Dashboard from 'pages/Dashboard';
import EditCarousel from 'pages/EditCarousel';
import EndCardPage from 'pages/EndCard';
import LoginPage from 'pages/Login';
import { MicrositePage } from 'pages/MicroSite';
import { MomentsPage } from 'pages/Moments';
import NewEndCardPage from 'pages/NewEndCard';
import { OrganizationPage } from 'pages/Organization';
import { OverlaysPage } from 'pages/Overlays';
import { PayNowPage } from 'pages/PayNowPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicy';
import PublishVideoPage from 'pages/PublishVideo';
import { RegisterAccountPage, RegisterOrganizationPage, SuccessPaymentPage } from 'pages/Register';
import ResetPasswordPage from 'pages/ResetPassword';
import { SettingsPage } from 'pages/Settings';
import { CreatorSuiteTermsOfUsePage, D2CTermsOfUsePage } from 'pages/TermsOfUse';
import { URLS } from 'pages/urls';
import { VerifyUserPage } from 'pages/VerifyUser';
import Video from 'pages/Video';
import Widget from 'pages/Widget';
import { PresetListPage } from 'pages/Widget/Presets';

import { CTAButtonPage, NewCTAButton } from './CTAButton';
import { CTAImagePage, NewCTAImage } from './CTAImage';
import { EditCustomCodeEndCardPage } from './EndCard/EditCustomCodeEndCardPage';
import { NewCustomCodeEndCardPage } from './NewCustomCodeEndCard';
import { NewPlaylistPage, PlaylistsPage, UpdatePlaylistPage } from './Playlists';
import { PromoteContent } from './PromoteContent';
import { SubscriptionPlan } from './Register/useRegisterOrganizationOwner';
import { VideoCTAPage } from './VideoCTA';
import { VideoEndCardPage } from './VideoEndCard';
import { VideoMonetization } from './VideoMonetization';

const attachKey = (prefix: string) => {
  return (jsx: JSX.Element, index: number) => {
    return React.cloneElement(jsx, { key: `${prefix}-${index}` });
  };
};

/** Pages that are only accessible when a user is authenticated. */
export const authorizedPages = (hasPermission: (permissions: Permission[]) => boolean) =>
  [
    <Route path={URLS.root} exact>
      {hasPermission([Permission.ViewVideoDashboard]) ? <Dashboard /> : <D2CDashboard />}
    </Route>,
    <Route path={URLS.d2c.root}>
      <D2CDashboard />
    </Route>,
    ...(hasPermission([Permission.ViewCarousel])
      ? [
          <Route path={URLS.widget.root} exact>
            <Widget />
          </Route>,
          <Route path={URLS.widget.carousel} exact>
            <EditCarousel />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.ViewVideoDashboard])
      ? [
          <Route path={URLS.dashboard.root}>
            <Dashboard />
          </Route>,
          <Route path={[URLS.video.root, URLS.video.objects]} exact>
            <Video />
          </Route>,
          <Route path={URLS.video.publish}>
            <PublishVideoPage />
          </Route>,
          <Route path={URLS.video.promoCards}>
            <PromoteContent />
          </Route>,
          <Route path={URLS.video.monetization}>
            <VideoMonetization />
          </Route>,
          <Route path={URLS.video.moments}>
            <MomentsPage />
          </Route>,
          <Route path={URLS.video.cta}>
            <VideoCTAPage />
          </Route>,
          <Route path={URLS.video.endCard}>
            <VideoEndCardPage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.ViewEndCard])
      ? [
          <Route path={URLS.endCard.new} exact>
            <NewEndCardPage />
          </Route>,
          <Route path={URLS.endCard.newCustomCode} exact>
            <NewCustomCodeEndCardPage />
          </Route>,
          <Route path={URLS.endCard.root}>
            <EndCardPage />
          </Route>,
          <Route path={URLS.endCard.customCodeRoot}>
            <EditCustomCodeEndCardPage />
          </Route>,
          <Route path={URLS.overlays.root}>
            <OverlaysPage />
          </Route>,
          <Route path={URLS.cta.newButton}>
            <NewCTAButton />
          </Route>,
          <Route path={URLS.cta.newImage}>
            <NewCTAImage />
          </Route>,
          <Route path={URLS.cta.button}>
            <CTAButtonPage />
          </Route>,
          <Route path={URLS.cta.image}>
            <CTAImagePage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.ManageOrganization])
      ? [
          <Route path={URLS.organization.root}>
            <OrganizationPage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.CreatePaymentSession])
      ? [
          <Route path={URLS.payNow}>
            <PayNowPage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.ViewMicrosite])
      ? [
          <Route path={URLS.microsite.root}>
            <MicrositePage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.ViewWidgetPreset])
      ? [
          <Route path={URLS.widget.presets} exact>
            <PresetListPage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.ViewPlaylist])
      ? [
          <Route path={URLS.playlists.list} exact>
            <PlaylistsPage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.CreatePlaylist])
      ? [
          <Route path={URLS.playlists.new} exact>
            <NewPlaylistPage />
          </Route>,
        ]
      : []),
    ...(hasPermission([Permission.ChangePlaylist])
      ? [
          <Route path={URLS.playlists.byId} exact>
            <UpdatePlaylistPage />
          </Route>,
        ]
      : []),
    <Route path={URLS.settings.root}>
      <SettingsPage />
    </Route>,
  ].map(attachKey('authorized'));

/** Pages that are only accessible when a user is unauthenticated. */
export const unauthorizedPages = [
  <Route path={URLS.root} exact>
    <LoginPage />
  </Route>,
  <Route path={URLS.verify} exact>
    <VerifyUserPage isD2CPlugin={false} />
  </Route>,
  <Route path={URLS.verifyD2C} exact>
    <VerifyUserPage isD2CPlugin={true} />
  </Route>,
  <Route path={URLS.register.root} exact>
    <RegisterAccountPage subscriptionPlan={SubscriptionPlan.premium} />
  </Route>,
  <Route path={URLS.register.trial} exact>
    <RegisterAccountPage subscriptionPlan={SubscriptionPlan.trial} />
  </Route>,
  <Route path={URLS.register.d2c} exact>
    <RegisterAccountPage subscriptionPlan={SubscriptionPlan.d2cPremium} />
  </Route>,
  <Route path={URLS.register.d2cTrial} exact>
    <RegisterAccountPage subscriptionPlan={SubscriptionPlan.d2cTrial} />
  </Route>,
  <Route path={URLS.register.organization}>
    <RegisterOrganizationPage />
  </Route>,
  <Route path={URLS.register.successPayment}>
    <SuccessPaymentPage />
  </Route>,
].map(attachKey('unauthorized'));

/**
 * Pages that are accessible all the time. Use sparingly.
 *
 * A page should be defined here to preserve state whenever user logs in/out in the background.
 * Otherwise some things which the user has interacted with may be reset to initial state.
 *
 * The common use cases for this are:
 * 1. a page doesn't care about user authentication status,
 * 2. a page is used to change user authentication status.
 *
 * */
export const sharedPages = [
  <Route path={URLS.authorize.youtube}>
    <AuthorizeYoutubePage />
  </Route>,
  <Route path={URLS.resetPassword.root}>
    <ResetPasswordPage />
  </Route>,
  <Route path={URLS.termsOfUse} exact>
    <CreatorSuiteTermsOfUsePage />
  </Route>,
  <Route path={URLS.termsOfUseD2C} exact>
    <D2CTermsOfUsePage />
  </Route>,
  <Route path={URLS.privacyPolicy}>
    <PrivacyPolicyPage />
  </Route>,
  <Route path={URLS.updateEmail.root}>
    <ConfirmNewEmailPage />
  </Route>,
  <Route>
    <MissingMediaPage />
  </Route>,
].map(attachKey('shared'));
