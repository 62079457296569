import { Nullable } from 'utils/types';

import { PageInfo } from './PageInfo';

export enum TranscriptionTokenType {
  Pronunciation = 'PRONUNCIATION',
  Punctuation = 'PUNCTUATION',
}

export interface VideoTranscriptionToken {
  id: string;
  startTimestamp: number;
  endTimestamp: number;
  token: string;
  tokenType: TranscriptionTokenType;
}

export interface VideoTranscriptionSegment {
  id: string;
  startTimestamp: number;
  endTimestamp: number;
  speakerLabel: string;
  tokens: VideoTranscriptionToken[];
  isSearchMatching?: boolean;
}

export interface VideoTranscription {
  id: string;
  languageCode: string;
  segments: {
    totalCount: number;
    nodes: Nullable<VideoTranscriptionSegment[]>;
    pageInfo: Nullable<PageInfo>;
  };
}
