import { Button } from '@material-ui/core';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarOrigin,
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarProviderProps,
  useSnackbar,
} from 'notistack';
import React from 'react';
import styled from 'styled-components/macro';

import { GlobalStream, globalStream$ } from 'context/globalStream';

export const DEFAULT_AUTOHIDE_DURATION = 5000; // ms

const variantOptionsMap: Record<GlobalStream['type'], OptionsObject | undefined> = {
  message: {
    variant: 'default',
  },
  error: {
    autoHideDuration: null,
    variant: 'error',
  },
};

const variantActionsMap = (
  closeSnackbar: (key?: SnackbarKey) => void,
): Record<GlobalStream['type'], OptionsObject['action'] | undefined> => ({
  message: undefined,
  error: (key) => {
    return (
      <Button onClick={() => closeSnackbar(key)} variant="text" color="inherit" size="small">
        DISMISS
      </Button>
    );
  },
});

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const CONTAINER_ROOT_CLASS = 'snackbar-provider';
const ContentWrapperContext = React.createContext<(element: HTMLElement) => void>(() => {});

export const SnackbarProvider: React.FC<SnackbarProviderProps> = (props) => {
  const [domRoot, setDomRoot] = React.useState<HTMLElement>();
  return (
    <ContentWrapperContext.Provider value={setDomRoot}>
      <NotistackSnackbarProvider
        maxSnack={3}
        anchorOrigin={anchorOrigin}
        autoHideDuration={DEFAULT_AUTOHIDE_DURATION}
        preventDuplicate
        domRoot={domRoot}
        classes={{ containerRoot: CONTAINER_ROOT_CLASS }}
        {...props}
      />
    </ContentWrapperContext.Provider>
  );
};

export const SnackbarController: React.FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar, removeSnackbars } = useSnackbar();
  const contentWrapperRef = React.useRef<HTMLDivElement>(null);
  const setElement = React.useContext(ContentWrapperContext);

  React.useEffect(() => {
    if (!contentWrapperRef.current) {
      return;
    }
    setElement(contentWrapperRef.current);
  }, [setElement]);

  React.useEffect(() => {
    const subscription = globalStream$.subscribe((next$) => {
      if (!next$) {
        return;
      }
      const { id, type, value } = next$;
      const { message, ...inlineOptions } = value;
      const action = variantActionsMap(closeSnackbar)[type];
      enqueueSnackbar(message, {
        key: id,
        ...variantOptionsMap[type],
        ...inlineOptions,
        action,
        // @ts-ignore
        'data-testid': 'snackbar-item',
      });
    });
    return () => {
      removeSnackbars();
      subscription.unsubscribe();
    };
  }, [enqueueSnackbar, closeSnackbar, removeSnackbars]);

  return <ContentWrapper ref={contentWrapperRef}>{children}</ContentWrapper>;
};

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;

  .${CONTAINER_ROOT_CLASS} {
    position: absolute;
  }
`;
