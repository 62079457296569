import { Box, Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { CustomPrompt } from 'components/common/CustomPrompt';
import { Columns } from 'components/common/Wrapper/Layout';
import BasePlayer from 'components/VideoPlayer/BasePlayer';
import { generateVideoLink } from 'pages/urls';
import { getStreamURL } from 'utils/streaming';

import { VideoMonetizationViewProps } from './types';

interface Props {
  hasUnsavedChanges: boolean;
}

export const VideoMonetizationMain = ({
  video,
  hasUnsavedChanges,
}: VideoMonetizationViewProps & Props) => {
  const history = useHistory();
  const handleModalConfirm = useCallback(() => {
    history.push(generateVideoLink(video.id));
  }, [history, video.id]);

  const sourceURL = getStreamURL(video.videoFileUrl, video.videoStreams);

  return (
    <>
      <Columns alignItems="center" display="flex" justifyContent="flex-end" pb="20px">
        <Button onClick={handleModalConfirm} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form="video-monetization-settings"
          disabled={!hasUnsavedChanges}
        >
          Save
        </Button>
      </Columns>
      <Box borderRadius="5px 5px 15px 15px">
        <BasePlayer url={sourceURL} />
      </Box>
      <CustomPrompt hasUnsavedChanges={hasUnsavedChanges} />
    </>
  );
};
