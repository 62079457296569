import { TimeRange } from 'models';
import { ButtonCTAResponse } from 'pages/CTAButton/types';
import { ImageCTAResponse } from 'pages/CTAImage/types';
import { CTAItemType } from 'pages/Overlays/CTAsTab/types';
import { Nullable } from 'utils/types';

export type VideoCTATimestampCtasResponse = { id: string } & {
  button: Nullable<ButtonCTAResponse>;
  image: Nullable<ImageCTAResponse>;
  order: number;
  config: Nullable<any>;
};

export type VideoCTATimestampResponse = { id: string } & {
  name: string;
  startTimestamp: number;
  endTimestamp: number;
  ctas: VideoCTATimestampCtasResponse[];
};

export interface VideoCTATimestampsResponse {
  ctaTimestamps: ({ id: string } & VideoCTATimestampResponse)[];
}

export interface VideoCTATimestamp {
  name: string;
  startTimestamp: number;
  endTimestamp: number;
  ctas: VideoCTATimestampCtas[];
}

interface VideoCTATimestampCtas {
  button: Nullable<string>;
  image: Nullable<string>;
  order: number;
  config: {
    width: Nullable<string>;
    position: CTATimestampCtasPosition;
  };
}

export interface CreateVideoCTAsInput {
  timestamps: VideoCTATimestamp[];
}

export interface CTATimestampCtasPosition {
  calculatedLeft: number;
  calculatedTop: number;
  isAlignment?: boolean;
}

export interface CTATimestampCtasAnimation {
  name: AnimationName;
  duration: Duration;
  cycleTime: CycleTime;
}

export interface CTATimestampCtas {
  id: string;
  order: number;
  type: Nullable<CTAItemType>;
  template: Nullable<{ id: string; name: string }>;
  styles: Nullable<ButtonCTAResponse | ImageCTAResponse>;
  width: Nullable<string>;
  config: {
    position: CTATimestampCtasPosition;
    animation?: CTATimestampCtasAnimation;
  };
}

export interface CTATimestamp {
  id: string;
  name: string;
  startTimestamp: number;
  endTimestamp: number;
  ranges: TimeRange;
  ctas: CTATimestampCtas[];
}

export interface CTAItemProps {
  disabledItem: boolean;
  item: CTATimestampCtas;
  onEditCTA: (cta: CTATimestampCtas) => void;
  onRemoveCTA: (ctaId: CTATimestampCtas['id']) => void;
}

export type Duration = '0.5s' | '1s' | '2s' | '3s' | '4s' | '5s' | '6s' | '7s' | '8s' | '9s';

export type CycleTime = 0 | 1 | 2 | 3 | 4 | 5 | 7 | 10 | 15 | 30 | 60;

export type AnimationName = 'JIGGLE' | 'BOUNCE' | 'ROTATE' | 'VIBRATE' | 'SHAKE' | '';

export enum AlignmentIcons {
  AlignBottom = 'ALIGN_BOTTOM',
  AlignCenterHorizontal = 'ALIGN_CENTER_HORIZONTAL',
  AlignCenterVertical = 'ALIGN_CENTER_VERTICAL',
  AlignLeft = 'ALIGN_LEFT',
  AlignRight = 'ALIGN_RIGHT',
  AlignTop = 'ALIGN_TOP',
}

export enum AnimationIcons {
  Jiggle = 'JIGGLE',
  Bounce = 'BOUNCE',
  Rotate = 'ROTATE',
  Vibrate = 'VIBRATE',
  Shake = 'SHAKE',
}
