import styled from 'styled-components/macro';

export const InlineCheckbox = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;

  .checkbox-label {
    margin: 0;
  }
`;
