import React, { useEffect } from 'react';

import { useSearch } from 'components/common/Search/Search';
import { TranscriptionSidebar } from 'components/common/TranscriptionSidebar';
import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { seekToMs, updateSource } from 'context/VideoMetadataStream';
import { useVideoTranscription } from 'hooks/query/useVideoTranscription';
import { VideoDetailsNode, VideoMomentNode } from 'models';
import { getStreamURL } from 'utils/streaming';

import { useMomentsEditorContext } from './context';
import { MomentsEditor } from './MomentsEditor';
import { MomentsSidebar } from './MomentsSidebar';

interface OwnProps {
  video: VideoDetailsNode;
  moments?: VideoMomentNode[];
}

const MomentPageView = ({ video, moments }: OwnProps) => {
  const sourceURL = getStreamURL(video.videoFileUrl, video.videoStreams);

  useEffect(() => updateSource({ duration: video.duration || 0 }), [video.duration]);

  const [searchText, setSearchText] = useSearch();
  const { transcription, segments, loadPage } = useVideoTranscription({
    variables: {
      videoId: video.id,
      search: searchText,
    },
  });

  const { openNewMomentMenu } = useMomentsEditorContext();

  const handleCreateMomentClick = (startTimestamp: number, endTimestamp: number) => {
    openNewMomentMenu({ startTimestamp, endTimestamp });
  };

  return (
    <ExtensibleSidebar
      sideContent={
        <MomentsSidebar videoId={video.id} moments={moments} videoDuration={video.duration} />
      }
      mainContent={<MomentsEditor videoUrl={sourceURL} moments={moments} videoId={video.id} />}
      moment
      rightContent={
        transcription ? (
          <TranscriptionSidebar
            allowMomentCreation
            onCreateMomentClick={handleCreateMomentClick}
            transcription={transcription}
            segments={segments}
            loadPage={loadPage}
            seekTo={seekToMs}
            onSearch={setSearchText}
          />
        ) : null
      }
    />
  );
};

export default MomentPageView;
