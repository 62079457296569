import { format, isToday } from 'date-fns';

export const formatDate = (date: Date) => {
  if (isToday(date)) {
    return 'Today';
  }
  return format(date, 'EEE, d MMM Y');
};

export const formatDateTime = (date: Date) => {
  return format(date, 'd MMM Y, hh:mm a');
};

export const changeDateToUTC = (dateString: string): string => {
  if (dateString === '') {
    return '';
  }
  const date = new Date(dateString);

  const yearUTC = String(date.getUTCFullYear()).padStart(4, '0');
  const monthUTC = String(date.getUTCMonth() + 1).padStart(2, '0');
  const dateUTC = String(date.getUTCDate()).padStart(2, '0');
  const hoursUTC = String(date.getUTCHours()).padStart(2, '0');
  const MinutesUTC = String(date.getUTCMinutes()).padStart(2, '0');

  const dateStringUTC = `${yearUTC}-${monthUTC}-${dateUTC}T${hoursUTC}:${MinutesUTC}`;
  return dateStringUTC;
};

export const isFutureDate = (dateString: string): boolean => {
  if (dateString === '') {
    return false;
  }
  const date = new Date(dateString);
  const currentDate = new Date();
  if (date > currentDate) {
    return true;
  } else return false;
};
