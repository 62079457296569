import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { FormLabel, TextInput } from 'components/common';
import CustomColor from 'components/common/CustomColor';
import { NumberInput } from 'components/common/NumberInput';
import { MeasurementUnit } from 'components/common/NumberInput/types';
import { getAllFontFamilyOptions } from 'utils/fonts';
import { validateColor } from 'utils/validation';

import { validateTextFontSize } from '../helpers';

import { FormField } from 'components/common/TagsField/TagsField.styles';

interface Props {
  errors: FieldErrors;
  control: Control;
}

export const ButtonText: React.FC<Props> = ({ errors, control }) => {
  const fontFamilyOptions = useMemo(() => getAllFontFamilyOptions(), []);

  return (
    <>
      <FormLabel>Button text *</FormLabel>
      <FormField mb="40px">
        <Controller
          rules={{ required: 'Button text cannot be empty' }}
          render={({ onChange, ...props }) => (
            <TextInput
              {...props}
              onChange={onChange}
              onBlur={({ currentTarget }) => onChange(currentTarget.value.trim())}
              label="Text *"
              error={!!errors.text}
              errorMessage={errors.text?.message}
              placeholder="Enter button text"
              mb="40px"
              info="Text"
              maxLength={50}
            />
          )}
          name="text"
          control={control}
        />

        <Box mb="40px">
          <FormLabel>Font family</FormLabel>
          <Controller
            render={({ onChange, value, ...props }) => (
              <Autocomplete
                options={fontFamilyOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                onChange={(e, data) => onChange(data)}
                value={value}
                {...props}
              />
            )}
            name="fontFamily"
            control={control}
          />
        </Box>
        <Controller
          name="fontSize"
          control={control}
          rules={{ validate: validateTextFontSize }}
          render={(field) => (
            <NumberInput
              {...field}
              measurement={MeasurementUnit.Pixel}
              label="Font size"
              error={!!errors.fontSize}
              errorMessage={errors.fontSize?.message}
              placeholder="Enter button text"
              mb="40px"
              info="Font size"
              validationMessage="Value from 12px to 18px"
              min={12}
              max={18}
            />
          )}
        />
        <Controller
          name="fontColor"
          control={control}
          rules={{ validate: validateColor }}
          render={({ value, onChange }) => (
            <CustomColor
              onColorChange={onChange}
              selectedColor={value}
              mb="18px"
              label="Font color"
              error={!!errors.fontColor}
              errorMessage={errors.fontColor?.message}
            />
          )}
        />
      </FormField>
    </>
  );
};
