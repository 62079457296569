import { Box } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { FormLabel } from 'components/common';

import { ErrorMessage } from 'components/common/TextInput/TextInput.styles';

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
  maxLength?: number;
  errorMessage?: string;
  postText: string;
}

export const SlugInput = React.forwardRef<HTMLInputElement, Props>(function SlugInput(
  { name, label, maxLength, errorMessage, postText, ...props },
  ref,
) {
  return (
    <Box position="relative" mb="30px">
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Wrapper>
        <PreWrapper>https://</PreWrapper>
        <InputWrapper error={!!errorMessage}>
          <Input
            ref={ref}
            type="text"
            id={name}
            name={name}
            maxLength={maxLength}
            aria-invalid={!!errorMessage}
            {...props}
          />
        </InputWrapper>
        <PostWrapper>{postText}</PostWrapper>
      </Wrapper>
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </Box>
  );
});

const Wrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  border: 1px solid ${materialTheme.palette.grey[200]};
  padding: 0 8px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    color: currentColor;
    opacity: 0.42;
  }

  &:hover {
    border-color: ${materialTheme.palette.secondary.main};
  }

  &:focus {
    border-width: 2px;
    border-color: ${materialTheme.palette.secondary.main} !important;
    padding: 0 7px;
  }
`;

const InputWrapper = styled.div<{ error: boolean }>`
  flex-grow: 1;

  ${({ error }) =>
    error &&
    css`
      ${Input} {
        border-color: ${materialTheme.palette.error.main};
      }
    `}
`;

const urlPart = css`
  background: ${materialTheme.palette.grey[100]};
  flex-shrink: 0;
  border: 1px solid ${materialTheme.palette.grey[200]};
  color: ${materialTheme.palette.grey[500]};
  display: flex;
  align-items: center;
`;

const PreWrapper = styled.div`
  ${urlPart};
  border-radius: 10px 0 0 10px;
  border-right: 0;
  padding-left: 10px;
  padding-right: 6px;
`;

const PostWrapper = styled.div`
  ${urlPart};
  border-radius: 0 10px 10px 0;
  border-left: 0;
  padding-left: 6px;
  padding-right: 10px;
`;
