import { CTAItemType } from 'pages/Overlays/CTAsTab/types';

import { CTATimestamp, VideoCTATimestamp, VideoCTATimestampsResponse } from './type';

export const convertDataFromServer = (
  items: VideoCTATimestampsResponse['ctaTimestamps'],
): CTATimestamp[] => {
  return items.map(({ startTimestamp, endTimestamp, ctas, ...field }) => ({
    ...field,
    startTimestamp,
    endTimestamp,
    ranges: [startTimestamp, endTimestamp],
    ctas: ctas.map(({ image, button, config, ...cta }) => ({
      ...cta,
      type: image ? CTAItemType.Image : CTAItemType.Button,
      template: image || button,
      styles: image || button,
      width: JSON.parse(config).width,
      config: JSON.parse(config),
    })),
  }));
};

export const convertDataToServer = (items: CTATimestamp[]): VideoCTATimestamp[] => {
  return items.map(({ ranges, ctas, name }) => ({
    name,
    startTimestamp: ranges[0],
    endTimestamp: ranges[1],
    ctas: ctas.map(({ width, ...cta }, order) => {
      const ctaTypeId = cta.template?.id || null;
      const { isAlignment, ...position } = cta.config.position;
      const animation = cta.config.animation;
      return {
        order,
        button: cta.type === CTAItemType.Button ? ctaTypeId : null,
        image: cta.type === CTAItemType.Image ? ctaTypeId : null,
        config: { width, position, animation },
      };
    }),
  }));
};

export const percentageToPx = (percentageValue: number, containerSize: number) => {
  return Math.ceil((percentageValue / 100) * containerSize);
};

export const pxToPercentage = (percentageValue: number, containerSize: number) => {
  return (percentageValue * 100) / containerSize;
};

export const getPositionCenter = (videoSize: number, size: number) => videoSize / 2 - size / 2;
