import { darken, lighten } from 'polished';
import React from 'react';

import { customPalette, materialTheme } from 'components/App/materialTheme';
import { Badge, BadgeProps } from 'components/common/Badge';
import { TranscriptionStatus } from 'models';

interface Props {
  status: TranscriptionStatus;
}

const statusMap: Record<TranscriptionStatus, string> = {
  [TranscriptionStatus.Completed]: 'COMPLETE',
  [TranscriptionStatus.Failed]: 'FAILED',
  [TranscriptionStatus.InProgress]: 'IN PROGRESS',
  [TranscriptionStatus.Queued]: 'QUEUED',
  [TranscriptionStatus.Validation]: 'VALIDATION',
};

export const formatTranscriptionStatus = (status: TranscriptionStatus) => statusMap[status];

export const TranscriptionStatusBadge: React.VFC<Props> = ({ status }) => {
  const props = colorMap[status];
  return <Badge {...props}>{formatTranscriptionStatus(status)}</Badge>;
};

export const colorMap: Record<TranscriptionStatus, Partial<BadgeProps>> = {
  [TranscriptionStatus.InProgress]: {
    backgroundColor: lighten(0.36, materialTheme.palette.warning.main),
    color: materialTheme.palette.warning.main,
  },
  [TranscriptionStatus.Failed]: {
    backgroundColor: lighten(0.46, materialTheme.palette.error.main),
    color: materialTheme.palette.error.main,
  },
  [TranscriptionStatus.Completed]: {
    backgroundColor: lighten(0.6, materialTheme.palette.success.main),
    color: darken(0.1, materialTheme.palette.success.main),
  },
  [TranscriptionStatus.Queued]: {
    backgroundColor: lighten(0.85, materialTheme.palette.text.primary),
    color: materialTheme.palette.text.primary,
  },
  [TranscriptionStatus.Validation]: {
    backgroundColor: lighten(0.45, customPalette.yellow),
    color: customPalette.yellow,
  },
};
