import { Box, makeStyles, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Control, Controller } from 'react-hook-form';

import { FormLabel, Toggle } from 'components/common';

import * as Styled from '../styled';

const selectStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

interface ButtonTemplatePresetProps {
  label: string;
  visible: boolean;
  defaultValue?: string;
  name: string;
  control: Control;
  options: { label: string; value: string }[];
  onToggle: () => void;
}

export const ButtonTemplateSelect: React.FC<ButtonTemplatePresetProps> = ({
  visible,
  name,
  defaultValue,
  control,
  options,
  label,
  onToggle,
}) => {
  const selectClasses = selectStyles();

  return (
    <>
      <Styled.InlineCheckbox style={{ marginBottom: '15px' }}>
        <FormLabel className="checkbox-label">{label}</FormLabel>
        <Toggle checked={visible} color="primary" onChange={onToggle} />
      </Styled.InlineCheckbox>
      {visible && (
        <Box
          display="flex"
          flexDirection="column"
          p="15px 20px"
          mb="15px"
          border="1px solid #e6e6e6"
          borderRadius="10px"
        >
          <FormLabel className="checkbox-label">Template</FormLabel>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ onChange, ...props }) => (
              <Select
                style={{ height: '56px' }}
                variant="outlined"
                className={selectClasses.root}
                onChange={onChange}
                {...props}
              >
                {options.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
      )}
    </>
  );
};
