import { Box } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { Avatar, Link } from 'components/common';
import { useLogout } from 'hooks/mutation';
import { URLS } from 'pages/urls';

import * as Styled from './UserProfile.styles';

interface UserDropdownProps {
  displayName?: string;
  avatarUrl?: string;
}

const dropdownLinks = [
  {
    label: 'Manage account',
    icon: <Settings />,
    href: URLS.settings.root,
  },
];

export const UserDropdown: React.FC<UserDropdownProps> = ({ displayName, avatarUrl }) => {
  const [logOut] = useLogout();

  return (
    <Styled.UserDropdown>
      <Box width="100%" display="flex" alignItems="center">
        <Avatar dropdownAvatar={true} url={avatarUrl} />
        <Styled.Username darkColor={true}>{displayName}</Styled.Username>
      </Box>
      <Styled.DropdownList>
        {dropdownLinks.length &&
          dropdownLinks.map(({ icon, label, href }) => (
            <Styled.DropdownItem key={href}>
              <Link to={href} className="dropdown-link">
                {icon}
                <span className="dropdown-link-label">{label}</span>
              </Link>
            </Styled.DropdownItem>
          ))}
        <Styled.DropdownItem>
          <button className="dropdown-link dropdown-link-logout" onClick={logOut}>
            <Logout />
            <span className="dropdown-link-label">Sign out</span>
          </button>
        </Styled.DropdownItem>
      </Styled.DropdownList>
    </Styled.UserDropdown>
  );
};
