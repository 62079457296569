import { useCallback, useEffect, useRef, useState } from 'react';

type UseWidthProps = (width: number, height: number) => void;

const useElementSize = (
  onResize?: UseWidthProps,
): {
  width: number;
  height: number;
  elementRef: React.RefObject<HTMLDivElement>;
} => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const getSize = useCallback((customWidth?: number) => {
    if (elementRef.current) {
      const newWidth = customWidth ?? elementRef.current.offsetWidth;
      const newHeight = elementRef.current.offsetHeight;
      setWidth(newWidth);
      setHeight(newHeight);
      if (onResize) {
        onResize(newWidth, newHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSize();

    const handleResize = () => {
      getSize();
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('animationend', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('animationend', handleResize);
      getSize(0);
    };
  }, [getSize]);

  return { width, height, elementRef };
};

export default useElementSize;
