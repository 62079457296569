import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  user-select: none;
  margin-left: -10px;
  margin-right: -10px;
`;

export const TEXT_INPUT_PADDING = 10;

export const TimeInputs = styled.div`
  align-items: center;
  display: flex;
`;

export const ErrorMessage = styled.p`
  font-size: 13px;
  margin-top: 8px;
`;

export const InputButton = styled.button`
  background-color: #f5f5f5;
  position: relative;
  right: 20px;
  height: 13px;
  width: 15px;
`;

export const ButtonsWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 13px;
  right: -15px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  ${InputButton} {
    display: none;
  }

  &:hover {
    ${InputButton} {
      display: block;
    }
  }
`;

interface ButtonTypes {
  isSplitDisabled?: boolean;
}

export const Button = styled.button<ButtonTypes>`
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  padding: 6px 10px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  margin-right: 5px;
  align-items: center;
  transition: 0.25s ease-out;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .start-icon {
    transform: rotate(-90deg);
    margin-right: 5px;
  }

  .end-icon {
    transform: rotate(90deg);
    margin-left: 5px;
  }

  .scale-icon {
    width: 12px;
    height: 12px;
  }

  .split-icon {
    width: 19px;
    height: 12px;
    margin-right: 5px;
  }

  ${({ isSplitDisabled }) =>
    isSplitDisabled
      ? css`
          cursor: not-allowed !important;
          opacity: 0.5;
        `
      : null}
`;

export const TimelineHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
`;

export const TimelineTools = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 10px;
`;

export const TimelineSection = styled.div`
  display: flex;
`;

export const TimelineTitle = styled.h5`
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0;
  margin: 0;
`;
