import { Button } from '@material-ui/core';
import React from 'react';

import { Dialog } from 'components/common/Dialog';
import { Stack } from 'components/common/Wrapper/Layout';
import { PlaylistContentType } from 'models/Playlist';

import { AddToPlaylistPicker } from './AddToPlaylistPicker';
import { Item } from './types';

interface Props {
  item: Item;
  isOpen: boolean;
  onClose: () => void;
  openPublishModal: () => void;
}

export function AddToPlaylistModal({ item, isOpen, onClose, openPublishModal }: Props) {
  const isMoment = item.type === PlaylistContentType.Moments;

  return (
    <Dialog open={isOpen} onClose={onClose} heading="Add to Playlist" width={600}>
      {item.isPublished ? (
        <AddToPlaylistPicker item={item} onClose={onClose} />
      ) : (
        <Stack rowGap="20px">
          <Dialog.Text>{isMoment ? 'Moment' : 'Video'} needs to be published first.</Dialog.Text>
          <Button variant="contained" color="primary" onClick={openPublishModal}>
            Publish
          </Button>
        </Stack>
      )}
    </Dialog>
  );
}
