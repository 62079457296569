import { CREATE_VIDEO_CTA_MUTATION } from 'context/queries/videoCTAMutations';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { CreateVideoCTAsInput, VideoCTATimestampsResponse } from 'pages/VideoCTA/type';
import { Nullable } from 'utils/types';

interface ResponseData {
  createVideoCtas: Nullable<{
    buttonCta: VideoCTATimestampsResponse;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  video_ctas: CreateVideoCTAsInput;
}

interface UseCreateVideoCTAs extends UseMutationOptions<ResponseData, Variables> {}

export const useCreateVideoCTAs = (options: UseCreateVideoCTAs = {}) => {
  return useMutation<ResponseData, Variables>(CREATE_VIDEO_CTA_MUTATION, {
    pathToErrors: 'createVideoCtas.errors',
    ...options,
  });
};
