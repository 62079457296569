import { TimeRange } from 'models';
import { ValidationError } from 'utils/errors';

/**
 * @example 5371 => 5.371
 */
export const msToSeconds = (value: number) => (value === 0 ? 0 : value / 1000);

/**
 * @example 5371 => 5
 */
export const msToFloorSeconds = (value: number) => (value === 0 ? 0 : Math.round(value / 1000));

/**
 * @example 5371 => 5000
 */
export const msRoundToFullSecond = (value: number) => Math.round(value / 1000) * 1000;

/**
 * @example 5.371 => 5371
 */
export const secondsToMs = (value: number) => (value === 0 ? 0 : value * 1000);

const pad = (value: number, length = 2) => String(value).padStart(length, '0');

interface MsToHMSStringOptions {
  forceHours?: boolean;
  skipMilliseconds?: boolean;
}

/**
 * @example 14832051 => "4:07:12.051"
 * @example -84712 => "-1:24.712"
 */
export const msToHMSString = (
  ms: number,
  { forceHours = false, skipMilliseconds = false }: MsToHMSStringOptions = {},
) => {
  const negativePrefix = ms < 0 ? '-' : '';
  const date = new Date(Math.abs(ms));
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  const msSuffix = skipMilliseconds ? '' : `.${pad(date.getUTCMilliseconds(), 3)}`;
  if (forceHours || hh) {
    return `${negativePrefix}${hh}:${pad(mm)}:${ss}${msSuffix}`;
  }
  return `${negativePrefix}${mm}:${ss}${msSuffix}`;
};

/**
 * @example "4:07:12" => 14832000
 * @example "-1:24" => -84000
 */
export const hmsStringToMs = (hms: string) => {
  const isNegative = hms.startsWith('-');
  const positiveHMS = isNegative ? hms.replace('-', '') : hms;
  const parts = positiveHMS.split(':').reverse();

  if (parts.length > 3 || parts.length < 2) {
    throw new ValidationError(
      `[Time Helpers] HMS string must contain from 2 to 3 parts. Received "${hms}" (${parts.length} parts) instead.`,
    );
  }

  const [ss, mm, hh] = parts.map((part, index) => {
    if (!part.length) {
      throw new ValidationError(`[Time Helpers] HMS string part cannot be empty.`);
    }

    if (index === 0) {
      if (!/^(\d{1,2})(\.\d{0,3})?$/.test(part)) {
        throw new ValidationError(
          `[Time Helpers] Seconds can contain 1-2 digits, optional milliseconds can contain up to 3 digits. Received "${part}".`,
        );
      }
    } else {
      if (!/^\d{1,2}$/.test(part)) {
        throw new ValidationError(
          `[Time Helpers] HMS part can only contain 1-2 digits. Received "${part}".`,
        );
      }
    }

    return Number(part);
  });

  const sign = isNegative ? -1 : 1;
  return sign * (ss * 1000 + mm * 60_000 + (hh ? hh * 3600_000 : 0));
};

/**
 * @example [14832051, 16347324] => ["4:07:12.051", "4:32:27.324"]
 */
export const timeRangeToHMSStrings = (timeRange: TimeRange, options: MsToHMSStringOptions = {}) => {
  const date = new Date(timeRange[1]);
  const hasHours = !!date.getUTCHours();
  return timeRange.map((ms) => msToHMSString(ms, { forceHours: hasHours, ...options }));
};

/**
 * @example [84712, 92347] => "1:24.712 / 1:32.347"
 */
export const timeRangeToString = (
  timeRange: TimeRange,
  { separator = '/', ...options }: { separator?: string } & MsToHMSStringOptions = {},
) => {
  const [start, end] = timeRangeToHMSStrings(timeRange, options);
  return `${start} ${separator} ${end}`;
};

export const getTimestampIntersection = (
  firstTimestamp: TimeRange,
  secondTimestamp: TimeRange,
): TimeRange => [
  firstTimestamp[0] < secondTimestamp[0] ? secondTimestamp[0] : firstTimestamp[0],
  firstTimestamp[1] > secondTimestamp[1] ? secondTimestamp[1] : firstTimestamp[1],
];
