import { Control } from 'react-hook-form';

export enum VerticalAlignment {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
}

export enum HorizontalAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum Direction {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export type AlignmentValue = VerticalAlignment | HorizontalAlignment;

export interface AlignmentInputProps {
  control: Control;
  fieldName: string;
  defaultHorizontalValue?: HorizontalAlignment;
  defaultVerticalValue?: VerticalAlignment;
}
