import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import {
  PlaylistContentType,
  PlaylistFilterBy,
  PlaylistI,
  PlaylistItemType,
  PlaylistOrdering,
} from 'models/Playlist';
import { Nullable } from 'utils/types';

type Errors = 'NO_ITEMS_PROVIDED' | 'ITEM_NOT_FOUND';

const errorMap: Record<Errors, string> = {
  NO_ITEMS_PROVIDED: 'No videos or moments were selected in manual ordering',
  ITEM_NOT_FOUND: 'Playlist contains unpublished or non-existent videos or moments.',
};

const QUERY = gql`
  mutation UpdatePlaylist($id: ID!, $playlist: UpdatePlaylistInput!) {
    updatePlaylist(id: $id, playlist: $playlist) {
      playlist {
        id
        name
        contentType
        orderBy
        filterBy
        filterValue
        items {
          nodes {
            ... on PublicVideoType {
              id
            }
            ... on PublicMomentType {
              id
            }
          }
        }
      }
      ${ERRORS_PART}
    }
  }
`;

type UpdatedPlaylist = Pick<
  PlaylistI,
  'id' | 'name' | 'contentType' | 'orderBy' | 'filterBy' | 'filterValue'
> & {
  items: {
    nodes: Nullable<Pick<PlaylistItemType, 'id'>[]>;
  };
};

interface ResponseData {
  updatePlaylist: Nullable<{
    playlist: Nullable<UpdatedPlaylist>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  playlist: Partial<{
    name: string;
    items: string[];
    orderBy: PlaylistOrdering;
    filterBy: PlaylistFilterBy;
    filterValue: string;
    contentType: PlaylistContentType;
  }>;
}

interface UseUpdatePlaylistMutationOptions extends UseMutationOptions<ResponseData, Variables> {}

export function useUpdatePlaylist(options?: UseUpdatePlaylistMutationOptions) {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'updatePlaylist.errors',
    errorMap,
    ...options,
  });
}
