import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

const PROMOTE_RESPONSE_FRAGMENT = gql`
  fragment PromoteContentFragment on VideoPromotedContentSettingsType {
    promoteObjects
    promoteAtStart
    promoteAtEnd
    promotedObjectsLabel
  }
`;

export const UPDATE_PROMOTE_CONTENT = gql`
  ${PROMOTE_RESPONSE_FRAGMENT}

  mutation UpdateVideoPromotedContentSettings($videoId: ID!, $promotedContentSettings: UpdateVideoPromotedContentSettingsInput!) {
    updateVideoPromotedContentSettings(videoId: $videoId, promotedContentSettings: $promotedContentSettings) {
      promotedContentSettings {
        ...PromoteContentFragment
      }
      ${ERRORS_PART}
    }
  }
`;

export const TOGGLE_PROMOTE_CONTENT = gql`
  ${PROMOTE_RESPONSE_FRAGMENT}

  mutation ToggleVideoPromotedContent($action: ToggleAction!, $videoId: ID!) {
    toggleVideoPromotedContent(action: $action, videoId: $videoId) {
      promotedContentSettings {
        ...PromoteContentFragment
      } 
      ${ERRORS_PART}
    }
  }
`;
