import { DELETE_END_CARD_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  deleeteEndCard: Nullable<{
    deletedEndCardId: string;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

export const useDelteEndCard = () => {
  return useMutation<ResponseData, Variables>(DELETE_END_CARD_MUTATION, {
    pathToErrors: 'deletedEndCardId.errors',
    refetchQueries: ['GetEndCards'],
  });
};
