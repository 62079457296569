import { Box } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextInput } from 'components/common';
import { GA4MeasurementIDInput } from 'components/common/GA4MeasurementIDInput';
import { GATrackingIDInput } from 'components/common/GATrackingIDInput';
import { MicrositeLayoutContent } from 'pages/MicroSite/MicrositeLayout';

const Other: React.VFC = () => {
  const { register } = useFormContext();
  return (
    <MicrositeLayoutContent>
      <Box width="100%">
        <GATrackingIDInput name="googleAnalyticsId" fullWidth />
        <GA4MeasurementIDInput name="googleAnalytics4Id" fullWidth />
        <TextInput
          ref={register()}
          label="Custom HTML"
          name="htmlCode"
          fullWidth
          multiline
          rows={10}
        />
      </Box>
    </MicrositeLayoutContent>
  );
};

export default Other;
