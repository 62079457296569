import { darken, lighten } from 'polished';
import React from 'react';

import { customPalette, materialTheme } from 'components/App/materialTheme';
import { Badge, BadgeProps } from 'components/common/Badge';
import { VideoStatus } from 'models';
import { formatVideoStatus } from 'utils/videos';

interface Props {
  status: VideoStatus;
}

export const VideoStatusBadge: React.VFC<Props> = ({ status }) => {
  const props = colorMap[status];
  return <Badge {...props}>{formatVideoStatus(status)}</Badge>;
};

export const colorMap: Record<VideoStatus, Partial<BadgeProps>> = {
  [VideoStatus.Created]: {
    backgroundColor: lighten(0.55, customPalette.darkBlue),
    color: customPalette.darkBlue,
  },
  [VideoStatus.Draft]: {
    backgroundColor: lighten(0.85, materialTheme.palette.text.primary),
    color: materialTheme.palette.text.primary,
  },
  [VideoStatus.Edited]: {
    backgroundColor: lighten(0.6, materialTheme.palette.success.main),
    color: darken(0.1, materialTheme.palette.success.main),
  },
  [VideoStatus.Failed]: {
    backgroundColor: lighten(0.46, materialTheme.palette.error.main),
    color: materialTheme.palette.error.main,
  },
  [VideoStatus.Processing]: {
    backgroundColor: lighten(0.36, materialTheme.palette.warning.main),
    color: materialTheme.palette.warning.main,
  },
  [VideoStatus.Published]: {
    backgroundColor: lighten(0.6, materialTheme.palette.success.main),
    color: darken(0.1, materialTheme.palette.success.main),
  },
  [VideoStatus.Scheduled]: {
    backgroundColor: lighten(0.5, customPalette.magenta),
    color: customPalette.magenta,
  },
  [VideoStatus.Uploaded]: {
    backgroundColor: lighten(0.58, materialTheme.palette.success.main),
    color: darken(0.1, materialTheme.palette.success.main),
  },
  [VideoStatus.Uploading]: {
    backgroundColor: lighten(0.33, materialTheme.palette.info.main),
    color: materialTheme.palette.info.main,
  },
};
