import { Button, IconButton, Tooltip } from '@material-ui/core';
import React, { useReducer } from 'react';

import { ReactComponent as Revoke } from 'assets/icons/x.svg';
import { Spinner } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import PaginationFooter from 'components/PaginationFooter';
import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useRevokeD2CApiKey } from 'hooks/mutation';
import { useD2CApiKeyQuery } from 'hooks/query';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { D2CApiKeyOrderBy } from 'models';
import { formatDate } from 'utils/date';

import { ApiKeyCell } from '../ApiKeyCell';
import { KeyStatus, KeyStatusBadge } from '../KeyStatusBadge';
import { NewKeyModal } from '../NewKeyModal';

import * as Styled from 'components/common/Table/Table.styles';

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

const initialPaginationSettings = {
  page: 1,
  pageSize: ROWS_PER_PAGE_OPTIONS[1],
  changingCount: 0,
};

export const ApiKeyTab: React.VFC = () => {
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const { orderBy, sorting, setSorting } = useSorting<D2CApiKeyOrderBy>('-created');
  const [pagination, dispatch] = useReducer(paginationReducer, initialPaginationSettings);
  const { loading, data, refetch } = useD2CApiKeyQuery({
    variables: {
      ...pagination,
      orderBy,
    },
  });

  const [revokeApiKey] = useRevokeD2CApiKey();

  const { hasPermissions } = useUserPermissions();

  const apiKeys = data?.d2cApiKeys?.nodes || [];

  if (loading) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  return (
    <Styled.TableWrapper>
      <LoadingOverlay isVisible={loading} />
      <Styled.TableHorizontalScroll>
        <Table>
          <Table.Head>
            <Table.HeadCell width="40%">
              <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="name">
                Key Name
              </SortInterface>
            </Table.HeadCell>
            <Table.HeadCell width="60%">Api Key</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>
              <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
                Date created
              </SortInterface>
            </Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {apiKeys.map(({ id, key, created, revoked, name }) => (
              <Table.Row key={id}>
                <Table.Cell>
                  <strong>{name}</strong>
                </Table.Cell>
                <Table.Cell>
                  <ApiKeyCell apiKey={key} />
                </Table.Cell>
                <Table.Cell>
                  <KeyStatusBadge status={revoked ? KeyStatus.inActive : KeyStatus.active} />
                </Table.Cell>
                <Table.Cell>{formatDate(new Date(created))}</Table.Cell>
                <Table.Cell>
                  {hasPermissions([Permission.RevokeD2CApiKey]) && !revoked && (
                    <Tooltip title="Revoke" arrow placement="top">
                      <IconButton
                        color="inherit"
                        edge="end"
                        onClick={() => {
                          if (window.confirm('Are you sure you want to revoke this API key?')) {
                            revokeApiKey({ variables: { id } });
                          }
                        }}
                      >
                        <Revoke />
                      </IconButton>
                    </Tooltip>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <PaginationFooter
            totalCount={data?.d2cApiKeys?.totalCount ?? 0}
            visibleCount={apiKeys.length}
            dispatch={dispatch}
            pageInfo={data?.d2cApiKeys?.pageInfo}
          />
        </Table>
      </Styled.TableHorizontalScroll>
      <Styled.ActionsContainer>
        <Button
          variant="contained"
          color="primary"
          data-testid="newKey"
          onClick={() => setShowCreateModal(true)}
        >
          New Key
        </Button>
      </Styled.ActionsContainer>
      <NewKeyModal
        isOpen={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          refetch();
        }}
      />
    </Styled.TableWrapper>
  );
};
