import gql from 'graphql-tag';

export const END_CARDS_FRAGMENT = gql`
  fragment EndCardsMetaData on EndCardType {
    created
    id
    name
    customCode
    status
    cardType
    cardOrientation
    cardSize
    overlay
    background
    backgroundImageSettings
    border
    videoControlButtons
    origin
    headline
    subline
    backgroundImageUrl
    ctaButton1 {
      id
      name
    }
    ctaButton2 {
      id
      name
    }
    promoCodeButton {
      label
      text
    }
    watchNextButtonVisible
    videosCount
    momentsCount
    videos {
      id
    }
    moments {
      video {
        id
      }
    }
  }
`;

export const END_CARDS_QUERY = gql`
  ${END_CARDS_FRAGMENT}

  query GetEndCards($orderBy: String, $pageSize: Int) {
    endCards(orderBy: $orderBy, pageSize: $pageSize) {
      totalCount
      nodes {
        ...EndCardsMetaData
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        pageNumber
        pageSize
      }
    }
  }
`;

export const END_CARD_QUERY = gql`
  ${END_CARDS_FRAGMENT}

  query GetEndCard($id: ID!) {
    endCardById(id: $id) {
      ...EndCardsMetaData
    }
  }
`;
