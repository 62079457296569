import { URLS } from 'pages/urls';
import { history } from 'utils/history';

import { Optional } from './types';

export const navigateTo404 = () => {
  history.push(URLS.missingMedia);
};

export const navigateTo404IfEmpty = (arr: Optional<any[]>) => {
  if (arr?.length === 0) {
    navigateTo404();
  }
};

export const navigateToRoot = () => {
  history.push(URLS.root);
};

export function pushHistoryUrl(url: string, state?: any) {
  window.history.pushState(state, '', url);
}

function handleBeforeUnload(event: Event) {
  event.preventDefault();
  event.returnValue = true;
}

let isLeaveWarningEnabled = false;

export function enableLeaveWarning() {
  if (isLeaveWarningEnabled) return;
  window.addEventListener('beforeunload', handleBeforeUnload);
  isLeaveWarningEnabled = true;
}

export function disableLeaveWarning() {
  window.removeEventListener('beforeunload', handleBeforeUnload);
  isLeaveWarningEnabled = false;
}
