import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 10px;
`;

interface ThumbnailProps {
  image: string;
}

export const Details = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const Thumbnail = styled.div<ThumbnailProps>`
  background-image: ${({ image }) => image && `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  height: 80px;
  width: 106px;
  flex-shrink: 0;
`;

export const Title = styled.div`
  margin-left: 20px;
  font-weight: bold;
`;
