import React, { useReducer } from 'react';

import { Spinner } from 'components/common';
import { useSearch } from 'components/common/Search/Search';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useDashboardMomentsQuery } from 'hooks/query';
import { MomentOrderBy } from 'models';
import MomentsTable from 'pages/Dashboard/Tables/MomentsTable';

import * as Styled from 'components/common/Table/Table.styles';

const MomentsTab = () => {
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.DashboardMoments),
  );
  const { orderBy, sorting, setSorting } = useSorting<MomentOrderBy>('-created');
  const [searchText, setSearchText] = useSearch('');

  const { loading, data } = useDashboardMomentsQuery({
    variables: {
      ...pagination,
      search: searchText,
      orderBy,
    },
  });

  // initial loading
  if (loading && !data?.moments?.nodes) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (data?.moments?.nodes) {
    const { nodes, totalCount, pageInfo } = data.moments;
    return (
      <MomentsTable
        momentsLoading={loading} // subsequent loading
        moments={nodes}
        totalCount={totalCount}
        pageInfo={pageInfo}
        dispatch={dispatch}
        sorting={sorting}
        setSorting={setSorting}
        onSearch={setSearchText}
      />
    );
  }

  return <div>ERROR HERE</div>;
};
export default MomentsTab;
