import { Tooltip as MaterialTooltip, TooltipProps, withStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { zIndex } from 'utils/styling';
import { Optional } from 'utils/types';

const StyledTooltip = withStyles(() => ({
  popper: {
    zIndex: zIndex.player + 31,
  },
  tooltip: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    padding: '10px 10px 0 10px',
    fontSize: 16,
    minWidth: 300,
  },
}))(MaterialTooltip);

const NoTransitionProps: TooltipProps['TransitionProps'] = {
  appear: false,
  enter: false,
  exit: false,
  timeout: 0,
};

const Image = styled.img`
  background-color: aliceblue;
  width: 200px;
  height: 112.5px;
`;

const Description = styled.p`
  padding: 10px 0;
  margin: 0;
`;

interface OwnProps {
  imageUrl?: Optional<string>;
  description: string;
  children: React.ReactElement;
}

const Tooltip = ({ imageUrl, description, children }: OwnProps) => {
  return (
    <StyledTooltip
      title={
        <>
          {imageUrl && <Image src={imageUrl} alt="tooltip" />}
          <Description>{description}</Description>
        </>
      }
      placement="top-start"
      TransitionProps={NoTransitionProps}
    >
      {children}
    </StyledTooltip>
  );
};

export default React.memo(Tooltip);
