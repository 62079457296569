import copyToClipboard from 'copy-to-clipboard';
import React from 'react';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';

import { CopyButton } from 'components/common/TextInput/TextInput.styles';
import * as Styled from './ApiKeyCell.styles';

interface ApiKeyCellProps {
  apiKey: string;
}

//TODO: refactor this and textInput (to common)
export const ApiKeyCell: React.VFC<ApiKeyCellProps> = ({ apiKey }) => {
  const [isCopyTooltipVisible, toggleTooltip] = React.useState(false);
  const handleCopy = React.useCallback(() => {
    toggleTooltip(true);
    copyToClipboard(apiKey);
    setTimeout(() => {
      toggleTooltip(false);
    }, 2000);
  }, [apiKey]);
  return (
    <Styled.Wrapper>
      <span>{apiKey}</span>
      <CopyButton type="button" onClick={handleCopy} tooltipVisible={isCopyTooltipVisible}>
        <Copy />
      </CopyButton>
    </Styled.Wrapper>
  );
};
