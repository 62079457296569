import { Box, IconButton } from '@material-ui/core';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Play } from 'assets/icons/overlays/play-circle.svg';
import { ReactComponent as Pause } from 'assets/icons/pause-circle.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { TextInput } from 'components/common';
import { TimestampBlock } from 'components/common/TimestampBlock';
import { source$, togglePlayback } from 'context/VideoMetadataStream';
import { timeRangeToHMSStrings } from 'utils/time';

import { CTAList } from '../CTA/CTAList';
import { CTATimestamp } from '../type';

import { PauseIcon } from '../VideoCTAPage.styles';

interface Props {
  item: CTATimestamp;
  disabled?: boolean;
  isCurrentPlayTimestampId?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
  onStartPlaying?: () => void;
}

export const TimestampItem: React.FC<Props> = ({
  item,
  disabled,
  isCurrentPlayTimestampId,
  onEdit,
  onRemove,
  onStartPlaying,
}) => {
  const [startTimestamp, endTimestamp] = timeRangeToHMSStrings(item.ranges);
  const isPlaying = source$.getValue().playing;

  return (
    <TimestampBlock title={item.name} disabled={disabled}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <p>{item.name}</p>
        <div>
          {isPlaying && isCurrentPlayTimestampId ? (
            <IconButton color="inherit" edge="start" onClick={togglePlayback}>
              <PauseIcon>
                <Pause />
              </PauseIcon>
            </IconButton>
          ) : (
            <IconButton color="inherit" edge="start" onClick={onStartPlaying}>
              <Play />
            </IconButton>
          )}
          <IconButton color="inherit" edge="start" onClick={onEdit} disabled={isPlaying}>
            <Edit />
          </IconButton>
          <IconButton color="inherit" edge="start" onClick={onRemove} disabled={isPlaying}>
            <Trash />
          </IconButton>
        </div>
      </Box>

      <Box display="flex" style={{ gap: '12px' }} mb="40px">
        <TextInput name="startTimestamp" value={startTimestamp} label="Start *" mb="0px" disabled />
        <TextInput name="endTimestamp" value={endTimestamp} label="End *" mb="0px" disabled />
      </Box>
      <CTAList ctas={item.ctas} />
    </TimestampBlock>
  );
};
