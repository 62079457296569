import { Box, Button } from '@material-ui/core';
import throttle from 'lodash.throttle';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { materialTheme } from 'components/App/materialTheme';
import { BackButton, Link, Typography } from 'components/common';
import { TextInput } from 'components/common';
import CustomColor from 'components/common/CustomColor';
import { GA4MeasurementIDInput } from 'components/common/GA4MeasurementIDInput';
import { GATrackingIDInput } from 'components/common/GATrackingIDInput';
import { useSearch } from 'components/common/Search/Search';
import { Columns } from 'components/common/Wrapper/Layout';
import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { Permission } from 'config/permissions';
import { paginationReducer } from 'context/pagination';
import { useCarouselMomentsQuery } from 'hooks/query/useCarouselMomentsQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { CarouselMomentI } from 'models';
import { URLS } from 'pages/urls';

import * as Styled from './EditCarousel.styled';
import { EditCarouselMoments } from './EditCarouselMoments';
import { EditCarouselPreview } from './EditCarouselPreview';
import { EditPageViewProps, FormData, SelectedMomentsList } from './types';

const FORM_ID = 'carousel-form';

export const EditPageView = ({
  pageTitle,
  carouselData,
  onDataSave,
  saveLoadingText,
  saveButtonText,
  momentsLoading,
}: EditPageViewProps) => {
  const [searchText, setSearchText] = useSearch('');
  const [selectedMoments, setSelectedMoments] = useState<SelectedMomentsList>({});
  const [customColor, setCustomColor] = useState(
    carouselData.style.mainColor || materialTheme.palette.primary.main,
  );
  const [isSelectedAllMoments, setIsSelectedAllMoments] = useState(false);
  const [allAvailableMoments, setAllAvailableMoments] = useState<CarouselMomentI[]>([]);
  const [momentsToPreview, setMomentsToPreview] = useState<CarouselMomentI[]>([]);
  const [initialMomentsToPreview, setInitialMomentsToPreview] = useState<CarouselMomentI[]>([]);
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.WidgetMoments),
  );

  const carouselForm = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: false,
  });
  const { register, handleSubmit, errors, watch } = carouselForm;

  const watchFields = watch();

  const prepareMomentsForPreview = () => {
    setMomentsToPreview(initialMomentsToPreview.filter((moment) => selectedMoments[moment.id]));
  };

  const { data } = useCarouselMomentsQuery({
    variables: {
      ...pagination,
      search: searchText,
      carouselId: carouselData.id,
    },
    onCompleted: (data) => {
      const loadedMoments = data.carouselMoments?.nodes?.reduce(
        (o, key) => ({ ...o, [key.id]: key.inCarousel }),
        {},
      );
      const allMoments = data.carouselMoments?.nodes;
      if (loadedMoments) {
        setSelectedMoments({
          ...loadedMoments,
          ...selectedMoments,
        });
      }

      if (allMoments) {
        const collectedMoments = [
          ...initialMomentsToPreview,
          ...allMoments.filter((moment) => {
            return !initialMomentsToPreview.find((initialMoment) => initialMoment.id === moment.id);
          }),
        ];

        setInitialMomentsToPreview(collectedMoments);
        setAllAvailableMoments(allMoments);
      }
    },
  });

  const onSubmit = (data: FormData) => {
    const moments: string[] = [];
    initialMomentsToPreview.forEach((moment) => {
      if (selectedMoments[moment.id]) moments.push(moment.id);
    });

    onDataSave({
      ...data,
      moments,
      mainColor: customColor,
    });
  };

  const onClickMoment = (momentId: string) => {
    setSelectedMoments({ ...selectedMoments, [momentId]: !selectedMoments[momentId] });
  };

  useEffect(() => {
    const moments = { ...selectedMoments };
    const momentsID = [];

    for (const prop in moments) {
      moments[prop] = isSelectedAllMoments;
      if (!selectedMoments[prop] || !isSelectedAllMoments) {
        momentsID.push(prop);
      }
    }

    setSelectedMoments({ ...moments });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedAllMoments]);

  useEffect(() => {
    prepareMomentsForPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMoments, initialMomentsToPreview]);

  const handleSelectAllMoments = () => {
    setIsSelectedAllMoments(!isSelectedAllMoments);
  };

  const setCustomColorThrottled = useMemo(() => throttle(setCustomColor, 500, { trailing: true }), [
    setCustomColor,
  ]);

  const { hasPermissions } = useUserPermissions();
  const canUserChangeCarousel = hasPermissions([Permission.ChangeCarousel]);

  return (
    <FormProvider {...carouselForm}>
      <ExtensibleSidebar
        sideContent={
          <Styled.CarouselForm id={FORM_ID} name={pageTitle} onSubmit={handleSubmit(onSubmit)}>
            <BackButton label="Back" link={URLS.widget.root} />
            <Box m="22px 0 32px 0">
              <Typography variant="h1" color="textPrimary">
                {pageTitle}
              </Typography>
            </Box>
            <TextInput
              label="Widget Title"
              name="title"
              placeholder="Type title"
              defaultValue={carouselData.title}
              ref={register()}
              error={!!errors.title}
              errorMessage={errors.title?.message}
              readOnly={!canUserChangeCarousel}
              maxLength={256}
            />
            <TextInput
              multiline
              label="Description"
              name="description"
              placeholder="Type description"
              defaultValue={carouselData.description}
              ref={register()}
              readOnly={!canUserChangeCarousel}
              maxLength={5000}
              rows={5}
            />
            <GATrackingIDInput
              name="googleAnalyticsTrackingId"
              defaultValue={carouselData.googleAnalyticsTrackingId}
              readOnly={!canUserChangeCarousel}
            />
            <GA4MeasurementIDInput
              name="googleAnalytics4MeasurementId"
              defaultValue={carouselData.googleAnalytics4MeasurementId}
              readOnly={!canUserChangeCarousel}
            />
            <CustomColor onColorChange={setCustomColorThrottled} selectedColor={customColor} />
          </Styled.CarouselForm>
        }
        mainContent={
          <>
            <Columns display="flex" alignItems="center" justifyContent="flex-end" pb="20px">
              {saveLoadingText && <Typography variant="h6">{saveLoadingText}</Typography>}
              <Button component={Link} to={URLS.widget.root} variant="outlined" color="secondary">
                Cancel
              </Button>
              {canUserChangeCarousel && (
                <Button
                  variant="contained"
                  disabled={!!saveLoadingText}
                  color="primary"
                  form={FORM_ID}
                  type="submit"
                >
                  {saveButtonText}
                </Button>
              )}
            </Columns>
            <EditCarouselPreview
              configurations={watchFields}
              moments={momentsToPreview}
              selectedColor={customColor}
            />
            {data?.carouselMoments?.nodes ? (
              <EditCarouselMoments
                momentsLoading={momentsLoading}
                moments={allAvailableMoments}
                setInitialMomentsToPreview={setInitialMomentsToPreview}
                setAllAvailableMoments={setAllAvailableMoments}
                canSelectMoments={canUserChangeCarousel}
                handleClickMoment={onClickMoment}
                selectedMoments={selectedMoments}
                setCheckAllMoments={handleSelectAllMoments}
                checkAllMoments={isSelectedAllMoments}
                onSearch={setSearchText}
                searchText={searchText}
                dispatch={dispatch}
                pageInfo={data.carouselMoments.pageInfo}
                totalCount={data.carouselMoments.totalCount}
              />
            ) : null}
          </>
        }
      />
    </FormProvider>
  );
};
