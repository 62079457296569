import { OutlinedInput as MaterialOutlinedInput } from '@material-ui/core';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

interface OutlinedInputProps {
  readOnly?: boolean;
  multiline?: boolean;
}

export const OutlinedInput = styled(MaterialOutlinedInput)<OutlinedInputProps>`
  background-color: ${({ readOnly }) => readOnly && materialTheme.palette.grey[100]};
  min-height: 52px;

  .MuiOutlinedInput-input {
    color: ${({ readOnly }) => readOnly && materialTheme.palette.grey[500]};
    padding: ${({ multiline }) => (multiline ? '0px' : '17.5px 20px')};
  }
`;

interface CopyButtonProps {
  tooltipVisible: boolean;
}

export const CopyButton = styled.button<CopyButtonProps>`
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 2px 4px 0 0;
  outline: none;
  position: relative;

  ::after {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    content: 'Copied!';
    display: ${({ tooltipVisible }) => (tooltipVisible ? 'block' : 'none')};
    padding: 8px;
    position: absolute;
    right: -5px;
    top: 0;
    transform: translateY(-150%);
    transition: opacity 0.2s ease-in;
  }
`;

export const CharsCounter = styled.div`
  color: #999999;
  font-size: 12px;
  margin-top: 8px;
  position: absolute;
  right: 0;
`;

export const ErrorMessage = styled.div`
  color: #f44336;
  font-size: 12px;
  left: 0;
  margin-top: 8px;
  position: absolute;
`;
