import { Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { pushMessage } from 'context/globalStream';
import { useUpdateOrganization } from 'hooks/mutation/useUpdateOrganization';
import { useSafeState } from 'hooks/useSafeState';
import { isNotEmptyString, validate } from 'utils/validation';

import * as Styled from './ChangeNameModal.styles';

interface FormData {
  organization: string;
}

interface Props {
  organizationId: string;
  organizationName?: string;
  onClose: () => void;
  isOpen: boolean;
}

export const ChangeNameModal: React.VFC<Props> = ({
  organizationId,
  organizationName,
  onClose,
  isOpen,
}) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const [frozenName, setFrozenName] = React.useState(organizationName);

  const [updateOrganization, , [errorMessage], resetErrorMessage] = useUpdateOrganization();

  const { register, handleSubmit, errors, formState } = useForm<FormData>({
    mode: 'onChange',
  });

  // Read the `formState` before render to subscribe to the form state proxy
  const { isDirty, isValid } = formState;

  const onSubmit = async ({ organization }: FormData) => {
    setIsLoading(true);
    setFrozenName(organizationName);

    const { data } = await updateOrganization({
      variables: {
        id: organizationId,
        organization: {
          name: organization,
        },
      },
    });

    if (data?.updateOrganization?.organization?.name === organization) {
      pushMessage({ message: 'Name has been successfully changed.' });
      onClose();
    } else {
      setIsLoading(false);
    }
  };

  const handleExited = () => {
    resetErrorMessage();
    setIsLoading(false);
  };

  const displayedName = isLoading ? frozenName : organizationName;

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpen}
      heading={`Change organization name`}
      width={600}
    >
      <form name="change-org-name" onSubmit={handleSubmit(onSubmit)}>
        <Stack display="flex" alignItems="center" flexDirection="column" mt="50px" rowGap={50}>
          {errorMessage ? (
            <Styled.ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
          ) : null}
          <Stack textAlign="left" width="400px">
            <TextInput
              name="current-organization"
              label="Current Organization Name"
              value={displayedName}
              disabled
              mb="0"
            />
            <TextInput
              name="organization"
              label="New Organization Name"
              placeholder="Write your new organization name here"
              ref={register({
                validate: validate(isNotEmptyString, 'Organization name cannot be empty'),
              })}
              error={!!errors.organization}
              errorMessage={errors.organization?.message}
              mb="0"
            />
          </Stack>
          <Columns display="flex" flexDirection="row" justifyContent="center">
            <Button variant="outlined" onClick={onClose} color="secondary" type="button">
              Cancel
            </Button>
            <LoadingButton
              disabled={!isValid || !isDirty}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </LoadingButton>
          </Columns>
        </Stack>
      </form>
    </Dialog>
  );
};
