import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

import { CONDENSED_FONT, TEXT_FONT } from 'utils/styling';

import { fontDeclaration } from './fonts';

export const customPalette = {
  seeenRed: '#f00223',
  purple: '#4e19ca',
  lightBlue: '#00b3d3',
  magenta: '#cc00cc',
  darkBlue: '#2466a8',
  yellow: '#eed91c',
  lightGrey: '#e7e7f0',
  darkGrey: '#32353e',
};

const palette = createPalette({
  primary: {
    main: '#CE0000',
    light: '#FFDEE2',
  },
  secondary: {
    main: '#0f131d',
    light: '#E6E6E6',
  },
  text: {
    primary: '#0F131D',
    secondary: '#898A91',
  },
  background: {
    paper: '#fff',
    default: '#f6f7fa',
  },
  error: {
    main: '#F00223',
  },
  warning: {
    main: '#fd8e2e',
  },
  info: {
    main: '#3b7dff',
  },
  success: {
    main: '#04b462',
  },
  grey: {
    100: '#fafafa',
    200: '#e6e6e6',
    500: '#777886',
    600: '#595959',
    700: '#333',
    800: '#20242c',
    900: '#0F131D',
    901: '#1a1a1a',
  },
});

export const materialTheme = createMuiTheme({
  palette,
  typography: {
    fontFamily: TEXT_FONT,
    h1: {
      fontSize: '28px',
      fontWeight: 700,
      fontFamily: CONDENSED_FONT,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontDeclaration,
      },
    },
    MuiTableCell: {
      root: {
        padding: '8px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '13px',
        backgroundColor: '#252525',
      },
      arrow: {
        color: '#252525',
      },
    },
    MuiTypography: {
      h1: {
        lineHeight: 'normal',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
        width: '100%',

        '&$disabled': {
          color: 'rgba(137, 138, 145, 0.70)',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        zIndex: 1,
      },
      positionStart: {
        marginRight: 0,
      },
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px',

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: customPalette.darkGrey,
        },

        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey[200],
        },
      },
      input: {
        padding: '17.5px 20px',
        height: '100%',
      },
      multiline: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
      notchedOutline: {
        borderColor: palette.grey[200],
      },
      adornedEnd: {
        paddingRight: '12px',
      },
      adornedStart: {
        paddingLeft: '12px',
      },
    },
    MuiSelect: {
      outlined: {
        paddingLeft: '20px',
        paddingRight: '40px',
      },
      iconOutlined: {
        right: '10px',
      },
      selectMenu: {
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: 52,
        paddingTop: 0,
        paddingBottom: 0,
      },
      select: {
        '&:focus': {
          borderRadius: 'inherit',
          backgroundColor: customPalette.lightGrey,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '10px',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: 1.5,
        textTransform: 'none',
        padding: '13px 24px',
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        borderColor: palette.grey[200],
        padding: '13px 24px',
      },
      outlinedSecondary: {
        backgroundColor: palette.background.paper,
        borderColor: customPalette.lightGrey,
      },
      text: {
        fontSize: '14px',
        minWidth: 0,
      },
      startIcon: {
        marginRight: '10px',
        marginLeft: '-5px',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: CONDENSED_FONT,
      },
    },
    MuiPopover: {
      root: {
        // @ts-ignore
        zIndex: '1500 !important',
        borderRadius: 8,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        paddingBottom: '8px',
        paddingTop: '8px',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: customPalette.lightGrey,

          '&:hover': {
            backgroundColor: palette.background.default,
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '8px 14px !important',
        minHeight: '52px',
      },
    },
  },
});
