import { useApolloClient } from '@apollo/react-hooks';
import { FetchResult } from 'apollo-link';
import React from 'react';
import { Except } from 'type-fest';

import { Dialog } from 'components/common/Dialog';
import {
  cancelScheduledVideoPublish,
  cancelScheduledVideoPublishResponseData,
  unpublishVideo,
  UnpublishVideoResponseData,
} from 'hooks/mutation';
import { useSafeState } from 'hooks/useSafeState';
import { VideoStatus } from 'models';

import { UnpublishVideoConfirm } from './UnpublishVideoConfirm';
import {
  parseUnpublishingResults,
  prepareVideoForUnpublish,
  UnpublishVideoPick,
} from './UnpublishVideoModal.utils';
import { VideoUnpublished } from './VideoUnpublished';

import * as Styled from './PublishVideo.styles';

interface DialogProps extends Except<React.ComponentPropsWithoutRef<typeof Dialog>, 'children'> {
  video: UnpublishVideoPick;
}

export const UnpublishVideoModal: React.FC<DialogProps> = ({ video, ...modalProps }) => {
  return (
    <Dialog {...modalProps} width={600}>
      <Styled.PageWrapper isInModal>
        <UnpublishVideoBody video={video} onClose={modalProps.onClose} />
      </Styled.PageWrapper>
    </Dialog>
  );
};

interface BodyProps {
  video: UnpublishVideoPick;
  onClose: () => void;
}

const UnpublishVideoBody: React.FC<BodyProps> = ({ video, onClose }) => {
  const client = useApolloClient();
  const [unpublishStatus, setUnpublishStatus] = useSafeState({
    isDone: false,
    isSuccess: false,
    isYoutubeScheduled: false,
  });
  const [isSubmitting, setIsSubmitting] = useSafeState(false);

  const { targetTypes, hasYouTubePublication } = prepareVideoForUnpublish(video);

  const handleUnpublishResponse = (
    result: FetchResult<UnpublishVideoResponseData | cancelScheduledVideoPublishResponseData>,
    isYoutubeSelected: boolean,
  ) => {
    const { isAllSuccess } = parseUnpublishingResults([result]);
    setUnpublishStatus({
      isDone: true,
      isSuccess: isAllSuccess,
      isYoutubeScheduled: isYoutubeSelected,
    });
  };

  const handleSubmit = async (isYoutubeSelected: boolean) => {
    setIsSubmitting(true);
    let result:
      | FetchResult<UnpublishVideoResponseData | cancelScheduledVideoPublishResponseData>
      | undefined;

    try {
      if (video.status === VideoStatus.Scheduled) {
        result = await cancelScheduledVideoPublish(client, targetTypes, { videoId: video.id });
      } else {
        result = await unpublishVideo(client, targetTypes, { videoId: video.id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }

    if (result) {
      handleUnpublishResponse(result, isYoutubeSelected);
    }
  };

  return (
    <>
      {!unpublishStatus.isDone ? (
        <UnpublishVideoConfirm
          video={video}
          canUnpublishFromYoutube={hasYouTubePublication}
          onConfirm={handleSubmit}
          onClose={onClose}
          isUnpublishing={isSubmitting}
        />
      ) : (
        <VideoUnpublished
          isSuccess={unpublishStatus.isSuccess}
          isYoutubeScheduled={unpublishStatus.isYoutubeScheduled}
          onClose={onClose}
        />
      )}
    </>
  );
};
