import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Embed } from 'assets/icons/embed.svg';
import { EndCardType } from 'pages/EndCard/types';
import { URLS } from 'pages/urls';

export const EndCardLinks = [
  {
    label: 'Visual Builder',
    icon: <Edit />,
    href: URLS.endCard.new,
  },
  {
    label: 'Custom code',
    icon: <Embed />,
    href: URLS.endCard.newCustomCode,
  },
];

export const EndCardTypes = {
  [EndCardType.GUI_BUILDER]: 'Visual Builder',
  [EndCardType.CUSTOM_JS]: 'Custom code',
};
