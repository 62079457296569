import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Share } from 'assets/icons/share.svg';

interface Props {
  onShareClick: () => void;
  edge?: 'start' | 'end';
}

export const ShareAction: React.FC<Props> = ({ onShareClick, edge }) => {
  return (
    <Tooltip title="Share" arrow placement="top">
      <IconButton color="inherit" edge={edge} onClick={onShareClick}>
        <Share />
      </IconButton>
    </Tooltip>
  );
};
