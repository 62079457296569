import { Box, Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Typography } from 'components/common';
import { EditedVideoPrompt } from 'components/common/EditedVideoPrompt';
import { SpinnerBox } from 'components/common/Spinner';
import { Columns } from 'components/common/Wrapper/Layout';
import { TimelineEditor } from 'components/TimelineEditor';
import BasePlayer from 'components/VideoPlayer/BasePlayer';
import { VideoEditorCard } from 'components/VideoPlayer/VideoPlayerElements';
import { MIN_TIMESTAMP_LENGTH } from 'config/constants';
import { Permission } from 'config/permissions';
import { QUERY_VIDEO_STATUS } from 'context/queries';
import { seekToMs } from 'context/VideoMetadataStream';
import { useUpdateMoment } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { Range, VideoMomentNode, VideoStatus } from 'models';
import { PublishModal } from 'pages/PublishVideo';
import { generateVideoPublishLink } from 'pages/urls';
import { pushHistoryUrl } from 'utils/navigation';
import { canVideoBePublished } from 'utils/videos';

import { useMomentsEditorContext } from './context';
import { SingleMomentEditor } from './SingleMomentEditor';

interface OwnProps {
  videoId: string;
  moments?: VideoMomentNode[];
  videoUrl: string;
}

interface MomentEditorLocationProps {
  momentId?: string;
}

export const MomentsEditor = ({ videoId, moments, videoUrl }: OwnProps) => {
  const {
    isSelectingMoment,
    selectedMomentId,
    setSelectedMomentId,
    videoStatus,
    handleSetChangesSaved,
    changesSaved,
  } = useMomentsEditorContext();

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [isScrolledToEditor, setIsScrolledToEditor] = useState(false);
  const singleMomentEditorRef = useRef(null);
  const { search } = useLocation<MomentEditorLocationProps>();
  const momentsSearchParams = new URLSearchParams(search);
  const momentIdFromQuery = momentsSearchParams.get('momentId');

  useEffect(() => {
    if (momentIdFromQuery && !isScrolledToEditor) {
      setSelectedMomentId(momentIdFromQuery);
    }
  }, [isScrolledToEditor, setSelectedMomentId, momentIdFromQuery]);

  useEffect(() => {
    if (singleMomentEditorRef?.current && !isScrolledToEditor && momentIdFromQuery) {
      // @ts-ignore
      singleMomentEditorRef.current.scrollIntoView();
      setIsScrolledToEditor(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleMomentEditorRef.current]);

  const momentsRanges = moments?.map((moment) => ({
    id: moment.id,
    startTimestamp: moment.timeRange[0],
    endTimestamp: moment.timeRange[1],
  }));

  const { hasPermissions } = useUserPermissions();
  const canUserChangeMoment = hasPermissions([Permission.ChangeMoment]);
  const canUserPublishVideo = hasPermissions([Permission.PublishVideo]);
  const publishUrl = generateVideoPublishLink(videoId);
  const isPublishButtonDisabled =
    isSelectingMoment || (videoStatus && !canVideoBePublished(videoStatus));

  const [updateMoment] = useUpdateMoment({
    refetchQueries: [{ query: QUERY_VIDEO_STATUS, variables: { id: videoId } }],
    onCompleted: () => {
      if (!changesSaved) handleSetChangesSaved(true);
    },
  });

  const selectedMomentData = selectedMomentId
    ? moments?.find((moment) => moment.id === selectedMomentId)
    : null;

  const handleRangeChange = (range: Range, skipSave?: boolean) => {
    if (!skipSave) {
      return updateMoment({
        variables: {
          id: range.id,
          moment: {
            startTimestamp: range.startTimestamp,
            endTimestamp: range.endTimestamp,
          },
        },
      });
    }
  };

  const match = useRouteMatch();

  return (
    <>
      <EditedVideoPrompt status={changesSaved ? VideoStatus.Edited : undefined} />
      <Columns display="flex" alignItems="center" justifyContent="space-between" pb="20px">
        <Typography variant="h6">{changesSaved ? 'Changes are saved' : ''}</Typography>
        {canUserPublishVideo ? (
          <Button
            href={publishUrl}
            onClick={(event) => {
              event.preventDefault();
              setShowPublishModal(true);
              pushHistoryUrl(publishUrl);
            }}
            variant="contained"
            disabled={isPublishButtonDisabled}
            color="primary"
          >
            {changesSaved && videoStatus === VideoStatus.Edited ? 'Republish' : 'Publish'}
          </Button>
        ) : null}
      </Columns>
      <VideoEditorCard>
        <BasePlayer url={videoUrl} />
        {moments && momentsRanges ? (
          <TimelineEditor
            seekTo={seekToMs}
            ranges={momentsRanges}
            minRangeLength={MIN_TIMESTAMP_LENGTH}
            isDisabled={!canUserChangeMoment}
            moments={moments}
            updateRange={handleRangeChange}
          />
        ) : null}
        {isSelectingMoment ? (
          <Box bgcolor="#fff">
            <SpinnerBox />
          </Box>
        ) : selectedMomentData ? (
          <div ref={singleMomentEditorRef}>
            <Box p="25px 20px" bgcolor="#fff">
              <SingleMomentEditor moment={selectedMomentData} videoId={videoId} />
            </Box>
          </div>
        ) : null}
      </VideoEditorCard>
      {canUserPublishVideo && (
        <PublishModal
          open={showPublishModal}
          handlePublished={() => handleSetChangesSaved(false)}
          onClose={() => {
            setShowPublishModal(false);
            pushHistoryUrl(match.url);
          }}
        />
      )}
    </>
  );
};
