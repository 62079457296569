import React from 'react';

import { VideoDetailsNode } from 'models';
import { isVideoInProcessingState } from 'utils/videos';

import ProcessingInfo from '../../ProcessingInfo';
import ObjectsTable from './ObjectsTable';

interface OwnProps {
  video: VideoDetailsNode;
}

const ObjectsTab = ({ video }: OwnProps) => {
  if (isVideoInProcessingState(video.status)) {
    return <ProcessingInfo type="objects" />;
  }

  return <ObjectsTable video={video} />;
};

export default ObjectsTab;
