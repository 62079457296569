import { Button } from '@material-ui/core';
import { innerJoin } from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { LoadingButton, Spinner } from 'components/common';
import { CheckboxEntry } from 'components/common/CheckboxEntry';
import { Dialog } from 'components/common/Dialog';
import Search, { useSearch } from 'components/common/Search/Search';
import { Stack } from 'components/common/Wrapper/Layout';
import { useSafeState } from 'hooks/useSafeState';
import { PlaylistContentType } from 'models/Playlist';
import { TitleCell } from 'pages/Dashboard/Tables/TitleCell';
import { MaybePromise } from 'utils/types';

import { Item } from './types';
import { usePlaylistItemsToAdd } from './usePlaylistItemsToAdd';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  type: PlaylistContentType;
  handleAddItemsToPlaylist: (itemIds: Item[]) => MaybePromise;
}

export function AddItemsToPlaylistModal({
  onClose,
  isOpen,
  type,
  handleAddItemsToPlaylist,
}: Props) {
  const [isLoaded, setIsLoaded] = useSafeState(false);

  const [searchText, setSearchText] = useSearch();

  const { items, error, refetch } = usePlaylistItemsToAdd({
    type,
    variables: {
      orderBy: '-created',
      search: searchText,
      page: 1,
      pageSize: 10,
      isPublished: true,
    },
    skip: !isOpen,
    onCompleted() {
      if (isOpen && !isLoaded) {
        setIsLoaded(true);
      }
    },
    onError() {
      if (isOpen && !isLoaded) {
        setIsLoaded(true);
      }
    },
  });

  const [selectedItemIds, setSelectedItemIds] = useState<Record<string, boolean>>({});
  const onlySelectedIds = Object.keys(selectedItemIds).filter((key) => selectedItemIds[key]);

  const [isAdding, setIsAdding] = useSafeState(false);

  const handleAdd = async () => {
    const newItems = innerJoin((item, id) => item.id === id, items, onlySelectedIds);

    setIsAdding(true);
    await handleAddItemsToPlaylist(newItems);
    onClose();
  };

  const handleExited = () => {
    setSearchText('');
    setSelectedItemIds({});
    setIsAdding(false);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpen}
      heading={
        type === PlaylistContentType.Moments ? 'Add Moments to Playlist' : 'Add Videos to Playlist'
      }
      width={600}
    >
      <Stack>
        {!isLoaded ? (
          <Spinner />
        ) : error ? (
          <div>
            Error occurred.{' '}
            <Button onClick={() => refetch()} variant="text" color="primary">
              Try again
            </Button>
          </div>
        ) : (
          <Stack>
            <Stack px="50px" mx="auto" rowGap="10px" maxHeight="300px" overflow="auto">
              <SearchWrapper>
                <Search
                  placeholder="Search"
                  onSearch={setSearchText}
                  iconPlacement="left"
                  searchOnInput
                  debounceSearch
                  fullWidth
                />
              </SearchWrapper>
              {items.map((item) => {
                const checked = selectedItemIds[item.id] === true;
                return (
                  <CheckboxEntry
                    key={item.id}
                    checked={checked}
                    onChange={(checked) => {
                      setSelectedItemIds((ids) => {
                        return { ...ids, [item.id]: checked };
                      });
                    }}
                  >
                    <TitleCell
                      title={item.title}
                      description={item.description}
                      thumbnailUrl={item.thumbnailUrl}
                    />
                  </CheckboxEntry>
                );
              })}
            </Stack>

            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleAdd}
              isLoading={isAdding}
              disabled={onlySelectedIds.length === 0}
            >
              Add
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
}

const SearchWrapper = styled.div`
  border: 1px solid ${materialTheme.palette.grey[200]};
  border-radius: 10px;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;
