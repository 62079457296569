import { Button } from '@material-ui/core';
import React from 'react';

import { Dialog } from 'components/common/Dialog';
import { Stack } from 'components/common/Wrapper/Layout';

import * as Styled from './PublishVideo.styles';

interface Props {
  isSuccess: boolean;
  isYoutubeScheduled: boolean;
  onClose: () => void;
}

export const VideoUnpublished: React.FC<Props> = ({ isSuccess, isYoutubeScheduled, onClose }) => {
  return (
    <Stack display="flex" flexDirection="column" alignItems="center">
      <Styled.Center>
        <Stack rowGap={30}>
          <Styled.Title>{isSuccess ? 'Video Unpublished!' : 'Unpublishing Failed'}</Styled.Title>
          {isYoutubeScheduled ? (
            <Dialog.Text>
              Unpublishing from YouTube has been scheduled, it make take a while before changes are
              visible.
            </Dialog.Text>
          ) : null}
        </Stack>
      </Styled.Center>
      <Button onClick={onClose} variant="outlined" color="secondary">
        Close
      </Button>
    </Stack>
  );
};
