import React from 'react';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useCarouselQuery } from 'hooks/query';

import { EditCarouselController } from './EditCarouselController';
import { RouteParams } from './types';

function EditCarousel() {
  const { carouselId } = useParams<RouteParams>();
  const { loading, data } = useCarouselQuery(carouselId);
  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (data?.carousels?.nodes && data.carousels.nodes[0]) {
    return (
      <MainLayout>
        <EditCarouselController carousel={data.carousels.nodes[0]} />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
}

export default EditCarousel;
