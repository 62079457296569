import { Box, TooltipProps } from '@material-ui/core';
import React, { useState } from 'react';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { FormLabel } from 'components/common/FormLabel';
import { InfoTooltip, Placement } from 'components/common/InfoTooltip';

import { UploadDropBox } from '../UploadDropBox';

import * as Styled from './UploadInput.styles';

export interface UploadInputProps {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: string;
  onChange(value: string): void;
  onFileValidation?(value: File): Promise<boolean>;
  placeholder?: string;
  validationMessage?: string;
  errorMessage?: any;
  error?: boolean;
  mb?: string;
  info?: string | React.ReactNode;
  infoPlacement?: Placement;
  infoOptions?: TooltipProps;
}

export const UploadInput = React.forwardRef<HTMLInputElement, UploadInputProps>(function (
  {
    label,
    name,
    value,
    validationMessage,
    errorMessage,
    placeholder,
    disabled,
    onChange,
    onFileValidation,
    error,
    mb = '32px',
    info,
    infoPlacement,
    infoOptions,
  },
  ref,
) {
  const [previewBg, setPreviewBg] = useState(value);

  const handleFileProcessed = (base64: string) => {
    onChange(base64);
    setPreviewBg(base64);
  };

  const handleRemoveClick = () => {
    onChange('');
    setPreviewBg('');
  };

  return (
    <Box mb={mb} position="relative" width="100%">
      {label && (
        <FormLabel htmlFor={name}>
          {label}
          {info ? (
            <InfoTooltip content={info} placement={infoPlacement} options={infoOptions} />
          ) : null}
        </FormLabel>
      )}
      <Box display="flex">
        <Styled.ImageWrapper>
          {!!previewBg && (
            <Styled.CloseButton type="button" onClick={handleRemoveClick}>
              <Close />
            </Styled.CloseButton>
          )}
          <Styled.Image url={previewBg} />
        </Styled.ImageWrapper>
        <Styled.UploadWrapper>
          <UploadDropBox
            inline
            id="cta-image-upload"
            disabled={disabled}
            uploadLabel={placeholder}
            onFileProcessed={handleFileProcessed}
            validateFile={onFileValidation}
          />
        </Styled.UploadWrapper>
      </Box>

      {(errorMessage || validationMessage) && (
        <Styled.WarningMessage error={error}>
          {errorMessage || validationMessage}
        </Styled.WarningMessage>
      )}
    </Box>
  );
});
