import styled from 'styled-components/macro';

export const CopyPasteTagsIcon = styled.span`
  background-color: #0261f0;
  padding: 6px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: background 0.25s ease-out;

  .copy-tag-icon {
    color: #fff;
    transition: color 0.25s ease-out;
  }
`;

export const CopyPasteTags = styled.button`
  padding: 4px 6px 4px 16px;
  border: 1px solid #0261f0;
  color: #000;
  border-radius: 17px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  font-size: 12px;
  outline: none;
  transition: 0.25s ease-out;

  &:disabled,
  &:hover {
    background-color: #0261f0;
    color: #fff;

    ${CopyPasteTagsIcon} {
      background-color: #fff;
    }

    .copy-tag-icon {
      color: #0261f0;
    }
  }
`;
