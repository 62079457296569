import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';

import { AddButton } from 'components/common/AddButton';
import { reorderArray } from 'utils/common';

import { EMPTY_CTA_FIELD, EMPTY_CTA_FIELD_ID } from '../constants';
import { CTAItemProps, CTATimestampCtas } from '../type';
import { VideoCTAPageContext } from '../useTimestampContext';
import { CTAForm } from './CTAForm';
import { CTAItem } from './CTAItem';

interface Props {
  isTimestampEdit?: boolean;
  ctas: CTATimestampCtas[];
}

const SortableContainerBox = SortableContainer(Box);

const SortableItem = SortableElement(
  ({ item, onEditCTA, onRemoveCTA, disabledItem }: CTAItemProps) => (
    <CTAItem
      item={item}
      onEditCTA={onEditCTA}
      onRemoveCTA={onRemoveCTA}
      disabledItem={disabledItem}
    />
  ),
);

export const CTAList: React.FC<Props> = ({ isTimestampEdit = false, ctas }) => {
  const {
    currentCTA,
    updateCurrentCTAs,
    hideCurrentCTAForm,
    createCurrentCTA,
    updateCurrentCTA,
    removeCurrentCTA,
  } = useContext(VideoCTAPageContext);

  const openCTAForm = () => {
    updateCurrentCTA(EMPTY_CTA_FIELD);
  };

  const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newItems = reorderArray(ctas, oldIndex, newIndex);
    updateCurrentCTAs(newItems);
  };

  const onSubmit = (cta: CTATimestampCtas) => {
    updateCurrentCTAs(ctas.map((item) => (item.id === cta.id ? cta : item)));
    hideCurrentCTAForm();
  };

  return (
    <Box id="ctas-list">
      {isTimestampEdit ? (
        <Box mb="36px">
          <AddButton label="Add CTA" onClick={openCTAForm} disabled={!!currentCTA} />
        </Box>
      ) : null}
      {isTimestampEdit && currentCTA?.id === EMPTY_CTA_FIELD_ID ? (
        <CTAForm
          defaultValues={currentCTA}
          onSubmit={createCurrentCTA}
          onCancel={hideCurrentCTAForm}
          onUpdate={updateCurrentCTA}
        />
      ) : null}
      <SortableContainerBox onSortEnd={handleSortEnd} useDragHandle>
        {ctas.map((item, index) => {
          return item.id === currentCTA?.id ? (
            <CTAForm
              key={item.id}
              defaultValues={currentCTA}
              onSubmit={onSubmit}
              onCancel={hideCurrentCTAForm}
              onUpdate={updateCurrentCTA}
            />
          ) : (
            <SortableItem
              key={item.id}
              index={index}
              item={item}
              disabledItem={!isTimestampEdit}
              onEditCTA={updateCurrentCTA}
              onRemoveCTA={removeCurrentCTA}
            />
          );
        })}
      </SortableContainerBox>
    </Box>
  );
};
