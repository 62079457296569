import { Box } from '@material-ui/core';
import React from 'react';

import { FormLabel, InfoTooltip } from 'components/common';
import { LimitsUsage, SubscriptionLimitPeriod } from 'models';

import { formatCurrency } from './formatCurrency';
import { formatDuration } from './formatDuration';

interface Props {
  limitsUsage: LimitsUsage;
}

export function UsageLimits({ limitsUsage }: Props) {
  const isPerMonth = limitsUsage.limit.period === SubscriptionLimitPeriod.Month;
  const usagePercentage = limitsUsage.usage
    ? `${Number((limitsUsage.usage * 100).toFixed(2))}%`
    : '0%';

  const periodLimit = limitsUsage.limit.value ?? 0;
  const isMeteredBillingEnabled = limitsUsage.limit.meteredLimit !== null;
  const meteredLimit = limitsUsage.limit.meteredLimit?.unitsCap ?? 0;
  const totalLimit = periodLimit + meteredLimit;

  const periodUsage = Math.min(limitsUsage?.value ?? 0, periodLimit);

  return (
    <Box p="20px 30px 30px" display="grid" gridTemplateColumns="1fr 1fr" gridGap="20px">
      <Box>
        <FormLabel>
          {isPerMonth ? 'Current Period' : 'Total'} Usage - {usagePercentage}
        </FormLabel>
        <Box>
          Used <strong>{formatDuration(periodUsage)}</strong> out of{' '}
          <strong>{formatDuration(periodLimit)}</strong> available{' '}
          {isPerMonth ? 'per month' : 'in total'}
        </Box>
      </Box>
      {isMeteredBillingEnabled ? (
        <Box>
          <FormLabel>
            Metered Usage
            <InfoTooltip
              content={
                <div>
                  When you reach the limit, you will be charged an additional{' '}
                  <strong>
                    {formatCurrency(
                      limitsUsage.limit.meteredLimit?.unitCurrency,
                      limitsUsage.limit.meteredLimit?.unitCost,
                    )}
                  </strong>{' '}
                  per each second of uploaded file duration, with the{' '}
                  {isPerMonth ? 'monthly' : 'total'} limit of{' '}
                  <strong>{formatDuration(totalLimit)}</strong> ({formatDuration(periodLimit)} of
                  base limit + {formatDuration(meteredLimit)} of metered usage).
                </div>
              }
            />
          </FormLabel>
          <Box>
            Used <strong>{formatDuration(limitsUsage.meteredValue)}</strong>{' '}
            {isPerMonth ? 'this month' : 'in total'}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
