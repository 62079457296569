import gql from 'graphql-tag';

import { PAGINATION_PART } from './_pagination';

export const D2C_API_KEY_FRAGMENT = gql`
  fragment D2CApiKeys on ApiKeyType {
    id
    name
    key
    revoked
    created
  }
`;

export const D2C_API_KEYS_LIST = gql`
  ${D2C_API_KEY_FRAGMENT}

  query GetApiKeys(
    $pageSize: Int!,
    $page: Int!,
    $orderBy: String = "",
    $revoked: Boolean,
  ) {
    d2cApiKeys(
      pageSize: $pageSize,
      page: $page,
      orderBy: $orderBy,
      revoked: $revoked,
    ) {
      nodes {
        ...D2CApiKeys
      }
      ${PAGINATION_PART}
    }
  }
`;
