import { Box, Button } from '@material-ui/core';
import React from 'react';

import { Dialog } from 'components/common/Dialog';
import { Columns } from 'components/common/Wrapper/Layout';

interface Props {
  open: boolean;
  onClose: () => void;
  onPublishClick: () => void;
  videoTitle?: string;
}

export const PublicationRequiredModal: React.FC<Props> = ({
  open,
  onClose,
  videoTitle,
  onPublishClick,
}) => (
  <Dialog
    onClose={onClose}
    open={open}
    heading={`Share ${videoTitle}`}
    width={600}
    wrapHeading={true}
  >
    <Box>
      In order to share the video, it needs to be published. <br />
      You can publish the video using the button below.
    </Box>
    <Columns display="flex" flexDirection="row" justifyContent="center" marginTop="30px">
      <Button variant="outlined" onClick={onClose} color="secondary" type="button">
        Cancel
      </Button>
      <Button variant="contained" color="primary" type="button" onClick={onPublishClick}>
        Publish Video
      </Button>
    </Columns>
  </Dialog>
);
