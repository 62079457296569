import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, OrganizationI } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'ORGANIZATION_NAME_TAKEN';

const errorMap: Record<Errors, string> = {
  ORGANIZATION_NAME_TAKEN: 'Organization name is already taken.',
};

const QUERY = gql`
  mutation UpdateOrganization($id: ID!, $organization: UpdateOrganizationInput!) {
    updateOrganization(id: $id, organization: $organization) {
      organization {
        id
        name
        isMonetizationEnabled
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  updateOrganization: Nullable<{
    organization: Nullable<OrganizationI>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  organization: {
    name?: string;
    isMonetizationEnabled?: boolean;
  };
}

interface UseCreateOrganizationQueryOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateOrganization = (options?: UseCreateOrganizationQueryOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'updateOrganization.errors',
    skipGlobalErrorHandling: true,
    errorMap,
    ...options,
  });
};
