import React, { useCallback, useContext, useState } from 'react';

import { usePlayerSizeObservable } from 'hooks/usePlayerSize';

import { Icons } from '../Alignment/Icons';
import { Inputs } from '../Alignment/Inputs';
import { CTATimestampCtasPosition } from '../type';
import { VideoCTAPageContext } from '../useTimestampContext';
import { HiddenElements } from './HiddenElements';

interface Props {
  updateCtaPosition: (position: Partial<CTATimestampCtasPosition>) => void;
}

export const CTAAlignment: React.FC<Props> = ({ updateCtaPosition }) => {
  const videoSize = usePlayerSizeObservable();
  const { currentCTA } = useContext(VideoCTAPageContext);

  const [size, setSize] = useState({ width: 0, height: 0 });

  const getCTASize = useCallback((ctaSize) => {
    setSize(ctaSize);
  }, []);

  if (!currentCTA) return null;

  return (
    <>
      {currentCTA.template ? (
        <HiddenElements
          getCTASize={getCTASize}
          config={currentCTA.styles}
          type={currentCTA.type}
          videoWidth={videoSize.width}
          width={currentCTA.width}
        />
      ) : null}

      {size.height && size.width && videoSize.height && videoSize.width ? (
        <>
          <Icons updateCtaPosition={updateCtaPosition} />
          <Inputs
            position={currentCTA.config.position}
            ctaSize={size}
            videoSize={videoSize}
            type={currentCTA.type}
            updateCtaPosition={updateCtaPosition}
          />
        </>
      ) : null}
    </>
  );
};
