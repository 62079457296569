import { useEffect } from 'react';

import { useSafeState } from './useSafeState';

interface LanguageEntry {
  code: string;
  label: string;
}

export const useLanguageList = () => {
  const [list, setList] = useSafeState<LanguageEntry[]>([]);

  useEffect(() => {
    async function importList() {
      const list = (await import('config/languages.json')).default;
      setList(list);
    }
    importList();
  }, [setList]);

  return list;
};

export const useLanguageName = (languageCode: string) => {
  const list = useLanguageList();
  return list.find(({ code }) => code === languageCode)?.label ?? languageCode;
};

export const mapToSelect = ({ label, code }: LanguageEntry) => ({ value: code, label });
