import { Box, Button, makeStyles, Popover } from '@material-ui/core';
import React, { useRef, useState } from 'react';

import { ReactComponent as Expand } from 'assets/icons/expand.svg';

import * as Styled from './DropdownButton.styles';

interface DropdownProps {
  dropdownLinks: { label: string; icon: JSX.Element; href: string }[];
}

const useStyles = makeStyles(() => ({
  paper: {
    background: 'none',
    boxShadow: 'none',
    margin: '8px 0 0',
    overflow: 'initial',
  },
}));

export const DropdownButton: React.FC<DropdownProps> = ({ dropdownLinks }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>();
  const dropdownRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const handleButtonClick = (target: HTMLElement) => {
    setDropdownVisible((visible) => !visible);
    setAnchorElement(target || null);
  };

  return (
    <Box position="relative">
      <div ref={dropdownRef}>
        <Button
          variant="contained"
          onClick={(event) => handleButtonClick(event.currentTarget)}
          color="primary"
        >
          Create New
          <Styled.ExpandDropdown dropdownVisible={dropdownVisible}>
            <Expand />
          </Styled.ExpandDropdown>
        </Button>
        <Popover
          classes={classes}
          anchorEl={anchorElement}
          open={dropdownVisible}
          onClose={handleButtonClick}
          anchorOrigin={{ vertical: 'bottom', horizontal: -110 }}
          transitionDuration={0}
        >
          <Styled.DropdownList>
            {dropdownLinks.length &&
              dropdownLinks.map(({ icon, label, href }) => (
                <Styled.DropdownItemLink to={href} key={href}>
                  <Styled.DropdownItemIcon>{icon}</Styled.DropdownItemIcon>
                  <Styled.DropdownItemLabel>{label}</Styled.DropdownItemLabel>
                </Styled.DropdownItemLink>
              ))}
          </Styled.DropdownList>
        </Popover>
      </div>
    </Box>
  );
};
