import { useLayoutEffect, useRef } from 'react';

import { useSafeState } from 'hooks/useSafeState';
import { PageInfo } from 'models';
import { Nullable } from 'utils/types';

interface Props {
  pageInfo?: Nullable<PageInfo>;
  loadPage: (page: number) => void;
}

export function useInfiniteSegmentScroll({ pageInfo, loadPage }: Props) {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [lastY, setLastY] = useSafeState(0);
  const currentPage = pageInfo?.pageNumber || 0;
  const hasNextPage = pageInfo?.hasNextPage || false;

  useLayoutEffect(() => {
    const element = bottomRef.current;
    if (!element || !hasNextPage) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const y = entries[0].boundingClientRect.y;
        if (lastY > y) {
          loadPage(currentPage + 1);
        }
        setLastY(y);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      },
    );

    observer.observe(element);

    return () => {
      if (!element) return;
      observer.unobserve(element);
    };
  }, [currentPage, hasNextPage, lastY, loadPage, setLastY]);

  return {
    bottomRef,
  };
}
