import styled from 'styled-components';

import { materialTheme } from 'components/App/materialTheme';

export const AccordionWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 120px;

  label {
    span {
      font-size: 14px;
    }
  }
`;

export const SelectWrapper = styled.div`
  margin-bottom: 32px;
`;

interface FormFieldProps {
  disabled?: boolean;
}

export const FormField = styled.div<FormFieldProps>`
  background-color: ${({ disabled }) => disabled && '#fafafa'};
  border-radius: 10px;
  border: 1px solid ${materialTheme.palette.grey[200]};
  margin-right: 16px;
  width: 40%;

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  &:focus-within {
    border: 2px solid rgb(26, 26, 26);
  }
`;

export const TagsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  position: relative;
`;

export const TagWrapper = styled.div`
  align-items: center;
  //background-color: #f5f5f5;
  border-radius: 17px;
  border: 1px solid white;
  display: flex;
  min-height: 34px;
  padding: 4px 6px 4px 18px;
`;

export const TagButton = styled.button<{ disabled?: boolean }>`
  align-items: center;
  background-color: ${(props) => (props.disabled ? '#555' : '#000')};
  border-radius: 100%;
  border: none;
  color: ${materialTheme.palette.common.white};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 26px;
  justify-content: center;
  margin-left: 10px;
  outline: none;
  width: 26px;
`;

export const InputWrapper = styled(TagWrapper)`
  display: inline-flex;

  input {
    border-radius: 0;
    padding: 0;
  }
`;

export const TagsSelectionWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const InternalTagDescription = styled.p`
  color: ${materialTheme.palette.text.secondary};
  font-size: 12px;
`;

export const TooltipWrapper = styled.span`
  margin-left: 12px;
  display: flex;
  align-items: center;
`;
