import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { FormLabel, TextInput } from 'components/common';

import * as Styled from './CustomColor.styles';

interface CustomColorProps {
  selectedColor?: string;
  label?: string;
  onColorChange: (color: string) => void;
  mb?: string;
  error?: boolean;
  errorMessage?: any;
}

const DEFAULT_COLOR = '#000';

const CustomColor: React.VFC<CustomColorProps> = ({
  selectedColor,
  onColorChange,
  label = 'Main color (enter your hex code)',
  mb = '0',
  error,
  errorMessage,
}) => {
  const [hexColorSelected, setHexColorSelected] = useState(selectedColor);

  const handleColorChange = () => {
    onColorChange(hexColorSelected || DEFAULT_COLOR);
  };

  useEffect(() => {
    setHexColorSelected(selectedColor);
  }, [selectedColor]);

  const hasError = !!errorMessage || error;

  return (
    <Box mb={mb} width="100%">
      <FormLabel error={hasError}>{label}</FormLabel>
      <Styled.Wrapper>
        <Styled.ColorInput
          type="color"
          id="customColor"
          name="customColor"
          value={selectedColor || DEFAULT_COLOR}
          onChange={(e) => onColorChange(e.currentTarget.value)}
        />
        <TextInput
          name="customColorHex"
          value={hexColorSelected}
          onChange={(color) => setHexColorSelected(color)}
          onBlur={handleColorChange}
          mb={'0'}
          error={hasError}
          errorMessage={errorMessage}
        />
      </Styled.Wrapper>
    </Box>
  );
};

export default CustomColor;
