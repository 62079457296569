import { pushError } from 'context/globalStream';
import { ErrorType, MutationErrorCode, ServerErrorCode, ServerErrorType } from 'models';
import { path } from 'utils/common';
import { AnyObject, AwaitableCallback, Indexable, Optional, PartialRecord } from 'utils/types';

export function withCatch<Callback extends AwaitableCallback>(
  callback: Callback,
  onRejected: (error: any) => void,
): (...args: Parameters<Callback>) => ReturnType<Callback> {
  // @ts-ignore ts(2322)
  // Type 'Promise<any>' is not assignable to type 'ReturnType<Callback>'.
  return (...args) => {
    return callback(...args).catch(onRejected);
  };
}

/**
 * Tries to extract errors from GraphQL response data object.
 */
export function extractOperationErrors(pathToErrors: string, data?: AnyObject) {
  const errors = pathToErrors ? path<ErrorType[]>(pathToErrors, data) : undefined;
  return errors && errors.length > 0 ? errors : undefined;
}

/**
 * Extracts operation errors from GraphQL response data objects and displays them on screen.
 */
export function pushOperationErrors(
  pathToErrors: string,
  data?: Optional<AnyObject>,
  {
    errorMap,
    callback,
  }: {
    errorMap?: ErrorMap;
    callback?: (error: ErrorType) => void;
  } = {},
) {
  const operationErrors = extractOperationErrors(pathToErrors, data ?? undefined);
  if (operationErrors) {
    operationErrors.forEach((error) => {
      if (callback) {
        callback(error);
      }

      pushError({ message: getErrorMessage(error, errorMap) });
    });

    return operationErrors;
  }
}

export type ErrorMap = Indexable<
  PartialRecord<MutationErrorCode | ServerErrorCode, string>,
  string
>;

const commonErrorMap: PartialRecord<MutationErrorCode | ServerErrorCode, string> = {
  [MutationErrorCode.AuthRequired]: 'Invalid session. Try to log in again.',
  [MutationErrorCode.PermissionsError]: "You don't have permissions to perform this action.",
  [ServerErrorCode.Base64CaptionMalformedData]:
    'File cold not be parsed. Make sure it is in the correct format.',
  [ServerErrorCode.Base64CaptionExpectedText]:
    'File cold not be parsed. Make sure it is in the correct format.',
};

export function getErrorMessage(error: ErrorType | ServerErrorType, errorMap?: ErrorMap) {
  // @ts-ignore
  const combinedErrorMap: ErrorMap = {
    ...commonErrorMap,
    ...errorMap,
  };

  const code = isServerError(error) ? error.extensions.code : error.name;
  const originalMessage = isServerError(error) ? error.message : error.messages[0];

  const customMessage = combinedErrorMap[code];
  return customMessage ?? originalMessage;
}

function isServerError(error: ErrorType | ServerErrorType): error is ServerErrorType {
  return error.hasOwnProperty('extensions');
}

export class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ValidationError';
  }
}

export type AuthorizationTokenErrorType = 'TOKEN_EXPIRED' | 'TOKEN_INVALID';
export const authorizationTokenErrorMap: Record<AuthorizationTokenErrorType, string> = {
  TOKEN_EXPIRED: 'Your link has expired, try again.',
  TOKEN_INVALID: 'Your link is invalid, try again.',
};

export type PasswordRequirementsErrorType =
  | 'PASSWORD_TOO_SHORT'
  | 'PASSWORD_TOO_SIMILAR'
  | 'PASSWORD_ENTIRELY_NUMERIC';
export const passwordRequirementsErrorMap: Record<PasswordRequirementsErrorType, string> = {
  PASSWORD_TOO_SHORT: 'This password is too short.',
  PASSWORD_TOO_SIMILAR: 'This password is too similar to your email address.',
  PASSWORD_ENTIRELY_NUMERIC: 'This password is entirely numeric.',
};

export type LegalAgreementsErrorType = 'TERMS_OF_USE_NOT_ACCEPTED' | 'PRIVACY_POLICY_NOT_ACCEPTED';
export const legalAgreementsErrorMap: Record<LegalAgreementsErrorType, string> = {
  TERMS_OF_USE_NOT_ACCEPTED: 'You must accept the Terms of Use.',
  PRIVACY_POLICY_NOT_ACCEPTED: 'You must accept the Privacy Policy.',
};
