import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'PLAYLIST_IN_USE';

const errorMap: Record<Errors, string> = {
  PLAYLIST_IN_USE: 'Playlist is being used in microsites. Make sure to remove it before deleting.',
};

const QUERY = gql`
  mutation DeletePlaylist($id: ID!) {
    deletePlaylist(id: $id) {
      deletedPlaylistId
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  deletePlaylist: Nullable<{
    deletedPlaylistId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeletePlaylistMutationOptions extends UseMutationOptions<ResponseData, Variables> {}

export function useDeletePlaylist(options?: UseDeletePlaylistMutationOptions) {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'deletePlaylist.errors',
    errorMap,
    ...options,
  });
}
