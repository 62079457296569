import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type RouterLinkProps = Omit<LinkProps, 'innerRef' | 'ref'>;

const RouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function RouterLink(
  props,
  ref,
) {
  return <Link innerRef={ref} {...props} />;
});

export default RouterLink;
