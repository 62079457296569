import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { LiteralUnion } from 'type-fest';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { OrganizationI } from 'models';
import { Nullable } from 'utils/types';

export type SocialMediaPlatformOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'label'
  | '-label'
  | 'platformName'
  | '-platformName',
  string
>;

export const QUERY = gql`
  query GetOrganization($orderBy: String = "", $search: String = "") {
    me {
      id
      organization {
        id
        name
        isMonetizationEnabled
        logoUrl
        owner {
          id
        }
        subscription {
          cost
          endsAt
          id
          plan {
            name
          }
          status
        }
        socialMedia(orderBy: $orderBy, search: $search) {
          id
          label
          accountUrl
          platformName
        }
        shareSocialMediaIcons {
          platformName
          visible
        }
        monetizationSettings {
          id
          active
          created
          default
          monetizationUrl
          name
        }
      }
    }
  }
`;

export type OrganizationPick = Pick<
  OrganizationI,
  | 'id'
  | 'name'
  | 'isMonetizationEnabled'
  | 'logoUrl'
  | 'owner'
  | 'subscription'
  | 'socialMedia'
  | 'shareSocialMediaIcons'
  | 'monetizationSettings'
>;

interface ResponseData {
  me: {
    id: string;
    organization: Nullable<OrganizationPick>;
  };
}

interface Variables {
  orderBy?: SocialMediaPlatformOrderBy;
  search?: string;
}

interface UseOrganizationQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useOrganizationQuery = (options?: UseOrganizationQueryOptions) => {
  return useQuery<ResponseData>(QUERY, options);
};

export const getOrganization = (client: ApolloClient<any>) => {
  return client.query<ResponseData>({
    query: QUERY,
    fetchPolicy: 'network-only',
  });
};
