import { CustomCodePauseScreen, lightTheme } from '@entertainment-ai/react-component-library';
import hash from 'hash-it';
import { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import previewBg from 'assets/images/end-card-preview-bg.jpg';
import * as Styled from 'components/common/PageContentReview';

interface CustomCodeEndCardPreviewProps {
  htmlContent: string;
}

export const CustomCodeEndCardPreview: React.FC<CustomCodeEndCardPreviewProps> = ({
  htmlContent,
}) => {
  // Hash the custom code and use it as a key to refresh the preview
  const codeHash = useMemo(() => {
    if (htmlContent) {
      return hash(htmlContent);
    }
  }, [htmlContent]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Styled.PreviewCard>
        <Styled.PreviewCardHeader>
          <Styled.PreviewCardLabel>Preview</Styled.PreviewCardLabel>
        </Styled.PreviewCardHeader>
      </Styled.PreviewCard>
      <Styled.PreviewCardBody
        height="420px"
        position="relative"
        width="auto"
        style={{
          backgroundImage: `url(${previewBg})`,
          backgroundSize: 'cover',
        }}
      >
        {!!codeHash && <CustomCodePauseScreen key={codeHash} isVisible htmlContent={htmlContent} />}
      </Styled.PreviewCardBody>
    </ThemeProvider>
  );
};
