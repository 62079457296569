import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as View } from 'assets/icons/eye.svg';
import { Link } from 'components/common';
import { VideoStatus } from 'models';
import { RouterLink } from 'utils/types';

interface Props {
  url: RouterLink;
  videoStatus?: VideoStatus;
  canUserChangeIt: boolean;
  edge?: 'start' | 'end';
}

export const ViewEditAction: React.FC<Props> = ({ url, videoStatus, canUserChangeIt, edge }) => {
  return url ? (
    videoStatus === VideoStatus.Failed || !canUserChangeIt ? (
      <Tooltip title="View details" arrow placement="top">
        <IconButton color="inherit" edge={edge} component={Link} to={url}>
          <View />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="Edit" arrow placement="top">
        <IconButton color="inherit" edge={edge} component={Link} to={url}>
          <Edit />
        </IconButton>
      </Tooltip>
    )
  ) : null;
};
