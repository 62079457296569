import { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Nullable } from 'utils/types';

import { CTATimestamp, CTATimestampCtas } from './type';

interface VideoCTAPageValues {
  duration: Nullable<number>;

  currentCTA: Nullable<CTATimestampCtas>;
  hideCurrentCTAForm: () => void;
  createCurrentCTA: (cta: CTATimestampCtas) => void;
  updateCurrentCTA: (cta: Nullable<CTATimestampCtas>) => void;
  removeCurrentCTA: (ctaId: CTATimestampCtas['id']) => void;

  currentCTAs: CTATimestampCtas[];
  updateCurrentCTAs: (ctas: CTATimestampCtas[]) => void;

  timestamps: CTATimestamp[];
  updateTimestamps: (timestamp: CTATimestamp[]) => void;
  createCurrentTimestamp: (timestamp: CTATimestamp) => void;
  removeCurrentTimestamp: (timestampId: CTATimestamp['id']) => void;
  hideCurrentTimestampForm: () => void;
}

export const VideoCTAPageContext = createContext<VideoCTAPageValues>({
  duration: null,

  currentCTA: null,
  hideCurrentCTAForm: () => {},
  updateCurrentCTA: () => {},
  createCurrentCTA: () => {},
  removeCurrentCTA: () => {},

  currentCTAs: [],
  updateCurrentCTAs: () => {},

  timestamps: [],
  updateTimestamps: () => {},
  createCurrentTimestamp: () => {},
  removeCurrentTimestamp: () => {},
  hideCurrentTimestampForm: () => {},
});

export const useTimestampContext = (videoTimestamps: CTATimestamp[]) => {
  const [currentCTA, setCurrentCTA] = useState<Nullable<CTATimestampCtas>>(null);
  const [currentCTAs, setCurrentCTAs] = useState<CTATimestampCtas[]>([]);
  const [timestamps, setTimestamps] = useState<CTATimestamp[]>(videoTimestamps);

  const hideCurrentCTAForm = (): void => {
    setCurrentCTA(null);
  };

  const updateCurrentCTA = (cta: Nullable<CTATimestampCtas>) => {
    setCurrentCTA(cta);
  };

  const createCurrentCTA = (cta: CTATimestampCtas) => {
    hideCurrentCTAForm();
    setCurrentCTAs([{ ...cta, id: uuidv4() }, ...currentCTAs]);
  };

  const removeCurrentCTA = (ctaId: CTATimestampCtas['id']): void => {
    setCurrentCTAs(currentCTAs.filter((cta) => cta.id !== ctaId));
  };

  const updateCurrentCTAs = (ctas: CTATimestampCtas[]) => {
    setCurrentCTAs(ctas);
  };

  const hideCurrentTimestampForm = (): void => {
    updateCurrentCTAs([]);
  };

  const createCurrentTimestamp = (newTimestamp: CTATimestamp): void => {
    setTimestamps([{ ...newTimestamp, id: uuidv4() }, ...timestamps]);
    hideCurrentTimestampForm();
  };

  const removeCurrentTimestamp = (timestampId?: string): void => {
    setTimestamps(timestamps.filter((item) => item.id !== timestampId));
  };

  const updateTimestamps = (updatedTimestamps: CTATimestamp[]): void => {
    setTimestamps(updatedTimestamps);
    hideCurrentTimestampForm();
  };

  return {
    currentCTA,
    hideCurrentCTAForm,
    createCurrentCTA,
    updateCurrentCTA,
    removeCurrentCTA,

    currentCTAs,
    updateCurrentCTAs,

    timestamps,
    updateTimestamps,
    createCurrentTimestamp,
    removeCurrentTimestamp,
    hideCurrentTimestampForm,
  };
};
