import { useApolloClient } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import React from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';
import config from 'config';
import { pushError } from 'context/globalStream';
import { LocalStorage } from 'utils/storage';

const getToken = (token: string) =>
  fetch(config.authUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access_token: token,
    }),
  });

export const GoogleLoginForm: React.FC = () => {
  const client = useApolloClient();

  const handleGoogleLoginSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('code' in response) {
      pushGoogleAuthError();
      return;
    }

    getToken(response.accessToken)
      .then((resp) => resp.json())
      .then(({ token }) => {
        LocalStorage.set('token', token);
        client.writeData({ data: { isLoggedIn: true } });
      })
      .catch(() => {
        client.writeData({ data: { isLoggedIn: false } });
        pushGoogleAuthError();
      });
  };

  const handleGoogleLoginError = (error: any) => {
    pushGoogleAuthError();
    console.error('Google authorization error', error);
  };

  return (
    <GoogleLogin
      clientId={config.googleClientId}
      render={({ onClick, disabled }) => (
        <Button variant="outlined" startIcon={<GoogleIcon />} onClick={onClick} disabled={disabled}>
          Sign in with Google
        </Button>
      )}
      onSuccess={handleGoogleLoginSuccess}
      onFailure={handleGoogleLoginError}
    />
  );
};

function pushGoogleAuthError() {
  pushError({ message: 'Google authorization failed. Try again.' });
}
