import { env } from './env';

interface EnvConfig {
  backendApi: string;
  authUrl: string;
  googleClientId: string;
  youtube: {
    authorizeUrl: string;
    saveTokenUrl: string;
    revokeUrl: string;
  };
  widget: {
    player: string;
    carousel: string;
    verticalCarousel: string;
  };
}

const config: EnvConfig = {
  backendApi: env.SEEEN_APP_BACKEND_URL + 'graphql/',
  authUrl: env.SEEEN_APP_BACKEND_URL + 'users/login/google-oauth2/',
  googleClientId: env.SEEEN_APP_GOOGLE_CLIENT_ID,
  youtube: {
    authorizeUrl: env.SEEEN_APP_BACKEND_URL + 'users/authorize/youtube/',
    saveTokenUrl: env.SEEEN_APP_BACKEND_URL + 'users/token/youtube/',
    revokeUrl: env.SEEEN_APP_BACKEND_URL + 'users/revoke/youtube/',
  },
  widget: {
    player:
      env.SEEEN_APP_PLAYER_WIDGET_URL ||
      'https://code.seeen.com/carousel-widget/widget/latest/player-widget.umd.min.js',
    carousel:
      env.SEEEN_APP_CAROUSEL_WIDGET_URL ||
      'https://code.seeen.com/carousel-widget/widget/latest/carousel-widget.umd.min.js',
    verticalCarousel:
      env.SEEEN_APP_VERTICAL_CAROUSEL_WIDGET_URL ||
      'https://code.seeen.com/carousel-widget/widget/latest/vertical-carousel-widget.umd.min.js',
  },
};

export default config;
