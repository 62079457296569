import { Box } from '@material-ui/core';
import React from 'react';

import { BackButton, Typography } from 'components/common';
import { VideoDetailsNode } from 'models';
import { generateVideoLink } from 'pages/urls';

import { TimestampList } from './Timestamp/TimestampList';

interface Props {
  videoId: VideoDetailsNode['id'];
}

export const VideoCTAPageSidebar: React.FC<Props> = ({ videoId }) => {
  const videoEditorUrl = generateVideoLink(videoId);

  return (
    <>
      <BackButton label="Back to video editor" link={videoEditorUrl} />
      <Box m="22px 0 32px 0">
        <Typography variant="h1" color="textPrimary">
          CTAs
        </Typography>
      </Box>
      <TimestampList />
    </>
  );
};
