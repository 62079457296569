import styled from 'styled-components/macro';

export const HORIZONTAL_PADDING = 20;

export const Wrapper = styled.div`
  background-color: #393b45;
  color: #fff;
  padding: 10px ${HORIZONTAL_PADDING}px 21px;
  overflow: hidden;
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const Actions = styled.div`
  margin-left: auto;

  && > * + * {
    margin-left: 16px;
  }
`;

export const TimelineWrapper = styled.div`
  position: relative;
  padding-top: 14px;
  margin: 0 -${HORIZONTAL_PADDING}px;
`;
