import React from 'react';

type DragCallbackPayload = {
  oldLeft: number;
  oldTop: number;
  newLeft: number;
  newTop: number;
};

type DragCallback = (event: MouseEvent, payload: DragCallbackPayload) => void;

export function useMove(
  elementRef: React.RefObject<HTMLElement>,
  wrapperRef: React.RefObject<HTMLElement>,
  onDrag: DragCallback,
  onFinish?: DragCallback,
) {
  // When the user is dragging and the mouse goes offscreen, when can't detect mouseup event.
  // To cancel the drag in this case, user has to click (mousedown + mouseup) onscreen again.
  // To prevent duplicated mousedown event, we need to store the information about mouse dragging.
  const isDragging = React.useRef(false);

  // Offset between top-left corner and mouse position inside element on click
  const mouseShift = React.useRef({
    x: 0,
    y: 0,
  });

  const move = (event: MouseEvent): DragCallbackPayload | undefined => {
    const element = elementRef.current;
    const wrapper = wrapperRef.current;

    if (!element || !wrapper) {
      return;
    }

    const elementRect = element.getBoundingClientRect();
    const wrapperRect = wrapper.getBoundingClientRect();

    let newLeft = event.clientX - mouseShift.current.x - wrapperRect.x;
    let newTop = event.clientY - mouseShift.current.y - wrapperRect.y;

    if (newTop < 0) {
      newTop = 0;
    }
    if (newTop > wrapperRect.height - elementRect.height) {
      newTop = wrapperRect.height - elementRect.height;
    }

    onDrag(event, {
      oldLeft: elementRect.left - wrapperRect.left,
      oldTop: elementRect.top - wrapperRect.top,
      newLeft,
      newTop,
    });

    return {
      oldLeft: elementRect.left - wrapperRect.left,
      oldTop: elementRect.top - wrapperRect.top,
      newLeft,
      newTop,
    };
  };

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const element = elementRef.current;
    const wrapper = wrapperRef.current;

    if (!element || !wrapper) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    startDrag();

    function startDrag() {
      if (isDragging.current) {
        return;
      }

      isDragging.current = true;

      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', finishDrag);

      const { left, top } = element!.getBoundingClientRect();

      mouseShift.current = {
        x: event.clientX - left,
        y: event.clientY - top,
      };
    }

    function finishDrag(event: MouseEvent) {
      if (!isDragging.current) {
        return;
      }

      const moveDiff = move(event);

      if (onFinish && moveDiff) {
        onFinish(event, moveDiff);
      }

      isDragging.current = false;

      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', finishDrag);
    }
  };

  return onMouseDown;
}
