import { Backdrop, Fade, Modal as MaterialModal } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { ReactComponent as Close } from 'assets/icons/x.svg';

import * as Styled from './Modal.styles';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  width?: number;
  heading?: string;
  dataTestId?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

/** @deprecated Use `<Dialog />` instead */
const Modal = ({ open, handleClose, children, width, heading, dataTestId }: ModalProps) => {
  const classes = useStyles();
  return (
    <MaterialModal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      data-testid={dataTestId}
    >
      <Fade in={open}>
        <Styled.Body bodyWidth={width}>
          <Styled.CloseButton onClick={handleClose}>
            <Close width="24" height="24" />
          </Styled.CloseButton>
          {heading && <Styled.Heading>{heading}</Styled.Heading>}
          {children}
        </Styled.Body>
      </Fade>
    </MaterialModal>
  );
};

export default Modal;
