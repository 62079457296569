import { darken, lighten } from 'polished';
import React from 'react';

import { materialTheme } from 'components/App/materialTheme';
import { Badge, BadgeProps } from 'components/common/Badge';

export enum KeyStatus {
  active = 'ACTIVE',
  inActive = 'INACTIVE',
}

interface Props {
  status: KeyStatus;
}

export const KeyStatusBadge: React.VFC<Props> = ({ status }) => {
  const props = colorMap[status];
  return <Badge {...props}>{status.toUpperCase()}</Badge>;
};

export const colorMap: Record<KeyStatus, Partial<BadgeProps>> = {
  [KeyStatus.inActive]: {
    backgroundColor: lighten(0.46, materialTheme.palette.error.main),
    color: materialTheme.palette.error.main,
  },
  [KeyStatus.active]: {
    backgroundColor: lighten(0.6, materialTheme.palette.success.main),
    color: darken(0.1, materialTheme.palette.success.main),
  },
};
