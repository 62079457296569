import React, { useEffect } from 'react';

import { useVideoMetadataObservable } from 'context/VideoMetadataStream';
import { Range, TimeRange, VideoMomentNode } from 'models';
import { useMomentsEditorContext } from 'pages/Moments/context';

import { Timeline } from './Timeline';
import { TimelineEditorAction } from './TimelineEditorAction';
import { TimePanel } from './TimePanel';

import * as Styled from './TimelineEditor.styles';

interface OwnProps<TRange extends Range> {
  actions?: React.ReactNode;
  ranges: TRange[];
  updateRange?: (range: TRange) => void;
  seekTo: (ms: number) => void;
  /** in ms */
  minRangeLength?: number;
  isDisabled?: boolean;
  getBlocksToBeRemoved?: (segmentId: string) => boolean[];
  moments: VideoMomentNode[];
}

const DEFAULT_RANGE_VIEW = [0, 50000];
export function TimelineEditor<TRange extends Range>({
  seekTo,
  ranges,
  updateRange,
  actions,
  minRangeLength = 0,
  isDisabled,
  getBlocksToBeRemoved,
  moments,
}: OwnProps<TRange>) {
  const { duration: videoDuration, played: playedDuration } = useVideoMetadataObservable();
  const { setPlayedDuration } = useMomentsEditorContext();

  const [rulerRange, setRulerRange] = React.useState(DEFAULT_RANGE_VIEW as TimeRange);

  useEffect(() => {
    setRulerRange([0, Math.min(videoDuration, DEFAULT_RANGE_VIEW[1])]);
  }, [videoDuration]);

  useEffect(() => {
    setPlayedDuration(playedDuration);
  }, [playedDuration, setPlayedDuration]);

  return (
    <Styled.Wrapper>
      <TimePanel
        videoDuration={videoDuration}
        rulerRange={rulerRange}
        setRulerRange={setRulerRange}
        playedDuration={playedDuration}
        moments={moments}
      />
      {actions && <Styled.Actions>{actions}</Styled.Actions>}
      <Styled.TimelineWrapper>
        <Timeline
          ranges={ranges}
          getBlocksToBeRemoved={getBlocksToBeRemoved}
          updateRange={updateRange}
          videoDuration={videoDuration}
          playedDuration={playedDuration}
          rulerRange={rulerRange}
          setRulerRange={setRulerRange}
          seekTo={seekTo}
          minRangeLength={minRangeLength}
          isDisabled={isDisabled}
          moments={moments}
        />
      </Styled.TimelineWrapper>
    </Styled.Wrapper>
  );
}

TimelineEditor.Action = TimelineEditorAction;
