import { IS_USER_LOGGED_IN } from 'context/queries';
import { useQuery } from 'hooks/useQuery';

export const useIsUserLoggedIn = () => {
  const result = useQuery<{ isLoggedIn: boolean }>(IS_USER_LOGGED_IN);
  return {
    ...result,
    isLoggedIn: result.data?.isLoggedIn || false,
  };
};
