import { Box } from '@material-ui/core';
import { motion } from 'framer-motion';
import React from 'react';

import { useVideoMetadataObservable } from 'context/VideoMetadataStream';
import { useElementSizeObservable } from 'hooks/useElementSizeObservable';
import { CTAButton } from 'pages/CTAButton';
import { convertButtonDataFromServer } from 'pages/CTAButton/helpers';
import { ButtonCTAResponse } from 'pages/CTAButton/types';

import { keyframes } from './constants';
import { percentageToPx } from './helpers';
import { AnimationName, CTATimestampCtas, CTATimestampCtasAnimation } from './type';

interface Props {
  position: CTATimestampCtas['config']['position'];
  styles: ButtonCTAResponse;
  zIndex: number;
  animation?: CTATimestampCtasAnimation;
}

export const VideoOverlaysButton: React.FC<Props> = ({ position, styles, zIndex, animation }) => {
  const { elementRef, width, height } = useElementSizeObservable();
  const { playing } = useVideoMetadataObservable();

  const calculatePosition = (percentage: number, size: number) => {
    const elementDiff = percentageToPx(percentage, size);

    return `calc(${percentage}% - ${elementDiff}px)`;
  };

  return (
    <Box
      position="absolute"
      left={calculatePosition(position.calculatedLeft, width)}
      top={calculatePosition(position.calculatedTop, height)}
      width="max-content"
      zIndex={zIndex}
    >
      <motion.div
        ref={elementRef}
        animate={
          animation &&
          (!playing || animation?.name === ''
            ? 'none'
            : {
                ...keyframes[animation.name as AnimationName],
                transition: {
                  [Object.keys(keyframes[animation?.name])[0]]: {
                    repeat: Infinity,
                    duration: parseInt(animation.duration),
                    ease: 'easeOut',
                    repeatDelay: animation.cycleTime,
                    type: 'keyframes',
                  },
                },
              })
        }
      >
        <CTAButton config={convertButtonDataFromServer(styles)} />
      </motion.div>
    </Box>
  );
};
