import { Nullable } from '@entertainment-ai/react-component-library/dist/utils/types';
import { Box, Button, Checkbox } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormLabel, TextInput } from 'components/common';
import { Intercom } from 'utils/intercom';
import {
  isMaxLength,
  isMinLength,
  isNotEmptyString,
  isValidSlugCharacters,
  isValidSlugDashes,
  validate,
} from 'utils/validation';

import { getMicrositeDomain } from '../helpers';
import { SlugInput } from './SlugInput';

import * as Styled from './SiteDetails.styles';

type Props =
  | {
      isEditMode: true;
      domain: string;
      momentsLocationTop: boolean;
      title: string;
      description: Nullable<string>;
    }
  | {
      title?: string;
      description?: null;
      isEditMode?: false;
      domain?: undefined;
      momentsLocationTop?: boolean;
    };

type FormData = {
  title: string;
  description: Nullable<string>;
  momentsLocationTop: boolean;
  domain: string;
};

export const SiteDetails: React.VFC<Props> = (props) => {
  return (
    <Styled.Content>
      <SiteDetailsForm {...props} />
      <Box mt="auto" pt="20px">
        <Styled.Text>Fields marked with an asterisk (*) are required</Styled.Text>
      </Box>
    </Styled.Content>
  );
};

export const SiteDetailsForm: React.VFC<Props> = ({ isEditMode, domain, momentsLocationTop }) => {
  const { register, errors, control } = useFormContext<FormData>();
  const [momentsLocation, setMomentsLocation] = useState<boolean>(
    momentsLocationTop === undefined ? true : momentsLocationTop,
  );
  return (
    <Box width="100%">
      <TextInput
        ref={register({
          validate: validate(isNotEmptyString, 'Title cannot be empty'),
        })}
        label="Microsite title*"
        name="title"
        error={!!errors.title}
        errorMessage={errors.title?.message}
        fullWidth
        info="The title will be displayed in the users browser tab of the microsite."
        infoPlacement="left"
      />
      <TextInput
        ref={register}
        label="Microsite description"
        name="description"
        maxLength={160}
        fullWidth
        info="The description will be displayed when sharing the URL to your microsite via social media."
        infoPlacement="left"
      />
      {isEditMode ? (
        <TextInput
          label="Microsite URL"
          name="micrositeUrl"
          defaultValue={`https://${domain}`}
          copyable={!!domain}
          readOnly
        />
      ) : (
        <SlugInput
          ref={register({
            validate: {
              slug: validate(
                isValidSlugCharacters,
                'Microsite URL must only consist of lowercase letters, numbers and dashes',
              ),
              dash: validate(isValidSlugDashes, 'Microsite URL cannot start or end with dashes'),
              minLength: validate(
                isMinLength(2),
                'Microsite URL must be at least 2 characters long',
              ),
              maxLength: validate(
                isMaxLength(63),
                'Microsite URL must be at most 63 characters long',
              ),
            },
          })}
          label="Microsite URL*"
          name="domain"
          postText={`.${getMicrositeDomain()}`}
          errorMessage={errors.domain?.message}
        />
      )}
      <FormLabel>Moments location</FormLabel>
      <Styled.Wrapper>
        <Controller
          name="momentsLocationTop"
          control={control}
          render={({ value, name, onChange }) => {
            const val = value !== undefined ? Boolean(value) : true;
            return (
              <Checkbox
                ref={register}
                onChange={(e) => onChange(e.target.checked)}
                onClick={() => setMomentsLocation((prev) => !prev)}
                name={name}
                checked={val}
              />
            );
          }}
        />
        <FormLabel moment>{`Moments will be ${
          momentsLocation ? 'above' : 'below'
        } the video player`}</FormLabel>
      </Styled.Wrapper>
      <Styled.Text>
        You can choose if you want to place moments of every video on your microsite above or under
        your video player
      </Styled.Text>
      <br />
      <div>
        <FormLabel>Custom URL</FormLabel>
        <Box mt="12px" alignSelf="flex-start">
          <Button variant="contained" color="primary" onClick={() => Intercom.show()}>
            Contact us
          </Button>
        </Box>
        <Styled.Text>
          If you’d like your own domain, please use the button above and we’ll get in touch
        </Styled.Text>
      </div>
    </Box>
  );
};
