import React from 'react';

import { LoadingButton } from 'components/common';

import { FormData, PresetForm } from '../PresetForm';

export type { FormData };

interface Props {
  initialData?: FormData;
  onSubmit: (data: FormData) => void;
  isSubmitting: boolean;
}

export function EditPreset({ initialData, onSubmit, isSubmitting }: Props) {
  return (
    <PresetForm
      name="edit-widget-preset"
      initialData={initialData}
      onSubmit={onSubmit}
      submitButton={
        <LoadingButton type="submit" variant="contained" color="primary" isLoading={isSubmitting}>
          Save Preset
        </LoadingButton>
      }
    />
  );
}
