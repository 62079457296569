import { QUERY_PUBLISHING_TARGETS } from 'context/queries';
import { stripTypename, useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PublishingTarget, PublishingTargetBase, PublishingTargetType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  me: {
    id: string;
    organization: Nullable<{
      publishingTargets: Nullable<PublishingTargetBase[]>;
      externalPublishingTargets: Nullable<PublishingTargetBase[]>;
    }>;
  };
}

interface UsePublishingTargetsQueryOptions extends UseQueryOptions<ResponseData> {}

export const usePublishingTargets = (options?: UsePublishingTargetsQueryOptions) => {
  const { data, loading: isLoading } = useQuery<ResponseData>(QUERY_PUBLISHING_TARGETS, options);

  const organization = stripTypename(data?.me.organization);
  const internalTargets = organization?.publishingTargets ?? [];
  const externalTargets = organization?.externalPublishingTargets ?? [];
  const publishingTargets = [...internalTargets, ...externalTargets].map(
    (target): PublishingTarget => {
      return {
        ...target,
        type: PublishingTargetType.SelfPublication,
      };
    },
  );

  return {
    publishingTargets,
    isLoading,
  };
};
