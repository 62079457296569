import { LiteralUnion } from 'type-fest';

import { PageInfo } from 'models';
import { Optional } from 'utils/types';

export enum CTAItemType {
  Button = 'BUTTON',
  Image = 'IMAGE',
}

export interface CTAItem {
  id: string;
  name: string;
  created: string;
  type: CTAItemType;
  usedInVideos: number;
  usedInCards: number;
}

export interface CTAsResponse {
  nodes: CTAItem[];
  pageInfo: Optional<PageInfo>;
  totalCount: number;
}

export type CTAsOrderBy = LiteralUnion<
  | ''
  | 'id'
  | '-id'
  | 'created'
  | '-created'
  | 'name'
  | '-name'
  | 'type'
  | '-type'
  | 'used_in_videos'
  | '-used_in_videos'
  | 'used_in_cards'
  | '-used_in_cards',
  string
>;
