import React from 'react';
import { useForm } from 'react-hook-form';

import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { useUpdateVideo } from 'hooks/mutation';
import { VideoDetailsNode } from 'models';

import { VideoEndCardPageMain } from './VideoEndCardPageMain';
import { VideoCTAPageSidebar } from './VideoEndCardPageSidebar';

export interface FormData {
  endCardId?: string | null;
}

interface Props {
  video: VideoDetailsNode;
}

export const VideoEndCardPageView: React.FC<Props> = ({ video }) => {
  const form = useForm<FormData>();
  const [updateVideo, { loading }] = useUpdateVideo(video.id);

  const onSubmit = (data: FormData) => {
    updateVideo({
      variables: {
        id: video.id,
        video: {
          title: video.title,
          description: video.description,
          endCardId: data.endCardId,
        },
      },
    });
  };

  return (
    <ExtensibleSidebar
      sideContent={<VideoCTAPageSidebar form={form} onSubmit={onSubmit} video={video} />}
      mainContent={<VideoEndCardPageMain isLoading={loading} video={video} />}
    />
  );
};
