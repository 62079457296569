import { Button, ButtonProps, IconButton, IconButtonProps, Tooltip } from '@material-ui/core';

import { ReactComponent as Embed } from 'assets/icons/embed.svg';

export interface PassedProps {
  showModal: React.MouseEventHandler;
}

export function EmbedButton({ showModal, ...buttonProps }: PassedProps & ButtonProps) {
  return (
    <Button variant="outlined" color="secondary" {...buttonProps} onClick={showModal}>
      Embed
    </Button>
  );
}

export function EmbedTableAction({ showModal, ...iconButtonProps }: PassedProps & IconButtonProps) {
  return (
    <Tooltip title="Get embed code" arrow placement="top">
      <IconButton color="inherit" {...iconButtonProps} onClick={showModal}>
        <Embed />
      </IconButton>
    </Tooltip>
  );
}
