import { Box } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { materialTheme } from 'components/App/materialTheme';
import { InfoTooltip } from 'components/common';
import { ConfirmationModal } from 'components/common/Modals';
import MemoToggle from 'components/common/Table/MemoToggle';

import { ToggleProps } from '../types';

import { MonetizationAllVideosTitle } from './MonetizationToggle.styles';

export const MonetizationAllVideosToggle: React.VFC<ToggleProps> = ({
  checked,
  disabled,
  onChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMonetizationDisabled = !checked;

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleToggleChange = useCallback(() => {
    if (checked) {
      setIsModalOpen(true);
    } else {
      onChange();
    }
  }, [checked, onChange]);

  return (
    <>
      <Box
        p="16px 32px"
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${materialTheme.palette.background.default}`}
        height="80"
      >
        <MemoToggle
          checked={checked}
          onChange={handleToggleChange}
          disabled={disabled}
          isLabelDisabled
        />
        <Box display="flex">
          <strong>Monetization</strong>
          <Box ml="10px" display="flex">
            <InfoTooltip
              content="Monetization allows you to earn revenue from your videos."
              placement="top"
            />
          </Box>
        </Box>
      </Box>
      {isMonetizationDisabled ? (
        <MonetizationAllVideosTitle display="flex" justifyContent="center" p="40px">
          Monetization is disabled for your organization.
          <br />
          Enable monetization to use the stored ad tags.
        </MonetizationAllVideosTitle>
      ) : null}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={onChange}
        heading="Disable monetization"
        description={`Are you sure you want to disable monetization?
          You will no longer be able to earn revenue from your videos.
          We will store your ad tags and per-video settings.`}
        confirmationButtonText="Disable"
      />
    </>
  );
};
