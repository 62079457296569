import { D2C_API_KEYS_LIST } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { D2CApiKey, D2CApiKeyOrderBy, PageInfo, PaginationVariables } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  d2cApiKeys: Nullable<{
    nodes: D2CApiKey[];
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends PaginationVariables {
  orderBy?: D2CApiKeyOrderBy;
  revoked?: boolean;
}

interface UseD2CApiKeyOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useD2CApiKeyQuery = (options: UseD2CApiKeyOptions) => {
  return useQuery<ResponseData, Variables>(D2C_API_KEYS_LIST, options);
};
