import { Box, Button } from '@material-ui/core';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Typography } from 'components/common';
import { StyledTabs, TabsWrapper } from 'components/common/Tab/Tab';
import { TabLink, useTabsWithRouter } from 'components/common/TabLink';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { URLS } from 'pages/urls';

import { ApiKeyTab } from './ApiKeyTab';
import { InstallModal } from './InstallModal';
import { ServiceUsageTab } from './ServiceUsageTab';

const { apiKey: apiKeyTabUrl, root, serviceUsage: serviceUsageTabUrl } = URLS.d2c;

export const D2CDashboard: React.VFC = () => {
  const [showInstallModal, setShowInstallModal] = React.useState(false);
  const tabValue = useTabsWithRouter([apiKeyTabUrl, serviceUsageTabUrl], apiKeyTabUrl);

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">D2C</Typography>
          <Button variant="contained" color="primary" onClick={() => setShowInstallModal(true)}>
            Install D2C
          </Button>
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <TabsWrapper px="32px">
            <StyledTabs value={tabValue}>
              <TabLink value={serviceUsageTabUrl} label="Service Usage" />
              <TabLink value={apiKeyTabUrl} label="API Keys Management" />
            </StyledTabs>
          </TabsWrapper>
          <Switch>
            <Route path={[URLS.root, root, apiKeyTabUrl]} exact>
              <ApiKeyTab />
            </Route>
            <Route path={serviceUsageTabUrl}>
              <ServiceUsageTab />
            </Route>
          </Switch>
        </BigCard>
      </Box>
      <InstallModal onClose={() => setShowInstallModal(false)} isOpen={showInstallModal} />
    </MainLayout>
  );
};
