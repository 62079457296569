import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { ReactComponent as UploadCloud } from 'assets/icons/upload-cloud.svg';
import { FileUpload } from 'components/common/FileUpload';
import { pushError } from 'context/globalStream';
import { useSafeState } from 'hooks/useSafeState';
import { noop } from 'utils/common';
import { blobToBase64 } from 'utils/files';

import * as Styled from './UploadDropBox.styles';

const passValidation = () => true;

interface Props {
  id: string;
  /** @returns `true` = pass, `false` = fail */
  validateFile?: (file: File) => boolean | Promise<boolean>;
  onFileProcessed?: (imageBase64: string) => unknown;
  disabled?: boolean;
  uploadLabel?: string;
  inline?: boolean;
  'data-testid'?: string;
  uploadBoxStyle?: React.CSSProperties;
}

export const UploadDropBox: React.VFC<Props> = ({
  id,
  validateFile = passValidation,
  onFileProcessed = noop,
  disabled,
  uploadLabel = 'Upload',
  inline = false,
  'data-testid': testId,
  uploadBoxStyle,
}) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file) => {
        if (!(await validateFile(file))) {
          return;
        }

        setIsLoading(true);

        blobToBase64(file)
          .then(onFileProcessed)
          .catch(() => {
            pushError({ message: 'File conversion failed. Try to upload again.' });
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    },
    [validateFile, setIsLoading, onFileProcessed],
  );

  return (
    <FileUpload id={id} onDrop={onDrop} isDisabled={disabled} data-testid={testId}>
      {(rootProps) => {
        return (
          <Styled.UploadBox {...rootProps} $inline={inline} style={uploadBoxStyle}>
            {isLoading ? (
              <CircularProgress size={26} />
            ) : (
              <>
                <UploadCloud width="20" height="20" />
                {uploadLabel}
              </>
            )}
          </Styled.UploadBox>
        );
      }}
    </FileUpload>
  );
};
