export const FORM_ID = 'new-cta-image';

export const ACCEPTED_IMAGE_FORMATS = ['.png', '.svg'];

export const MAX_IMAGE_SIZE = 10;
export const VALIDATION_FILE_MESSAGE = `Accepted format: ${ACCEPTED_IMAGE_FORMATS.join(
  ', ',
)}, max. size: ${MAX_IMAGE_SIZE}MB`;

export const ACCEPTED_FORMATS_ERROR_MESSAGE = `Accepted format: ${ACCEPTED_IMAGE_FORMATS.join(
  ', ',
)}`;
export const MAX_SIZE_ERROR_MESSAGE = `Max. size: ${MAX_IMAGE_SIZE}MB`;
