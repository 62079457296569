import { LiteralUnion } from 'type-fest';

import { HorizontalAlignment, VerticalAlignment } from 'components/common/AlignmentInput/types';
import { OverlaysFontFamilyOption } from 'constants/fonts';
import { MomentI, PageInfo, SocialMediaI, VideoI } from 'models';
import { Optional } from 'utils/types';

export enum IconOptions {
  CART_PLUS = 'CART_PLUS',
  ARROW_RIGHT = 'ARROW_RIGHT',
  PLAY = 'PLAY',
  SHARE_ARROW = 'SHARE_ARROW',
  SHARE_BG = 'SHARE_BG',
}

export enum OrientationOptions {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum CardSizeOptions {
  FULLSCREEN = 'FULLSCREEN',
  XXL = 'XXL',
  XL = 'XL',
  L = 'L',
  M = 'M',
  S = 'S',
}

export enum EndCardType {
  GUI_BUILDER = 'GUI_BUILDER',
  CUSTOM_JS = 'CUSTOM_JS',
}

export interface CustomCodeEndCardFormData {
  name: string;
  customCode: string;
}

export interface EndCardFormData {
  name: string;
  cardOrientation: string;
  cardSize: string;
  cardType?: string;
  overlay: {
    color: string;
    opacity: string;
  };
  background: {
    color: string;
    opacity: string;
  };
  backgroundImageSettings: {
    size: string;
    top: string;
    left: string;
    horizontal: HorizontalAlignment;
    vertical: VerticalAlignment;
  };
  border: {
    width: string;
    color: string;
    radius: string;
  };
  videoControlButtons: {
    iconsColor: string;
    backgroundColor: string;
    backgroundOpacity: string;
  };
  headline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  subline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  promoCodeButton: EndCardCopyTextButtonInput | null;
  backgroundImageUrl: string;
  isPromoCode?: boolean;
  promoCodeText?: string;
  promoCode?: string;
  watchNextButtonVisible: boolean;
  socialMedia: SocialMediaI[];
  ctaButton1: string;
  ctaButton2: string;
}

export interface EndCardLinkButtonInput {
  label: string;
  icon?: IconOptions;
  url: string;
}

export interface EndCardCopyTextButtonInput {
  label: string;
  text: string;
}

export interface CreateEndCardInput {
  name: string;
  customCode?: string;
  cardOrientation?: string;
  cardSize?: string;
  overlay?: {
    color: string;
    opacity: string;
  };
  background?: {
    color: string;
    opacity: string;
  };
  backgroundImageSettings?: {
    size: string;
    top: string;
    left: string;
    horizontal: HorizontalAlignment;
    vertical: VerticalAlignment;
  };
  border?: {
    width: string;
    color: string;
    radius: string;
  };
  videoControlButtons?: {
    iconsColor: string;
    backgroundColor: string;
    backgroundOpacity: string;
  };
  headline?: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  subline?: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  ctaButton1?: string | null;
  ctaButton2?: string | null;
  backgroundImageBase64?: string;
  callToActionButton?: EndCardLinkButtonInput | null;
  promoCodeButton?: EndCardCopyTextButtonInput | null;
  watchNextButtonVisible?: boolean;
  backgroundImageEnabled?: boolean;
}

export interface EndCardResponse {
  id: string;
  name: string;
  customCode: string;
  cardType: 'CUSTOM' | 'LEGACY';
  cardOrientation: OrientationOptions.HORIZONTAL | OrientationOptions.VERTICAL;
  overlay: string;
  background: string;
  backgroundImageSettings: string;
  border: string;
  videoControlButtons: string;
  headline: string;
  subline: string;
  origin: EndCardType;
  backgroundImageUrl: string;
  cardSize: string;
  promoCodeButton?: EndCardCopyTextButtonInput;
  watchNextButtonVisible: boolean;
  videosCount: number;
  momentsCount: number;
  created: string;
  videos: VideoI[];
  moments: MomentI[];
}

export interface CustomCodeEndCardData {
  name: string;
  customCode: string;
}

export interface EndCardData {
  id: string;
  name: string;
  cardOrientation: OrientationOptions.HORIZONTAL | OrientationOptions.VERTICAL;
  overlay: {
    color: string;
    opacity: string;
  };
  cardType?: string;
  background: {
    color: string;
    opacity: string;
  };
  backgroundImageSettings: {
    size: string;
    top: string;
    left: string;
    horizontal: HorizontalAlignment;
    vertical: VerticalAlignment;
  };
  border: {
    width: string;
    color: string;
    radius: string;
  };
  videoControlButtons: {
    iconsColor: string;
    backgroundColor: string;
    backgroundOpacity: string;
  };
  headline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  subline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  ctaButton1?: {
    id: string;
    name: string;
  };
  ctaButton2?: {
    id: string;
    name: string;
  };
  backgroundImageUrl: string;
  cardSize: string;
  promoCodeButton?: EndCardCopyTextButtonInput;
  watchNextButtonVisible: boolean;
  videosCount: number;
  momentsCount: number;
  created: string;
  videos: VideoI[];
  moments: MomentI[];
}

export interface EndCardsResponse {
  nodes: EndCardResponse[];
  pageInfo: Optional<PageInfo>;
  totalCount: number;
}

export interface EditCustomCodeEndCardProps {
  isLoading?: boolean;
  endCard?: CustomCodeEndCardData;
  handleSave: (data: CustomCodeEndCardFormData) => void;
}

export interface EditEndCardProps {
  isLoading?: boolean;
  endCard?: EndCardData;
  handleSave: (
    data: EndCardFormData,
    hasImage: boolean,
    watchNextButtonVisible: boolean,
    showPromoCode: boolean,
  ) => void;
}

export interface CTAButtonProps {
  title: string;
  iconName?: IconOptions;
  actionUrl: string;
}

export interface PromoCodeProps {
  code: string;
  text: string;
  onCopy: () => void;
}

/**
 * Order string can be one (or a combination, joined with `,`) of given values.
 *
 * `column` -> ascending,
 * `-column` -> descending
 */

export type EndCardsOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'created'
  | '-created'
  | 'name'
  | '-name'
  | 'momentsCount'
  | '-momentsCount'
  | 'videosCount'
  | '-videosCount'
  | 'origin'
  | '-origin',
  string
>;
