import React from 'react';

export const PrivacyPolicyText: React.VFC = () => {
  return (
    <div>
      <h1>SEEEN PRIVACY POLICY</h1>
      Last Modified: December 14, 2020
      <ol>
        <li>
          <strong>Introduction</strong>
          Seeen, Inc. (“Seeen”) is dedicated to protecting your personal information and informing
          you about how we use your personal information. This privacy policy applies to your use of
          the Seeen Services and Platform (as defined by the Subscriber Agreement and Terms of Use).
          This Privacy Policy should be read in conjunction with the Subscriber Agreement and Terms
          of Use and is integrated into the Subscriber Agreement and Terms of Use. All capitalized
          proper nouns not defined in this Privacy Policy will have the same definitions and
          meanings as defined by the Subscriber Agreement and Terms of Use. Please review this
          Privacy Policy periodically as we may revise it from time to time. If you do not agree
          with or accept our Privacy Policy in its entirety, you must not access or use the
          Platform. If you use the Platform following a change to the terms of this Privacy Policy
          you agree to accept the revised policies.
        </li>

        <li>
          <strong>Information Collected</strong>
          At Seeen, we collect personally identifiable information (“PII”) and non-personally
          identifiable (“Non-PII”) information from you. Personally identifiable information is
          information that can be used to identify you personally. Non-personally identifiable
          information is information that must be combined with other information to identify you
          personally.
          <strong className="underline">PII Collected</strong>
          You will not be required to provide us any information when you visit our Platform.
          However, in order to access or use some portions of the Platform, we may collect PII such
          as username, payment details, log in information, name, address, business information. We
          may also collect your relevant payment or credit card information if you wish to pay for
          any services offered via the Platform. Please be aware that all payment information shall
          be stored and processed by our third party payment processors.
          <strong className="underline">Non-PII Collected</strong>
          Whenever you use our website, we may collect Non-PII from you, such as your IP address,
          zip code, browsing history, search history, and registration history, interactions with
          the Platform, usage information, location, referring URL, browser, operating system, data
          usage, data transferred, and Internet service provider.
        </li>

        <li>
          <strong>Use of Your Information</strong>
          Some of your information will be visible to other users of the Platform to facilitate
          communication. We will never sell your information without your permission; however you
          agree that we may use your information in the following ways:
          <ul>
            <li>To provide any services offered and to operate Seeen Platform.</li>
            <li>To enhance or improve our users’ experiences.</li>
            <li>
              To to contact you via email or other electronic communications where you have an
              inquiry.
            </li>
            <li>To notify you of additional Seeen services and updates.</li>
            <li>To process your transactions.</li>
            <li>
              To share your information with third party partners or third parties hired by us to
              perform functions and provide services to us subject to the obligations consistent
              with this Privacy Policy
            </li>
          </ul>
        </li>

        <li>
          <strong>Anonymized Data</strong>
          Please be aware that we me may collect and aggregate personally identifiable information
          from our Platform and may anonymize that information for our own research or internal
          purposes. Once such data has been anonymized, it cannot be traced back to you, the user.
        </li>

        <li>
          <strong>Accessing and Editing Your Information</strong>
          You will be able to access any information contained in your account through our Platform.
          You may edit that information by changing the information listed in your account. If you
          have any questions or wish to review, change, or access any of your information collected
          by us, please contact us at support@seeen.com. After you have cancelled your account
          please be aware that we may keep inaccessible copies of your PII and non-PII subject to
          our data retention policies.
        </li>

        <li>
          <strong>Cookies and Tracking</strong>
          We use cookies for to understand how you use our Platform. Additionally, some third party
          cookies integrated into our Platform may track your usage information and interactions
          with the Platform. If you disable cookies from your web browser some portions of our
          Platform may not work.
        </li>

        <li>
          <strong>Third Party Access to Your Information</strong>
          Although you are entering into an agreement with Seeen to disclose your information to us,
          we do use third party individuals and organizations to assist us, including contractors,
          web hosts, and others to allow you to access the Platform. Throughout the course of our
          provision of our services to you, we may delegate our authority to collect, access, use,
          and disseminate your information. It is therefore necessary that you grant the third
          parties we may use in the course of our business the same rights that you afford us under
          this Privacy Policy. For this reason, you hereby agree that for every authorization which
          you grant to us in this Privacy Policy, you also grant to any third party that we may
          hire, contract, or otherwise retain the services of for the purpose of operating,
          maintaining, repairing, or otherwise improving or preserving our website or its underlying
          files or systems. You agree not to hold us liable for the actions of any of these third
          parties, even if we would normally be held vicariously liable for their actions, and that
          you must take legal action against them directly should they commit any tort or other
          actionable wrong against you.
        </li>

        <li>
          <strong>Law Enforcement</strong>
          You agree that we may disclose your information to authorities if compelled to by a court
          order. Additionally, you agree that we may disclose your information if we reasonably
          believe that you have violated US laws, the terms of our Subscriber Agreement and Terms of
          Use or our Privacy Policy, or if we believe that a third party is at risk of bodily or
          economic harm. In the event that we receive a subpoena affecting your privacy, we may
          elect to notify you to give you an opportunity to file a motion to quash the subpoena, or
          we may attempt to quash it ourselves, but we are not obligated to do either. We may also
          proactively report you and release your information without receiving any request to third
          parties where we believe that it is proper to do so for legal reasons, where your actions
          violate any law of the United States or any other country having jurisdiction over us, our
          Platform, or our Subscriber Agreement and Terms of Use. You release us from any damages
          that may arise from or relate to the release of your information to a request from law
          enforcement agencies or private litigants. We may release your information under the
          conditions listed in this paragraph whether it is to individuals or entities and to any
          state or federal authorities, as required.
        </li>

        <li>
          <strong>Opt Out of Commercial, Non-Commercial Communications and Do Not Track</strong>
          If you decide to provide us with your contact information, you agree that we may send you
          communications via text and emails. However, you may unsubscribe from certain
          communications by notifying Seeen that you no longer wish to receive these communications,
          we will endeavour to promptly remove you from our once we have received that request. We
          currently do not offer functionality for you to opt out through “do not track” listings.
          If you wish to opt out of certain communications or information collection, please contact
          us at support@seeen.com.
        </li>

        <li>
          <strong>Third Parties</strong>
          Seeen or other users may post links to third party websites on Platform, which may include
          information that we have no control over. When accessing a third party site through our
          Platform, you acknowledge that you are aware that these third party websites are not
          screened for privacy or security issues by us, and you release us from any liability for
          the conduct of these third party websites. Please be aware that this Privacy Policy, and
          any other policies in place, in addition to any amendments, does not create rights
          enforceable by third parties. Seeen bears no responsibility for the information collected
          or used by any advertiser or third party website. You must review their policies to
          understand how their information collection practices work.
        </li>

        <li>
          <strong>Security Measures</strong>
          We make reasonable attempts to protect your information by using physical and electronic
          safeguards including encryption. However, as this is the Internet, we can make no
          guarantees as to the security or privacy of your information. For this reason, we
          recommend that you use anti-virus software, routine credit checks, firewalls, and other
          precautions to protect yourself from security and privacy threats.
        </li>

        <li>
          <strong>Your California Privacy Rights</strong>
          Seeen permits residents of the State of California to use its Platform, and complies with
          the California Business and Professions Code §§ 22575-22579. If you are a California
          resident you may request certain information regarding our disclosure of personal
          information to any third parties for their direct marketing purposes. Various provisions
          throughout this Privacy Policy address requirements of the Californian privacy statutes.
          Although we do not disseminate your information to third parties without permission, you
          must presume that we collect electronic information from all visitors. You may contact us
          at support@seeen.com with any questions.
        </li>

        <li>
          <strong>Age Compliance</strong>
          We intend to fully comply with American and international laws respecting children’s
          privacy including COPPA. Therefore, we do not collect or process any information for any
          persons under the age of 18. If you are under 18 and using our Platform, please stop
          immediately and do not submit any information to us. In the event that we have
          inadvertently collected any information from users under the age of 18 please contact us
          immediately.
        </li>

        <li>
          <strong>International Transfer</strong>
          Your information may be transferred to - and maintained on - computers located outside of
          your state, province, country, or other governmental jurisdiction where the privacy laws
          may not be as protective as those in your jurisdiction. Your consent to this Privacy
          Policy followed by your submission of such information represents your agreement to that
          transfer. PII and Non-PII that is submitted to Seeen will be collected, processed, stored,
          disclosed and disposed of in accordance with applicable U.S. law and this policy. If you
          are a non-U.S. member, you acknowledge and agree that Seeen may collect and use your
          Information and disclose it to other entities outside your resident jurisdiction. In
          addition, such information may be stored on servers located outside your resident
          jurisdiction. U.S. law may not provide the degree of protection for information that is
          available in other countries.
        </li>

        <li>
          <strong>Merger and Acquisition</strong>
          In the event that Seeen is involved in a bankruptcy, merger, acquisition, reorganization
          or sale of assets, your information may be sold or transferred as part of that
          transaction. Please be aware that once the information is transferred your privacy rights
          may change.
        </li>

        <li>
          <strong>Amendments</strong>
          Like our Subscriber Agreement and Terms of Use, we may amend this Privacy Policy from time
          to time. When we amend this Privacy Policy, we will modify the date listed on this Privacy
          Policy or we may contact you. You must agree to the amendments as a condition of your
          continued use of our Platform. If you do not agree, you must immediately cease using our
          Platform and notify us of your refusal to agree by e-mailing us at support@seeen.com.
        </li>

        <li>
          <strong>Privacy Notice for European Citizens</strong>
          We respect the rights of persons living within the European Economic Community (EEC) and
          the rights afforded to them under the General Data Protection Regulation (GDPR), the
          sections 17-23 are referred to as our Privacy Notice and address additional privileges
          that EEC users may have under our Privacy Policy. This Privacy Notice explains how we
          shall assist our users who live within the EEC.
          <table>
            <tr>
              <th style={{ width: '25%' }}>Legal Rights</th>
              <th>Your Rights Under the GDPR</th>
            </tr>
            <tr>
              <td>The right to be informed</td>
              <td>
                Seeen wishes to keep you informed as to what we do with your personal information.
                We strive to be transparent about how we use your data.
              </td>
            </tr>
            <tr>
              <td>The right to access</td>
              <td>You have the right to access your information at any time.</td>
            </tr>
            <tr>
              <td>The right to rectification</td>
              <td>
                If the information Seeen holds about you is inaccurate or not complete, you have the
                right to ask us to rectify it. If that data has been passed to a third party with
                your consent or for legal reasons, then we must also ask them to rectify the data.
              </td>
            </tr>

            <tr></tr>
            <tr>
              <td>The right to erasure</td>
              <td>
                Sometimes called ‘the right to be forgotten’. You have the right to request that
                Seeen to erase all your PII.
              </td>
            </tr>
            <tr>
              <td>The right to restrict processing</td>
              <td>
                You have the right to ask Seeen to restrict how we process your data. This means we
                are permitted to store the data but not further process it. We will only keep enough
                data to ensure that we can accommodate any additional requests.
              </td>
            </tr>
            <tr>
              <td>The right to data portability</td>
              <td>
                Seeen must allow you to port and reuse your PII for your own purposes across
                different platforms. This right only applies to PII that you have provided to us as
                a data controller.
              </td>
            </tr>
            <tr>
              <td>The right to object</td>
              <td>
                You have the right to object to Seeen processing your data even if our processing is
                due to legitimate purposes as described in our Privacy Notice
              </td>
            </tr>
            <tr>
              <td>The right to withdraw consent</td>
              <td>
                If you have given us your consent to process your data but change your mind later,
                you have the right to withdraw your consent at any time, and Seeen must stop
                processing your data.
              </td>
            </tr>
          </table>
          If you wish to exercise any of these rights or have questions about any of these rights
          please contact us at the information listed below.
        </li>

        <li>
          <strong>Legitimate Purposes for Collecting Your PII</strong>
          For the purposes of this Privacy Notice “personal data” as defined under the GDPR shall
          mean PII and Non-PII as defined in this Privacy Policy. The following are the specific
          legitimate purposes that we may use your personal data for:
          <ul>
            <li>
              Contract Administration - We may use your personal data to (1) negotiate, execute,
              renew and/or manage a contract with you; and/or (2) communicate with you in respect of
              the above (including sending (legal) notifications). (Performance of Contract)
            </li>

            <li>
              Access and Communications to Our Platform – (1) interact with you through our
              Platform; (2) communicate with you regarding updates or in response to inquiries;
              and/or (3) manage and respond to your questions or comments (e.g. technical,
              commercial or administrative) or requests for maintenance and support. (Legitimate
              Interest)
            </li>

            <li>
              Use of the Platform - We may use your personal data to (1) enable you to enjoy the use
              of, and easily navigate the Platform; (2) to understand how our Platform functions
              with you; and (3) to share with third parties we hire to maintain our Platform
              (Legitimate Interest)
            </li>

            <li>
              Direct Marketing - We may use your personal data to contact you for additional
              products and services that you have expressed interest in. (Consent)
            </li>

            <li>
              Training and Improvements - We may use your PII to (1) train our employees or
              contractors to allow for a better Platform experience; and/or (2) improve the
              Platform. (Legitimate Interest)
            </li>
          </ul>
          Please be aware that all legitimate purposes will be taken with minimal amounts of
          additional processing. Aside from the purposes listed, we may share your information where
          investigations or a legal dispute has occurred in accordance with our Privacy Policy
          (Legal Obligation).
        </li>

        <li>
          <strong>Basis for Data Processing</strong>
          Your personal data is processed by Seeen manually and/or using our Platform tools and
          third party providers as described in this Notice. Seeen shall process such personal data
          for specific purposes and only where there is a specific legal basis for doing as
          described above in Section 18.
        </li>

        <li>
          <strong>Retention of Personal Information</strong>
          Seeen will only retain your personal data for as long as required and proportionate to the
          use of your personal data. We will keep your personal data:
          <ul>
            <li>For any legally required duration.</li>

            <li>
              Upon your request to eliminate, delete, or modify any of your personal data stored
              with us, we will honor such legitimate request.
            </li>

            <li>Until we no longer have a valid reason to keep or use your personal data.</li>
          </ul>
          Where you have requested modification or deletion of your personal data, we may keep just
          enough of your personal information to ensure that we comply with your requests not use
          your personal information or comply with your right to erasure subject to any legal
          obligations or legitimate interests. If you require additional details regarding the
          retention of your personal data please contact us.
        </li>

        <li>
          <strong>Security and Transfer of Personal Data Outside of the EEC</strong>
          Your personal data is subject to the security provisions found within this Privacy Policy
          and the security measures we take are in line with industry standards. When you submit
          personal data to us it may be transferred and stored in the United States. Seeen shall
          ensure that your personal data shall have an adequate level of protection and that your
          information will be accessible as stated under the Privacy Notice.
        </li>

        <li>
          <strong>Sharing of Data with Third Parties</strong>
          We may disclose your personal information to a third party as described below subject to
          the legitimate purpose as specified within the Privacy Notice. We have entered into
          agreements (including but not limited to Data Protection Agreements) with all third
          parties that we disclose your personal information to and they are prohibited from selling
          such personal information. We share your personal data with the following third parties:
          <table>
            <tr>
              <th>Data Processor</th>
              <th>Purpose of Sharing</th>
              <th>Privacy Policy</th>
            </tr>
            <tr>
              <td>Google</td>
              <td>Use of the Platform; Training and Improvements; Analytics</td>
              <td>https://policies.google.com/privacy</td>
            </tr>
          </table>
          All Data Processors have entered into binding agreements with us that respect your rights
          under the GDPR. We use cookies via Google that are more fully described within their
          Privacy Policy
        </li>

        <li>
          <strong>Data Controller Information</strong>
          If you are an EEC resident and you have any questions regarding your rights under this
          Notice or wish to exercise any rights as enumerated under the GDPR, please contact us at:
          support@seeen.com or
          <br />
          Seeen, Inc. – Data Privacy Officer
          <br />
          199 Whitney Ave
          <br />
          New Haven, Connecticut 06511
          <br />
          United States of America.
          <br />
        </li>
      </ol>
      Please label any email communications with the title “GDPR Request”.
    </div>
  );
};
