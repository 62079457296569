import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, Microsite } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation DeleteMicrositeFavicon($id: ID!) {
    deleteMicrositeFavicon(id: $id) {
      microsite {
        faviconUrl
        id
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  deleteMicrositeFavicon: Nullable<{
    microsite: Nullable<Pick<Microsite, 'faviconUrl' | 'id'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteMicrositeFaviconOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useDeleteMicrositeFavicon = (options?: UseDeleteMicrositeFaviconOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'deleteMicrositeFavicon.errors',
    ...options,
  });
};
