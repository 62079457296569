import { QUERY_VIDEO_STATUS, TOGGLE_OBJECT_MUTATION } from 'context/queries';
import { ErrorType, ObjectI, VideoI } from 'models';
import { Nullable } from 'utils/types';

import { ToggleAction, useToggleMutation } from './_useToggleMutation';

interface ResponseData {
  toggleObjectAnnotation: {
    objectAnnotation: Nullable<Pick<ObjectI, 'id' | 'visible'> & { video: Pick<VideoI, 'id'> }>;
    errors: ErrorType[];
  };
}

export function useToggleObject() {
  return useToggleMutation<ResponseData>(TOGGLE_OBJECT_MUTATION, {
    pathToErrors: 'toggleObjectAnnotation.errors',
    optimisticResponse: ({ id, videoId, action }) => ({
      toggleObjectAnnotation: {
        objectAnnotation: {
          id,
          video: {
            id: videoId,
            __typename: 'VideoType',
          },
          visible: action === ToggleAction.enable,
          __typename: 'ObjectAnnotationType',
        },
        errors: [],
        __typename: 'ToggleObjectAnnotationMutation',
      },
    }),
    refetchQueries: ({ data }) => {
      const videoId = (data as ResponseData)?.toggleObjectAnnotation.objectAnnotation?.video.id;
      return videoId ? [{ query: QUERY_VIDEO_STATUS, variables: { id: videoId } }] : [];
    },
  });
}
