import { Box } from '@material-ui/core';
import React from 'react';

import { ProgressBar } from 'components/common';
import { useUppyProgress } from 'context/uppy';
import { DashboardVideoNode, VideoOrigin, VideoStatus } from 'models';
import { generateVideoLink } from 'pages/urls';
import { isVideoInUploadState } from 'utils/videos';

import { TitleCell } from '../TitleCell';
import { colorMap } from './VideoStatusBadge';

import * as StyledTable from 'components/common/Table/Table.styles';
import * as Styled from '../DashboardTable.styles';

interface ProgressCellProps {
  video: DashboardVideoNode;
}

const UppyProgressTitleCell: React.FC<ProgressCellProps> = ({ video }) => {
  const value = useUppyProgress(video.id);
  const variant = video.origin === VideoOrigin.Direct ? 'determinate' : undefined;
  return (
    <Styled.TitleCell>
      <StyledTable.Thumbnail />
      <Styled.EllipsisParent>
        <Styled.NonDisappearingContent>
          <Styled.EllipsisContent>
            <StyledTable.MomentTitle>{video.title}</StyledTable.MomentTitle>
            <Box mt="4px">
              <ProgressBar
                variant={variant}
                value={value || 0}
                barColor={colorMap[video.status].color}
              />
            </Box>
          </Styled.EllipsisContent>
        </Styled.NonDisappearingContent>
      </Styled.EllipsisParent>
    </Styled.TitleCell>
  );
};

interface TitleCellProps {
  video: DashboardVideoNode;
}

export const VideoTitleCell: React.FC<TitleCellProps> = ({ video }) => {
  if (isVideoInUploadState(video.status)) {
    return <UppyProgressTitleCell video={video} />;
  }

  const videoPageUrl = generateVideoLink(video.id);

  return (
    <TitleCell
      title={video.title}
      description={
        video.status === VideoStatus.Processing ? (
          <Box mt="4px">
            <ProgressBar
              variant="determinate"
              value={video.processingProgress || 0}
              barColor={colorMap[video.status].color}
            />
          </Box>
        ) : (
          video.description
        )
      }
      link={videoPageUrl}
      thumbnailUrl={video.thumbnailUrl}
    />
  );
};
