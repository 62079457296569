import { Box, Button, Checkbox, IconButton, Tooltip } from '@material-ui/core';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Play } from 'assets/icons/overlays/play-circle.svg';
import { ReactComponent as Pause } from 'assets/icons/pause-circle.svg';
import thumbnailPlaceholder from 'assets/images/thumbnail-placeholder.jpg';
import { FormLabel, TextInput } from 'components/common';
import MemoToggle from 'components/common/Table/MemoToggle';
import { PublishableWidgetEmbed } from 'components/common/WidgetEmbedCode';
import { DeleteAction } from 'components/tables/TableActionCell';
import { AddToPlaylistAction } from 'components/tables/TableActionCell/AddToPlaylistAction';
import { ShareAction } from 'components/tables/TableActionCell/ShareAction';
import { TimestampInput } from 'components/TimelineEditor/TimestampInput';
import { MIN_TIMESTAMP_LENGTH } from 'config/constants';
import { Permission } from 'config/permissions';
import { useCreateMoment, useUpdateMoment } from 'hooks/mutation';
import { ToggleMutateOptions } from 'hooks/mutation/_useToggleMutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useTimestampInputValidation } from 'hooks/useTimestampInputValidation';
import { VideoMomentNode } from 'models';
import { PlaylistContentType } from 'models/Playlist';
import { TimestampsTypes } from 'utils/overlapping';
import { hmsStringToMs, msToHMSString, timeRangeToHMSStrings } from 'utils/time';
import { Nullable } from 'utils/types';
import { isNotEmptyString, validate } from 'utils/validation';
import { canVideoBePublished } from 'utils/videos';

import { InitialMomentData, useMomentsEditorContext } from './context';
import * as Styled from './styled';
import { useDownload } from './useDownload';

interface MomentsSidebarSingleTypes {
  isSelected: boolean;
  moment?: VideoMomentNode;
  canUserChangeMoment?: boolean;
  canUserDeleteMoment?: boolean;
  deleteMoment?: (variables: any) => Promise<any>;
  handleCheckboxClick?: (id: string) => void;
  videoId: string;
  handleCreateCompleted?: () => void;
  newMomentStyle?: boolean;
  videoDuration: Nullable<number>;
  disableAutoSave?: boolean;
  formId?: string;
  widgetApiKey?: string;
  toggleMoment?: (options: ToggleMutateOptions) => Promise<any>;
  setSharedMoment?: (moment: Nullable<VideoMomentNode>) => void;
  allMomentsTimestamps: Array<TimestampsTypes>;
  isLastSelected: boolean;
  setBulkPosition: (position: number) => void;
  initialMomentData?: InitialMomentData;
  isActive?: boolean;
}

interface FormData {
  title: string;
  startTimestamp: string;
  endTimestamp: string;
}

enum InputValidationError {
  InvalidFormat,
  RangeOutOfBoundaries,
  EndSmaller,
  RangeTooSmall,
  Overlapping,
  Other,
}

export const MomentsSidebarSingle: React.FC<MomentsSidebarSingleTypes> = ({
  isSelected,
  moment,
  canUserChangeMoment,
  deleteMoment,
  handleCheckboxClick,
  canUserDeleteMoment,
  videoId,
  handleCreateCompleted,
  newMomentStyle,
  videoDuration,
  disableAutoSave = false,
  formId = 'moment-editor',
  widgetApiKey,
  setSharedMoment,
  toggleMoment,
  allMomentsTimestamps,
  isLastSelected,
  setBulkPosition,
  isActive,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentInputValue, setCurrentInputValue] = useState(''); // this is for watch if input has changed
  const {
    setShowCreateMoment,
    showCreateMoment,
    selectedMomentId,
    handleSetChangesSaved,
    changesSaved,
    videoStatus,
    handleMomentClick,
    isSelectingMoment,
    refreshValidation,
    playedDuration,
    runRefreshValidation,
    resetInitialMomentData,
    initialMomentData,
    setInitialMomentData,
    playSelectedMoment,
    pauseSelectedMoment,
    isTranscription,
  } = useMomentsEditorContext();
  const singleMomentRef = useRef<HTMLLIElement>(null);

  const allTimestamps = allMomentsTimestamps.filter(
    (singleMoment) => singleMoment.id !== moment?.id,
  );
  const {
    error,
    errorMessage,
    range: momentRange,
    startTimestampOverlap,
    endTimestampOverlap,
    setError,
    validateInput,
    setRange: setMomentRange,
    getNextAvailableTimeSlotStartTime,
  } = useTimestampInputValidation({ videoDuration, allTimestamps });

  const { isDownloading, currentDownload, handleDownload } = useDownload();

  useEffect(() => {
    if (moment?.timeRange) {
      setMomentRange(timeRangeToHMSStrings(moment.timeRange));
    } else if (initialMomentData) {
      setMomentRange(
        timeRangeToHMSStrings([initialMomentData.startTimestamp, initialMomentData.endTimestamp]),
      );
    }
  }, [initialMomentData, moment?.timeRange, setMomentRange]);

  useEffect(() => {
    if (isLastSelected && singleMomentRef && singleMomentRef.current) {
      setBulkPosition(singleMomentRef.current.offsetTop + singleMomentRef.current.offsetHeight);
    }
  }, [isLastSelected, setBulkPosition]);

  useEffect(() => {
    if (initialMomentData) return;
    setMomentRange(
      timeRangeToHMSStrings(
        moment?.timeRange || [playedDuration, playedDuration + MIN_TIMESTAMP_LENGTH],
      ),
    );
  }, [initialMomentData, moment?.timeRange, playedDuration, setMomentRange]);

  useEffect(() => {
    const { error } = validateInput({
      newStart: momentRange[0] || msToHMSString(0),
      newEnd: momentRange[1] || msToHMSString(MIN_TIMESTAMP_LENGTH),
    });

    if (error) {
      setError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectingMoment, momentRange, showCreateMoment, refreshValidation]);

  // If start and end times are coming from Transcription generator do not suggest start and end times for a new moment
  useEffect(() => {
    if (isTranscription) return;
    if (!moment && allMomentsTimestamps.length > 0) {
      const suggestedStartTime = getNextAvailableTimeSlotStartTime(allMomentsTimestamps);
      setInitialMomentData({
        startTimestamp: suggestedStartTime,
        endTimestamp: suggestedStartTime + MIN_TIMESTAMP_LENGTH,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moment, allMomentsTimestamps.length, isTranscription]);

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const [updateMoment] = useUpdateMoment({
    onCompleted: () => {
      runRefreshValidation(!refreshValidation);
      if (!changesSaved) {
        handleSetChangesSaved(true);
      }
    },
  });
  const [createMoment, { loading: createMomentLoading }] = useCreateMoment(handleCreateCompleted);

  const handleUpdateTimestamps = async (startTimestamp: number, endTimestamp: number) => {
    if (!error && moment) {
      await updateMoment({
        variables: {
          id: moment.id,
          moment: {
            startTimestamp,
            endTimestamp,
          },
        },
      });
    }
  };

  const onSubmitCreate = async (data: FormData) => {
    if (moment) return;
    if (!error) {
      await createMoment({
        variables: {
          videoId,
          moment: {
            title: data.title.trim(),
            startTimestamp: hmsStringToMs(data.startTimestamp),
            endTimestamp: hmsStringToMs(data.endTimestamp),
          },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleUpdateTimestamps = useCallback(debounce(handleUpdateTimestamps, 1000), []);

  const onInputChange = (index: number) => (value: string, commit?: boolean) => {
    const newRange = momentRange.slice();
    newRange[index] = value;
    setMomentRange(newRange);
    setCurrentInputValue(value);
    if (commit) {
      onInputCommit(newRange);
    }
  };

  const onInputCommit = (range?: string[]) => {
    setError(null);

    const { newTimeRange, error } = validateInput(
      range ? { newStart: range[0], newEnd: range[1] } : {},
    );

    if (newTimeRange) {
      setMomentRange(timeRangeToHMSStrings(newTimeRange));
      debouncedHandleUpdateTimestamps(newTimeRange[0], newTimeRange[1]);
      return;
    }

    setError(error);
  };

  const { hasPermissions } = useUserPermissions();
  const canPublishVideo = hasPermissions([Permission.PublishVideo]);
  const canUserAddToPlaylist = hasPermissions([Permission.ChangePlaylist]);

  return (
    <Styled.Moment
      newMomentStyle={newMomentStyle || selectedMomentId === moment?.id}
      isLastSelected={isLastSelected}
      ref={singleMomentRef}
    >
      <Styled.MomentForm id={formId} onSubmit={handleSubmit(onSubmitCreate)}>
        <Styled.MomentHead>
          {handleCheckboxClick && !!moment && deleteMoment ? (
            <>
              <Styled.MomentCheckbox>
                <Checkbox
                  checked={isSelected}
                  onClick={() => handleCheckboxClick(moment.id)}
                  color="primary"
                  disabled={!canUserChangeMoment}
                />
              </Styled.MomentCheckbox>
              <Styled.MomentTitle>{moment?.title}</Styled.MomentTitle>
              <Styled.MomentTools>
                {!isActive ? (
                  <Tooltip title="Play current moment" arrow placement="top">
                    <IconButton
                      color="inherit"
                      edge="end"
                      onClick={() => playSelectedMoment(moment)}
                    >
                      <Play />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Stop current moment" arrow placement="top">
                    <IconButton
                      color="inherit"
                      edge="end"
                      onClick={() => pauseSelectedMoment(moment)}
                    >
                      <Styled.MomentPauseIcon>
                        <Pause />
                      </Styled.MomentPauseIcon>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Edit" arrow placement="top">
                  <IconButton
                    color="inherit"
                    edge="end"
                    onClick={() => handleMomentClick(moment.id)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                {canUserAddToPlaylist &&
                  moment.visible &&
                  canVideoBePublished(moment.video.status) && (
                    <AddToPlaylistAction
                      item={{
                        type: PlaylistContentType.Moments,
                        id: moment.id,
                        title: moment.title,
                        isPublished: moment.isPublished,
                        videoId: moment.video.id,
                      }}
                      edge="end"
                    />
                  )}

                {moment.visible && setSharedMoment ? (
                  <ShareAction onShareClick={() => setSharedMoment(moment)} edge="end" />
                ) : null}
                {moment.visible && videoStatus ? (
                  <PublishableWidgetEmbed
                    id={moment.id}
                    videoId={videoId}
                    type="moment"
                    videoStatus={videoStatus}
                    canUserPublishVideo={canPublishVideo}
                    apiKey={widgetApiKey}
                  >
                    {({ showModal }) => {
                      return (
                        <PublishableWidgetEmbed.TableAction showModal={showModal} edge="end" />
                      );
                    }}
                  </PublishableWidgetEmbed>
                ) : null}
                <Tooltip title="Download" arrow placement="top">
                  <IconButton
                    color="inherit"
                    edge="end"
                    disabled={!!error || (moment.id === currentDownload && isDownloading)}
                    onClick={() => handleDownload(moment)}
                  >
                    <Download />
                  </IconButton>
                </Tooltip>
                {canUserDeleteMoment && (
                  <DeleteAction
                    type="video"
                    onDelete={() => deleteMoment({ variables: { id: moment.id } })}
                    customModalTitle="Are you sure you want to remove this moment?"
                    customModalDescription="You will remove the video permanently and this action can't be reverted back."
                    edge="end"
                  />
                )}
              </Styled.MomentTools>
            </>
          ) : null}
        </Styled.MomentHead>
        <Styled.MomentInputs createMomentFields={!moment}>
          {moment ? (
            <div>
              <FormLabel>Thumbnail</FormLabel>
              <Styled.MomentThumbnail src={moment.thumbnailUrl || thumbnailPlaceholder} alt="" />
            </div>
          ) : (
            <TextInput
              label="Title"
              name="title"
              placeholder="Write a title for your moment…"
              ref={register({
                validate: validate(isNotEmptyString, 'Title cannot be empty'),
              })}
              error={!!errors.title}
              style={{ width: '165px' }}
              mb="0px"
              maxLength={256}
            />
          )}
          <TimestampInput
            name="startTimestamp"
            value={momentRange[0]}
            onChange={onInputChange(0)}
            onCommit={onInputCommit}
            label="Start"
            ref={disableAutoSave ? register() : undefined}
            disabled={createMomentLoading}
            isError={startTimestampOverlap}
          />
          <TimestampInput
            name="endTimestamp"
            value={momentRange[1]}
            onChange={onInputChange(1)}
            onCommit={onInputCommit}
            label="End"
            ref={disableAutoSave ? register() : undefined}
            disabled={createMomentLoading}
            isError={endTimestampOverlap}
          />
          {canUserChangeMoment && toggleMoment && moment && (
            <Box display="flex" flexDirection="column">
              <FormLabel>Visibility</FormLabel>
              <Box mt="10px" ml="-10px">
                <MemoToggle
                  id={moment.id}
                  videoId={moment.video.id}
                  checked={moment.visible}
                  onChange={toggleMoment}
                />
              </Box>
            </Box>
          )}
        </Styled.MomentInputs>
        {errorMessage && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
        {disableAutoSave ? (
          <Box display="flex" justifyContent="space-between" mt="12px" mb="-8px">
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                setShowCreateMoment(false);
                resetInitialMomentData();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="text"
              color="primary"
              disabled={createMomentLoading || error === InputValidationError.RangeTooSmall}
            >
              {createMomentLoading ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        ) : null}
      </Styled.MomentForm>
    </Styled.Moment>
  );
};
