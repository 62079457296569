import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

import { OutlinedInput } from 'components/common/TextInput/TextInput.styles';

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
`;

export const Subtitle = styled.p`
  color: ${materialTheme.palette.grey[700]};
  font-size: 16px;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 400px;
  overflow-wrap: break-word;
`;

export const VideoLink = styled(Link)`
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  color: inherit;
`;

export const PlatformList = styled.ul`
  overflow-y: auto;
  max-height: 470px;
  padding: 0 10px;
`;

export const PlatformName = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const EditButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const PlatformDetails = styled.div`
  font-size: 14px;
`;

export const DetailsWrapper = styled.div`
  flex-grow: 1;
  text-align: left;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
`;

export const StatusText = styled.p`
  color: ${materialTheme.palette.text.primary};
  line-height: 1.5;
`;

export const ActionWrapper = styled(Center)`
  margin-top: 30px;
`;

export const Platform = styled.li<{ disabled?: boolean }>`
  background-color: ${materialTheme.palette.background.paper};
  border: 1px solid ${materialTheme.palette.secondary.light};
  border-radius: 10px;
  cursor: default;
  display: flex;
  margin-bottom: 10px;
  min-width: 300px;
  max-width: 420px;
  padding: 20px 20px 20px 10px;
  position: relative;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${materialTheme.palette.grey[500]};
    `}
`;

export const PageWrapper = styled.div<{ isInModal: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 50px 100px;
  justify-content: space-between;

  ${({ isInModal }) =>
    isInModal &&
    css`
      margin: 0 100px;
    `}

  ${OutlinedInput} {
    color: inherit;
    background-color: inherit;
  }
`;

export const ButtonLink = styled(Link)`
  padding: 0;
  border: 0;
  background-color: transparent;
  font-weight: 600;
  text-decoration: underline;
  color: #327dff;
  cursor: pointer;
`;
