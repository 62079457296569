import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const UploadsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
  gap: 18px;
`;

export const ImagePreviewWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
`;

export const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
`;
