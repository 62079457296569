import React from 'react';

import { WidgetEmbedModal } from '../WidgetEmbedModal';
import { EmbedButton, EmbedTableAction, PassedProps } from './EmbedButtons';

interface Props {
  type: 'playlist';
  id: string;
  apiKey?: string;
  children: (props: PassedProps) => React.ReactNode;
}

export function WidgetEmbed({ type, id, apiKey, children }: Props) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);

  if (!apiKey) {
    return null;
  }

  return (
    <>
      {children({ showModal })}
      <WidgetEmbedModal
        isOpen={isModalOpen}
        onClose={hideModal}
        type={type}
        id={id}
        apiKey={apiKey}
      />
    </>
  );
}

WidgetEmbed.Button = EmbedButton;
WidgetEmbed.TableAction = EmbedTableAction;
