import React from 'react';

import { Stack } from 'components/common/Wrapper/Layout';

import { YouTubeCategoryPicker } from './YouTubeCategoryPicker';
import { useYouTubeOptionsContext } from './YouTubeOptionsContext';

export function YouTubeOptions() {
  const {
    youtubeOptions: { category },
    setCategory,
  } = useYouTubeOptionsContext();

  return (
    <Stack>
      <YouTubeCategoryPicker selectedCategoryId={category} onChange={setCategory} />
    </Stack>
  );
}
