import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { useTangleMainBar } from 'context/mainBar';

import { SidePanel } from './SidePanel';

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px 35px;
  overflow: auto;
`;

interface OwnProps {
  sideContent: React.ReactNode;
  mainContent: React.ReactNode;
  rightContent?: React.ReactNode;
  moment?: boolean;
}

function ExtensibleSidebar({ sideContent, mainContent, rightContent, moment }: OwnProps) {
  useTangleMainBar(true);

  const [isLeftOpen, setIsLeftOpen] = useState(true);
  const [isRightOpen, setIsRightOpen] = useState(false);

  return (
    <Box display="flex" overflow="hidden" maxWidth="100%" width="100%" height="100%">
      <SidePanel
        side="left"
        isOpen={isLeftOpen}
        onToggle={() => {
          setIsLeftOpen((open) => !open);
        }}
      >
        {sideContent}
      </SidePanel>
      <MainContent>{mainContent}</MainContent>
      {rightContent ? (
        <SidePanel
          side="right"
          isOpen={isRightOpen}
          onToggle={() => {
            if (moment && !isRightOpen) {
              setIsRightOpen((open) => !open);
              setIsLeftOpen(() => false);
            } else {
              setIsRightOpen((open) => !open);
            }
          }}
        >
          {rightContent}
        </SidePanel>
      ) : null}
    </Box>
  );
}

export default ExtensibleSidebar;
