import React from 'react';
import { DropzoneOptions, DropzoneRootProps, useDropzone } from 'react-dropzone';

interface Props {
  id: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onDrop: (files: File[]) => void;
  dropzoneOptions?: DropzoneOptions;
  children: (rootProps: DropzoneRootProps) => React.ReactNode;
  'data-testid'?: string;
}

export const FileUpload: React.VFC<Props> = ({
  id,
  isDisabled,
  onDrop,
  dropzoneOptions,
  children,
  'data-testid': testId,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: isDisabled,
    ...dropzoneOptions,
  });

  return (
    <div>
      {children(getRootProps())}
      <input id={id} {...getInputProps()} data-testid={testId} />
    </div>
  );
};
