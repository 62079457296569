import { useEffect, useRef, useState } from 'react';

export type UseSizeProps = ({ width, height }: { width: number; height: number }) => void;

export const useElementSizeObservable = (
  getSize?: UseSizeProps,
): {
  width: number;
  height: number;
  elementRef: React.RefObject<HTMLDivElement>;
} => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new window.ResizeObserver((entries) => {
      const { clientWidth, clientHeight } = entries[0].target;
      setElementSize({ width: clientWidth, height: clientHeight });
      if (getSize) getSize({ width: clientWidth, height: clientHeight });
    });

    observer.observe(element);

    return () => {
      if (!element) return;
      observer.disconnect();
    };
  }, [getSize]);

  return { width: elementSize.width, height: elementSize.height, elementRef };
};
