import { ButtonBase } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

import imageThumbnail from 'assets/images/overlays-image-thumbnail.png';
import { materialTheme } from 'components/App/materialTheme';

interface ImageProps {
  url?: string;
}

export const UploadWrapper = styled.div`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  background-image: url(${imageThumbnail});
  border-radius: 10px;
  height: 85px;
  width: 85px;
  margin-right: 16px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.div<ImageProps>`
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const WarningMessage = styled.div<{ error?: boolean }>`
  color: ${({ error }) =>
    error ? materialTheme.palette.error.main : materialTheme.palette.text.secondary};
  font-size: 12px;
  margin-top: 8px;
`;
const iconWrapper = css`
  align-items: center;
  border-radius: 50%;
  border: none;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  position: absolute;
  width: 20px;
`;
export const RemoveThumbnailButton = styled(ButtonBase)`
  && {
    ${iconWrapper};
    position: absolute;
    top: 3px;
    right: 3px;
    background-color: ${materialTheme.palette.secondary.main};
    color: ${materialTheme.palette.common.white};
  }
`;
