import { QUERY_VIDEO } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { VideoDetailsNode } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  videoById: Nullable<VideoDetailsNode>;
}

interface Variables {
  id: string;
}

interface UseVideoQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useVideoQuery = (options: UseVideoQueryOptions) => {
  return useQuery<ResponseData, Variables>(QUERY_VIDEO, options);
};
