import { Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoadingButton } from 'components/common';
import { PasswordInput } from 'components/common/TextInput';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { MIN_PASSWORD_LENGTH } from 'config/constants';
import { TEST_ID } from 'config/test-ids';
import { URLS } from 'pages/urls';
import { isMinLength, isNotEmptyString, isNotNumericOnly, validate } from 'utils/validation';

export interface FormData {
  password: string;
}

interface Props {
  onSubmit: (data: FormData) => Promise<void>;
  isLoading: boolean;
}

export const ResetPasswordForm: React.FC<Props> = ({ onSubmit, isLoading }) => {
  const { register, errors, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
  });

  return (
    <form name="reset-password" onSubmit={handleSubmit(onSubmit)}>
      <Stack width="400px" pb="40px">
        <p>
          Password should be at least {MIN_PASSWORD_LENGTH} characters long, it cannot consist of
          numbers only, and be too similar to your email address.
        </p>
        <PasswordInput
          name="password"
          autoComplete="new-password"
          label="Password"
          placeholder="Type your new password here"
          ref={register({
            required: 'Required',
            validate: {
              minLength: validate(
                isMinLength(MIN_PASSWORD_LENGTH),
                `Minimum ${MIN_PASSWORD_LENGTH} characters required`,
              ),
              whitespace: validate(isNotEmptyString, 'Password cannot be empty'),
              numeric: validate(isNotNumericOnly, 'Password cannot be only numeric'),
            },
          })}
          errorMessage={errors.password?.message}
          disabled={isLoading}
          mb="0"
        />
      </Stack>
      <Columns display="flex" justifyContent="center">
        <Button variant="outlined" color="secondary" component={Link} to={URLS.root}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          isLoading={isLoading}
          data-testid={TEST_ID.resetPasswordButton}
        >
          Reset password
        </LoadingButton>
      </Columns>
    </form>
  );
};
