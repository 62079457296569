import { useApolloClient } from '@apollo/react-hooks';
import React from 'react';

import { POLL_VIDEO_STATUS } from 'context/queries';
import { usePolling } from 'hooks/usePolling';
import { VideoI } from 'models';
import { Nullable } from 'utils/types';
import { getVideosWithPolling } from 'utils/videos';

type VideoPick = Pick<VideoI, 'id' | 'status'>;

interface ResponseData {
  videoById: Nullable<VideoPick>;
}

interface Variables {
  id: string;
}

export function useVideoStatusPolling(videos: VideoPick[]) {
  const videosWithPolling = getVideosWithPolling(videos);
  const client = useApolloClient();
  const [isPolling, setIsPolling] = React.useState(false);
  const interval = React.useRef<number>();

  const startPolling = React.useCallback((pollInterval: number) => {
    interval.current = pollInterval;
    setIsPolling(true);
  }, []);

  const stopPolling = React.useCallback(() => {
    interval.current = undefined;
    setIsPolling(false);
  }, []);

  const query = React.useCallback(() => {
    const promises = videosWithPolling.map(({ id }) => {
      return client.query<ResponseData, Variables>({
        query: POLL_VIDEO_STATUS,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
        context: {
          isErrorHandledLocally: true,
        },
      });
    });

    return Promise.all(promises);
  }, [client, videosWithPolling]);

  // run the polling with interval
  React.useEffect(() => {
    if (!isPolling || !interval.current) {
      return;
    }
    const intervalId = setInterval(query, interval.current);
    return () => {
      clearInterval(intervalId);
    };
  }, [isPolling, query]);

  usePolling({
    isEnabled: videosWithPolling.length > 0,
    startPolling,
    stopPolling,
  });
}
