const RULER_GRADUATION_STEPS = [6, 3, 1.5, 0.5, -100];
const RULER_TEXT_INTERVAL = [5_000, 10_000, 30_000, 60_000, 120_000];
const RULER_MARK_INTERVAL = [1_000, 2_000, 5_000, 10_000, 25_000];

const getRulerGraduationRange = (stepWidth: number) =>
  RULER_GRADUATION_STEPS.findIndex((step: number) => stepWidth >= step);

export const getRulerMarkStep = (stepWidth: number) =>
  RULER_MARK_INTERVAL[getRulerGraduationRange(stepWidth)];

export const getRulerTextStep = (stepWidth: number) =>
  RULER_TEXT_INTERVAL[getRulerGraduationRange(stepWidth)];

const isMultipleOf5 = (n: number) => n % 5 === 0;
export const showTextOnMarker = (value: number, step: number) =>
  step === 1 ? isMultipleOf5(value) : value % step === 0;

export const getFirstMark = (val: number, step: number) =>
  val % step === 0 ? val : val + step - (val % step);
