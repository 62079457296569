import React, { createContext, ReactNode, useContext, useState } from 'react';

import { VideoLogoPositionType } from 'models';

interface OrganizationLogoPreviewContextType {
  orgLogoPosition: VideoLogoPositionType;
  setOrgLogoPosition: (position: VideoLogoPositionType) => void;
  isLogoEnabled: boolean;
  setIsLogoEnabled: (isEnabled: boolean) => void;
}

const OrganizationLogoPreviewContext = createContext<
  OrganizationLogoPreviewContextType | undefined
>(undefined);

export const OrganizationLogoPreviewProvider = ({ children }: { children: ReactNode }) => {
  const [orgLogoPosition, setOrgLogoPosition] = useState<VideoLogoPositionType>('BOTTOM_RIGHT');
  const [isLogoEnabled, setIsLogoEnabled] = useState<boolean>(false);

  return (
    <OrganizationLogoPreviewContext.Provider
      value={{ orgLogoPosition, setOrgLogoPosition, isLogoEnabled, setIsLogoEnabled }}
    >
      {children}
    </OrganizationLogoPreviewContext.Provider>
  );
};

export const useOrganizationLogoPreview = () => {
  const context = useContext(OrganizationLogoPreviewContext);
  if (!context) {
    throw new Error('useOrgLogoPosition must be used within an OrgLogoPositionProvider');
  }
  return context;
};
