import { Box } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { EmptyTabAddButton, EmptyTabTitle } from 'components/common/EmptyTab';

import { AddButton } from './MonetizationButtons/AddButton';
import { SetupButton } from './MonetizationButtons/SetupButton';
import { SetupModal } from './MonetizationModals/SetupModal';

export const MonetizationEmptyData: React.VFC = () => {
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsSetupModalOpen(false);
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p="30px 40px">
      <EmptyTabTitle>Add your ad tag URLs to enable monetization of your videos.</EmptyTabTitle>
      <EmptyTabAddButton>
        <AddButton isEmptyMonetization />
      </EmptyTabAddButton>
      <SetupButton setIsModalOpen={setIsSetupModalOpen} />
      <SetupModal isOpen={isSetupModalOpen} onClose={handleModalClose} />
    </Box>
  );
};
