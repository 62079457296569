import { pushMessage } from 'context/globalStream';
import { ThumbnailI } from 'models';

import { Nullable } from './types';

export function getThumbnailUrl(thumbnails: ThumbnailI[], id: Nullable<string>) {
  return id ? thumbnails.find((thumbnail) => thumbnail.id === id)?.imageUrl : undefined;
}

export function pushMaxThumbnailCountError(maxCount: number) {
  pushMessage({ message: `Max allowed uploaded thumbnail count is ${maxCount}` });
}
