import { useHistory, useLocation } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import { useCreateEndCard } from 'hooks/mutation/useCreateEndCard';
import { EditCustomCodeEndCardView } from 'pages/EndCard/EditCustomCodeEndCardView';
import { CustomCodeEndCardFormData } from 'pages/EndCard/types';
import { generateCustomCodeEndCardEditLink } from 'pages/urls';

export const NewCustomCodeEndCardPage = () => {
  const history = useHistory();
  const { state } = useLocation();

  const [createEndCard, { loading: isLoadingCreate }] = useCreateEndCard({
    onCompleted: (data) => {
      if (data?.createEndCard?.endCard?.id) {
        history.replace(generateCustomCodeEndCardEditLink(data.createEndCard.endCard.id), state);
      }
    },
  });

  const onSubmit = async (data: CustomCodeEndCardFormData) => {
    const { name, customCode } = data;

    await createEndCard({
      variables: {
        endCard: {
          name,
          customCode,
        },
      },
    });
  };

  return (
    <MainLayout>
      <EditCustomCodeEndCardView handleSave={onSubmit} isLoading={isLoadingCreate} />
    </MainLayout>
  );
};
