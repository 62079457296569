import gql from 'graphql-tag';

import { D2C_API_KEY_FRAGMENT } from 'context/queries';
import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation } from 'hooks/useMutation';
import { D2CApiKey } from 'models';
//import { ErrorType, OrganizationI } from 'models'; TODO: models
import { Nullable } from 'utils/types';

const QUERY = gql`
  ${D2C_API_KEY_FRAGMENT}
  
  mutation RevokeD2CApiKey($id: ID!) {
    revokeD2CApiKey(id: $id) {
      apiKey {
        ...D2CApiKeys
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  createD2CApiKey: Nullable<{
    apiKey: D2CApiKey;
  }>;
}

interface Variables {
  id: string;
}

export const useRevokeD2CApiKey = () => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'createD2CApiKey.errors',
    skipGlobalErrorHandling: true,
  });
};
