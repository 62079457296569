import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';

const initialSource = { width: 0, height: 0 };

export const playerSizeSource$ = new BehaviorSubject(initialSource);

export const usePlayerSize = (ref: React.RefObject<HTMLElement>) => {
  const [playerWidth, setPlayerWidth] = React.useState(0);
  const [playerHeight, setPlayerHeight] = React.useState(0);

  const resizeObserver = React.useRef(
    new window.ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      const height = entries[0].contentRect.height;
      setPlayerWidth(width);
      setPlayerHeight(height);
      playerSizeSource$.next({ width, height });
    }),
  );

  React.useEffect(() => {
    const element = ref.current;
    const observer = resizeObserver.current;
    if (element) {
      observer.observe(element);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref, resizeObserver]);

  return {
    height: playerHeight,
    width: playerWidth,
  };
};

export const usePlayerWidthObservable = () => {
  return useObservable(
    () =>
      playerSizeSource$.pipe(
        map(({ width }) => width),
        distinctUntilChanged((prevWidth, currentWidth) => prevWidth === currentWidth),
      ),
    0,
  );
};

export const usePlayerSizeObservable = () => {
  return useObservable(
    () =>
      playerSizeSource$.pipe(
        distinctUntilChanged(
          (prev, current) => prev.width === current.width && prev.height === current.height,
        ),
      ),
    initialSource,
  );
};
