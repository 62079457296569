import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { CONDENSED_FONT } from 'utils/styling';

interface PreviewTabProps {
  isActive: boolean;
}

export const PreviewCard = styled.div`
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
`;

export const PreviewCardHeader = styled.div`
  padding: 30px 25px;
  display: flex;
  align-items: center;
`;

export const PreviewCardLabel = styled.h2`
  font-size: 16px;
  font-weight: 400;
`;

export const PreviewCardTooltip = styled.span`
  margin-left: 8px;
  display: inline-flex;
`;

export const PreviewCardTabs = styled.ul`
  display: flex;
  margin-left: 40px;
`;

export const PreviewCardTab = styled.button<PreviewTabProps>`
  position: relative;
  margin-right: 30px;
  opacity: ${({ isActive }) => (isActive ? '1' : '.5')};
  font-size: 20px;
  font-weight: 700;
  font-family: ${CONDENSED_FONT};
  outline: none;

  ${({ isActive }) =>
    isActive
      ? css`
          &:before {
            position: absolute;
            content: '';
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: ${materialTheme.palette.primary.main};
          }
        `
      : null};
`;

export const PreviewLabel = styled.h2`
  font-size: 16px;
  padding-bottom: 10px;
`;

export const PreviewCardBody = styled(Box)`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${materialTheme.palette.secondary.main};
  max-width: 960px;
`;
