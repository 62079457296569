import styled from 'styled-components';

import { materialTheme } from 'components/App/materialTheme';

export const Wrapper = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

interface CheckmarkProps {
  active?: boolean;
}

export const Checkmark = styled.div<CheckmarkProps>`
  align-items: center;
  border-color: ${({ active }) =>
    active ? materialTheme.palette.text.primary : materialTheme.palette.text.secondary};
  border-radius: 50%;
  border: 2px solid;
  color: ${materialTheme.palette.text.secondary};
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin-right: 16px;
  width: 24px;
`;

interface ItemProps {
  active?: boolean;
}

export const Item = styled.li<ItemProps>`
  align-items: center;
  color: ${materialTheme.palette.text.secondary};
  display: flex;
  font-size: 14px;
  justify-content: center;
  position: relative;

  &:not(:first-child) {
    margin-left: 88px;

    ::before {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 40px;
      background-color: ${materialTheme.palette.background.default};
      left: -64px;
    }
  }

  ${({ active }) =>
    active &&
    `
    font-weight: bold;
    color: ${materialTheme.palette.text.primary};

    ${Checkmark} {
      color: ${materialTheme.palette.text.primary};
      border-color: ${materialTheme.palette.text.primary};
    }
  `}
`;
