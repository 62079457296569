import React from 'react';

import { msToHMSString } from 'utils/time';

import {
  Break,
  EndBreak,
  Line,
  LineText,
  Mark,
  MARKER_WIDTH,
  OverflowedText,
  TallLine,
} from './GraduationElements';
import { getFirstMark, getRulerMarkStep, getRulerTextStep, showTextOnMarker } from './helpers';

interface OwnProps {
  start: number;
  end: number;
  rulerWidth: number;
  videoDuration: number;
  rulerPadding: number;
}

const renderMark = (
  timestamp: number,
  textStep: number,
  stepWidth: number,
  markWidth: number,
  videoDuration: number,
) => {
  if (showTextOnMarker(timestamp, textStep)) {
    return (
      <Mark key={`${stepWidth}-${timestamp}`} width={markWidth}>
        {timestamp >= 0 && timestamp <= videoDuration && (
          <LineText>{msToHMSString(timestamp, { skipMilliseconds: true })}</LineText>
        )}
        <TallLine />
      </Mark>
    );
  } else {
    return (
      <Mark key={`${stepWidth}-${timestamp}`} width={markWidth}>
        <Line />
      </Mark>
    );
  }
};

const Graduation = React.memo(
  ({ start, end, rulerWidth, videoDuration, rulerPadding }: OwnProps) => {
    let rulerDom = [];

    const stepWidth = ((rulerWidth - MARKER_WIDTH) / (end - start)) * 1000;

    const textStep = getRulerTextStep(stepWidth);
    const markStep = getRulerMarkStep(stepWidth);
    const markWidth = (stepWidth * markStep) / 1000;

    // First mark rendered outside of padding area
    const firstMarkPosition = getFirstMark(start, markStep);
    const firstBreak = ((firstMarkPosition - start) * stepWidth) / 1000;

    const minPaddingMarksCount = Math.floor(rulerPadding / markWidth);
    let availableWidth = rulerWidth + 2 * rulerPadding;

    // First mark that may be in the padding area
    const firstPaddingMarkPosition = firstMarkPosition - (minPaddingMarksCount + 1) * markStep;
    const firstPaddingBreak = ((firstPaddingMarkPosition - start) * stepWidth) / 1000;

    const closestLabel = firstPaddingMarkPosition - (firstPaddingMarkPosition % textStep);
    const closestLabelBreak = ((closestLabel - start) * stepWidth) / 1000;

    if (closestLabelBreak < -rulerPadding) {
      rulerDom.push(
        <OverflowedText left={closestLabelBreak + rulerPadding} key={`label-${stepWidth}`}>
          {msToHMSString(closestLabel, { skipMilliseconds: true })}
        </OverflowedText>,
      );
    }

    if (firstPaddingBreak < -rulerPadding) {
      const fillerWidth = rulerPadding - minPaddingMarksCount * markWidth + firstBreak;
      rulerDom.push(<Break width={fillerWidth} key={`filler-${stepWidth}`} />);
      availableWidth -= fillerWidth;
    } else {
      rulerDom.push(<Break width={firstPaddingBreak + rulerPadding} key={`filler-${stepWidth}`} />);
      rulerDom.push(
        renderMark(firstPaddingMarkPosition, textStep, stepWidth, markWidth, videoDuration),
      );
      availableWidth -= firstPaddingBreak + rulerPadding + markWidth;
    }

    let pos = firstMarkPosition - minPaddingMarksCount * markStep;
    if (markWidth > 0) {
      while (availableWidth > markWidth) {
        rulerDom.push(renderMark(pos, textStep, stepWidth, markWidth, videoDuration));
        availableWidth -= markWidth;
        pos += markStep;
      }

      rulerDom.push(renderMark(pos, textStep, stepWidth, MARKER_WIDTH, videoDuration));
    }

    rulerDom.push(<EndBreak key={`last-${stepWidth}`} />);

    return <>{rulerDom}</>;
  },
);

export default Graduation;
