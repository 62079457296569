import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { zIndex } from 'utils/styling';

const baseButton = css`
  align-items: center;
  background: transparent;
  border: none;
  color: ${materialTheme.palette.common.white};
  cursor: pointer;
  display: flex;
  display: inline-block;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  user-select: none;

  :focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }
`;

export const ControlWrapper = styled.div`
  background-color: #1b1c1f;
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
`;

export const ButtonBox = styled.div`
  height: 100%;
  width: 46px;
`;

export const StopPlayButton = styled.button`
  ${baseButton};
  height: 100%;
  width: 100%;
`;

export const Timer = styled.div`
  margin: auto 0 auto 20px;
  font-size: 14px;
  letter-spacing: 0.32px;
  line-height: 21px;
  color: #ffffff;
`;

export const Fullscreen = styled.button`
  ${baseButton};
  height: 100%;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VolumeSlider = styled.div`
  background: #404040;
  border-radius: 4px;
  height: 120px;
  padding: 10px 0;
  position: absolute;
  top: -10px;
  transform: translateY(-100%);
  z-index: ${zIndex.player + 21};
`;

export const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  color: #fff;
  margin-left: auto;
`;
