import React, { createContext, useCallback, useContext, useState } from 'react';

import { DEFAULT_TIMESTAMP_LENGTH } from 'config/constants';
import { seekToMomentStart, source$, togglePlayback } from 'context/VideoMetadataStream';
import { useVideoStatusQuery } from 'hooks/query';
import { VideoMomentNode, VideoStatus } from 'models';

export interface InitialMomentData {
  startTimestamp: number;
  endTimestamp: number;
}

interface MomentsEditorContextTypes {
  handleMomentClick: (momentId: string) => void;
  selectedMomentId: string;
  setSelectedMomentId: (momentId: string) => void;
  showCreateMoment: boolean;
  setShowCreateMoment: (show: boolean) => void;
  initialMomentData: InitialMomentData;
  openNewMomentMenu: (data: InitialMomentData) => void;
  resetInitialMomentData: () => void;
  isInitialRender: boolean;
  setIsInitialRender: (is: boolean) => void;
  allowEditing: boolean;
  videoId: string;
  videoStatus: VideoStatus | undefined;
  isSelectingMoment: boolean;
  handleSetChangesSaved: (saved: boolean) => void;
  changesSaved: boolean;
  refreshValidation: boolean;
  playedDuration: number;
  setPlayedDuration: (duration: number) => void;
  runRefreshValidation: (run: boolean) => void;
  setInitialMomentData: (data: InitialMomentData) => void;
  currentMomentID: string;
  setCurrentMomentID: (id: string) => void;
  currentMomentEndTime: number;
  setCurrentMomentEndTime: (time: number) => void;
  currentMomentStartTime: number;
  setCurrentMomentStartTime: (time: number) => void;
  playSelectedMoment: (moment: VideoMomentNode) => void;
  pauseSelectedMoment: (moment: VideoMomentNode) => void;
  isTranscription: boolean;
  setIsTranscription: (is: boolean) => void;
}

interface MomentsEditorWrapperTypes {
  children: React.ReactNode;
  allowEditing: boolean;
  videoId: string;
}

export const MomentsEditorContext = createContext<MomentsEditorContextTypes | null>(null);

export const MomentsEditorWrapper: React.FC<MomentsEditorWrapperTypes> = ({
  children,
  allowEditing,
  videoId,
}) => {
  const { data: videoData } = useVideoStatusQuery({ variables: { id: videoId } });
  const videoStatus = videoData?.videoById?.status;
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isSelectingMoment, setIsSelectingMoment] = useState(false);
  const [selectedMomentId, setSelectedMomentId] = useState('');
  const [initialMomentData, setInitialMomentData] = useState<InitialMomentData>({
    startTimestamp: 0,
    endTimestamp: DEFAULT_TIMESTAMP_LENGTH,
  });
  const [isCreateMomentVisible, setIsCreateMomentVisible] = useState(false);
  const [changesSaved, setChangesSaved] = useState(videoStatus === VideoStatus.Edited);
  const [refreshValidation, runRefreshValidation] = useState(false);
  const [playedDuration, setPlayedDuration] = useState(0);
  const [currentMomentID, setCurrentMomentID] = useState('');
  const [currentMomentEndTime, setCurrentMomentEndTime] = useState(source$.getValue().duration);
  // eslint-disable-next-line
  const [currentMomentStartTime, setCurrentMomentStartTime] = useState(0);
  const [isTranscription, setIsTranscription] = useState(false);

  const handleMomentClick = (momentId: string) => {
    setIsSelectingMoment(true);
    setIsInitialRender(false);
    setSelectedMomentId(momentId);
    setTimeout(() => {
      setIsSelectingMoment(false);
    }, 500);
  };

  const handleSetChangesSaved = (saved: boolean) => {
    setChangesSaved(saved);
    runRefreshValidation(!refreshValidation);
  };

  const openNewMomentMenu = (data: InitialMomentData) => {
    setInitialMomentData(data);
    setIsCreateMomentVisible(true);
  };

  const resetInitialMomentData = useCallback(() => {
    setInitialMomentData({
      startTimestamp: 0,
      endTimestamp: DEFAULT_TIMESTAMP_LENGTH,
    });
  }, []);

  const playSelectedMoment = (moment: VideoMomentNode) => {
    if (setCurrentMomentStartTime) setCurrentMomentStartTime(moment.timeRange[0]);
    if (setCurrentMomentEndTime) setCurrentMomentEndTime(moment.timeRange[1]);

    if (setCurrentMomentID) {
      setCurrentMomentID(moment.id);
    }

    seekToMomentStart(moment.timeRange[0]);
    togglePlayback();
  };

  const pauseSelectedMoment = (moment: VideoMomentNode) => {
    seekToMomentStart(moment.timeRange[0] + 1);

    if (setCurrentMomentID) setCurrentMomentID('');
    togglePlayback();
  };

  return (
    <MomentsEditorContext.Provider
      value={{
        handleMomentClick,
        selectedMomentId,
        setSelectedMomentId,
        showCreateMoment: isCreateMomentVisible,
        setShowCreateMoment: setIsCreateMomentVisible,
        initialMomentData,
        openNewMomentMenu,
        resetInitialMomentData,
        setInitialMomentData,
        isInitialRender,
        setIsInitialRender,
        allowEditing,
        videoId,
        videoStatus,
        isSelectingMoment,
        changesSaved,
        handleSetChangesSaved,
        refreshValidation,
        playedDuration,
        setPlayedDuration,
        runRefreshValidation,
        currentMomentID,
        setCurrentMomentID,
        currentMomentEndTime,
        setCurrentMomentEndTime,
        currentMomentStartTime,
        setCurrentMomentStartTime,
        playSelectedMoment,
        pauseSelectedMoment,
        isTranscription,
        setIsTranscription,
      }}
    >
      {children}
    </MomentsEditorContext.Provider>
  );
};

export const useMomentsEditorContext = () => {
  const value = useContext(MomentsEditorContext);
  if (!value) {
    throw new Error('useMomentsEditorContext needs to be used within MomentsEditorContext tree');
  }
  return value;
};
