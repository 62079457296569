import { CREATE_SOCIAL_MEDIA_PLATFORM } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { SocialMediaI, SocialMediaPlatformInput } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  createSocialMediaPlatform: Nullable<{
    platform: Nullable<SocialMediaI>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  platform: SocialMediaPlatformInput;
}

interface UseCreateSocialMediaPlatform extends UseMutationOptions<ResponseData, Variables> {}

export const useCreateSocialMediaPlatform = (options: UseCreateSocialMediaPlatform = {}) => {
  return useMutation<ResponseData, Variables>(CREATE_SOCIAL_MEDIA_PLATFORM, {
    skipGlobalErrorHandling: true,
    pathToErrors: 'createSocialMediaPlatform.errors',
    refetchQueries: ['GetOrganization'],
    ...options,
  });
};
