import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation } from 'hooks/useMutation';
import { CreatePaymentSessionInput, ErrorType, PaymentSessionI } from 'models';
import { authorizationTokenErrorMap, AuthorizationTokenErrorType } from 'utils/errors';
import { Nullable } from 'utils/types';

type Errors =
  | 'ACCOUNT_ALREADY_VERIFIED'
  | 'ACCOUNT_IN_ORGANIZATION'
  | 'ORGANIZATION_NAME_TAKEN'
  | AuthorizationTokenErrorType;

const errorMap: Record<Errors, string> = {
  ACCOUNT_ALREADY_VERIFIED:
    'Your account is already verified. Please contact the support to continue.',
  ACCOUNT_IN_ORGANIZATION:
    'Your account is already in an organization. Please contact the support to continue.',
  ORGANIZATION_NAME_TAKEN: 'Organization name is already taken.',
  ...authorizationTokenErrorMap,
};

const QUERY = gql`
  mutation CreateOrganization(
    $organization: CreateOrganizationInput!
    $token: String!
    $paymentSession: CreatePaymentSessionInput
  ) {
    createOrganization(
      organization: $organization
      token: $token
      paymentSession: $paymentSession
    ) {
      organization {
        id
        name
      }
      paymentSession {
        id
        stripePaymentSessionId
      }
      ${ERRORS_PART}
    }
  }
`;

export interface Organization {
  id: string;
  name: string;
}

interface ResponseData {
  createOrganization: Nullable<{
    organization: Nullable<Organization>;
    errors: ErrorType[];
    paymentSession: Nullable<Pick<PaymentSessionI, 'id' | 'stripePaymentSessionId'>>;
  }>;
}

interface Variables {
  organization: {
    name?: string;
  };
  token: string;
  paymentSession: CreatePaymentSessionInput;
}

export const useCreateOrganization = () => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'createOrganization.errors',
    errorMap,
    skipGlobalErrorHandling: true,
  });
};
