import { useApolloClient } from '@apollo/react-hooks';
import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { HTMLHead } from 'components/common/HTMLHead';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { ModalPageLayout } from 'components/layouts/ModalPageLayout';
import { IS_USER_LOGGED_IN } from 'context/queries';
import { useSafeState } from 'hooks/useSafeState';
import { URLS } from 'pages/urls';
import { LocalStorage } from 'utils/storage';

import { useVerifyOrganizationMember } from './useVerifyOrganizationMember';
import { FormData, VerifyUserForm } from './VerifyUserForm';

// TODO: extract this into common component
import { ErrorMessage } from 'pages/ResetPassword/ResetPassword.styles';

interface VerifyUserPageProps {
  isD2CPlugin: boolean;
}

export const VerifyUserPage: React.VFC<VerifyUserPageProps> = ({ isD2CPlugin }) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('id');

  const [verifyUser, { loading: isSubmitting }, [errorMessage]] = useVerifyOrganizationMember();
  const [isSuccess, setIsSuccess] = useSafeState<boolean>();

  if (!token) {
    history.push(URLS.root);
    return null;
  }

  const handleSubmit = async ({ name, password, terms, privacy }: FormData) => {
    const { data } = await verifyUser({
      variables: {
        legalAgreements: {
          acceptsPrivacyPolicy: privacy,
          acceptsTermsOfUse: terms,
        },
        user: {
          name,
          password,
        },
        token,
      },
    });
    const authToken = data?.verifyOrganizationMemberAccount?.authToken;
    if (authToken) {
      LocalStorage.set('token', authToken);
      setIsSuccess(true);
    }
  };

  const appTitle = isD2CPlugin ? 'Seeen' : 'Seeen Creator Suite';

  return (
    <ModalPageLayout>
      <HTMLHead title={appTitle} />
      <Stack
        rowGap={40}
        display="flex"
        alignItems="center"
        flexDirection="column"
        py="64px"
        px="180px"
      >
        <Box textAlign="center" width="100%">
          <Typography variant="h1">Create an account</Typography>
        </Box>
        {isSuccess ? (
          <UserCreated />
        ) : (
          <>
            {errorMessage ? (
              <ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
            ) : null}
            <VerifyUserForm
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isD2CPlugin={isD2CPlugin}
            />
          </>
        )}
      </Stack>
    </ModalPageLayout>
  );
};

const UserCreated: React.VFC = () => {
  const client = useApolloClient();
  const history = useHistory();

  const handleClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    client.writeQuery({
      query: IS_USER_LOGGED_IN,
      data: { isLoggedIn: true },
    });
    history.push(URLS.root);
  };

  return (
    <Stack>
      <Box textAlign="center">Congratulations. Account created!</Box>
      <Columns display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          component={Link}
          to={URLS.root}
        >
          Go to dashboard
        </Button>
      </Columns>
    </Stack>
  );
};
