import { Switch, SwitchProps } from '@material-ui/core';
import React, { forwardRef } from 'react';

import { SwitchBox } from './Toggle.styles';

interface Props {
  isLabelDisabled?: boolean;
}

const Toggle = forwardRef<HTMLInputElement, SwitchProps & Props>(
  ({ isLabelDisabled = false, ...props }, ref) => {
    const toggleLabel = props.checked ? 'On' : 'Off';

    return (
      <SwitchBox display="flex" alignItems="center">
        <Switch {...props} color="primary" inputRef={ref} className="CustomSwitch" />
        {!isLabelDisabled ? toggleLabel : null}
      </SwitchBox>
    );
  },
);

export default Toggle;
