import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { URLS } from 'pages/urls';

import { ChangePage } from './ChangePage';
import { RequestPage } from './RequestPage';

export const ResetPasswordPage: React.FC = () => {
  return (
    <Switch>
      <Route path={URLS.resetPassword.root} exact>
        <RequestPage />
      </Route>
      <Route path={URLS.resetPassword.change}>
        <ChangePage />
      </Route>
    </Switch>
  );
};
