import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { FormLabel } from 'components/common/FormLabel';
import { Permission } from 'config/permissions';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useSafeState } from 'hooks/useSafeState';

import { ChangeNameModal } from './ChangeNameModal';

export const OrganizationName: React.VFC = () => {
  const { data } = useOrganizationQuery({
    fetchPolicy: 'cache-first',
  });
  const organization = data?.me.organization;
  const organizationId = organization?.id;
  const organizationName = organization?.name;

  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useSafeState(false);
  const openChangeNameModal = () => setIsChangeNameModalOpen(true);
  const closeChangeNameModal = () => setIsChangeNameModalOpen(false);

  const { hasPermissions } = useUserPermissions();
  const canEditOrganization = hasPermissions([Permission.ChangeOrganization]);

  return (
    <div>
      <FormLabel>Organization Name</FormLabel>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FakeInput>{organizationName}</FakeInput>
        {organizationId && canEditOrganization && (
          <>
            <IconButton onClick={openChangeNameModal} color="inherit">
              <EditIcon />
            </IconButton>
            <ChangeNameModal
              organizationId={organizationId}
              organizationName={organizationName}
              isOpen={isChangeNameModalOpen}
              onClose={closeChangeNameModal}
            />
          </>
        )}
      </Box>
    </div>
  );
};

const FakeInput = styled(Box)`
  border: 1px solid #e0e1e8;
  border-radius: 10px;
  padding: 18px 20px;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
