import { Button, ButtonBase } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { Spinner } from 'components/common';
import { CheckboxEntry } from 'components/common/CheckboxEntry';
import { Stack } from 'components/common/Wrapper/Layout';
import { pushError, pushMessage } from 'context/globalStream';
import { useAddToPlaylist } from 'hooks/mutation/useAddToPlaylist';
import { PlaylistContentType, PlaylistOrdering } from 'models/Playlist';
import { URLS } from 'pages/urls';

import { Item } from './types';
import { usePlaylistsToAdd } from './usePlaylistsToAdd';

import { Text } from 'components/common/Dialog/Dialog.styles';

interface Props {
  item: Item;
  onClose: () => void;
}

export function AddToPlaylistPicker({ item, onClose }: Props) {
  const isMoment = item.type === PlaylistContentType.Moments;

  const { playlists, loading: arePlaylistsLoading } = usePlaylistsToAdd({
    itemId: item.id,
    variables: {
      contentType: item.type,
      itemsOrderBy: PlaylistOrdering.Manual,
      page: 1,
      pageSize: 999,
      changingCount: 0,
      orderBy: 'name',
    },
  });
  const [addToPlaylist] = useAddToPlaylist();
  const [selectedPlaylistIds, setSelectedPlaylistIds] = useState<Record<string, boolean>>({});
  const selectedList = Object.keys(selectedPlaylistIds).filter((key) => selectedPlaylistIds[key]);

  const handleAddClick = async () => {
    const results = await Promise.all(
      selectedList.map((playlistId) => {
        return addToPlaylist({
          variables: {
            itemId: item.id,
            playlistId,
          },
        });
      }),
    );

    let successCount = 0;
    let errorCount = 0;
    results.forEach(({ data, errors, operationErrors }) => {
      if (errors?.length || operationErrors?.length) {
        errorCount++;
      } else if (data) {
        successCount++;
      }
    });

    const itemType = isMoment ? 'Moment' : 'Video';
    if (successCount > 0 && errorCount > 0) {
      pushMessage({
        message: `${itemType} added to ${successCount} ${
          successCount === 1 ? 'playlist' : 'playlists'
        } with errors.`,
      });
    } else if (successCount === results.length) {
      pushMessage({
        message: `${itemType} added to ${successCount} ${
          successCount === 1 ? 'playlist' : 'playlists'
        }.`,
      });
    } else {
      pushError({ message: `${itemType} was not added to any playlist. Try again.` });
    }

    onClose();
  };

  return (
    <Stack>
      <Text>
        Choose one or multiple playlists
        <br /> to add{' '}
        <strong>
          {item.link ? (
            <Link to={item.link} onClick={onClose}>
              “{item.title}”
            </Link>
          ) : (
            `“${item.title}”`
          )}
        </strong>{' '}
        to:
      </Text>
      {arePlaylistsLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <Stack width="400px" mx="auto" rowGap="10px" maxHeight="300px" overflow="auto">
          {playlists.map((playlist) => {
            return (
              <CheckboxEntry
                key={playlist.id}
                checked={playlist.alreadyAdded || selectedPlaylistIds[playlist.id] === true}
                disabled={playlist.alreadyAdded}
                onChange={(checked) => {
                  setSelectedPlaylistIds((ids) => {
                    return { ...ids, [playlist.id]: checked };
                  });
                }}
              >
                {playlist.name} {playlist.alreadyAdded && '(already added)'}
              </CheckboxEntry>
            );
          })}
          <CreatePlaylistButton
            // @ts-expect-error
            component={Link}
            to={URLS.playlists.new}
          >
            Create New Playlist
          </CreatePlaylistButton>
        </Stack>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddClick}
        disabled={selectedList.length === 0}
      >
        Add
      </Button>
    </Stack>
  );
}

const CreatePlaylistButton = styled(ButtonBase)`
  border: 1px dashed ${materialTheme.palette.grey[200]};
  border-radius: 10px;
  height: 58px;
  width: 100%;
`;
