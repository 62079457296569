import gql from 'graphql-tag';

export const IS_USER_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const QUERY_USER_PERMISSIONS = gql`
  query GetUserPermissions {
    me {
      id
      permissions
    }
  }
`;
