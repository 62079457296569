import { uniqBy } from 'ramda';
import React from 'react';
import { useHistory } from 'react-router';

import MainLayout from 'components/layouts/MainLayout';
import { PlaylistOrdering } from 'models/Playlist';
import { generatePlaylistEditLink, URLS } from 'pages/urls';

import { FormData, PlaylistPageView } from './PlaylistEditor';
import { Item } from './PlaylistEditor/types';
import { useCreatePlaylist } from './useCreatePlaylist';

export function NewPlaylistPage() {
  const history = useHistory();

  const [createPlaylist, { loading: isSaving }] = useCreatePlaylist({
    onCompleted: (data) => {
      const id = data.createPlaylist?.playlist?.id;
      if (id) {
        history.replace(generatePlaylistEditLink(id));
      }
    },
  });

  const [manualItems, setManualItems] = React.useState<Item[]>([]);

  const handleSubmit = ({ contentType, filterBy, filterValue, name, orderBy }: FormData) => {
    const isManual = orderBy === PlaylistOrdering.Manual;

    createPlaylist({
      variables: {
        playlist: {
          contentType,
          filterBy,
          filterValue,
          name,
          orderBy,
          items: isManual ? manualItems.map(({ id }) => id) : [],
        },
      },
    });
  };

  const handleAddItemsToPlaylist = (items: Item[]) => {
    setManualItems((oldItems) => {
      return uniqBy((item) => item.id, [...oldItems, ...items]);
    });
  };

  return (
    <MainLayout>
      <PlaylistPageView
        pageTitle="Create Playlist"
        onFormSubmit={handleSubmit}
        goBackLink={URLS.playlists.list}
        isSaving={isSaving}
        saveButtonText="Create Playlist"
        handleAddItemsToPlaylist={handleAddItemsToPlaylist}
        manualItems={manualItems}
        setManualItems={setManualItems}
      />
    </MainLayout>
  );
}
