import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: ${materialTheme.palette.error.main};
  position: relative;
  top: -16px;
`;

export const CtaItem = styled(Box)<{ disabled: boolean }>`
  border: 1px solid ${materialTheme.palette.grey[200]};
  border-radius: 10px;
  background-color: ${materialTheme.palette.background.default};
  padding-right: 10px;

  & {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

export const CtaItemName = styled.p`
  font-weight: 700;
`;

export const CtaItemType = styled.p`
  font-size: 12px;
`;

export const PauseIcon = styled.span`
  width: 16px;
  height: 16px;
  display: inline-flex;

  svg {
    width: 100%;
    height: 100%;
  }
`;
