import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Drag } from 'assets/icons/re-arrange.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { CTATypes } from 'pages/Overlays/CTAsTab/constants';

import { CTAItemProps } from '../type';

import { CtaItem, CtaItemName, CtaItemType } from '../VideoCTAPage.styles';

const DragHandle = SortableHandle(() => (
  <IconButton color="inherit" edge="start">
    <Drag />
  </IconButton>
));

export const CTAItem: React.FC<CTAItemProps> = ({ item, onEditCTA, onRemoveCTA, disabledItem }) => {
  const type = item.type ? CTATypes[item.type] : '';
  return (
    <CtaItem
      mb="16px"
      p="10px 20px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      disabled={disabledItem}
    >
      <Box display="flex" flexDirection="column">
        <CtaItemName>{item.template?.name}</CtaItemName>
        <CtaItemType>{type}</CtaItemType>
      </Box>
      <Box>
        <IconButton color="inherit" edge="start" onClick={() => onEditCTA(item)}>
          <Edit />
        </IconButton>
        <IconButton color="inherit" edge="start" onClick={() => onRemoveCTA(item.id)}>
          <Trash />
        </IconButton>
        <DragHandle />
      </Box>
    </CtaItem>
  );
};
