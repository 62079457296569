import React from 'react';

import { FormLabel } from 'components/common';
import { Stack } from 'components/common/Wrapper/Layout';
import { useLanguageList } from 'hooks/useLanguageList';
import { VideoCaptions } from 'models';

import { CaptionsItem } from './CaptionsItem';
import { UploadField } from './UploadField';

const UPLOAD_ID = 'captions-upload';

interface Props {
  videoId: string;
  captions: VideoCaptions[];
}

export const CaptionsUpload: React.VFC<Props> = ({ videoId, captions = [] }) => {
  const sortedCaptions = useSortedCaptions(captions);
  return (
    <div>
      <Stack rowGap={10}>
        <FormLabel htmlFor={UPLOAD_ID}>Captions</FormLabel>
        <Stack component="ul" rowGap={6}>
          {sortedCaptions.map((caption) => {
            return <CaptionsItem key={caption.id} caption={caption} videoId={videoId} />;
          })}
        </Stack>
        <div>
          <UploadField captions={sortedCaptions} id={UPLOAD_ID} videoId={videoId} />
        </div>
      </Stack>
    </div>
  );
};

function useSortedCaptions(captions: VideoCaptions[]) {
  const list = useLanguageList();

  return captions.slice().sort(({ languageCode: A }, { languageCode: B }) => {
    const labelA = list.find((e) => e.code === A)?.label;
    const labelB = list.find((e) => e.code === B)?.label;
    // If a language code is not found, send it to the end.
    return labelA && labelB ? labelA.localeCompare(labelB) : -1;
  });
}
