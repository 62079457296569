import { UPDATE_IMAGE_CTA_MUTATION } from 'context/queries/imageCTAMutations';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { CreateImageCTAInput, ImageCTAResponse } from 'pages/CTAImage/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateImageCta: Nullable<{
    imageCta: Nullable<ImageCTAResponse>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  image_cta: Partial<CreateImageCTAInput>;
}

interface UseUpdateImageCTA extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateImageCTA = (options: UseUpdateImageCTA = {}) => {
  return useMutation<ResponseData, Variables>(UPDATE_IMAGE_CTA_MUTATION, {
    pathToErrors: 'updateImageCta.errors',
    refetchQueries: ({ data }: { data: ResponseData }) =>
      data?.updateImageCta?.imageCta ? ['GetImageCta'] : [],
    ...options,
  });
};
