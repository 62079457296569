import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const CREATE_END_CARD_MUTATION = gql`
  mutation CreateEndCard($endCard: CreateEndCardInput!) {
    createEndCard(endCard: $endCard) {
      endCard {
        id
        name
        cardOrientation
        cardSize
        overlay
        background
        backgroundImageSettings
        border
        videoControlButtons
        headline
        subline
        backgroundImageUrl
        promoCodeButton {
          label
          text
        }
        watchNextButtonVisible
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_END_CARD_MUTATION = gql`
  mutation UpdateEndCard($endCard: UpdateEndCardInput!, $id: ID!) {
    updateEndCard(endCard: $endCard, id: $id) {
      endCard {
        id
        name
        cardOrientation
        cardSize
        overlay
        background
        backgroundImageSettings
        border
        videoControlButtons
        headline
        subline
        backgroundImageUrl
        promoCodeButton {
          label
          text
        }
        watchNextButtonVisible
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_END_CARD_MUTATION = gql`
mutation DeleteEndCard($id: ID!) {
    deleteEndCard(id: $id) {
      deletedEndCardId
      ${ERRORS_PART}
    }
  }`;
