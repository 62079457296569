import gql from 'graphql-tag';

import { QUERY_USER_PERMISSIONS } from 'context/queries';
import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, OrganizationMemberI, UserRole } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'CAN_NOT_CHANGE_ORG_OWNER_ROLE' | 'ORG_OWNER_ROLE_RESERVED';

const errorMap: Record<Errors, string> = {
  CAN_NOT_CHANGE_ORG_OWNER_ROLE: "Organization Owner's role cannot be changed.",
  ORG_OWNER_ROLE_RESERVED: 'Your organization can only have one Owner.',
};

const QUERY = gql`
  mutation UpdateOrganizationMember($id: ID!, $organizationMember: UpdateOrganizationMemberInput!) {
    updateOrganizationMember(id: $id, organizationMember: $organizationMember) {
      organizationMember {
        id
        role
      }
      ${ERRORS_PART}
    }
  }
`;

type OrganizationMemberPick = Pick<OrganizationMemberI, 'id' | 'role'>;

interface ResponseData {
  updateOrganizationMember: Nullable<{
    organizationMember: Nullable<OrganizationMemberPick>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  organizationMember: {
    role?: UserRole;
  };
}

interface UseUpdateOrganizationMemberQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateOrganizationMember = (options?: UseUpdateOrganizationMemberQueryOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'updateOrganizationMember.errors',
    skipGlobalErrorHandling: true,
    errorMap,
    refetchQueries: [{ query: QUERY_USER_PERMISSIONS }],
    ...options,
  });
};
