import { Button } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoadingButton } from 'components/common';
import { Columns } from 'components/common/Wrapper/Layout';
import { Permission } from 'config/permissions';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { FlowStep, getCurrentStepIndex, NAV_ITEMS } from 'pages/MicroSite/MicrositeLayout';
import { URLS } from 'pages/urls';

import * as Styled from './Footer.styles';

export interface FooterProps {
  currentStep: FlowStep;
  isLoading: boolean;
}

const Footer: React.VFC<FooterProps> = ({ currentStep, isLoading }) => {
  const { formState } = useFormContext();

  // Read the `formState` before render to subscribe to the form state proxy
  const { isValid } = formState;

  const currentStepIndex = getCurrentStepIndex(currentStep);
  const isLastStep = currentStepIndex === NAV_ITEMS.length - 1;

  const { hasPermissions } = useUserPermissions();
  const canCreateMicrosite = hasPermissions([Permission.CreateMicrosite]);

  return (
    <Styled.Wrapper>
      <Button variant="outlined" color="secondary" component={Link} to={URLS.microsite.root}>
        Cancel
      </Button>
      <Columns>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={!canCreateMicrosite || !isValid || isLastStep}
          isLoading={isLoading}
        >
          Publish
        </LoadingButton>
      </Columns>
    </Styled.Wrapper>
  );
};

export default Footer;
