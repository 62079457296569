import 'slick-carousel/slick/slick.css';

import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { ReactComponent as Arrow } from 'assets/icons/arrow-left.svg';

import { EditCarouselPreviewProps } from '../types';
import { CarouselItem } from './EditCarouselSlide';
import * as Styled from './styled';

export const EditCarouselPreview: React.FC<EditCarouselPreviewProps> = ({
  moments,
  configurations,
  selectedColor,
}) => {
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const sliderRef = useRef<Slider>(null);
  const slidesToShow = 3;

  useEffect(() => {
    setSliderLoaded(!!sliderRef.current);
  }, [sliderRef]);

  const settings = {
    dots: false,
    infinite: moments.length > 2,
    speed: 300,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    arrows: false,
  };

  return (
    <Styled.CarouselContainer>
      <Styled.CarouselWrapper>
        <Styled.CarouselContent>
          <Styled.CarouselSlider>
            <Slider ref={sliderRef} {...settings}>
              {moments.map((moment, index) => (
                <CarouselItem moment={moment} key={index} configurations={configurations} />
              ))}
            </Slider>
          </Styled.CarouselSlider>
        </Styled.CarouselContent>
        {sliderLoaded ? (
          <>
            <Styled.CarouselPrevArrow
              onClick={() => sliderRef.current?.slickPrev()}
              aria-label="Go to previous moments in slider"
              selectedColor={selectedColor}
              isDisabled={moments.length <= slidesToShow}
            >
              <Arrow className="prev-arrow-icon" />
            </Styled.CarouselPrevArrow>
            <Styled.CarouselNextArrow
              onClick={() => sliderRef.current?.slickNext()}
              aria-label="Go to next moments in slider"
              selectedColor={selectedColor}
              isDisabled={moments.length <= slidesToShow}
            >
              <Arrow className="next-arrow-icon" />
            </Styled.CarouselNextArrow>
          </>
        ) : null}
      </Styled.CarouselWrapper>
    </Styled.CarouselContainer>
  );
};
