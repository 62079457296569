import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PaginationVariables, WidgetPresetsOrderBy } from 'models';
import { WidgetPresetI } from 'models/Widget';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetWidgetPresets($id: ID, $search: String, $orderBy: String, $page: Int, $pageSize: Int) {
    widgetPresets(id: $id, search: $search, orderBy: $orderBy, page: $page, pageSize: $pageSize) {
      nodes {
        id
        name
      }
    }
  }
`;

export type WidgetPresetEmbed = Pick<WidgetPresetI, 'id' | 'name'>;

interface ResponseData {
  widgetPresets: Nullable<{
    nodes: Nullable<WidgetPresetEmbed[]>;
  }>;
}

interface Variables extends PaginationVariables {
  id?: string;
  search?: string;
  orderBy?: WidgetPresetsOrderBy;
}

interface UseWidgetPresetsEmbedQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export function useWidgetPresetsEmbed(options?: UseWidgetPresetsEmbedQueryOptions) {
  return useQuery<ResponseData, Variables>(QUERY, options);
}
