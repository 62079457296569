import { useId } from '@react-aria/utils';
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortEvent,
  SortEventWithTag,
  SortStartHandler,
} from 'react-sortable-hoc';
import styled from 'styled-components/macro';

import { ReactComponent as DragIcon } from 'assets/icons/re-arrange.svg';
import { CheckboxCell, Table } from 'components/Table';
import { TitleCell } from 'pages/Dashboard/Tables/TitleCell';
import { generateMomentLink, generateVideoLink } from 'pages/urls';
import { reorderArray } from 'utils/common';
import { SetState } from 'utils/types';

import { Item } from './types';

import * as Styled from 'components/common/Table/Table.styles';

interface Props {
  items: Item[];
  setManualItems: (items: Item[]) => void;
  isSortingEnabled: boolean;
  isSelectingEnabled: boolean;
  selectedItemIds: Record<string, boolean>;
  setSelectedItemIds: SetState<Record<string, boolean>>;
}

export function SortableItemTable({
  items,
  setManualItems,
  isSortingEnabled,
  isSelectingEnabled,
  selectedItemIds,
  setSelectedItemIds,
}: Props) {
  const id = useId();

  const onSortStart: SortStartHandler = ({ node, helper }) => {
    // keep the ghost row width the same as before sort start
    node.childNodes.forEach((td, index) => {
      // @ts-expect-error
      helper.childNodes[index].style.width = `${td.offsetWidth}px`;
    });
  };

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newItems = reorderArray(items, oldIndex, newIndex);
    setManualItems(newItems);
  };

  const shouldCancelStart = (event: SortEvent | SortEventWithTag) => {
    if (!isSortingEnabled) {
      return true;
    }

    const target = event.target as HTMLElement;
    return (
      target.parentElement?.tagName.toLowerCase() === 'a' ||
      target.tagName.toLowerCase() === 'input'
    );
  };

  return (
    <Styled.TableWrapper>
      <Styled.TableHorizontalScroll>
        <Table fullWidth>
          <Table.Head>
            {isSelectingEnabled ? <Table.HeadCell></Table.HeadCell> : null}
            <Table.HeadCell width="100%">Title</Table.HeadCell>
            {isSortingEnabled ? <Table.HeadCell></Table.HeadCell> : null}
          </Table.Head>
          <SortableContainerTable
            id={id}
            helperContainer={() => document.getElementById(id) as HTMLElement}
            onSortStart={onSortStart}
            onSortEnd={onSortEnd}
            shouldCancelStart={shouldCancelStart}
          >
            {items.map((item, index) => {
              return (
                <SortableElementRow
                  index={index}
                  key={item.id}
                  item={item}
                  isSelected={selectedItemIds[item.id] === true}
                  onCheckboxClick={(checked) =>
                    setSelectedItemIds((ids) => {
                      return { ...ids, [item.id]: checked };
                    })
                  }
                  isSortingEnabled={isSortingEnabled}
                  isSelectingEnabled={isSelectingEnabled}
                />
              );
            })}
          </SortableContainerTable>
        </Table>
      </Styled.TableHorizontalScroll>
    </Styled.TableWrapper>
  );
}

const SortableContainerTable = SortableContainer(Table.Body);

interface SortableElementRowProps {
  item: Item;
  isSelected: boolean;
  onCheckboxClick: (checked: boolean) => void;
  isSortingEnabled: boolean;
  isSelectingEnabled: boolean;
}

const SortableElementRow = SortableElement(function SortableElementRow({
  item,
  isSelected,
  onCheckboxClick,
  isSortingEnabled,
  isSelectingEnabled,
}: SortableElementRowProps) {
  const link = item.video ? generateMomentLink(item.video.id, item.id) : generateVideoLink(item.id);

  return (
    <StyledRow>
      {isSelectingEnabled ? (
        <CheckboxCell
          checked={isSelected}
          onChange={(event) => onCheckboxClick(event.currentTarget.checked)}
          color="secondary"
        />
      ) : null}
      <Table.Cell>
        <TitleCell
          title={item.title}
          description={item.description}
          link={link}
          thumbnailUrl={item.thumbnailUrl}
        />
      </Table.Cell>
      {isSortingEnabled ? (
        <Table.Cell>
          <DragCell>
            <DragIcon className="drag-icon" />
          </DragCell>
        </Table.Cell>
      ) : null}
    </StyledRow>
  );
});

const StyledRow = styled(Table.Row)`
  background-color: #fff;
  height: 80px;
`;

const DragCell = styled.div`
  display: flex;
  align-items: center;
  /* this declares the row height for react-sortable-hoc,
     setting height only on <tr> breaks the ghost preview */
  height: 80px;
`;
