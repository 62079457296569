import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PageInfo, PaginationVariables, WidgetPresetsOrderBy } from 'models';
import { DashboardWidgetPreset } from 'models/Widget';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetWidgetPresets($id: ID, $search: String, $orderBy: String, $page: Int, $pageSize: Int) {
    widgetPresets(id: $id, search: $search, orderBy: $orderBy, page: $page, pageSize: $pageSize) {
      nodes {
        created
        id
        name
        googleAnalyticsTrackingId
        googleAnalytics4MeasurementId
        endCard {
          id
          name
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        pageNumber
        pageSize
      }
    }
  }
`;

interface ResponseData {
  widgetPresets: Nullable<{
    nodes: Nullable<DashboardWidgetPreset[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends PaginationVariables {
  id?: string;
  search?: string;
  orderBy?: WidgetPresetsOrderBy;
}

interface UseWidgetPresetsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export function useWidgetPresets(options?: UseWidgetPresetsQueryOptions) {
  return useQuery<ResponseData, Variables>(QUERY, options);
}
