import { Box, Button } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { CustomPrompt } from 'components/common/CustomPrompt';
import * as Styled from 'components/common/PageContentReview';
import { Columns } from 'components/common/Wrapper/Layout';
import BasePlayer from 'components/VideoPlayer/BasePlayer';
import { updateSource } from 'context/VideoMetadataStream';
import { VideoDetailsNode } from 'models';
import { generateVideoLink } from 'pages/urls';
import { isDeepEqual } from 'utils/common';
import { getStreamURL } from 'utils/streaming';

import { EMPTY_CTA_FIELD_ID } from './constants';
import { convertDataFromServer } from './helpers';
import { CTATimestamp } from './type';
import { VideoCTAPageContext } from './useTimestampContext';

interface Props {
  video: VideoDetailsNode;
  onSubmit: (timestamps: CTATimestamp[]) => void;
}

export const VideoCTAPageMain: React.FC<Props> = ({ video, onSubmit }) => {
  const history = useHistory();
  const { currentCTA, currentCTAs, timestamps } = useContext(VideoCTAPageContext);

  const hasUnsavedChanges = useMemo(
    () => !isDeepEqual(convertDataFromServer(video.ctaTimestamps), timestamps),
    [video.ctaTimestamps, timestamps],
  );

  const ctas =
    currentCTA?.id === EMPTY_CTA_FIELD_ID
      ? [currentCTA, ...currentCTAs.filter(({ id }) => id !== currentCTA.id)]
      : currentCTAs.map((cta) => (cta.id === currentCTA?.id ? currentCTA : cta));

  useEffect(() => updateSource({ duration: video.duration || 0 }), [video.duration]);

  const handleModalConfirm = useCallback(() => {
    history.push(generateVideoLink(video.id));
  }, [history, video.id]);

  const sourceURL = getStreamURL(video.videoFileUrl, video.videoStreams);

  return (
    <>
      <Columns alignItems="center" display="flex" justifyContent="flex-end" pb="20px">
        <Button onClick={handleModalConfirm} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!hasUnsavedChanges}
          onClick={() => onSubmit(timestamps)}
        >
          Save
        </Button>
      </Columns>
      <Styled.PreviewCard>
        <Styled.PreviewCardHeader>
          <Styled.PreviewCardLabel>Preview</Styled.PreviewCardLabel>
          {/* <Styled.PreviewCardTooltip>
            <InfoTooltip content="test" />
          </Styled.PreviewCardTooltip> */}
        </Styled.PreviewCardHeader>
        <Styled.PreviewCardBody />
      </Styled.PreviewCard>
      <Box position="relative">
        <BasePlayer url={sourceURL} ctas={ctas} />
      </Box>
      <CustomPrompt hasUnsavedChanges={hasUnsavedChanges} />
    </>
  );
};
