import { VideoDetailsNode } from 'models';
import {
  generateExternalContentLink,
  generateVideoCTALink,
  generateVideoEndCardLink,
} from 'pages/urls';

export const OVERLAYS_BUTTONS = [
  {
    title: 'End Card',
    info: '',
    getPathname: generateVideoEndCardLink,
    getBadgeValue: (video: VideoDetailsNode) => video.endCard,
    mb: '40px',
  },
  {
    title: 'CTAs',
    info: 'Display multiple CTAs while video is playing',
    getPathname: generateVideoCTALink,
    getBadgeValue: (video: VideoDetailsNode) => video.ctaTimestamps.length,
    mb: '40px',
  },
  {
    title: 'Promo Cards',
    info:
      'Enrich your video and allow viewers to explore more of your promo cards like Products or Blog Articles, still within the same video player.',
    getPathname: generateExternalContentLink,
    getBadgeValue: (video: VideoDetailsNode) => video.promotedContentSettings?.promoteObjects,
    mb: '25px',
  },
];
