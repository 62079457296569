import { CREATE_VIDEO_CAPTIONS, REFETCH_VIDEO_CAPTIONS } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, VideoCaptions } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'DUPLICATED_LANGUAGE_CAPTION';

const errorMap: Record<Errors, string> = {
  DUPLICATED_LANGUAGE_CAPTION: 'Captions for this language already exist.',
};

interface ResponseData {
  createVideoCaption: Nullable<{
    captions: Nullable<VideoCaptions>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  videoCaption: {
    captionBase64: string;
    languageCode: string;
    name: string;
  };
}

export const useCreateVideoCaptions = (videoId: string) => {
  return useMutation<ResponseData, Variables>(CREATE_VIDEO_CAPTIONS, {
    pathToErrors: 'createVideoCaption.errors',
    refetchQueries: [{ query: REFETCH_VIDEO_CAPTIONS, variables: { videoId } }],
    errorMap,
  });
};
