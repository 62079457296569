import React from 'react';

import * as Styled from './Table.styles';

interface OwnProps {
  children: React.ReactNode;
}

const ExpandableCell = ({ children }: OwnProps) => (
  <Styled.TitleCell>
    <Styled.TitleBox>
      <Styled.TitleWrapper>
        <Styled.TitleCenterBox>{children}</Styled.TitleCenterBox>
      </Styled.TitleWrapper>
    </Styled.TitleBox>
  </Styled.TitleCell>
);

export default ExpandableCell;
