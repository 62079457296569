import { DELETE_SOCIAL_MEDIA_PLATFORM } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  deleteSocialMediaPlatform: Nullable<{
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteSocialMediaPlatform extends UseMutationOptions<ResponseData, Variables> {}

export const useDeleteSocialMediaPlatform = (options: UseDeleteSocialMediaPlatform = {}) => {
  return useMutation<ResponseData, Variables>(DELETE_SOCIAL_MEDIA_PLATFORM, {
    pathToErrors: 'deleteSocialMediaPlatform.errors',
    refetchQueries: ['GetOrganization'],
    ...options,
  });
};
