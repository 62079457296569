import styled from 'styled-components/macro';

export const IconsWrapper = styled.div<{ color: string }>`
  position: relative;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: ${({ color }) => color};
`;

export const IconWrapper = styled.div<{ color: string }>`
  position: absolute;
  display: flex;
  color: ${({ color }) => color};
`;
