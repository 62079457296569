import React from 'react';

export const TermsOfUseD2CText: React.VFC = () => {
  return (
    <div>
      <h1>SEEEN END USER LICENSE AGREEMENT</h1>
      Last Modified: April 25, 2021
      <ol>
        <li>
          <strong>Your Acceptance</strong>
          Welcome to the SEEEN End User License Agreement. This is an agreement (“Agreement”)
          between Entertainment AI, Inc. (dba SEEEN) (“SEEEN"), the owner and operator of the SEEEN
          software, application, components, and any associated services offered (collectively the
          “Plugin”) and you (“you”, “your”, or “User”, or “User”), a User that has been granted a
          license to use the Plugin subject to this Agreement. Throughout this Agreement, the words
          “Seeen”, “us”, “we”, and “our”, refer to our company, SEEEN, as is appropriate in the
          context of the use of the words. By clicking “I agree”, subscribing to the Plugin, and
          accessing or using the Plugin you agree to be bound by this Agreement and the Privacy
          Policy. We may amend our Agreement and Privacy Policy and may notify you when we do so.
          PLEASE BE AWARETHAT THERE ARE ARBITRATION AND CLASS ACTION PROVISIONS THAT MAY AFFECT YOUR
          RIGHTS. If you do not agree to the Agreement or the Privacy Policy please cease using our
          Plugin immediately.
        </li>

        <li>
          <strong>Plugin License</strong>
          Where applicable Users may be required to register to access the Plugin and properly pay
          any fees to purchase a license to the Plugin. Any registration information must be
          complete, truthful, accurate, and up-to-date. Upon proper registration and payment (where
          required) we grant you a non-exclusive, worldwide, non-transferable, non-sub-licensable,
          revocable, limited license to download and use the Plugin solely in accordance with the
          rights granted within this Agreement. If you breach this Agreement, your license to use
          our Plugin may be terminated at our discretion. Additionally, we may revoke your license
          to our Plugin if we believe that your actions may harm us, our business interests, or any
          third party rights. Failure by us to license your access does not act as a waiver of your
          conduct.
        </li>

        <li>
          <strong>Authorized Users</strong>
          The Plugin license may be shared with User’s authorized user(s) (“Authorized User(s)”)
          Please be aware that any additional Authorized Users may be subject to additional fees.
          User agrees that it is solely responsible for all actions taken under any Authorized
          User’s account and User shall ensure that all Authorized Users shall comply with all User
          obligations as set forth in this Agreement and indemnify SEEEN for its Authorized User’s
          use of the Plugin. All Authorized Users shall agree to abide by this Agreement and the
          Privacy Policy at all times. All information or User Content (defined below) contained
          within an Authorized User’s account shall be owned and controlled by User. Any requests
          are at the discretion of SEEEN and SEEEN is not liable to Authorized User for any User
          Content that is controlled by a User account and User shall bear all liability to such
          Authorized User.
        </li>

        <li>
          <strong>Plugin Ownership</strong>
          You acknowledge that the structure, organization, and code of the Plugin, along with
          theSEEEN identifiers, marks, logos, branding, and all related software components are
          proprietary to SEEEN and/or SEEEN’s licensors and that SEEEN and/or its licensors retains
          exclusive ownership of the Plugin, any documentation, information and any and all other
          intellectual property rights relating to the Plugin, including all modifications, copies,
          enhancements, derivatives, and other software and materials developed hereunder by SEEEN
          (“SEEEN IP”). You shall not sell, copy, reproduce, transfer, publish, disclose, display or
          otherwise make available the SEEEN IP or any portions of the SEEEN IP including any
          modifications, enhancements, derivatives, and other software and materials developed
          hereunder by SEEEN to others in violation of this Agreement. You shall not remove any
          proprietary, copyright, trademark, or service mark legend from any portion of any of the
          Plugin, including any modifications, enhancements, derivatives, and other software and
          materials developed by SEEEN. Allrights not expressly granted in this Agreement are
          reserved for us.
        </li>

        <li>
          <strong>Third Party Terms</strong>
          The Plugin may contain software owned and licensed by third parties. User agrees to abide
          by all license restrictions as set forth within such third party terms. Failure to abide
          by these obligations shall result in termination of the Plugin license. The Plugin offered
          on services and/or platforms owned by Adobe, Inc. or its affiliates (collectively
          “Adobe”). You agree to abide by all terms or agreements set forth by Adobe and
          furtheragree that you will not violate any terms or agreements with Adobe while using our
          Plugin.
        </li>

        <li>
          <strong>User Responsibilities</strong>
          User shall be exclusively responsible for its use of the Plugin. Where a User or an
          Authorized User submits any User Content (defined below), we are not responsible for such
          User Content including but not limited to monitoring access permissions. Additionally, you
          represent and warrant that: (1) you shall use the Plugin solely for your internal
          commercial purposes; (2) you are duly authorized and have the power and authority to enter
          into this Agreement; (3) any User Content is not confidential; (4) thatthe User Content is
          owned or properly licensed to User and does not violate any third party agreements; and
          (5) you shall use the Plugin only for legal and lawful purposes. User shall indemnify and
          hold SEEEN harmless for a breach of any provisions of this section.
        </li>

        <li>
          <strong>Information Generated</strong>
          Seeen is not responsible for any information or content generated via the Plugin. THE USER
          AGREES TO HOLD SEEEN FREE FROM RESPONSIBILITY FOR ANY LIABILITY OR DAMAGE THAT MIGHT ARISE
          OUT OF YOUR USE OF THE PLUGIN. SEEEN WILL NOT BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE
          ARISING IN CONNECTION WITH YOUR USE OF THE SERVICES OR THE PLUGIN.
        </li>

        <li>
          <strong>Privacy Policy</strong>
          Our Privacy Policy, found on the SEEEN website, is incorporated into this Agreement, and
          it governs your submission of information to our Plugin. Please review our Privacy Policy
          so that you may understand our privacy practices. All information we collect is subject to
          our Privacy Policy, and by using the Plugin you consent to all actions taken by us with
          respect to your information in compliance with the Privacy Policy.
        </li>

        <li>
          <strong>User Content</strong>A User’s ability to submit or transmit any information
          through the Plugin, including but not limited to user information, data, recordings, audio
          visual content, written content, images, videos, or any other information will be referred
          to as “User Content” throughout this Agreement. Please be aware that we are not required
          to host, display, migrate, or distribute any of your User Content and we may refuse to
          accept or transmit any User Content. You agree that you are solely responsible for any
          User Content submitted and you release us from any liability associated with any User
          Content submitted. Any User Content found to be in violation of this Agreement or that we
          determine to be harmful to the Plugin may be rejected, modified, edited, deleted, or
          removed at our discretion. When submitting any User Content to our Plugin you represent
          and warrant that you own all rights to the User Content, you have paid any fees to use or
          license the User Content, or you otherwise have the permission and right to use any User
          Content. Furthermore, you represent and warrant that all User Content is legal and the
          User Content does not interfere with any third party rights or obligations. When you
          submit any User Content to us, you grant SEEEN, its partners, affiliates, Users,
          representatives and assigns a non-exclusive, unlimited, irrevocable, fully-paid,
          royalty-free, world-wide, universal, transferable, assignable license to display,
          distribute, store, broadcast, transmit, reproduce, modify, prepare derivative works, or
          use and reuse all or part of your User Content for any purpose as deemed necessary by
          SEEEN. SEEEN has no liability to Users for any User Content including all data,
          information, copy, images, URL names, and anything else submitted by you or any third
          parties using the Plugin. Specifically, SEEEN shall not be liable for any errors related
          to any User Content.
        </li>

        <li>
          <strong>Additional Guidelines for User Content</strong>
          Please be aware that all User Content may be viewed by third parties, thus we cannot
          guarantee the confidentiality of any User Content. When submitting any User Content you
          agree to the following:
          <ul>
            <li>
              You agree that User Content submitted is to the best of your knowledge truthful,
              up-to-date, and accurate;
            </li>
            <li>
              You agree not to submit any User Content that contains any confidential information;
            </li>
            <li>
              You agree not to submit any User Content which contains hate speech or promotes or
              condones violence against individuals or groups based on race or ethnic origin,
              religion, disability, gender, age, nationality, veteran status, or sexual
              orientation/gender identity;
            </li>
            <li>
              You agree not to submit any User Content that is considered spam or politically
              controversial; and
            </li>
            <li>
              You agree not to submit any User Content that may be considered: misleading, unlawful,
              defamatory, obscene, invasive, threatening, or harassing.
            </li>
          </ul>
          If you have violated any of our User Content Guidelines or if we believe that any User
          Content may harm the Platform, your access to the Platform may be suspended or terminated.
        </li>

        <li>
          <strong>Storage and Monitoring User Content</strong>
          Seeen shall have the right, but not the obligation, to monitor all User Content on the
          Platform at all times, to determine compliance with this Agreement and any guidelines
          established by us. Without limiting the foregoing, Seeen shall have the right, but not the
          obligation, to remove or disable access to any User Content at its sole discretion. Seeen
          may offer reasonable amounts of storage for User Content but is not obligated to do. Seeen
          is specifically not liable for any User Content stored on the Platform and shall not be
          responsible for maintaining or making any User Content stored on the Platform available to
          Subscriber at any time. Subscriber is solely responsible and liable for any User Content
          storage and/or backups and shall release Seeen from any responsibility or liability for
          any User Content stored on the Platform. Seeen shall have no liability to Subscriber for
          any missing, incomplete, or otherwise unavailable User Content.
        </li>

        <li>
          <strong>Use of the Plugin</strong>
          When using our Plugin, you are responsible for your use of the Plugin. You agree to the
          following:
          <ul>
            <li>
              You may not attempt to interfere with, compromise the system integrity or security, or
              decipher any transmissions to or from the servers running the Plugin or any Adobe
              products;
            </li>
            <li>
              You may not use automated bots or other software to use excessively use the capacity
              of the Plugin more than humanly possible;
            </li>
            <li>
              You may not decompile, reverse engineer, disassemble, modify, rent, sell, lease, loan,
              distribute, or create derivative works or improvements to the Plugin or any portion of
              it;
            </li>
            <li>
              You may not access our Plugin in an attempt to build a similar or other competitive
              product;
            </li>
            <li>You may not use the Plugin in an unlawful manner;</li>
            <li>
              You may not take any action that imposes, or may impose at our sole discretion, an
              unreasonable or disproportionately large load on our infrastructure;
            </li>
            <li>
              You may not violate or infringe other people's intellectual property, privacy, or
              other contractual rights while using our Plugin;
            </li>
            <li>You may not interfere with or disrupt the Plugin;</li>
            <li>
              You agree not to use the Platform in any way that is: misleading, unlawful,
              defamatory, obscene, invasive, threatening, or harassing; and
            </li>
            <li>You agree that you will not hold SEEEN responsible for your use of our Plugin</li>
          </ul>
          If you are discovered to be undertaking any of the aforementioned actions your privileges
          to use our Plugin may at our discretion be terminated or suspended. Additionally, if we
          believe that your actions may harm us or a third party we may suspend or terminate your
          use of the Plugin and we may pursue any legal remedy available to us under applicable law.
          SEEEN reserves the right to suspend or terminate any account at any time.
        </li>

        <li>
          <strong>Third Party Requirements</strong>
          The Plugin may reside on the Adobe platform and may offer integrations with third party
          websites, applications, or software (collectively “Third Party Software”). Please be aware
          that SEEEN is not affiliated with any companies that own the Third Party Software and the
          availability of such integration is not an endorsement or recommendation to use any Third
          Party Software. The Third Party Software is controlled by companies that are not under the
          control of SEEEN and SEEEN shall have no ability to control the availability of or your
          access with any Third Party Software. You agree to release us from any liability related
          to your use of any Third Party Software and indemnify us for your use of any Third Party
          Software. In order to use any Third Party Software you may be required to agree that
          company’s legal agreements and pay any fees associated with access to any Third Party
          Software. We are not a party to any contracts that you enter into with any companies that
          control any Third Party Software and are not responsible for Third Party Software payment,
          errors, or service issues. The Plugin may allow you to request information from any Third
          Party Software that may be transferred to the SEEEN Plugin, or vice versa. You expressly
          authorize us to initiate such a request on your behalf and allow any Third Party Software
          to disclose such User Content to us, or vice versa. Please be aware that such transfers
          with Third Party Software may be untimely or contain errors and/or omissions, you are
          solely responsible for verifying that any transfer is accurately completed to your
          specifications. Please be aware that any license granted to us for any User Content may be
          sub-licensed to such Third Party Software.
        </li>

        <li>
          <strong>Free Trials and Beta Users</strong>
          Seeen may offer the Plugin on a free trial basis or may make the Plugin free for its beta
          users. SEEEN reserves the right to discontinue any free trials or free beta users at any
          time and without liability to us. Where you have signed up for a free trial, you agree at
          the expiration of your free trial you may be automatically charged for any additional
          usage of the Plugin.
        </li>

        <li>
          <strong>User Payments</strong>
          User agrees to pay for all costs, fees, and taxes listed when purchasing access the Plugin
          including any recurring subscriptions. Where applicable, you must agree to our third party
          payment processors’ terms and conditions for processing payments. All prices are listed in
          US Dollars. All information that you provide in connection with a payment or transaction
          must be accurate, complete, and current. Where you have failed to pay or where payments
          are overdue, SEEEN may suspend or terminate your access to the paid portions of the
          Plugin, without liability to us.
        </li>

        <li>
          <strong>Automatic Payments</strong>
          Where a User has purchased a subscription, your payment information shall be logged for
          your convenience. IF YOUR ACCOUNT HAS BEEN SET UP FOR AUTOMATIC BILLING, SEEEN MAY CHARGE
          YOU AUTOMATICALLY AND ON A RECURRING BASIS, UNLESS YOU NOTIFY US THAT YOU WANT TO CANCEL
          YOUR AUTOMATIC PAYMENT VIA THE PLUGIN OR AT SUPPORT@SEEEN.COM. ADDITIONALLY, YOU AUTHORIZE
          US TO BILL YOU ON A RECURRING BASIS AND AGREE THAT YOUR SELECTED SUBSCRIPTION SHALL
          AUTOMATICALLY RENEW FOR ADDITIONAL PERIODS (OF THE SAME LENGTH AS YOUR INITIAL
          SUBSCRIPTION) UNTIL WE ARE NOTIFIED OTHERWISE. YOU AGREE THAT NO ADDITIONAL CONSENT IS
          REQUIRED BY YOU TO CHARGE YOUR PREFERRED PAYMENT METHOD AUTOMATICALLY AND ON AN
          AUTOMATICALLY RENEWABLE BASIS FOR THE SUBSCRIPTION.
        </li>

        <li>
          <strong>Refunds for Subscription</strong>
          We want you to be satisfied with the Plugin; however we do not offer refunds at this time.
          If you have any questions regarding our policies, please contact us.
        </li>

        <li>
          <strong>Taxes</strong>
          Where SEEEN does not charge you taxes for any payments, you agree to pay any and all
          applicable taxes. User agrees that SEEEN cannot and will not provide User with any tax
          advice; any such questions should be directed to User’s tax attorney or other tax
          professional.
        </li>

        <li>
          <strong>Pricing and Price Increases</strong>
          The pricing for all paid subscriptions is listed within the SEEEN Plugin. Additionally,
          SEEEN may increase the price SEEEN fees charged, at our discretion and we reserve the
          right to do so at any time. In the event of a price increase, SEEEN shall notify you and
          you will have the chance to accept or reject any price increase. Please notify us if you
          intend to reject a price increase. Where you have rejected a price increase this Agreement
          may be terminated immediately at our discretion. You agree that SEEEN has no obligation to
          offer any services for the price originally offered to you at sign up.
        </li>

        <li>
          <strong>User Account Holds</strong>
          From time to time, SEEEN may place a hold (“Hold”) on a User’s account. Some of the
          reasons that we may place a Hold on User’s account include but are not limited to the
          following: (1) if we have reason that your services offered or your actions have violated
          this Agreement, may harm our business, are deceptive, misleading, unlawful, or have harmed
          a third party or interfere with a third party contractual right; (2) at the request of our
          payment processors or due to delayed payment; or (3) if required in order to comply with a
          court order, subpoena, writ, injunction, or as otherwise required under applicable laws
          and regulations. If you have questions about a Hold we may have placed on your account, or
          need information about how to resolve the Hold, please contact us. Additionally, we may
          suspend User’s access to the Plugin while such Hold is in place.
        </li>

        <li>
          <strong>Termination</strong>
          Please be aware that upon termination of your account, access to all or portions of our
          Plugin may be become immediately disabled and any User Content stored may not be retained.
          We may terminate this Agreement with you if we determine that: (1) you have violated any
          applicable laws while using our Plugin; (2) if you have violated any portion of this
          Agreement or any of our Plugin policies; (3) where you have failed to properly and fully
          pay for any fees to access our Plugin; or (4) if we believe that any of your current or
          future actions may legally harm SEEEN, our business interests or a third party, at our
          discretion. In the event of termination, we will strive to provide you with a timely
          explanation; however, we are not required to do so.
        </li>

        <li>
          <strong>Plugin Availability</strong>
          Although we try to make the Plugin generally available, we do not guarantee that the
          Plugin will always be available, work, or be accessible at any particular time.
          Specifically, we do not guarantee any uptime or specific availability of the Plugin. You
          agree and acknowledge that the Plugin may not always be either 100% reliable or available.
          Only Users who are eligible to use our Plugin may do so and we may refuse service or
          terminate your access at any time. We cannot guarantee that anything found on our Plugin
          will work to the functionality desired by you or give you any desired results.
        </li>

        <li>
          <strong>Support</strong>
          Where a User requires support for the Plugin, please contact us at support@seeen.com.
        </li>

        <li>
          <strong>Modification of Plugin </strong>
          We reserve the right to alter, modify, update, or remove the Plugin or any portions
          thereof, at any time at our discretion. We reserve the right to discontinue previously
          offered features or functionality at our sole discretion and without prior notice. We are
          not liable to you or to any third party for any modification, suspension, or
          discontinuance of any feature or component of any portion of the Plugin. We reserve the
          right to determine the timing and content of software updates, which may be automatically
          downloaded and installed by the Plugin without notice to you.
        </li>

        <li>
          <strong>Force Majeure</strong>
          Seeen will not be liable or responsible for any delays in service, for failing to provide
          its services or to operate the Plugin as a result of any event beyond its reasonable
          control, including, without limitation, adverse weather conditions, internet outage or
          interruption of service, power or telecommunications outage, fire, contagion, flood, civil
          disobedience, labor disruptions, strikes, lockouts, freight embargoes, terrorism, natural
          disaster, war or acts of God.
        </li>

        <li>
          <strong>Migration</strong>
          As SEEEN does not store any User Content, SEEEN shall not be responsible for any
          assistance with migrating any User Content created via the Plugin.
        </li>

        <li>
          <strong>Idea Submission</strong>
          Seeen or any of its employees do not accept or consider unsolicited ideas, including but
          not limited to ideas relating to processes, technologies, product enhancements, or product
          names. Please do not submit any unsolicited ideas, content, artwork, suggestions, or other
          works (“Submissions”) in any form to SEEEN. The sole purpose of this policy is to avoid
          potential misunderstandings or disputes when SEEEN’s products might seem similar to ideas
          you submitted to SEEEN. Where you submit any Submission, whether solicited or unsolicited,
          you agree that: (1) your Submissions and their contents will automatically become the
          property of SEEEN, without any compensation to you; (2) SEEEN may use or redistribute the
          Submissions and their contents for any purpose and in any way; (3) there is no obligation
          for SEEEN to review the Submission; and (4) there is no obligation to keep any Submissions
          confidential.
        </li>

        <li>
          <strong>Disclaimer</strong>
          THE PLUGIN IS PROVIDED ON AN "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS" BASIS. TO THE
          FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER SEEEN, NOR ANY OF OUR EMPLOYEES, DIRECTORS,
          OFFICERS, ASSIGNS, AFFILIATES, OR AGENTS MAKE ANY REPRESENTATIONS OR WARRANTIES OR
          ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO: (1)
          THE PLUGIN; (2) ANY INFORMATION OR CONTENT PROVIDED VIA THE PLUGIN; OR (3) SECURITY
          ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO SEEEN, OR VIA THE PLUGIN. IN ADDITION,
          WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE,
          CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM FROM COMPUTER VIRUS. SEEEN
          DOES NOT REPRESENT OR WARRANT THAT THE PLUGIN WILL BE ERROR-FREE OR UNINTERRUPTED; THAT
          DEFECTS WILL BE CORRECTED; OR THAT THE PLUGIN OR THE SERVER THAT MAKES THE PLUGIN
          AVAILABLE IS FREE FROM ANY HARMFUL COMPONENTS. SEEEN DOES NOT MAKE ANY REPRESENTATIONS OR
          WARRANTIES THAT THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE PLUGIN IS ACCURATE,
          COMPLETE, OR USEFUL. SEEEN DOES NOT WARRANT THAT YOUR USE OF THE PLUGIN IS LAWFUL IN ANY
          PARTICULAR JURISDICTION, AND SEEEN SPECIFICALLY DISCLAIMS ANY SUCH WARRANTIES.
        </li>

        <li>
          <strong>Limitation of Liability</strong>
          IN NO EVENT SHALL SEEEN , ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, OR AGENTS, BE
          LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
          WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL
          THEORY, AND WHETHER OR NOT THE SEEEN IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
          FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN
          THE APPLICABLE JURISDICTION. SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION
          MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM STATE TO STATE.
          SPECIFICALLY, IN THOSE JURISDICTIONS NOT ALLOWED, WE DO NOT DISCLAIM LIABILITY FOR: (1)
          DEATH OR PERSONAL INJURY CAUSED BY SEEEN’S NEGLIGENCE OR THAT OF ANY OF ITS OFFICERS,
          EMPLOYEES OR AGENTS; (2) FRAUDULENT MISREPRESENTATION; OR (3) ANY LIABILITY WHICH IT IS
          NOT LAWFUL TO EXCLUDE EITHER NOW OR IN THE FUTURE. WHERE A TOTAL DISCLAIMER OF LIABILITY
          IS DISALLOWED YOU AGREE THAT OUR TOTAL LIABILITY TO YOU SHALL NOT EXCEED THE AMOUNTS YOU
          HAVE PAID IN THE PAST SIX (6) MONTHS TO USE OUR PLUGIN OR ONE HUNDRED USD, WHICHEVER IS
          GREATER.
        </li>

        <li>
          <strong>Indemnity</strong>
          You agree to defend, indemnify and hold harmless SEEEN, its officers, directors,
          employees, affiliates, and agents, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses (including but not limited
          to attorney's fees) arising from:
          <ul>
            <li>your use of and access to the Seeen Plugin;</li>
            <li>an Authorized User’s use and access to the Seeen Plugin;</li>
            <li>your violation of any term of this Agreement; or</li>
            <li>
              Your violation of any third party right, including without limitation any copyright,
              property, or contractual right.
            </li>
          </ul>
          This defense and indemnification obligation will survive this Agreement and your use of
          the SEEEN Plugin. You also agree that you have a duty to defend us against such claims and
          we may require you to pay for an attorney(s) of our choice in such cases. You agree that
          this indemnity extends to requiring you to pay for our reasonable attorneys’ fees, court
          costs, and disbursements. In the event of a claim such as one described in this paragraph,
          we may elect to settle with the party/parties making the claim and you shall be liable for
          the damages as though we had proceeded with a trial.
        </li>

        <li>
          <strong>Copyrights</strong>
          <strong className="underline">Takedown Notice</strong>
          We take copyright infringement very seriously. If you believe that any copyrighted
          material owned by you has been infringed upon by someone using our Plugin, please send us
          a message which contains:
          <ul>
            <li>Your name.</li>
            <li>
              The name of the party whose copyright has been infringed, if different from your name.
            </li>
            <li>The name and description of the work that is being infringed</li>
            <li>The location on our Plugin of the infringing copy.</li>
            <li>
              A statement that you have a good faith belief that use of the copyrighted work
              described above is not authorized by the copyright owner (or by a third party who is
              legally entitled to do so on behalf of the copyright owner) and is not otherwise
              permitted by law.
            </li>
            <li>
              A statement that you swear, under penalty of perjury, that the information contained
              in this notification is accurate and that you are the copyright owner or have an
              exclusive right in law to bring infringement proceedings with respect to its use.
            </li>
          </ul>
          You must sign this notification and send it to our Copyright Agent: Copyright Agent of
          SEEEN, support@seeen.com or SEEEN, Inc., 199 Whitney Ave New Haven, Connecticut 06511,
          United States of America
          <strong className="underline">Counter Notice</strong>
          In the event that you receive a notification from SEEEN stating content posted by you has
          been subject to a DMCA takedown, you may respond by filing a counter-notice pursuant to
          the DMCA. Your counter-notice must contain the following:
          <ul>
            <li>Your name, address, email and physical or electronic signature.</li>
            <li>The notification reference number (if applicable).</li>
            <li>Identification of the material and its location before it was removed.</li>
            <li>
              A statement under penalty of perjury that the material was removed by mistake or
              misidentification.
            </li>
            <li>
              Your consent to the jurisdiction of a federal court in the district where you live (if
              You are in the U.S.), or Your consent to the jurisdiction of a federal court in the
              district where Your service provider is located (if you are not in the US).
            </li>
            <li>
              Your consent to accept service of process from the party who submitted the takedown
              notice.
            </li>
          </ul>
          Please be aware that we may not take any action regarding your counter-notice unless Your
          notification strictly complies with the foregoing requirements. Please send this
          counter-notice to: support@seeen.com or SEEEN, Inc., 199 Whitney Ave New Haven,
          Connecticut 06511, United States of America.
        </li>

        <li>
          <strong>Choice of Law</strong>
          This Agreement shall be governed by the laws in force in the state of Connecticut. The
          offer and acceptance of this contract is deemed to have occurred in the state of
          Connecticut.
        </li>

        <li>
          <strong>Disputes</strong>
          Any dispute relating in any way to your visit to the Plugin shall be submitted to
          confidential arbitration in New Haven, Connecticut. Arbitration under this Agreement shall
          be conducted pursuant to the applicable Commercial Rules (“Rules”) then prevailing at the
          American Arbitration Association. Arbitration shall be conducted in English by one (1)
          arbitrator as selected pursuant to the Rules; the arbitrator's award shall be final and
          binding and may be entered as a judgment in any court of competent jurisdiction. Each
          party shall be responsible for their own arbitration fees and costs. To the fullest extent
          permitted by applicable law, no arbitration under this Agreement shall be joined to an
          arbitration involving any other party subject to this Agreement, whether through class
          action proceedings or otherwise. Where permitted by the Rules, both parties may make any
          and all appearances telephonically, in written briefs, or electronically. You agree that
          regardless of any statute or law to the contrary, any claim or cause of action arising out
          of, related to or connected with the use of the Plugin or this Agreement must be filed
          within one (1) year after such claim or cause of action arose or be forever banned.
          Notwithstanding the foregoing, either party may seek equitable relief to protect its
          interests (including but not limited to injunctive relief), or make a claim for
          nonpayment, in a court of appropriate jurisdiction, and issues of intellectual property
          ownership or infringement may be decided only by a court of appropriate jurisdiction and
          not by arbitration. In the event that the law does not permit the abovementioned dispute
          to be resolved through arbitration or if this arbitration agreement is unenforceable, you
          agree that any actions and disputes shall be brought solely in a court of competent
          jurisdiction located within New Haven, Connecticut.
          <strong className="underline">Opt-Out</strong>
          You may opt-out of this dispute resolution provision by notifying SEEEN within 30 days of
          the date on which you entered into this Agreement or purchased a subscription, whichever
          is earlier. You must do so by writing to: SEEEN, Inc., 199 Whitney Ave New Haven,
          Connecticut 06511, United States of America, attn: Arbitration Opt-Out, and you must
          include your name, address, account information, and a clear statement that you do not
          wish to resolve disputes with SEEEN through arbitration. Where you opt out of arbitration,
          all disputes shall be heard in a court of competent jurisdiction located within New Haven,
          Connecticut.
        </li>

        <li>
          <strong>Class Action Waiver</strong>
          You and SEEEN agree that any proceedings to resolve or litigate any dispute whether
          through a court of law or arbitration shall be solely conducted on an individual basis.
          You agree that you will not seek to have any dispute heard as a class action,
          representative action, collective action, or private attorney general action.
        </li>

        <li>
          <strong>Severability</strong>
          In the event that a provision of this Agreement is found to be unlawful, conflicting with
          another provision of the Agreement, or otherwise unenforceable, the Agreement will remain
          in force as though it had been entered into without that unenforceable provision being
          included in it. If two or more provisions of this Agreement or any other agreement you may
          have with SEEEN are deemed to conflict with each other’s operation, SEEEN shall have the
          sole right to elect which provision remains in force.
        </li>

        <li>
          <strong>Non-Waiver</strong>
          We reserve all rights permitted to us under this Agreement as well as under the provisions
          of any applicable law. Our non-enforcement of any particular provision or provisions of
          this Agreement or the any applicable law should not be construed as our waiver of the
          right to enforce that same provision under the same or different circumstances at any time
          in the future.
        </li>

        <li>
          <strong>Assignment and Survival</strong>
          You may not assign your rights and/or obligations under this Agreement to any other party
          without our prior written consent. We may assign our rights and/or obligations under this
          Agreement to any other party at our discretion. All portions of this Agreement that would
          reasonably be believed to survive termination shall survive and remain in full force upon
          termination, including but not limited to the Limitation of Liabilities, Representation
          and Warranties, Access, Payment, Indemnification, and Arbitration sections.
        </li>

        <li>
          <strong>Entire Agreement</strong>
          This Agreement along with the Privacy Policy constitute the complete and exclusive
          understanding and agreement between the parties regarding the subject matter herein and
          supersede all prior or contemporaneous agreements or understandings written or oral,
          relating to its subject matter. Where this Agreement expressly and directly conflicts with
          the Privacy Policy, this Agreement shall control. Any waiver, modification or amendment of
          any provision of this Agreement will be effective only if in writing and signed by a duly
          authorized representative of each party. Where this Agreement conflicts with our Privacy
          Policy or any other documentation listed on our website this Agreement shall supersede and
          control.
        </li>

        <li>
          <strong>Amendments</strong>
          We may amend this Agreement from time to time. When we amend this Agreement, we will
          update this page and indicate the date that it was last modified or we may email you. You
          may refuse to agree to the amendments, but if you do, you must immediately cease using our
          Plugin and our Plugin.
        </li>

        <li>
          <strong>Electronic Communications</strong>
          The communications between you and SEEEN use electronic means, whether you visit the
          Plugin or send SEEEN e-mails, or whether SEEEN posts notices on the Plugin or
          communications with you via mobile notifications or e-mail. For contractual purposes, you
          (1) consent to receive communications from SEEEN in an electronic form; and (2) agree that
          all terms, conditions, agreements, notices, disclosures, and other communications that
          SEEEN provides to you electronically satisfy any legal requirement that such
          communications would satisfy if it were to be in writing. The foregoing does not affect
          your statutory rights.
        </li>

        <li>
          <strong>Export Compliance</strong>
          You represent and warrant that (i) you are not located in a country that is subject to a
          U.S. Government embargo, or that has been designated by the U.S. Government as a
          “terrorist supporting” country; (ii) you are not listed on any U.S. Government list of
          prohibited or restricted parties; (iii) you will obtain any authorizations that may be
          required to import, export, and otherwise transfer data used or processed using the
          Plugin; and (iv) you will not use, distribute, transfer, or transmit any products,
          software or technical information (even if incorporated into other products) in violation
          of applicable export laws and regulations.
        </li>

        <li>
          <strong>Plugin Issues</strong>
          Where you have any questions, issues, or if you are having trouble accessing or using the
          Plugin, please contact us at support@seeen.com.
        </li>

        <li>
          <strong>Relationship of Parties</strong>
          This Agreement shall not be construed to create any association, partnership, joint
          venture, employee, worker or agency relationship between User and SEEEN. The relationship
          of the parties is as independent contractors. User has no authority (and shall not hold
          himself or herself out as having authority) to bind SEEEN and User shall not make any
          agreements or representations on SEEEN’s behalf without SEEEN’s prior written consent.
        </li>
      </ol>
    </div>
  );
};
