import { Nullable } from 'utils/types';

export enum VideoCaptionSource {
  YouTube = 'YOUTUBE',
  System = 'SYSTEM',
  User = 'USER',
}

export interface VideoCaptions {
  captionFileUrl: string;
  id: string;
  languageCode: string;
  name: Nullable<string>;
  source: VideoCaptionSource;
}
