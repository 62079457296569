import { VideoI } from 'models';
import { Nullable } from 'utils/types';

import { AnnotationSource } from './ObjectI';

export enum CarouselColorTheme {
  Light = 'LIGHT',
  Dark = 'DARK',
}

export interface CarouselStyle {
  /** in hex format */
  mainColor: string;
  colorTheme?: CarouselColorTheme;
}

export interface CarouselI {
  id: string;
  publicId: string;
  created: string;
  name: string;
  title: string | null;
  description: string | null;
  googleAnalyticsTrackingId: string | null;
  googleAnalytics4MeasurementId: string | null;
  apiKey: string;
  momentsCount: number | null;
  style: CarouselStyle;
}

export interface CreateCarouselInput {
  name: string;
}

export interface UpdateCarouselInput {
  title?: string;
  addMoments?: string[];
  removeMoments?: string[];
  moments?: string[];
}

export interface CarouselStyleInput extends CarouselStyle {}

export interface CarouselMomentI {
  id: string;
  created: string;
  title: string;
  description: string;
  source: AnnotationSource;
  video: VideoI;
  thumbnailUrl: Nullable<string>;
  inCarousel: boolean;
}

export interface CustomizeCarouselInput {
  name?: string;
  title?: string;
  description?: string;
  moments?: string[];
  googleAnalyticsTrackingId?: string;
  googleAnalytics4MeasurementId?: string;
}
