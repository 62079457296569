import React from 'react';

import { EmptyTabInformationButton } from 'components/common/EmptyTab';

interface Props {
  setIsModalOpen: (isOpen: boolean) => void;
}

export const SetupButton: React.VFC<Props> = ({ setIsModalOpen }) => {
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  return (
    <EmptyTabInformationButton onClick={handleButtonClick}>
      How do I find the ad tag URL
    </EmptyTabInformationButton>
  );
};
