import { Button } from '@material-ui/core';
import React from 'react';

import { InfoTooltip, Link, LoadingButton } from 'components/common';
import { CustomPrompt } from 'components/common/CustomPrompt';
import * as Styled from 'components/common/PageContentReview';
import { Columns } from 'components/common/Wrapper/Layout';
import { URLS } from 'pages/urls';

import { FORM_ID } from './constants';

interface Props {
  isLoading: boolean;
  hasUnsavedChanges: boolean;
  disabled: boolean;
  previewBg?: string;
}

export const CTAImagePagePreview: React.FC<Props> = ({
  previewBg,
  hasUnsavedChanges,
  disabled,
  isLoading,
}) => {
  return (
    <>
      <Columns display="flex" alignItems="center" justifyContent="flex-end" pb="20px">
        <Button component={Link} to={URLS.overlays.ctas} variant="outlined" color="secondary">
          Cancel
        </Button>
        <LoadingButton
          form={FORM_ID}
          disabled={disabled}
          isLoading={isLoading}
          variant="contained"
          color="primary"
          type="submit"
        >
          Save
        </LoadingButton>
      </Columns>
      <Styled.PreviewCard>
        <Styled.PreviewCardHeader>
          <Styled.PreviewCardLabel>Preview</Styled.PreviewCardLabel>
          <Styled.PreviewCardTooltip>
            <InfoTooltip content="This is only a preview, the placement of the Image can be selected in the Video Editor" />
          </Styled.PreviewCardTooltip>
        </Styled.PreviewCardHeader>
        <Styled.PreviewCardBody
          height="460px"
          position="relative"
          width="auto"
          style={{
            backgroundImage: `url(${previewBg})`,
          }}
        />
      </Styled.PreviewCard>
      <CustomPrompt hasUnsavedChanges={hasUnsavedChanges} />
    </>
  );
};
