import { Button } from '@material-ui/core';
import React from 'react';

import { LoadingButton } from 'components/common';
import { Columns, Stack } from 'components/common/Wrapper/Layout';

interface Props {
  email: string;
  onRetry: () => void;
  onGoBack: () => void;
  isLoading: boolean;
}

export const ResetRequestSuccess: React.FC<Props> = ({ email, onRetry, onGoBack, isLoading }) => {
  return (
    <Stack textAlign="center">
      <div>
        Email to <strong>{email}</strong> with reset instructions has been sent!
      </div>
      <Columns display="flex" justifyContent="center">
        <Button variant="outlined" color="secondary" onClick={onGoBack}>
          Go back
        </Button>
        <LoadingButton
          isLoading={isLoading}
          variant="contained"
          color="secondary"
          onClick={onRetry}
        >
          Send again
        </LoadingButton>
      </Columns>
    </Stack>
  );
};
