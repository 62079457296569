import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useUpdateEndCard } from 'hooks/mutation/useUpdateEndCard';
import { useEndCardQuery } from 'hooks/query';

import { EditEndCardView } from './EditEndCardView';
import { CreateEndCardInput, EndCardFormData } from './types';

function EditEndCardPage() {
  const { endCardId } = useParams<{ endCardId: string }>();
  const { data, loading } = useEndCardQuery(endCardId);

  const transformedData = useMemo(() => {
    if (!data || !data.endCardById) {
      return null;
    }

    return {
      ...data,
      endCardById: {
        ...data.endCardById,
        overlay: JSON.parse(data.endCardById.overlay),
        background: JSON.parse(data.endCardById.background),
        backgroundImageSettings: JSON.parse(data.endCardById.backgroundImageSettings),
        border: JSON.parse(data.endCardById.border),
        videoControlButtons: JSON.parse(data.endCardById.videoControlButtons),
        headline: JSON.parse(data.endCardById.headline),
        subline: JSON.parse(data.endCardById.subline),
      },
    };
  }, [data]);

  const [updateEndCard, { loading: isLoadingUpdate }] = useUpdateEndCard();

  const onSubmit = async (
    formData: EndCardFormData,
    hasImage: boolean,
    showNextMomentBtn: boolean,
    showPromoCode: boolean,
  ) => {
    const variablesData: CreateEndCardInput = {
      name: formData.name,
      cardOrientation: formData.cardOrientation,
      cardSize: formData.cardSize,
      overlay: formData.overlay,
      background: formData.background,
      backgroundImageSettings: formData.backgroundImageSettings,
      border: formData.border,
      videoControlButtons: formData.videoControlButtons,
      headline: formData.headline,
      subline: formData.subline || null,
      ctaButton1: formData.ctaButton1 || null,
      ctaButton2: formData.ctaButton2 || null,
      watchNextButtonVisible: showNextMomentBtn,
      promoCodeButton: formData.promoCodeButton || null,
    };

    if (!hasImage) {
      variablesData.backgroundImageEnabled = false;
    }

    if (formData.backgroundImageUrl && !formData.backgroundImageUrl.startsWith('https')) {
      variablesData.backgroundImageBase64 = formData.backgroundImageUrl;
    }

    await updateEndCard({
      variables: {
        id: endCardId,
        endCard: variablesData,
      },
    });
  };

  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (transformedData?.endCardById) {
    return (
      <MainLayout>
        <EditEndCardView
          endCard={transformedData.endCardById}
          handleSave={onSubmit}
          isLoading={isLoadingUpdate}
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
}

export default EditEndCardPage;
