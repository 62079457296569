import isUrl from 'is-url-superb';

import { ImageDimensions } from 'utils/files';

import { extractNumbers } from './common';

const not = <Fn extends (...args: any[]) => any>(fn: Fn) => (...args: Parameters<Fn>) =>
  !fn(...args);

/**
 * Utility for react-hook-form register function.
 *
 * @example
 * ```tsx
 * <input
 *   name="firstname"
 *   ref={register({
 *     validate: validate(isNotEmptyString, 'First name cannot be empty'),
 *   })}
 * />
 * ```
 */
export function validate<TValue>(validator: (value: TValue) => boolean, errorMessage: string) {
  return (value: TValue) => validator(value) || errorMessage;
}

export const isValidUrl = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;-a-z\\d%_.~+=/\\[\\]&]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  );
  return !!isUrl(url) && pattern.test(url);
};

export const isValidEmail = (email: string) => {
  return !!email.trim().match(/^\S+@\S+$/);
};

export const isEmptyString = (value: string) => !value.trim();

export const isNotEmptyString = not(isEmptyString);

export const isMinLength = (min: number) => (value: string) => {
  return value.length >= min;
};

export const isMaxLength = (max: number) => (value: string) => {
  return value.length <= max;
};

export const isNumericOnly = (value: string) => {
  return /^[\d\s]+$/.test(value.trim());
};

export const isNotNumericOnly = not(isNumericOnly);

export const isValidHexColor = (hex: string) => {
  return /^#([0-9A-F]{3}){1,2}$/i.test(hex);
};

export const isValidSlugCharacters = (slug: string) => {
  return /^[a-z\d-]+$/.test(slug);
};

export const isValidApiKeyName = (name: string) => {
  return /^[-a-zA-Z0-9_]+$/.test(name);
};

export const isValidSlugDashes = (slug: string) => {
  return !/^-|-$/.test(slug);
};

export const isSameAs = (comparator: string) => (value: string) => value === comparator;

export const isNotSameAs = (comparator: string) => (value: string) => value !== comparator;

export function exceedsMaxAllowedFileSize(
  maxAllowedSizeMebibytes: number,
  actualSizeBytes: number,
) {
  return actualSizeBytes === 0 || actualSizeBytes / 1024 / 1024 > maxAllowedSizeMebibytes;
}

export function exceedsMaxImageDimensions(
  maxAllowedDimensions: ImageDimensions,
  actualDimensions: ImageDimensions,
) {
  return (
    actualDimensions.height > maxAllowedDimensions.height ||
    actualDimensions.width > maxAllowedDimensions.width
  );
}

export const validateColor = validate(
  (value: string) => isValidHexColor(value),
  'Invalid hex color format',
);

export const isValueBetween = (min: number, max: number) => (value: string) =>
  min <= extractNumbers(value) && max >= extractNumbers(value);

export const isBiggerThan = (min: number) => (value: string) => min <= extractNumbers(value);

export const isSmallerThan = (max: number) => (value: string) => max >= extractNumbers(value);
