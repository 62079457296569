import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { ToggleAction } from 'context/queries/bulk';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, OrganizationMemberI } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'ACCOUNT_NOT_VERIFIED' | 'CAN_NOT_DEACTIVATE_ORG_OWNER';

const errorMap: Record<Errors, string> = {
  ACCOUNT_NOT_VERIFIED: 'This account needs to be verified before activation.',
  CAN_NOT_DEACTIVATE_ORG_OWNER: 'Organization Owner account cannot be deactivated.',
};

const QUERY = gql`
  mutation ToggleOrganizationMemberAccount($id: ID!, $action: ToggleAction!) {
    toggleOrganizationMemberAccount(id: $id, action: $action) {
      organizationMember {
        id
        isActive
      }
      ${ERRORS_PART}
    }
  }
`;

type OrganizationMemberPick = Pick<OrganizationMemberI, 'id' | 'isActive'>;

interface ResponseData {
  toggleOrganizationMemberAccount: Nullable<{
    organizationMember: Nullable<OrganizationMemberPick>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  action: ToggleAction;
}

interface UseToggleOrganizationMemberQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useToggleOrganizationMember = (options?: UseToggleOrganizationMemberQueryOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'toggleOrganizationMemberAccount.errors',
    errorMap,
    optimisticResponse: ({ id, action }) => {
      return {
        toggleOrganizationMemberAccount: {
          organizationMember: {
            id,
            isActive: action === ToggleAction.enable,
            __typename: 'OrganizationMemberType',
          },
          errors: [],
          __typename: 'ToggleOrganizationMemberAccountMutation',
        },
      };
    },
    ...options,
  });
};
