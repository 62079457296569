import { LiteralUnion } from 'type-fest';

import { Optional } from 'utils/types';

export enum Permission {
  CancelSubscription = 'CANCEL_SUBSCRIPTION',
  ChangeCarousel = 'CHANGE_CAROUSEL',
  ChangeMicrosite = 'CHANGE_MICROSITE',
  ChangeMoment = 'CHANGE_MOMENT',
  ChangeObject = 'CHANGE_OBJECT',
  ChangeObjectSegment = 'CHANGE_OBJECT_SEGMENT',
  ChangeOrganization = 'CHANGE_ORGANIZATION',
  ChangeOrganizationMembers = 'CHANGE_ORGANIZATION_MEMBERS',
  ChangeOrganizationMonetization = 'CHANGE_ORGANIZATION_MONETIZATION',
  ChangePlaylist = 'CHANGE_PLAYLIST',
  ChangeSocialMedia = 'CHANGE_SOCIAL_MEDIA',
  ChangeVideo = 'CHANGE_VIDEO',
  ChangeVideoCaption = 'CHANGE_VIDEO_CAPTION',
  ChangeWidgetPreset = 'CHANGE_WIDGET_PRESET',
  CreateCarousel = 'CREATE_CAROUSEL',
  CreateCustomerPortalSession = 'CREATE_CUSTOMER_PORTAL_SESSION',
  CreateMicrosite = 'CREATE_MICROSITE',
  CreateMoment = 'CREATE_MOMENT',
  CreateMomentThumbnail = 'CREATE_MOMENT_THUMBNAIL',
  CreateObject = 'CREATE_OBJECT',
  CreateObjectSegment = 'CREATE_OBJECT_SEGMENT',
  CreateObjectThumbnail = 'CREATE_OBJECT_THUMBNAIL',
  CreateOrganizationMonetization = 'CREATE_ORGANIZATION_MONETIZATION',
  CreatePaymentSession = 'CREATE_PAYMENT_SESSION',
  CreatePlaylist = 'CREATE_PLAYLIST',
  CreateSocialMedia = 'CREATE_SOCIAL_MEDIA',
  CreateVideoCaption = 'CREATE_VIDEO_CAPTION',
  CreateVideoThumbnail = 'CREATE_VIDEO_THUMBNAIL',
  CreateWidgetPreset = 'CREATE_WIDGET_PRESET',
  DeleteCarousel = 'DELETE_CAROUSEL',
  DeleteMicrosite = 'DELETE_MICROSITE',
  DeleteMoment = 'DELETE_MOMENT',
  DeleteMomentThumbnail = 'DELETE_MOMENT_THUMBNAIL',
  DeleteObject = 'DELETE_OBJECT',
  DeleteObjectSegment = 'DELETE_OBJECT_SEGMENT',
  DeleteObjectThumbnail = 'DELETE_OBJECT_THUMBNAIL',
  DeleteOrganizationMonetization = 'DELETE_ORGANIZATION_MONETIZATION',
  DeletePlaylist = 'DELETE_PLAYLIST',
  DeleteSocialMedia = 'DELETE_SOCIAL_MEDIA',
  DeleteVideo = 'DELETE_VIDEO',
  DeleteVideoCaption = 'DELETE_VIDEO_CAPTION',
  DeleteVideoThumbnail = 'DELETE_VIDEO_THUMBNAIL',
  DeleteWidgetPreset = 'DELETE_WIDGET_PRESET',
  DuplicateVideo = 'DUPLICATE_VIDEO',
  InviteOrganizationMembers = 'INVITE_ORGANIZATION_MEMBERS',
  ManageOrganization = 'MANAGE_ORGANIZATION',
  ManageVideoStatus = 'MANAGE_VIDEO_STATUS',
  PublishVideo = 'PUBLISH_VIDEO',
  RevokeD2CApiKey = 'REVOKE_D2C_API_KEY',
  ToggleOrganizationMembers = 'TOGGLE_ORGANIZATION_MEMBERS',
  UnpublishVideo = 'UNPUBLISH_VIDEO',
  UploadVideo = 'UPLOAD_VIDEO',
  ViewApiKeys = 'VIEW_API_KEYS',
  ViewCarousel = 'VIEW_CAROUSEL',
  ViewD2CDashboard = 'VIEW_D2C_DASHBOARD',
  ViewEndCard = 'VIEW_END_CARD',
  ViewMicrosite = 'VIEW_MICROSITE',
  ViewMoment = 'VIEW_MOMENT',
  ViewObject = 'VIEW_OBJECT',
  ViewOrganizationMembers = 'VIEW_ORGANIZATION_MEMBERS',
  ViewPlaylist = 'VIEW_PLAYLIST',
  ViewVideo = 'VIEW_VIDEO',
  ViewVideoDashboard = 'VIEW_VIDEO_DASHBOARD',
  ViewWidgetPreset = 'VIEW_WIDGET_PRESET',
  DeleteOrganizationLogo = 'DELETE_ORGANIZATION_LOGO',
}

export function hasPermission(
  permission: Permission,
  userPermissions: Optional<LiteralUnion<Permission, string>[]>,
) {
  return !!userPermissions?.includes(permission);
}
