import { ApolloProvider } from '@apollo/react-hooks';
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import type { GraphQLError } from 'graphql';
import React from 'react';
import { serializeError } from 'serialize-error';

import config from 'config';
import { pushError } from 'context/globalStream';
import { ChildrenProps, ServerErrorCode } from 'models';
import { ErrorMap, pushOperationErrors } from 'utils/errors';
import { navigateToRoot } from 'utils/navigation';
import Sentry from 'utils/sentry';
import { LocalStorage } from 'utils/storage';

// Workaround for Apollo bug https://github.com/apollographql/apollo-client/issues/3397#issuecomment-421433032
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});

const dataIdFromObject = (object: any) => {
  const uniqueField = object.publicUuid;

  if (uniqueField) {
    // Use unique field to generate a unique identifier for the object
    return `${object.__typename}:${uniqueField}`;
  }

  // Fall back to the default data identifier generation method
  return defaultDataIdFromObject(object);
};

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject,
});

interface OperationContext {
  pathToErrors?: string;
  isErrorHandledLocally?: boolean;
  errorMap?: ErrorMap;
}

const successLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const {
      isErrorHandledLocally,
      pathToErrors,
      errorMap,
    } = operation.getContext() as OperationContext;

    if (!isErrorHandledLocally && pathToErrors) {
      pushOperationErrors(pathToErrors, result.data, {
        errorMap,
        callback: (error) => {
          // HTTP 200, but backend resulted in an error (equivalent to HTTP 4xx in REST model).
          // These errors generally should be handled individually to render custom UI depending
          // on the context, however, we leave this as an escape hatch for legacy code.
          console.warn('[Client Error]', serializeError(error));
        },
      });
    }

    return result;
  });
});

// https://www.apollographql.com/docs/react/data/error-handling/
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // HTTP 400, client is sending an invalid request according to GraphQL schema.
  if (Array.isArray(graphQLErrors)) {
    // TODO: remove type assignment after TypeScript 4.1 https://github.com/microsoft/TypeScript/issues/17002
    (graphQLErrors as GraphQLError[]).forEach((error) => {
      // Filter out server-related errors and top-level validation (like auth or permissions).
      if (Object.values(ServerErrorCode).includes(error.extensions?.code)) {
        return;
      }

      const errorMessage = `[GraphQL Error] Operation ${operation.operationName} failed`;
      const serializedError = serializeError(error);
      console.error(errorMessage, serializedError);

      Sentry.withScope((scope) => {
        scope.setExtras(serializedError);
        Sentry.captureMessage(errorMessage, Sentry.Severity.Fatal);
      });
    });
  }

  // Handle unauthorized responses.
  // @ts-ignore
  if (networkError?.statusCode === 401) {
    LocalStorage.remove('token');
    cache.writeData({ data: { isLoggedIn: false } });
    pushError({ message: 'Your session has expired. Log in again.' });
    navigateToRoot();
    return;
  }

  const { isErrorHandledLocally } = operation.getContext() as OperationContext;

  // Exit early to prevent multiple warnings about the same error
  if (isErrorHandledLocally) {
    return;
  }

  // All HTTP 4xx/5xx and server response parsing errors.
  if (networkError) {
    console.error('[Network Error]', networkError.message, serializeError(networkError));
    pushError({ message: 'Operation failed. Try again.', autoHideDuration: 3000 });
  }
});

const httpLink = createHttpLink({
  uri: config.backendApi,
});

const authLink = setContext((_, { headers }) => {
  const token = LocalStorage.get('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      Accept: 'application/json',
    },
  };
});

const link = ApolloLink.from([successLink, errorLink, authLink, httpLink]);

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  resolvers: {},
});

cache.writeData({
  data: {
    isLoggedIn: !!LocalStorage.get('token'),
    isMainBarTangled: false,
  },
});

const GraphqlProvider = ({ children }: ChildrenProps) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;
