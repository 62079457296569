import { Typography } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Stack } from 'components/common/Wrapper/Layout';
import { ModalPageLayout } from 'components/layouts/ModalPageLayout';
import { useLogout, usePasswordReset } from 'hooks/mutation';
import { useSafeState } from 'hooks/useSafeState';
import { URLS } from 'pages/urls';

import { FormData, ResetPasswordForm } from './ResetPasswordForm';
import { ResetPasswordSuccess } from './ResetPasswordSuccess';

import * as Styles from './ResetPassword.styles';

export const ChangePage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [logout] = useLogout({ redirectToRoot: false });
  const [resetPassword, { loading: isLoading }, [errorMessage]] = usePasswordReset();
  const [isSuccess, setIsSuccess] = useSafeState(false);

  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('id');

  if (!token) {
    history.push(URLS.resetPassword.root);
    return null;
  }

  const handleSubmit = async ({ password }: FormData) => {
    const { data } = await resetPassword({ variables: { newPassword: password, token } });
    if (data?.passwordReset?.ok) {
      await logout();
      setIsSuccess(true);
    }
  };

  return (
    <ModalPageLayout>
      <Stack display="flex" alignItems="center" flexDirection="column" py="64px" px="180px">
        <Typography variant="h1" align="center">
          Reset password
        </Typography>
        {isSuccess ? (
          <ResetPasswordSuccess />
        ) : (
          <>
            {errorMessage ? (
              <Styles.ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
            ) : null}
            <ResetPasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
          </>
        )}
      </Stack>
    </ModalPageLayout>
  );
};
