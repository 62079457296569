import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { FormLabel, InfoTooltip } from 'components/common';
import { useOrganizationLogoPreview } from 'context/OrgLogoPositionContext';
import { useUpdateVideo } from 'hooks/mutation';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';

import { CustomSelect } from '../CustomSelect';
import { LogoPositionToggle } from './LogoPositionToggle';

interface LogoPositionPickerProps {
  mb?: string;
  tooltipContent?: string;
  control: Control;
  id: string;
  isLogoEnabled?: boolean;
  logoPosition?: string;
  onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  dirtyFields?: { [key: string]: boolean };
}

const logoPositions = [
  { label: 'Bottom-right', value: 'BOTTOM_RIGHT' },
  { label: 'Bottom-left', value: 'BOTTOM_LEFT' },
  { label: 'Top-left', value: 'TOP_LEFT' },
  { label: 'Top-right', value: 'TOP_RIGHT' },
];

const LOGO_SELECT_INPUT_NAME = 'orgLogoPosition';

export const LogoPositionPicker = ({
  mb = '40px',
  tooltipContent,
  control,
  id,
  isLogoEnabled,
  logoPosition,
  onInputBlur,
  dirtyFields,
}: LogoPositionPickerProps) => {
  const { data } = useOrganizationQuery();
  const organization = data?.me?.organization;
  const [updateVideo] = useUpdateVideo(id);
  const [isChecked, setIsChecked] = useState<boolean>(isLogoEnabled || false);
  const { setIsLogoEnabled } = useOrganizationLogoPreview();

  const handleEnableLogo = async () => {
    await updateVideo({
      variables: {
        id,
        video: {
          // @ts-ignore
          orgLogoEnabled: !isChecked,
        },
      },
    });
  };

  const handleEnableOrgLogo = () => {
    setIsChecked(!isChecked);
    handleEnableLogo();
  };

  useEffect(() => {
    setIsLogoEnabled(isChecked);
  }, [isChecked, setIsLogoEnabled]);

  if (!organization?.logoUrl) return null;

  const hasDirtyFields = dirtyFields && dirtyFields[LOGO_SELECT_INPUT_NAME];

  return (
    <Box mb={mb}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel>
          Logo
          {tooltipContent ? (
            <Box ml="10px" mr="auto">
              <InfoTooltip content={tooltipContent} placement="top" />
            </Box>
          ) : null}
        </FormLabel>
        <Box mb="8px">
          <LogoPositionToggle
            disabled={hasDirtyFields && isChecked}
            checked={isChecked}
            onClick={handleEnableOrgLogo}
          />
        </Box>
      </Box>
      {isChecked && (
        <Box display="flex" alignItems="center">
          <Box width="100%">
            <Controller
              render={(field) => (
                <CustomSelect
                  {...field}
                  id="logo-pos"
                  options={logoPositions}
                  onBlur={onInputBlur}
                />
              )}
              defaultValue={logoPosition || 'BOTTOM_RIGHT'}
              name={LOGO_SELECT_INPUT_NAME}
              control={control}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
