import { IconButton, Tooltip } from '@material-ui/core';
import { cover, ellipsis } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { WidgetEmbed } from 'components/common/WidgetEmbedCode';
import { Table } from 'components/Table';
import { DeleteAction } from 'components/tables/TableActionCell';
import { Permission } from 'config/permissions';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { DashboardPlaylist, PlaylistContentType, PlaylistOrdering } from 'models/Playlist';
import { generatePlaylistEditLink } from 'pages/urls';
import { formatDate } from 'utils/date';

import { useDeletePlaylist } from '../useDeletePlaylist';

interface Props {
  playlist: DashboardPlaylist;
  widgetApiKey?: string;
}

export function PlaylistTableRow({ playlist, widgetApiKey }: Props) {
  const [deletePlaylist] = useDeletePlaylist({
    refetchQueries: ['GetPlaylists'],
  });

  const editPlaylistLink = generatePlaylistEditLink(playlist.id);

  const { hasPermissions } = useUserPermissions();
  const canUpdatePlaylist = hasPermissions([Permission.ChangePlaylist]);
  const canDeletePlaylist = hasPermissions([Permission.DeletePlaylist]);

  return (
    <Table.Row>
      <Table.Cell>
        <EllipsisParent>
          <EllipsisContent>
            <Name to={editPlaylistLink}>{playlist.name}</Name>
          </EllipsisContent>
        </EllipsisParent>
      </Table.Cell>
      <Table.Cell>{formatContentType(playlist.contentType)}</Table.Cell>
      <Table.Cell>{formatOrdering(playlist.orderBy)}</Table.Cell>
      <Table.Cell>{formatDate(new Date(playlist.created))}</Table.Cell>
      <Table.Cell>
        {canUpdatePlaylist ? (
          <Tooltip title="Edit" arrow placement="top">
            <IconButton color="inherit" edge="start" component={Link} to={editPlaylistLink}>
              <Edit />
            </IconButton>
          </Tooltip>
        ) : null}
        <WidgetEmbed id={playlist.id} type="playlist" apiKey={widgetApiKey}>
          {({ showModal }) => {
            return <WidgetEmbed.TableAction showModal={showModal} edge="start" />;
          }}
        </WidgetEmbed>
        {canDeletePlaylist ? (
          <DeleteAction
            type="playlist"
            onDelete={() => deletePlaylist({ variables: { id: playlist.id } })}
            customModalDescription="Are you sure you want to delete this playlist?"
            edge="start"
          />
        ) : null}
      </Table.Cell>
    </Table.Row>
  );
}

const EllipsisParent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 200px;
`;

const EllipsisContent = styled.div`
  ${cover()};
  display: flex;
  align-items: center;
`;

const Name = styled(Link)`
  ${ellipsis()};
  font-weight: 500;
`;

const contentTypeMap: Record<PlaylistContentType, string> = {
  [PlaylistContentType.Moments]: 'Moments',
  [PlaylistContentType.Videos]: 'Videos',
};

function formatContentType(type: PlaylistContentType) {
  return contentTypeMap[type];
}

const orderingMap: Record<PlaylistOrdering, string> = {
  [PlaylistOrdering.Manual]: 'Manual',
  [PlaylistOrdering.PublicationDateAscending]: 'Least recently published',
  [PlaylistOrdering.PublicationDateDescending]: 'Most recently published',
  [PlaylistOrdering.TitleAlphabetical]: 'Alphabetical (title)',
  [PlaylistOrdering.TitleAlphabeticalReversed]: 'Alphabetical descending (title)',
  [PlaylistOrdering.ViewsAscending]: 'Least viewed',
  [PlaylistOrdering.ViewsDescending]: 'Most viewed',
};

function formatOrdering(orderBy: PlaylistOrdering) {
  return orderingMap[orderBy];
}
