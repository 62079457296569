import throttle from 'lodash.throttle';
import React from 'react';

import { RangeSelector } from 'components/RangeSelector';
import { Ruler } from 'components/Ruler';
import { useEvent } from 'hooks/useEvent';
import { useMove } from 'hooks/useMove';
import { usePlayerWidthObservable } from 'hooks/usePlayerSize';
import { Range, RangeBlock, TimeRange, VideoMomentNode } from 'models';
import { pxOrString } from 'utils/styling';
import { SetState } from 'utils/types';

import {
  createNotInRangeWarning,
  getOffsetFromMousePosition,
  getPositionOnTimeline,
  getTimestampOnTimeline,
  isContainedInAllowedRange,
  isVerticalScroll,
  rescaleRange,
} from './Timeline.utils';

import * as Styled from './Timeline.styles';
import { HORIZONTAL_PADDING } from './TimelineEditor.styles';

/** distance between edge of the box and first rendered time label */
const RULER_PADDING = HORIZONTAL_PADDING;

interface OwnProps<TRange extends Range> {
  ranges: TRange[];
  updateRange?: (range: TRange) => void;
  activeRange?: TRange;
  activeBlock?: RangeBlock;
  videoDuration: number;
  playedDuration: number;
  rulerRange: TimeRange;
  setRulerRange: SetState<TimeRange>;
  seekTo: (ms: number) => void;
  /** in ms */
  minRangeLength: number;
  isDisabled?: boolean;
  getBlocksToBeRemoved?: (id: string) => boolean[];
  moments: VideoMomentNode[];
  handleMomentClick?: (momentId: string) => void;
  selectedMomentId?: string;
}

export function Timeline<TRange extends Range>({
  ranges,
  updateRange,
  videoDuration,
  playedDuration,
  rulerRange,
  setRulerRange,
  seekTo,
  minRangeLength,
  isDisabled,
  moments,
}: OwnProps<TRange>) {
  const boxWidth = usePlayerWidthObservable();

  const scrollRef = React.useRef<HTMLDivElement>(null);
  useEvent(scrollRef, 'wheel', (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!scrollRef.current) {
      return;
    }

    if (isVerticalScroll(event)) {
      const newRange = rescaleRange({
        maxRange: videoDuration,
        range: rulerRange,
        scale: event.deltaY,
        centerOffset: getOffsetFromMousePosition(event, scrollRef.current),
      });

      if (!isContainedInAllowedRange(newRange, videoDuration)) {
        console.warn(createNotInRangeWarning(newRange));
        return;
      }

      setRulerRange(newRange);
    }
  });

  const rulerWidth = boxWidth - RULER_PADDING * 2;
  const currentTimestampPosition = getPositionOnTimeline(rulerRange, rulerWidth, playedDuration);

  const handleTimestampChange = React.useCallback(
    (positionPx: number) => {
      const timestamp = getTimestampOnTimeline(rulerRange, rulerWidth, positionPx);
      seekTo(timestamp);
    },
    [rulerRange, rulerWidth, seekTo],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCurrentTimestampChange = React.useCallback(
    throttle(handleTimestampChange, 200, { trailing: true }),
    [handleTimestampChange],
  );

  const lockPositionToBoundaries = React.useCallback(
    (positionPx: number) => {
      const timestamp = getTimestampOnTimeline(rulerRange, rulerWidth, positionPx);
      if (timestamp < 0) {
        return getPositionOnTimeline(rulerRange, rulerWidth, 0);
      }
      if (timestamp > videoDuration) {
        return getPositionOnTimeline(rulerRange, rulerWidth, videoDuration);
      }
      return positionPx;
    },
    [rulerRange, rulerWidth, videoDuration],
  );

  return (
    <div ref={scrollRef}>
      <Ruler
        rulerRange={rulerRange}
        onRulerRangeChange={setRulerRange}
        onRulerClick={handleTimestampChange}
        videoDuration={videoDuration}
        rulerWidth={rulerWidth}
        lockPositionToBoundaries={lockPositionToBoundaries}
        rulerPadding={RULER_PADDING}
      />
      <CurrentTimestamp
        wrapperRef={scrollRef}
        x={currentTimestampPosition}
        onChange={handleCurrentTimestampChange}
        lockPositionToBoundaries={lockPositionToBoundaries}
      />
      <RangeSelector
        ranges={ranges}
        onRangeChange={updateRange}
        rulerRange={rulerRange}
        rulerWidth={rulerWidth}
        seekTo={seekTo}
        videoDuration={videoDuration}
        minRangeLength={minRangeLength}
        isDisabled={isDisabled}
        moments={moments}
      />
    </div>
  );
}

interface CurrentTimestampProps {
  wrapperRef: React.RefObject<HTMLElement>;
  x: number;
  onChange: (position: number) => void;
  lockPositionToBoundaries: (position: number) => number;
}

function CurrentTimestamp({
  wrapperRef,
  x,
  onChange,
  lockPositionToBoundaries,
}: CurrentTimestampProps) {
  const elementRef = React.useRef<HTMLDivElement>(null);

  const handleMove = useMove(elementRef, wrapperRef, (event, { newLeft }) => {
    newLeft = lockPositionToBoundaries(newLeft);
    requestAnimationFrame(() => {
      elementRef.current!.style.transform = `translateX(${pxOrString(newLeft)})`;
    });

    onChange(newLeft);
  });

  return <Styled.CurrentTimestamp ref={elementRef} x={x} onMouseDown={handleMove} />;
}
