import { InputBase } from '@material-ui/core';
import styled from 'styled-components/macro';

interface WrapperProps {
  fullWidth?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')};
  display: flex;
  align-items: center;
`;

export const Input = styled(InputBase)`
  && {
    width: 100%;
    border: none;
    font-size: 18px;
    letter-spacing: 0.34px;
    padding-right: 10px;

    input::placeholder {
      color: #999999;
      opacity: 1;
    }
  }
`;

export const IconButton = styled.button`
  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
`;
