import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, LegalAgreements } from 'models';
import {
  authorizationTokenErrorMap,
  AuthorizationTokenErrorType,
  legalAgreementsErrorMap,
  LegalAgreementsErrorType,
  passwordRequirementsErrorMap,
  PasswordRequirementsErrorType,
} from 'utils/errors';
import { Nullable } from 'utils/types';

type Errors =
  | 'ACCOUNT_FOR_EMAIL_EXISTS'
  | AuthorizationTokenErrorType
  | PasswordRequirementsErrorType
  | LegalAgreementsErrorType;

const errorMap: Record<Errors, string> = {
  ACCOUNT_FOR_EMAIL_EXISTS:
    'Another verified account with this email already exists, try using email alias.',
  ...authorizationTokenErrorMap,
  ...passwordRequirementsErrorMap,
  ...legalAgreementsErrorMap,
};

const QUERY = gql`
  mutation RegisterOrganizationOwner(
    $legalAgreements: UserLegalAgreementsInput!
    $user: CreateOrganizationOwnerAccountInput!
    $subscriptionPlan: SubscriptionPlan!
  ) {
    registerOrganizationOwner(
      legalAgreements: $legalAgreements
      user: $user
      subscriptionPlan: $subscriptionPlan
    ) {
      user {
        email
        id
        name
      }
      ${ERRORS_PART}
    }
  }
`;

export interface OrganizationOwnerAccount {
  email: string;
  id: string;
  name: string;
}

interface ResponseData {
  registerOrganizationOwner: Nullable<{
    user: Nullable<OrganizationOwnerAccount>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  legalAgreements: LegalAgreements;
  user: {
    name: string;
    email: string;
    password: string;
  };
  subscriptionPlan: SubscriptionPlan;
}

export enum SubscriptionPlan {
  trial = 'TRIAL',
  premium = 'PREMIUM',
  unlimited = 'UNLIMITED',
  d2cTrial = 'D2C_TRIAL',
  d2cPremium = 'D2C_PREMIUM',
  d2cUnlimited = 'D2C_UNLIMITED',
}

export const D2CPlans = [
  SubscriptionPlan.d2cPremium,
  SubscriptionPlan.d2cTrial,
  SubscriptionPlan.d2cUnlimited,
];
export const isD2CPlan = (plan: SubscriptionPlan) => D2CPlans.includes(plan);

export const useRegisterOrganizationOwner = () => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'registerOrganizationOwner.errors',
    errorMap,
    skipGlobalErrorHandling: true,
  });
};
