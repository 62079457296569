import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import { Columns } from 'components/common/Wrapper/Layout';
import { Table } from 'components/Table';
import { Permission } from 'config/permissions';
import { useUpdateOrganization } from 'hooks/mutation/useUpdateOrganization';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import useElementSize from 'hooks/useElementSize';
import { OrganizationI } from 'models';

import { ActionContentWidthHandler } from '../types';
import { AddButton } from './MonetizationButtons/AddButton';
import { MonetizationTableRow } from './MonetizationTableRow';
import { MonetizationAllVideosToggle } from './MonetizationToggles/MonetizationAllVideosToggle';
import { Monetization } from './types';

import * as Styled from 'components/common/Table/Table.styles';

interface Props {
  monetizationSettings: Monetization[];
  organization: Pick<OrganizationI, 'id' | 'isMonetizationEnabled'>;
}

export const MonetizationTable: React.FC<Props & ActionContentWidthHandler> = ({
  monetizationSettings,
  organization,
  getActionContentWidth,
}) => {
  const { elementRef } = useElementSize(getActionContentWidth);

  const { hasPermissions } = useUserPermissions();
  const canEditMonetization = hasPermissions([Permission.ChangeOrganizationMonetization]);
  const canRemoveMonetization = hasPermissions([Permission.DeleteOrganizationMonetization]);

  const [updateOrganization] = useUpdateOrganization({
    refetchQueries: ['GetOrganization'],
  });
  const { id, isMonetizationEnabled } = organization;

  const handleMonetizationToggle = () => {
    updateOrganization({
      variables: {
        id,
        organization: {
          isMonetizationEnabled: !isMonetizationEnabled,
        },
      },
    });
  };
  return (
    <Styled.TableWrapper>
      <MonetizationAllVideosToggle
        checked={isMonetizationEnabled}
        onChange={handleMonetizationToggle}
        disabled={!canEditMonetization}
      />
      <LoadingOverlay isVisible={false} />
      <Styled.Table>
        <Table.Head>
          <Table.HeadCell width="70%">Ad tag url name</Table.HeadCell>
          <Table.HeadCell width="10%">Added</Table.HeadCell>
          <Table.HeadCell width="10%">Default</Table.HeadCell>
          <Table.HeadCell width="10%">Active</Table.HeadCell>
          {canEditMonetization || canRemoveMonetization ? (
            <Table.HeadCell>Actions</Table.HeadCell>
          ) : null}
        </Table.Head>
        <Table.Body>
          {monetizationSettings.map((monetization) => {
            return (
              <MonetizationTableRow
                key={monetization.id}
                monetization={monetization}
                isOnlyDefaultAdTag={monetizationSettings.length === 1}
                isMonetizationEnabled={isMonetizationEnabled}
              />
            );
          })}
        </Table.Body>
      </Styled.Table>
      <Styled.ActionsContainer ref={elementRef}>
        <Columns display="flex" alignItems="center">
          <AddButton />
        </Columns>
      </Styled.ActionsContainer>
    </Styled.TableWrapper>
  );
};
