import React from 'react';

import { VideoStatus } from 'models';
import { canVideoBePublished, isVideoPublished } from 'utils/videos';

import { PublishableWidgetEmbedModal } from '../PublishableWidgetEmbedModal';
import { EmbedButton, EmbedTableAction, PassedProps } from './EmbedButtons';

interface Props {
  type: 'video' | 'moment';
  videoStatus: VideoStatus;
  id: string;
  videoId: string;
  apiKey?: string;
  canUserPublishVideo: boolean;
  children: (props: PassedProps) => React.ReactNode;
}

export function PublishableWidgetEmbed({
  type,
  videoStatus,
  id,
  videoId,
  apiKey,
  canUserPublishVideo,
  children,
}: Props) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const videoCanBePublished = canVideoBePublished(videoStatus);
  const videoIsPublished = isVideoPublished(videoStatus);

  const canGenerateEmbed = canUserPublishVideo ? videoCanBePublished : videoIsPublished;

  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);

  if (!apiKey) {
    return null;
  }

  return (
    <>
      {canGenerateEmbed ? children({ showModal }) : null}
      <PublishableWidgetEmbedModal
        isOpen={isModalOpen}
        onClose={hideModal}
        type={type}
        id={id}
        videoId={videoId}
        apiKey={apiKey}
        isVideoPublished={videoIsPublished}
        canVideoBePublished={canUserPublishVideo && videoCanBePublished}
      />
    </>
  );
}

PublishableWidgetEmbed.Button = EmbedButton;
PublishableWidgetEmbed.TableAction = EmbedTableAction;
