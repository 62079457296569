import React from 'react';

import { LocalStorage } from 'utils/storage';

export type SetValue<ValueType> = (value: ValueType, saveToLocalStorage?: boolean) => void;

function useLocalStorage<ValueType>(key: string, initialValue?: ValueType) {
  const [storedValue, setStoredValue] = React.useState<ValueType>(() => {
    try {
      const value = LocalStorage.get(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      console.error(`LocalStorage: failed to read value under key "${key}"`, error);
      return initialValue;
    }
  });

  const setValue = React.useCallback<SetValue<ValueType>>(
    (value, saveToLocalStorage = true) => {
      setStoredValue(value);

      if (saveToLocalStorage) {
        try {
          LocalStorage.set(key, JSON.stringify(value));
        } catch (error) {
          console.error(`LocalStorage: failed to save "${value}" under key "${key}"`, error);
        }
      }
    },
    [key],
  );

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
