import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Typography } from 'components/common';
import { BigCard } from 'components/common/Wrapper/Card';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import MainLayout from 'components/layouts/MainLayout';
import { URLS } from 'pages/urls';

export const Information: React.VFC = () => {
  return (
    <MainLayout>
      <Stack p="40px">
        <Typography variant="h1">Microsite</Typography>
        <BigCard p="70px">
          <Title>You don’t have any microsites yet</Title>
          <Stack
            display="flex"
            flexDirection="column"
            alignItems="center"
            maxWidth="768px"
            mx="auto"
          >
            <Text>
              A microsite is a standardized web site where you can publish your videos and moments.
              As part of your account, you can set up your personal site and share the link. You can
              pick a name for the subdomain (but choose wisely, as you can't change it later), you
              can customize the highlight color, and you can even upload your logo if you have one.
              Super simple, and straight forward.
            </Text>
            <Columns>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={URLS.microsite.newMicrosite}
              >
                Create Microsite
              </Button>
            </Columns>
          </Stack>
        </BigCard>
      </Stack>
    </MainLayout>
  );
};

const Title = styled.h2`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
`;
