import React from 'react';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { getTimestampOnTimeline } from 'components/TimelineEditor/Timeline.utils';
import { TimeRange } from 'models';
import { mouseMoveEventsStreamUntilMouseUp } from 'utils/observables';

type Event$ = React.MouseEvent<HTMLDivElement>;
type Inputs$ = [TimeRange, number, (rulerRange: TimeRange) => void, (positionPx: number) => void];

const eventStopPropagation = tap((event: Event$) => {
  event.stopPropagation();
  event.preventDefault();
});

const mapEventToSliderCoordinates = map(([event, [rulerRange]]: [Event$, Inputs$]) => {
  const { left, width } = event.currentTarget.getBoundingClientRect();
  const dragStartMs = getTimestampOnTimeline(rulerRange, width, event.clientX - left);
  return [dragStartMs, left, width] as const;
});

const getPermissibleShift = (rulerRange: TimeRange, dragDiff: number, videoDuration: number) =>
  rulerRange[0] - dragDiff < 0
    ? rulerRange[0]
    : rulerRange[1] - dragDiff > videoDuration
    ? rulerRange[1] - videoDuration
    : dragDiff;

export const mouseDownEventCallback = (
  event$: Observable<Event$>,
  state$: Observable<TimeRange>,
  inputs$: Observable<Inputs$>,
) => {
  const mouseDownClickCoordinates$ = event$.pipe(
    eventStopPropagation,
    withLatestFrom(inputs$),
    mapEventToSliderCoordinates,
  );

  return event$.pipe(
    switchMap(mouseMoveEventsStreamUntilMouseUp),
    withLatestFrom(inputs$, mouseDownClickCoordinates$),
    map(
      ([clientX, [rulerRange, videoDuration], [dragStartMs, offsetLeft, width]]): TimeRange => {
        const dragEndMs = getTimestampOnTimeline(rulerRange, width, clientX - offsetLeft);
        const dragDiff = dragEndMs - dragStartMs;
        const possibleChange = getPermissibleShift(rulerRange, dragDiff, videoDuration);

        return [rulerRange[0] - possibleChange, rulerRange[1] - possibleChange];
      },
    ),
    distinctUntilChanged((prev, cur) => prev[0] === cur[0] && prev[1] === cur[1]),
    withLatestFrom(inputs$),
    tap(([rulerRange, [, , onRangesChange]]) => onRangesChange(rulerRange)),
    map(([ranges]) => ranges),
  );
};
