import { Box } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Compass } from 'assets/icons/compass.svg';
import { ReactComponent as Duplicate } from 'assets/icons/duplicate.svg';
import { ReactComponent as Info } from 'assets/icons/info-circle.svg';
import { ReactComponent as Playlist } from 'assets/icons/playlist.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Widget } from 'assets/icons/widget.svg';
import { ReactComponent as Play } from 'assets/icons/youtube.svg';
import logo from 'assets/images/seeen-logo--white.png';
import { Link } from 'components/common';
import { UserProfile } from 'components/UserProfile';
import { Permission } from 'config/permissions';
import { TEST_ID } from 'config/test-ids';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { notEmpty } from 'utils/common';

import { URLS } from '../../pages/urls';
import * as Navbar from './Navbar.styled';

interface MenuItem {
  item: string;
  icon: JSX.Element;
  link: string;
  matchingUrls: string[];
}

const getMenuItems = (hasPermissions: (permissions: Permission[]) => boolean): MenuItem[] =>
  [
    hasPermissions([Permission.ViewVideoDashboard])
      ? {
          item: 'Videos',
          icon: <Play width="24" height="24" />,
          link: URLS.root,
          matchingUrls: [
            URLS.root,
            URLS.dashboard.root,
            URLS.dashboard.videos,
            URLS.dashboard.moments,
          ],
        }
      : null,
    hasPermissions([Permission.ViewD2CDashboard])
      ? {
          item: 'D2C',
          icon: <Play width="24" height="24" />,
          link: URLS.d2c.root,
          matchingUrls: [URLS.root, URLS.d2c.root, URLS.d2c.apiKey, URLS.d2c.serviceUsage],
        }
      : null,
    hasPermissions([Permission.ViewCarousel])
      ? {
          item: 'Widget',
          icon: <Widget />,
          link: URLS.widget.root,
          matchingUrls: [URLS.widget.root],
        }
      : null,
    hasPermissions([Permission.ViewPlaylist])
      ? {
          item: 'Playlists',
          icon: <Playlist />,
          link: URLS.playlists.list,
          matchingUrls: [URLS.playlists.list],
        }
      : null,
    hasPermissions([Permission.ViewMicrosite])
      ? {
          item: 'Microsite',
          icon: <Compass />,
          link: URLS.microsite.root,
          matchingUrls: [URLS.microsite.root],
        }
      : null,
    hasPermissions([Permission.ManageOrganization])
      ? {
          item: 'Manage my Organization',
          icon: <User />,
          link: URLS.organization.root,
          matchingUrls: [URLS.organization.root],
        }
      : null,
    hasPermissions([Permission.ViewEndCard])
      ? {
          item: 'Overlays',
          icon: <Duplicate />,
          link: URLS.overlays.root,
          matchingUrls: [URLS.overlays.root],
        }
      : null,
    {
      item: 'Settings',
      icon: <Settings />,
      link: URLS.settings.root,
      matchingUrls: [],
    },
    {
      item: 'Help',
      icon: <Info />,
      link: URLS.help.root,
      matchingUrls: [],
    },
  ].filter(notEmpty);

interface SuperLinkProps {
  link: string;
}

const SuperLink: React.FC<SuperLinkProps> = ({ link, children }) => {
  const isLinkInternal = link.startsWith('/');

  if (isLinkInternal) {
    return <Link to={link}>{children}</Link>;
  }

  return (
    <Navbar.ExternalLink href={link} target="_blank" rel="noreferrer noopener">
      {children}
    </Navbar.ExternalLink>
  );
};

interface StyledNavbarProps {
  miniNavbar?: boolean;
}

const StyledNavbar = ({ miniNavbar }: StyledNavbarProps) => {
  const location = useLocation();
  const { hasPermissions } = useUserPermissions();
  const menuItems = getMenuItems(hasPermissions);

  const isMenuItemActive = (link: string, matchingUrls: Array<string>) => {
    return (
      matchingUrls.includes(location.pathname) ||
      (link !== menuItems[0].link && location.pathname.includes(link))
    );
  };

  return (
    <Box
      bgcolor="grey.900"
      p="40px 20px 20px 20px"
      component="nav"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flex={`0 0 ${miniNavbar ? '130px' : '270px'}`}
      flexDirection="column"
      overflow="auto"
      data-testid={TEST_ID.navbar}
    >
      <Link to="/">
        <Navbar.Logo src={logo} alt="Seeen logo" miniNavbar={miniNavbar} />
      </Link>
      <Navbar.Menu miniNavbar={miniNavbar}>
        {menuItems.map(({ item, icon, link, matchingUrls }) => (
          <SuperLink link={link} key={item}>
            {isMenuItemActive(link, matchingUrls)}
            <Navbar.MenuItem miniNavbar={miniNavbar} active={isMenuItemActive(link, matchingUrls)}>
              <Box
                bgcolor={isMenuItemActive(link, matchingUrls) ? 'primary.main' : 'grey.800'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="48px"
                width="48px"
                borderRadius="12px"
                mr={miniNavbar ? '0' : '20px'}
                flexShrink="0"
                color="common.white"
              >
                {icon}
              </Box>
              {!miniNavbar && item}
            </Navbar.MenuItem>
          </SuperLink>
        ))}
      </Navbar.Menu>
      <UserProfile miniNavbar={miniNavbar} />
    </Box>
  );
};

export default StyledNavbar;
