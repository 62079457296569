import { CREATE_IMAGE_CTA_MUTATION } from 'context/queries/imageCTAMutations';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { CreateImageCTAInput, ImageCTAResponse } from 'pages/CTAImage/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  createImageCta: Nullable<{
    imageCta: Nullable<ImageCTAResponse>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  image_cta: CreateImageCTAInput;
}

interface UseCreateImageCTA extends UseMutationOptions<ResponseData, Variables> {}

export const useCreateImageCTA = (options: UseCreateImageCTA = {}) => {
  return useMutation<ResponseData, Variables>(CREATE_IMAGE_CTA_MUTATION, {
    pathToErrors: 'createImageCta.errors',
    ...options,
  });
};
