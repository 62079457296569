import { Box } from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { BrandingUploadInput } from 'components/common/BrandingUploadInput';
import { MAX_IMAGE_SIZE } from 'config/constants';
import {
  ACCEPTED_FORMATS_ERROR_MESSAGE,
  ACCEPTED_IMAGE_FORMATS,
  MAX_SIZE_ERROR_MESSAGE,
  VALIDATION_FILE_MESSAGE,
} from 'pages/CTAImage/constants';
import { exceedsMaxAllowedFileSize } from 'utils/validation';

interface BrandingTabProps {
  getActionContentWidth: (width: number) => void;
}

export const BrandingTab = ({ getActionContentWidth }: BrandingTabProps) => {
  const { control, setError, clearErrors, errors } = useForm();

  const handleFileValidation = React.useCallback(
    async (file: File) => {
      if (exceedsMaxAllowedFileSize(MAX_IMAGE_SIZE, file.size)) {
        setError('orgLogo', { message: MAX_SIZE_ERROR_MESSAGE });
        return false;
      }

      if (file.name) {
        const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
        if (!ACCEPTED_IMAGE_FORMATS.includes(fileExtension)) {
          setError('orgLogo', { message: ACCEPTED_FORMATS_ERROR_MESSAGE });
          return false;
        }
      }

      clearErrors('orgLogo');
      return true;
    },
    [setError, clearErrors],
  );

  return (
    <div style={{ padding: '23px 32px' }}>
      <Box maxWidth={512}>
        <Controller
          name="orgLogo"
          control={control}
          rules={{ required: 'Image cannot be empty', validate: handleFileValidation }}
          render={(field) => {
            return (
              <BrandingUploadInput
                {...field}
                label="Logo"
                info="Your organization logo"
                onFileValidation={handleFileValidation}
                error={!!errors.orgLogo}
                errorMessage={errors.orgLogo?.message}
                validationMessage={VALIDATION_FILE_MESSAGE}
                mb="40px"
                tooltipStyle={{ marginLeft: '8px', marginRight: 'auto' }}
              />
            );
          }}
        />
      </Box>
    </div>
  );
};
