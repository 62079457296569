import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { CONDENSED_FONT } from 'utils/styling';

interface LabelProps {
  error?: boolean;
  moment?: boolean;
}

export const FormLabel = styled.label<LabelProps>`
  color: ${({ error }) => (error ? materialTheme.palette.error.main : '#323343')};
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${CONDENSED_FONT};
  font-size: 14px;
  line-height: ${({ moment }) => moment && '42px'};
  font-weight: 600;
  letter-spacing: 0.32px;
  margin-bottom: ${({ moment }) => (moment ? '0px' : '8px')};
`;
