import React from 'react';

import * as Styled from './Table.styles';

interface HeadProps {
  children?: React.ReactNode;
}

function Head({ children }: HeadProps) {
  return (
    <thead>
      <Styled.Head>{children}</Styled.Head>
    </thead>
  );
}

interface TableProps {
  fullWidth?: boolean;
  children?: React.ReactNode;
}

export function Table({ fullWidth, children }: TableProps) {
  return <Styled.Table fullWidth={fullWidth}>{children}</Styled.Table>;
}

Table.Head = Head;
Table.HeadCell = Styled.HeadCell;
Table.Body = Styled.Body;
Table.Row = Styled.Row;
Table.Cell = Styled.Cell;
