import { QUERY_VIDEO_STATUS, UPDATE_OBJECT_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, ObjectMetadataFragment, UpdateObjectInput } from 'models';
import { pushOperationErrors } from 'utils/errors';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateObjectAnnotation: Nullable<{
    objectAnnotation: Nullable<ObjectMetadataFragment>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  objectId: string;
  objectAnnotation: UpdateObjectInput;
}

interface UseUpdateObjectOptions {
  onCompleted?: (data: ResponseData) => void;
  videoId?: string;
}

export const useUpdateObject = ({ onCompleted, videoId }: UseUpdateObjectOptions) => {
  return useMutation<ResponseData, Variables>(UPDATE_OBJECT_MUTATION, {
    skipGlobalErrorHandling: true,
    onCompleted: (data) => {
      onCompleted && onCompleted(data);
      pushOperationErrors('updateObjectAnnotation.errors', data);
    },
    refetchQueries: ({ data }) => {
      return !!data.updateObjectAnnotation?.objectAnnotation
        ? [{ query: QUERY_VIDEO_STATUS, variables: { id: videoId } }]
        : [];
    },
  });
};
