import styled from 'styled-components';

import { materialTheme } from 'components/App/materialTheme';
import { Stack } from 'components/common/Wrapper/Layout';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  padding: 40px;
`;

export const Title = styled.h1`
  display: block;
  flex-shrink: 0;
  font-size: 28px;
  font-weight: 600;
  height: 70px;
`;

export const Container = styled.div`
  background-color: ${materialTheme.palette.background.paper};
  border-radius: 15px;
  box-shadow: 0px 2px 60px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
  justify-content: space-between;
  width: 100%;
`;

export const Bar = styled.div`
  flex-shrink: 0;
  height: 110px;
`;

export const ContentContainer = styled.div`
  align-items: center;
  border-bottom: 2px solid ${materialTheme.palette.background.default};
  border-top: 2px solid ${materialTheme.palette.background.default};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 40px;
`;

export const Content = styled.div<{ isVisible?: boolean }>`
  align-items: center;
  display: ${({ isVisible = true }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
`;

export const EditForm = styled(Stack)`
  display: flex;
  flex-flow: column nowrap;
`;
