import { Box } from '@material-ui/core';
import React from 'react';
import { Settings } from 'react-slick';

import { ThumbnailI } from 'models';
import { Nullable } from 'utils/types';

import { Thumbnail } from './Thumbnail';

import * as Styled from './ThumbnailSelect.styles';

const settingsWithArrows: Settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
};

const settingsNoArrows: Settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
};

interface SlidesProps {
  thumbnails?: ThumbnailI[];
  selectedId?: Nullable<string>;
  onSelect?: (id: string) => void;
  onRemove: (id: string) => void;
  isSelectDisabled?: boolean;
  isDeleteDisabled?: boolean;
}

const Slides = ({
  thumbnails = [],
  selectedId,
  onSelect,
  onRemove,
  isSelectDisabled,
  isDeleteDisabled,
}: SlidesProps) => {
  const settings = thumbnails.length > 3 ? settingsWithArrows : settingsNoArrows;
  const thumbnailsFiltered = thumbnails.sort((prev, curr) =>
    prev.id === selectedId ? -1 : curr.id === selectedId ? 1 : 0,
  );

  return (
    <Box width="315px">
      <Styled.Slider {...settings}>
        {thumbnailsFiltered.map((thumb) => (
          <Thumbnail
            key={thumb.id}
            url={thumb.imageUrl}
            selected={selectedId === thumb.id}
            onSelect={onSelect && !isSelectDisabled ? () => onSelect(thumb.id) : undefined}
            onRemove={!isDeleteDisabled ? () => onRemove(thumb.id) : undefined}
          />
        ))}
      </Styled.Slider>
    </Box>
  );
};

export default Slides;
