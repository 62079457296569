import { CHANGE_USER_EMAIL_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';

export interface UpdateUserEmailResponse {
  updateUserEmail: {
    errors: ErrorType[];
  };
}

interface Variables {
  token: string;
}

interface UseUpdateUserEmailOptions
  extends UseMutationOptions<UpdateUserEmailResponse, Variables> {}

export const useUpdateUserEmail = (options: UseUpdateUserEmailOptions = {}) => {
  return useMutation<UpdateUserEmailResponse, Variables>(CHANGE_USER_EMAIL_MUTATION, {
    pathToErrors: 'updateUserEmail.errors',
    ...options,
  });
};
