import { Button } from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { pushMessage } from 'context/globalStream';
import { useSafeState } from 'hooks/useSafeState';
import { UserRole } from 'models';
import { isValidEmail, validate } from 'utils/validation';

import { RoleDropdown } from './RoleDropdown';
import { useInviteOrganizationMember } from './useInviteOrganizationMember';

import * as Styled from '../OrganizationTab/ChangeNameModal.styles';

interface FormData {
  email: string;
  role: UserRole;
}

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const InviteMemberModal: React.VFC<Props> = ({ onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useSafeState(false);

  const [inviteMember, , [errorMessage], resetErrorMessage] = useInviteOrganizationMember();

  const { register, handleSubmit, errors, formState, control } = useForm<FormData>({
    mode: 'onChange',
  });

  // Read the `formState` before render to subscribe to the form state proxy
  const { isDirty, isValid } = formState;

  const onSubmit = async ({ email, role }: FormData) => {
    const cleanEmail = email.trim();
    setIsLoading(true);

    const { data } = await inviteMember({
      variables: {
        organizationMember: {
          email: cleanEmail,
          role,
        },
      },
    });

    if (data?.inviteOrganizationMember?.organizationMember?.email === cleanEmail) {
      pushMessage({ message: 'New member has been invited. Let them know to check their inbox.' });
      onClose();
    } else {
      setIsLoading(false);
    }
  };

  const handleExited = () => {
    resetErrorMessage();
    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpen}
      heading={`Invite member`}
      width={600}
    >
      <form name="invite-member" onSubmit={handleSubmit(onSubmit)}>
        <Stack display="flex" alignItems="center" flexDirection="column" mt="50px" rowGap={50}>
          {errorMessage ? (
            <Styled.ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
          ) : null}
          <Stack textAlign="left" width="400px">
            <TextInput
              name="email"
              label="Email Address"
              placeholder="Write their email address here"
              ref={register({
                validate: {
                  email: validate(isValidEmail, 'Incorrect email format'),
                },
              })}
              error={!!errors.email}
              errorMessage={errors.email?.message}
              mb="0"
            />
            <Controller
              render={({ onChange, value, name, ref }) => {
                return (
                  <RoleDropdown
                    ref={ref}
                    id="member-role"
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={UserRole.VideoEditor}
              name="role"
              control={control}
            />
          </Stack>
          <Columns display="flex" flexDirection="row" justifyContent="center">
            <Button variant="outlined" onClick={onClose} color="secondary" type="button">
              Cancel
            </Button>
            <LoadingButton
              disabled={!isValid || !isDirty}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Send invite
            </LoadingButton>
          </Columns>
        </Stack>
      </form>
    </Dialog>
  );
};
