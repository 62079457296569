import React from 'react';

import Tab, { TabProps } from 'components/common/Tab';

import RouterLink from './RouterLink';

interface OwnProps {
  to?: string;
}

type Props = OwnProps & TabProps;

function TabLink({ to, value, ...props }: Props) {
  return (
    <Tab
      // @ts-ignore: `component` is forwared to ButtonBase https://material-ui.com/api/tab/#props
      component={RouterLink}
      to={to ?? value}
      value={value}
      {...props}
    />
  );
}

export default TabLink;
