import { Box } from '@material-ui/core';
import React, { FormEventHandler } from 'react';
import { Control } from 'react-hook-form';

import { BackButton, Typography } from 'components/common';
import { VideoMonetization } from 'models';
import { Monetization } from 'pages/Organization/MonetizationTab/types';
import { generateVideoLink } from 'pages/urls';
import { Nullable } from 'utils/types';

import { AdTagSelect } from './Selects';
import { VideoMonetizationSidebarToggle } from './Toggles';

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  control: Control;
  monetizationSettings?: Monetization[];
  videoId: string;
  monetization: Nullable<VideoMonetization>;
  isMonetizationEnabled: boolean;
}

export const VideoMonetizationSidebar = ({
  videoId,
  monetization,
  onSubmit,
  control,
  monetizationSettings,
  isMonetizationEnabled,
}: Props) => {
  const videoEditorUrl = generateVideoLink(videoId);

  return (
    <form onSubmit={onSubmit} id="video-monetization-settings">
      <BackButton label="Back to video editor" link={videoEditorUrl} />
      <Box m="22px 0 32px 0">
        <Typography variant="h1" color="textPrimary">
          Monetization
        </Typography>
      </Box>
      <Box mb="36px" display="flex" alignItems="center" justifyContent="space-between">
        <VideoMonetizationSidebarToggle
          videoId={videoId}
          isMonetizationEnabled={isMonetizationEnabled}
          isVideoMonetizationEnabled={!!monetization?.isMonetizationEnabled}
        />
      </Box>
      <Box mb="36px">
        <AdTagSelect
          control={control}
          defaultValue={monetization?.orgMonetization}
          options={monetizationSettings}
        />
      </Box>
      {/* ARE HIDDEN UNTIL SC-156 WILL BE COMPLETED */}
      {/* <Box mb="36px">
        <AdTypeSelect control={control} defaultValue={monetization?.adType} />
      </Box>
      <AdLocationToggles
        adDensity={monetization?.adDensity}
        adLocation={monetization?.adLocation}
        control={control}
      /> */}
    </form>
  );
};
