import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PaginationVariables } from 'models';
import { EditorPlaylist } from 'models/Playlist';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetEditorPlaylistById($id: ID!, $page: Int, $pageSize: Int) {
    playlistById(id: $id) {
      id
      contentType
      name
      orderBy
      filterBy
      filterValue
      items(page: $page, pageSize: $pageSize) {
        nodes {
          ... on PublicVideoType {
            id
            title
            description
            thumbnailUrl
          }
          ... on PublicMomentType {
            id
            title
            description
            thumbnailUrl
            video {
              id
            }
          }
        }
      }
    }
  }
`;

interface ResponseData {
  playlistById: Nullable<EditorPlaylist>;
}

interface Variables extends Partial<PaginationVariables> {
  id: string;
}

interface UseEditorPlaylistByIdQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export function useEditorPlaylistById(options?: UseEditorPlaylistByIdQueryOptions) {
  const results = useQuery<ResponseData, Variables>(QUERY, options);

  return {
    ...results,
    playlist: results.data?.playlistById,
  };
}
