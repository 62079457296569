import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { ModalPageLayout } from 'components/layouts/ModalPageLayout';
import { useSafeState } from 'hooks/useSafeState';
import { useStripe } from 'hooks/useStripe';
import { URLS } from 'pages/urls';

import { FormData, OrganizationForm } from './OrganizationForm';
import { useCreateOrganization } from './useCreateOrganization';

// TODO: extract this into common component
import { ErrorMessage } from 'pages/ResetPassword/ResetPassword.styles';

export const RegisterOrganizationPage: React.VFC = () => {
  const [stripe] = useStripe();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('id');

  const [createOrganization, { loading: isSubmitting }, [errorMessage]] = useCreateOrganization();
  const [successName, setSuccessName] = useSafeState<string>();

  if (!token) {
    history.push(URLS.root);
    return null;
  }

  const handleSubmit = async ({ organization }: FormData) => {
    const successUrl = new URL(URLS.register.successPayment, window.location.origin);
    successUrl.searchParams.set('successName', organization);
    const cancelUrl = new URL(window.location.origin);

    const { data } = await createOrganization({
      variables: {
        organization: { name: organization },
        token,
        paymentSession: {
          successUrl: successUrl.toString(),
          cancelUrl: cancelUrl.toString(),
        },
      },
    });
    const paymentSessionId = data?.createOrganization?.paymentSession?.stripePaymentSessionId;
    if (paymentSessionId && stripe) {
      stripe.redirectToCheckout({ sessionId: paymentSessionId });
      return;
    }
    if (data?.createOrganization?.organization?.name) {
      setSuccessName(data?.createOrganization?.organization?.name);
    }
  };

  return (
    <ModalPageLayout>
      <Stack
        rowGap={40}
        display="flex"
        alignItems="center"
        flexDirection="column"
        py="64px"
        px="180px"
      >
        <Box textAlign="center" width="100%">
          <Typography variant="h1">Verify an account</Typography>
        </Box>
        {successName ? (
          <OrganizationCreated successName={successName} />
        ) : (
          <>
            {errorMessage ? (
              <ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
            ) : null}
            <OrganizationForm
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              disabled={!stripe}
            />
          </>
        )}
      </Stack>
    </ModalPageLayout>
  );
};

const OrganizationCreated: React.VFC<{ successName: string | null; successPayment?: boolean }> = ({
  successName,
  successPayment = false,
}) => {
  return (
    <Stack>
      <Box textAlign="center">
        {successName && (
          <>
            Organization <strong>{successName}</strong> created!
          </>
        )}
        <br />
        {successPayment && (
          <p>
            Payment has been successful finalized and your <strong>Premium Plan</strong> has been
            activated.
          </p>
        )}
        You can now log in with your previously created account.
      </Box>
      <Columns display="flex" justifyContent="center">
        <Button variant="contained" color="primary" component={Link} to={URLS.root}>
          Log in
        </Button>
      </Columns>
    </Stack>
  );
};

export const SuccessPaymentPage: React.VFC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const successName = urlParams.get('successName');
  return (
    <ModalPageLayout>
      <Stack
        rowGap={40}
        display="flex"
        alignItems="center"
        flexDirection="column"
        py="64px"
        px="180px"
      >
        <OrganizationCreated successName={successName} successPayment={true} />
      </Stack>
    </ModalPageLayout>
  );
};
