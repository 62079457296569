import { lighten } from 'polished';
import React from 'react';

import { materialTheme } from 'components/App/materialTheme';
import { Badge } from 'components/common/Badge';

interface Props {
  healthy?: boolean;
}

export const MicroSiteStatus: React.FC<Props> = ({ healthy }) => {
  const colors = healthy
    ? {
        backgroundColor: lighten(0.85, materialTheme.palette.text.primary),
        color: materialTheme.palette.text.primary,
      }
    : {
        backgroundColor: lighten(0.36, materialTheme.palette.warning.main),
        color: materialTheme.palette.warning.main,
      };

  return <Badge {...colors}>{healthy ? 'PUBLISHED' : 'PUBLISHING'}</Badge>;
};
