import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { ImageCTAResponse } from 'pages/CTAImage/types';
import { Nullable } from 'utils/types';

export const QUERY = gql`
  query GetImageCta($id: ID!) {
    imageCtaById(id: $id) {
      id
      created
      name
      url
      imageUrl
    }
  }
`;

interface ResponseData {
  imageCtaById: Nullable<ImageCTAResponse>;
}

interface Variables {
  id?: string;
}

interface UseImageCTAQuery extends UseQueryOptions<ResponseData, Variables> {}

export const useImageCTAQuery = (options?: UseImageCTAQuery) => {
  return useQuery<ResponseData, Variables>(QUERY, {
    ...options,
    fetchPolicy: 'cache-first',
  });
};
