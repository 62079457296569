import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { TagsField, TextInput } from 'components/common';
import { EndCardPicker } from 'components/common/EndCardPicker';
import { useTagsState } from 'components/common/TagsField/TagsField';
import ThumbnailSelect from 'components/ThumbnailSelect';
import { MAX_THUMBNAILS_PER_MOMENT } from 'config/constants';
import { Permission } from 'config/permissions';
import { pushMessage } from 'context/globalStream';
import { QUERY_VIDEO_STATUS } from 'context/queries';
import {
  useCreateMomentThumbnail,
  useDeleteMomentThumbnail,
  useUpdateMoment,
} from 'hooks/mutation';
import { useMomentThumbnailsQuery } from 'hooks/query/useThumbnailsQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useFormAutosave } from 'hooks/useFormAutosave';
import { TagTypes, VideoMomentNode } from 'models';
import { LocalStorage } from 'utils/storage';
import { pushMaxThumbnailCountError } from 'utils/thumbnails';
import { isNotEmptyString, validate } from 'utils/validation';

import { useMomentsEditorContext } from './context';
interface FormData {
  title: string;
  description: string;
  endCardId?: string | null;
}

interface ExtendedFormData extends FormData {
  tags: string[];
  internalTags: string[];
}

export type { ExtendedFormData as FormData };

interface OwnProps {
  moment: VideoMomentNode;
  enableAutosave?: boolean;
  videoId: string;
}

const FORM_ID = 'moment-form';
const TAGS_EDITED = (id: string) => `momentTagsEdited${id}`;
const INTERNAL_TAGS_EDITED = (id: string) => `momentInternalTagsEdited${id}`;

export const SingleMomentEditor = ({ moment, enableAutosave = true, videoId }: OwnProps) => {
  const { handleSetChangesSaved } = useMomentsEditorContext();

  const [tags, setTags] = useTagsState(moment.tags);
  const [internalTags, setInternalTags] = useTagsState(moment.internalTags);
  const [selectedThumbnailId, setSelectedThumbnailId] = useState(moment.thumbnailId);
  const [selectedEndCardId, setSelectedEndCardId] = useState(moment.endCard?.id);
  const [tagsChanged, setTagsChanged] = useState(false);
  const [internalTagsChanged, setInternalTagsChanged] = useState(false);
  const [updateMoment] = useUpdateMoment({
    refetchQueries: [{ query: QUERY_VIDEO_STATUS, variables: { id: videoId } }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      handleSetChangesSaved(true);
    },
  });

  const { submitRef, triggerFormSubmit } = useFormAutosave(
    [tags, selectedThumbnailId, internalTags, selectedEndCardId],
    enableAutosave,
  );

  const { register, handleSubmit, formState, control, errors } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  useEffect(() => {
    const { id } = moment;
    const tagsEditedValue = LocalStorage.get(TAGS_EDITED(id));
    const internalTagsEditedValue = LocalStorage.get(INTERNAL_TAGS_EDITED(id));

    if (moment.video.status === 'EDITED' && tagsEditedValue) {
      setTagsChanged(true);
    } else {
      LocalStorage.remove(TAGS_EDITED(id));
      setTagsChanged(false);
    }

    if (moment.video.status === 'EDITED' && internalTagsEditedValue) {
      setInternalTagsChanged(true);
    } else {
      LocalStorage.remove(INTERNAL_TAGS_EDITED(id));
      setInternalTagsChanged(false);
    }
  }, [moment]);

  const { dirtyFields } = formState;

  const onSubmit = (data: FormData) => {
    return updateMoment({
      variables: {
        id: moment.id,
        moment: {
          title: data.title.trim(),
          description: data.description.trim(),
          tags,
          internalTags,
          thumbnailId: selectedThumbnailId ?? undefined,
          endCardId: data.endCardId || null,
        },
      },
    });
  };

  const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!enableAutosave) {
      return;
    }
    const { name } = event.currentTarget;
    if (name in dirtyFields) {
      triggerFormSubmit();
    }
  };

  const { hasPermissions } = useUserPermissions();
  const canUploadThumbnails = hasPermissions([Permission.CreateMomentThumbnail]);
  const canDeleteThumbnails = hasPermissions([Permission.DeleteMomentThumbnail]);
  const canUserUpdateMoment = hasPermissions([Permission.ChangeMoment]);
  const canChangeMetadata = hasPermissions([Permission.ChangeMoment]);

  const { thumbnails } = useMomentThumbnailsQuery({ variables: { momentId: moment.id } });
  const [createThumbnailMutation] = useCreateMomentThumbnail(moment.id, videoId);

  const addNewThumbnail = React.useCallback(
    async (imageBase64: string) => {
      if (thumbnails.length + 1 > MAX_THUMBNAILS_PER_MOMENT) {
        pushMaxThumbnailCountError(MAX_THUMBNAILS_PER_MOMENT);
        return;
      }

      const { data } = await createThumbnailMutation({
        variables: { momentId: moment.id, imageBase64 },
      });

      if (data?.createMomentThumbnail?.thumbnail) {
        setSelectedThumbnailId(data.createMomentThumbnail.thumbnail.id);
        pushMessage({ message: 'Thumbnail successfully uploaded' });
      }
    },
    [createThumbnailMutation, moment.id, thumbnails.length],
  );

  const handleTagEdited = () => {
    LocalStorage.set(TAGS_EDITED(moment.id), true);
    setTagsChanged(true);
  };

  const handleInternalTagEdited = () => {
    LocalStorage.set(INTERNAL_TAGS_EDITED(moment.id), true);
    setInternalTagsChanged(true);
  };

  const [deleteThumbnailMutation] = useDeleteMomentThumbnail(moment.id, videoId);

  const removeThumbnail = React.useCallback(
    async (id: string) => {
      const { data } = await deleteThumbnailMutation({
        variables: { id },
      });

      if (data?.deleteMomentThumbnail?.deletedMomentThumbnailId) {
        handleSetChangesSaved(true);
        pushMessage({ message: 'Thumbnail successfully deleted' });
      }
    },
    [deleteThumbnailMutation, handleSetChangesSaved],
  );

  return (
    <MomentForm id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <input ref={submitRef} type="submit" style={{ display: 'none' }} />
      <div>
        <Box mb="32px">
          <ThumbnailSelect
            thumbnails={thumbnails}
            selectedId={selectedThumbnailId}
            onSelect={setSelectedThumbnailId}
            onRemove={removeThumbnail}
            onThumbnailAdded={addNewThumbnail}
            isUploadHidden={!canUploadThumbnails}
            isSelectDisabled={!canChangeMetadata}
            isDeleteDisabled={!canDeleteThumbnails}
          />
        </Box>
      </div>
      <TextInput
        label="Title"
        name="title"
        placeholder="Write a title for your moment…"
        defaultValue={moment?.title}
        ref={register({
          validate: validate(isNotEmptyString, 'Title cannot be empty'),
        })}
        error={!!errors.title}
        onBlur={onInputBlur}
        maxLength={256}
      />
      <TextInput
        multiline
        label="Description"
        name="description"
        placeholder="Write a description for your moment…"
        onBlur={onInputBlur}
        ref={register()}
        defaultValue={moment.description}
        maxLength={5000}
        readOnly={!canChangeMetadata}
      />
      <TagsField
        tags={tags}
        setTags={setTags}
        disabled={!canChangeMetadata}
        sectionLabel="Tags"
        type={TagTypes.Regular}
        canUserUpdate={canUserUpdateMoment}
        onTagEdited={handleTagEdited}
        hasUnpublishedChanges={tagsChanged}
      />
      <TagsField
        tags={internalTags}
        setTags={setInternalTags}
        disabled={!canChangeMetadata}
        sectionLabel="Categories"
        type={TagTypes.Internal}
        canUserUpdate={canUserUpdateMoment}
        tooltipText="Used as filtering keyword in Microsite’s creator."
        placeholder="Add category..."
        onTagEdited={handleInternalTagEdited}
        hasUnpublishedChanges={internalTagsChanged}
      />
      <EndCardPicker
        disabled={!canUserUpdateMoment}
        control={control}
        selected={selectedEndCardId}
        handleChange={setSelectedEndCardId}
        backButtonPlaceholder={'moment editor'}
        videoId={videoId}
        tooltipContent="The purpose of an End Card is to input additional information. An example of one is a promocode that can be used on a product page linked. By default, every Moment will end up with Video’s End Card but here you can choose the End Card specific for this Moment."
      />
    </MomentForm>
  );
};

const MomentForm = styled.form`
  height: 100%;
  width: 100%;
`;
