import { DELETE_USER_AVATAR } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

export interface DeleteAvatarResponse {
  deleteAvatar: Nullable<{
    user: {
      avatarUrl: Nullable<string>;
    };
    errors: ErrorType[];
  }>;
}

export const useDeleteAvatar = () => {
  return useMutation<DeleteAvatarResponse>(DELETE_USER_AVATAR, {
    pathToErrors: 'deleteAvatar.errors',
  });
};
