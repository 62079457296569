import { Box } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import defaultAvatar from 'assets/images/default-avatar.png';
import { FormLabel } from 'components/common/FormLabel';
import { ImageUploadDropBox } from 'components/common/UploadDropBox';
import { MAX_USER_AVATAR_RESOLUTION } from 'config/constants';
import { TEST_ID } from 'config/test-ids';

import * as Styled from './AccountTab.styles';

interface ProfilePictureTypes {
  avatarUrl?: string;
  removeAvatar: () => void;
  onThumbnailAdded: (imageBase64: string) => void;
}

export const ProfilePicture: React.FC<ProfilePictureTypes> = ({
  avatarUrl,
  removeAvatar,
  onThumbnailAdded,
}) => {
  return (
    <>
      <FormLabel>Profile picture</FormLabel>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Styled.Avatar url={avatarUrl || defaultAvatar} />
        <ImageUploadDropBox
          id="avatar-upload"
          data-testid={TEST_ID.avatarUploadBox}
          uploadLabel={'Upload new photo'}
          inline={true}
          maxImageDimensions={MAX_USER_AVATAR_RESOLUTION}
          onFileProcessed={onThumbnailAdded}
        />
        <Styled.Trash onClick={removeAvatar} data-testid={TEST_ID.deleteAvatarButton}>
          <Trash />
        </Styled.Trash>
      </Box>
    </>
  );
};
