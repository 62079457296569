import { LiteralUnion } from 'type-fest';

export interface D2CTranscription {
  id: string;
  created: string;
  duration: number;
  inputName: string;
  jobName: string;
  status: TranscriptionStatus;
}

export type D2CTranscriptionOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'inputName'
  | '-inputName'
  | 'created'
  | '-created'
  | 'status'
  | '-status'
  | 'duration'
  | '-duration'
  | 'globalId'
  | '-globalId',
  string
>;

export enum TranscriptionStatus {
  Failed = 'FAILED',
  Validation = 'VALIDATION',
  Queued = 'QUEUED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}
