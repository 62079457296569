import { CAROUSEL_MOMENTS_LIST_QUERY } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { CarouselMomentI, PageInfo } from 'models';
import { CarouselQueryPagination } from 'models/CarouselQueryVariables';
import { Nullable } from 'utils/types';

interface ResponseData {
  carouselMoments: Nullable<{
    nodes: Nullable<CarouselMomentI[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

export interface Variables extends CarouselQueryPagination {
  carouselId: string;
  search?: string;
}

interface UseCarouselMomentsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useCarouselMomentsQuery = (options: UseCarouselMomentsQueryOptions) => {
  return useQuery<ResponseData, Variables>(CAROUSEL_MOMENTS_LIST_QUERY, options);
};
