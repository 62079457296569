import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const CAROUSEL_METADATA_FRAGMENT = gql`
  fragment CarouselMetadata on CarouselType {
    created
    name
    id
  }
`;

export const CREATE_CAROUSEL_MUTATION = gql`
  ${CAROUSEL_METADATA_FRAGMENT}
  mutation CreateCarousel($carousel: CreateCarouselInput!) {
    createCarousel(carousel: $carousel) {
      carousel {
        ...CarouselMetadata
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_CAROUSEL_MUTATION = gql`
  mutation UpdateCarousel($id: ID!, $carousel: UpdateCarouselInput!) {
    updateCarousel(id: $id, carousel: $carousel) {
      carousel {
        created
        id
        name
        title
      }
      ${ERRORS_PART}
    }
  }
`;

export const CUSTOMIZE_CAROUSEL_MUTATION = gql`
  mutation CustomizeCarousel($id: ID!, $carousel: UpdateCarouselInput!, $carouselStyle: CarouselStyleInput!) {
    updateCarousel(id: $id, carousel: $carousel, carouselStyle: $carouselStyle) {
      carousel {
        id
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_CAROUSEL_MUTATION = gql`
  mutation DeleteCarousel($id: ID!) {
    deleteCarousel(id: $id) {
      deletedCarouselId
    }
  }
`;
