import { CSSProperties } from '@material-ui/styles/';

import RobotoBold from 'assets/fonts/Roboto-Bold.woff2';
import RobotoBoldItalic from 'assets/fonts/Roboto-BoldItalic.woff2';
import RobotoItalic from 'assets/fonts/Roboto-Italic.woff2';
import RobotoLight from 'assets/fonts/Roboto-Light.woff2';
import RobotoLightItalic from 'assets/fonts/Roboto-LightItalic.woff2';
import RobotoMedium from 'assets/fonts/Roboto-Medium.woff2';
import RobotoMediumItalic from 'assets/fonts/Roboto-MediumItalic.woff2';
import RobotoRegular from 'assets/fonts/Roboto-Regular.woff2';
import RobotoCondensedBold from 'assets/fonts/RobotoCondensed-Bold.woff2';
import RobotoCondensedBoldItalic from 'assets/fonts/RobotoCondensed-BoldItalic.woff2';
import RobotoCondensedItalic from 'assets/fonts/RobotoCondensed-Italic.woff2';
import RobotoCondensedLight from 'assets/fonts/RobotoCondensed-Light.woff2';
import RobotoCondensedLightItalic from 'assets/fonts/RobotoCondensed-LightItalic.woff2';
import RobotoCondensedRegular from 'assets/fonts/RobotoCondensed-Regular.woff2';

type FontFace = CSSProperties['@font-face'];

const roboto300: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto Light'), local('Roboto-Light'), url(${RobotoLight}) format('woff2')`,
  fontWeight: 300,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const roboto300italic: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto Light Italic'), local('Roboto-LightItalic'), url(${RobotoLightItalic}) format('woff2')`,
  fontWeight: 300,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

const roboto400: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto'), local('Roboto-Regular'), url(${RobotoRegular}) format('woff2')`,
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const roboto400italic: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto Italic'), local('Roboto-Italic'), url(${RobotoItalic}) format('woff2')`,
  fontWeight: 400,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

const roboto500: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto Medium'), local('Roboto-Medium'), url(${RobotoMedium}) format('woff2')`,
  fontWeight: 500,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const roboto500italic: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url(${RobotoMediumItalic}) format('woff2')`,
  fontWeight: 500,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

const roboto700: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto Bold'), local('Roboto-Bold'), url(${RobotoBold}) format('woff2')`,
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const roboto700italic: FontFace = {
  fontFamily: 'Roboto',
  src: `local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(${RobotoBoldItalic}) format('woff2')`,
  fontWeight: 700,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

const robotoCondensed300: FontFace = {
  fontFamily: 'Roboto Condensed',
  src: `local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url(${RobotoCondensedLight}) format('woff2')`,
  fontWeight: 300,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const robotoCondensed300italic: FontFace = {
  fontFamily: 'Roboto Condensed',
  src: `local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'), url(${RobotoCondensedLightItalic}) format('woff2')`,
  fontWeight: 300,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

const robotoCondensed400: FontFace = {
  fontFamily: 'Roboto Condensed',
  src: `local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(${RobotoCondensedRegular}) format('woff2')`,
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const robotoCondensed400italic: FontFace = {
  fontFamily: 'Roboto Condensed',
  src: `local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url(${RobotoCondensedItalic}) format('woff2')`,
  fontWeight: 400,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

const robotoCondensed700: FontFace = {
  fontFamily: 'Roboto Condensed',
  src: `local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(${RobotoCondensedBold}) format('woff2')`,
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const robotoCondensed700italic: FontFace = {
  fontFamily: 'Roboto Condensed',
  src: `local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'), url(${RobotoCondensedBoldItalic}) format('woff2')`,
  fontWeight: 700,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

export const fontDeclaration = [
  roboto300,
  roboto300italic,
  roboto400,
  roboto400italic,
  roboto500,
  roboto500italic,
  roboto700,
  roboto700italic,
  robotoCondensed300,
  robotoCondensed300italic,
  robotoCondensed400,
  robotoCondensed400italic,
  robotoCondensed700,
  robotoCondensed700italic,
];
