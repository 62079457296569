import { YOUTUBE_VIDEO_UPLOAD_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, ProcessingPipelineType, VideoI } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  uploadRemoteVideo: {
    video: Nullable<Pick<VideoI, 'id'>>;
    errors: ErrorType[];
  };
}

interface Variables {
  url: string;
  pipelineType: ProcessingPipelineType;
}

export const useUploadYoutubeVideo = () => {
  return useMutation<ResponseData, Variables>(YOUTUBE_VIDEO_UPLOAD_MUTATION, {
    pathToErrors: 'uploadRemoteVideo.errors',
    refetchQueries: ({ data }: { data: ResponseData }) => {
      return data.uploadRemoteVideo.video
        ? [
            // update videos only if upload is successful
            'GetVideos',
          ]
        : [];
    },
  });
};
