import React from 'react';

import { PaymentPlan } from './PaymentPlan';
import * as Styled from './PaymentPlanTab.styled';

interface Props {
  successUrl: string;
  cancelUrl: string;
}

export const PaymentPlanTab: React.VFC<Props> = ({ successUrl, cancelUrl }) => {
  return (
    <Styled.Container>
      <PaymentPlan successUrl={successUrl} cancelUrl={cancelUrl} />
    </Styled.Container>
  );
};
