import gql from 'graphql-tag';

import { PAGINATION_PART } from './_pagination';
import { CAPTION_FRAGMENT } from './captions';
import { THUMBNAILS_PART } from './thumbnails';

export const VIDEO_METADATA_FRAGMENT = gql`
  fragment VideoMetadata on VideoType {
    created
    description
    id
    momentsCount
    objectsCount
    origin
    status
    title
    views
    thumbnailUrl
  }
`;

export const QUERY_VIDEO = gql`
  ${VIDEO_METADATA_FRAGMENT}
  ${CAPTION_FRAGMENT}

  query GetVideo($id: ID!) {
    videoById(id: $id) {
      scheduledDatetime
      ...VideoMetadata
      captions {
        ...CaptionsFragment
      }
      duration
      internalTags {
        value
      }
      orgLogoEnabled
      orgLogoPosition
      moments {
        description
        id
        timeRange
        title
        tags {
          id
          value
        }
        internalTags {
          id
          value
        }
        endCard {
          id
        }
        thumbnailUrl
        video {
          id
        }
      }
      tags {
        value
      }
      ${THUMBNAILS_PART}
      videoFileUrl
      endCard {
        id
      }
      videoStreams {
        standard
        id
        manifestFileUrl
      }
      promotedContentSettings {
        promoteObjects
        promoteAtStart
        promoteAtEnd
        promotedObjectsLabel
      }
      monetization {
        orgMonetization {
          id
          active
          default
          monetizationUrl
          name
        }
        useDefaultMonetization
        isMonetizationEnabled
        adType
        adDensity
        adLocation
      }
      ctaTimestamps {
        id
        name
        startTimestamp
        endTimestamp
        ctas {
          id
          button {
            id
            name
            url
            text
            background
            border
            icon
          }
          image {
            id
            name
            url
            imageUrl
          }
          config
          order
        }
      }
    }
  }
`;

export const QUERY_VIDEO_STATUS = gql`
  query GetVideoStatus($id: ID!) {
    videoById(id: $id) {
      id
      status
    }
  }
`;

export const POLL_VIDEO_STATUS = gql`
  query PollVideoStatus($id: ID!) {
    videoById(id: $id) {
      id
      description
      processingProgress
      status
      title
      thumbnailUrl
    }
  }
`;

const DASHBOARD_VIDEO_FRAGMENT = gql`
  ${VIDEO_METADATA_FRAGMENT}

  fragment DashboardVideo on VideoType {
    ...VideoMetadata
    externalPublications {
      ... on YouTubePublicationType {
        id
        studioUrl
        uploadStatus
      }
      __typename
    }
    processingProgress
    publications {
      ... on SelfPublicationType {
        id
        publishingTargetId
        publicUrl
      }
      __typename
    }
    monetization {
      orgMonetization {
        id
        active
        default
        monetizationUrl
        name
      }
      useDefaultMonetization
      isMonetizationEnabled
      adType
      adDensity
      adLocation
    }
    ctaTimestamps {
      id
      name
      startTimestamp
      endTimestamp
      ctas {
        id
        button {
          id
        }
        image {
          id
        }
        config
        order
      }
    }
  }
`;

export const VIDEOS_LIST_QUERY = gql`
  ${DASHBOARD_VIDEO_FRAGMENT}

  query GetVideos(
    $pageSize: Int!,
    $page: Int!,
    $orderBy: String = "",
    $search: String = "",
    $distinctGlobalId: Boolean,
  ) {
    videos(
      pageSize: $pageSize,
      page: $page,
      orderBy: $orderBy,
      search: $search,
      distinctGlobalId: $distinctGlobalId,
    ) {
      nodes {
        ...DashboardVideo
        duplicates {
          ...DashboardVideo
        }
      }
      ${PAGINATION_PART}
    }
  }
`;

export const VIDEOS_LIST_QUERY_NO_DUPLICATES = gql`
  ${DASHBOARD_VIDEO_FRAGMENT}

  query GetVideos(
    $pageSize: Int!,
    $page: Int!,
    $orderBy: String = "",
    $search: String = "",
    $distinctGlobalId: Boolean,
  ) {
    videos(
      pageSize: $pageSize,
      page: $page,
      orderBy: $orderBy,
      search: $search,
      distinctGlobalId: $distinctGlobalId,
    ) {
      nodes {
        ...DashboardVideo
      }
      ${PAGINATION_PART}
    }
  }
`;

export const QUERY_VIDEO_PUBLICATIONS = gql`
  query GetVideoPublications($videoId: ID!) {
    videoById(id: $videoId) {
      externalPublications {
        ... on YouTubePublicationType {
          id
          stale
          uploadStatus
        }
        __typename
      }
      id
      publications {
        ... on SelfPublicationType {
          id
          publishingTargetId
          stale
        }
        __typename
      }
    }
  }
`;

export const IS_VIDEO_PUBLISHED_QUERY = gql`
  query IsVideoPublished($id: ID!) {
    videoById(id: $id) {
      id
      isPublished
    }
  }
`;
