import React from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components/macro';

import { Stack } from 'components/common/Wrapper/Layout';

import { useEditorContext } from '../MicrositeEditorContext';
import { ItemTypeLabel, LayoutContainer, SectionTitle } from './Layout';
import { VideoBox } from './VideoBox';

export const VideoWithThumbnailsSection: React.VFC = () => {
  const { initialMicrositeData, color } = useEditorContext();
  const sectionTitle = useWatch({
    name: 'sections[1].title',
    defaultValue: initialMicrositeData.sections[1]?.title,
  });

  return (
    <Wrapper $color={color}>
      {sectionTitle ? <SectionTitle>{sectionTitle}</SectionTitle> : null}
      <Container>
        <VideoWrapper>
          <VideoBox />
          <VideoTitle>Video 1</VideoTitle>
          <Description>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</Description>
          <ReadMore>Read more</ReadMore>
        </VideoWrapper>
        <Stack as="ul">
          <Thumbnail>Video 2</Thumbnail>
          <Thumbnail>Video 3</Thumbnail>
          <Thumbnail>Video 4</Thumbnail>
          <Thumbnail>Video 5</Thumbnail>
        </Stack>
      </Container>
    </Wrapper>
  );
};

const Thumbnail: React.FC = ({ children }) => {
  return (
    <ThumbnailContainer>
      <VideoBox />
      <VideoWrapper>
        <ItemTypeLabel>Video</ItemTypeLabel>
        <ThumbnailTitle>{children}</ThumbnailTitle>
      </VideoWrapper>
    </ThumbnailContainer>
  );
};

const ThumbnailContainer = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
`;

const ThumbnailTitle = styled.h4`
  font-size: 16px;
`;

const VideoTitle = styled(ThumbnailTitle)`
  margin-top: 16px;
  font-size: 20px;
`;

const Description = styled.p`
  margin-top: 10px;
`;

const ReadMore = styled.span`
  margin-top: 6px;
  font-weight: 500;
`;

const Wrapper = styled(LayoutContainer)<{ $color: string }>`
  ${ReadMore} {
    color: ${({ $color }) => $color};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 32px;
  align-items: flex-start;
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;
