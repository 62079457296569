import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import React from 'react';
import { Except } from 'type-fest';

import { Spinner } from 'components/common';

const useStyles = makeStyles(() => ({
  endIcon: {
    marginLeft: '18px',
    marginRight: '-9px',
  },
}));

type Props = Except<ButtonProps, 'endIcon'> & {
  isLoading?: boolean;
};

export const LoadingButton: React.FC<Props> = ({ children, disabled, isLoading, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      {...rest}
      classes={classes}
      disabled={isLoading || disabled}
      endIcon={isLoading ? <Spinner color="inherit" size="1em" /> : null}
    >
      {children}
    </Button>
  );
};
