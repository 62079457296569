import React from 'react';

import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import PaginationFooter from 'components/PaginationFooter';
import { PostsPerPage } from 'components/PaginationFooter/constants';
import { Table } from 'components/Table';
import { PaginationAction } from 'context/pagination';
import { SetSorting, Sorting } from 'context/sorting';
import { DashboardWidgetPreset, PageInfo, WidgetPresetsOrderBy } from 'models';
import { Nullable } from 'utils/types';

import { PresetTableRow } from './PresetTableRow';

import * as Styled from 'components/common/Table/Table.styles';

interface Props {
  presetsLoading: boolean;
  presets: DashboardWidgetPreset[];
  totalCount: number;
  pageInfo: Nullable<PageInfo>;
  dispatch: (value: PaginationAction) => void;
  sorting: Sorting<WidgetPresetsOrderBy>;
  setSorting: SetSorting<WidgetPresetsOrderBy>;
}

export function PresetTable({
  presetsLoading,
  presets,
  totalCount,
  dispatch,
  pageInfo,
  sorting,
  setSorting,
}: Props) {
  const loading = presetsLoading;

  return (
    <Styled.TableWrapper>
      <LoadingOverlay isVisible={loading} />
      <Styled.TableHorizontalScroll>
        <Table fullWidth>
          <Table.Head>
            <Table.HeadCell width="100%">
              <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="name">
                Name
              </SortInterface>
            </Table.HeadCell>
            <Table.HeadCell>Tracking ID</Table.HeadCell>
            <Table.HeadCell>
              <SortInterface
                activeSorting={sorting}
                setSorting={setSorting}
                columnName="endCardName"
              >
                End Card
              </SortInterface>
            </Table.HeadCell>
            <Table.HeadCell>
              <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
                Date Created
              </SortInterface>
            </Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {presets.map((preset) => {
              return <PresetTableRow key={preset.id} preset={preset} />;
            })}
          </Table.Body>
          <PaginationFooter
            totalCount={totalCount}
            visibleCount={presets.length}
            pageInfo={pageInfo}
            dispatch={dispatch}
            tableType={PostsPerPage.WidgetPresets}
          />
        </Table>
      </Styled.TableHorizontalScroll>
    </Styled.TableWrapper>
  );
}
