import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { serializeError } from 'serialize-error';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import config from 'config';
import { pushError } from 'context/globalStream';
import Sentry from 'utils/sentry';
import { LocalStorage } from 'utils/storage';

function revoke(callback: () => void) {
  const token = LocalStorage.get('token');
  if (!token) {
    pushError({ message: 'Invalid session. Try to log in again.' });
    return;
  }

  if (!window.confirm('Do you want to unlink your current account?')) {
    return;
  }

  fetch(config.youtube.revokeUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then(callback)
    .catch((error) => {
      const serializedError = serializeError(error);
      const errorMessage = `Unknown error during revoking YouTube account`;
      console.warn(errorMessage, serializedError);
      Sentry.withScope((scope) => {
        scope.setExtras(serializedError);
        Sentry.captureMessage(errorMessage, Sentry.Severity.Warning);
      });
      pushError({ message: 'Error during unlinking your YouTube account. Try again.' });
    });
}

interface EditYouTubeAccountProps {
  onRevoke: () => void;
  disabled?: boolean;
}

export function EditYouTubeAccount({ onRevoke, disabled }: EditYouTubeAccountProps) {
  return (
    <Tooltip title="Change account" arrow placement="top">
      <div>
        {/* tooltip won't work on disabled button without a wrapper */}
        <IconButton disabled={disabled} onClick={() => revoke(onRevoke)} color="inherit">
          <EditIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
}
