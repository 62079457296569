import { Box, Checkbox } from '@material-ui/core';
import React from 'react';

import { TooltipWrapper } from 'components/common';
import { PublishableWidgetEmbed } from 'components/common/WidgetEmbedCode';
import { Columns } from 'components/common/Wrapper/Layout';
import { CheckboxCell, ExpandableRow, Table } from 'components/Table';
import { ViewEditAction } from 'components/tables/TableActionCell';
import { AddToPlaylistAction } from 'components/tables/TableActionCell/AddToPlaylistAction';
import { ShareAction } from 'components/tables/TableActionCell/ShareAction';
import { Permission } from 'config/permissions';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { DashboardVideoNode, VideoStatus } from 'models';
import { PlaylistContentType } from 'models/Playlist';
import { generateVideoLink } from 'pages/urls';
import { VideoMonetizationToggle } from 'pages/VideoMonetization/Toggles';
import { isDeepEqual } from 'utils/common';
import { formatDate } from 'utils/date';
import { Nullable } from 'utils/types';
import { canVideoBePublished, isVideoPublished } from 'utils/videos';

import { DuplicateVideoAction } from './DuplicateVideoAction';
import { ReprocessVideoAction } from './ReprocessVideoAction';
import { UnpublishVideoAction } from './UnpublishVideoAction';
import { VideoPublications } from './VideoPublications';
import { VideoStatusBadge } from './VideoStatusBadge';
import { VideoTitleCell } from './VideoTitleCell';

import * as Styled from '../DashboardTable.styles';

interface VideoTableRowProps {
  video: DashboardVideoNode;
  getIsSelected: (videoId: string) => boolean;
  hasDuplicatesInTable: boolean;
  hasCheckboxCell: boolean;
  handleCheckboxClick: (id: string) => void;
  duplicateVideo: (options: { variables: { id: string } }) => Promise<any>;
  reprocessFailedVideo: (options: { variables: { id: string } }) => Promise<any>;
  setSharedVideo: (video: Nullable<DashboardVideoNode>) => void;
  widgetApiKey?: string;
}

export const VideoTableRow = React.memo(function VideoTableRow(props: VideoTableRowProps) {
  const { id, duplicates } = props.video;
  const hasDuplicatesInRow = duplicates ? duplicates.length > 0 : false;
  return (
    <ExpandableRow
      key={id}
      as={Styled.Row}
      // added tabindex so it's focusable on ipad
      tabIndex={0}
      isExpandable={hasDuplicatesInRow}
      extraContent={duplicates?.map((duplicate) => {
        return (
          <Styled.Row
            key={duplicate.id}
            // added tabindex so it's focusable on ipad
            tabIndex={0}
          >
            <Cells {...props} video={duplicate} isExtraContent />
          </Styled.Row>
        );
      })}
    >
      <Cells {...props} />
    </ExpandableRow>
  );
}, isDeepEqual);

type CellsProps = VideoTableRowProps & {
  isExtraContent?: boolean;
};

const Cells: React.FC<CellsProps> = ({
  video,
  getIsSelected,
  hasCheckboxCell,
  hasDuplicatesInTable,
  handleCheckboxClick,
  duplicateVideo,
  reprocessFailedVideo,
  isExtraContent,
  setSharedVideo,
  widgetApiKey,
}) => {
  const isSelected = getIsSelected(video.id);

  const { hasPermissions } = useUserPermissions();
  const canUserEditVideo = hasPermissions([Permission.ChangeVideo]);
  const canUserRetryProcessing = hasPermissions([Permission.ManageVideoStatus]);
  const canUserDuplicateVideo = hasPermissions([Permission.DuplicateVideo]);
  const canUserUnpublishVideo = hasPermissions([Permission.UnpublishVideo]);
  const canUserPublishVideo = hasPermissions([Permission.PublishVideo]);
  const canUserAddToPlaylist = hasPermissions([Permission.ChangePlaylist]);

  const { data } = useOrganizationQuery();
  const isMonetizationEnabled = data?.me?.organization?.isMonetizationEnabled;

  return (
    <>
      {hasCheckboxCell ? (
        isExtraContent ? (
          <Table.Cell></Table.Cell>
        ) : (
          <CheckboxCell
            checked={isSelected}
            onClick={() => handleCheckboxClick(video.id)}
            color="secondary"
          >
            {hasDuplicatesInTable && (
              <Box display="inline-block" pl="8px" pr="12px">
                <ExpandableRow.ExpandButton />
              </Box>
            )}
          </CheckboxCell>
        )
      ) : null}
      <Table.Cell>
        <Columns display="flex" alignItems="center" columnGap={10}>
          {isExtraContent && (
            <Checkbox
              checked={isSelected}
              onClick={() => handleCheckboxClick(video.id)}
              color="secondary"
            />
          )}
          <VideoTitleCell video={video} />
        </Columns>
      </Table.Cell>
      <Table.Cell align="center">{video.views}</Table.Cell>
      <Table.Cell>
        <Box display="flex" alignItems="center">
          {video.status === VideoStatus.Edited ? (
            <TooltipWrapper
              text="There are some saved but unpublished changes. Republish Video to see all changes."
              children={<VideoStatusBadge status={video.status} />}
            ></TooltipWrapper>
          ) : (
            <VideoStatusBadge status={video.status} />
          )}
        </Box>
      </Table.Cell>
      <Table.Cell>
        <VideoMonetizationToggle
          checked={!!video.monetization?.isMonetizationEnabled}
          disabled={!isMonetizationEnabled}
          refetchQuery="GetVideos"
          videoId={video.id}
        />
      </Table.Cell>
      <Table.Cell>
        <VideoPublications video={video} />
      </Table.Cell>
      <Table.Cell>{formatDate(new Date(video.created))}</Table.Cell>
      <Table.Cell>
        <ViewEditAction
          url={generateVideoLink(video.id)}
          videoStatus={video.status}
          canUserChangeIt={canUserEditVideo}
          edge="start"
        />
        {canUserDuplicateVideo && (
          <DuplicateVideoAction
            onDuplicate={() => duplicateVideo({ variables: { id: video.id } })}
            edge="start"
          />
        )}
        {canUserRetryProcessing && video.status === VideoStatus.Failed && (
          <ReprocessVideoAction
            onRetry={() => reprocessFailedVideo({ variables: { id: video.id } })}
            edge="start"
          />
        )}
        <PublishableWidgetEmbed
          id={video.id}
          videoId={video.id}
          type="video"
          videoStatus={video.status}
          canUserPublishVideo={canUserPublishVideo}
          apiKey={widgetApiKey}
        >
          {({ showModal }) => {
            return <PublishableWidgetEmbed.TableAction showModal={showModal} edge="start" />;
          }}
        </PublishableWidgetEmbed>
        {canUserUnpublishVideo && <UnpublishVideoAction video={video} edge="start" />}
        {canVideoBePublished(video.status) ? (
          <ShareAction onShareClick={() => setSharedVideo(video)} edge="start" />
        ) : null}
        {canUserAddToPlaylist && canVideoBePublished(video.status) && (
          <AddToPlaylistAction
            item={{
              type: PlaylistContentType.Videos,
              id: video.id,
              title: video.title,
              link: generateVideoLink(video.id),
              isPublished: isVideoPublished(video.status),
              videoId: video.id,
            }}
            edge="start"
          />
        )}
      </Table.Cell>
    </>
  );
};
