import { LocalStorage } from 'utils/storage';

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

export enum PostsPerPage {
  DashboardVideos = 'SEEEN_VIDEOS_PER_PAGE',
  DashboardMoments = 'SEEEN_MOMENTS_PER_PAGE',
  Widgets = 'SEEEN_WIDGETS_PER_PAGE',
  EndCards = 'SEEEN_ENDCARDS_PER_PAGE',
  WidgetMoments = 'SEEEN_WIDGET_MOMENTS_PER_PAGE',
  OrganizationMembers = 'SEEEN_MEMBERS_PER_PAGE',
  PromoteObjects = 'SEEEN_PROMOTE_PER_PAGE',
  VideoObjects = 'SEEEN_OBJECTS_PER_PAGE',
  WidgetPresets = 'SEEEN_PRESETS_PER_PAGE',
  Playlists = 'SEEEN_PLAYLISTS_PER_PAGE',
  CTA = 'SEEEN_CTA_PER_PAGE',
}

export const initialPaginationSettings = (tableType?: PostsPerPage) => {
  const pageSize = tableType ? Number(LocalStorage.get(tableType)) : null;
  return {
    page: 1,
    pageSize: pageSize || ROWS_PER_PAGE_OPTIONS[1],
    changingCount: 0,
  };
};
