import React from 'react';

import { Spinner } from 'components/common';
import { useSearch } from 'components/common/Search/Search';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useUserQuery } from 'hooks/query';
import { OrganizationMembersOrderBy } from 'models';

import { ActionContentWidthHandler } from '../types';
import { MemberTable } from './MemberTable';
import { useOrganizationMembers } from './useOrganizationMembers';

import * as Styled from 'components/common/Table/Table.styles';

export const MembersTab: React.FC<ActionContentWidthHandler> = ({ getActionContentWidth }) => {
  const [pagination, dispatch] = React.useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.OrganizationMembers),
  );
  const { orderBy, sorting, setSorting } = useSorting<OrganizationMembersOrderBy>('-created');
  const [searchText, setSearchText] = useSearch('');

  const { loading, data } = useOrganizationMembers({
    variables: {
      ...pagination,
      search: searchText,
      orderBy,
    },
  });

  const { data: userData, loading: userLoading } = useUserQuery({
    fetchPolicy: 'cache-first',
  });
  const loggedInUserId = userData?.me.id;

  // initial loading
  if ((loading && !data?.organizationMembers?.nodes) || userLoading) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (data?.organizationMembers?.nodes) {
    const { nodes, totalCount, pageInfo } = data.organizationMembers;
    return (
      <MemberTable
        membersLoading={loading} // subsequent loading
        members={nodes}
        totalCount={totalCount}
        pageInfo={pageInfo}
        dispatch={dispatch}
        sorting={sorting}
        setSorting={setSorting}
        onSearch={setSearchText}
        loggedInUserId={loggedInUserId}
        getActionContentWidth={getActionContentWidth}
      />
    );
  }

  return <div>Unexpected error</div>;
};
