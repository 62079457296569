import React from 'react';

export const useFontState = () => {
  const [isFontReady, setIsFontReady] = React.useState(false);

  React.useLayoutEffect(() => {
    document.fonts.ready.then(function () {
      setIsFontReady(true);
    });
  }, []);

  return isFontReady;
};
