import { DELETE_CAROUSEL_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  deleteCarousel: Nullable<{
    deletedCarouselId: string;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

export const useDeleteCarousel = () => {
  return useMutation<ResponseData, Variables>(DELETE_CAROUSEL_MUTATION, {
    pathToErrors: 'deleteCarousel.errors',
    refetchQueries: ['getCarousels'],
  });
};
