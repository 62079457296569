import { Box, IconButton } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { ReactComponent as Repeat } from 'assets/icons/alignment/repeat.svg';
import { NumberInput } from 'components/common/NumberInput';
import { MeasurementUnit } from 'components/common/NumberInput/types';
import { extractNumbers } from 'utils/common';

import { percentageToPx, pxToPercentage } from '../helpers';
import { CTATimestampCtasPosition } from '../type';

interface Props {
  isButton: boolean;
  position: CTATimestampCtasPosition;
  ctaSizeWidth: number;
  videoSizeWidth: number;
  updateCtaPosition: (position: Partial<CTATimestampCtasPosition>) => void;
  control: Control;
  setValue: (field: string, value: any) => void;
}

export const LeftInputs: React.FC<Props> = ({
  control,
  position,
  isButton,
  ctaSizeWidth,
  videoSizeWidth,
  updateCtaPosition,
  setValue,
}) => {
  const [isLeftPositionDisabled, setIsLeftPositionDisabled] = useState(false);

  const width = Math.floor(videoSizeWidth);

  const MAX_WIDTH = width - ctaSizeWidth;

  const MIN_LEFT_VALUE = isButton ? 0 : -ctaSizeWidth;
  const MAX_LEFT_VALUE = isButton ? MAX_WIDTH : MAX_WIDTH + ctaSizeWidth;

  const setWidthValue = useCallback(
    (value: number, isOpposite?: boolean) => {
      const inputValue = percentageToPx(value, MAX_WIDTH);
      if (isOpposite) {
        const size = MAX_WIDTH - Math.floor(inputValue);
        return size + MeasurementUnit.Pixel;
      }
      return Math.floor(inputValue) + MeasurementUnit.Pixel;
    },
    [MAX_WIDTH],
  );

  const onLeftChange = (value: string | number, isOpposite?: boolean) => {
    let updatedLeft = Math.floor(extractNumbers(value));

    if (isOpposite) {
      updatedLeft = MAX_WIDTH - updatedLeft;
    }

    const calculatedLeft = pxToPercentage(updatedLeft, MAX_WIDTH);

    updateCtaPosition({ calculatedLeft, isAlignment: false });
  };

  const reverseLeftValues = () => {
    const inputValue = percentageToPx(position.calculatedLeft, MAX_WIDTH);
    const updatedLeft = MAX_WIDTH - inputValue;
    const calculatedLeft = pxToPercentage(updatedLeft, MAX_WIDTH);

    setIsLeftPositionDisabled((isDisabled) => !isDisabled);

    updateCtaPosition({ calculatedLeft });

    // change input values
    setValue('position-left', setWidthValue(calculatedLeft));
    setValue('position-right', setWidthValue(calculatedLeft, true));
  };

  useEffect(() => {
    // change input values when alignment position is changed
    if (position.isAlignment) {
      setValue('position-left', setWidthValue(position.calculatedLeft));
      setValue('position-right', setWidthValue(position.calculatedLeft, true));
    }
  }, [position, setWidthValue, setValue]);

  useEffect(() => {
    // change input values for disabled input value
    if (!isLeftPositionDisabled) {
      setValue('position-right', setWidthValue(position.calculatedLeft, true));
    } else {
      setValue('position-left', setWidthValue(position.calculatedLeft));
    }
  }, [isLeftPositionDisabled, position, setWidthValue, setValue]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb="25px"
      style={{ gap: '8px' }}
    >
      <Controller
        name="position-left"
        control={control}
        defaultValue={setWidthValue(position.calculatedLeft)}
        render={({ onChange, ...field }) => (
          <NumberInput
            {...field}
            onChange={(data) => {
              onChange(data);
              onLeftChange(data);
            }}
            measurement={MeasurementUnit.Pixel}
            step={5}
            label="Position left *"
            info="Position left"
            min={MIN_LEFT_VALUE}
            max={MAX_LEFT_VALUE}
            mb="16px"
            disabled={isLeftPositionDisabled}
          />
        )}
      />
      <IconButton color="inherit" onClick={reverseLeftValues}>
        <Repeat />
      </IconButton>
      <Controller
        name="position-right"
        control={control}
        defaultValue={setWidthValue(position.calculatedLeft, true)}
        render={({ onChange, ...field }) => (
          <NumberInput
            {...field}
            onChange={(data) => {
              onChange(data);
              onLeftChange(data, true);
            }}
            measurement={MeasurementUnit.Pixel}
            step={5}
            label="Position right *"
            info="Position right"
            min={MIN_LEFT_VALUE}
            max={MAX_LEFT_VALUE}
            mb="16px"
            disabled={!isLeftPositionDisabled}
          />
        )}
      />
    </Box>
  );
};
