import { Variants } from 'framer-motion';

import { ReactComponent as AlignBottom } from 'assets/icons/alignment/align-bottom.svg';
import { ReactComponent as AlignCenterHorizontal } from 'assets/icons/alignment/align-center-horizontal.svg';
import { ReactComponent as AlignCenterVertical } from 'assets/icons/alignment/align-center-vertical.svg';
import { ReactComponent as AlignLeft } from 'assets/icons/alignment/align-left.svg';
import { ReactComponent as AlignRight } from 'assets/icons/alignment/align-right.svg';
import { ReactComponent as AlignTop } from 'assets/icons/alignment/align-top.svg';
import { ReactComponent as Square } from 'assets/icons/square.svg';
import { MIN_TIMESTAMP_LENGTH } from 'config/constants';
import { TimeRange } from 'models';
import { CTATypes } from 'pages/Overlays/CTAsTab/constants';
import { CTAItemType } from 'pages/Overlays/CTAsTab/types';

import { AlignmentIcons, AnimationIcons } from './type';

export const FORM_ID = 'video-cta';

export const EMPTY_CTA_FIELD_ID = 'cta-field-id';
export const EMPTY_TIMESTAMP_FIELD_ID = 'timestamp-field-id';

export const EMPTY_CTA_FIELD = {
  id: EMPTY_CTA_FIELD_ID,
  order: 0,
  type: null,
  template: null,
  styles: null,
  width: '33%',
  config: {
    position: { calculatedTop: 50, calculatedLeft: 50 },
  },
};

export const EMPTY_TIMESTAMP_FIELD = {
  id: EMPTY_TIMESTAMP_FIELD_ID,
  name: '',
  ctas: [],
  startTimestamp: 0,
  endTimestamp: MIN_TIMESTAMP_LENGTH,
  ranges: [0, MIN_TIMESTAMP_LENGTH] as TimeRange,
};

export const CTATypeOptions = [
  { label: CTATypes[CTAItemType.Button], value: CTAItemType.Button },
  { label: CTATypes[CTAItemType.Image], value: CTAItemType.Image },
];

export const VIDEO_PROGRESS_BAR_HEIGHT = 48;

export const ALIGNMENT_ICONS = [
  {
    Icon: AlignBottom,
    name: AlignmentIcons.AlignBottom,
    title: 'Align bottom',
    position: { calculatedTop: 100 },
  },
  {
    Icon: AlignCenterVertical,
    name: AlignmentIcons.AlignCenterVertical,
    title: 'Align center vertical',
    position: { calculatedTop: 50 },
  },
  {
    Icon: AlignTop,
    name: AlignmentIcons.AlignTop,
    title: 'Align top',
    position: { calculatedTop: 0 },
  },
  {
    Icon: AlignLeft,
    name: AlignmentIcons.AlignLeft,
    title: 'Align left',
    position: { calculatedLeft: 0 },
  },
  {
    Icon: AlignCenterHorizontal,
    name: AlignmentIcons.AlignCenterHorizontal,
    title: 'Align center horizontal',
    position: { calculatedLeft: 50 },
  },
  {
    Icon: AlignRight,
    name: AlignmentIcons.AlignRight,
    title: 'Align right',
    position: { calculatedLeft: 100 },
  },
];

export const ANIMATION_ICONS = [
  {
    Icon: Square,
    name: AnimationIcons.Jiggle,
    title: 'Jiggle',
  },
  {
    Icon: Square,
    name: AnimationIcons.Bounce,
    title: 'Bounce',
  },
  {
    Icon: Square,
    name: AnimationIcons.Rotate,
    title: 'Rotate',
  },
  {
    Icon: Square,
    name: AnimationIcons.Vibrate,
    title: 'Vibrate',
  },
  {
    Icon: Square,
    name: AnimationIcons.Shake,
    title: 'Shake',
  },
];

export const ANIMATION_DURATION = [
  {
    label: '1s',
    value: '1s',
  },
  {
    label: '2s',
    value: '2s',
  },
  {
    label: '3s',
    value: '3s',
  },
  {
    label: '4s',
    value: '4s',
  },
  {
    label: '5s',
    value: '5s',
  },
  {
    label: '6s',
    value: '6s',
  },
  {
    label: '7s',
    value: '7s',
  },
  {
    label: '8s',
    value: '8s',
  },
  {
    label: '9s',
    value: '9s',
  },
];

export const ANIMATION_CYCLE = [
  {
    label: 'Constant',
    value: '0',
  },
  {
    label: '1s',
    value: '1',
  },
  {
    label: '2s',
    value: '2',
  },
  {
    label: '3s',
    value: '3',
  },
  {
    label: '4s',
    value: '4',
  },
  {
    label: '5s',
    value: '5',
  },
  {
    label: '7s',
    value: '7',
  },
  {
    label: '10s',
    value: '10',
  },
  {
    label: '15s',
    value: '15',
  },
  {
    label: '30s',
    value: '30',
  },
  {
    label: '60s',
    value: '60',
  },
];

export const keyframes: Variants = {
  JIGGLE: {
    rotate: [0, 6, -6, 6, -6, 0],
  },
  BOUNCE: {
    y: [0, -10, 0, -10, 0],
  },
  ROTATE: {
    rotate: [0, 360],
  },
  VIBRATE: {
    x: [0, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, 0],
  },
  SHAKE: {
    x: [0, 8, -10, 10, -10, 10, -10, 10, -8, 8, 0],
  },
};
