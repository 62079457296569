import { TimeRange } from 'models';
import { msToHMSString } from 'utils/time';

export interface TimestampsTypes {
  id: string;
  ranges: TimeRange;
}

export interface OverlappingTimestampTypes {
  timestamp: TimestampsTypes;
  overlappingPosition: OverlappingPositions;
}

export enum OverlappingPositions {
  Start = 'START_TIMESTAMP',
  End = 'END_TIMESTAMP',
  Inside = 'INSIDE',
  Outside = 'OUTSIDE',
}

export const checkOverlappingTimestamps = (
  timestamp: TimestampsTypes,
  newStart: number,
  newEnd: number,
): null | OverlappingTimestampTypes => {
  // this +1 and -1 is required for correct validation
  // without that if we'll create moment with the same start and end timestamp like other available moment
  // this validation won't generate an error because we allow to create moments without empty spaces between
  if (newStart + 1 > timestamp.ranges[0] && newEnd - 1 < timestamp.ranges[1]) {
    return { timestamp, overlappingPosition: OverlappingPositions.Inside };
  }

  if (newStart - 1 < timestamp.ranges[0] && newEnd + 1 > timestamp.ranges[1]) {
    return { timestamp, overlappingPosition: OverlappingPositions.Outside };
  }

  if (newStart > timestamp.ranges[0] && newStart < timestamp.ranges[1]) {
    return { timestamp, overlappingPosition: OverlappingPositions.Start };
  }

  if (newEnd > timestamp.ranges[0] && newEnd < timestamp.ranges[1]) {
    return { timestamp, overlappingPosition: OverlappingPositions.End };
  }

  return null;
};

export const generateOverlappingErrorMessage = (
  overlappedMoments: OverlappingTimestampTypes[],
  newStart: number,
  newEnd: number,
): string => {
  if (overlappedMoments) {
    return `Moment's timestamps (${msToHMSString(newStart)} - ${msToHMSString(
      newEnd,
    )}) overlap with existing ${
      overlappedMoments.length > 1 ? 'Moments' : 'Moment'
    }: ${msToHMSString(overlappedMoments[0].timestamp.ranges[0])} - ${msToHMSString(
      overlappedMoments[0].timestamp.ranges[1],
    )} ${overlappedMoments.length > 1 ? `and ${overlappedMoments.length - 1} more.` : ''} `;
  }

  return '';
};
