import { Box, Button } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { InfoTooltip } from 'components/common';
import * as Styled from 'components/common/PageContentReview';
import { Columns } from 'components/common/Wrapper/Layout';
import BasePlayer from 'components/VideoPlayer/BasePlayer';
import { updateSource } from 'context/VideoMetadataStream';
import { VideoDetailsNode } from 'models';
import { generateVideoLink } from 'pages/urls';
import { getStreamURL } from 'utils/streaming';

interface Props {
  video: VideoDetailsNode;
  isLoading: boolean;
}

export const VideoEndCardPageMain: React.FC<Props> = ({ video, isLoading }) => {
  const history = useHistory();

  const handleModalConfirm = useCallback(() => {
    history.push(generateVideoLink(video.id));
  }, [history, video.id]);

  const sourceURL = getStreamURL(video.videoFileUrl, video.videoStreams);
  useEffect(() => updateSource({ duration: video.duration || 0 }), [video.duration]);

  return (
    <>
      <Columns alignItems="center" display="flex" justifyContent="flex-end" pb="20px">
        <Button onClick={handleModalConfirm} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          color="primary"
          form="video-endcard-settings"
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </Columns>
      <Styled.PreviewCard>
        <Styled.PreviewCardHeader>
          <Styled.PreviewCardLabel>Preview</Styled.PreviewCardLabel>
          <Styled.PreviewCardTooltip>
            <InfoTooltip content="test" />
          </Styled.PreviewCardTooltip>
        </Styled.PreviewCardHeader>
        <Styled.PreviewCardBody />
      </Styled.PreviewCard>
      <Box position="relative">
        <BasePlayer url={sourceURL} />
      </Box>
    </>
  );
};
