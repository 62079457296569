import React, { useEffect } from 'react';

import { Dialog } from 'components/common/Dialog';
import { usePublishWidget } from 'hooks/mutation/usePublishWidget';
import { useSafeState } from 'hooks/useSafeState';

import Spinner from '../Spinner';
import { Stack } from '../Wrapper/Layout';
import { WidgetEmbedCode } from './WidgetEmbedCode';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  type: 'video' | 'moment' | 'playlist';
  id: string;
  videoId: string;
  apiKey: string;
  isVideoPublished: boolean;
  canVideoBePublished: boolean;
}

export function PublishableWidgetEmbedModal({
  onClose,
  isOpen,
  type,
  id,
  videoId,
  apiKey,
  isVideoPublished,
  canVideoBePublished,
}: Props) {
  const [publishWidget] = usePublishWidget({ variables: { id: videoId } });
  const [isLoading, setIsLoading] = useSafeState(true);

  useEffect(() => {
    if (isOpen && canVideoBePublished) {
      setIsLoading(true);
      publishWidget().finally(() => {
        setIsLoading(false);
      });
    }
  }, [isOpen, canVideoBePublished, publishWidget, setIsLoading]);

  return (
    <Dialog onClose={onClose} open={isOpen} heading="Embed Code" width={600}>
      {isLoading && !isVideoPublished ? (
        <Stack rowGap="20px" display="inline-block">
          <Spinner size="35px" />
          <p>Publishing video…</p>
        </Stack>
      ) : (
        <WidgetEmbedCode onCopy={onClose} type={type} id={id} apiKey={apiKey} />
      )}
    </Dialog>
  );
}
