import { Box, Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { JsonObject } from 'type-fest';

import { InfoTooltip } from 'components/common';
import config from 'config';
import { env } from 'config/env';
import { URLS } from 'pages/urls';

import { LabelledCheckbox } from '../LabelledCheckbox';
import { Stack } from '../Wrapper/Layout';
import { Option, WidgetPresetPicker } from './WidgetPresetPicker';

import {
  CheckboxesWrapper,
  Code,
  StyledBox,
} from 'components/common/WidgetEmbedCode/WidgetEmbedCode.styles';
import { TooltipLink } from './WidgetEmbedCode.styles';

interface Props {
  onCopy: () => void;
  type: 'video' | 'moment' | 'playlist';
  id: string;
  apiKey: string;
}

export const WidgetEmbedCode: React.VFC<Props> = ({ onCopy, type, id, apiKey }) => {
  const codeRef = useRef<HTMLTextAreaElement>(null);

  const [presetId, setPresetId] = useState<Option>();
  const [showThumbnailsSlider, setShowThumbnailsSlider] = useState(true);
  const [showMomentsBelowVideo, setShowMomentsBelowVideo] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [showSocialIcons, setShowSocialIcons] = useState(false);
  const [verticalTheme, setVerticalTheme] = useState(false);
  const widgetId = useMemo(() => Date.now().toString(), []);

  const contentIdName = `${type}Id`;

  const code = getEmbedCode({
    widgetId,
    widgetName: `seeen${
      type === 'playlist' ? (verticalTheme ? 'VerticalCarousel' : 'Carousel') : 'Player'
    }Widget${widgetId}`,
    sourceUrl:
      type === 'playlist'
        ? verticalTheme
          ? config.widget.verticalCarousel
          : config.widget.carousel
        : config.widget.player,
    className: `seeen-${type === 'playlist' ? 'carousel' : 'player'}-widget`,
    options: {
      presetId: presetId?.value || undefined,
      [contentIdName]: id,
      apiUrl: env.SEEEN_APP_BACKEND_URL,
      apiKey,
      showThumbnailsSlider: type === 'video' && showThumbnailsSlider ? true : undefined,
      showMomentsBelowVideo: showMomentsBelowVideo,
      showSocialIcons: showSocialIcons,
      autoplay,
    },
  });

  useEffect(() => {
    if (!showThumbnailsSlider) {
      setShowMomentsBelowVideo(false);
    }
  }, [showThumbnailsSlider]);

  const handleCopy = () => {
    if (codeRef.current) {
      copy(codeRef.current.value, { format: 'text/plain' });
      onCopy();
    }
  };

  return (
    <Stack rowGap="16px">
      <Stack rowGap="24px">
        <Stack width="80%" mx="auto" rowGap="0" textAlign="left">
          <WidgetPresetPicker value={presetId} onChange={setPresetId} />
          <Box ml="-6px">
            <Button component={Link} to={URLS.widget.presets} variant="text" color="primary">
              Manage presets
            </Button>
          </Box>
        </Stack>
        <Stack rowGap="16px">
          <p>Copy the code and embed it on your page:</p>
          <Code value={code} ref={codeRef} readOnly={true} />
          <CheckboxesWrapper>
            {type === 'video' ? (
              <StyledBox>
                <LabelledCheckbox
                  name="showThumbnailsSlider"
                  label={
                    <>
                      Display Slider with Moments Thumbnails
                      <InfoTooltip
                        content={'Adds a navigation bar to quickly skip between published moments.'}
                        style={{ marginLeft: 5 }}
                      />
                    </>
                  }
                  onChange={() => setShowThumbnailsSlider(!showThumbnailsSlider)}
                  value={showThumbnailsSlider}
                />
              </StyledBox>
            ) : null}
            {type === 'video' ? (
              <StyledBox>
                <LabelledCheckbox
                  name="showMomentsBelowVideo"
                  label={
                    <>
                      Display Moments Thumbnails Below Video Player
                      <InfoTooltip
                        content={'Moves the moment navigation bar to under the video player.'}
                        style={{ marginLeft: 5 }}
                      />
                    </>
                  }
                  onChange={() => setShowMomentsBelowVideo(!showMomentsBelowVideo)}
                  value={showMomentsBelowVideo}
                  disabled={!showThumbnailsSlider}
                  flex
                />
              </StyledBox>
            ) : null}
            <StyledBox>
              <LabelledCheckbox
                name="showSocialIcons"
                label={
                  <>
                    Show Social Icons Overlay
                    <InfoTooltip
                      content={
                        'Adds an overlay to share the video on social media. Supported services are configured in the organization.'
                      }
                      style={{ marginLeft: 5 }}
                    />
                  </>
                }
                onChange={() => setShowSocialIcons(!showSocialIcons)}
                value={showSocialIcons}
                flex
              />
            </StyledBox>
            <StyledBox>
              <LabelledCheckbox
                name="setAutoPlay"
                label={
                  <>
                    Enable Autoplay
                    <InfoTooltip
                      interactive
                      content={
                        <>
                          Attempts to autoplay the video. On most browsers and websites, this will
                          require interacting with the website first.{' '}
                          <TooltipLink
                            href="https://developer.chrome.com/blog/autoplay"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more
                          </TooltipLink>
                        </>
                      }
                      style={{ marginLeft: 5 }}
                    />
                  </>
                }
                onChange={() => setAutoplay(!autoplay)}
                value={autoplay}
                flex
              />
            </StyledBox>
            {type === 'playlist' && (
              <StyledBox>
                <LabelledCheckbox
                  name="setVerticalTheme"
                  label={`Use vertical playlist theme`}
                  onChange={() => setVerticalTheme(!verticalTheme)}
                  value={verticalTheme}
                  flex
                />
              </StyledBox>
            )}
          </CheckboxesWrapper>
        </Stack>
      </Stack>
      <Button variant="contained" color="primary" onClick={handleCopy}>
        Copy to Clipboard
      </Button>
    </Stack>
  );
};

function getEmbedCode({
  widgetName,
  widgetId,
  sourceUrl,
  className,
  options,
}: {
  widgetName: string;
  widgetId: string;
  sourceUrl: string;
  className: string;
  options: JsonObject;
}) {
  // https://gitlab.com/entertainment-ai/creator-suite/carousel-widget#generating-embed-code
  // prettier-ignore
  return `<script>(function(w,d,o,f,j){w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments)};j=d.createElement('script');j.id=o;j.src=f;j.async=1;d.head.insertAdjacentElement('beforeend',j)})(window,document,${JSON.stringify(widgetName)},${JSON.stringify(sourceUrl)});${widgetName}('init', ${JSON.stringify(options)});
</script><div id="seeen-widget-${widgetId}" class="seeen-widget ${className}"></div>`.trim();
}
