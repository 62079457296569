import { partition } from 'ramda';
import { useMemo } from 'react';

export interface Item {
  id: string;
  visible: boolean;
}

export const useSelectedListItemsVisibility = (
  selected: { [key: string]: boolean },
  items: Item[],
): string[][] =>
  useMemo(() => {
    const selectedRows = items.filter(({ id }) => selected[id]);
    const partitionedRows = partition(({ visible }) => visible, selectedRows);

    return [...partitionedRows, selectedRows].map((part) => part.map((e) => e.id));
  }, [selected, items]);
