import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { TextInput } from 'components/common';

interface Props {
  errors: FieldErrors;
  control: Control;
}

export const ButtonName: React.FC<Props> = ({ errors, control }) => {
  return (
    <Controller
      rules={{ required: 'Button name cannot be empty' }}
      render={({ onChange, ...props }) => (
        <TextInput
          {...props}
          onChange={onChange}
          onBlur={({ currentTarget }) => onChange(currentTarget.value.trim())}
          label="Button name *"
          error={!!errors.name}
          errorMessage={errors.name?.message}
          placeholder="Enter button name"
          mb="40px"
          info="Name"
          maxLength={200}
        />
      )}
      name="name"
      control={control}
    />
  );
};
