import React from 'react';

export const TermsOfUseCSText: React.VFC = () => {
  return (
    <div>
      <h1>SEEEN SUBSCRIBER AGREEMENT AND TERMS OF USE</h1>
      Last Modified: December 14, 2020
      <ol>
        <li>
          <strong>Your Acceptance</strong>
          Welcome to the Seeen Subscriber Agreement and Terms of Use. This is an agreement
          (“Agreement”) between Seeen, Inc. (“Seeen"), the owner and operator of Seeen.com and Seeen
          software, application, components, and any associated services offered (collectively the
          “Platform”) and you (“you”, “your”, “User”, or “Subscriber”), a Subscriber of the
          Platform. The Seeen Platform may offer Subscriber services (“Seeen Services”) as
          applicable. Throughout this Agreement, the words “Seeen,” “us,” “we,” and “our,” refer to
          our company, Seeen, as is appropriate in the context of the use of the words. By clicking
          “I agree”, subscribing to the Platform, and accessing or using the Platform you agree to
          be bound by this Agreement and the Privacy Policy. We may amend our Subscriber Terms or
          the Privacy Policy and may notify you when we do so. PLEASE BE AWARE THAT THERE ARE
          ARBITRATION AND CLASS ACTION PROVISIONS THAT MAY AFFECT YOUR RIGHTS. If you do not agree
          to the Agreement or the Privacy Policy please cease using our Platform immediately.
        </li>

        <li>
          <strong>Platform and Subscriber Accounts</strong>
          Users may be required to register on the Platform before accessing portions of the
          Platform. Your information will be collected and disclosed in accordance with our Privacy
          Policy. All users are required to provide truthful, up-to-date, and accurate information
          when registering for our Platform and must be over the age of 18. We reserve the right to
          verify all user credentials and to reject any users. You are entirely responsible for
          maintaining the confidentiality of password and account and for any and all activities
          that occur under your account. You agree to notify Seeen immediately of any unauthorized
          use of your or your Authorized User’s (defined below) account or any other breach of
          security. Seeen will not be liable for any losses you incur as a result of someone else
          using your or your Authorized User’s password or account, either with or without your
          knowledge. You may also create an account on behalf of your business or organization,
          where you create such an account you represent and warrant that you have the authority to
          enter into this Agreement on behalf of your business or organization.
        </li>

        <li>
          <strong>Additional Access</strong>
          After registering and properly paying for our Platform, where required, we shall grant you
          access to the Platform as permitted by us and in accordance with this Agreement. If you
          breach this Agreement, your access to use our Platform may be terminated at our
          discretion. Additionally, we may revoke your access to our Platform if we believe that
          your actions may harm us, our business interests, or any third party rights. Failure by us
          to revoke your access does not act as a waiver of your conduct.
        </li>

        <li>
          <strong>Authorized Users</strong>
          The Platform may allow Subscriber to add authorized user(s) (“Authorized User(s)”) to its
          account. Please be aware that any additional Authorized Users may be subject to additional
          subscription fees. Subscriber agrees that it is solely responsible for all actions taken
          under any Authorized User’s account and Subscriber shall ensure that all Authorized Users
          shall comply with all Subscriber obligations as set forth in this Agreement and indemnify
          Seeen for its Authorized User’s use of the Platform and the Seeen Services. All Authorized
          Users shall agree to abide by this Agreement and the Privacy Policy at all times. All
          information or User Content (defined below) contained within an Authorized User’s account
          shall be owned and controlled by Subscriber. Any requests are at the discretion of Seeen
          and Seeen is not liable to Authorized User for any User Content that is controlled by a
          Subscriber account and Subscriber shall bear all liability to such Authorized User.
        </li>

        <li>
          <strong>Seeen Services</strong>
          The Seeen Services offered via the Platform may allow Subscribers to manage, edit, and
          update User Content. You may use the Platform and any Seeen Services solely as permitted
          and provided for by Seeen and in compliance with all applicable laws. Please be aware that
          any Seeen Services, except as expressly stated otherwise, are offered on an “as is” basis
          and Seeen makes no representations or warranties regarding any of the Seeen Services
          offered. Seeen reserves the right to modify, change, and/or discontinue any and all
          portions of the Seeen Services at any time and may also remove or add Seeen Services to
          any subscription levels at its sole discretion. Please be aware that any information
          generated during the use of the Seeen Services are for general informational purposes and
          are considered User Content (defined below). Subscriber is solely responsible for all such
          information generated.
        </li>

        <li>
          <strong>Platform Ownership</strong>
          You acknowledge that the structure, organization, and code of the Platform, the Seeen
          Services, any hosted services, and all related software components are proprietary to
          Seeen and/or Seeen ’s licensors and that Seeen and/or its licensors retains exclusive
          ownership of the Platform, any documentation, information and any and all other
          intellectual property rights relating to the Platform, including all modifications,
          copies, enhancements, derivatives, and other software and materials developed hereunder by
          Seeen . You shall not sell, copy, reproduce, transfer, publish, disclose, display or
          otherwise make available the Platform or any portions of the Platform including any
          modifications, enhancements, derivatives, and other software and materials developed
          hereunder by Seeen to others in violation of this Agreement. You shall not remove any
          proprietary, copyright, trademark, or service mark legend from any portion of any of the
          Platform, including any modifications, enhancements, derivatives, and other software and
          materials developed by Seeen. All rights not expressly granted in this Agreement are
          reserved for us.
        </li>

        <li>
          <strong>Subscriber Responsibilities</strong>
          Subscriber shall be exclusively responsible for its use or any of its authorized user’s
          use. Where a Subscriber or an Authorized User submits any User Content (defined below), we
          are not responsible for such User Content including but not limited to monitoring access
          permissions or providing any services excluding the Seeen Services. Additionally, you
          represent and warrant that: (1) you shall use the Platform solely for your internal
          commercial purposes; (2) you are duly authorized and have the power and authority to enter
          into this Agreement; (3) any User Content is not confidential; (4) that the User Content
          is owned or properly licensed to Subscriber and does not violate any third party
          agreements; and (5) you shall use the Platform only for legal and lawful purposes.
          Subscriber shall indemnify and hold Seeen harmless for a breach of any provisions of this
          section.
        </li>

        <li>
          <strong>Information Generated</strong>
          Seeen is not responsible for any information generated via the Platform or the Seeen
          Services. Subscriber understands and agrees that all Seeen Services are dependent on the
          information contained in any User Content submitted by Subscriber and Subscriber is solely
          responsible for that information. THE SUBSCRIBER AGREES TO HOLD SEEEN FREE FROM
          RESPONSIBILITY FOR ANY LIABILITY OR DAMAGE THAT MIGHT ARISE OUT OF YOUR USE OF THE SEEEN
          SERVICES. SEEEN WILL NOT BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION
          WITH YOUR USE OF THE SERVICES OR THE PLATFORM. Seeen does not warrant any results,
          guarantee, endorse, or recommend any information generated and Subscriber’s use of such
          information generated is at its own risk.
        </li>

        <li>
          <strong>Privacy Policy</strong>
          We value your privacy and understand your privacy concerns. Our Privacy Policy is
          incorporated into this Agreement, and it governs your submission of information to our
          Platform. Please review our Privacy Policy so that you may understand our privacy
          practices. All information we collect is subject to our Privacy Policy, and by using the
          Platform you consent to all actions taken by us with respect to your information in
          compliance with the Privacy Policy.
        </li>

        <li>
          <strong>User Content</strong>A Subscriber’s ability to submit or transmit any information
          through the Platform, including but not limited to user information, data, recordings,
          audio visual content, written content, images, videos, or any other information will be
          referred to as “User Content” throughout this Agreement. Please be aware that we are not
          required to host, display, migrate, or distribute any of your User Content and we may
          refuse to accept or transmit any User Content, should it not adhere to all applicable law,
          rules, regulation, advertising standards or codes of practice issued by a competent
          authority in the jurisdiction in which the User Content is published. You agree that you
          are solely responsible for any User Content submitted and you release us from any
          liability associated with any User Content submitted. Any User Content found to be in
          violation of this Agreement or that we determine to be harmful to the Platform may be
          rejected, modified, edited, deleted, or removed at our discretion. When submitting any
          User Content to our Platform you represent and warrant that you own all rights to the User
          Content, you have paid any fees to use or license the User Content, or you otherwise have
          the permission and right to use any User Content. Furthermore, you represent and warrant
          that all User Content is legal and the User Content does not interfere with any third
          party rights or obligations. When you submit any User Content to us, you grant Seeen , its
          partners, affiliates, Users, representatives and assigns a non-exclusive, unlimited,
          irrevocable, fully-paid, royalty-free, world-wide, universal, transferable, fully
          sub-licenseable, assignable license to display, distribute, store, broadcast, transmit,
          reproduce, modify, prepare derivative works, or use and reuse all or part of your User
          Content for any purpose as deemed necessary by Seeen. Seeen has no liability to Users for
          any User Content including all data, information, copy, images, URL names, and anything
          else submitted by you or any third parties using the Platform. Specifically, Seeen shall
          not be liable for any errors related to any User Content.
        </li>

        <li>
          <strong>Additional Guidelines for User Content</strong>
          Please be aware that all User Content may be viewed by third parties, thus we cannot
          guarantee the confidentiality of any User Content. When submitting any User Content you
          agree to the following:
          <ul>
            <li>
              You agree that User Content submitted is to the best of your knowledge truthful,
              up-to-date, and accurate;
            </li>
            <li>
              You agree not to submit any User Content that contains any confidential information;
            </li>
            <li>
              You agree not to submit any User Content which contains hate speech or promotes or
              condones violence against individuals or groups based on race or ethnic origin,
              religion, disability, gender, age, nationality, veteran status, or sexual
              orientation/gender identity;
            </li>
            <li>
              You agree not to submit any User Content that is considered spam or politically
              controversial; and
            </li>
            <li>
              You agree not to submit any User Content that may be considered: misleading, unlawful,
              defamatory, obscene, invasive, threatening, or harassing.
            </li>
          </ul>
          If you have violated any of our User Content Guidelines or if we believe that any User
          Content may harm the Platform, your access to the Platform may be suspended or terminated.
        </li>

        <li>
          <strong>Storage and Monitoring User Content</strong>
          Seeen shall have the right, but not the obligation, to monitor all User Content on the
          Platform at all times, to determine compliance with this Agreement and any guidelines
          established by us. Without limiting the foregoing, Seeen shall have the right, but not the
          obligation, to remove or disable access to any User Content at its sole discretion. Seeen
          may offer reasonable amounts of storage for User Content but is not obligated to do. Seeen
          is specifically not liable for any User Content stored on the Platform and shall not be
          responsible for maintaining or making any User Content stored on the Platform available to
          Subscriber at any time. Subscriber is solely responsible and liable for any User Content
          storage and/or backups and shall release Seeen from any responsibility or liability for
          any User Content stored on the Platform. Seeen shall have no liability to Subscriber for
          any missing, incomplete, or otherwise unavailable User Content.
        </li>

        <li>
          <strong>Use of the Platform</strong>
          When using our Platform, you are responsible for your use of the Platform. You agree to
          the following:
          <ul>
            <li>
              You may not attempt to interfere with, compromise the system integrity or security, or
              decipher any transmissions to or from the servers running the Platform;
            </li>
            <li>
              You may not use automated bots or other software to send more messages through our
              Platform than humanly possible;
            </li>
            <li>
              You may not decompile, reverse engineer, disassemble, modify, rent, sell, lease, loan,
              distribute, or create derivative works or improvements to the Platform or any portion
              of it;
            </li>
            <li>
              You may not access our Platform in an attempt to build a similar or other competitive
              product;
            </li>
            <li>You may not use the Platform in an unlawful manner;</li>
            <li>
              You may not take any action that imposes, or may impose at our sole discretion, an
              unreasonable or disproportionately large load on our infrastructure;
            </li>
            <li>
              You may not violate or infringe other people's intellectual property, privacy, or
              other contractual rights while using our Platform;
            </li>
            <li>You may not interfere with or disrupt the Platform;</li>
            <li>
              You agree not to use the Platform in any way that is: misleading, unlawful,
              defamatory, obscene, invasive, threatening, or harassing; and
            </li>
            <li>You agree that you will not hold Seeen responsible for your use of our Platform</li>
          </ul>
          If you are discovered to be undertaking any of the aforementioned actions your privileges
          to use our Platform may at our discretion be terminated or suspended. Additionally, if we
          believe that your actions may harm us or a third party we may suspend or terminate your
          use of the Platform and we may pursue any legal remedy available to us under applicable
          law. Generally, we will provide an explanation for any suspension or termination of your
          use of any of our Platform, but Seeen reserves the right to suspend or terminate any
          account at any time.
        </li>

        <li>
          <strong>Third Party Integrations</strong>
          The Seeen Services may offer integrations with third party websites, applications, or
          software (collectively “Third Party Software''). Please be aware that Seeen is not
          affiliated with any companies that own the Third Party Software and the availability of
          such integration is not an endorsement or recommendation to use any Third Party Software.
          The Third Party Software is controlled by companies that are not under the control of
          Seeen and Seeen shall have no ability to control the availability of or your access with
          any Third Party Software. You agree to release us from any liability related to your use
          of any Third Party Software and indemnify us for your use of any Third Party Software. In
          order to use any Third Party Software you may be required to agree to the company’s legal
          agreements and pay any fees associated with access to any Third Party Software. We are not
          a party to any contracts that you enter into with any companies that control any Third
          Party Software and are not responsible for Third Party Software payment, errors, or
          service issues. The Platform may allow you to request information from any Third Party
          Software that may be transferred to the Seeen Platform, or vice versa. You expressly
          authorize us to initiate such a request on your behalf and allow any Third Party Software
          to disclose such User Content to us, or vice versa. Please be aware that such transfers
          with Third Party Software may be untimely or contain errors and/or omissions, you are
          solely responsible for verifying that any transfer is accurately completed to your
          specifications. Please be aware that any license granted to us for any User Content may be
          sub-licensed to such Third Party Software.
        </li>

        <li>
          <strong>Free Trials and Beta Users</strong>
          Seeen may offer the Platform or the Seeen Services on a free trial basis or may make the
          Platform and Seeen Services free for its beta users. Seeen reserves the right to
          discontinue any free trials or free beta users at any time and without liability to us.
          Where you have signed up for a free trial, you agree at the expiration of your free trial
          you may be automatically subscribed to the Seeen Services and your payment method on file
          may be charged.
        </li>

        <li>
          <strong>Subscriber Payments</strong>
          Subscriber agrees to pay for all costs, fees, and taxes listed when purchasing access to
          the Platform including any recurring subscriptions. Where you have properly paid for a
          subscription we shall grant you access to the Seeen Services and Platform. In addition,
          Subscriber agrees to timely pay any and all fees as charged. Subscriber may upgrade or
          downgrade its subscription at any time. Where applicable, you must agree to our third
          party payment processors’ terms and conditions for processing payments. All prices are
          listed in US Dollars. All information that you provide in connection with a payment or
          transaction must be accurate, complete, and current. Where you have failed to pay or where
          payments are overdue, Seeen may suspend or terminate your access to the paid portions of
          the Platform and the associated Seeen Services, without liability to us.
        </li>

        <li>
          <strong>Automatic Payments</strong>
          Where a Subscriber has purchased a subscription, your payment information shall be logged
          for your convenience. IF YOUR ACCOUNT HAS BEEN SET UP FOR AUTOMATIC BILLING, SEEEN MAY
          CHARGE YOU AUTOMATICALLY AND ON A RECURRING BASIS, UNLESS YOU NOTIFY US THAT YOU WANT TO
          CANCEL YOUR AUTOMATIC PAYMENT VIA THE PLATFORM OR AT SUPPORT@SEEEN.COM. ADDITIONALLY, YOU
          AUTHORIZE US TO BILL YOU ON A RECURRING BASIS AND AGREE THAT YOUR SELECTED SUBSCRIPTION
          SHALL AUTOMATICALLY RENEW FOR ADDITIONAL PERIODS (OF THE SAME LENGTH AS YOUR INITIAL
          SUBSCRIPTION) UNTIL WE ARE NOTIFIED OTHERWISE. YOU AGREE THAT NO ADDITIONAL CONSENT IS
          REQUIRED BY YOU TO CHARGE YOUR PREFERRED PAYMENT METHOD AUTOMATICALLY AND ON AN
          AUTOMATICALLY RENEWABLE BASIS FOR THE SUBSCRIPTION.
        </li>

        <li>
          <strong>Refunds for Subscription</strong>
          We want you to be satisfied with the Seeen Services; however we do not offer refunds at
          this time. If you have any questions regarding the subscription or our policies, please
          contact us.
        </li>

        <li>
          <strong>Taxes</strong>
          Where Seeen does not charge you taxes for any payments, you agree to pay any and all
          applicable taxes. Subscriber agrees that Seeen cannot and will not provide Subscriber with
          any tax advice; any such questions should be directed to Subscriber’s tax attorney or
          other tax professional.
        </li>

        <li>
          <strong>Pricing and Price Increases</strong>
          The pricing for all paid subscriptions is listed on the Order Form or the Seeen Platform
          or within your account. Additionally, Seeen may increase the price of any paid
          subscriptions or Seeen fees charged, at our discretion and we reserve the right to do so
          at any time. In the event of a price increase, Seeen shall notify you and you will have
          the chance to accept or reject any price increase. Please notify us if you intend to
          reject a price increase. Where you have rejected a price increase this Agreement may be
          terminated immediately at our discretion. You agree that Seeen has no obligation to offer
          any services for the price originally offered to you at sign up.
        </li>

        <li>
          <strong>Subscriber Account Holds</strong>
          From time to time, Seeen may place a hold (“Hold”) on a Subscriber’s account. Some of the
          reasons that we may place a Hold on Subscriber’s account include but are not limited to
          the following: (1) if we have reason that your services offered or your actions have
          violated this Agreement, may harm our business, are deceptive, misleading, unlawful, or
          have harmed a third party or interfere with a third party contractual right; (2) at the
          request of our payment processors or due to delayed payment; or (3) if required in order
          to comply with a court order, subpoena, writ, injunction, or as otherwise required under
          applicable laws and regulations. If you have questions about a Hold we may have placed on
          your account, or need information about how to resolve the Hold, please contact us.
          Additionally, we may suspend Subscriber’s access to the Platform while such Hold is in
          place.
        </li>

        <li>
          <strong>Termination of Your Subscription</strong>
          You may cancel your subscription at any time via your Seeen dashboard or contacting us.
          Please be aware that upon termination of your account, access to all or portions of our
          Platform may be immediately disabled and any User Content stored may not be retained. We
          may terminate this Agreement with you if we determine that: (1) you have violated any
          applicable laws while using our Platform; (2) if you have violated any portion of this
          Agreement or any of our Platform policies; or (3) if we believe that any of your current
          or future actions may legally harm Seeen, our business interests or a third party, at our
          discretion. In the event of termination, we will strive to provide you with a timely
          explanation; however, we are not required to do so.
        </li>

        <li>
          <strong>Platform Security</strong>
          You agree to exercise caution and common sense when using the Platform and agree that you
          will not submit any User Content that is considered confidential. Further, some or all
          portions of the Seeen Services may be accessible by third parties based on Subscriber’s
          settings or the sharing of Subscriber’s User Content, Subscriber is solely responsible for
          any access by such third parties, whether such access is permitted or un-permitted.
        </li>

        <li>
          <strong>Platform Availability</strong>
          Although we try to provide continuous availability to you, we do not guarantee that the
          Platform will always be available, work, or be accessible at any particular time.
          Specifically, we do not guarantee any uptime or specific availability of the Platform. You
          agree and acknowledge that the Platform may not always be either 100% reliable or
          available. Only Subscribers who are eligible to use our Platform may do so and we may
          refuse service or terminate your access at any time. We cannot guarantee that anything
          found on our Platform will work to the functionality desired by you or give you any
          desired results.
        </li>

        <li>
          <strong>Support</strong>
          Where a Subscriber requires support for the Platform, please contact us at
          support@seeen.com.
        </li>

        <li>
          <strong>Modification of Platform</strong>
          We reserve the right to alter, modify, update, or remove the Platform or any portions
          thereof, at any time at our discretion. We reserve the right to discontinue previously
          offered features or functionality at our sole discretion and without prior notice. We are
          not liable to you or to any third party for any modification, suspension, or
          discontinuance of any feature or component of any portion of the Platform or the Seeen
          Services. We reserve the right to determine the timing and content of software updates,
          which may be automatically downloaded and installed by the Platform without notice to you.
        </li>

        <li>
          <strong>Force Majeure</strong>
          Seeen will not be liable or responsible for any delays in service, for failing to provide
          its services or to operate the Platform or provide the Seeen Services as a result of any
          event beyond its reasonable control, including, without limitation, adverse weather
          conditions, internet outage or interruption of service, power or telecommunications
          outage, fire, contagion, flood, civil disobedience, labor disruptions, strikes, lockouts,
          freight embargoes, terrorism, natural disaster, war or acts of God.
        </li>

        <li>
          <strong>Migration</strong>
          Where a Subscriber or Authorized User terminates or alters its subscription, Seeen shall
          have no obligation to assist Subscriber or Authorized User in migrating or exporting any
          User Content and such User Content may be immediately disabled or deleted without
          liability to Seeen.
        </li>

        <li>
          <strong>Idea Submission</strong>
          Seeen or any of its employees do not accept or consider unsolicited ideas, including but
          not limited to ideas relating to processes, technologies, product enhancements, or product
          names. Please do not submit any unsolicited ideas, content, artwork, suggestions, or other
          works (“Submissions”) in any form to Seeen. The sole purpose of this policy is to avoid
          potential misunderstandings or disputes when Seeen’s products might seem similar to ideas
          you submitted to Seeen. Where you submit any Submission, whether solicited or unsolicited,
          you agree that: (1) your Submissions and their contents will automatically become the
          property of Seeen, without any compensation to you; (2) Seeen may use or redistribute the
          Submissions and their contents for any purpose and in any way; (3) there is no obligation
          for Seeen to review the Submission; and (4) there is no obligation to keep any Submissions
          confidential.
        </li>

        <li>
          <strong>Disclaimer</strong>
          THE PLATFORM IS PROVIDED ON AN "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS" BASIS. TO THE
          FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER SEEEN, NOR ANY OF OUR EMPLOYEES, DIRECTORS,
          OFFICERS, ASSIGNS, AFFILIATES, OR AGENTS MAKE ANY REPRESENTATIONS OR WARRANTIES OR
          ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO: (1)
          THE PLATFORM OR ANY SEEEN SERVICES; (2) ANY INFORMATION OR CONTENT PROVIDED VIA THE
          PLATFORM; OR (3) SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO SEEEN, OR VIA
          THE PLATFORM. IN ADDITION, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM
          FROM COMPUTER VIRUS. SEEEN DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM WILL BE
          ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT THE PLATFORM OR THE
          SERVER THAT MAKES THE PLATFORM AVAILABLE IS FREE FROM ANY HARMFUL COMPONENTS. SEEEN DOES
          NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY
          INSTRUCTIONS) ON THE PLATFORM IS ACCURATE, COMPLETE, OR USEFUL. SEEEN DOES NOT WARRANT
          THAT YOUR USE OF THE PLATFORM IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND SEEEN
          SPECIFICALLY DISCLAIMS ANY SUCH WARRANTIES.
        </li>

        <li>
          <strong>Limitation of Liability</strong>
          IN NO EVENT SHALL SEEEN , ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, OR AGENTS, BE
          LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
          WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL
          THEORY, AND WHETHER OR NOT THE SEEEN IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
          FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN
          THE APPLICABLE JURISDICTION. SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION
          MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM STATE TO STATE.
          SPECIFICALLY, IN THOSE JURISDICTIONS NOT ALLOWED, WE DO NOT DISCLAIM LIABILITY FOR: (1)
          DEATH OR PERSONAL INJURY CAUSED BY SEEEN’S NEGLIGENCE OR THAT OF ANY OF ITS OFFICERS,
          EMPLOYEES OR AGENTS; (2) FRAUDULENT MISREPRESENTATION; OR (3) ANY LIABILITY WHICH IT IS
          NOT LAWFUL TO EXCLUDE EITHER NOW OR IN THE FUTURE. WHERE A TOTAL DISCLAIMER OF LIABILITY
          IS DISALLOWED YOU AGREE THAT OUR TOTAL LIABILITY TO YOU SHALL NOT EXCEED THE AMOUNTS YOU
          HAVE PAID IN THE PAST SIX (6) MONTHS TO USE OUR PLATFORM OR ONE HUNDRED USD, WHICHEVER IS
          GREATER.
        </li>

        <li>
          <strong>Indemnity</strong>
          You agree to defend, indemnify and hold harmless Seeen, its officers, directors,
          employees, affiliates, and agents, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses (including but not limited
          to attorney's fees) arising from:
          <ul>
            <li>your use of and access to the Seeen Platform;</li>
            <li>an Authorized User’s use and access to the Seeen Platform;</li>
            <li>your violation of any term of this Agreement; or</li>
            <li>
              Your violation of any third party right, including without limitation any copyright,
              property, or contractual right.
            </li>
          </ul>
          This defense and indemnification obligation will survive this Agreement and your use of
          the Seeen Platform. You also agree that you have a duty to defend us against such claims
          and we may require you to pay for an attorney(s) of our choice in such cases. You agree
          that this indemnity extends to requiring you to pay for our reasonable attorneys’ fees,
          court costs, and disbursements.
        </li>

        <li>
          <strong>Copyrights</strong>
          <strong className="underline">Takedown Notice</strong>
          We take copyright infringement very seriously. If you believe that any copyrighted
          material owned by you has been infringed upon by someone using our Platform, please send
          us a message which contains: Your name. The name of the party whose copyright has been
          infringed, if different from your name. The name and description of the work that is being
          infringed The location on our Platform of the infringing copy. A statement that you have a
          good faith belief that use of the copyrighted work described above is not authorized by
          the copyright owner (or by a third party who is legally entitled to do so on behalf of the
          copyright owner) and is not otherwise permitted by law. A statement that you swear, under
          penalty of perjury, that the information contained in this notification is accurate and
          that you are the copyright owner or have an exclusive right in law to bring infringement
          proceedings with respect to its use. You must sign this notification and send it to our
          Copyright Agent: Copyright Agent of Seeen, support@seeen.com or Seeen, Inc., 199 Whitney
          Ave New Haven, Connecticut 06511, United States of America
          <strong className="underline">Counter Notice</strong>
          In the event that you receive a notification from Seeen stating that content posted by you
          has been subject to a DMCA takedown, you may respond by filing a counter-notice pursuant
          to the DMCA. Your counter-notice must contain the following:
          <ul>
            <li>Your name, address, email and physical or electronic signature.</li>
            <li>The notification reference number (if applicable).</li>
            <li>Identification of the material and its location before it was removed.</li>
            <li>
              A statement under penalty of perjury that the material was removed by mistake or
              misidentification.
            </li>
            <li>
              Your consent to the jurisdiction of a federal court in the district where you live (if
              You are in the U.S.), or Your consent to the jurisdiction of a federal court in the
              district where Your service provider is located (if you are not in the US).
            </li>
            <li>
              Your consent to accept service of process from the party who submitted the takedown
              notice.
            </li>
          </ul>
          Please be aware that we may not take any action regarding your counter-notice unless Your
          notification strictly complies with the foregoing requirements. Please send this
          counter-notice to: support@seeen.com or Seeen, Inc., 199 Whitney Ave New Haven,
          Connecticut 06511, United States of America.rsements. In the event of a claim such as one
          described in this paragraph, we may elect to settle with the party/parties making the
          claim and you shall be liable for the damages as though we had proceeded with a trial.
        </li>

        <li>
          <strong>Choice of Law</strong>
          This Agreement shall be governed by the laws in force in the state of Connecticut. The
          offer and acceptance of this contract is deemed to have occurred in the state of
          Connecticut.
        </li>

        <li>
          <strong>Disputes</strong>
          Any dispute relating in any way to your visit to the Platform or the Seeen Services shall
          be submitted to confidential arbitration in New Haven, Connecticut. Arbitration under this
          Agreement shall be conducted pursuant to the applicable Commercial Rules (“Rules”) then
          prevailing at the American Arbitration Association. Arbitration shall be conducted in
          English by one (1) arbitrator as selected pursuant to the Rules; the arbitrator's award
          shall be final and binding and may be entered as a judgment in any court of competent
          jurisdiction. Each party shall be responsible for their own arbitration fees and costs. To
          the fullest extent permitted by applicable law, no arbitration under this Agreement shall
          be joined to an arbitration involving any other party subject to this Agreement, whether
          through class action proceedings or otherwise. Where permitted by the Rules, both parties
          may make any and all appearances telephonically, in written briefs, or electronically. You
          agree that regardless of any statute or law to the contrary, any claim or cause of action
          arising out of, related to or connected with the use of the Platform or this Agreement
          must be filed within one (1) year after such claim or cause of action arose or be forever
          banned. Notwithstanding the foregoing, either party may seek equitable relief to protect
          its interests (including but not limited to injunctive relief), or make a claim for
          nonpayment, in a court of appropriate jurisdiction, and issues of intellectual property
          ownership or infringement may be decided only by a court of appropriate jurisdiction and
          not by arbitration. In the event that the law does not permit the above mentioned dispute
          to be resolved through arbitration or if this arbitration agreement is unenforceable, you
          agree that any actions and disputes shall be brought solely in a court of competent
          jurisdiction located within New Haven, Connecticut.
          <strong className="underline">Opt-Out</strong>
          You may opt-out of this dispute resolution provision by notifying Seeen within 30 days of
          the date on which you entered into this Agreement or purchased a subscription, whichever
          is earlier. You must do so by writing to: Seeen, Inc., 199 Whitney Ave New Haven,
          Connecticut 06511, United States of America, attn: Arbitration Opt-Out, and you must
          include your name, address, account information, and a clear statement that you do not
          wish to resolve disputes with Seeen through arbitration. Where you opt out of arbitration,
          all disputes shall be heard in a court of competent jurisdiction located within New Haven,
          Connecticut.
        </li>

        <li>
          <strong>Class Action Waiver</strong>
          You and Seeen agree that any proceedings to resolve or litigate any dispute whether
          through a court of law or arbitration shall be solely conducted on an individual basis.
          You agree that you will not seek to have any dispute heard as a class action,
          representative action, collective action, or private attorney general action.
        </li>

        <li>
          <strong>Severability</strong>
          In the event that a provision of this Agreement is found to be unlawful, conflicting with
          another provision of the Agreement, or otherwise unenforceable, the Agreement will remain
          in force as though it had been entered into without that unenforceable provision being
          included in it. If two or more provisions of this Agreement or any other agreement you may
          have with Seeen are deemed to conflict with each other’s operation, Seeen shall have the
          sole right to elect which provision remains in force.
        </li>

        <li>
          <strong>Non-Waiver</strong>
          We reserve all rights permitted to us under this Agreement as well as under the provisions
          of any applicable law. Our non-enforcement of any particular provision or provisions of
          this Agreement or any applicable law should not be construed as our waiver of the right to
          enforce that same provision under the same or different circumstances at any time in the
          future.
        </li>

        <li>
          <strong>Assignment and Survival</strong>
          You may not assign your rights and/or obligations under this Agreement to any other party
          without our prior written consent, not to be unreasonably withheld. We may assign our
          rights and/or obligations under this Agreement to any other party at our discretion. All
          portions of this Agreement that would reasonably be believed to survive termination shall
          survive and remain in full force upon termination, including but not limited to the
          Limitation of Liabilities, Representation and Warranties, Access, Payment,
          Indemnification, and Arbitration sections.
        </li>

        <li>
          <strong>Entire Agreement</strong>
          This Agreement, along with the Order Form and the Privacy Policy, constitute the complete
          and exclusive understanding and agreement between the parties regarding the subject matter
          herein and supersede all prior or contemporaneous agreements or understandings written or
          oral, relating to its subject matter. Where this Agreement expressly and directly
          conflicts with the Order Form or the Privacy Policy, this Agreement shall control. Any
          waiver, modification or amendment of any provision of this Agreement will be effective
          only if in writing and signed by a duly authorized representative of each party. Where
          this Agreement conflicts with the Order Form or our Privacy Policy or any other
          documentation listed on our website this Agreement shall supersede and control.
        </li>

        <li>
          <strong>Amendments</strong>
          We may amend this Agreement from time to time. When we amend this Agreement, we will
          update this page and indicate the date that it was last modified and we may email you. If
          we make changes that are material, we will use reasonable efforts to attempt to notify
          you. You may refuse to agree to the amendments, but if you do, you must immediately cease
          using our Platform and our Platform. Furthermore, it is your sole responsibility to review
          this Agreement from time to time to view any such changes. The updated Agreement will be
          effective as of the time of posting, or such later date as may be specified in the updated
          Agreement. Your continued access or use of the Seeen Platform after the modifications have
          become effective will be deemed your acceptance of the modified Agreement.
        </li>

        <li>
          <strong>Electronic Communications</strong>
          The communications between you and Seeen use electronic means, whether you visit the
          Platform or send Seeen e-mails, or whether Seeen posts notices on the Platform or
          communications with you via mobile notifications or e-mail. For contractual purposes, you
          (1) consent to receive communications from Seeen in an electronic form; and (2) agree that
          all terms, conditions, agreements, notices, disclosures, and other communications that
          Seeen provides to you electronically satisfy any legal requirement that such
          communications would satisfy if it were to be in writing. The foregoing does not affect
          your statutory rights.
        </li>

        <li>
          <strong>Platform Issues</strong>
          Where you have any questions, issues, or if you are having trouble accessing or using the
          Platform, please contact us at support@seeen.com.
        </li>

        <li>
          <strong>Relationship of Parties</strong>
          This Agreement shall not be construed to create any association, partnership, joint
          venture, employee, worker or agency relationship between Subscriber and Seeen. The
          relationship of the parties is as independent contractors. Subscriber has no authority
          (and shall not hold himself or herself out as having authority) to bind Seeen and
          Subscriber shall not make any agreements or representations on Seeen’s behalf without
          Seeen’s prior written consent.
        </li>

        <li>
          <strong>California Users</strong>
          Pursuant to California Civil Code Section 1789.3, any questions about pricing, complaints,
          or inquiries about Seeen must be sent to our agent for notice to: support@seeen.com or
          Seeen, Inc. 199 Whitney Ave New Haven, Connecticut 06511, United States of America.
        </li>
      </ol>
    </div>
  );
};
