import { useContext, useEffect, useState } from 'react';

import AnimationIcons from '../Animations/AnimationIcons';
import AnimationInputs from '../Animations/AnimationInputs';
import { ANIMATION_ICONS } from '../constants';
import { AnimationName, CTATimestampCtasAnimation, CycleTime, Duration } from '../type';
import { VideoCTAPageContext } from '../useTimestampContext';

interface CTAAnimationsProps {
  updateCtaAnimation: (animation: CTATimestampCtasAnimation) => void;
}

export default function CTAAnimations({ updateCtaAnimation }: CTAAnimationsProps) {
  const [pausedStates, setPausedStates] = useState<boolean[]>(ANIMATION_ICONS.map(() => true));

  const { currentCTA } = useContext(VideoCTAPageContext);

  const initialAnimation: CTATimestampCtasAnimation = {
    name: '',
    duration: '1s',
    cycleTime: 0,
  };

  const [animation, setAnimation] = useState<CTATimestampCtasAnimation>(initialAnimation);
  const handleTogglePause = (index: number) => {
    setPausedStates((prevStates) => {
      const newPausedStates = [...prevStates];
      newPausedStates[index] = !newPausedStates[index];
      return newPausedStates;
    });
  };

  const handleIconClick = (name: AnimationName) => {
    setAnimation({ ...animation, name });
  };

  const handleAnimationDuration = (duration: Duration) => {
    setAnimation({ ...animation, duration });
  };
  const handleAnimationCycleTime = (cycleTime: CycleTime) => {
    setAnimation({ ...animation, cycleTime });
  };
  const handleCancelAnimation = () => {
    setAnimation(initialAnimation);
    updateCtaAnimation(initialAnimation);
    setPausedStates(ANIMATION_ICONS.map(() => true));
  };
  useEffect(() => {
    if (typeof animation === 'string') {
      const animationString = animation as string;
      const animationParts = animationString.split(' ');
      const name = animationParts[0] as AnimationName;
      const duration = animationParts[1] as Duration;

      setAnimation({
        name,
        duration,
        cycleTime: 0,
      });
    }

    updateCtaAnimation(animation);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  useEffect(() => {
    if (currentCTA && currentCTA.config.animation) {
      setAnimation(currentCTA.config.animation);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AnimationIcons
        handleIconClick={handleIconClick}
        handleTogglePause={handleTogglePause}
        pausedStates={pausedStates}
        handleCancelAnimation={handleCancelAnimation}
        currentName={animation.name}
      />
      <AnimationInputs
        handleAnimationDuration={handleAnimationDuration}
        handleAnimationCycleTime={handleAnimationCycleTime}
        animation={animation}
      />
    </>
  );
}
