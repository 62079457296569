import styled from 'styled-components';

import { materialTheme } from 'components/App/materialTheme';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 40px;
  position: relative;
`;

interface PaginationProps {}

export const Pagination = styled.div<PaginationProps>`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

interface IconWrapperProps {
  disabled?: boolean;
  right?: boolean;
}

export const IconWrapper = styled.span<IconWrapperProps>`
  align-items: center;
  color: ${({ disabled }) => (!disabled ? materialTheme.palette.primary.main : 'inherit')};
  display: flex;

  ${({ right }) =>
    right &&
    `
  transform: rotate(180deg);
  `}
`;
