import { omit } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';

type VideoMetaActionsStream =
  | { type: 'init'; value: undefined }
  | { type: 'progress'; value: { played: number | null; loadedFraction: number } }
  | { type: 'player-unmount' };

interface Stream {
  seekId: number | null;
  seek: number;
  duration: number;
  played: number;
  loadedFraction: number;
  playing: boolean;
}

const streamInitialValue: Stream = {
  /**
   * needed when seeking to the same timestamp multiple times in a row
   * without this, distinctUntilChanged will prevent the stream from updating
   */
  seekId: null,
  seek: 0,
  // TODO: optimize initial duration logic, it should be falsy before video is loaded
  duration: Number.MAX_SAFE_INTEGER,
  played: 0,
  loadedFraction: 0,
  playing: false,
};

export const videoMetadataActions$ = new BehaviorSubject<VideoMetaActionsStream>({
  type: 'init',
  value: undefined,
});

export const source$ = new BehaviorSubject<Stream>(streamInitialValue);

const normalizeTimeValue = (
  key: keyof Stream,
  nextValue: Partial<Stream>,
  data: Partial<Stream>,
) => {
  if (typeof data[key] === 'number') {
    // round milliseconds to be compatible with BE
    // @ts-ignore
    nextValue[key] = Math.round(data[key]);
  }
};

export const updateSource = (data: Partial<Stream>) => {
  const previousValue = source$.getValue();

  const nextValue: Partial<Stream> = {
    ...data,
  };

  normalizeTimeValue('seek', nextValue, data);
  normalizeTimeValue('duration', nextValue, data);
  normalizeTimeValue('played', nextValue, data);

  source$.next({ ...previousValue, ...nextValue });
};

videoMetadataActions$.subscribe((action) => {
  switch (action.type) {
    case 'progress': {
      const { played, loadedFraction } = action.value;
      if (played === null) {
        updateSource({ loadedFraction });
      } else {
        updateSource({ played, loadedFraction });
      }
      break;
    }
    case 'player-unmount': {
      updateSource(streamInitialValue);
      break;
    }
  }
});

export const seekToMomentStart = (start: number) => {
  updateSource({
    seekId: Date.now(),
    seek: start,
    played: start + 1,
  });
};

export const seekToMs = (seek: number) => {
  updateSource({
    seekId: Date.now(),
    seek,
    played: seek,
  });
};

export const togglePlayback = () => {
  updateSource({
    playing: !source$.getValue().playing,
  });
};

export const useVideoMetadataObservable = () => {
  return useObservable(
    () =>
      source$.pipe(
        map(omit(['loadedFraction'])),
        distinctUntilChanged(
          (prev, cur) =>
            prev.seekId === cur.seekId &&
            prev.seek === cur.seek &&
            prev.played === cur.played &&
            prev.duration === cur.duration &&
            prev.playing === cur.playing,
        ),
        map(omit(['seekId'])),
      ),
    omit(['loadedFraction'], streamInitialValue),
  );
};

export function useVideoLoadedFraction() {
  return useObservable(() => {
    return source$.pipe(pluck('loadedFraction'), distinctUntilChanged());
  }, streamInitialValue.loadedFraction);
}
