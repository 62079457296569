import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as EyeOpen } from 'assets/icons/eye.svg';
import { ReactComponent as EyeClosed } from 'assets/icons/eye-off.svg';
import { MIN_PASSWORD_LENGTH } from 'config/constants';

import type { TextInputProps } from './TextInput';
import TextInput from './TextInput';

export const PasswordRequirementsNote = () => {
  return (
    <p>
      Password should be at least {MIN_PASSWORD_LENGTH} characters long, it cannot consist of
      numbers only, and be too similar to your email address.
    </p>
  );
};

export const PasswordInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  function PasswordInput(props, ref) {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const togglePassword = () => setIsPasswordVisible((visible) => !visible);

    return (
      <TextInput
        {...props}
        type={isPasswordVisible ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip
              title={isPasswordVisible ? 'Hide password' : 'Show password'}
              arrow
              placement="top"
            >
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={togglePassword}
              >
                {isPasswordVisible ? (
                  <EyeOpen width={24} height={24} />
                ) : (
                  <EyeClosed width={24} height={24} />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        ref={ref}
      />
    );
  },
);
