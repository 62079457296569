import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const SpinnerBox = () => (
  <Box
    display="flex"
    width="auto"
    height="100%"
    alignItems="center"
    justifyContent="center"
    minHeight="300px"
  >
    <CircularProgress />
  </Box>
);

export default SpinnerBox;
