import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import seeenLogo from 'assets/images/seeen-logo.png';
import { BigCard } from 'components/common/Wrapper/Card';
import { SnackbarController } from 'components/Snackbar';
import { URLS } from 'pages/urls';

const CenterLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 50px;
`;

const RootLink = styled(Link)`
  margin: 64px 0;
`;

const Logo = styled.img`
  height: 30px;
  object-fit: contain;
`;

export const ModalPageLayout: React.FC = ({ children }) => {
  return (
    <SnackbarController>
      <CenterLayout>
        <RootLink to={URLS.root}>
          <Logo src={seeenLogo} alt="Seeen logo" />
        </RootLink>
        <BigCard>{children}</BigCard>
      </CenterLayout>
    </SnackbarController>
  );
};
