import { Controller, useForm } from 'react-hook-form';

import { CustomSelect } from 'components/common/CustomSelect';

import { ANIMATION_CYCLE, ANIMATION_DURATION } from '../constants';
import { CTATimestampCtasAnimation, CycleTime, Duration } from '../type';

interface AnimationInputsProps {
  handleAnimationDuration: (value: Duration) => void;
  handleAnimationCycleTime: (value: CycleTime) => void;
  animation: CTATimestampCtasAnimation;
}

export default function AnimationInputs({
  handleAnimationDuration,
  handleAnimationCycleTime,
  animation,
}: AnimationInputsProps) {
  const { control } = useForm();

  return (
    <>
      <Controller
        name="duration"
        control={control}
        render={({ onChange }) => (
          <CustomSelect
            options={ANIMATION_DURATION}
            id="cta-animation-type"
            label="Duration *"
            mb="40px"
            info="Select a duration of the animation"
            infoPlacement="top"
            defaultValue={animation.duration}
            value={animation.duration}
            disabled={animation.name === ''}
            onChange={(e) => {
              onChange(e.target.value);
              handleAnimationDuration(e.target.value as Duration);
            }}
          />
        )}
      />
      <Controller
        name="Cycle Time"
        control={control}
        render={({ onChange }) => (
          <CustomSelect
            options={ANIMATION_CYCLE}
            id="cta-animation-type"
            label="Cycle Time *"
            mb="40px"
            info="Select a cycle time of the animation"
            infoPlacement="top"
            defaultValue={animation.cycleTime}
            value={animation.cycleTime}
            disabled={animation.name === ''}
            onChange={(e) => {
              onChange(e.target.value);
              handleAnimationCycleTime(e.target.value as CycleTime);
            }}
          />
        )}
      />
    </>
  );
}
