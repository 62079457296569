import { Box, FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { LoadingButton, Spinner } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Stack } from 'components/common/Wrapper/Layout';
import { useUploadYoutubeVideo } from 'hooks/mutation';
import { ProcessingPipelineType } from 'models';
import { URLS } from 'pages/urls';
import { isValidUrl, validate } from 'utils/validation';

import { ProcessingPipelineDropdown } from '../ProcessingPipelineDropdown';
import { CenteredContent } from './YoutubeVideoModalElements';

import { LegalNote } from 'components/common/Modal/Modal.styles';

interface FormData {
  url: string;
  pipelineType: ProcessingPipelineType;
}

interface YoutubeVideoModalProps {
  open: boolean;
  onClose: () => void;
}

const YoutubeVideoModal = ({ open, onClose }: YoutubeVideoModalProps) => {
  const { register, handleSubmit, reset, errors, control } = useForm<FormData>({
    mode: 'onBlur',
  });

  const [uploadYoutubeVideo, { loading }] = useUploadYoutubeVideo();

  const onSubmit = async ({ url, pipelineType }: FormData) => {
    await uploadYoutubeVideo({
      variables: {
        url: url.trim(),
        pipelineType,
      },
    });
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      heading="Import video from YouTube"
      width={600}
      data-testid="youtubeUploadModal"
    >
      {loading && (
        <CenteredContent>
          <Spinner />
        </CenteredContent>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          display="flex"
          flexDirection="column"
          position="relative"
          alignItems="center"
          rowGap="20px"
        >
          <Stack width="400px" rowGap="20px">
            <FormControl fullWidth>
              <TextField
                name="url"
                label="YouTube URL"
                error={!!errors.url?.message}
                helperText={errors.url?.message}
                inputRef={register({
                  validate: validate(isValidUrl, 'URL is in incorrect format'),
                })}
              />
            </FormControl>
            <Box display="flex" justifyContent="space-between">
              <Controller
                render={({ onChange, value, name, ref }) => {
                  return (
                    <ProcessingPipelineDropdown
                      ref={ref}
                      id="pipelineType"
                      name={name}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
                defaultValue={ProcessingPipelineType.Default}
                name="pipelineType"
                control={control}
              />
              <LoadingButton variant="contained" isLoading={loading} type="submit" color="primary">
                Upload
              </LoadingButton>
            </Box>
          </Stack>
          <LegalNote>
            By submitting your videos to Seeen, you acknowledge that you agree to Seeen’s{' '}
            <a href={URLS.termsOfUse} target="_blank" rel="noreferrer noopener">
              <strong>Terms of Service and Community Guidelines</strong>
            </a>
            . Please be sure not to violate others' copyright or privacy rights.
            <br />
            <a href={URLS.privacyPolicy} target="_blank" rel="noreferrer noopener">
              <strong>Learn more</strong>
            </a>
            .
          </LegalNote>
        </Stack>
      </form>
    </Dialog>
  );
};

export default YoutubeVideoModal;
