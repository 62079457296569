import { IconButton, Tooltip } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ConfirmationModal } from 'components/common/Modals';
import { Permission } from 'config/permissions';
import { useDeleteOrganizationMonetization } from 'hooks/mutation/useDeleteMonetization';
import { useUserPermissions } from 'hooks/query/useUserPermissions';

import {
  DELETE_MODAL_DEFAULT_DESCRIPTION,
  DELETE_MODAL_DEFAULT_HEADING,
  DELETE_MODAL_DESCRIPTION,
  DELETE_MODAL_HEADING,
} from './constants';
import { UpdateMonetizationModal } from './MonetizationModals/UpdateMonetizationModal';
import { Monetization } from './types';

interface EditActionProps {
  monetization: Monetization;
  isRemoveActionDisabled: boolean;
}

export const MonetizationActions: React.VFC<EditActionProps> = ({
  monetization,
  isRemoveActionDisabled,
}) => {
  const { hasPermissions } = useUserPermissions();
  const canEditMonetization = hasPermissions([Permission.ChangeOrganizationMonetization]);
  const canRemoveMonetization = hasPermissions([Permission.DeleteOrganizationMonetization]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [deleteOrganizationMonetization] = useDeleteOrganizationMonetization();

  const handleEditModalClose = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const handleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  const handleRemoveModalClose = useCallback(() => {
    setIsRemoveModalOpen(false);
  }, []);

  const handleRemoveModalOpen = useCallback(() => {
    setIsRemoveModalOpen(true);
  }, []);

  const handleDelete = () =>
    deleteOrganizationMonetization({
      variables: {
        id: monetization.id,
      },
    });

  return (
    <>
      {canEditMonetization ? (
        <>
          <Tooltip title="Edit" arrow placement="top">
            <IconButton color="inherit" edge="start" onClick={handleEditModalOpen}>
              <Edit />
            </IconButton>
          </Tooltip>
          <UpdateMonetizationModal
            isOpen={isEditModalOpen}
            onClose={handleEditModalClose}
            monetization={monetization}
          />
        </>
      ) : null}

      {canRemoveMonetization ? (
        <>
          <Tooltip title="Remove" arrow placement="top">
            <span>
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleRemoveModalOpen}
                disabled={isRemoveActionDisabled}
              >
                <Trash />
              </IconButton>
            </span>
          </Tooltip>
          <ConfirmationModal
            isOpen={isRemoveModalOpen}
            heading={monetization.default ? DELETE_MODAL_DEFAULT_HEADING : DELETE_MODAL_HEADING}
            description={
              monetization.default ? DELETE_MODAL_DEFAULT_DESCRIPTION : DELETE_MODAL_DESCRIPTION
            }
            confirmationButtonText="Delete"
            onConfirm={handleDelete}
            onClose={handleRemoveModalClose}
          />
        </>
      ) : null}
    </>
  );
};
