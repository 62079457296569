import { LiteralUnion } from 'type-fest';

export enum MutationErrorCode {
  NotFound = 'NOT_FOUND',
  AuthRequired = 'AUTH_REQUIRED',
  DatabaseError = 'DATABASE_ERROR',
  PermissionsError = 'PERMISSIONS_ERROR',
  ValidationError = 'VALIDATION_ERROR',
}

export enum ServerErrorCode {
  UnknownError = 'UNKNOWN_ERROR',
  ServerError = 'SERVER_ERROR',
  NotFound = 'NOT_FOUND',
  AuthRequired = 'AUTH_REQUIRED',
  PermRequired = 'PERM_REQUIRED',

  Base64DecodedSize = 'BASE64_DECODED_SIZE',
  Base64DataSize = 'BASE64_DATA_SIZE',
  Base64CaptionMalformedData = 'BASE64_CAPTION_MALFORMED_DATA',
  Base64CaptionExpectedText = 'BASE64_CAPTION_EXPECTED_TEXT',

  YouTubeCredentialsRefresh = 'credentials_refresh',
}

export interface ErrorType {
  field: string;
  messages: string[];
  name: LiteralUnion<MutationErrorCode, string>;
}

export interface ServerErrorType {
  extensions: {
    code: LiteralUnion<ServerErrorCode, string>;
  };
  message: string;
  path: string[];
}
