import { env } from 'config/env';
import { history } from 'utils/history';
import { msToFloorSeconds } from 'utils/time';

declare global {
  interface Window {
    // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
    Intercom?: (method: string, ...args: any[]) => void;
  }
}

const APP_ID = env.SEEEN_APP_INTERCOM_ID;

interface UserConfig {
  id: string;
  name: string;
  email: string;
  dateJoined: string;
}

export const Intercom = {
  isEnabled: false,
  userConfig: undefined as UserConfig | undefined,
  setup(user?: UserConfig) {
    if (!window.Intercom || !APP_ID || !user) {
      return;
    }

    if (this.isEnabled && this.userConfig) {
      this.update();
      return;
    }

    window.Intercom('boot', {
      app_id: APP_ID,
      user_id: user.id,
      name: user.name,
      email: user.email,
      created_at: user.dateJoined,
    });
    this.isEnabled = true;
    this.userConfig = user;
  },
  update(user: Partial<Pick<UserConfig, 'email' | 'name'>> = {}) {
    if (!window.Intercom || !APP_ID || !this.userConfig || !this.isEnabled) {
      return;
    }

    const { id, name, email } = this.userConfig;

    window.Intercom('update', {
      app_id: APP_ID,
      user_id: id,
      name: user.name ?? name,
      email: user.email ?? email,
      last_request_at: msToFloorSeconds(Date.now()),
    });
    this.userConfig = {
      ...this.userConfig,
      ...user,
    };
  },
  shutdown() {
    if (!window.Intercom) {
      return;
    }

    window.Intercom('shutdown');
    this.isEnabled = false;
  },
  show() {
    if (!window.Intercom || !APP_ID || !this.isEnabled) {
      return;
    }

    window.Intercom('show');
  },
  hide() {
    if (!window.Intercom || !APP_ID || !this.isEnabled) {
      return;
    }

    window.Intercom('hide');
  },
};

export function setupIntercomPageTracking() {
  return history.listen(() => {
    Intercom.update();
  });
}
