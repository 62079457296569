import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { BackButton, Typography } from 'components/common';
import { EndCardPicker } from 'components/common/EndCardPicker';
import { VideoDetailsNode } from 'models';
import { generateVideoLink } from 'pages/urls';

import { FormData } from './VideoEndCardPageView';

interface Props {
  form: UseFormMethods<FormData>;
  onSubmit: (data: FormData) => void;
  video: VideoDetailsNode;
}

export const VideoCTAPageSidebar: React.FC<Props> = ({ video, form, onSubmit }) => {
  const [selectedEndCardId, setSelectedEndCardId] = useState(video.endCard?.id);
  const videoEditorUrl = generateVideoLink(video.id);

  const { handleSubmit, control } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="video-endcard-settings">
      <BackButton label="Back to video editor" link={videoEditorUrl} />
      <Box m="22px 0 32px 0">
        <Typography variant="h1" color="textPrimary">
          End Card
        </Typography>
        <EndCardPicker
          videoId={video.id}
          selected={selectedEndCardId}
          handleChange={setSelectedEndCardId}
          hideCreateButton
          control={control}
        />
      </Box>
    </form>
  );
};
