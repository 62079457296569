import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import { materialTheme } from 'components/App/materialTheme';
import { InfoTooltip, Select, TextInput } from 'components/common';
import { MicrositeSectionOrdering, MicrositeSectionTypes, TagType } from 'models';

import * as Styled from './SectionAccordion.styles';

const ORDERING_OPTIONS: { label: string; value: MicrositeSectionOrdering }[] = [
  {
    label: 'Most recently published',
    value: MicrositeSectionOrdering.PublicationDateDesc,
  },
  {
    label: 'Least recently published',
    value: MicrositeSectionOrdering.PublicationDate,
  },
  {
    label: 'Most views',
    value: MicrositeSectionOrdering.ViewsDesc,
  },
  {
    label: 'Least views',
    value: MicrositeSectionOrdering.Views,
  },
  {
    label: 'Most stars',
    value: MicrositeSectionOrdering.StarsDesc,
  },
  {
    label: 'Least stars',
    value: MicrositeSectionOrdering.Stars,
  },
];

const CONTENT_TYPE_OPTIONS: { label: string; value: MicrositeSectionTypes }[] = [
  {
    label: 'Videos',
    value: MicrositeSectionTypes.Videos,
  },
  {
    label: 'Moments',
    value: MicrositeSectionTypes.Moments,
  },
  {
    label: 'Videos & Moments',
    value: MicrositeSectionTypes.VideosMoments,
  },
];

const StyledAccordion = withStyles(() => ({
  root: {
    border: `1px solid ${materialTheme.palette.grey[200]}`,
    borderRadius: '10px !important',
    minHeight: '56px',
  },
}))(Accordion);

const StyledAccordionSummary = withStyles(() => ({
  root: {
    height: '56px',
  },
}))(AccordionSummary);

const StyledAccordionDetails = withStyles(() => ({
  root: {
    border: `1px solid ${materialTheme.palette.background.default}`,
  },
}))(AccordionDetails);

interface SectionAccordionProps {
  title: string;
  namePrefix: string;
}

const TooltipContent: React.VFC = () => (
  <div>
    <p>
      Your microsite contains three sections with slightly different styling, to keep it from
      getting boring. The top section is the first thing users will see and we suggest you place the
      top content you want to promote here. Note that this section must have 3 items published into
      it to display.
    </p>
    <br />
    <p>
      The middle section displays up to 5 items, one large and up to 4 smaller ones. This section
      requires a minimum of 2 items to display.
    </p>
    <br />
    <p>The bottom section displays up to 20 items in rows of 4 each (in the desktop view).</p>
  </div>
);

const SectionAccordion: React.FC<SectionAccordionProps> = ({ title, namePrefix }) => {
  const { register, control, getValues } = useFormContext();
  return (
    <Styled.AccordionWrapper>
      <StyledAccordion elevation={0}>
        <StyledAccordionSummary expandIcon={<Chevron />}>
          {title}
          <Styled.TooltipWrapper>
            <InfoTooltip content={<TooltipContent />} />
          </Styled.TooltipWrapper>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Styled.Content>
            <TextInput
              ref={register}
              label="Section heading"
              name={`${namePrefix}.title`}
              fullWidth
            />
            <Box mb="30px">
              {/* <FormLabel>Tag</FormLabel> */}
              <Styled.TagsSelectionWrapper>
                {/* <Styled.FormField>
                  <Styled.TagsContainer>
                    <Styled.InputWrapper as="label" htmlFor={title}>
                      <InputBase
                        id={title}
                        // value={inputValue}
                        // onKeyDown={handleKeyDown}
                        // onChange={handleChange}
                        placeholder="Add tag…"
                        inputProps={{ maxLength: MAX_TAG_LENGTH }}
                      />
                      <Styled.TagButton onClick={() => undefined} type="button">
                        <Checkmark />
                      </Styled.TagButton>
                    </Styled.InputWrapper>
                  </Styled.TagsContainer>
                </Styled.FormField> */}
                <Box mr="30px">
                  <TextInput
                    placeholder="Add filter..."
                    ref={register}
                    label="Filters"
                    name={`${namePrefix}.tag`}
                    fullWidth
                    info="If you select a filter here, only content with that tag or category will display in this section. If you leave
                    this field empty, any published content can be displayed."
                  />
                </Box>
                <FormControl component="fieldset">
                  <Controller
                    as={
                      <RadioGroup aria-label="tagType">
                        <FormControlLabel
                          value={TagType.internalTag}
                          control={<Radio size="small" color="primary" />}
                          label="Categories"
                        />
                        <FormControlLabel
                          value={TagType.regularTag}
                          control={<Radio size="small" color="primary" />}
                          label="Tags"
                          name={`${namePrefix}.tagType`}
                        />
                      </RadioGroup>
                    }
                    name={`${namePrefix}.tagType`}
                    defaultValue={TagType.internalTag}
                    control={control}
                  />
                </FormControl>
              </Styled.TagsSelectionWrapper>
              <Styled.InternalTagDescription>
                Only videos/moments that have the provided Tag/Category set will be included in this
                section. To add Tags or Categories to videos/moments go to their editor.
              </Styled.InternalTagDescription>
            </Box>
            <Styled.SelectWrapper>
              <Controller
                render={({ onChange, value, ref }) => {
                  return (
                    <Select
                      innerRef={ref}
                      id={`${namePrefix}.sortBy`}
                      label="Order by"
                      options={ORDERING_OPTIONS}
                      value={value}
                      onChange={({ currentTarget }) => {
                        onChange(currentTarget.value);
                      }}
                    />
                  );
                }}
                defaultValue={getValues(`${namePrefix}.sortBy`) || ORDERING_OPTIONS[0].value}
                name={`${namePrefix}.sortBy`}
                control={control}
              />
            </Styled.SelectWrapper>
            <Styled.SelectWrapper>
              <Controller
                render={({ onChange, value, ref }) => {
                  return (
                    <Select
                      innerRef={ref}
                      id={`${namePrefix}.contentType`}
                      label="Display"
                      options={CONTENT_TYPE_OPTIONS}
                      value={value}
                      onChange={({ currentTarget }) => {
                        onChange(currentTarget.value);
                      }}
                    />
                  );
                }}
                defaultValue={
                  getValues(`${namePrefix}.contentType`) || CONTENT_TYPE_OPTIONS[0].value
                }
                name={`${namePrefix}.contentType`}
                control={control}
              />
            </Styled.SelectWrapper>
          </Styled.Content>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Styled.AccordionWrapper>
  );
};

export default SectionAccordion;
