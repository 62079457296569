import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 10px;
  color: rgba(50, 51, 67, 0.68);
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
