import React from 'react';

import { Typography } from 'components/common';
import { Stack } from 'components/common/Wrapper/Layout';

import { useEditorContext } from '../MicrositeEditorContext';
import Other from '../Other';
import SectionHeadings from '../SectionHeadings';
import SiteBranding from '../SiteBranding';
import { SiteDetailsForm } from '../SiteDetails';

import * as Styled from '../MicrositeLayout/MicrositeLayout.styles';

interface Props {
  formId: string;
  onSubmit: (event: React.SyntheticEvent) => void;
}

export const CustomizeMicrosite: React.VFC<Props> = ({ formId, onSubmit }) => {
  const { initialMicrositeData } = useEditorContext();
  return (
    <Stack>
      <Stack rowGap="12px">
        <Typography variant="h1">Microsite Editor</Typography>
        <Typography variant="body2">Fields marked with an asterisk (*) are required</Typography>
      </Stack>

      <Styled.EditForm as="form" name="edit-microsite" id={formId} onSubmit={onSubmit}>
        <Styled.Content>
          <SiteDetailsForm
            isEditMode={true}
            domain={initialMicrositeData.domain}
            momentsLocationTop={initialMicrositeData.momentsLocationTop}
            title={initialMicrositeData.title}
            description={initialMicrositeData.description}
          />
        </Styled.Content>
        <SectionHeadings />
        <SiteBranding />
        <Other />
      </Styled.EditForm>
    </Stack>
  );
};
