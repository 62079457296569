import { useRouteMatch } from 'react-router-dom';

/**
 * The least specific route should be defined as last. For example, when having routes
 * `/dashboard` and `/dashboard/videos`, the `/dashboard` should be last.
 */
function useTabsWithRouter<Route extends string>(routes: Route[], defaultRoute: Route) {
  const match = useRouteMatch(routes);
  return match?.path ?? defaultRoute;
}

export default useTabsWithRouter;
