import { Box } from '@material-ui/core';
import React from 'react';

import { OrganizationName } from './OrganizationName';

export const OrganizationTab: React.VFC = () => {
  return (
    <Box p="30px 40px">
      <OrganizationName />
    </Box>
  );
};
