// @ts-ignore
import { VASTParser } from '@dailymotion/vast-client';

export const validateAdTagURL = async (url: string): Promise<boolean> => {
  const vastClient = new VASTParser();

  try {
    await vastClient.getAndParseVAST(url);
    return true;
  } catch {
    return false;
  }
};
