import { IconButton } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';

import { useVideoPlayerContext } from './context';

import * as Styled from './VideoPlayer.styles';

interface VideoSettingsQualityMenuItemProps {
  index: number;
  resolution: string;
}
interface VideoSettingsMenuItemProps {
  value: number;
  label: string;
  isSelected: boolean;
  onClick: () => void;
}
interface VideoSettingPlaybacksMenuItemProps {
  speed: number;
  label: string;
}

interface VideoSettingsMenuProps {
  showSettings: boolean;
}

interface SubMenuContentProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}
interface SubMenuItemProps {
  title: string;
  onClick: () => void;
  ariaTitle: string;
}

export const VideoSettingsMenuItem: React.FC<VideoSettingsMenuItemProps> = ({
  value,
  label,
  isSelected,
  onClick,
}) => {
  return (
    <Styled.SettingsItem key={value} isSelected={isSelected}>
      <Styled.SettingsValue onClick={onClick}>{label}</Styled.SettingsValue>
      {isSelected ? <Check className="settings-item-icon" /> : null}
    </Styled.SettingsItem>
  );
};

export const VideoSettingsQualityMenuItem: React.FC<VideoSettingsQualityMenuItemProps> = ({
  index,
  resolution,
}) => {
  const { onLevelChange, selectedLevel } = useVideoPlayerContext();
  const isSelected = selectedLevel === index;

  return (
    <VideoSettingsMenuItem
      value={index}
      label={resolution}
      isSelected={isSelected}
      onClick={() => onLevelChange(index)}
    />
  );
};

export const VideoSettingsPlaybackMenuItem: React.FC<VideoSettingPlaybacksMenuItemProps> = ({
  speed,
  label,
}) => {
  const { videoPlaybackRate, setVideoPlaybackRate } = useVideoPlayerContext();
  const isSelected = videoPlaybackRate === speed;

  return (
    <VideoSettingsMenuItem
      value={speed}
      label={label}
      isSelected={isSelected}
      onClick={() => setVideoPlaybackRate(speed)}
    />
  );
};

const playbackSpeeds = [
  { value: 0.25, label: '0.25x' },
  { value: 0.5, label: '0.5x' },
  { value: 0.75, label: '0.75x' },
  { value: 1, label: 'Normal' },
  { value: 1.25, label: '1.25x' },
  { value: 1.5, label: '1.5x' },
  { value: 1.75, label: '1.75x' },
  { value: 2, label: '2x' },
  { value: 3, label: '3x' },
];

const SubMenuContent: React.FC<SubMenuContentProps> = ({ title, onClose, children }) => {
  return (
    <>
      <Styled.SettingsTitle onClick={onClose}>
        <IconButton title="Go back" aria-label="Go back" color="inherit">
          <Arrow />
        </IconButton>
        {title}
      </Styled.SettingsTitle>
      <Styled.SettingsList>{children}</Styled.SettingsList>
    </>
  );
};
const SubMenuItem: React.FC<SubMenuItemProps> = ({ title, onClick, ariaTitle }) => {
  return (
    <>
      <Styled.SettingsRow onClick={onClick}>
        <Styled.SettingsType>{title}</Styled.SettingsType>
        <IconButton title={ariaTitle} aria-label={ariaTitle} color="inherit">
          <Arrow className="settings-icon" />
        </IconButton>
      </Styled.SettingsRow>
    </>
  );
};

export const VideoSettingsMenu: React.FC<VideoSettingsMenuProps> = ({ showSettings }) => {
  const { streamLevels } = useVideoPlayerContext();
  const [qualitySubMenuVisible, setQualitySubMenuVisible] = React.useState(false);
  const [playbackSubMenuVisibile, setPlaybackSubMenuVisibile] = React.useState(false);

  const enterQualitySubMenu = () => {
    setQualitySubMenuVisible(true);
  };
  const exitQualitySubMenu = () => {
    setQualitySubMenuVisible(false);
  };

  const enterPlaybackSubMenu = () => {
    setPlaybackSubMenuVisibile(true);
  };
  const exitPlaybackSubMenu = () => {
    setPlaybackSubMenuVisibile(false);
  };

  return (
    <Styled.SettingsMenu showSettings={showSettings}>
      {!qualitySubMenuVisible ? (
        <>
          {streamLevels && !playbackSubMenuVisibile && (
            <SubMenuItem title="Quality" onClick={enterQualitySubMenu} ariaTitle="Change quality" />
          )}
        </>
      ) : (
        <SubMenuContent title="Quality" onClose={exitQualitySubMenu}>
          <VideoSettingsQualityMenuItem index={-1} resolution="Auto" />
          {streamLevels?.map(({ width, height }, index) => {
            const RESOLUTION = `${width} x ${height}`;
            return (
              <VideoSettingsQualityMenuItem key={index} index={index} resolution={RESOLUTION} />
            );
          })}
        </SubMenuContent>
      )}
      {!playbackSubMenuVisibile ? (
        <>
          {!qualitySubMenuVisible && (
            <SubMenuItem
              title="Playback speed"
              onClick={enterPlaybackSubMenu}
              ariaTitle="Change playback speed"
            />
          )}
        </>
      ) : (
        <SubMenuContent title="Playback speed" onClose={exitPlaybackSubMenu}>
          {playbackSpeeds.map(({ value, label }, index) => {
            return <VideoSettingsPlaybackMenuItem key={index} speed={value} label={label} />;
          })}
        </SubMenuContent>
      )}
    </Styled.SettingsMenu>
  );
};
