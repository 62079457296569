import throttle from 'lodash.throttle';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { materialTheme } from 'components/App/materialTheme';
import { TextInput } from 'components/common';
import CustomColor from 'components/common/CustomColor';
import { EndCardPicker } from 'components/common/EndCardPicker';
import { GA4MeasurementIDInput } from 'components/common/GA4MeasurementIDInput';
import { GATrackingIDInput } from 'components/common/GATrackingIDInput';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { WidgetColorTheme } from 'models';
import { isNotEmptyString, validate } from 'utils/validation';

import { WidgetThemeDropdown } from './WidgetThemeDropdown';

interface FormData {
  name: string;
  googleAnalyticsTrackingId: string;
  googleAnalytics4MeasurementId: string;
  endCardId: string | undefined;
  colorTheme: WidgetColorTheme;
}

interface ExtendedFormData extends FormData {
  mainColor: string;
}

export type { ExtendedFormData as FormData };

interface Props {
  initialData?: ExtendedFormData;
  onSubmit: (data: ExtendedFormData) => void;
  name: string;
  submitButton: React.ReactNode;
}

export function PresetForm({
  initialData,
  onSubmit: onSubmitFromProps,
  name,
  submitButton,
}: Props) {
  const [customColor, setCustomColor] = React.useState(
    initialData?.mainColor || materialTheme.palette.primary.main,
  );
  const setCustomColorThrottled = React.useMemo(
    () => throttle(setCustomColor, 500, { trailing: true }),
    [setCustomColor],
  );

  const presetForm = useForm<FormData>({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: initialData,
  });
  const { register, handleSubmit, errors, control } = presetForm;

  const onSubmit = (data: FormData) => {
    onSubmitFromProps({
      ...data,
      mainColor: customColor,
    });
  };

  return (
    <FormProvider {...presetForm}>
      <form name={name} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Columns display="flex">
            <Stack flex="1 1 50%" textAlign="left">
              <TextInput
                label="Preset Name (required)"
                name="name"
                placeholder="Type name"
                defaultValue=""
                ref={register({
                  validate: validate(isNotEmptyString, 'Preset name cannot be empty'),
                })}
                errorMessage={errors.name?.message}
                mb="0"
                maxLength={256}
              />
              <GATrackingIDInput name="googleAnalyticsTrackingId" mb="0" />
              <GA4MeasurementIDInput name="googleAnalytics4MeasurementId" mb="0" />
              <Controller
                render={({ onChange, value, name, ref }) => {
                  return (
                    <WidgetThemeDropdown
                      ref={ref}
                      id="widget-theme"
                      name={name}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
                defaultValue={WidgetColorTheme.Light}
                name="colorTheme"
                control={control}
              />
            </Stack>
            <Stack flex="1 1 50%" textAlign="left">
              <EndCardPicker
                control={control}
                createButtonLabel="Create new end card"
                backButtonPlaceholder="widget presets"
                mt="0"
              />
              <CustomColor onColorChange={setCustomColorThrottled} selectedColor={customColor} />
            </Stack>
          </Columns>
          {submitButton}
        </Stack>
      </form>
    </FormProvider>
  );
}
