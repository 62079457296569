import gql from 'graphql-tag';
import { range } from 'ramda';

import { ToggleAction } from 'hooks/mutation/_useToggleMutation';

import { ERRORS_PART } from './_errors';

export { ToggleAction };
export enum BulkResource {
  Video = 'Video',
  Moment = 'Moment',
  ObjectAnnotation = 'ObjectAnnotation',
}
enum BulkOperation {
  Delete = 'Delete',
  Toggle = 'Toggle',
}

const resourceKeyMap: Record<BulkResource, string> = {
  [BulkResource.Video]: 'video',
  [BulkResource.Moment]: 'moment',
  [BulkResource.ObjectAnnotation]: 'objectAnnotation',
};

const getMutationLabel = (index: number) => `item${index}`;

const getDeleteItemByAlias = (resource: BulkResource, index: number) => {
  const label = getMutationLabel(index);
  const mutation = `delete${resource}`;
  return `${label}: ${mutation}(id: $id${index}) {
    deleted${resource}Id
    ${ERRORS_PART}
  }`;
};

const getToggleItemByAlias = (action: ToggleAction) => (resource: BulkResource, index: number) => {
  const label = getMutationLabel(index);
  const mutation = `toggle${resource}`;
  return `${label}: ${mutation}(id: $id${index}, action: ${action}) {
    ${resourceKeyMap[resource]} {
      id
    }
    ${ERRORS_PART}
  }`;
};

export const getPathsToErrors = (count: number) => {
  return range(0, count).map((index) => `${getMutationLabel(index)}.errors`);
};

const createArgsDeclaration = (countRange: number[]) =>
  countRange.reduce((args, index) => args + `,$id${index}: ID!`, '').substring(1);

const getBulkMutation = (
  operation: BulkOperation,
  resource: BulkResource,
  getItemByAlias: (resource: BulkResource, index: number) => string,
  count: number,
) => {
  const name = `${operation}${resource}`;
  const countRange = range(0, count || 1);
  const args = createArgsDeclaration(countRange);

  return gql`
    mutation ${name}(${args}) {
      ${countRange.reduce((aliases, index) => aliases + getItemByAlias(resource, index), '')}
    }
  `;
};

export const getBulkDeleteMutation = (resource: BulkResource, count: number) =>
  getBulkMutation(BulkOperation.Delete, resource, getDeleteItemByAlias, count);

export const getBulkToggleMutation = (
  action: ToggleAction,
  resource: BulkResource,
  count: number,
) => getBulkMutation(BulkOperation.Toggle, resource, getToggleItemByAlias(action), count);
