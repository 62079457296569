import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select as MaterialSelect,
} from '@material-ui/core';
import React, { forwardRef, Ref } from 'react';

import { FormLabel } from '../FormLabel';
import { InfoTooltip } from '../InfoTooltip';
import { SelectProps } from './types';

import { MenuItemTypography } from './CustomSelect.styles';

const selectStyles = makeStyles(() => ({
  formControl: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#32353E',
    },
  },
}));

const menuItemTextStyles = {
  fontStyle: 'italic',
  marginLeft: 8,
};

export const CustomSelect = forwardRef(function <TValue extends string>(
  { id, options, label, info, mb, infoPlacement, ...selectProps }: SelectProps<TValue>,
  ref: Ref<HTMLSelectElement>,
) {
  const selectClasses = selectStyles();

  return (
    <FormControl fullWidth className={selectClasses.formControl}>
      <Box mb={mb}>
        {label && (
          <FormLabel>
            {label}
            {info ? <InfoTooltip content={info} placement={infoPlacement} /> : null}
          </FormLabel>
        )}
        <MaterialSelect
          labelId={id}
          className={selectClasses.formControl}
          variant="outlined"
          {...selectProps}
          ref={ref}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                marginTop: 8,
                maxHeight: 200,
              },
            },
          }}
        >
          {options.map(({ label, value, disabled, hasBadge, Component }) => (
            <MenuItem key={value} value={value} disabled={disabled}>
              {Component ? (
                <Box mr="12px" display="inline-flex">
                  <Component />
                </Box>
              ) : null}
              <MenuItemTypography style={hasBadge ? menuItemTextStyles : null}>
                {label}
              </MenuItemTypography>
            </MenuItem>
          ))}
        </MaterialSelect>
      </Box>
    </FormControl>
  );
});
