import { partition, splitAt } from 'ramda';

import { EndCardResponse } from 'pages/EndCard/types';

export const prioritizeEndCards = (endCards: EndCardResponse[] = [], videoId?: string) => {
  const [sameVideoId, differentVideoId] = partition(({ videos, moments }) => {
    return !!(
      videos.find((video) => video.id === videoId) ||
      moments.find((moment) => moment.video.id === videoId)
    );
  }, endCards);

  const [mostRecent, leastRecent] = splitAt(
    3,
    differentVideoId.sort((a, b) => compareDateDescending(a, b) || compareName(a, b)),
  );

  return [
    ...sameVideoId.sort(compareName),
    ...mostRecent,
    ...leastRecent.sort((a, b) => compareUsageDescending(a, b) || compareName(a, b)),
  ];
};

function compareName(a: EndCardResponse, b: EndCardResponse) {
  return a.name.localeCompare(b.name);
}

function compareDateDescending(a: EndCardResponse, b: EndCardResponse) {
  return new Date(b.created).getTime() - new Date(a.created).getTime();
}

function compareUsageDescending(a: EndCardResponse, b: EndCardResponse) {
  return b.momentsCount + b.videosCount - (a.momentsCount + a.videosCount);
}
