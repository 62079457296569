import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import { useCreateEndCard } from 'hooks/mutation/useCreateEndCard';
import { EditEndCardView } from 'pages/EndCard/EditEndCardView';
import { CreateEndCardInput, EndCardFormData } from 'pages/EndCard/types';
import { generateEndCardEditLink } from 'pages/urls';

function NewEndCardPage() {
  const history = useHistory();
  const { state } = useLocation();

  const [createEndCard, { loading: isLoadingCreate }] = useCreateEndCard({
    onCompleted: (data) => {
      if (data?.createEndCard?.endCard?.id) {
        history.replace(generateEndCardEditLink(data.createEndCard.endCard.id), state);
      }
    },
  });

  const onSubmit = async (formData: EndCardFormData, showNextMomentBtn: boolean) => {
    const variablesData: CreateEndCardInput = {
      name: formData.name,
      cardOrientation: formData.cardOrientation,
      cardSize: formData.cardSize,
      overlay: formData.overlay,
      background: formData.background,
      backgroundImageSettings: formData.backgroundImageSettings,
      border: formData.border,
      videoControlButtons: formData.videoControlButtons,
      headline: formData.headline,
      subline: formData.subline,
      ctaButton1: formData.ctaButton1,
      ctaButton2: formData.ctaButton2,
      watchNextButtonVisible: showNextMomentBtn,
    };

    if (formData.backgroundImageUrl && !formData.backgroundImageUrl.startsWith('https')) {
      variablesData.backgroundImageBase64 = formData.backgroundImageUrl;
    }

    if (formData.promoCodeText && formData.promoCode) {
      variablesData.promoCodeButton = {
        text: formData.promoCodeText,
        label: formData.promoCode,
      };
    }

    await createEndCard({
      variables: {
        endCard: {
          ...variablesData,
        },
      },
    });
  };

  return (
    <MainLayout>
      <EditEndCardView handleSave={onSubmit} isLoading={isLoadingCreate} />
    </MainLayout>
  );
}

export default NewEndCardPage;
