import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Typography } from 'components/common';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { useTangleMainBar } from 'context/mainBar';
import { URLS } from 'pages/urls';

import { CTAsTab } from './CTAsTab';
import { EndCardsTab } from './EndCardsTab';
import { OverlaysTabs } from './OverlaysTabs';

export const OverlaysPage: React.VFC = () => {
  useTangleMainBar(false);

  const [tabsActionContentWidth, setTabsActionContentWidth] = useState(0);

  const getTabsActionContentWidth = (width: number) => {
    setTabsActionContentWidth(width);
  };

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">Overlays</Typography>
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <OverlaysTabs tabsActionContentWidth={tabsActionContentWidth} />
          <Switch>
            <Route path={[URLS.overlays.root, URLS.overlays.endCards]} exact>
              <EndCardsTab getActionContentWidth={getTabsActionContentWidth} />
            </Route>
            <Route path={[URLS.overlays.ctas]} exact>
              <CTAsTab getActionContentWidth={getTabsActionContentWidth} />
            </Route>
          </Switch>
        </BigCard>
      </Box>
    </MainLayout>
  );
};
