declare global {
  interface Window {
    ResizeObserver: typeof import('@juggle/resize-observer').ResizeObserver;
  }
}

(async () => {
  if (!('ResizeObserver' in window)) {
    const module = await import('@juggle/resize-observer');
    window.ResizeObserver = module.ResizeObserver;
  }
})();

// needed because of --isolatedModules
export {};
