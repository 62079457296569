import { Box } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { DropdownButton } from 'components/common/DropdownButton/DropdownButton';
import {
  EmptyTabAddButton,
  EmptyTabInformationButton,
  EmptyTabTitle,
} from 'components/common/EmptyTab';
import { InformationalModal } from 'components/common/Modals';

import { CTALinks } from './constants';

export const CTAEmptyData: React.VFC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const renderModalDescription = () => (
    <Box textAlign="left">
      <Box mb="24px">CTAs are small, non-obscuring overlays displayed while video is played.</Box>
      <Box mb="24px">
        It can be a button or a clickable custom image (product, person, small banner, or label),
        which allow to enhance videos with interactive promotional content and link it to the
        external source.
      </Box>
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p="30px 40px">
      <EmptyTabTitle>
        Create CTAs to use them in your End Cards or overlaid on videos.
      </EmptyTabTitle>
      <EmptyTabAddButton>
        <DropdownButton dropdownLinks={CTALinks} />
      </EmptyTabAddButton>

      <EmptyTabInformationButton onClick={handleModalOpen}>
        What are CTAs?
      </EmptyTabInformationButton>
      <InformationalModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        heading="What are CTAs?"
        description={renderModalDescription()}
      />
    </Box>
  );
};
