import { Box, TooltipProps } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { InfoTooltip } from 'components/common';
import { URLS } from 'pages/urls';

import {
  DISABLED_MONETIZATION_FOR_VIDEO_TOOLTIP,
  ENABLED_MONETIZATION_FOR_VIDEO_TOOLTIP,
} from '../constants';
import { VideoMonetizationToggle } from './VideoMonetizationToggle';

import { DisabledMonitizationLabel, MonetizationInfoTooltip } from '../VideoMonetization.styles';

interface Props {
  videoId: string;
  isMonetizationEnabled: boolean;
  isVideoMonetizationEnabled: boolean;
}

export const VideoMonetizationSidebarToggle: React.FC<Props> = ({
  videoId,
  isMonetizationEnabled,
  isVideoMonetizationEnabled,
}) => {
  const getTooltipDescription = () => {
    if (!isMonetizationEnabled) {
      return (
        <MonetizationInfoTooltip>
          Monetization is disabled for your organization. Use the{' '}
          <Link to={URLS.organization.monetization}>Monetization</Link> tab under your organization
          to enable monetization.
        </MonetizationInfoTooltip>
      );
    }
    if (!isVideoMonetizationEnabled) {
      return DISABLED_MONETIZATION_FOR_VIDEO_TOOLTIP;
    }
    return ENABLED_MONETIZATION_FOR_VIDEO_TOOLTIP;
  };

  return (
    <>
      <DisabledMonitizationLabel $disabled={!isMonetizationEnabled}>
        Monetization
        <Box ml="10px">
          <InfoTooltip
            content={getTooltipDescription()}
            options={{ interactive: true } as TooltipProps}
          />
        </Box>
      </DisabledMonitizationLabel>
      <VideoMonetizationToggle
        checked={isVideoMonetizationEnabled}
        disabled={!isMonetizationEnabled}
        refetchQuery="GetVideo"
        videoId={videoId}
      />
    </>
  );
};
