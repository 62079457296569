import { MOMENTS_LIST_QUERY } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { DashboardMomentNode, MomentOrderBy, PageInfo, PaginationVariables } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  moments: Nullable<{
    nodes: Nullable<DashboardMomentNode[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends PaginationVariables {
  orderBy?: MomentOrderBy;
  search?: string;
}

interface UseDashboardMomentsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useDashboardMomentsQuery = (options: UseDashboardMomentsQueryOptions) => {
  return useQuery<ResponseData, Variables>(MOMENTS_LIST_QUERY, options);
};
