import {
  CustomizablePauseScreen,
  lightTheme,
  NewCustomizablePauseScreen,
} from '@entertainment-ai/react-component-library';
import { Box } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import previewBg from 'assets/images/end-card-preview-bg.jpg';
import * as Styled from 'components/common/PageContentReview';
import { useButtonCTAQuery } from 'hooks/query/useButtonCTAQuery';
import { convertButtonDataFromServer } from 'pages/CTAButton/helpers';

import { EndCardFormData, PromoCodeProps } from '../types';

enum TabsNames {
  Videos = 'Videos',
  Moments = 'Moments',
  Widget = 'Widget',
}

const tabsList = [TabsNames.Videos, TabsNames.Moments, TabsNames.Widget];

export const EndCardPreview: React.FC<EndCardFormData> = ({
  promoCodeText,
  promoCode,
  watchNextButtonVisible,
  socialMedia,
  overlay,
  background,
  backgroundImageSettings,
  border,
  videoControlButtons,
  headline,
  subline,
  backgroundImageUrl,
  cardSize,
  ctaButton1,
  ctaButton2,
  promoCodeButton,
  cardType,
  cardOrientation,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const { data: cta1Data } = useButtonCTAQuery({ variables: { id: ctaButton1 || '' } });
  const { data: cta2Data } = useButtonCTAQuery({ variables: { id: ctaButton2 || '' } });

  const promoCodeData: PromoCodeProps | undefined =
    promoCodeText && promoCode
      ? {
          code: promoCode,
          text: promoCodeText,
          onCopy: () => {},
        }
      : undefined;

  const isVideo = tabsList[activeTab] === TabsNames.Videos;
  const isWidget = tabsList[activeTab] === TabsNames.Widget;

  const ctaButton1Data = useMemo(() => {
    if (cta1Data?.buttonCtaById) {
      return convertButtonDataFromServer(cta1Data?.buttonCtaById);
    }

    return undefined;
  }, [cta1Data]);

  const ctaButton2Data = useMemo(() => {
    if (cta2Data?.buttonCtaById) {
      return convertButtonDataFromServer(cta2Data?.buttonCtaById);
    }

    return undefined;
  }, [cta2Data]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Styled.PreviewCard>
        <Styled.PreviewCardHeader>
          <Styled.PreviewCardLabel>Preview for:</Styled.PreviewCardLabel>
          <Styled.PreviewCardTabs>
            {tabsList.length
              ? tabsList.map((tab, index) => (
                  <li key={index}>
                    <Styled.PreviewCardTab
                      onClick={(e) => setActiveTab(Number(e.currentTarget.dataset.tab) || 0)}
                      data-tab={index}
                      isActive={index === activeTab}
                    >
                      {tab}
                    </Styled.PreviewCardTab>
                  </li>
                ))
              : null}
          </Styled.PreviewCardTabs>
        </Styled.PreviewCardHeader>
        <Styled.PreviewCardBody
          height={cardOrientation === 'HORIZONTAL' ? '420px' : '800px'}
          position="relative"
          borderRadius="5px"
          width={cardOrientation === 'HORIZONTAL' ? 'auto' : '450px'}
          style={{
            backgroundImage: `url(${previewBg})`,
            backgroundSize: 'cover',
            margin: '0 auto',
          }}
        >
          {/* @ts-ignore */}
          <NewCustomizablePauseScreen
            isVisible={true}
            title={!isVideo ? 'Moment title' : 'Video title'}
            objects={[]}
            showShareMoment={!isVideo && !isWidget}
            showWatchFullVideo={!isVideo && !isWidget}
            showWatchNextMomentButton={!isVideo && watchNextButtonVisible}
            justifyContent="center"
            nextMomentHandler={() => {}}
            promoCodeButton={promoCodeButton}
            onReplay={() => {}}
            socialMediaButtons={socialMedia}
            overlay={overlay}
            background={background}
            backgroundImageSettings={backgroundImageSettings}
            border={border}
            videoControlButtons={videoControlButtons}
            headline={headline}
            subline={subline}
            backgroundImageUrl={backgroundImageUrl}
            // @ts-ignore
            cardOrientation={cardOrientation}
            cardSize={cardSize}
            ctaButton1={ctaButton1Data}
            ctaButton2={ctaButton2Data}
          />
        </Styled.PreviewCardBody>
      </Styled.PreviewCard>
      {cardType === 'LEGACY' && (
        <Box p="40px 0" display="flex" flexDirection="column">
          <Styled.PreviewLabel>Mobile preview (380px screen width)</Styled.PreviewLabel>
          <Box width="380px" height="215px" position="relative">
            <CustomizablePauseScreen
              isVisible={true}
              title={'Moment title'}
              objects={[]}
              showShareMoment={!isVideo && !isWidget}
              showWatchFullVideo={!isVideo && !isWidget}
              showWatchNextMomentButton={!isVideo && watchNextButtonVisible}
              justifyContent="center"
              nextMomentHandler={() => {}}
              promoCode={promoCodeData}
              //@ts-ignore
              // callToActionButton={CTAButtonData}
              onReplay={() => {}}
              showMobileVersion={true}
              socialMediaButtons={socialMedia}
            />
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
};
