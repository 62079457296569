import { END_CARDS_QUERY } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { EndCardsOrderBy, EndCardsResponse } from 'pages/EndCard/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  endCards: Nullable<EndCardsResponse>;
}

interface Variables {
  orderBy?: EndCardsOrderBy;
  pageSize: number;
}

interface UseEndCardsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useEndCardsQuery = (options?: UseEndCardsQueryOptions) => {
  return useQuery<ResponseData, Variables>(END_CARDS_QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });
};
