import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useUpdateEndCard } from 'hooks/mutation';
import { useEndCardQuery } from 'hooks/query';

import { EditCustomCodeEndCardView } from './EditCustomCodeEndCardView';
import { CustomCodeEndCardFormData } from './types';

export const EditCustomCodeEndCardPage = () => {
  const { endCardId } = useParams<{ endCardId: string }>();
  const { data, loading } = useEndCardQuery(endCardId);

  const [updateEndCard, { loading: isLoadingUpdate }] = useUpdateEndCard();

  const onSubmit = async (data: CustomCodeEndCardFormData) => {
    const { name, customCode } = data;

    await updateEndCard({
      variables: {
        id: endCardId,
        endCard: {
          name,
          customCode,
        },
      },
    });
  };

  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (data?.endCardById) {
    return (
      <MainLayout>
        <EditCustomCodeEndCardView
          endCard={data.endCardById}
          handleSave={onSubmit}
          isLoading={isLoadingUpdate}
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div>{endCardId}</div>
    </MainLayout>
  );
};
