import { last } from 'ramda';
import React, { useState } from 'react';

import { ReactComponent as SliderGrabIcon } from 'assets/icons/slider-grab.svg';
import thumbnailPlaceholder from 'assets/images/thumbnail-placeholder.jpg';
import {
  getPositionOnTimeline,
  getTimeBoundsForRange,
  getTimestampOnTimeline,
} from 'components/TimelineEditor/Timeline.utils';
import { DEFAULT_TIMESTAMP_LENGTH } from 'config/constants';
import { useMove } from 'hooks/useMove';
import { useResize } from 'hooks/useResize';
import { Range, TimeRange, VideoMomentNode } from 'models';
import { useMomentsEditorContext } from 'pages/Moments/context';
import { pxOrString } from 'utils/styling';

import { moveRangeOnTimeline } from './RangeSelector.utils';

import * as Styled from './RangeSelector.styles';

const BLOCK_SIZE = 12;
const getBlockTransform = (x: number) => `translateX(${pxOrString(x - BLOCK_SIZE / 2)})`;
interface OwnProps<TRange extends Range> {
  ranges: TRange[];
  onRangeChange?: (range: TRange, skipSave?: boolean) => void;
  rulerRange: TimeRange;
  rulerWidth: number;
  seekTo: (ms: number) => void;
  videoDuration: number;
  minRangeLength: number;
  isDisabled?: boolean;
  moments: VideoMomentNode[];
}

export function RangeSelector<TRange extends Range>({
  ranges,
  onRangeChange,
  rulerRange,
  rulerWidth,
  seekTo,
  videoDuration,
  minRangeLength,
  isDisabled,
  moments,
}: OwnProps<TRange>) {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const { showCreateMoment, allowEditing } = useMomentsEditorContext();

  const newMomentRange = { startTimestamp: 0, endTimestamp: DEFAULT_TIMESTAMP_LENGTH } as TRange;
  const isNewMomentRangeVisible =
    (!ranges.some((range) => range.startTimestamp < DEFAULT_TIMESTAMP_LENGTH) || !ranges.length) &&
    !showCreateMoment &&
    allowEditing;

  return (
    <Styled.Wrapper ref={wrapperRef}>
      {isNewMomentRangeVisible ? (
        <RangeBox
          range={newMomentRange}
          onRangeChange={onRangeChange}
          rulerRange={rulerRange}
          rulerWidth={rulerWidth}
          wrapperRef={wrapperRef}
          seekTo={seekTo}
          timeBounds={getTimeBoundsForRange(newMomentRange, ranges, videoDuration)}
          minRangeLength={minRangeLength}
          isDisabled={isDisabled}
        />
      ) : null}

      {ranges.map((range, index) => {
        return (
          <RangeBox
            key={range.id}
            range={range}
            onRangeChange={onRangeChange}
            rulerRange={rulerRange}
            rulerWidth={rulerWidth}
            wrapperRef={wrapperRef}
            seekTo={seekTo}
            timeBounds={getTimeBoundsForRange(range, ranges, videoDuration)}
            minRangeLength={minRangeLength}
            isDisabled={isDisabled}
            moment={moments[index]}
          />
        );
      })}
    </Styled.Wrapper>
  );
}

interface RangeBoxProps<TRange extends Range> {
  range: TRange;
  onRangeChange?: (range: TRange, skipSave?: boolean) => void;
  rulerRange: TimeRange;
  rulerWidth: number;
  wrapperRef: React.RefObject<HTMLDivElement>;
  seekTo: (ms: number) => void;
  timeBounds: [number, number];
  minRangeLength: number;
  isDisabled?: boolean;
  moment?: VideoMomentNode;
}

const EMPTY_ARRAY: never[] = [];

function RangeBox<TRange extends Range>({
  range,
  onRangeChange,
  rulerRange,
  rulerWidth,
  wrapperRef,
  timeBounds: [startBound, endBound],
  minRangeLength,
  isDisabled,
  moment,
}: RangeBoxProps<TRange>) {
  const {
    handleMomentClick,
    selectedMomentId: selectedMoment,
    allowEditing,
    setShowCreateMoment,
    showCreateMoment,
  } = useMomentsEditorContext();
  const blocks = range.blocks ?? EMPTY_ARRAY;
  const blockRefs = React.useRef<HTMLButtonElement[]>([]);
  const [boxRange, setBoxRange] = useState(range);

  // Update internal boxRange state to match the prop value if its timestamps change outside
  // of this component.
  React.useLayoutEffect(() => {
    setBoxRange(range);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.startTimestamp, range.endTimestamp]);

  const barRef = React.useRef<HTMLDivElement>(null);

  // reset block refs and assign them again on each rerender to sync blocks with refs
  React.useLayoutEffect(() => {
    return () => {
      blockRefs.current = [];
    };
  }, [blocks]);

  const handleResize = useResize(
    barRef,
    wrapperRef,
    (event, resizerElement, { element, mouse }) => {
      if (isDisabled || mouse.diffX === 0) {
        return;
      }

      const { resizer } = resizerElement.dataset;
      const newRange = { ...range };

      let leftPosition = element.oldLeft;
      let rightPosition = leftPosition + element.oldWidth;

      if (resizer === 'left') {
        newRange.startTimestamp = getTimestampOnTimeline(
          rulerRange,
          rulerWidth,
          leftPosition + mouse.diffX,
        );
        const firstBlockTimestamp = blocks[0]?.timestamp;

        if (firstBlockTimestamp && newRange.startTimestamp > firstBlockTimestamp) {
          newRange.startTimestamp = firstBlockTimestamp;
        }
        if (newRange.startTimestamp > newRange.endTimestamp - minRangeLength) {
          newRange.startTimestamp = newRange.endTimestamp - minRangeLength;
        }
        if (newRange.startTimestamp < startBound) {
          newRange.startTimestamp = startBound;
        }

        leftPosition = getPositionOnTimeline(rulerRange, rulerWidth, newRange.startTimestamp);
      } else if (resizer === 'right') {
        newRange.endTimestamp = getTimestampOnTimeline(
          rulerRange,
          rulerWidth,
          rightPosition + mouse.diffX,
        );
        const lastBlockTimestamp = last(blocks)?.timestamp;

        if (lastBlockTimestamp && newRange.endTimestamp < lastBlockTimestamp) {
          newRange.endTimestamp = lastBlockTimestamp;
        }
        if (newRange.endTimestamp < newRange.startTimestamp + minRangeLength) {
          newRange.endTimestamp = newRange.startTimestamp + minRangeLength;
        }
        if (newRange.endTimestamp > endBound) {
          newRange.endTimestamp = endBound;
        }

        rightPosition = getPositionOnTimeline(rulerRange, rulerWidth, newRange.endTimestamp);
      }

      const width = rightPosition - leftPosition;
      requestAnimationFrame(() => {
        barRef.current!.style.width = pxOrString(width);

        if (leftPosition !== element.oldLeft) {
          barRef.current!.style.transform = Styled.getBarTransform(leftPosition);
          blockRefs.current.forEach((blockElement, index) => {
            const blockPosition = getPositionOnTimeline(
              rulerRange,
              rulerWidth,
              newRange.blocks![index].timestamp,
            );
            blockElement.style.transform = getBlockTransform(blockPosition - leftPosition);
          });
        }
      });

      setBoxRange(newRange);
    },
  );

  const handleMove = useMove(barRef, wrapperRef, (event, { oldLeft, newLeft }) => {
    if (isDisabled || oldLeft === newLeft || event.type === 'mouseup') {
      return;
    }

    const elementWidth = Math.max(boxRange.endTimestamp - boxRange.startTimestamp, minRangeLength);
    let startTimestamp = getTimestampOnTimeline(rulerRange, rulerWidth, newLeft);

    if (startTimestamp < startBound) {
      startTimestamp = startBound;
    }
    if (startTimestamp > endBound - elementWidth) {
      startTimestamp = endBound - elementWidth;
    }

    const newRange = moveRangeOnTimeline(boxRange, startTimestamp);
    const barPosition = getPositionOnTimeline(rulerRange, rulerWidth, startTimestamp);
    requestAnimationFrame(() => {
      barRef.current!.style.transform = Styled.getBarTransform(barPosition);
      blockRefs.current.forEach((blockElement, index) => {
        const blockPosition = getPositionOnTimeline(
          rulerRange,
          rulerWidth,
          newRange.blocks![index].timestamp,
        );
        blockElement.style.transform = getBlockTransform(blockPosition - barPosition);
      });
    });

    setBoxRange(newRange);
    if (onRangeChange) {
      onRangeChange(newRange, true);
    }
  });

  const leftPosition = getPositionOnTimeline(rulerRange, rulerWidth, range.startTimestamp);
  const rightPosition = getPositionOnTimeline(rulerRange, rulerWidth, range.endTimestamp);

  const handleMouseUp = () => {
    if (
      onRangeChange &&
      (range.startTimestamp !== boxRange.startTimestamp ||
        range.endTimestamp !== boxRange.endTimestamp)
    ) {
      onRangeChange(boxRange);
    }
  };

  return moment ? (
    <Styled.Bar
      ref={barRef}
      x={leftPosition}
      width={rightPosition - leftPosition}
      onMouseUp={handleMouseUp}
      onMouseDown={allowEditing ? handleMove : undefined}
      onClick={() => handleMomentClick(moment.id)}
      isDisabled={!allowEditing}
      title={moment.title}
    >
      {allowEditing ? (
        <>
          <Styled.GrabBox data-resizer="left" onMouseDown={handleResize}>
            <SliderGrabIcon />
          </Styled.GrabBox>
          <Styled.GrabBox data-resizer="right" onMouseDown={handleResize}>
            <SliderGrabIcon />
          </Styled.GrabBox>
        </>
      ) : null}
      <Styled.MomentContent isSelected={selectedMoment === moment.id}>
        <Styled.MomentThumbnail src={moment.thumbnailUrl || thumbnailPlaceholder} alt="" />
        <Styled.MomentTitle>{moment.title}</Styled.MomentTitle>
      </Styled.MomentContent>
    </Styled.Bar>
  ) : (
    <Styled.Bar
      onClick={() => setShowCreateMoment(!showCreateMoment)}
      newMoment={true}
      ref={barRef}
      x={leftPosition}
      width={rightPosition - leftPosition}
    >
      +
    </Styled.Bar>
  );
}
