import { ObjectAnnotationCategory, VideoDetailsNode, VideoObjectNode } from 'models';

export interface PromoteContentSettingsProps {
  promoteObjects: boolean;
  promoteAtStart: boolean;
  promoteAtEnd: boolean;
  promotedObjectsLabel?: string;
}

export interface PromoteContentViewProps {
  video: VideoDetailsNode;
}

export interface PromoteContentSidebarProps {
  video: VideoDetailsNode;
  onSave: (data: PromoteContentSettingsProps | null) => Promise<void>;
  togglePromoteObjects: (val: boolean) => void;
  togglePromoteAtStart: (val: boolean) => void;
  togglePromoteAtEnd: (val: boolean) => void;
  setPromoteObjectsLabel: (val: string) => void;
  promoteContentSettings: PromoteContentSettingsProps;
}

export interface PromoteContentForm {
  promoteAtStart: boolean;
  promoteAtEnd: boolean;
  promotedObjectsLabel?: string;
}

export interface PromoteContentMainProps {
  video: VideoDetailsNode;
  isLoading: boolean;
  promoteContentSettings: PromoteContentSettingsProps;
}

export enum TogglePromoteContentActions {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export interface EditObjectModalProps {
  onClose: () => void;
  isOpen: boolean;
  object: VideoObjectNode;
}

export interface CreateObjectModalProps {
  onClose: () => void;
  isOpen: boolean;
  object?: VideoObjectNode;
  videoId: string;
}

export interface EditObjectFormProps {
  url: string;
  title: string;
  category: ObjectAnnotationCategory;
}
