import React from 'react';

type ResizerElement = EventTarget & HTMLElement;

export type ResizePayload = {
  element: {
    oldLeft: number;
    oldTop: number;
    oldWidth: number;
    oldHeight: number;
  };
  mouse: {
    oldX: number;
    oldY: number;
    diffX: number;
    diffY: number;
  };
};

type ResizeCallback = (
  event: MouseEvent,
  resizerElement: ResizerElement,
  payload: ResizePayload,
) => void;

export function useResize(
  elementRef: React.RefObject<HTMLElement>,
  wrapperRef: React.RefObject<HTMLElement>,
  onResize: ResizeCallback,
) {
  const resizerElement = React.useRef<ResizerElement>();
  const originalMousePosition = React.useRef({
    x: 0,
    y: 0,
  });
  const originalRect = React.useRef({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  const resize = (event: MouseEvent) => {
    onResize(event, resizerElement.current!, {
      element: {
        oldLeft: originalRect.current.left,
        oldTop: originalRect.current.top,
        oldWidth: originalRect.current.width,
        oldHeight: originalRect.current.height,
        // Diff is not provided, because we don't know which side of the element is being resized.
        // It's up to the user to decide the direction based on mouse position and resizerElement.
      },
      mouse: {
        oldX: originalMousePosition.current.x,
        oldY: originalMousePosition.current.y,
        diffX: event.pageX - originalMousePosition.current.x,
        diffY: event.pageY - originalMousePosition.current.y,
      },
    });
  };

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const element = elementRef.current;
    const wrapper = wrapperRef.current;

    if (!element || !wrapper) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    resizerElement.current = event.currentTarget;

    originalRect.current.width = parseFloat(getComputedStyle(element).width);
    originalRect.current.height = parseFloat(getComputedStyle(element).height);

    const { left, top } = element.getBoundingClientRect();
    const { left: wrapperLeft, top: wrapperTop } = wrapper.getBoundingClientRect();

    // Make sure that `left` and `top` are measured within the wrapper
    originalRect.current.left = left - wrapperLeft;
    originalRect.current.top = top - wrapperTop;
    originalMousePosition.current.x = event.pageX;
    originalMousePosition.current.y = event.pageY;

    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', finishResize);

    function finishResize(event: MouseEvent) {
      resize(event);
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', finishResize);
    }
  };

  return onMouseDown;
}
