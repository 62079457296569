import { Box } from '@material-ui/core';
import React from 'react';

import { Typography } from 'components/common';
import { Divider } from 'components/common/Divider';
import { ModalPageLayout } from 'components/layouts/ModalPageLayout';

import { GoogleLoginForm } from './GoogleLoginForm';
import { LoginForm } from './LoginForm';

export const LoginPage: React.FC = () => {
  const [isLoginFormExclusive, setIsLoginFormExclusive] = React.useState(false);

  return (
    <ModalPageLayout>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        py={isLoginFormExclusive ? '40px' : '64px'}
        px={isLoginFormExclusive ? '40px' : '180px'}
      >
        {!isLoginFormExclusive && (
          <Box textAlign="center" width="100%">
            <Box mb="40px">
              <Typography variant="h1">Log in</Typography>
            </Box>
            <GoogleLoginForm />
            <Box mt="38px" mb="22px" width="100%">
              <Divider>Or</Divider>
            </Box>
          </Box>
        )}
        <LoginForm isExclusive={isLoginFormExclusive} setIsExclusive={setIsLoginFormExclusive} />
      </Box>
    </ModalPageLayout>
  );
};
