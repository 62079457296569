import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const CREATE_IMAGE_CTA_MUTATION = gql`
  mutation createImageCta($image_cta: CreateImageCTAInput!) {
    createImageCta(imageCta: $image_cta) {
      imageCta {
        id
        created
        name
        url
        imageUrl
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_IMAGE_CTA_MUTATION = gql`
  mutation updateImageCta($id: ID!, $image_cta: UpdateImageCTAInput!) {
    updateImageCta(id: $id, imageCta: $image_cta) {
      imageCta {
        id
        created
        name
        url
        imageUrl
      }
      ${ERRORS_PART}
    }
  }
`;
