import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { PaginationVariables } from 'models';
import { PlaylistContentType } from 'models/Playlist';
import { Nullable } from 'utils/types';

const VIDEO_QUERY = gql`
  query GetVideosForPlaylist(
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $isPublished: Boolean
  ) {
    items: videos(
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      isPublished: $isPublished
    ) {
      nodes {
        id
        title
        description
        thumbnailUrl
      }
    }
  }
`;

const MOMENT_QUERY = gql`
  query GetMomentsForPlaylist(
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $isPublished: Boolean
  ) {
    items: moments(
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      isPublished: $isPublished
    ) {
      nodes {
        id
        title
        description
        thumbnailUrl
        video {
          id
        }
      }
    }
  }
`;

interface ResponseData {
  items: Nullable<{
    nodes: Nullable<
      {
        id: string;
        title: string;
        description: string;
        thumbnailUrl: Nullable<string>;
        video?: {
          id: string;
        };
      }[]
    >;
  }>;
}

interface Variables extends Partial<PaginationVariables> {
  search?: string;
  orderBy?: string;
  isPublished?: boolean;
}

interface UsePlaylistItemsToAddQueryOptions extends UseQueryOptions<ResponseData, Variables> {
  type: PlaylistContentType;
}

export function usePlaylistItemsToAdd(options: UsePlaylistItemsToAddQueryOptions) {
  const QUERY = options.type === PlaylistContentType.Moments ? MOMENT_QUERY : VIDEO_QUERY;
  const results = useQuery<ResponseData, Variables>(QUERY, options);

  return {
    ...results,
    items: results.data?.items?.nodes ?? [],
  };
}
