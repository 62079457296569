import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Info } from 'assets/icons/info-circle.svg';

export type Placement = TooltipProps['placement'];

interface InfoTooltipProps {
  content: TooltipProps['title'];
  placement?: Placement;
  options?: TooltipProps;
  style?: object;
  interactive?: boolean;
}

const DEFAULT_TOOLTIP_PLACEMENT: Placement = 'right';

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  content,
  placement = DEFAULT_TOOLTIP_PLACEMENT,
  options = {},
  style,
  interactive,
}) => {
  return (
    <Tooltip
      arrow
      title={content}
      placement={placement}
      {...options}
      style={style}
      interactive={interactive}
    >
      <Info />
    </Tooltip>
  );
};
