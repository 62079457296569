import React from 'react';
import { DeepMap } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useUpdateImageCTA } from 'hooks/mutation/useUpdateImageCTA';
import { useImageCTAQuery } from 'hooks/query/useImageCTAQuery';

import { CTAImagePageView } from './CTAImagePageView';
import { convertImageDataFromServer, convertImageDataToServer } from './helpers';
import { CreateImageCTAInput } from './types';

export const CTAImagePage: React.FC = () => {
  const { ctaId } = useParams<{ ctaId: string }>();
  const { data, loading } = useImageCTAQuery({ variables: { id: ctaId } });

  const [updateImageCta, { loading: isLoadingUpdate }] = useUpdateImageCTA();

  const onSubmit = async (
    formData: CreateImageCTAInput,
    dirtyFields: DeepMap<CreateImageCTAInput, true>,
  ) => {
    await updateImageCta({
      variables: {
        id: ctaId,
        image_cta: convertImageDataToServer(formData, dirtyFields),
      },
    });
  };

  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }
  if (data?.imageCtaById) {
    return (
      <MainLayout>
        <CTAImagePageView
          imageCTA={convertImageDataFromServer(data?.imageCtaById)}
          handleSave={onSubmit}
          isLoading={isLoadingUpdate}
        />
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
};
