import styled from 'styled-components';

import { materialTheme } from 'components/App/materialTheme';

export const MainColorsSelectionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: 100%;
`;

interface ColorLabelProps {
  selected?: boolean;
}

export const ColorLabel = styled.label<ColorLabelProps>`
  border-radius: 8px;
  border: 1px solid ${materialTheme.palette.background.default};
  cursor: pointer;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
`;

export const ColorInput = styled.input`
  height: 0;
  width: 0;
  opacity: 0;

  &:checked {
    + label {
      border: 1px solid ${materialTheme.palette.text.primary};
    }
  }
`;

interface ColorProps {
  color: string;
}

export const Color = styled.span<ColorProps>`
  background-color: ${({ color }) => color && color};
  border-radius: 5px;
  display: block;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
`;

export const InputWrapper = styled.div`
  height: 40px;
  position: relative;
  width: 40px;

  :not(:last-child) {
    margin-right: 10px;
  }
`;
