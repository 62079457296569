import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { QUERY as ORGANIZATION_QUERY } from 'hooks/query/useOrganizationQuery';
import { useMutation } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'ORGANIZATION_HAS_NO_PAID_SUBSCRIPTION';

const errorMap: Record<Errors, string> = {
  ORGANIZATION_HAS_NO_PAID_SUBSCRIPTION:
    'Your organization has no paid subscription or is already canceled',
};

const QUERY = gql`
  mutation CancelSubscription {
    cancelSubscription {
      subscription {
        id
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  cancelSubscription: Nullable<{
    subscription: Nullable<{
      id: string;
    }>;
    errors: ErrorType[];
  }>;
}

export const useCancelSubscription = () => {
  return useMutation<ResponseData>(QUERY, {
    pathToErrors: 'cancelSubscription.errors',
    errorMap,
    refetchQueries: [
      // refresh current subscription plan
      { query: ORGANIZATION_QUERY },
    ],
    awaitRefetchQueries: true,
  });
};
