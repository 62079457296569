import {
  OBJECT_MOMENT_MUTATION_ALLOWED_STATUSES,
  PUBLISH_ALLOWED_STATUSES,
  PUBLISHED_STATUSES,
  UNPUBLISH_ALLOWED_STATUSES,
  UPLOADING_STATUSES,
  VIDEO_METADATA_UPDATE_ALLOWED_STATUSES,
} from 'config/mutationRules';
import { AnnotationSource, VideoI, VideoStatus } from 'models';

import { capitalize } from './common';
import { Nullable } from './types';

export const isVideoInProcessingState = (status: VideoStatus) =>
  [VideoStatus.Uploading, VideoStatus.Uploaded, VideoStatus.Processing].includes(status);

export const isVideoInUploadState = (status: VideoStatus) => UPLOADING_STATUSES.includes(status);

export const isVideoPublished = (status: VideoStatus) => PUBLISHED_STATUSES.includes(status);

export const canVideoBePublished = (status: VideoStatus) =>
  PUBLISH_ALLOWED_STATUSES.includes(status);

export const canVideoBeUnpublished = (status: VideoStatus) =>
  UNPUBLISH_ALLOWED_STATUSES.includes(status);

type VideoMutationFields = Pick<VideoI, 'origin' | 'status'>;

export const canVideoMetadataBeMutated = (video: VideoMutationFields) =>
  VIDEO_METADATA_UPDATE_ALLOWED_STATUSES[video.origin].includes(video.status);

export const canVideoAnnotationBeMutated = (status: VideoStatus) =>
  OBJECT_MOMENT_MUTATION_ALLOWED_STATUSES.includes(status);

const POLL_REQUIRE_STATUSES: VideoStatus[] = [
  VideoStatus.Processing,
  VideoStatus.Uploading,
  VideoStatus.Uploaded,
  VideoStatus.Created,
];

export const isPollingRequired = ({ status }: Pick<VideoI, 'status'>) => {
  return POLL_REQUIRE_STATUSES.includes(status);
};

export const getVideosWithPolling = <TVideo extends Pick<VideoI, 'status'>>(
  videos?: Nullable<TVideo[]>,
) => {
  return videos?.filter(isPollingRequired) ?? [];
};

export function formatSource(source: AnnotationSource) {
  return capitalize(source);
}

const statusMap: Record<VideoStatus, string> = {
  [VideoStatus.Uploading]: 'UPLOADING',
  [VideoStatus.Uploaded]: 'UPLOADED',
  [VideoStatus.Processing]: 'PROCESSING',
  [VideoStatus.Created]: 'CREATED',
  [VideoStatus.Draft]: 'DRAFT',
  [VideoStatus.Failed]: 'FAILED',
  [VideoStatus.Published]: 'PUBLISHED',
  [VideoStatus.Scheduled]: 'SCHEDULED',
  [VideoStatus.Edited]: 'PUBLISHED*',
};

export const formatVideoStatus = (status: VideoStatus): string => statusMap[status];
