import { Button } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';

interface Props {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export const AddButton = ({ label, onClick, disabled }: Props) => (
  <Button
    onClick={onClick}
    startIcon={<Plus width="10px" height="10px" />}
    variant="text"
    color="primary"
    disabled={disabled}
  >
    {label}
  </Button>
);
