import { UPDATE_SOCIAL_MEDIA_PLATFORM } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { SocialMediaI, SocialMediaPlatformInput } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateSocialMediaPlatform: Nullable<{
    platform: Nullable<SocialMediaI>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  platform: SocialMediaPlatformInput;
}

interface UseUpdateSocialMediaPlatform extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateSocialMediaPlatform = (options: UseUpdateSocialMediaPlatform = {}) => {
  return useMutation<ResponseData, Variables>(UPDATE_SOCIAL_MEDIA_PLATFORM, {
    skipGlobalErrorHandling: true,
    pathToErrors: 'updateSocialMediaPlatform.errors',
    ...options,
  });
};
