import { D2C_TRANSCRIPTIONS_LIST } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import {
  D2CTranscription,
  D2CTranscriptionOrderBy,
  PageInfo,
  PaginationVariables,
  Subscription,
} from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  d2cTranscriptions: Nullable<{
    nodes: D2CTranscription[];
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
  me: Nullable<{
    id: string;
    organization: Nullable<{
      id: string;
      subscription: Nullable<Pick<Subscription, 'id' | 'limitsUsage'>>;
    }>;
  }>;
}

interface Variables extends PaginationVariables {
  orderBy?: D2CTranscriptionOrderBy;
}

interface UseD2CServiceUsageQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useD2CServiceUsageQuery = (options: UseD2CServiceUsageQueryOptions) => {
  return useQuery<ResponseData, Variables>(D2C_TRANSCRIPTIONS_LIST, options);
};
