import { UserRole } from 'models';

const userRoleMap: Record<UserRole, string> = {
  [UserRole.OrgOwner]: 'OWNER',
  [UserRole.Curator]: 'CURATOR',
  [UserRole.OrgAdmin]: 'ADMIN',
  [UserRole.VideoEditor]: 'EDITOR',
};

export function formatUserRole(role: UserRole) {
  return userRoleMap[role];
}
