import { Box, Checkbox, TableContainer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortEventWithTag,
} from 'react-sortable-hoc';

import { ReactComponent as DragIcon } from 'assets/icons/re-arrange.svg';
import { Link, Search } from 'components/common';
import ExpandableCell from 'components/common/Table/ExpandableCell';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import PaginationFooter from 'components/PaginationFooter';
import { PostsPerPage } from 'components/PaginationFooter/constants';
import { CarouselMomentI } from 'models';
import { generateMomentLink } from 'pages/urls';
import { reorderArray } from 'utils/common';

import { DraggableElementProps, EditCarouselMomentsProps, SortEvent } from '../types';
import { MomentsTableRow } from './styled';

import * as Styled from 'components/common/Table/Table.styles';

const EditCarouselSortableContainer = SortableContainer(
  ({ children }: { children: React.ReactNode }) => {
    return <tbody>{children}</tbody>;
  },
);

const EditCarouselSortableElement = SortableElement(
  ({ moment, canSelectMoments, isSelected, handleClickMoment, index }: DraggableElementProps) => {
    return (
      <MomentsTableRow key={index}>
        <Styled.CheckboxCell>
          <Checkbox
            checked={isSelected(moment.id)}
            onClick={() => handleClickMoment(moment.id)}
            color="primary"
            disabled={!canSelectMoments}
          />
        </Styled.CheckboxCell>
        <Styled.ThumbnailCell>
          <Styled.Thumbnail thumbnailUrl={moment.thumbnailUrl} />
        </Styled.ThumbnailCell>
        <ExpandableCell>
          <Link to={generateMomentLink(moment.video.id, moment.id)}>
            <Styled.MomentTitle>{moment.title}</Styled.MomentTitle>
            <br />
            <Styled.MomentSubtitle>{moment.video.title}</Styled.MomentSubtitle>
          </Link>
        </ExpandableCell>
        <Styled.DragCell>
          <DragIcon className="drag-icon" />
        </Styled.DragCell>
      </MomentsTableRow>
    );
  },
);

export const EditCarouselMoments: React.FC<EditCarouselMomentsProps> = ({
  momentsLoading,
  moments,
  setAllAvailableMoments,
  setInitialMomentsToPreview,
  canSelectMoments,
  handleClickMoment,
  selectedMoments,
  setCheckAllMoments,
  checkAllMoments,
  onSearch,
  searchText,
  dispatch,
  totalCount,
  pageInfo,
}) => {
  const isSelected = (id: string) => selectedMoments[id] || false;
  const [visibleMoments, setVisibleMoments] = useState<CarouselMomentI[]>(moments);
  useEffect(() => {
    setVisibleMoments(moments);
  }, [moments, setVisibleMoments]);

  const onSortEnd: SortEndHandler = (result) => {
    if (!searchText?.trim()) {
      const reorderedMoments = reorderArray(moments, result.oldIndex, result.newIndex);
      setAllAvailableMoments(reorderedMoments);
      setInitialMomentsToPreview(reorderedMoments);
    }
  };

  const shouldCancelStart = (event: SortEvent | SortEventWithTag): boolean => {
    const target = event.target as HTMLElement;
    return (
      target.parentElement?.tagName.toLowerCase() === 'a' ||
      target.tagName.toLowerCase() === 'input'
    );
  };

  return (
    <>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={!!momentsLoading} />
        <Box
          height="80px"
          display="flex"
          alignItems="center"
          p="0 20px"
          justifyContent="space-between"
          bgcolor="#fff"
        >
          <Styled.TableHeading>Moments</Styled.TableHeading>
          <Search placeholder="Search moments" onSearch={onSearch} />
        </Box>
        <Box bgcolor="#fff">
          <TableContainer>
            <Styled.Table>
              <Styled.TableHead variant="gray">
                <Styled.HeadRow>
                  <Styled.CheckboxCell>
                    <Checkbox
                      checked={checkAllMoments}
                      onClick={setCheckAllMoments}
                      color="primary"
                      disabled={!canSelectMoments}
                    />
                  </Styled.CheckboxCell>
                  <Styled.ThumbnailCell />
                  <Styled.TitleCell>Title</Styled.TitleCell>
                  <Styled.DragCell />
                </Styled.HeadRow>
              </Styled.TableHead>
              <EditCarouselSortableContainer
                onSortEnd={onSortEnd}
                shouldCancelStart={shouldCancelStart}
              >
                {visibleMoments.map((moment, index) => (
                  <EditCarouselSortableElement
                    key={moment.id}
                    moment={moment}
                    canSelectMoments={canSelectMoments}
                    handleClickMoment={handleClickMoment}
                    isSelected={isSelected}
                    index={index}
                  />
                ))}
              </EditCarouselSortableContainer>
              <PaginationFooter
                totalCount={totalCount}
                visibleCount={visibleMoments.length}
                pageInfo={pageInfo}
                dispatch={dispatch}
                tableType={PostsPerPage.WidgetMoments}
              />
            </Styled.Table>
          </TableContainer>
        </Box>
      </Styled.TableWrapper>
    </>
  );
};
