import { Location } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface CustomPromptResult {
  showPrompt: boolean;
  handleBlockedNavigation: (nextLocation: Location) => boolean;
  handleConfirmNavigation: () => void;
  closeModal: () => void;
}

const useCustomPrompt = (hasUnsavedChanges: boolean): CustomPromptResult => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [targetPath, setTargetPath] = useState('');
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const showModal = useCallback((location: Location) => {
    setTargetPath(location.pathname);
    setShowPrompt(true);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location) => {
      if (!confirmedNavigation && hasUnsavedChanges) {
        showModal(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, hasUnsavedChanges, showModal],
  );

  const closeModal = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleConfirmNavigation = useCallback(() => {
    closeModal();
    if (targetPath) {
      setConfirmedNavigation(true);
    }
  }, [closeModal, targetPath]);

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(targetPath);
    }
  }, [history, confirmedNavigation, targetPath]);

  return {
    showPrompt,
    handleBlockedNavigation,
    handleConfirmNavigation,
    closeModal,
  };
};

export default useCustomPrompt;
