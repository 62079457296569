import React from 'react';

import { TEMP_Microsite } from 'models';

interface ContextValue {
  initialMicrositeData: TEMP_Microsite;
  logoUrl?: string;
  title?: string;
  setLogoUrl: (logoBase64: string | undefined) => void;
  faviconUrl?: string;
  setFaviconUrl: (faviconBase64: string | undefined) => void;
  color: string;
  setColor: (colorHex: string) => void;
  momentsLocationTop: boolean;
}

const EditorContext = React.createContext<ContextValue | undefined>(undefined);

interface ProviderProps {
  micrositeData: TEMP_Microsite;
}

export const EditorContextProvider: React.FC<ProviderProps> = ({
  children,
  micrositeData: initialMicrositeData,
}) => {
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(
    initialMicrositeData.logoUrl || undefined,
  );
  const [faviconUrl, setFaviconUrl] = React.useState<string | undefined>(
    initialMicrositeData.faviconUrl || undefined,
  );
  const [color, setColor] = React.useState(initialMicrositeData.mainColor);
  const [title, setTitle] = React.useState<string | undefined>(initialMicrositeData.title);
  const [momentsLocationTop, setMomentsLocationTop] = React.useState<boolean>(
    initialMicrositeData.momentsLocationTop,
  );
  React.useEffect(() => {
    // After update in response image urls are having new security tokens
    setLogoUrl(initialMicrositeData.logoUrl || undefined);
  }, [initialMicrositeData.logoUrl]);

  React.useEffect(() => {
    setMomentsLocationTop(initialMicrositeData.momentsLocationTop);
  }, [initialMicrositeData.momentsLocationTop]);

  React.useEffect(() => {
    // After update in response image urls are having new security tokens
    setFaviconUrl(initialMicrositeData.faviconUrl || undefined);
  }, [initialMicrositeData.faviconUrl]);

  React.useEffect(() => {
    setTitle(initialMicrositeData.title || undefined);
  }, [initialMicrositeData.title]);

  return (
    <EditorContext.Provider
      value={{
        title,
        initialMicrositeData,
        logoUrl,
        setLogoUrl,
        faviconUrl,
        setFaviconUrl,
        color,
        setColor,
        momentsLocationTop,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export function useEditorContext() {
  const value = React.useContext(EditorContext);
  if (!value) {
    throw new Error(`useEditorContext needs to be called within EditorContext tree.`);
  }
  return value;
}
