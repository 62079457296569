import googleAnalytics from '@analytics/google-analytics';
import Analytics from 'analytics';

import { env } from 'config/env';
import { history } from 'utils/history';

const plugins = [
  env.SEEEN_APP_GOOGLE_ANALYTICS_ID
    ? googleAnalytics({
        trackingId: env.SEEEN_APP_GOOGLE_ANALYTICS_ID,
      })
    : null,
].filter(Boolean);

export const analytics = Analytics({
  debug: env.SEEEN_APP_ENV === 'local',
  plugins,
});

export function setupAnalyticsPageTracking() {
  // Track initial page load
  analytics.page({
    path: window.location.pathname,
  });

  // Track page changes
  return history.listen((location) => {
    analytics.page({
      path: location.pathname,
    });
  });
}
