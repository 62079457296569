import React from 'react';

import { FlowStep, Navigation } from './Navigation';

import * as Styled from './MicrositeLayout.styles';

interface MicrositeLayoutProps {
  currentStep: FlowStep;
  footer: React.ReactNode;
  children: React.ReactNode;
}

export const MicrositeLayout: React.FC<MicrositeLayoutProps> = ({
  children,
  currentStep,
  footer,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.Title>Microsite</Styled.Title>
      <Styled.Container>
        <Styled.Bar>
          <Navigation currentStep={currentStep} />
        </Styled.Bar>
        <Styled.ContentContainer>
          <Styled.Content>{children}</Styled.Content>
        </Styled.ContentContainer>
        {footer ? <Styled.Bar>{footer}</Styled.Bar> : null}
      </Styled.Container>
    </Styled.Wrapper>
  );
};
