import { DELETE_OBJECT_MUTATION, QUERY_VIDEO_STATUS } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  deleteObjectAnnotation: Nullable<{
    deletedObjectAnnotationId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

type MutationOptions = Pick<UseMutationOptions<ResponseData, Variables>, 'onCompleted'>;

interface UseDeleteObjectOptions extends MutationOptions {
  videoId?: string;
}

export const useDeleteObject = ({ onCompleted, videoId }: UseDeleteObjectOptions = {}) => {
  return useMutation<ResponseData, Variables>(DELETE_OBJECT_MUTATION, {
    pathToErrors: 'deleteObjectAnnotation.errors',
    onCompleted,
    refetchQueries: () => {
      return videoId
        ? [
            // update video status
            { query: QUERY_VIDEO_STATUS, variables: { id: videoId } },
          ]
        : [];
    },
  });
};
