import { Box, TooltipProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ReactComponent as Remove } from 'assets/icons/x.svg';
import { FormLabel } from 'components/common/FormLabel';
import { InfoTooltip, Placement } from 'components/common/InfoTooltip';
import { Permission } from 'config/permissions';
import { useDeleteOrganizationLogo } from 'hooks/mutation/useDeleteOrganizationLogo';
import { useUpdateOrganization } from 'hooks/mutation/useUpdateOrganization';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';

import { UploadDropBox } from '../UploadDropBox';

import * as Styled from './BrandingUploadInput.styles';

export interface UploadInputProps {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: string;
  onChange(value: string): void;
  onFileValidation?(value: File): Promise<boolean>;
  placeholder?: string;
  validationMessage?: string;
  errorMessage?: any;
  error?: boolean;
  mb?: string;
  info?: string | React.ReactNode;
  infoPlacement?: Placement;
  infoOptions?: TooltipProps;
  tooltipStyle?: React.CSSProperties;
}

export const BrandingUploadInput = React.forwardRef<HTMLInputElement, UploadInputProps>(function (
  {
    label,
    name,
    value,
    validationMessage,
    errorMessage,
    placeholder,
    disabled,
    onChange,
    onFileValidation,
    error,
    mb = '32px',
    info,
    infoPlacement,
    infoOptions,
    tooltipStyle,
  },
  ref,
) {
  const [previewBg, setPreviewBg] = useState(value);
  const { data } = useOrganizationQuery();
  const organization = data?.me?.organization;
  const [updateOrganization] = useUpdateOrganization();
  const [deleteOrganizationLogo] = useDeleteOrganizationLogo();
  const { hasPermissions } = useUserPermissions();
  const canRemoveLogo = hasPermissions([Permission.DeleteOrganizationLogo]);

  // @ts-ignore
  const { id } = organization;

  const handleUploadLogo = async (base64: string) => {
    await updateOrganization({
      variables: {
        id,
        organization: {
          // @ts-ignore
          logo: base64,
        },
      },
    });
  };
  const handleRemoveOrgLogo = async () => {
    await deleteOrganizationLogo({
      variables: {
        id,
      },
    });
  };

  const handleFileProcessed = (base64: string) => {
    onChange(base64);
    handleUploadLogo(base64);
    setPreviewBg(base64);
  };

  const handleRemoveLogo = () => {
    handleRemoveOrgLogo();
    setPreviewBg('');
  };

  useEffect(() => {
    if (organization?.logoUrl) {
      setPreviewBg(organization?.logoUrl);
    }
  }, [organization]);

  return (
    <Box mb={mb} position="relative" width="100%">
      {label && (
        <FormLabel htmlFor={name}>
          {label}
          {info ? (
            <Box style={tooltipStyle}>
              <InfoTooltip content={info} placement={infoPlacement} options={infoOptions} />
            </Box>
          ) : null}
        </FormLabel>
      )}
      <Box display="flex">
        <Box display="flex">
          {previewBg && (
            <Styled.ImageWrapper>
              <Styled.Image url={previewBg} />

              {canRemoveLogo && (
                <Styled.RemoveThumbnailButton onClick={handleRemoveLogo}>
                  <Remove />
                </Styled.RemoveThumbnailButton>
              )}
            </Styled.ImageWrapper>
          )}
          {!previewBg && (
            <Styled.UploadWrapper>
              <UploadDropBox
                id="logo-upload"
                disabled={disabled}
                uploadLabel={placeholder}
                onFileProcessed={handleFileProcessed}
                validateFile={onFileValidation}
                uploadBoxStyle={{ height: '85px' }}
              />
            </Styled.UploadWrapper>
          )}
        </Box>
        <Box maxWidth="170px">
          {(errorMessage || validationMessage) && (
            <Styled.WarningMessage
              error={error}
              style={{ marginTop: 0, marginLeft: '1rem', lineHeight: '143%' }}
            >
              {errorMessage || validationMessage}
            </Styled.WarningMessage>
          )}
        </Box>
      </Box>
    </Box>
  );
});
