import { Tooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Compass } from 'assets/icons/compass.svg';
import YouTubeIcon from 'assets/images/youtube-icon-color-48.png';
import { DashboardVideoNode, PublicationTypename, YouTubePublication } from 'models';

interface VideoPublicationsProps {
  video: DashboardVideoNode;
}

export const VideoPublications: React.FC<VideoPublicationsProps> = ({ video }) => {
  const youtubeStudioUrl = video.externalPublications?.find(
    (publication): publication is YouTubePublication =>
      publication.__typename === PublicationTypename.YouTube,
  )?.studioUrl;

  const micrositeId = video.publications?.find(
    (publication) => publication.__typename === PublicationTypename.Self,
  )?.id;

  return (
    <List>
      {youtubeStudioUrl ? (
        <Item>
          <Tooltip title="Go to YouTube studio" arrow placement="top">
            <IconLink href={youtubeStudioUrl} target="_blank" rel="noreferrer noopener">
              <IconImage src={YouTubeIcon} alt="YouTube" />
            </IconLink>
          </Tooltip>
        </Item>
      ) : null}
      {micrositeId ? (
        <Item>
          <Tooltip title={'Microsite'} arrow placement="top">
            <Compass />
          </Tooltip>
        </Item>
      ) : null}
    </List>
  );
};

const List = styled.ul`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 6px;
  }
`;

const Item = styled.li`
  display: inline-block;
`;

const IconLink = styled.a`
  display: inline-grid;
  place-items: center;
  width: 24px;
  height: 24px;
`;

const IconImage = styled.img`
  height: auto;
  width: 100%;
`;
