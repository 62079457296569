import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy from '@uppy/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { pushError } from 'context/globalStream';
import { UppyContext, UppyUpdateStream } from 'context/uppy';
import {
  useCancelLocalVideoUpload,
  useConfirmLocalVideoUpload,
  useDeleteVideo,
  useNewLocalVideoUploadPart,
  useUploadLocalVideo,
} from 'hooks/mutation';
import useSubject from 'hooks/useSubject';
import { ProcessingPipelineType } from 'models';
import { disableLeaveWarning, enableLeaveWarning } from 'utils/navigation';

import { FileMap, VideoState } from './models';

const initialUppyStreamValue: UppyUpdateStream = {
  type: 'progress',
  value: {
    fileId: '',
    progress: 0,
  },
};

const uppyConfig: Uppy.UppyOptions = {
  allowMultipleUploads: true,
  debug: false,
  restrictions: {
    maxFileSize: null,
    maxNumberOfFiles: 1,
    allowedFileTypes: [
      // safari doesn't work with just `video/*`
      'video/mp4',
      'video/x-m4v',
      'video/*',
    ],
  },
};

export const UppyProvider: React.FC = ({ children }) => {
  const { pipelineType, setPipelineType, getCurrentPipelineType } = usePipelineType();

  const [uploadLocalVideo] = useUploadLocalVideo();
  const [newVideoUploadPart] = useNewLocalVideoUploadPart();
  const [confirmLocalVideoUpload] = useConfirmLocalVideoUpload();
  const [cancelLocalVideoUpload] = useCancelLocalVideoUpload();
  const [deleteVideo] = useDeleteVideo({
    refetchQueries: ['GetVideos'],
  });
  const idMapRef = useRef<FileMap>({});
  const updateStream = useSubject<UppyUpdateStream>(initialUppyStreamValue);

  const appendUpload = (fileId: string, videoState: VideoState) => {
    idMapRef.current = {
      ...idMapRef.current,
      [fileId]: videoState,
    };
  };

  const getUpload = (fileId: string) => {
    return idMapRef.current[fileId];
  };

  const spliceUpload = (fileId: string) => {
    const { [fileId]: currentFile, ...newRef } = idMapRef.current;
    idMapRef.current = newRef;
    return currentFile;
  };

  const [uppy] = useState(() => {
    const uppy = Uppy<Uppy.StrictTypes>(uppyConfig).use(AwsS3Multipart, {
      createMultipartUpload: async ({ id, meta: { name } }) => {
        const { data } = await uploadLocalVideo({
          variables: {
            filename: name,
            multipart: true,
            pipelineType: getCurrentPipelineType(),
          },
        });
        const response = data?.uploadLocalVideo;

        if (!response || !response.video || !response.videoUploadUrl) {
          console.info('[AwsS3Multipart] Invalid response for uploadLocalVideo', response);
          throw new Error('File upload failed on createMultipartUpload step.');
        }

        const videoId = response.video.id;
        const uploadUrl = new URL(response.videoUploadUrl);
        const s3Key = uploadUrl.pathname.slice(1);
        const uploadId = uploadUrl.searchParams.get('uploadId');

        if (!s3Key) {
          deleteVideo({ variables: { id: videoId } });
          throw new Error(`File upload failed. Missing s3Key for video id ${videoId}`);
        }

        if (!uploadId) {
          deleteVideo({ variables: { id: videoId } });
          throw new Error(`File upload failed. Missing uploadId for video id ${videoId}`);
        }

        appendUpload(id, { videoId, s3Key });

        updateStream.next({
          type: 'action',
          value: 'upload',
        });

        return {
          key: s3Key,
          uploadId,
        };
      },
      prepareUploadPart: async ({ id }, { number: partNumber }) => {
        const currentFile = getUpload(id);
        if (!currentFile) {
          throw new Error(`File part upload failed. Missing file of id "${id}"`);
        }

        const { data } = await newVideoUploadPart({
          variables: {
            videoId: currentFile.videoId,
            partNumber,
          },
        });
        const response = data?.newLocalVideoUploadPart;

        if (!response || !response.videoUploadUrl) {
          console.info('[AwsS3Multipart] Invalid response for newLocalVideoUploadPart', response);
          throw new Error(`File upload failed on prepareUploadPart step. Missing videoUploadUrl`);
        }

        return {
          url: response.videoUploadUrl,
        };
      },
      // Override default hook to stop using built-in auth companion.
      // Upload confirmation is done via basic uppy events.
      completeMultipartUpload: () => {
        return {};
      },
      // Override default hook to stop using built-in auth companion.
      // Upload aborting is done via basic uppy events.
      abortMultipartUpload: () => {},
    });

    uppy.on('upload-error', async (file) => {
      pushError({ message: 'File upload has failed. Try again.' });
      const currentFile = spliceUpload(file.id);
      if (currentFile) {
        await cancelLocalVideoUpload({ variables: currentFile });
      }
      uppy.reset();
      updateStream.next({
        type: 'action',
        value: 'canceled',
      });
    });

    uppy.on('file-added', () => {
      uppy.upload();
    });

    uppy.on('upload-success', async (file) => {
      const currentFile = spliceUpload(file.id);
      if (currentFile) {
        await confirmLocalVideoUpload({ variables: currentFile });
      }
      uppy.reset();
      updateStream.next({
        type: 'action',
        value: 'completed',
      });
    });

    uppy.on('upload-progress', (file, progress) => {
      const currentFile = idMapRef.current[file.id];
      if (!currentFile) {
        return;
      }
      updateStream.next({
        type: 'progress',
        value: {
          fileId: currentFile.videoId,
          progress: (progress.bytesUploaded / progress.bytesTotal) * 100,
        },
      });
    });

    uppy.on('upload', () => {
      enableLeaveWarning();
    });

    uppy.on('complete', () => {
      disableLeaveWarning();
    });

    uppy.on('file-removed', ({ id }) => {
      disableLeaveWarning();
      spliceUpload(id);
    });

    uppy.on('cancel-all', () => {
      disableLeaveWarning();
      idMapRef.current = {};
    });

    return uppy;
  });

  useEffect(() => {
    return () => {
      uppy.close();
      disableLeaveWarning();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelVideoUpload = useCallback(
    (videoId: string) => {
      const [fileId] =
        Object.entries(idMapRef.current).find(([, videoState]) => {
          return videoState?.videoId === videoId;
        }) || [];
      if (fileId) {
        uppy.removeFile(fileId);
      }
    },
    [uppy],
  );

  return (
    <UppyContext.Provider
      value={{
        uppy,
        uploadStream: updateStream,
        refMap: idMapRef,
        cancelVideoUpload,
        pipelineType,
        setPipelineType,
      }}
    >
      {children}
    </UppyContext.Provider>
  );
};

function usePipelineType() {
  const [pipelineType, setPipelineType] = useState(ProcessingPipelineType.Default);

  // Because the createMultipartUpload is created once (inside of useState initializer),
  // the lexical scope of the function is limited to values that available at the time of creation,
  // even though the pipelineType can change at any time during the component lifecycle.
  // It means that the pipelineType used for uploading videos would be always equal to what
  // was set during (before) the first render.
  //
  // To work around this problem, we are using an object (ref) that won't change after first render,
  // as opposed to the primitive value (state which returns a different reference on every change).
  //
  // We also can't get rid of the useState here, since the value is also displayed in
  // the dropdown selection in the UI and it needs to update on every change.
  // - State change === render
  // - Ref change !== render
  const pipelineTypeForUpload = useRef(ProcessingPipelineType.Default);

  useEffect(() => {
    pipelineTypeForUpload.current = pipelineType;
  }, [pipelineType]);

  const getCurrentPipelineType = () => {
    return pipelineTypeForUpload.current;
  };

  return {
    pipelineType,
    setPipelineType,
    getCurrentPipelineType,
  };
}
