import { ButtonBase } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

import { thumbnailItem } from 'components/ThumbnailSelect/ThumbnailSelect.styles';

interface UploadBoxProps {
  $inline?: boolean;
}

export const UploadBox = styled(ButtonBase)<UploadBoxProps>`
  && {
    ${thumbnailItem};
    height: 64px;
    min-width: 85px;
    width: 100%;
    align-items: center;
    background: transparent;
    border: 2px dashed ${materialTheme.palette.grey[200]};
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    margin: 0;

    &:disabled {
      cursor: default;
    }

    svg {
      color: ${materialTheme.palette.primary.main};
      margin-bottom: 4px;
    }

    ${({ $inline }) =>
      $inline &&
      css`
        flex-direction: row;
        padding: 0 10px;
        line-height: 1;

        svg {
          margin-right: 10px;
          margin-bottom: 0;
        }
      `};
  }
`;
