import { Checkbox, CheckboxProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { Table } from 'components/Table';

const Cell = styled(Table.Cell)`
  padding-right: 5px;

  &:first-child {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 10px;
  }

  @media (min-width: 1300px) {
    padding-right: 10px;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }
`;

export const CheckboxCell: React.FC<CheckboxProps> = ({ children, ...props }) => {
  return (
    <Cell>
      <Checkbox {...props} />
      {children}
    </Cell>
  );
};

export const CheckboxHeadCell: React.FC<CheckboxProps> = (props) => {
  return (
    <Cell as="th">
      <Checkbox {...props} />
    </Cell>
  );
};
