import styled from 'styled-components/macro';

export const IconsWrapper = styled.div`
  display: flex;
  width: 240px;
  flex-direction: column;
  padding: 10px 40px 20px 40px;
  text-align: center;
`;

export const Header = styled.h4`
  gap: 4px;
  padding-top: 30px;
  padding-left: 30px;
  font-weight: 500;
  margin-right: 20px;
`;
