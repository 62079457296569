import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import { useCreateImageCTA } from 'hooks/mutation/useCreateImageCTA';
import { generateCTAImageEditLink } from 'pages/urls';

import { CTAImagePageView } from './CTAImagePageView';
import { CreateImageCTAInput } from './types';

export const NewCTAImage: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [isCreated, setIsCreated] = useState<boolean>(false);

  const [createImageCta, { loading: isLoading }] = useCreateImageCTA({
    onCompleted: (data) => {
      setIsCreated(true);
      const id = data.createImageCta?.imageCta?.id;
      if (id) {
        history.replace(generateCTAImageEditLink(id), state);
      }
    },
  });

  const onSubmit = async (image_cta: CreateImageCTAInput) => {
    await createImageCta({
      variables: {
        image_cta: { ...image_cta, url: image_cta.url || null },
      },
    });
  };

  return (
    <MainLayout>
      <CTAImagePageView handleSave={onSubmit} isLoading={isLoading} isCreated={isCreated} />
    </MainLayout>
  );
};
