import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, OrganizationI } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation DeleteOrganizationLogo($id: ID!) {
    deleteOrganizationLogo(id: $id) {
      organization {
        id
        name
        isMonetizationEnabled
        logoUrl
        owner {
          id
        }
        subscription {
          cost
          endsAt
          id
          plan {
            name
          }
          status
        }
        socialMedia {
          id
          label
          accountUrl
          platformName
        }
        monetizationSettings {
          id
          active
          created
          default
          monetizationUrl
          name
        }
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  deleteOrganizationLogo: Nullable<{
    organization: OrganizationI;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteOrganizationLogo extends UseMutationOptions<ResponseData, Variables> {}

export const useDeleteOrganizationLogo = (options: UseDeleteOrganizationLogo = {}) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'deleteOrganizationLogo.errors',
    refetchQueries: ['GetOrganization'],
    ...options,
  });
};
