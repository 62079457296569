import styled, { css } from 'styled-components';

import { materialTheme } from 'components/App/materialTheme';

export const MomentForm = styled.form`
  height: 100%;
  width: 100%;
`;

interface MomentTypes {
  newMomentStyle?: boolean;
  isLastSelected: boolean;
}

export const Moment = styled.li<MomentTypes>`
  position: relative;
  padding: 20px;
  border: 1px solid ${({ newMomentStyle }) => (newMomentStyle ? 'rgba(0, 0, 0, .5)' : '#e5e5e5')};
  box-shadow: ${({ newMomentStyle }) => (newMomentStyle ? '0 0 7px rgba(0, 0, 0, .2)' : '')};
  border-radius: 10px;
  min-height: 163px;
  transition: border 0.25s ease-out, box-shadow 0.25s ease-out;
  margin-bottom: ${({ isLastSelected }) => (isLastSelected ? '130px' : '20px')};

  &:last-of-type {
    margin: 0;
  }
`;

export const MomentHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const MomentCheckbox = styled.div`
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: -5px;
`;

export const MomentTools = styled.div`
  display: flex;
  margin-top: -10px;
`;

interface MomentInputsTypes {
  createMomentFields: boolean;
}

export const MomentInputs = styled.div<MomentInputsTypes>`
  display: flex;

  & > div {
    margin-right: 10px;

    &:last-of-type {
      margin: 0;
    }
  }

  ${({ createMomentFields }) =>
    createMomentFields
      ? css`
          margin-bottom: 30px;
        `
      : ''}
`;

interface BulkWrapperTypes {
  bulkPosition: number;
}

export const BulkWrapper = styled.div<BulkWrapperTypes>`
  padding: 20px;
  background-color: #f6f7fa;
  margin-top: 20px;
  border-radius: 15px;

  ${({ bulkPosition }) => css`
    position: absolute;
    top: ${bulkPosition}px;
    left: 0;
    width: 100%;
  `}
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: ${materialTheme.palette.error.main};
  margin-top: 10px;
`;

export const MomentTitle = styled.h2`
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 15px;
  padding-top: 3px;
  margin-right: auto;
`;

export const MomentThumbnail = styled.img`
  border-radius: 10px;
  width: 80px;
  height: 52px;
`;

export const MomentPauseIcon = styled.span`
  width: 16px;
  height: 16px;
  display: inline-flex;

  svg {
    width: 100%;
    height: 100%;
  }
`;
