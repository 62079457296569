import { Box } from '@material-ui/core';
import gql from 'graphql-tag';
import React from 'react';

import Navbar from 'components/Navbar/Navbar';
import { SnackbarController } from 'components/Snackbar';
import { useQuery } from 'hooks/useQuery';

import { ContentBox, ContentWrapper } from './MainLayoutElements';

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MAIN_NAVBAR_QUERY = gql`
  query IsNavbarTangled {
    isMainBarTangled @client
  }
`;

const MainLayout = ({ children }: MainLayoutProps) => {
  const { data } = useQuery(MAIN_NAVBAR_QUERY);
  return (
    <Box display="flex" flexDirection="row" height="100%" width="100%">
      <Navbar miniNavbar={data.isMainBarTangled} />
      <ContentBox>
        <SnackbarController>
          <ContentWrapper>{children}</ContentWrapper>
        </SnackbarController>
      </ContentBox>
    </Box>
  );
};

export default MainLayout;
