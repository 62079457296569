import styled, { css } from 'styled-components/macro';

import { zIndex } from 'utils/styling';

const LOADED_FRACTION_COLOR = 'rgba(249, 249, 249, 0.2)';
const HOVER_COLOR = 'rgb(150, 150, 150)';
const MARKER_BOX_COLOR = 'rgba(126, 4, 46, 0.50)';
const PLAYED_FRACTION_COLOR = '#eb3776';
const BORDER_COLOR = '#0f131d';

export const VideoEditorCard = styled.div`
  > *:first-child {
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  > *:last-child {
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const FullscreenWrapper = styled.div`
  flex-flow: column nowrap;
  display: flex;
`;

export const FullscreenPlayerWrapper = styled.div`
  align-items: center;
  background-color: #000;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: 0;
`;

export const PlayerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 350px;
  overflow: hidden;

  /* remove implicit "padding" from .react-player */
  font-size: 0;
`;

export const VideoControlsWrapper = styled.div`
  flex-shrink: 0;
  margin-top: auto;
  z-index: ${zIndex.player + 22};
`;

interface ProgressProps {
  scale: number;
}

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #333333;
  position: relative;
  cursor: pointer;
  z-index: ${zIndex.player + 20};
`;

export const ProgressBarList = styled.div`
  overflow: hidden;
  width: 100%;
  height: 8px;
  background-color: #333333;
  position: relative;
  cursor: pointer;
  z-index: ${zIndex.player + 20};
`;

const progressBar = css`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
`;

export const LoadingProgressBar = styled.div.attrs<ProgressProps>((props) => ({
  style: {
    transform: `scaleX(${props.scale})`,
  },
}))<ProgressProps>`
  ${progressBar};
  background-color: ${LOADED_FRACTION_COLOR};
  z-index: ${zIndex.player + 10};
`;

export const PlayedProgressBar = styled.div.attrs<ProgressProps>((props) => ({
  style: {
    transform: `scaleX(${props.scale})`,
  },
}))<ProgressProps>`
  ${progressBar};
  background-color: ${PLAYED_FRACTION_COLOR};
  z-index: ${zIndex.player + 12};
`;

export const HoverProgressBar = styled.div.attrs<ProgressProps & { show: boolean }>((props) => ({
  style: {
    transform: `scaleX(${props.scale})`,
    opacity: `${props.show ? 1 : 0}`,
  },
}))<ProgressProps & { show: boolean }>`
  ${progressBar};
  background-color: ${HOVER_COLOR};
  z-index: ${zIndex.player + 11};
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
`;

interface MarkerBoxProps {
  leftPosition: number;
}

export const MarkerBox = styled.div.attrs<MarkerBoxProps>((props) => ({
  style: {
    left: props.leftPosition,
  },
}))<MarkerBoxProps>`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 14px;
  z-index: ${zIndex.player + 30};
  background-color: ${MARKER_BOX_COLOR};
  border-left: 2px solid ${BORDER_COLOR};
  border-right: 2px solid ${BORDER_COLOR};
  transform: translateX(-50%) scaleX(0.5);
  transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1);

  &:hover {
    transform: translateX(-50%) scaleX(1);
  }
`;
