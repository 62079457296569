import React from 'react';

import { TermsOfUse } from './TermsOfUse';
import { TermsOfUseD2CText } from './TermsOfUseD2CText';

export const D2CTermsOfUsePage: React.VFC = () => (
  <TermsOfUse>
    <TermsOfUseD2CText />
  </TermsOfUse>
);
