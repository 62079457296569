import styled from 'styled-components/macro';

import { pxOrString } from 'utils/styling';

export const MARKER_WIDTH = 2;

interface WidthProps {
  width: number;
}

export const Mark = styled.div.attrs<WidthProps>(({ width }) => ({
  style: {
    flexBasis: pxOrString(width),
  },
}))<WidthProps>`
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
`;

export const LineText = styled.div`
  position: absolute;
  top: -25px;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  transform: translateX(-50%);
`;

interface OverflowedTextProps {
  left: number;
}

export const OverflowedText = styled(LineText).attrs<OverflowedTextProps>(({ left }) => ({
  style: {
    left: left,
  },
}))<OverflowedTextProps>`
  top: 2px;
`;

export const TallLine = styled.div`
  position: relative;
  height: 5px;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    border-radius: 24px;
    background-color: #fff;
  }
`;

export const Line = styled.div`
  position: relative;
  height: 3px;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    border-radius: 50%;
    background-color: #7a7c87;
  }
`;

export const Break = styled.div.attrs<WidthProps>(({ width }) => ({
  style: {
    width: pxOrString(width),
  },
}))<WidthProps>`
  flex-shrink: 0;
`;

export const EndBreak = styled.div`
  flex: 1;
`;
