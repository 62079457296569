import React, { useReducer } from 'react';

import { Spinner } from 'components/common';
import { useSearch } from 'components/common/Search/Search';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { paginationReducer } from 'context/pagination';
import { useObjectsQuery } from 'hooks/query';
import { stripTypename } from 'hooks/useQuery';
import { VideoDetailsNode } from 'models';

import ObjectsTableView from './ObjectsTableView';

import { TableSpinnerBox } from 'components/common/Table/Table.styles';

interface OwnProps {
  video: VideoDetailsNode;
}

const ObjectsTable = ({ video }: OwnProps) => {
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.VideoObjects),
  );
  const [searchText, setSearchText] = useSearch('');

  const { loading, data } = useObjectsQuery({
    variables: {
      ...pagination,
      videoId: video.id,
      search: searchText,
      orderBy: 'startTimestamp,endTimestamp',
    },
  });

  // initial loading
  if (loading && !data?.objects?.nodes) {
    return (
      <TableSpinnerBox>
        <Spinner />
      </TableSpinnerBox>
    );
  }

  if (data?.objects?.nodes) {
    const { nodes, totalCount, pageInfo } = data.objects;
    const objects = stripTypename(nodes);
    return (
      <ObjectsTableView
        objectsLoading={loading} // subsequent loading
        objects={objects}
        totalCount={totalCount}
        video={video}
        pageInfo={pageInfo}
        dispatch={dispatch}
        onSearch={setSearchText}
      />
    );
  }

  return null;
};

export default ObjectsTable;
