import { useApolloClient } from '@apollo/react-hooks';
import { Box, Button } from '@material-ui/core';
import { RefetchQueryDescription } from 'apollo-client/core/watchQueryOptions';
import { innerJoin } from 'ramda';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from 'components/common';
import BackButton from 'components/common/BackButton';
import { Stack } from 'components/common/Wrapper/Layout';
import MainLayout from 'components/layouts/MainLayout';
import { publishVideo, PublishVideoResponseData } from 'hooks/mutation';
import { useVideoQuery } from 'hooks/query';
import { useSafeState } from 'hooks/useSafeState';
import { PublishingTarget, PublishingTargetType, VideoDetailsNode } from 'models';
import { generateVideoLink } from 'pages/urls';
import { changeDateToUTC } from 'utils/date';
import { canVideoBePublished } from 'utils/videos';

import { usePublishingTargetHandlers } from './publishingTargets';
import {
  HandlePollResponse,
  HandlePublishResponse,
  PublishStatusWithMeta,
} from './PublishVideo.utils';
import { SelectTargets } from './SelectTargets';
import {
  useYouTubeOptionsContext,
  YouTubeOptionsContextProvider,
} from './targets/YouTube/YouTubeOptionsContext';
import { VideoPublished } from './VideoPublished';

import * as Styled from './PublishVideo.styles';

interface PublishVideoProps {
  isInModal?: boolean;
  onClose?: () => void;
  handlePublished?: () => void;
  videoId?: string;
  refetchQueries?: RefetchQueryDescription;
}

function PublishVideo({
  isInModal = false,
  onClose,
  videoId: propsId,
  handlePublished,
  refetchQueries,
}: PublishVideoProps) {
  const { videoId: paramsId } = useParams<{ videoId: string }>();

  const videoId = propsId || paramsId;

  const { data, loading: isVideoLoading } = useVideoQuery({
    variables: { id: videoId },
    fetchPolicy: 'cache-first',
    refetchOnVariablesChange: false,
  });

  const video = data?.videoById;

  const Wrapper = isInModal ? React.Fragment : MainLayout;

  if (isVideoLoading) {
    return (
      <Wrapper>
        <Styled.PageWrapper isInModal={isInModal}>
          <Spinner />
        </Styled.PageWrapper>
      </Wrapper>
    );
  }

  if (!video) {
    return (
      <Wrapper>
        <Styled.PageWrapper isInModal={isInModal}>
          <Styled.Subtitle>Fetching video metadata failed</Styled.Subtitle>
          {onClose && (
            <Styled.ActionWrapper>
              <Button onClick={onClose} variant="outlined" color="primary">
                Close
              </Button>
            </Styled.ActionWrapper>
          )}
        </Styled.PageWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {!isInModal && (
        <Box m={3}>
          <BackButton label="Back to video editor" link={generateVideoLink(videoId)} />
        </Box>
      )}
      <Styled.PageWrapper isInModal={isInModal}>
        <YouTubeOptionsContextProvider>
          <PublishVideoBody
            video={video}
            onClose={onClose}
            handlePublished={handlePublished}
            refetchQueries={refetchQueries}
          />
        </YouTubeOptionsContextProvider>
      </Styled.PageWrapper>
    </Wrapper>
  );
}

interface PublishVideoBodyProps {
  video: VideoDetailsNode;
  onClose?: () => void;
  handlePublished?: () => void;
  refetchQueries?: RefetchQueryDescription;
}

function PublishVideoBody({
  video,
  onClose,
  handlePublished,
  refetchQueries,
}: PublishVideoBodyProps) {
  const client = useApolloClient();
  const [publishStatuses, setPublishStatuses] = useSafeState<PublishStatusWithMeta[]>([]);
  const [isPublishing, setIsPublishing] = useSafeState(false);

  const targetHandlers = usePublishingTargetHandlers();

  const handlePublishResponse: HandlePublishResponse = (data, selectedTargets) => {
    const handlers = innerJoin(
      (handler, target) => handler.id === target.id,
      targetHandlers,
      selectedTargets,
    );

    const statuses = handlers.map(({ id, label, type, onPublish }) => ({
      id,
      label,
      type,
      scheduledPublishDate: selectedTargets[0].scheduledPublishDate,
      ...onPublish(id, data),
    }));

    setPublishStatuses(statuses);
  };

  const handlePollResponse: HandlePollResponse = (data, updatedTargets) => {
    const handlers = innerJoin(
      (handler, target) => handler.id === target.id,
      targetHandlers,
      updatedTargets,
    );

    const newStatuses = handlers.map(({ id, label, type, onPoll }) => ({
      id,
      label,
      type,
      ...onPoll(id, data),
    }));

    setPublishStatuses((oldStatuses) => {
      return oldStatuses.map((status) => {
        const updatedStatus = newStatuses.find(({ id }) => id === status.id);
        return updatedStatus ?? status;
      });
    });
  };

  const { youtubeOptions } = useYouTubeOptionsContext();

  const handlePublish = async (selectedTargets: PublishingTarget[]) => {
    setIsPublishing(true);
    const isSetScheduledPublishDate: boolean = selectedTargets[0].scheduledPublishDate.length > 0;
    const scheduledPublishDateUTC: string = changeDateToUTC(
      selectedTargets[0].scheduledPublishDate,
    );

    if (isSetScheduledPublishDate) {
      youtubeOptions.publishAt = scheduledPublishDateUTC;
    }

    let data: PublishVideoResponseData | undefined;

    try {
      const targetIds = selectedTargets
        .filter(({ type }) => type === PublishingTargetType.SelfPublication)
        .map(({ id }) => id);

      const isYouTubeSelected = !!selectedTargets.find(
        ({ type }) => type === PublishingTargetType.YouTube,
      );

      const result = await publishVideo(client, selectedTargets, {
        variables: {
          videoId: video.id,
          targetIds: targetIds.length > 0 ? targetIds : undefined,
          youtubeOptions: isYouTubeSelected ? youtubeOptions : undefined,
          scheduledPublishDate: isSetScheduledPublishDate ? scheduledPublishDateUTC : undefined,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
      data = result.data ?? undefined;
    } catch (error) {
      // do nothing, errors are handled globally
    } finally {
      setIsPublishing(false);
      handlePublished && handlePublished();
    }

    if (!data) {
      return;
    }

    handlePublishResponse(data, selectedTargets);
  };

  const isSelectView = publishStatuses.length === 0;

  return (
    <>
      {isSelectView ? (
        <SelectTargets
          video={video}
          targetHandlers={targetHandlers}
          isSubmitting={isPublishing}
          onSubmit={handlePublish}
          isSubmitDisabled={!canVideoBePublished(video.status)}
          submitButtonLabel={isPublishing ? 'Publishing…' : 'Publish'}
        >
          <Stack rowGap={28}>
            <Styled.Title>Publish</Styled.Title>
            <Styled.Subtitle>
              Choose one or multiple platforms to publish{' '}
              <Styled.VideoLink to={generateVideoLink(video.id)} onClick={onClose}>
                “{video.title}”
              </Styled.VideoLink>{' '}
              video to:
            </Styled.Subtitle>
          </Stack>
        </SelectTargets>
      ) : (
        <VideoPublished
          videoId={video.id}
          publishStatuses={publishStatuses}
          onClose={onClose}
          handlePollResponse={handlePollResponse}
        />
      )}
    </>
  );
}

export default PublishVideo;
