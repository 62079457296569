import { OutlinedInput as MaterialOutlinedInput } from '@material-ui/core';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

interface OutlinedInputProps {
  readOnly?: boolean;
  multiline?: boolean;
}

export const OutlinedInput = styled(MaterialOutlinedInput)<OutlinedInputProps>`
  background-color: ${({ readOnly }) => readOnly && materialTheme.palette.grey[100]};
  min-height: 52px;

  .MuiOutlinedInput-input {
    color: ${({ readOnly }) => readOnly && materialTheme.palette.grey[500]};
    text-align: center;
  }
`;

export const WarningMessage = styled.div<{ error?: boolean }>`
  color: ${({ error }) =>
    error ? materialTheme.palette.error.main : materialTheme.palette.text.secondary};
  font-size: 12px;
  margin-top: 8px;
  left: 0;
  position: absolute;
`;
