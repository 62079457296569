import { QUERY_VIDEO_MOMENTS } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { MomentOrderBy, PageInfo, PaginationVariables, VideoMomentNode } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  moments: Nullable<{
    nodes: Nullable<VideoMomentNode[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends Partial<PaginationVariables> {
  orderBy?: MomentOrderBy;
  search?: string;
  title?: string;
  videoId: string;
}

interface UseVideoMomentsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

// TODO: check if this can be merged with `useDashboardMomentsQuery` into one query
export const useVideoMomentsQuery = (options: UseVideoMomentsQueryOptions) => {
  return useQuery<ResponseData, Variables>(QUERY_VIDEO_MOMENTS, options);
};
