import { Box, Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import React from 'react';

import { ALIGNMENT_ICONS } from '../constants';
import { CTATimestampCtasPosition } from '../type';

interface Props {
  updateCtaPosition: (position: Partial<CTATimestampCtasPosition>) => void;
}

export const Icons: React.FC<Props> = ({ updateCtaPosition }) => {
  return (
    <Box display="flex" justifyContent="space-between" mb="25px">
      {ALIGNMENT_ICONS.map(({ Icon, title, position }) => (
        <Tooltip key={title} title={title} arrow placement="top">
          <IconButton
            color="inherit"
            onClick={() => updateCtaPosition({ ...position, isAlignment: true })}
          >
            <Icon />
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};
