import { VIDEOS_LIST_QUERY, VIDEOS_LIST_QUERY_NO_DUPLICATES } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { DashboardVideoNode, PageInfo, PaginationVariables, VideoOrderBy } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  videos: Nullable<{
    nodes: Nullable<DashboardVideoNode[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends PaginationVariables {
  orderBy?: VideoOrderBy;
  search?: string;
  distinctGlobalId?: boolean;
}

interface UseDashboardVideosQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useDashboardVideosQuery = (options: UseDashboardVideosQueryOptions) => {
  const query = options.variables?.distinctGlobalId
    ? VIDEOS_LIST_QUERY
    : VIDEOS_LIST_QUERY_NO_DUPLICATES;
  return useQuery<ResponseData, Variables>(query, options);
};
