import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { Permission } from 'config/permissions';
import { useUserPermissions } from 'hooks/query/useUserPermissions';

import { UpdateMonetizationModal } from '../MonetizationModals/UpdateMonetizationModal';

export const AddButton: React.VFC<{ isEmptyMonetization?: boolean }> = ({
  isEmptyMonetization,
}) => {
  const { hasPermissions } = useUserPermissions();
  const canAddMonetization = hasPermissions([Permission.CreateOrganizationMonetization]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleButtonClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  if (!canAddMonetization) {
    return null;
  }

  return (
    <>
      <Button variant="contained" onClick={handleButtonClick} color="primary">
        Add ad tag URL
      </Button>
      <UpdateMonetizationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        isEmptyMonetization={isEmptyMonetization}
      />
    </>
  );
};
