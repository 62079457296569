import React from 'react';

import Select from 'components/common/Select';
import { UserRole, WidgetColorTheme } from 'models';
import { capitalize } from 'utils/common';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (role: UserRole) => void;
}

export const WidgetThemeDropdown = React.forwardRef<any, Props>(function ThemeDropdown(
  { id, name, value, onChange },
  ref,
) {
  return (
    <Select
      innerRef={ref}
      id={id}
      name={name}
      label="Widget Theme"
      options={options}
      value={value}
      onChange={({ currentTarget }) => {
        onChange(currentTarget.value as UserRole);
      }}
    />
  );
});

const validOptions = [WidgetColorTheme.Light, WidgetColorTheme.Dark];
const options = validOptions.map((option) => ({
  value: option,
  label: capitalize(option),
}));
