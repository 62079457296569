import React, { useEffect, useState } from 'react';

import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { updateSource } from 'context/VideoMetadataStream';
import { useToggleVideoPromotedContent, useUpdatePromoteContent } from 'hooks/mutation';

import { PromoteContentMain } from './PromoteContentMain';
import { PromoteContentSidebar } from './PromoteContentSidebar';
import {
  PromoteContentSettingsProps,
  PromoteContentViewProps,
  TogglePromoteContentActions,
} from './types';

export const PromoteContentView = ({ video }: PromoteContentViewProps) => {
  const [showPromoteObjects, setShowPromoteObjects] = useState(
    !!video.promotedContentSettings?.promoteObjects,
  );
  const [showPromoteAtStart, setShowPromoteAtStart] = useState(
    !!video.promotedContentSettings?.promoteAtStart,
  );
  const [showPromoteAtEnd, setShowPromoteAtEnd] = useState(
    !!video.promotedContentSettings?.promoteAtEnd,
  );
  const [promotedObjectsLabel, setPromotedObjectsLabel] = useState(
    video.promotedContentSettings?.promotedObjectsLabel,
  );

  const [updatePromoteContent, { loading: isLoading }] = useUpdatePromoteContent();
  const [
    toggleVideoPromotedContent,
    { loading: isToggleLoading },
  ] = useToggleVideoPromotedContent();

  const handleSubmit = async (data: PromoteContentSettingsProps | null) => {
    if (data) {
      await updatePromoteContent({
        variables: {
          videoId: video.id,
          promotedContentSettings: data,
        },
      });
    } else {
      await toggleVideoPromotedContent({
        variables: {
          videoId: video.id,
          action: TogglePromoteContentActions.DISABLE,
        },
      });
    }
  };

  const promoteContentSettings = {
    promoteObjects: showPromoteObjects,
    promoteAtStart: showPromoteAtStart,
    promoteAtEnd: showPromoteAtEnd,
    promotedObjectsLabel,
  };

  useEffect(() => updateSource({ duration: video.duration || 0 }), [video.duration]);

  return (
    <>
      <ExtensibleSidebar
        sideContent={
          <PromoteContentSidebar
            video={video}
            onSave={handleSubmit}
            promoteContentSettings={promoteContentSettings}
            setPromoteObjectsLabel={setPromotedObjectsLabel}
            togglePromoteObjects={setShowPromoteObjects}
            togglePromoteAtStart={setShowPromoteAtStart}
            togglePromoteAtEnd={setShowPromoteAtEnd}
          />
        }
        mainContent={
          <PromoteContentMain
            video={video}
            isLoading={isLoading || isToggleLoading}
            promoteContentSettings={promoteContentSettings}
          />
        }
      />
    </>
  );
};
