import { UPDATE_MOMENT_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, MomentMetadataFragment, UpdateMomentInput } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateMoment: Nullable<{
    moment: Nullable<MomentMetadataFragment>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  moment: UpdateMomentInput;
}

interface UseUpdateMomentOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateMoment = (options?: UseUpdateMomentOptions) => {
  return useMutation<ResponseData, Variables>(UPDATE_MOMENT_MUTATION, {
    pathToErrors: 'updateMoment.errors',
    ...options,
  });
};
