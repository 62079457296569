import { Box, Button } from '@material-ui/core';
import React from 'react';

import { LoadingButton, Spinner } from 'components/common';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { Permission } from 'config/permissions';
import { pushMessage } from 'context/globalStream';
import { useCancelSubscription } from 'hooks/mutation/useCancelSubscription';
import { useCreatePaymentSession } from 'hooks/mutation/useCreatePaymentSession';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useStripe } from 'hooks/useStripe';
import { SubscriptionStatus } from 'models';

import { CustomerPortalLink } from './CustomerPortalLink';
import * as Styled from './PaymentPlanTab.styled';

interface Props {
  successUrl: string;
  cancelUrl: string;
}

export const PaymentPlan: React.VFC<Props> = ({ successUrl, cancelUrl }) => {
  const [stripe, isStripeLoading] = useStripe();
  const { data, loading: isSubscriptionLoading } = useOrganizationQuery();
  const subscription = data?.me?.organization?.subscription;

  const [createPaymentSession, { loading: isPaymentLoading }] = useCreatePaymentSession();
  const [cancelSubscription, { loading: isCancelSubscriptionLoading }] = useCancelSubscription();

  const { hasPermissions } = useUserPermissions();
  const canSeeCustomerPortal = hasPermissions([Permission.CreateCustomerPortalSession]);
  const canCancelSubscription = hasPermissions([Permission.CancelSubscription]);

  const onUpgradeToPremium = async () => {
    const { data } = await createPaymentSession({
      variables: {
        paymentSession: {
          successUrl,
          cancelUrl,
        },
      },
    });
    const paymentSessionId = data?.createPaymentSession?.paymentSession?.stripePaymentSessionId;
    if (paymentSessionId && stripe) {
      stripe.redirectToCheckout({ sessionId: paymentSessionId });
    }
  };

  const onCancelSubscription = async () => {
    await cancelSubscription();
    pushMessage({ message: 'Subscription has been canceled.' });
  };

  const isUpgradeButtonVisible = ![
    SubscriptionStatus.Premium,
    SubscriptionStatus.Unlimited,
    SubscriptionStatus.D2C,
    SubscriptionStatus.D2CUnlimited,
  ].includes(
    // @ts-ignore
    subscription?.status,
  );

  return (
    <>
      {isSubscriptionLoading ? (
        <LoadingSubscription />
      ) : !subscription?.plan ? (
        <Styled.ExpiredPlanContainer>
          <div>
            Your plan has expired. Please upgrade now.
            <br />
            Your organization and all the members have been deactivated till the confirmed payment.
          </div>
          {isUpgradeButtonVisible && (
            <Columns display="flex" justifyContent="center" alignItems="center">
              {canSeeCustomerPortal && <CustomerPortalLink />}
              <Button
                onClick={onUpgradeToPremium}
                id="checkout"
                variant="contained"
                color="primary"
              >
                Upgrade to Premium
              </Button>
            </Columns>
          )}
        </Styled.ExpiredPlanContainer>
      ) : (
        <Styled.YourPlanContainer>
          <Stack rowGap={20} display="flex" flexDirection="column" alignItems="flex-start">
            <span>Your Plan</span>
            <Styled.YourPlanBox>
              {subscription.plan && (
                <Styled.PlanPrice>${subscription.cost ? subscription.cost : 0}</Styled.PlanPrice>
              )}
              <Styled.PlanName>
                <span>{subscription.plan ? subscription.plan.name : 'EXPIRED'}</span>
                {subscription.endsAt && (
                  <Styled.EndsAt>Ends on {subscription.endsAt}</Styled.EndsAt>
                )}
              </Styled.PlanName>
            </Styled.YourPlanBox>
            {canSeeCustomerPortal && <CustomerPortalLink />}
          </Stack>

          <Columns>
            {isUpgradeButtonVisible && (
              <LoadingButton
                onClick={onUpgradeToPremium}
                disabled={!stripe}
                isLoading={isStripeLoading || isPaymentLoading}
                variant="contained"
                color="primary"
              >
                Upgrade to Premium
              </LoadingButton>
            )}
            {canCancelSubscription &&
              subscription.status === SubscriptionStatus.Premium &&
              !subscription.endsAt && (
                <LoadingButton
                  onClick={onCancelSubscription}
                  isLoading={isCancelSubscriptionLoading}
                  variant="contained"
                  color="secondary"
                >
                  Cancel Subscription
                </LoadingButton>
              )}
          </Columns>
        </Styled.YourPlanContainer>
      )}
    </>
  );
};

export const LoadingSubscription: React.VFC = () => {
  return (
    <Box mb="20px" textAlign="center">
      <Spinner />
    </Box>
  );
};
