import { Box, Button, Tabs as MaterialTabs, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { materialTheme } from 'components/App/materialTheme';
import { Link } from 'components/common';
import { EditedVideoPrompt } from 'components/common/EditedVideoPrompt';
import { useSearch } from 'components/common/Search/Search';
import { TabsWrapper } from 'components/common/Tab/Tab';
import { TabLink, useTabsWithRouter } from 'components/common/TabLink';
import { TranscriptionSidebar } from 'components/common/TranscriptionSidebar';
import { PublishableWidgetEmbed } from 'components/common/WidgetEmbedCode';
import { Columns } from 'components/common/Wrapper/Layout';
import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { DeleteAction } from 'components/tables/TableActionCell';
import { TimelineEditor } from 'components/TimelineEditor';
import BasePlayer from 'components/VideoPlayer/BasePlayer';
import { MIN_TIMESTAMP_LENGTH } from 'config/constants';
import { Permission } from 'config/permissions';
import { updateSource } from 'context/VideoMetadataStream';
import { seekToMs } from 'context/VideoMetadataStream';
import { useReprocessFailedVideo, useUpdateVideo } from 'hooks/mutation';
import { useDeleteVideo } from 'hooks/mutation';
import { useVideoStatusQuery } from 'hooks/query';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useVideoTranscription } from 'hooks/query/useVideoTranscription';
import { useWidgetApiKey } from 'hooks/query/useWidgetApiKey';
import { usePolling } from 'hooks/usePolling';
import { MomentPlayerPickI, VideoDetailsNode, VideoStatus } from 'models';
import { PublishModal } from 'pages/PublishVideo';
import { generateVideoObjectsLink, generateVideoPublishLink, URLS } from 'pages/urls';
import { formatDateTime } from 'utils/date';
import { pushHistoryUrl } from 'utils/navigation';
import { getStreamURL } from 'utils/streaming';
import { Nullable } from 'utils/types';
import { canVideoBePublished, isPollingRequired } from 'utils/videos';
import { formatVideoStatus } from 'utils/videos';

import FailInfo from '../FailInfo';
import VideoDetailsPanel, { FormData } from '../VideoDetailsPanel';
import ObjectsTab from './ObjectsTab';
import * as Styled from './styled';

const Tabs = withStyles({
  indicator: {
    backgroundColor: materialTheme.palette.primary.main,
  },
})(MaterialTabs);
interface OwnProps {
  video: VideoDetailsNode;
}

const VideoContent = ({ video }: OwnProps) => {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [updateVideo, { loading }] = useUpdateVideo(video.id);
  const [isRemovingVideo, setIsRemovingVideo] = useState(false);
  const [reprocessFailedVideo] = useReprocessFailedVideo();

  const objectsTabUrl = generateVideoObjectsLink(video.id);
  const tabValue = useTabsWithRouter([objectsTabUrl], objectsTabUrl);

  const videoMoments: MomentPlayerPickI[] = video.moments;
  const history = useHistory();

  const formatScheduleDateTime = (): Nullable<string> | undefined => {
    if (!video.scheduledDatetime || video.scheduledDatetime.slice(-6) === '+00:00') {
      return video.scheduledDatetime;
    } else {
      return `${video.scheduledDatetime}+00:00`;
    }
  };
  const isScheduled: boolean = video.status === VideoStatus.Scheduled;
  const scheduledDatetime: Nullable<string> | undefined = formatScheduleDateTime();

  const momentsRanges = video.moments.map((moment) => ({
    id: moment.id,
    startTimestamp: moment.timeRange[0],
    endTimestamp: moment.timeRange[1],
  }));

  const { startPolling, stopPolling } = useVideoStatusQuery({
    variables: {
      id: video.id,
    },
  });

  usePolling({
    isEnabled: isPollingRequired(video),
    startPolling,
    stopPolling,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateSource({ duration: video.duration || 0 }), []);

  const handleVideoSave = React.useCallback(
    ({
      title,
      description,
      tags,
      internalTags,
      thumbnailId,
      endCardId,
      orgLogoPosition,
    }: FormData) => {
      updateVideo({
        variables: {
          id: video.id,
          video: {
            title: title.trim(),
            description: description.trim(),
            tags,
            internalTags,
            thumbnailId,
            orgLogoPosition,
          },
        },
      });
    },
    [updateVideo, video.id],
  );

  const [deleteVideo] = useDeleteVideo({
    onCompleted: (data) => {
      if (data.deleteVideo?.deletedVideoId) {
        setIsRemovingVideo(true);
        history.replace(URLS.dashboard.root);
      }
    },
  });

  const handleVideoReprocess = React.useCallback(() => {
    reprocessFailedVideo({ variables: { id: video.id } });
  }, [reprocessFailedVideo, video.id]);

  const match = useRouteMatch();
  const publishUrl = generateVideoPublishLink(video.id);

  const { hasPermissions } = useUserPermissions();
  const canUserPublishVideo = hasPermissions([Permission.PublishVideo]);
  const isPublishButtonDisabled = loading || !canVideoBePublished(video.status);
  const canUserDeleteVideo = hasPermissions([Permission.DeleteVideo]);
  const canUserChangeMoment = hasPermissions([Permission.ChangeMoment]);
  const canUserViewObject = hasPermissions([Permission.ViewObject]);

  const sourceURL = getStreamURL(video.videoFileUrl, video.videoStreams);
  const videoFailed = video.status === VideoStatus.Failed;

  const { widgetApiKey } = useWidgetApiKey();

  const [searchText, setSearchText] = useSearch();
  const { transcription, segments, loadPage } = useVideoTranscription({
    variables: {
      videoId: video.id,
      search: searchText,
    },
  });

  return (
    <>
      {!isRemovingVideo ? <EditedVideoPrompt status={video.status} /> : null}
      <ExtensibleSidebar
        sideContent={<VideoDetailsPanel video={video} onSave={handleVideoSave} />}
        mainContent={
          <>
            <Columns display="flex" alignItems="center" justifyContent="flex-end" pb="20px">
              <Columns columnGap="20px" mr="auto" display="flex" alignItems="center">
                {canUserDeleteVideo && (
                  <DeleteAction
                    type="video"
                    onDelete={() => deleteVideo({ variables: { id: video.id } })}
                    renderAsButton={true}
                    customModalTitle="Are you sure you want to remove this video?"
                    customModalDescription="You will remove the video permanently and this action can't be reverted back."
                  />
                )}
                <Styled.VideoStatus>
                  {formatVideoStatus(video.status)}{' '}
                  {isScheduled && scheduledDatetime ? (
                    <span className="date-time">{formatDateTime(new Date(scheduledDatetime))}</span>
                  ) : (
                    ''
                  )}
                </Styled.VideoStatus>
              </Columns>
              <Styled.VideoStatus>{loading ? 'Saving…' : ''}</Styled.VideoStatus>
              <Button component={Link} to={URLS.root} variant="outlined" color="secondary">
                Cancel
              </Button>
              <PublishableWidgetEmbed
                id={video.id}
                videoId={video.id}
                type="video"
                videoStatus={video.status}
                canUserPublishVideo={canUserPublishVideo}
                apiKey={widgetApiKey}
              >
                {({ showModal }) => {
                  return <PublishableWidgetEmbed.Button showModal={showModal} />;
                }}
              </PublishableWidgetEmbed>
              {canUserPublishVideo && (
                <Tooltip
                  title={
                    isScheduled
                      ? 'It is not possible to publish a scheduled video. First cancel scheduled publish or create a duplicate.'
                      : ''
                  }
                  arrow
                  placement="bottom"
                >
                  <div>
                    <Button
                      href={publishUrl}
                      onClick={(event) => {
                        event.preventDefault();
                        setShowPublishModal(true);
                        pushHistoryUrl(publishUrl);
                      }}
                      variant="contained"
                      disabled={isPublishButtonDisabled}
                      color="primary"
                    >
                      {video.status === VideoStatus.Edited ? 'Republish' : 'Publish'}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Columns>
            <Styled.VideoWrapper>
              <BasePlayer
                url={sourceURL}
                moments={videoMoments}
                isLogoEnabled={video.orgLogoEnabled || false}
                logoPosition={video?.orgLogoPosition || 'BOTTOM_RIGHT'}
              />
              {videoFailed ? (
                <FailInfo onRetry={handleVideoReprocess} />
              ) : (
                <>
                  <TimelineEditor
                    seekTo={seekToMs}
                    ranges={momentsRanges}
                    minRangeLength={MIN_TIMESTAMP_LENGTH}
                    isDisabled={!canUserChangeMoment}
                    moments={video.moments}
                  />
                  <Box bgcolor="common.white" boxShadow={5} overflow="hidden">
                    <>
                      <TabsWrapper pl="20px">
                        <Tabs value={tabValue}>
                          {canUserViewObject && <TabLink value={objectsTabUrl} label="Objects" />}
                        </Tabs>
                      </TabsWrapper>
                      <Switch>
                        {canUserViewObject && (
                          <Route path={[URLS.video.root, URLS.video.objects]}>
                            <ObjectsTab video={video} />
                          </Route>
                        )}
                      </Switch>
                    </>
                  </Box>
                </>
              )}
            </Styled.VideoWrapper>
          </>
        }
        rightContent={
          transcription ? (
            <TranscriptionSidebar
              transcription={transcription}
              segments={segments}
              loadPage={loadPage}
              seekTo={seekToMs}
              onSearch={setSearchText}
            />
          ) : null
        }
      />
      {canUserPublishVideo && (
        <PublishModal
          open={showPublishModal}
          onClose={() => {
            setShowPublishModal(false);
            pushHistoryUrl(match.url);
          }}
        />
      )}
    </>
  );
};

export default VideoContent;
