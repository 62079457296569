export const TEST_ID = {
  navbar: 'navbar',
  profileButton: 'profile-button',
  resetPasswordButton: 'reset-password-button',
  logInButton: 'log-in-button',
  avatarUploadBox: 'avatar-upload-box',
  deleteAvatarButton: 'delete-avatar-button',
  saveAccountButton: 'save-account-button',
  savePasswordButton: 'save-password-button',
  saveEmailButton: 'save-email-button',
} as const;
