import { intervalToDuration } from 'date-fns';

import { notEmpty } from 'utils/common';
import { Optional } from 'utils/types';

export function formatDuration(inputSeconds: Optional<number>) {
  if (typeof inputSeconds !== 'number' || inputSeconds === 0) {
    return '0s';
  }

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: inputSeconds * 1000,
  });
  const milliseconds = Number((inputSeconds % 1).toFixed(4)) * 1000;

  const dayHours = days ? days * 24 : undefined;
  const totalHours = addUndefinedNumbers(dayHours, hours);

  const hoursString = getTimeString(totalHours, 'h');
  const minutesString = getTimeString(minutes, 'm');
  const secondsString = getTimeString(seconds, 's');
  const millisecondsString = getTimeString(milliseconds, 'ms');

  return [hoursString, minutesString, secondsString, millisecondsString].filter(notEmpty).join(' ');
}

function getTimeString(value: number | undefined, suffix: string) {
  return typeof value !== 'undefined' && value !== 0 ? `${value}${suffix}` : undefined;
}

function addUndefinedNumbers(a: Optional<number>, b: Optional<number>) {
  return (a ?? 0) + (b ?? 0) || undefined;
}
