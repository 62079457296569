import { uniqBy } from 'ramda';
import React from 'react';
import { useParams } from 'react-router';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { PlaylistOrdering } from 'models/Playlist';
import { URLS } from 'pages/urls';

import { FormData, PlaylistPageView } from './PlaylistEditor';
import { Item } from './PlaylistEditor/types';
import { useEditorPlaylistById } from './PlaylistEditor/useEditorPlaylistById';
import { useUpdatePlaylist } from './useUpdatePlaylist';

interface RouteParams {
  playlistId: string;
}

export function UpdatePlaylistPage() {
  const { playlistId } = useParams<RouteParams>();

  const [manualItems, setManualItems] = React.useState<Item[]>([]);

  const { playlist, loading: isPlaylistLoading } = useEditorPlaylistById({
    variables: {
      id: playlistId,
      page: 1,
      pageSize: 999,
    },
    onCompleted: (data) => {
      const items = data.playlistById?.items.nodes;
      if (items) {
        setManualItems(items);
      }
    },
  });

  const [updatePlaylist, { loading: isSaving }] = useUpdatePlaylist();

  const handleSubmit = ({ contentType, filterBy, filterValue, name, orderBy }: FormData) => {
    const isManual = orderBy === PlaylistOrdering.Manual;

    updatePlaylist({
      variables: {
        id: playlistId,
        playlist: {
          contentType,
          filterBy,
          filterValue,
          name,
          orderBy,
          items: isManual ? manualItems.map(({ id }) => id) : [],
        },
      },
    });
  };

  const handleAddItemsToPlaylist = (items: Item[]) => {
    setManualItems((oldItems) => {
      return uniqBy((item) => item.id, [...oldItems, ...items]);
    });
  };

  if (isPlaylistLoading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (playlist) {
    return (
      <MainLayout>
        <PlaylistPageView
          pageTitle="Customize Playlist"
          onFormSubmit={handleSubmit}
          goBackLink={URLS.playlists.list}
          isSaving={isSaving}
          saveButtonText="Save"
          playlist={playlist}
          handleAddItemsToPlaylist={handleAddItemsToPlaylist}
          manualItems={manualItems}
          setManualItems={setManualItems}
        />
      </MainLayout>
    );
  }

  return <MainLayout>Unexpected error</MainLayout>;
}
