import { SnackbarKey, useSnackbar } from 'notistack';
import { useState } from 'react';

import { env } from 'config/env';
import { pushError } from 'context/globalStream';
import { VideoMomentNode } from 'models';

export const useDownload = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentDownload, setCurrentDownload] = useState<string>('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleDownload = async (moment: VideoMomentNode) => {
    const { id, timeRange } = moment;
    const [start, end] = timeRange;
    const url = `${env.SEEEN_APP_BACKEND_URL}moments/download/${id}/${start}/${end}`;
    setIsDownloading(true);
    setCurrentDownload(id);

    let notification: SnackbarKey;
    let data;
    const notificationId = new Date().getTime().toString();
    try {
      notification = enqueueSnackbar(
        <div id={notificationId}>
          Please wait. Your moment is being prepared for downloading...
        </div>,
        {
          variant: 'default',
          persist: true,
        },
      );

      data = await fetch(url);

      const subscribe = (job_id: string) => async () => {
        const statusUrl = `${env.SEEEN_APP_BACKEND_URL}moments/download/status/${job_id}`;
        let response = await fetch(statusUrl);
        const contentType = response.headers.get('Content-Type');
        if (contentType === 'application/json') {
          const jobResponse = await response.json();

          const { progress_percentage } = jobResponse;
          const percentage = progress_percentage ?? '0';
          const ref = document.getElementById(`${notificationId}`);
          if (ref)
            ref.innerText = `Please wait. Your moment is being prepared for downloading ${percentage}%`;
          setTimeout(() => {
            subscribe(job_id)();
          }, 10000);
        } else if (contentType === 'binary/octet-stream') {
          const a = document.createElement('a');
          a.href = response.url;
          document.body.appendChild(a);
          a.click();
          a.remove();
          closeSnackbar(notification);
          setCurrentDownload('');
          setIsDownloading(false);
        }
      };

      if (data.ok) {
        const dataJson = await data.json();
        const { job_id } = dataJson;
        subscribe(job_id)();
      } else {
        pushError({
          message: `Downloading moment failed: status code ${data.status}`,
          autoHideDuration: 4000,
        });
        setCurrentDownload('');
        setIsDownloading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log((error as Error).message);
    }
  };
  return {
    isDownloading,
    currentDownload,
    handleDownload,
  };
};
