import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

interface TimestampProps {
  isEdit?: boolean;
  disabled?: boolean;
}

export const TimestampWrapper = styled.div<{ disabled?: boolean }>`
  & > *:not(:last-child) {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }
  & > *:not(:last-child) {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

export const Timestamp = styled.div<TimestampProps>`
  position: relative;
  padding: 20px;
  border-style: solid;
  border-color: ${({ isEdit }) =>
    isEdit ? '#32353E' : `${materialTheme.palette.secondary.light}`};
  border-width: ${({ isEdit }) => (isEdit ? '2px' : '1px')};
  box-shadow: ${({ isEdit }) => (isEdit ? '0px 4px 10px 0px rgba(0, 0, 0, 0.25)' : '')};
  border-radius: 10px;
  transition: border 0.25s ease-out, box-shadow 0.25s ease-out;
  margin-bottom: 40px;

  & > :not(#ctas-list) {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }
  & > :not(input):not(#ctas-list) {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;
