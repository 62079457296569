import styled from 'styled-components/macro';

import imageThumbnail from 'assets/images/overlays-image-thumbnail.png';
import { materialTheme } from 'components/App/materialTheme';

interface ImageProps {
  url?: string;
}

export const UploadWrapper = styled.div`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  position: relative;
  background-image: url(${imageThumbnail});
  border-radius: 10px;
  height: 64px;
  width: 64px;
  margin-right: 16px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  overflow: hidden;
`;

export const Image = styled.div<ImageProps>`
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const WarningMessage = styled.div<{ error?: boolean }>`
  color: ${({ error }) =>
    error ? materialTheme.palette.error.main : materialTheme.palette.text.secondary};
  font-size: 12px;
  margin-top: 8px;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  background-color: ${materialTheme.palette.secondary.main};
  border-radius: 50%;

  svg {
    color: ${materialTheme.palette.background.paper};
  }
`;
