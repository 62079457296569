import './animations.css';

import { IconButton, Tooltip, TooltipProps } from '@material-ui/core';
import { motion } from 'framer-motion';

import { ReactComponent as CrossedSquare } from 'assets/icons/square-crossed.svg';
import { FormLabel, InfoTooltip } from 'components/common';

import { ANIMATION_ICONS } from '../constants';
import { keyframes } from '../constants';
import { AnimationName } from '../type';

import { CtaIconBox } from './AnimationIcons.styles';

interface Props {
  handleIconClick: (name: AnimationName) => void;
  handleTogglePause: (index: number) => void;
  pausedStates: boolean[];
  handleCancelAnimation: () => void;
  currentName: AnimationName;
}
export default function AnimationIcons({
  handleIconClick,
  handleTogglePause,
  pausedStates,
  handleCancelAnimation,
  currentName,
}: Props) {
  return (
    <>
      <FormLabel>
        Animations
        <InfoTooltip
          content={<div>Choose an animation for your CTA</div>}
          options={{ interactive: true } as TooltipProps}
          placement="top"
        />
      </FormLabel>
      <CtaIconBox>
        <Tooltip title="Cancel animation" arrow placement="top">
          <IconButton color="inherit" onClick={() => handleCancelAnimation()}>
            <CrossedSquare />
          </IconButton>
        </Tooltip>
        {ANIMATION_ICONS.map(({ Icon, title, name }, index) => (
          <Tooltip key={title} title={title} arrow placement="top">
            <motion.div
              animate={
                pausedStates[index]
                  ? 'none'
                  : {
                      ...keyframes[name],
                      transition: {
                        [Object.keys(keyframes[name])[0]]: {
                          repeat: Infinity,
                          duration: 0.75,
                          ease: 'easeOut',
                        },
                      },
                    }
              }
              style={{ overflow: 'hidden', borderRadius: '50%' }}
            >
              <IconButton
                color="inherit"
                onMouseEnter={() => handleTogglePause(index)}
                onMouseLeave={() => handleTogglePause(index)}
                onClick={() => handleIconClick(name)}
                className={currentName === name ? 'active' : ''}
              >
                <Icon />
              </IconButton>
            </motion.div>
          </Tooltip>
        ))}
      </CtaIconBox>
    </>
  );
}
