import React from 'react';

import { CarouselItemProps } from '../types';
import * as Styled from './styled';

export const CarouselItem: React.FC<CarouselItemProps> = ({ moment }) => {
  return (
    <Styled.Slide>
      <Styled.ImageContainer className="slide-image-container">
        <Styled.Thumbnail
          style={{
            backgroundImage: moment.thumbnailUrl ? `url(${moment.thumbnailUrl})` : undefined,
          }}
        />
      </Styled.ImageContainer>
      <Styled.SlideTitle>{moment.title}</Styled.SlideTitle>
    </Styled.Slide>
  );
};
