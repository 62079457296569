import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, OrganizationMonetization, OrganizationMonetizationInput } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation CreateOrganizationMonetization($monetization: OrganizationMonetizationInput!) {
    createOrganizationMonetization(monetization: $monetization) {
      monetization {
        id
        name
        monetizationUrl
        default
        active
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  createOrganizationMonetization: Nullable<{
    monetization: Nullable<OrganizationMonetization>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  monetization: OrganizationMonetizationInput;
}

interface UseCreateOrganizationMonetizationQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useCreateOrganizationMonetization = (
  options?: UseCreateOrganizationMonetizationQueryOptions,
) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'createOrganizationMonetization.errors',
    skipGlobalErrorHandling: true,
    refetchQueries: ['GetOrganization'],
    ...options,
  });
};
