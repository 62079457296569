import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { Link } from 'components/common';

export const ExpandDropdown = styled.div<{ dropdownVisible: boolean }>`
  justify-content: flex-start;
  margin-left: 12px;

  svg {
    color: #fff;
    width: 12px;
    height: 12px;
    transform: ${({ dropdownVisible }) => (dropdownVisible ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;

export const DropdownList = styled.div`
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  border-radius: 10px;
  width: 280px;
  overflow: hidden;

  & > *:not(:last-child) {
    border-bottom: 1px solid ${materialTheme.palette.grey[200]};
  }
`;

export const DropdownItemLink = styled(Link)`
  padding: 14px 16px;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: ${materialTheme.palette.background.default};
  }
`;

export const DropdownItemIcon = styled.span`
  display: inline-flex;
  margin-right: 8px;
  color: ${materialTheme.palette.primary.main};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const DropdownItemLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
`;
