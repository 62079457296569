import gql from 'graphql-tag';

import { D2C_API_KEY_FRAGMENT } from 'context/queries';
import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation } from 'hooks/useMutation';
import { D2CApiKey } from 'models';
//import { ErrorType, OrganizationI } from 'models'; TODO: models
import { Nullable } from 'utils/types';

type Errors = 'DUPLICATED_API_KEY_NAME';

const errorMap: Record<Errors, string> = {
  DUPLICATED_API_KEY_NAME: 'API Key name is already taken.',
};

const QUERY = gql`
  ${D2C_API_KEY_FRAGMENT}

  mutation CreateD2CApiKey($name: Slug!) {
    createD2CApiKey(name: $name) {
      apiKey {
        ...D2CApiKeys
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  createD2CApiKey: Nullable<{
    apiKey: D2CApiKey;
  }>;
}

interface Variables {
  name: string;
}

export const useCreateD2CApiKey = () => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'createD2CApiKey.errors',
    skipGlobalErrorHandling: true,
    errorMap,
  });
};
