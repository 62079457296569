import React from 'react';

import { materialTheme } from 'components/App/materialTheme';
import { StyledTabs, TabsWrapper } from 'components/common/Tab/Tab';
import { TabLink, useTabsWithRouter } from 'components/common/TabLink';
import { URLS } from 'pages/urls';
import { notEmpty } from 'utils/common';

interface Props {
  tabsActionContentWidth: number;
}

export const OverlaysTabs: React.FC<Props> = ({ tabsActionContentWidth }) => {
  const tabValue = useTabsWithRouter(
    [URLS.overlays.endCards, URLS.overlays.ctas].filter(notEmpty),
    URLS.overlays.endCards,
  );

  return (
    <TabsWrapper borderBottom={`1px solid ${materialTheme.palette.background.default}`}>
      <div style={{ width: `calc(100% - ${tabsActionContentWidth}px)` }}>
        <StyledTabs value={tabValue} variant="scrollable" scrollButtons="on">
          <TabLink value={URLS.overlays.endCards} label="End cards" />
          <TabLink value={URLS.overlays.ctas} label="CTAs" />
        </StyledTabs>
      </div>
    </TabsWrapper>
  );
};
