import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation DeleteOrganizationMonetization($id: ID!) {
    deleteOrganizationMonetization(id: $id) {
      deletedOrganizationMonetizationId
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  deleteOrganizationMonetization: Nullable<{
    id: string;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteOrganizationMonetization extends UseMutationOptions<ResponseData, Variables> {}

export const useDeleteOrganizationMonetization = (
  options: UseDeleteOrganizationMonetization = {},
) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'deleteOrganizationMonetization.errors',
    refetchQueries: ['GetOrganization'],
    ...options,
  });
};
