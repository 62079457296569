import { DELETE_VIDEO_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  deleteVideo: Nullable<{
    deletedVideoId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteVideoOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useDeleteVideo = (options?: UseDeleteVideoOptions) => {
  return useMutation<ResponseData, Variables>(DELETE_VIDEO_MUTATION, {
    pathToErrors: 'deleteVideo.errors',
    ...options,
  });
};
