import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Spinner } from 'components/common';
import { LabelledCheckbox } from 'components/common/LabelledCheckbox';
import { useSorting } from 'context/sorting';
import { useUpdateShareSocialIcons } from 'hooks/mutation/useUpdateShareSocialIcons';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { platformMap, ShareSocialMediaInput, ShareSocialMediaPlatforms } from 'models';

import * as Styled from 'components/common/Table/Table.styles';
import { Header, IconsWrapper } from './ShareSocialTab.styles';

export const ShareSocialsTab: React.FC = () => {
  const { orderBy } = useSorting<any>('-created');
  const { loading, data } = useOrganizationQuery({
    variables: {
      orderBy,
    },
  });
  const shareSocials = data?.me?.organization?.shareSocialMediaIcons;
  const id = data?.me?.organization?.id;
  const [updateShareSocialIcons] = useUpdateShareSocialIcons();
  const [shareSocialsIcons, setShareSocialsIcons] = useState<ShareSocialMediaInput[]>([]);

  const formatPlatformName = (platform: ShareSocialMediaPlatforms) => {
    return platformMap[platform] || platform;
  };

  const handleSaveIcons = () => {
    const socialsWithoutTypename = shareSocialsIcons.map((item) => ({
      visible: item.visible,
      platformName: item.platformName,
    }));
    updateShareSocialIcons({ variables: { id: id || '', icons: socialsWithoutTypename } });
  };

  useEffect(() => {
    if (shareSocials) {
      setShareSocialsIcons(shareSocials);
    }
  }, [shareSocials]);

  const handleCheckboxChange = (platformName: string) => {
    setShareSocialsIcons(
      (prevSocials) =>
        prevSocials &&
        prevSocials.map((item) =>
          item.platformName === platformName ? { ...item, visible: !item.visible } : item,
        ),
    );
  };

  if (loading && !shareSocialsIcons) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  return (
    <>
      <Header>
        Social sharing icons to display on video overlay (if enabled for embed). Affects all videos
        embedded from this account.{' '}
      </Header>
      <IconsWrapper>
        {shareSocialsIcons?.map((item) => (
          <LabelledCheckbox
            key={item.platformName}
            name={item.platformName}
            label={formatPlatformName(item.platformName)}
            onChange={() => handleCheckboxChange(item.platformName)}
            value={item.visible}
            flex
          />
        ))}
      </IconsWrapper>
      <Button
        style={{ margin: '0px 40px 10px 40px' }}
        color="primary"
        variant="contained"
        onClick={() => handleSaveIcons()}
      >
        Save
      </Button>
    </>
  );
};
