import gql from 'graphql-tag';

import { PAGINATION_PART } from './_pagination';

export const QUERY_CAROUSELS_BY_ID = gql`
  query GetCarousel($id: ID!) {
    carousels(id: $id) {
      nodes {
        id
        name
        description
        created
        apiKey
        title
        publicId
        style {
          mainColor
          colorTheme
        }
        googleAnalyticsTrackingId
        googleAnalytics4MeasurementId
      }
    }
  }
`;

export const CAROUSELS_LIST_QUERY = gql`
  query getCarousels($pageSize: Int!, $page: Int!, $orderBy: String = "") {
    carousels(pageSize: $pageSize, page: $page, orderBy: $orderBy)
      @connection(key: "carousels-pagination") {
      nodes {
        id
        publicId
        created
        name
        title
        apiKey
        momentsCount
      }
      ${PAGINATION_PART}
    }
  }
`;

export const CAROUSEL_MOMENTS_LIST_QUERY = gql`
  query getCarouselMoments($carouselId: Int!, $search: String = "", $pageSize: Int!, $page: Int!) {
    carouselMoments(carouselId: $carouselId, search: $search, pageSize: $pageSize, page: $page) {
      nodes {
        id
        created
        title
        description
        source
        thumbnailUrl
        video {
          id
          title
        }
        inCarousel(carouselId: $carouselId)
      }
      ${PAGINATION_PART}
    }
  }
`;
