import { fromEvent } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export const mouseMoveEventsStreamUntilMouseUp = () =>
  fromEvent<MouseEvent>(document, 'mousemove').pipe(
    map((event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      return event.clientX;
    }),
    takeUntil(fromEvent(document, 'mouseup')),
  );
