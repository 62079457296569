import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, VideoI } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation PublishWidget($id: ID!) {
    publishWidget(id: $id) {
      video {
        id
        status
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  publishWidget: Nullable<{
    video: Nullable<Pick<VideoI, 'id' | 'status'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UsePublishWidgetOptions extends UseMutationOptions<ResponseData, Variables> {}

export const usePublishWidget = (options: UsePublishWidgetOptions = {}) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'publishWidget.errors',
    skipGlobalErrorHandling: true,
    ...options,
  });
};
