import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, LegalAgreements } from 'models';
import {
  authorizationTokenErrorMap,
  AuthorizationTokenErrorType,
  legalAgreementsErrorMap,
  LegalAgreementsErrorType,
  passwordRequirementsErrorMap,
  PasswordRequirementsErrorType,
} from 'utils/errors';
import { Nullable } from 'utils/types';

type Errors =
  | 'ACCOUNT_ALREADY_VERIFIED'
  | AuthorizationTokenErrorType
  | PasswordRequirementsErrorType
  | LegalAgreementsErrorType;

const errorMap: Record<Errors, string> = {
  ACCOUNT_ALREADY_VERIFIED: 'Account with this email has already been created and verified.',
  ...authorizationTokenErrorMap,
  ...passwordRequirementsErrorMap,
  ...legalAgreementsErrorMap,
};

const QUERY = gql`
  mutation VerifyOrganizationMember(
    $legalAgreements: UserLegalAgreementsInput!
    $user: VerifyOrganizationMemberInput!
    $token: String!
  ) {
    verifyOrganizationMemberAccount(legalAgreements: $legalAgreements, user: $user, token: $token) {
      authToken
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  verifyOrganizationMemberAccount: Nullable<{
    authToken: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  legalAgreements: LegalAgreements;
  user: {
    name: string;
    password: string;
  };
  token: string;
}

interface UseVerifyOrganizationMemberQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useVerifyOrganizationMember = (options?: UseVerifyOrganizationMemberQueryOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'verifyOrganizationMemberAccount.errors',
    errorMap,
    skipGlobalErrorHandling: true,
    ...options,
  });
};
