import React from 'react';

import MemoToggle from 'components/common/Table/MemoToggle';

interface LogoPositionToggleProps {
  checked: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const LogoPositionToggle: React.FC<LogoPositionToggleProps> = ({
  checked,
  disabled,
  onClick,
}) => {
  const handleToggleChange = () => {
    if (checked) {
      //  logic for showing logo
    } else {
      // logic for hiding logo
    }
  };

  return (
    <>
      <MemoToggle
        checked={checked}
        onChange={handleToggleChange}
        onClick={onClick}
        disabled={disabled}
        size="small"
      />
    </>
  );
};
