import React, { createContext, useContext, useState } from 'react';

import { YouTubePublishOptions } from 'hooks/mutation';
import { SetState } from 'utils/types';

interface YouTubeOptionsContextValue {
  youtubeOptions: YouTubePublishOptions;
  setCategory: SetState<number | undefined>;
}

const YouTubeOptionsContext = createContext<YouTubeOptionsContextValue | undefined>(undefined);

interface YouTubeOptionsContextProviderProps {
  children?: React.ReactNode;
}

export function YouTubeOptionsContextProvider({ children }: YouTubeOptionsContextProviderProps) {
  const [category, setCategory] = useState<number>();

  return (
    <YouTubeOptionsContext.Provider
      value={{
        youtubeOptions: {
          category,
        },
        setCategory,
      }}
    >
      {children}
    </YouTubeOptionsContext.Provider>
  );
}

export function useYouTubeOptionsContext() {
  const value = useContext(YouTubeOptionsContext);
  if (!value) {
    throw new Error(
      'useYouTubeOptionsContext needs to be used within YouTubeOptionsContextProvider tree',
    );
  }
  return value;
}
