import { Box, Button } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Arrow } from 'assets/icons/arrow-left.svg';
import { Link, Typography } from 'components/common';
import MainLayout from 'components/layouts/MainLayout';
import { useIsUserLoggedIn } from 'hooks/query';
import { URLS } from 'pages/urls';

function MissingMediaPage() {
  const { isLoggedIn } = useIsUserLoggedIn();
  const Wrapper = isLoggedIn ? MainLayout : React.Fragment;
  return (
    <Wrapper>
      <Box width="100%" height="100%" display="flex">
        <Box mx="auto" my="auto" display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h1">404</Typography>
          <Typography variant="body1">This media doesn't exist.</Typography>
          <Box mt="32px">
            <Link to={URLS.root}>
              <Button color="primary" variant="contained">
                <Arrow />
                <Box ml="16px">Back to dashboard</Box>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default MissingMediaPage;
