import gql from 'graphql-tag';
import { uniq } from 'ramda';

import { PublishingTarget, PublishingTargetType } from 'models';

import { ERRORS_PART } from './_errors';

const targetMap: Record<PublishingTargetType, { variables?: string[]; query: string }> = {
  [PublishingTargetType.SelfPublication]: {
    query: `
      ${PublishingTargetType.SelfPublication}: unpublishVideo(id: $videoId) {
        video {
          id
          publications {
            ... on SelfPublicationType {
              id
              publishingTargetId
            }
            __typename
          }
          status
        }
        ${ERRORS_PART}
      }
    `,
  },
  [PublishingTargetType.YouTube]: {
    query: `
      ${PublishingTargetType.YouTube}: unpublishYouTube(id: $videoId) {
        video {
          id
          externalPublications {
            ... on YouTubePublicationType {
              id
            }
            __typename
          }
          status
        }
        ${ERRORS_PART}
      }
    `,
  },
};

export function getVideoUnpublishMutation(targets: PublishingTarget['type'][]) {
  if (targets.length === 0) {
    throw new Error(
      '[VideoUnpublish] At least one platform needs to be selected to generate a mutation',
    );
  }

  const uniqueTargetTypes = uniq(targets);

  let mutationVariables = ['$videoId: ID!'];

  const mutations = uniqueTargetTypes.reduce((body, type) => {
    const { query, variables } = targetMap[type];
    if (variables) {
      mutationVariables = mutationVariables.concat(variables);
    }
    return body + query;
  }, '');

  const variableDeclaration = mutationVariables.join(', ');

  return gql`
    mutation UnpublishVideo(${variableDeclaration}) {
      ${mutations}
    }
  `;
}
