import { Box } from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, RegisterOptions } from 'react-hook-form';

import { BackButton, Typography } from 'components/common';
import { URLS } from 'pages/urls';

import {
  ButtonBackground,
  ButtonBorder,
  ButtonIcon,
  ButtonName,
  ButtonText,
  ButtonUrl,
} from './Inputs';

interface Props {
  control: Control;
  errors: FieldErrors;
  register: (rules?: RegisterOptions) => (ref: HTMLInputElement) => void;
}

export const CTAButtonPageSidebar: React.FC<Props> = ({ control, errors, register }) => {
  return (
    <>
      <BackButton label="Back to CTAs list" link={URLS.overlays.ctas} />
      <Box m="22px 0 32px 0">
        <Typography variant="h1" color="textPrimary">
          Button editor
        </Typography>
      </Box>
      <ButtonName errors={errors} control={control} />
      <ButtonUrl errors={errors} control={control} />
      <ButtonText errors={errors} control={control} />
      <ButtonIcon errors={errors} control={control} />
      <ButtonBackground errors={errors} control={control} />
      <ButtonBorder errors={errors} control={control} />
    </>
  );
};
