import { Box } from '@material-ui/core';
import throttle from 'lodash.throttle';
import React, { useMemo } from 'react';

import { ReactComponent as Remove } from 'assets/icons/x.svg';
import { FormLabel } from 'components/common';
import { InfoTooltip } from 'components/common/InfoTooltip';
import { ImageUploadDropBox } from 'components/common/UploadDropBox';
import { MAX_MICROSITE_FAVICON_RESOLUTION, MAX_MICROSITE_LOGO_RESOLUTION } from 'config/constants';
import { MicrositeLayoutContent } from 'pages/MicroSite/MicrositeLayout';

import { useEditorContext } from '../MicrositeEditorContext';
import { ColorPicker } from './ColorPicker';

import { RemoveThumbnailButton } from 'components/ThumbnailSelect/ThumbnailSelect.styles';
import * as Styled from './SiteBranding.styles';

const SiteBranding: React.VFC = () => {
  const { logoUrl, setLogoUrl, faviconUrl, setFaviconUrl, color, setColor } = useEditorContext();

  const setColorThrottled = useMemo(() => throttle(setColor, 500, { trailing: true }), [setColor]);

  return (
    <MicrositeLayoutContent>
      <Box width="100%">
        <Styled.UploadsWrapper>
          <div>
            <FormLabel>Logo</FormLabel>
            {logoUrl ? (
              <Styled.ImagePreviewWrapper>
                <Styled.ImagePreview src={logoUrl} alt="logo" />
                <RemoveThumbnailButton onClick={() => setLogoUrl(undefined)}>
                  <Remove />
                </RemoveThumbnailButton>
              </Styled.ImagePreviewWrapper>
            ) : (
              <ImageUploadDropBox
                id="logo-upload"
                maxImageDimensions={MAX_MICROSITE_LOGO_RESOLUTION}
                onFileProcessed={(imageBase64) => setLogoUrl(imageBase64)}
              />
            )}
          </div>
          <div>
            <FormLabel>
              Favicon{' '}
              <InfoTooltip content="The favicon is the icon that will display in the users browser tab of the microsite." />
            </FormLabel>

            {faviconUrl ? (
              <Styled.ImagePreviewWrapper>
                <Styled.ImagePreview src={faviconUrl} alt="favicon" />
                <RemoveThumbnailButton onClick={() => setFaviconUrl(undefined)}>
                  <Remove />
                </RemoveThumbnailButton>
              </Styled.ImagePreviewWrapper>
            ) : (
              <ImageUploadDropBox
                id="favicon-upload"
                maxImageDimensions={MAX_MICROSITE_FAVICON_RESOLUTION}
                onFileProcessed={(imageBase64) => setFaviconUrl(imageBase64)}
              />
            )}
          </div>
        </Styled.UploadsWrapper>
        <ColorPicker selectedColor={color} onColorChange={setColorThrottled} />
      </Box>
    </MicrositeLayoutContent>
  );
};

export default SiteBranding;
