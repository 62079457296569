import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextInput } from 'components/common';
import { PlaylistFilterBy } from 'models/Playlist';

import {
  InternalTagDescription,
  TagsSelectionWrapper,
} from 'pages/MicroSite/SectionHeadings/SectionAccordion/SectionAccordion.styles';

export function FilterSection() {
  const { register, control } = useFormContext();

  return (
    <Box>
      <TagsSelectionWrapper>
        <Box mr="30px">
          <TextInput
            placeholder="Add filter..."
            ref={register}
            label="Filters"
            name="filterValue"
            fullWidth
            info="If you select a filter here, only content with that tag or category will display in this section. If you leave
        this field empty, any published content can be displayed."
          />
        </Box>
        <FormControl component="fieldset">
          <Controller
            as={
              <RadioGroup>
                <FormControlLabel
                  value={PlaylistFilterBy.InternalTag}
                  control={<Radio size="small" color="primary" />}
                  label="Categories"
                />
                <FormControlLabel
                  value={PlaylistFilterBy.Tag}
                  control={<Radio size="small" color="primary" />}
                  label="Tags"
                />
              </RadioGroup>
            }
            name="filterBy"
            control={control}
          />
        </FormControl>
      </TagsSelectionWrapper>
      <InternalTagDescription>
        Only videos/moments that have the provided Tag/Category set will be included in this
        playlist. To add Tags or Categories to videos/moments go to their editor.
      </InternalTagDescription>
    </Box>
  );
}
