import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { DashboardWidgetPreset, WidgetStyle } from 'models/Widget';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation UpdateWidgetPreset(
    $id: ID!
    $style: WidgetStyleInput
    $widgetPreset: UpdateWidgetPresetInput!
  ) {
    updateWidgetPreset(id: $id, style: $style, widgetPreset: $widgetPreset) {
      widgetPreset {
        id
        name
        googleAnalyticsTrackingId
        googleAnalytics4MeasurementId
        style {
          mainColor
          colorTheme
        }
        endCard {
          id
          name
        }
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  updateWidgetPreset: Nullable<{
    widgetPreset: Nullable<DashboardWidgetPreset>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  style?: WidgetStyle;
  widgetPreset: {
    name?: string;
    googleAnalyticsTrackingId?: string;
    googleAnalytics4MeasurementId?: string;
    endCardId?: string;
  };
}

interface UseUpdateWidgetPresetMutationOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export function useUpdateWidgetPreset(options?: UseUpdateWidgetPresetMutationOptions) {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'updateWidgetPreset.errors',
    ...options,
  });
}
