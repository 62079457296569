import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const CREATE_BUTTON_CTA_MUTATION = gql`
  mutation CreateButtonCTA($button_cta: CreateButtonCTAInput!) {
    createButtonCta(buttonCta: $button_cta) {
      buttonCta {
        id
        created
        name
        url
        text
        background
        border
        icon
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_BUTTON_CTA_MUTATION = gql`
  mutation UpdateButtonCTA($id: ID!, $button_cta: UpdateButtonCTAInput!) {
    updateButtonCta(id: $id, buttonCta: $button_cta) {
      buttonCta {
        id
        created
        name
        url
        text
        background
        border
        icon
      }
      ${ERRORS_PART}
    }
  }
`;
