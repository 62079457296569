import {
  ExternalPublicationUnion,
  PublicationTypename,
  PublishingTarget,
  PublishingTargetType,
  YouTubePublication,
  YouTubeUploadStatus,
} from 'models';
import {
  extractErrorMessage,
  PublishStatus,
  TargetHandlerEntry,
} from 'pages/PublishVideo/PublishVideo.utils';
import { Optional } from 'utils/types';

import { PlatformHandler } from './YouTube';

export const YouTubeHandler: TargetHandlerEntry = {
  type: PublishingTargetType.YouTube,
  label: 'YouTube',
  Handler: PlatformHandler,
  onPublish: (targetId, data) => {
    const externalPublications =
      data && data[PublishingTargetType.YouTube]?.video?.externalPublications;
    const errorMessage = extractErrorMessage(`${PublishingTargetType.YouTube}.errors`, data);
    return getPublishStatus(externalPublications, errorMessage);
  },
  onPoll: (targetId, data) => {
    return getPublishStatus(data.videoById?.externalPublications);
  },
};

function getPublishStatus(
  externalPublications: Optional<ExternalPublicationUnion[]>,
  errorMessage?: string,
): PublishStatus {
  if (errorMessage) {
    return {
      ok: false,
      errorMessage,
    };
  }

  const publication = externalPublications?.find(
    (publication): publication is YouTubePublication =>
      publication.__typename === PublicationTypename.YouTube,
  );

  if (!publication) {
    return { ok: true, isInProgress: true, shareLink: undefined };
  }

  if (publication.uploadStatus === YouTubeUploadStatus.Failed) {
    return { ok: false, errorMessage: 'Publication has failed.' };
  }

  const isCompleted =
    publication.uploadStatus === YouTubeUploadStatus.Completed && !publication.stale;
  const studioUrl = (isCompleted && publication.studioUrl) || undefined;
  return {
    ok: true,
    isInProgress: !studioUrl,
    shareLink: studioUrl,
  };
}

export const YouTubeTarget: PublishingTarget = {
  id: PublishingTargetType.YouTube,
  name: 'YouTube',
  type: PublishingTargetType.YouTube,
  scheduledPublishDate: '',
};
