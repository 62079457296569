import gql from 'graphql-tag';

import { PAGINATION_PART } from './_pagination';

export const D2C_TRANSCRIPTIONS_FRAGMENT = gql`
  fragment D2CTranscription on D2CTranscriptionType {
    id
    created
    duration
    inputName
    jobName
    status
  }
`;

export const D2C_TRANSCRIPTIONS_LIST = gql`
  ${D2C_TRANSCRIPTIONS_FRAGMENT}

  query GetTranscriptions($pageSize: Int!, $page: Int!, $orderBy: String = "") {
    d2cTranscriptions(pageSize: $pageSize, page: $page, orderBy: $orderBy) {
      nodes {
        ...D2CTranscription
      }
      ${PAGINATION_PART}
    }

    me {
      id
      organization {
        id
        subscription {
          id
          limitsUsage {
            ... on DialogToClipPlanLimitUsageType {
              value
              meteredValue
              usage
              meteredUsage
              limit {
                limit
                value
                period
                meteredLimit {
                  unitsCap
                  unitCost
                  unitCurrency
                }
              }
            }
          }
        }
      }
    }
  }
`;
