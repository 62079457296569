import { DeepMap } from 'react-hook-form';

import { CreateImageCTAInput, ImageCTAResponse } from './types';

export const convertImageDataToServer = (
  formData: CreateImageCTAInput,
  dirtyFields: DeepMap<CreateImageCTAInput, true>,
) => {
  const image_cta: Partial<CreateImageCTAInput> = {};
  for (const property in dirtyFields) {
    image_cta[property as keyof CreateImageCTAInput] = formData[
      property as keyof CreateImageCTAInput
    ] as string;
  }
  return image_cta;
};

export const convertImageDataFromServer = ({ imageUrl, url, ...fields }: ImageCTAResponse) => {
  return { ...fields, imageBase64: imageUrl, url: url || '' } as CreateImageCTAInput;
};
