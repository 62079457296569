import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { PasswordInput } from 'components/common/TextInput';
import { MIN_PASSWORD_LENGTH } from 'config/constants';
import { TEST_ID } from 'config/test-ids';
import {
  UpdateUserPasswordResponse,
  useLogin,
  useLogout,
  useUpdateUserPassword,
} from 'hooks/mutation';
import { useUserQuery } from 'hooks/query';
import { isMinLength, isNotEmptyString, isNotNumericOnly, validate } from 'utils/validation';

interface FormData {
  currentPassword: string;
  newPassword: string;
}

export const PasswordTab = () => {
  const [logout] = useLogout();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const onSuccessfulChange = React.useCallback(() => {
    setIsModalVisible(false);
    logout();
  }, [logout]);

  const { data } = useUserQuery();
  const [login, { loading: loginLoading }] = useLogin();
  const [updateUserPassword, { loading: updateProfileLoading }] = useUpdateUserPassword({
    onCompleted: (data: UpdateUserPasswordResponse) => {
      if (data.updatePassword?.errors.length === 0) {
        setIsModalVisible(true);
      }
    },
  });

  const { register, errors, handleSubmit, setError } = useForm<FormData>({
    mode: 'onSubmit',
  });

  const onSubmit = async (formData: FormData) => {
    const loginResponse = await login({
      variables: {
        email: data!.me.email,
        password: formData.currentPassword,
      },
    });

    if (Boolean(loginResponse.data?.tokenAuth?.token)) {
      updateUserPassword({
        variables: {
          updatePassword: {
            currentPassword: formData.currentPassword,
            password: formData.newPassword,
          },
        },
      });
    } else {
      setError('currentPassword', { message: 'Wrong password' });
      return;
    }
  };

  const isLoading = loginLoading || updateProfileLoading;

  return (
    <>
      <Box component="form" p="40px" onSubmit={handleSubmit(onSubmit)}>
        <Box maxWidth="400px">
          <PasswordInput
            name="currentPassword"
            label="Current Password"
            autoComplete="current-password"
            placeholder="Type in your current password"
            ref={register({
              required: 'Required',
            })}
            errorMessage={errors.currentPassword?.message}
            disabled={isLoading}
            mb="40px"
          />
          <PasswordInput
            name="newPassword"
            label="New Password"
            autoComplete="new-password"
            placeholder="Type in new password"
            ref={register({
              required: 'Required',
              validate: {
                minLength: validate(
                  isMinLength(MIN_PASSWORD_LENGTH),
                  `Minimum ${MIN_PASSWORD_LENGTH} characters required`,
                ),
                whitespace: validate(isNotEmptyString, 'Password cannot be empty'),
                numeric: validate(isNotNumericOnly, 'Password cannot be only numeric'),
              },
            })}
            errorMessage={errors.newPassword?.message}
            disabled={isLoading}
            mb="40px"
          />
        </Box>
        <Box p="30px 0 0 0" display="flex" justifyContent="flex-end">
          <LoadingButton
            data-testid={TEST_ID.savePasswordButton}
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
      <Dialog
        open={isModalVisible}
        onClose={onSuccessfulChange}
        heading="Change Successful"
        width={600}
      >
        <Box p="20px">
          Your password has been changed succesfully.
          <br />
          Log in with new credentials.
        </Box>
        <Button variant="outlined" onClick={onSuccessfulChange}>
          Close
        </Button>
      </Dialog>
    </>
  );
};
