import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { CTAsOrderBy, CTAsResponse } from 'pages/Overlays/CTAsTab/types';
import { Nullable } from 'utils/types';

export const QUERY = gql`
  query GetCTAByType($orderBy: String, $search: String, $typeFilter: String) {
    ctas(orderBy: $orderBy, search: $search, typeFilter: $typeFilter) {
      items(pageSize: 100) {
        nodes {
          id
          name
          created
          type
          usedInCards
          usedInVideos
          publicUuid
        }
      }
    }
  }
`;

interface ResponseData {
  ctas: {
    items: Nullable<CTAsResponse>;
  };
}

interface Variables {
  orderBy?: CTAsOrderBy;
  search: string;
  typeFilter: string;
}

interface UseCTAsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useCTAQuery = (options?: UseCTAsQueryOptions) => {
  return useQuery<ResponseData, Variables>(QUERY, {
    ...options,
  });
};
