import { Box } from '@material-ui/core';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { BackButton, TextInput, Typography } from 'components/common';
import { UploadInput } from 'components/common/UploadInput';
import { URLS } from 'pages/urls';
import { isValidUrl, validate } from 'utils/validation';

import { VALIDATION_FILE_MESSAGE } from './constants';

interface Props {
  control: Control;
  errors: FieldErrors;
  handleFileValidation: (value: File) => Promise<boolean>;
}

export const CTAImagePageSidebar: React.FC<Props> = ({ control, errors, handleFileValidation }) => {
  return (
    <>
      <BackButton label="Back to CTAs list" link={URLS.overlays.ctas} />
      <Box m="22px 0 32px 0">
        <Typography variant="h1" color="textPrimary">
          Images editor
        </Typography>
      </Box>
      <Controller
        name="imageBase64"
        control={control}
        rules={{ required: 'Image cannot be empty', validate: handleFileValidation }}
        render={(field) => (
          <UploadInput
            {...field}
            label="Image *"
            onFileValidation={handleFileValidation}
            error={!!errors.imageBase64}
            errorMessage={errors.imageBase64?.message}
            validationMessage={VALIDATION_FILE_MESSAGE}
            mb="40px"
          />
        )}
      />
      <Controller
        rules={{ required: 'Image name cannot be empty' }}
        render={({ onChange, ...props }) => (
          <TextInput
            {...props}
            onChange={onChange}
            onBlur={({ currentTarget }) => onChange(currentTarget.value.trim())}
            label="Image name *"
            error={!!errors.name}
            errorMessage={errors.name?.message}
            placeholder="Enter image name"
            mb="40px"
            info="Name"
            maxLength={200}
          />
        )}
        name="name"
        control={control}
      />
      <Controller
        rules={{
          validate: validate<string>(
            (value) => value.length === 0 || isValidUrl(value),
            'URL is in incorrect format',
          ),
        }}
        render={({ onChange, ...props }) => (
          <TextInput
            {...props}
            onChange={onChange}
            onBlur={({ currentTarget }) => onChange(currentTarget.value.trim())}
            label="Image URL"
            error={!!errors.url}
            errorMessage={errors.url?.message}
            placeholder="https://"
            mb="40px"
            info="URL"
            maxLength={200}
          />
        )}
        name="url"
        defaultValue=""
        control={control}
      />
    </>
  );
};
