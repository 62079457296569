import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, VideoMonetization, VideoMonetizationInput } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation UpdateVideoMonetization($videoId: ID!, $monetization: UpdateVideoMonetizationInput!) {
    updateVideoMonetization(videoId: $videoId, monetization: $monetization) {
      monetization {
        orgMonetization {
          id
          monetizationUrl
        }
        useDefaultMonetization
        isMonetizationEnabled
        adType
        adDensity
        adLocation
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  updateVideoMonetization: Nullable<{
    monetization: Nullable<VideoMonetization>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  monetization: VideoMonetizationInput;
}

interface UseUpdateVideoMonetizationQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateVideoMonetization = (options?: UseUpdateVideoMonetizationQueryOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'updateVideoMonetization.errors',
    ...options,
  });
};
