import {
  Backdrop,
  Dialog as MaterialDialog,
  DialogProps as MaterialDialogProps,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { ReactComponent as Close } from 'assets/icons/x.svg';

import * as Styled from './Dialog.styles';

export interface DialogProps extends MaterialDialogProps {
  onClose: () => void;
  width?: number;
  heading?: string;
  wrapHeading?: boolean;
}

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: 'none',
    borderRadius: 15,
  },
}));

export function Dialog({
  width,
  heading,
  children,
  open,
  onClose,
  wrapHeading,
  ...dialogProps
}: DialogProps) {
  const classes = useStyles();
  return (
    <MaterialDialog
      classes={classes}
      open={open}
      onClose={onClose}
      closeAfterTransition
      maxWidth={false}
      PaperComponent={undefined}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...dialogProps}
    >
      <Fade in={open}>
        <Styled.Body bodyWidth={width}>
          <Styled.CloseButton onClick={onClose}>
            <Close width="24" height="24" />
          </Styled.CloseButton>
          {heading && <Styled.Heading wrapped={wrapHeading}>{heading}</Styled.Heading>}
          {children}
        </Styled.Body>
      </Fade>
    </MaterialDialog>
  );
}

Dialog.Text = Styled.Text;
