import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { PlaylistI, PlaylistItemType } from 'models/Playlist';
import { Nullable } from 'utils/types';

type Errors = 'WRONG_ORDERING_TYPE' | 'ITEM_NOT_FOUND';

const errorMap: Record<Errors, string> = {
  WRONG_ORDERING_TYPE: 'Videos and moments can only be added to playlists with manual ordering.',
  ITEM_NOT_FOUND: 'This video or moment is not published and cannot be added to the playlist.',
};

const QUERY = gql`
  mutation AddToPlaylist($itemId: ID!, $playlistId: ID!) {
    addToPlaylist(itemId: $itemId, id: $playlistId) {
      playlist {
        id
        items {
          nodes {
            ... on PublicVideoType {
              id
            }
            ... on PublicMomentType {
              id
            }
          }
        }
      }
      ${ERRORS_PART}
    }
  }
`;

type PlaylistPick = Pick<PlaylistI, 'id'> & {
  items: {
    nodes: Nullable<Pick<PlaylistItemType, 'id'>>;
  };
};

interface ResponseData {
  addToPlaylist: Nullable<{
    playlist: Nullable<PlaylistPick>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  /** moment or video id */
  itemId: string;
  playlistId: string;
}

interface UseAddToPlaylistOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useAddToPlaylist = (options: UseAddToPlaylistOptions = {}) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'addToPlaylist.errors',
    errorMap,
    skipGlobalErrorHandling: true,
    ...options,
  });
};
