import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType, OrganizationMemberI, UserRole } from 'models';
import { Nullable } from 'utils/types';

type Errors =
  | 'DUPLICATED_EMAIL_IN_ORGANIZATION'
  | 'ACCOUNT_FOR_EMAIL_EXISTS'
  | 'ORG_OWNER_ROLE_RESERVED';

const errorMap: Record<Errors, string> = {
  DUPLICATED_EMAIL_IN_ORGANIZATION: 'User with this email already exists within your organization.',
  // TODO: remove this temporary error when multiple accounts per email are accepted
  ACCOUNT_FOR_EMAIL_EXISTS:
    'Another verified account with this email already exists, try using email alias.',
  ORG_OWNER_ROLE_RESERVED: 'Your organization can only have one Owner.',
};

const QUERY = gql`
  mutation InviteOrganizationMember($organizationMember: CreateOrganizationMemberInput!) {
    inviteOrganizationMember(organizationMember: $organizationMember) {
      organizationMember {
        email
        id
      }
      ${ERRORS_PART}
    }
  }
`;

type OrganizationMemberPick = Pick<OrganizationMemberI, 'email' | 'id'>;

interface ResponseData {
  inviteOrganizationMember: Nullable<{
    organizationMember: Nullable<OrganizationMemberPick>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  organizationMember: {
    email: string;
    role?: UserRole;
  };
}

interface UseInviteOrganizationMemberQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useInviteOrganizationMember = (options?: UseInviteOrganizationMemberQueryOptions) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'inviteOrganizationMember.errors',
    skipGlobalErrorHandling: true,
    errorMap,
    ...options,
    refetchQueries: ['GetOrganizationMembers'],
  });
};
