import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

interface FormFieldProps {
  disabled?: boolean;
}

export const FormField = styled(Box)<FormFieldProps>`
  background-color: ${({ disabled }) => disabled && '#fafafa'};
  border: 1px solid ${materialTheme.palette.grey[200]};
  border-radius: 10px;
  padding: 20px;

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  &:focus-within {
    border: 1px solid rgb(26, 26, 26);
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  position: relative;
  gap: 10px;
`;

export const TagWrapper = styled.div`
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid white;
  border-radius: 17px;
  display: flex;
  min-height: 34px;
  padding: 4px 6px 4px 18px;
  word-break: break-all;
`;

export const TagButton = styled.button<{ disabled?: boolean }>`
  align-items: center;
  background-color: ${(props) => (props.disabled ? '#555' : '#000')};
  border-radius: 100%;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  height: 26px;
  justify-content: center;
  margin-left: 10px;
  outline: none;
  width: 26px;
  font-size: 12px;
  font-weight: bold;
  padding: 6px;
  color: ${materialTheme.palette.common.white};
`;

export const InputWrapper = styled(TagWrapper)`
  display: inline-flex;
  input {
    padding: 0;
    border-radius: 0;
  }
`;

export const MaxLengthInfo = styled.div`
  color: #999999;
  font-size: 12px;
  margin-top: 8px;
  text-align: right;
`;

export const TitleLabel = styled.span`
  color: #999999;
  font-size: 12px;
`;
