import styled from 'styled-components/macro';

interface MenuProps {
  showSettings: boolean;
}

export const Menu = styled.div<MenuProps>`
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 3px;
  overflow: hidden;
  display: ${({ showSettings }) => (showSettings ? 'block' : 'none')};
  transform: translateX(-50%);
  min-width: 200px;
`;

export const SettingsMenu = styled(Menu)`
  left: unset;
  right: 0px;
  transform: translateX(0);
`;

export const SettingsList = styled.ul`
  display: flex;
  flex-direction: column;
`;

interface SettingsItemProps {
  isSelected: boolean;
}

export const SettingsItem = styled.li<SettingsItemProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;

  .settings-item-icon {
    width: 15px;
    height: 10px;
  }
`;

export const SettingsValue = styled.button`
  color: #fff;
  padding: 10px 0 10px 25px;
  outline: none;
  transition: 0.25s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const SettingsType = styled.span`
  white-space: nowrap;
  flex: 1 0 150px;
  cursor: default;
  user-select: none;
`;

export const SettingsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 15px;
  padding-right: 40px;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  .settings-icon {
    color: #fff;
    transform: rotate(180deg);
  }

  ${({ onClick }) => `
    > * {
      cursor: ${onClick ? 'pointer' : 'default'};
    }
  `}
`;

export const SettingsTitle = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  ${({ onClick }) => `
      cursor: ${onClick ? 'pointer' : 'default'};
  `}
`;
