import { SubscriptionStatus, VideoOrigin, VideoStatus } from 'models';

export const PUBLISHED_STATUSES: VideoStatus[] = [VideoStatus.Edited, VideoStatus.Published];

export const PUBLISH_ALLOWED_STATUSES: VideoStatus[] = [
  VideoStatus.Draft,
  VideoStatus.Edited,
  VideoStatus.Published,
];

export const UNPUBLISH_ALLOWED_STATUSES: VideoStatus[] = [
  VideoStatus.Edited,
  VideoStatus.Published,
];

export const UPLOADING_STATUSES: VideoStatus[] = [VideoStatus.Uploading];

export const OBJECT_MOMENT_MUTATION_ALLOWED_STATUSES: VideoStatus[] = [
  VideoStatus.Draft,
  VideoStatus.Scheduled,
  VideoStatus.Published,
  VideoStatus.Edited,
];

export const VIDEO_METADATA_UPDATE_ALLOWED_STATUSES: { [key in VideoOrigin]: VideoStatus[] } = {
  [VideoOrigin.YouTube]: [
    VideoStatus.Uploaded,
    VideoStatus.Processing,
    VideoStatus.Draft,
    VideoStatus.Scheduled,
    VideoStatus.Published,
    VideoStatus.Edited,
  ],
  [VideoOrigin.Vimeo]: [
    VideoStatus.Uploaded,
    VideoStatus.Processing,
    VideoStatus.Draft,
    VideoStatus.Scheduled,
    VideoStatus.Published,
    VideoStatus.Edited,
  ],
  [VideoOrigin.Remote]: [
    VideoStatus.Uploaded,
    VideoStatus.Processing,
    VideoStatus.Draft,
    VideoStatus.Scheduled,
    VideoStatus.Published,
    VideoStatus.Edited,
  ],
  [VideoOrigin.Direct]: [
    VideoStatus.Created,
    VideoStatus.Uploading,
    VideoStatus.Uploaded,
    VideoStatus.Processing,
    VideoStatus.Draft,
    VideoStatus.Scheduled,
    VideoStatus.Published,
    VideoStatus.Edited,
  ],
};

export const ACTIVE_SUBSCRIPTION_STATUSES: SubscriptionStatus[] = [
  SubscriptionStatus.Premium,
  SubscriptionStatus.Trial,
  SubscriptionStatus.Unlimited,
  SubscriptionStatus.D2C,
  SubscriptionStatus.D2CTrial,
  SubscriptionStatus.D2CUnlimited,
];
