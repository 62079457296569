import { QueryResult } from '@apollo/react-common';
import { QueryHookOptions, useQuery as useApolloQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { AnyObject, Optional } from 'utils/types';

export interface UseQueryOptions<TData = any, TVariables = Record<string, any>>
  extends QueryHookOptions<TData, TVariables> {
  pathToErrors?: string;
  skipGlobalErrorHandling?: boolean;
  refetchOnVariablesChange?: boolean;
}

/**
 * Wrapper for Apollo's `useQuery` that is able to refetch on variables change.
 *
 * Be careful when setting `fetchPolicy` to anything that includes network, because it may
 * fetch twice. You can disable `refetchOnVariablesChange` in such cases.
 *
 * It uses global error handling by default. You can opt out of this behavior using
 * `skipGlobalErrorHandling: true` option.
 */
export function useQuery<TData = any, TVariables = Record<string, any>>(
  query: DocumentNode,
  {
    pathToErrors,
    skipGlobalErrorHandling = false,
    refetchOnVariablesChange = true,
    skip,
    ...queryOptions
  }: UseQueryOptions<TData, TVariables> = {},
): QueryResult<TData> {
  const results = useApolloQuery(query, {
    ...queryOptions,
    skip,
    // Needed for `loading` status to be updated by `refetch()`.
    // See https://github.com/apollographql/react-apollo/issues/321#issuecomment-301192825
    notifyOnNetworkStatusChange: true,
    context: {
      pathToErrors,
      isErrorHandledLocally: skipGlobalErrorHandling,
      ...queryOptions.context,
    },
  });

  const { variables } = queryOptions;

  useDeepCompareEffect(
    () => {
      if (refetchOnVariablesChange && !skip) {
        results?.refetch(variables);
      }
    },
    refetchOnVariablesChange ? [variables, skip] : undefined,
  );

  return results;
}

/**
 * Useful when using response data in next request, as API schema doesn't usually allow for
 * including __typename which is automatically added by Apollo Client
 */
// TODO: add DeepOmit<Object, '__typename'>
export function stripTypename<Object extends AnyObject>(obj?: Optional<Object>): Object {
  return obj
    ? JSON.parse(
        JSON.stringify(obj, (key, value) => {
          if (key === '__typename') {
            return undefined;
          }
          return value;
        }),
      )
    : undefined;
}
