import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Permission } from 'config/permissions';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { URLS } from 'pages/urls';

import { CreateMicrositePage } from './CreateMicrositePage';
import { DashboardMicrositePage } from './DashboardMicrositePage';
import { EditMicrositePage } from './EditMicrositePage';
import { MicrositeCreated } from './MicrositeCreated';

export const MicrositePage: React.VFC = () => {
  const { hasPermissions } = useUserPermissions();
  const canEditMicrosite = hasPermissions([Permission.ChangeMicrosite]);
  const canCreateMicrosite = hasPermissions([Permission.CreateMicrosite]);

  return (
    <Switch>
      <Route path={URLS.microsite.root} exact>
        <DashboardMicrositePage />
      </Route>
      {canCreateMicrosite && (
        <Route path={URLS.microsite.newMicrosite} exact>
          <CreateMicrositePage />
        </Route>
      )}
      {canEditMicrosite && (
        <Route path={URLS.microsite.editMicrosite} exact>
          <EditMicrositePage />
        </Route>
      )}
      <Route path={URLS.microsite.micrositeCreated} exact>
        <MicrositeCreated />
      </Route>
    </Switch>
  );
};
