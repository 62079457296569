import {
  Tabs as MaterialTabs,
  TabsActions,
  TabsProps as MaterialTabsProps,
} from '@material-ui/core';
import React from 'react';

import { useFontState } from 'hooks/useFontState';

interface TabsProps extends Omit<MaterialTabsProps, 'action'> {}

const Tabs: React.FC<TabsProps> = ({ children, ...props }) => {
  const tabsActions = React.useRef<TabsActions | null>(null);
  const isFontReady = useFontState();

  React.useLayoutEffect(() => {
    if (tabsActions.current && isFontReady) {
      tabsActions.current.updateIndicator();
    }
  }, [isFontReady]);

  return (
    <MaterialTabs {...props} action={tabsActions}>
      {children}
    </MaterialTabs>
  );
};

export default Tabs;
