import React from 'react';

import Select from 'components/common/Select';
import { UserRole } from 'models';

import { formatUserRole } from './MemberTable.utils';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (role: UserRole) => void;
  disabled?: boolean;
}

export const RoleDropdown = React.forwardRef<any, Props>(function RoleDropdown(
  { id, name, value, onChange, disabled },
  ref,
) {
  return (
    <Select
      innerRef={ref}
      id={id}
      name={name}
      label="Role"
      options={options}
      value={value}
      onChange={({ currentTarget }) => {
        onChange(currentTarget.value as UserRole);
      }}
      disabled={disabled}
    />
  );
});

const validOptions = [
  { role: UserRole.OrgOwner, disabled: true },
  { role: UserRole.OrgAdmin },
  { role: UserRole.Curator },
  { role: UserRole.VideoEditor },
];
const options = validOptions.map(({ role, disabled }) => ({
  value: role,
  label: formatUserRole(role),
  disabled,
}));
