import { loadStripe, Stripe } from '@stripe/stripe-js';
import React from 'react';

import { env } from 'config/env';

import { useSafeState } from './useSafeState';

const STRIPE_PUBLISHABLE_KEY = env.SEEEN_APP_STRIPE_PUBLISHABLE_KEY;

export function useStripe() {
  const stripeRef = React.useRef<Stripe | null>(null);
  const [isLoading, setIsLoading] = useSafeState(false);

  React.useEffect(() => {
    if (!STRIPE_PUBLISHABLE_KEY || stripeRef.current) return;

    (async () => {
      setIsLoading(true);
      stripeRef.current = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      setIsLoading(false);
    })();
  }, [setIsLoading]);

  return [stripeRef.current, isLoading] as const;
}
