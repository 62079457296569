import gql from 'graphql-tag';

import { PAGINATION_PART } from './_pagination';
import { THUMBNAILS_PART } from './thumbnails';

export const OBJECT_METADATA_FRAGMENT = gql`
  fragment ObjectMetadata on ObjectAnnotationType {
    created
    category
    id
    timeRange
    title
    ${THUMBNAILS_PART}
    source
    url
    video {
      id
    }
    visible
    promote
  }
`;

export const QUERY_VIDEO_OBJECT = gql`
  ${OBJECT_METADATA_FRAGMENT}

  query GetObject($id: ID!) {
    objectById(id: $id) {
      ...ObjectMetadata
      video {
        duration
        videoFileUrl
        status
      }
    }
  }
`;

export const QUERY_OBJECTS = gql`
  ${OBJECT_METADATA_FRAGMENT}

  query GetObjects(
    $title: String
    $videoId: ID
    $search: String
    $page: Int
    $pageSize: Int
    $orderBy: String = ""
  ) {
    objects(
      title: $title
      videoId: $videoId
      search: $search
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
    ) {
      nodes {
        ...ObjectMetadata
      }
      ${PAGINATION_PART}
    }
  }
`;
