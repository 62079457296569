import { Box, Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Select, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import ThumbnailSelect from 'components/ThumbnailSelect';
import { MAX_THUMBNAILS_PER_OBJECT } from 'config/constants';
import { Permission } from 'config/permissions';
import { pushMessage } from 'context/globalStream';
import {
  useCreateObjectThumbnail,
  useDeleteObjectThumbnail,
  useUpdateObject,
} from 'hooks/mutation';
import { useObjectThumbnailsQuery } from 'hooks/query/useThumbnailsQuery';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { pushMaxThumbnailCountError } from 'utils/thumbnails';
import { isNotEmptyString, isValidUrl, validate } from 'utils/validation';
import { isEmptyString } from 'utils/validation';

import { EditObjectFormProps, EditObjectModalProps } from '../types';
import { objectCategories } from './CreateObject';

export const EditObjectModal: React.VFC<EditObjectModalProps> = ({ onClose, isOpen, object }) => {
  const { register, handleSubmit, errors, control } = useForm<EditObjectFormProps>({
    mode: 'onChange',
  });

  const [updateObject, { loading }] = useUpdateObject({
    onCompleted: () => {
      onClose();
    },
    videoId: object.video.id,
  });

  const { thumbnails } = useObjectThumbnailsQuery({ variables: { objectId: object.id } });
  const [createThumbnailMutation] = useCreateObjectThumbnail(object.id);
  const [selectedThumbnailId, setSelectedThumbnailId] = React.useState(object.thumbnailId);

  const handleSave = useCallback(
    ({ url, title, category }: EditObjectFormProps) => {
      updateObject({
        variables: {
          objectId: object.id,
          objectAnnotation: {
            url: isEmptyString(url) ? undefined : url.trim(),
            title: title.trim(),
            category,
            thumbnailId: selectedThumbnailId ?? undefined,
          },
        },
      });
    },
    [updateObject, object.id, selectedThumbnailId],
  );

  const addNewThumbnail = React.useCallback(
    async (imageBase64: string) => {
      if (thumbnails.length + 1 > MAX_THUMBNAILS_PER_OBJECT) {
        pushMaxThumbnailCountError(MAX_THUMBNAILS_PER_OBJECT);
        return;
      }

      const { data } = await createThumbnailMutation({
        variables: { objectId: object.id, imageBase64 },
      });

      if (data?.createObjectAnnotationThumbnail?.thumbnail) {
        setSelectedThumbnailId(data.createObjectAnnotationThumbnail.thumbnail.id);
        pushMessage({ message: 'Thumbnail successfully uploaded' });
      }
    },
    [createThumbnailMutation, object.id, thumbnails.length],
  );

  const [deleteThumbnailMutation] = useDeleteObjectThumbnail(object.id);
  const removeThumbnail = React.useCallback(
    async (id: string) => {
      const { data } = await deleteThumbnailMutation({
        variables: { id },
      });

      if (data?.deleteObjectAnnotationThumbnail?.deletedObjectAnnotationThumbnailId) {
        pushMessage({ message: 'Thumbnail successfully deleted' });
      }
    },
    [deleteThumbnailMutation],
  );

  const { hasPermissions } = useUserPermissions();
  const canChangeMetadata = hasPermissions([Permission.ChangeObject]);

  return (
    <Dialog onClose={onClose} open={isOpen} width={600} heading="Edit Object">
      <form
        onSubmit={handleSubmit(handleSave)}
        style={{ width: '80%', height: '100%', margin: '0 auto' }}
      >
        <Box mb="32px">
          <ThumbnailSelect
            thumbnails={thumbnails}
            selectedId={selectedThumbnailId}
            onSelect={setSelectedThumbnailId}
            onThumbnailAdded={addNewThumbnail}
            onRemove={removeThumbnail}
          />
        </Box>
        <TextInput
          label="URL"
          name="url"
          placeholder="Write a url to your object…"
          defaultValue={object.url}
          ref={register({
            required: false,
            validate: validate<string>(
              (value) => value.length === 0 || isValidUrl(value),
              'URL is in incorrect format',
            ),
          })}
          error={!!errors.url}
          errorMessage={errors.url?.message}
          readOnly={!canChangeMetadata}
        />
        <TextInput
          label="Title (required)"
          name="title"
          placeholder="Write a title for your object…"
          defaultValue={object.title}
          ref={register({
            validate: validate(isNotEmptyString, 'Title cannot be empty'),
          })}
          error={!!errors.title}
          errorMessage={errors.title?.message}
          readOnly={!canChangeMetadata}
        />
        <SelectWrapper>
          <Controller
            render={({ onChange, value, name, ref }) => {
              return (
                <Select
                  innerRef={ref}
                  id="object-type"
                  label="Object type"
                  options={objectCategories}
                  value={value}
                  onChange={({ currentTarget }) => {
                    onChange(currentTarget.value);
                  }}
                />
              );
            }}
            defaultValue={object.category}
            name="category"
            control={control}
            disabled={!canChangeMetadata}
          />
        </SelectWrapper>
        <Box mt="25px">
          <Button variant="contained" disabled={!!loading} color="primary" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};

const SelectWrapper = styled.div`
  label {
    text-align: left;
  }
`;
