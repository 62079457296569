import { Button, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Delete } from 'assets/icons/trash.svg';
import { DeleteModal } from 'components/common';
import { useSafeState } from 'hooks/useSafeState';

import { LoadingAction } from './LoadingAction';

type Type = React.ComponentProps<typeof DeleteModal>['type'];

interface Props {
  type: Type;
  onDelete: () => Promise<any>;
  renderAsButton?: boolean;
  customModalTitle?: string;
  customModalDescription?: string;
  edge?: 'start' | 'end';
}

export const DeleteAction: React.FC<Props> = ({
  type,
  onDelete,
  renderAsButton,
  customModalTitle,
  customModalDescription,
  edge,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const [isLoading, setIsLoading] = useSafeState(false);
  const handleDelete = React.useCallback(async () => {
    setIsLoading(true);
    await onDelete();
    setIsLoading(false);
  }, [onDelete, setIsLoading]);

  return (
    <>
      {isLoading ? (
        <LoadingAction />
      ) : renderAsButton ? (
        <Button variant="outlined" color="secondary" onClick={showModal}>
          Remove {type}
        </Button>
      ) : (
        <Tooltip title="Delete" arrow placement="top">
          <IconButton color="inherit" edge={edge} onClick={showModal}>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
      <DeleteModal
        type={type}
        count={1}
        open={isModalVisible}
        handleClose={hideModal}
        handleDelete={handleDelete}
        customTitle={customModalTitle}
        customDescription={customModalDescription}
      />
    </>
  );
};
