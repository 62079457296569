import { DUPLICATE_VIDEO_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  duplicateVideo: Nullable<{
    duplicate: Nullable<{
      id: string;
    }>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDuplicateVideoOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useDuplicateVideo = (options?: UseDuplicateVideoOptions) => {
  return useMutation<ResponseData, Variables>(DUPLICATE_VIDEO_MUTATION, {
    pathToErrors: 'duplicateVideo.errors',
    ...options,
  });
};
