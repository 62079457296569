import { VIDEO_UPLOAD_LOCAL } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType, ProcessingPipelineType, VideoI } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  uploadLocalVideo: Nullable<{
    videoUploadUrl: Nullable<string>;
    formFieldsData: Nullable<string>;
    video: Nullable<Pick<VideoI, 'id'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  filename: string;
  multipart?: boolean;
  pipelineType: ProcessingPipelineType;
}

export const useUploadLocalVideo = () => {
  return useMutation<ResponseData, Variables>(VIDEO_UPLOAD_LOCAL, {
    pathToErrors: 'uploadLocalVideo.errors',
    refetchQueries: ['GetVideos'],
  });
};
