import React, { RefObject } from 'react';

type EventListener<TEvent extends Event> = (event: TEvent) => void;

/**
 * @source Inspired by https://github.com/donavon/use-event-listener with few corrections.
 */
export function useEvent<K extends keyof HTMLElementEventMap>(
  element: RefObject<HTMLElement | undefined | null>,
  event: K,
  handler: EventListener<HTMLElementEventMap[K]>,
): void;
export function useEvent<K extends keyof DocumentEventMap>(
  element: RefObject<Document>,
  event: K,
  handler: EventListener<DocumentEventMap[K]>,
): void;
export function useEvent<K extends keyof WindowEventMap>(
  element: RefObject<Window>,
  event: K,
  handler: EventListener<WindowEventMap[K]>,
): void;
export function useEvent(
  element: RefObject<HTMLElement | Window | Document | undefined | null>,
  event: string,
  handler: EventListenerOrEventListenerObject,
): void;
export function useEvent<K extends keyof (HTMLElementEventMap & DocumentEventMap & WindowEventMap)>(
  element: RefObject<HTMLElement | Document | Window | undefined | null>,
  event: K,
  handler: (event: (HTMLElementEventMap & DocumentEventMap & WindowEventMap)[K]) => void,
): void;

export function useEvent(
  element: RefObject<Element | Window | Document | undefined | null>,
  event: string,
  handler: EventListenerOrEventListenerObject,
) {
  // attach and remove listeners
  React.useEffect(() => {
    const domElement = element.current;
    if (!domElement) {
      return;
    }

    domElement.addEventListener(event, handler);
    return () => {
      domElement.removeEventListener(event, handler);
    };
  }, [element, event, handler]);
}
