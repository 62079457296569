import { Box, Button, Typography } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { last } from 'ramda';
import React, { useLayoutEffect, useRef, useState } from 'react';

import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
import { Search } from 'components/common';
import {
  TranscriptionTokenType,
  VideoTranscription,
  VideoTranscriptionSegment,
} from 'models/VideoTranscription';
import { useMomentsEditorContext } from 'pages/Moments/context';
import { noop } from 'utils/common';
import { timeRangeToString } from 'utils/time';

import { Columns, Stack } from '../Wrapper/Layout';
import { useInfiniteSegmentScroll } from './useInfiniteSegmentScroll';

import * as Styled from './Transcription.styles';

interface Props {
  allowMomentCreation?: boolean;
  onCreateMomentClick?: (start: number, end: number) => void;
  transcription: VideoTranscription;
  segments: VideoTranscriptionSegment[];
  loadPage: (page: number) => void;
  seekTo: (ms: number) => void;
  onSearch: (searchText: string) => void;
}

export function TranscriptionSidebar({
  allowMomentCreation = false,
  onCreateMomentClick = noop,
  transcription,
  segments,
  loadPage,
  seekTo,
  onSearch,
}: Props) {
  const { bottomRef } = useInfiniteSegmentScroll({
    pageInfo: transcription.segments.pageInfo,
    loadPage,
  });

  const listRef = useRef<HTMLDivElement>(null);

  const [selectedTokenRange, setSelectedTokenRange] = useState<[number, number]>();
  const [lastSelectedSegmentId, setLastSelectedSegmentId] = useState<string>();

  const { setIsTranscription } = useMomentsEditorContext();

  useLayoutEffect(() => {
    const list = listRef.current;
    if (!list || !allowMomentCreation) return;

    const listener = debounce(
      () => {
        const elements = Array.from(list.querySelectorAll<HTMLElement>('[data-token="true"]'));
        const selection = window.getSelection();

        if (selection?.toString().trim() === '') return;

        const foundTokens = elements.filter((element) => {
          return selection?.containsNode(element, true);
        });

        const startTimestamp = foundTokens[0]?.dataset.start;
        const lastToken = last(foundTokens);
        const endTimestamp = lastToken?.dataset.end;

        if (typeof startTimestamp !== 'undefined' && typeof endTimestamp !== 'undefined') {
          setSelectedTokenRange([Number(startTimestamp), Number(endTimestamp)]);
          setLastSelectedSegmentId(lastToken?.dataset.segment);
        }
      },
      300,
      { trailing: true },
    );

    document.addEventListener('selectionchange', listener);

    return () => {
      document.removeEventListener('selectionchange', listener);
    };
  }, [allowMomentCreation]);

  const handleTokenClick: React.MouseEventHandler = (event) => {
    if (!event.target) return;
    event.preventDefault();

    const target = event.target as HTMLElement;
    if (target.dataset.token === 'true') {
      const startTimestamp = target.dataset.start;
      if (startTimestamp !== undefined) {
        seekTo(Number(startTimestamp));
      }
    }
  };

  return (
    <Stack position="relative">
      <Stack rowGap="12px">
        <Typography variant="h1">Transcription</Typography>
        {allowMomentCreation ? (
          <Columns display="flex" alignItems="center" columnGap="8px">
            <Box flexShrink="0">
              <InfoCircle />
            </Box>
            <span>You can highlight the text to create a moment</span>
          </Columns>
        ) : null}
        <Search placeholder="Search" iconPlacement="left" onSearch={onSearch} />
      </Stack>
      <div
        // We have an empty div, because material-ui's Box cannot have refs (fixed in v5 major)
        // https://github.com/mui-org/material-ui/issues/17010
        ref={listRef}
      >
        <Stack component="ul" rowGap="10px" onClick={handleTokenClick}>
          {segments.map((segment) => {
            return (
              <Segment
                key={segment.id}
                segment={segment}
                selectedTokenRange={selectedTokenRange}
                areActionsVisible={lastSelectedSegmentId === segment.id}
                onCreateMomentClick={(...args) => {
                  setSelectedTokenRange(undefined);
                  setLastSelectedSegmentId(undefined);
                  setIsTranscription(true);
                  onCreateMomentClick(...args);
                }}
              />
            );
          })}
        </Stack>
      </div>
      <Styled.InfiniteScrollMark aria-hidden="true" ref={bottomRef} />
    </Stack>
  );
}

interface SegmentProps {
  segment: VideoTranscriptionSegment;
  areActionsVisible: boolean;
  selectedTokenRange?: [number, number];
  onCreateMomentClick: (start: number, end: number) => void;
}

function Segment({
  segment,
  areActionsVisible,
  selectedTokenRange,
  onCreateMomentClick,
}: SegmentProps) {
  const text = segment.tokens.map(
    ({ id, token, tokenType, startTimestamp, endTimestamp }, index) => {
      const isPronunciation = tokenType === TranscriptionTokenType.Pronunciation;
      const nextToken = segment.tokens[index + 1];
      const isLastToken = nextToken === undefined;
      const isNextTokenPunctuation = nextToken?.tokenType === TranscriptionTokenType.Punctuation;

      const space = isLastToken ? '' : isNextTokenPunctuation ? '' : ' ';
      return (
        <Styled.Token
          key={id}
          data-token={isPronunciation ? 'true' : undefined}
          data-start={startTimestamp}
          data-end={endTimestamp}
          data-segment={segment.id}
          title="Click to seek"
        >
          {token}
          {space}
        </Styled.Token>
      );
    },
  );

  const timeRange = timeRangeToString([segment.startTimestamp, segment.endTimestamp], {
    separator: '-',
    skipMilliseconds: true,
  });

  return (
    <Styled.SegmentItem isHighlighted={segment.isSearchMatching}>
      <Styled.SegmentWrapper>
        <Styled.SpeakerLabelWrapper>
          <Styled.SpeakerLabel>{segment.speakerLabel}</Styled.SpeakerLabel>{' '}
          <span>({timeRange})</span>
        </Styled.SpeakerLabelWrapper>
        <p>{text}</p>
      </Styled.SegmentWrapper>
      {areActionsVisible && selectedTokenRange ? (
        <Styled.MomentActions>
          <Styled.SelectedLabel>
            {timeRangeToString(selectedTokenRange, { separator: '-' })}
          </Styled.SelectedLabel>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onCreateMomentClick(selectedTokenRange[0], selectedTokenRange[1])}
          >
            Create Moment
          </Button>
        </Styled.MomentActions>
      ) : null}
    </Styled.SegmentItem>
  );
}
