import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';

import { FormLabel, Toggle } from 'components/common';
import CustomColor from 'components/common/CustomColor';
import { CustomSelect } from 'components/common/CustomSelect';
import { validateColor } from 'utils/validation';

import { getAllIconOptions } from '../helpers';

import { FormField } from 'components/common/TagsField/TagsField.styles';

interface Props {
  control: Control;
  errors: FieldErrors;
}

export const ButtonIcon: React.FC<Props> = ({ control, errors }) => {
  const hasIcon = useWatch({ control, name: 'hasIcon' });
  const iconOptions = useMemo(() => getAllIconOptions(), []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mb={hasIcon ? '0' : '40px'}
      >
        <FormLabel>Button Icon</FormLabel>
        <Controller
          name="hasIcon"
          render={({ value, onChange, ...field }) => {
            return (
              <Toggle
                {...field}
                checked={value}
                size="small"
                onChange={(event, val) => {
                  onChange(val);
                }}
              />
            );
          }}
          control={control}
        />
      </Box>
      {hasIcon ? (
        <FormField mb="40px">
          <Controller
            render={(field) => (
              <CustomSelect
                {...field}
                id="button-icon"
                options={iconOptions}
                label="Icon"
                mb="40px"
              />
            )}
            name="iconValue"
            control={control}
          />
          <Controller
            render={({ value, onChange }) => (
              <CustomColor
                onColorChange={onChange}
                selectedColor={value}
                label="Icon color"
                mb="18px"
                error={!!errors.iconColor}
                errorMessage={errors.iconColor?.message}
              />
            )}
            name="iconColor"
            rules={{ validate: validateColor }}
            control={control}
            defaultValue="#FFFFFF"
          />
        </FormField>
      ) : null}
    </>
  );
};
