import React from 'react';

import { ReactComponent as Check } from 'assets/icons/check.svg';

import * as Styled from './Navigation.styles';

export enum FlowStep {
  Details,
  Complete,
}

interface NavItem {
  step: FlowStep;
  label: string;
}

export const NAV_ITEMS: NavItem[] = [
  { step: FlowStep.Details, label: 'Site details' },
  { step: FlowStep.Complete, label: 'Complete' },
];

interface CheckmarkProps {
  done?: boolean;
  active?: boolean;
}

const Checkmark: React.VFC<CheckmarkProps> = ({ done }) => (
  <Styled.Checkmark>{done && <Check />}</Styled.Checkmark>
);

interface NavigationProps {
  currentStep: FlowStep;
}

export const Navigation: React.VFC<NavigationProps> = ({ currentStep }) => {
  const currentIndex = getCurrentStepIndex(currentStep);
  return (
    <Styled.Wrapper>
      {NAV_ITEMS.map(({ label, step }, index) => {
        return (
          <Styled.Item active={index === currentIndex} key={step}>
            <Checkmark done={index < currentIndex || currentStep === FlowStep.Complete} />
            {label}
          </Styled.Item>
        );
      })}
    </Styled.Wrapper>
  );
};

export function getCurrentStepIndex(currentStep: FlowStep) {
  return NAV_ITEMS.findIndex((item) => item.step === currentStep);
}
