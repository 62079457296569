import gql from 'graphql-tag';
import { uniqBy } from 'ramda';

import { PublishingTarget, PublishingTargetType } from 'models';

import { ERRORS_PART } from './_errors';

const targetMap: Record<PublishingTargetType, { variables?: string[]; query: string }> = {
  [PublishingTargetType.SelfPublication]: {
    variables: ['$targetIds: [ID!]!, $scheduledPublishDate: DateTime'],
    query: `
      ${PublishingTargetType.SelfPublication}: publishVideo(
        id: $videoId,
        publishingTargetIds: $targetIds
        scheduledPublishDate: $scheduledPublishDate
      ) {
        video {
          id
          scheduledDatetime
          publications {
            ... on SelfPublicationType {
              id
              publicUrl
              publishingTargetId
              stale
              __typename
            }
          }
          status
          videoFileUrl
        }
        ${ERRORS_PART}
      }
    `,
  },
  [PublishingTargetType.YouTube]: {
    variables: ['$youtubeOptions: YouTubePublishInput'],
    query: `
      ${PublishingTargetType.YouTube}: publishYouTube(
        id: $videoId,
        options: $youtubeOptions
      ) {
        video {
          id
          scheduledDatetime
          externalPublications {
            ... on YouTubePublicationType {
              id
              publicUrl
              stale
              studioUrl
              uploadStatus
            }
            __typename
          }
          status
        }
        ${ERRORS_PART}
      }
    `,
  },
};

export function getVideoPublishMutation(targets: PublishingTarget[]) {
  if (targets.length === 0) {
    throw new Error(
      '[VideoPublish] At least one platform needs to be selected to generate a mutation',
    );
  }

  const uniqueTargetTypes = uniqBy(({ type }) => type, targets);

  let mutationVariables = ['$videoId: ID!'];

  const mutations = uniqueTargetTypes.reduce((body, { type }) => {
    const { query, variables } = targetMap[type];
    if (variables) {
      mutationVariables = mutationVariables.concat(variables);
    }
    return body + query;
  }, '');

  const variableDeclaration = mutationVariables.join(', ');

  return gql`
    mutation PublishVideo(${variableDeclaration}) {
      ${mutations}
    }
  `;
}
