import { ApolloClient, MutationOptions } from 'apollo-client';

import {
  getCancelScheduledPublishMutation,
  getVideoPublishMutation,
  getVideoUnpublishMutation,
  QUERY_VIDEO_PUBLICATIONS,
  QUERY_VIDEO_STATUS,
} from 'context/queries';
import {
  ErrorType,
  ExternalPublicationUnion,
  PublishingTarget,
  PublishingTargetType,
  SelfPublication,
  VideoI,
} from 'models';
import { Nullable } from 'utils/types';

// Publishing

export interface PublishVideoResponseData {
  [PublishingTargetType.SelfPublication]?: {
    video: Nullable<Pick<VideoI, 'id' | 'publications' | 'status' | 'videoFileUrl'>>;
    errors: ErrorType[];
  };
  [PublishingTargetType.YouTube]?: {
    video: Nullable<Pick<VideoI, 'id' | 'externalPublications' | 'status'>>;
    errors: ErrorType[];
  };
}

export interface YouTubePublishOptions {
  private?: boolean;
  /** Category integer */
  category?: number;
  /** ISO 8601 DateTime */
  publishAt?: string;
}

interface PublishVariables {
  videoId: string;
  targetIds?: string[];
  youtubeOptions?: YouTubePublishOptions;
  scheduledPublishDate?: string;
}

export const publishVideo = (
  client: ApolloClient<any>,
  targets: PublishingTarget[],
  options: Omit<MutationOptions<PublishVideoResponseData, PublishVariables>, 'mutation'>,
) => {
  return client.mutate<PublishVideoResponseData, PublishVariables>({
    ...options,
    mutation: getVideoPublishMutation(targets),
  });
};

// Unpublishing

export interface UnpublishVideoResponseData {
  [PublishingTargetType.SelfPublication]?: {
    video: Nullable<
      Pick<VideoI, 'id' | 'status'> & {
        publications: Pick<SelfPublication, 'id' | 'publishingTargetId' | '__typename'>[];
      }
    >;
    errors: ErrorType[];
  };
  [PublishingTargetType.YouTube]?: {
    video: Nullable<
      Pick<VideoI, 'id' | 'status'> & {
        externalPublications: Pick<ExternalPublicationUnion, 'id' | '__typename'>[];
      }
    >;
    errors: ErrorType[];
  };
}

interface UnpublishVariables {
  videoId: string;
}

export const unpublishVideo = (
  client: ApolloClient<any>,
  targets: PublishingTarget['type'][],
  variables: UnpublishVariables,
) => {
  return client.mutate<UnpublishVideoResponseData, UnpublishVariables>({
    mutation: getVideoUnpublishMutation(targets),
    variables,
    awaitRefetchQueries: true,
    refetchQueries: [
      // Update publications cache to remove unpublished targets.
      { query: QUERY_VIDEO_PUBLICATIONS, variables: { videoId: variables.videoId } },
    ],
  });
};

export interface cancelScheduledVideoPublishResponseData {
  [PublishingTargetType.SelfPublication]?: {
    video: Nullable<Pick<VideoI, 'id' | 'status'>>;
    errors: ErrorType[];
  };
  [PublishingTargetType.YouTube]?: {
    video: Nullable<Pick<VideoI, 'id' | 'status'>>;
    errors: ErrorType[];
  };
}

interface cancelScheduledPublishVariables {
  videoId: string;
}

export const cancelScheduledVideoPublish = (
  client: ApolloClient<any>,
  targets: PublishingTarget['type'][],
  variables: cancelScheduledPublishVariables,
) => {
  return client.mutate<cancelScheduledVideoPublishResponseData, cancelScheduledPublishVariables>({
    mutation: getCancelScheduledPublishMutation(targets),
    variables,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY_VIDEO_STATUS, variables: { id: variables.videoId } }],
  });
};
