import { Box, Button } from '@material-ui/core';
import React from 'react';

import { FormLabel, Link } from 'components/common';
import { Badge } from 'components/common/Badge';
import { VideoMonetization } from 'models';
import { generateVideoMonetizationLink } from 'pages/urls';
import { Nullable } from 'utils/types';

interface Props {
  videoId: string;
  monetization: Nullable<VideoMonetization>;
}

export const MonetizationButton = ({ videoId, monetization }: Props) => {
  const isActive = monetization?.isMonetizationEnabled;
  const activeBadgeProps = { color: '#038247', backgroundColor: '#E1FEF1' };
  const inactiveBadgeProps = { backgroundColor: '#EEEEEE' };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel>Monetization</FormLabel>
        <Box mb="8px">
          <Badge size="small" {...(isActive ? activeBadgeProps : inactiveBadgeProps)}>
            {isActive ? 'Active' : 'Inactive'}
          </Badge>
        </Box>
      </Box>

      <Button
        component={Link}
        to={generateVideoMonetizationLink(videoId)}
        variant="contained"
        color="secondary"
      >
        Edit
      </Button>
    </Box>
  );
};
