export const LocalStorage = {
  set: (key: string, value: any) => {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      console.warn(`Value "${value}" can't be saved in local store on "${key}" key`);
    }
  },
  get: (key: string) => {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      console.warn(`Value on "${key}" key can't be read`);
    }
  },
  getJSON: (key: string, defaultValue?: string) => {
    try {
      const value = window.localStorage.getItem(key);
      if (!value) return defaultValue;
      return JSON.parse(value);
    } catch (error) {
      return defaultValue;
    }
  },
  remove: (key: string) => {
    try {
      return window.localStorage.removeItem(key);
    } catch (e) {
      console.warn(`Value on "${key}" key can't be removed`);
    }
  },
};
