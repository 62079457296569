import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation } from 'hooks/useMutation';
import { CreatePaymentSessionInput, ErrorType, PaymentSessionI } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'ORGANIZATION_ALREADY_HAS_PREMIUM_SUBSCRIPTION';

const errorMap: Record<Errors, string> = {
  ORGANIZATION_ALREADY_HAS_PREMIUM_SUBSCRIPTION:
    'Your organization already has a premium subscription',
};

const QUERY = gql`
  mutation CreatePaymentSession($paymentSession: CreatePaymentSessionInput!) {
    createPaymentSession(paymentSession: $paymentSession) {
      paymentSession {
        id
        stripePaymentSessionId
      }
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  createPaymentSession: Nullable<{
    paymentSession: Nullable<Pick<PaymentSessionI, 'id' | 'stripePaymentSessionId'>>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  paymentSession: CreatePaymentSessionInput;
}

export const useCreatePaymentSession = () => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'createPaymentSession.errors',
    errorMap,
  });
};
