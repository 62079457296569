import React from 'react';
import { useForm } from 'react-hook-form';

import { CTAItemType } from 'pages/Overlays/CTAsTab/types';
import { Nullable } from 'utils/types';

import { CTATimestampCtasPosition } from '../type';
import { LeftInputs } from './LeftInputs';
import { TopInputs } from './TopInputs';

interface Props {
  position: CTATimestampCtasPosition;
  ctaSize: { width: number; height: number };
  videoSize: { width: number; height: number };
  type: Nullable<CTAItemType>;
  updateCtaPosition: (position: Partial<CTATimestampCtasPosition>) => void;
}

export const Inputs: React.FC<Props> = ({
  position,
  ctaSize,
  videoSize,
  type,
  updateCtaPosition,
}) => {
  const { control, setValue } = useForm();

  const isButton = type === CTAItemType.Button;

  return (
    <>
      <TopInputs
        position={position}
        isButton={isButton}
        control={control}
        setValue={setValue}
        updateCtaPosition={updateCtaPosition}
        ctaSizeHeight={ctaSize.height}
        videoSizeHeight={videoSize.height}
      />
      <LeftInputs
        position={position}
        isButton={isButton}
        control={control}
        setValue={setValue}
        updateCtaPosition={updateCtaPosition}
        ctaSizeWidth={ctaSize.width}
        videoSizeWidth={videoSize.width}
      />
    </>
  );
};
