import { UPDATE_ACCOUNT_DETAILS } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

export interface UpdateAccountDetailsResponse {
  updateAccountDetails: Nullable<{
    user: {
      name: string;
      avatarUrl: Nullable<string>;
      email: string;
    };
    errors: ErrorType[];
  }>;
}

interface Variables {
  accountDetails: {
    name?: string;
    avatarBase64?: string;
  };
}

interface UseUpdateAccountDetailsOptions
  extends UseMutationOptions<UpdateAccountDetailsResponse, Variables> {}

export const useUpdateAccountDetails = (options: UseUpdateAccountDetailsOptions = {}) => {
  return useMutation<UpdateAccountDetailsResponse, Variables>(UPDATE_ACCOUNT_DETAILS, {
    pathToErrors: 'updateAccountDetails.errors',
    ...options,
  });
};
