import React from 'react';

import { Dialog } from 'components/common/Dialog';

import { CreatePreset, FormData } from './CreatePreset';
import { useCreateWidgetPreset } from './useCreateWidgetPreset';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export function CreatePresetModal({ onClose, isOpen }: Props) {
  const [createWidgetPreset, { loading: isSubmitting }] = useCreateWidgetPreset({
    refetchQueries: ['GetWidgetPresets'],
  });

  const onSubmit = async (formData: FormData) => {
    const name = formData.name.trim();
    const result = await createWidgetPreset({
      variables: {
        widgetPreset: {
          name,
          googleAnalyticsTrackingId: formData.googleAnalyticsTrackingId.trim(),
          googleAnalytics4MeasurementId: formData.googleAnalytics4MeasurementId.trim(),
          endCardId: formData.endCardId,
        },
        style: {
          colorTheme: formData.colorTheme,
          mainColor: formData.mainColor,
        },
      },
    });

    if (result.data?.createWidgetPreset?.widgetPreset?.name === name) {
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open={isOpen} heading="Create Preset" width={800}>
      <CreatePreset onSubmit={onSubmit} isSubmitting={isSubmitting} />
    </Dialog>
  );
}
