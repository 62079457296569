import { UPDATE_USER_PASSWORD } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

export interface UpdateUserPasswordResponse {
  updatePassword: Nullable<{
    user: {
      currentPassword: string;
      password: Nullable<string>;
    };
    errors: ErrorType[];
  }>;
}

interface Variables {
  updatePassword: {
    currentPassword: string;
    password: Nullable<string>;
  };
}

interface UseUpdateUserPassword extends UseMutationOptions<UpdateUserPasswordResponse, Variables> {}

export const useUpdateUserPassword = (options: UseUpdateUserPassword = {}) => {
  return useMutation<UpdateUserPasswordResponse, Variables>(UPDATE_USER_PASSWORD, {
    pathToErrors: 'updatePassword.errors',
    ...options,
  });
};
