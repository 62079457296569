import styled from 'styled-components/macro';

export const CarouselContainer = styled.div`
  width: 100%;
  display: flex; /* improves compatibility */

  .slick-list {
    overflow: visible;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const CarouselContent = styled.div`
  font-family: 'Montserrat', sans-serif;
  -webkit-mask-image: linear-gradient(
    270deg,
    transparent 0%,
    black 20%,
    black 88%,
    transparent 100%
  );
`;

export const CarouselSlider = styled.div`
  width: 82%;
  margin-left: 9%;

  @media screen and (max-width: 455px) {
    margin-left: 20px;
  }
`;

export const Slide = styled.div`
  padding: 0 10px;
  position: relative;
  width: 100%;
  display: inline-block;
  cursor: pointer;
`;

export const PlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Image = styled.img`
  border-radius: 7px;
  max-height: 201px;
  width: 100%;
`;

export const ImageSvg = styled.svg`
  border-radius: 15px;
  max-height: 201px;
  width: 100%;
`;

export const Thumbnail = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; // 16/9 aspect ratio
  width: 100%;
`;

interface SlideTitleProps {
  fontWeight?: number;
  fontName?: string;
  fontSize?: string;
}

export const SlideTitle = styled.h5<SlideTitleProps>`
  line-height: 20px;
  word-wrap: break-word;
  overflow: hidden;
  text-align: center;
  margin: 10px 0;

  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : `normal`)};
  font-family: ${(props) =>
    props.fontName ? `'${props.fontName}', 'Montserrat', sans-serif` : `inherit`};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `12px`)};
`;

interface CarouselArrowProps {
  selectedColor: string;
  isDisabled: boolean;
}

export const CarouselArrow = styled.button<CarouselArrowProps>`
  border-radius: 8px;
  z-index: 5;
  color: #fff !important;
  position: absolute;
  border: none;
  width: 34px;
  height: 34px;
  display: flex;
  cursor: pointer;
  top: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ selectedColor }) => selectedColor};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};

  ::before {
    content: none;
  }

  :focus {
    outline: none;
  }

  .prev-arrow-icon *,
  .next-arrow-icon * {
    display: inline;
  }
`;

export const CarouselNextArrow = styled(CarouselArrow)`
  right: 0;
  transform: translateY(-100%) rotate(-180deg);
`;

export const CarouselPrevArrow = styled(CarouselArrow)`
  left: 0;
  transform: translateY(-100%);
`;
