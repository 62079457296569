import { Box, Button, CircularProgress, Tooltip } from '@material-ui/core';
import React from 'react';

import { TextInput } from 'components/common';
import { Stack } from 'components/common/Wrapper/Layout';
import { PublishingTarget, PublishingTargetType, VideoStatus } from 'models';
import { URLS } from 'pages/urls';
import { isFutureDate } from 'utils/date';

import { PublishTargetHandler, VideoPick } from './PublishVideo.utils';

import * as Styled from './PublishVideo.styles';

interface SelectPlatformsProps {
  video: VideoPick;
  targetHandlers: PublishTargetHandler[];
  isSubmitting: boolean;
  onSubmit: (selectedTargets: PublishingTarget[]) => void;
  isSubmitDisabled?: boolean;
  submitButtonLabel: React.ReactNode;
}

export const SelectTargets: React.FC<SelectPlatformsProps> = ({
  video,
  targetHandlers,
  isSubmitting,
  onSubmit,
  isSubmitDisabled: isSubmitDisabledFromParent,
  submitButtonLabel,
  children,
}) => {
  const [selectedTargetIds, setSelectedTargetIds] = React.useState(
    targetHandlers.filter(({ initiallyChecked }) => initiallyChecked).map(({ id }) => id),
  );
  const [scheduledPublishDate, setScheduledPublishDate] = React.useState('');

  const connotBeScheduled: boolean =
    video.status === VideoStatus.Published || video.status === VideoStatus.Edited;

  const handleSelect = (event: React.SyntheticEvent<HTMLElement>) => {
    const platformId = event.currentTarget.dataset.platform as PublishingTargetType;
    setSelectedTargetIds((platforms) => {
      if (platforms.includes(platformId)) {
        return platforms.filter((id) => id !== platformId);
      }
      return platforms.concat(platformId);
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const targets = targetHandlers
      .filter(({ id }) => selectedTargetIds.includes(id))
      .map(
        ({ id, label, type }): PublishingTarget => {
          return {
            id,
            name: label,
            type,
            scheduledPublishDate: isFutureDate(scheduledPublishDate) ? scheduledPublishDate : '',
          };
        },
      );
    onSubmit(targets);
  };

  const [validationMessages, setValidationMessage] = React.useState<ValidationMessages>({});
  const [isValid, tooltipContent, tooltipProps] = useValidationTooltip(validationMessages);
  const handleValidationMessage = (id: string) => (message?: string) => {
    setValidationMessage((messages) => {
      return { ...messages, [id]: message };
    });
  };

  const isPublishDisabled =
    isSubmitting || selectedTargetIds.length === 0 || !isValid || isSubmitDisabledFromParent;

  return (
    <form onSubmit={handleSubmit}>
      <Styled.Center>
        <Stack rowGap={30}>
          {children}
          <Styled.PlatformList>
            {targetHandlers.map(({ id, label, isLocked, Handler }) => {
              return (
                <Handler
                  key={id}
                  id={id}
                  video={video}
                  label={label}
                  isLocked={isLocked}
                  isSelected={selectedTargetIds.includes(id)}
                  onSelect={handleSelect}
                  setValidationMessage={handleValidationMessage(id)}
                />
              );
            })}
            <Styled.Platform>
              <Box my="-12px" p="21px"></Box>
              <Styled.DetailsWrapper>
                <Stack rowGap={10}>
                  <Styled.PlatformName>Microsite</Styled.PlatformName>
                  <Styled.PlatformDetails>
                    <Styled.ButtonLink to={URLS.microsite.root}>Create Microsite</Styled.ButtonLink>
                  </Styled.PlatformDetails>
                </Stack>
              </Styled.DetailsWrapper>
            </Styled.Platform>
            <TextInput
              name="scheduledPublishDate"
              type={`${connotBeScheduled ? 'text' : 'datetime-local'}`}
              label="Schedule publish date"
              disabled={connotBeScheduled}
              placeholder="Published video cannot be scheduled"
              info={
                'Example: "12/31/2222 12:00 PM". If is provided past date or the input stays unset, the video will be published immediately.'
              }
              infoPlacement="left"
              value={scheduledPublishDate}
              onChange={(dateTime) => setScheduledPublishDate(dateTime)}
            />
          </Styled.PlatformList>
        </Stack>
      </Styled.Center>
      <Styled.ActionWrapper>
        <Tooltip title={tooltipContent} arrow placement="top" {...tooltipProps}>
          <span>
            {/* tooltip won't work on disabled button without a wrapper */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isPublishDisabled}
              endIcon={isSubmitting && <CircularProgress color="secondary" size={20} />}
            >
              {submitButtonLabel}
            </Button>
          </span>
        </Tooltip>
      </Styled.ActionWrapper>
    </form>
  );
};

type ValidationMessages = Record<string, string | undefined>;

function useValidationTooltip(messages: ValidationMessages) {
  const content = (
    <div>
      {Object.entries(messages).map(([key, message]) => {
        return (
          <p key={key} style={{ margin: '2px 0' }}>
            {message}
          </p>
        );
      })}
    </div>
  );
  const isTooltipDisabled = Object.values(messages).filter(Boolean).length === 0;
  const props: Partial<React.ComponentPropsWithoutRef<typeof Tooltip>> = {
    disableFocusListener: isTooltipDisabled,
    disableHoverListener: isTooltipDisabled,
    disableTouchListener: isTooltipDisabled,
  };
  return [isTooltipDisabled, content, props] as const;
}
