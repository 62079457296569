import styled from 'styled-components/macro';

interface CurrentTimestampProps {
  x: number;
}

export const CurrentTimestamp = styled.div.attrs<CurrentTimestampProps>((props) => ({
  style: {
    transform: `translateX(${props.x}px)`,
  },
}))<CurrentTimestampProps>`
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 4px;
  color: #f00223;
  background: #f00223;
  cursor: ew-resize;
  z-index: 999;
  border-radius: 22px;

  ::after {
    position: absolute;
    content: '';
    left: 50%;
    top: -3px;
    transform: translateX(-50%);
    background-color: #fff;
    border: 4px solid #f00223;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
`;
