import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'components/common';
import { Columns } from 'components/common/Wrapper/Layout';
import MainLayout from 'components/layouts/MainLayout';
import { Permission } from 'config/permissions';
import { useCreateCarousel } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { generateCarouselLink, URLS } from 'pages/urls';
import { history } from 'utils/history';

import Carousels from './Carousels';
import CreateCarouselModal from './CreateCarouselModal';
import { FormData } from './CreateCarouselModal/CreateCarouselModal';
import CreateCarouselModalButton from './CreateCarouselModalButton';

function Widget() {
  const [showUCreateCarouselModal, setShowCreateCarouselModal] = useState(false);

  const [createCarousel] = useCreateCarousel();

  const handleCarouselSave = React.useCallback(
    async ({ name }: FormData) => {
      const response = await createCarousel({
        variables: {
          carousel: {
            name: name.trim(),
          },
        },
      });
      if (response?.data?.createCarousel?.carousel) {
        setShowCreateCarouselModal(false);
        history.push(generateCarouselLink(response.data.createCarousel.carousel.id));
      }
    },
    [createCarousel],
  );

  const { hasPermissions } = useUserPermissions();
  const canUserCreateCarousel = hasPermissions([Permission.CreateCarousel]);

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">List of widgets</Typography>
          <Columns display="flex">
            <Button variant="outlined" color="secondary" component={Link} to={URLS.widget.presets}>
              Presets
            </Button>
            {canUserCreateCarousel && (
              <CreateCarouselModalButton handle={() => setShowCreateCarouselModal(true)} />
            )}
          </Columns>
        </Box>
        <Box bgcolor="common.white" boxShadow={5} borderRadius="15px" mt="40px" overflow="hidden">
          <Carousels />
        </Box>
      </Box>
      {canUserCreateCarousel && (
        <CreateCarouselModal
          open={showUCreateCarouselModal}
          handleClose={() => setShowCreateCarouselModal(false)}
          onSave={handleCarouselSave}
        />
      )}
    </MainLayout>
  );
}

export default Widget;
