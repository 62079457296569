import { Typography, TypographyProps as MaterialTypographyProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

interface TypographyProps extends MaterialTypographyProps {
  fontSize?: string;
}

export default styled(({ fontSize, ...otherProps }) => (
  <Typography {...otherProps} />
))<TypographyProps>`
  font-size: ${(props) => props.fontSize};
`;
