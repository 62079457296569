import { createGlobalStyle } from 'styled-components/macro';

import { CONDENSED_FONT, TEXT_FONT } from 'utils/styling';

import { materialTheme } from './materialTheme';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    box-sizing: border-box;
  }

  body {
    min-height: 100%;
    background-color: ${materialTheme.palette.background.default};
    color: ${materialTheme.palette.text.primary};
    font-family: ${TEXT_FONT};
  }

  p, h1, h2, h3, h4, input, label {
    margin: 0;
  }

  h1, h2, h3, label {
    font-family: ${CONDENSED_FONT};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  button {
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  button:not(:disabled), [role="button"]:not(:disabled) {
    cursor: pointer;
  }

  img {
    display: block;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  strong, b {
    font-weight: 700;
  }
`;
