import { useEffect, useRef } from 'react';

const DEFAULT_POLLING_INTERVAL = 5_000; // ms

interface UsePollingOptions {
  isEnabled: boolean;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  interval?: number;
}

export function usePolling({
  isEnabled,
  startPolling,
  stopPolling,
  interval = DEFAULT_POLLING_INTERVAL,
}: UsePollingOptions) {
  const isPolling = useRef(false);

  useEffect(() => {
    if (isEnabled) {
      startPolling(interval);
      isPolling.current = true;
    } else if (isPolling.current) {
      stopPolling();
    }

    return () => {
      if (isPolling.current) {
        stopPolling();
      }
    };
  }, [isEnabled, interval, startPolling, stopPolling]);
}
