import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Typography } from 'components/common';
import { StyledTabs, TabsWrapper } from 'components/common/Tab/Tab';
import { TabLink, useTabsWithRouter } from 'components/common/TabLink';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { Permission } from 'config/permissions';
import { useTangleMainBar } from 'context/mainBar';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import MomentsTab from 'pages/Dashboard/MomentsTab';
import UploadButton from 'pages/Dashboard/UploadButton/UploadButton';
import UploadVideoModal from 'pages/Dashboard/UploadVideoModal';
import VideosTab from 'pages/Dashboard/VideosTab';
import YoutubeVideoModal from 'pages/Dashboard/YoutubeVideoModal';
import { URLS } from 'pages/urls';

const { videos, moments } = URLS.dashboard;

function Dashboard() {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  const tabValue = useTabsWithRouter([videos, moments], videos);

  const { hasPermissions } = useUserPermissions();
  const canUploadVideo = hasPermissions([Permission.UploadVideo]);

  useTangleMainBar(false);

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">Videos and Moments</Typography>
          {canUploadVideo && (
            <UploadButton
              handleUploadSelect={() => setShowUploadModal(true)}
              handleYoutubeSelect={() => setShowYoutubeModal(true)}
            />
          )}
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <TabsWrapper px="32px">
            <StyledTabs value={tabValue}>
              <TabLink value={videos} label="Videos" />
              <TabLink value={moments} label="Moments" />
            </StyledTabs>
          </TabsWrapper>
          <Switch>
            <Route path={[URLS.root, URLS.dashboard.root, videos]} exact>
              <VideosTab />
            </Route>
            <Route path={moments}>
              <MomentsTab />
            </Route>
          </Switch>
        </BigCard>
      </Box>
      {canUploadVideo && (
        <>
          <UploadVideoModal open={showUploadModal} onClose={() => setShowUploadModal(false)} />
          <YoutubeVideoModal open={showYoutubeModal} onClose={() => setShowYoutubeModal(false)} />
        </>
      )}
    </MainLayout>
  );
}

export default Dashboard;
