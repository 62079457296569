import gql from 'graphql-tag';

import { PAGINATION_PART } from 'context/queries/_pagination';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import {
  OrganizationMemberI,
  OrganizationMembersOrderBy,
  PageInfo,
  PaginationVariables,
  UserRole,
} from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetOrganizationMembers(
    $id: ID
    $isActive: Boolean
    $isVerified: Boolean
    $role: Role
    $search: String
    $orderBy: String
    $page: Int
    $pageSize: Int
  ) {
    organizationMembers(
      id: $id
      isActive: $isActive
      isVerified: $isVerified
      role: $role
      search: $search
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
    ) {
      nodes {
        avatarUrl
        dateJoined
        email
        id
        isActive
        isVerified
        name
        role
      }
      ${PAGINATION_PART}
    }
  }
`;

export type OrganizationMemberPick = Pick<
  OrganizationMemberI,
  'avatarUrl' | 'dateJoined' | 'email' | 'id' | 'isActive' | 'isVerified' | 'name' | 'role'
>;

interface ResponseData {
  organizationMembers: Nullable<{
    nodes: OrganizationMemberPick[];
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends PaginationVariables {
  id?: string;
  isActive?: boolean;
  isVerified?: boolean;
  role?: UserRole;
  search?: string;
  orderBy?: OrganizationMembersOrderBy;
}

interface UseOrganizationMembersQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useOrganizationMembers = (options?: UseOrganizationMembersQueryOptions) => {
  return useQuery<ResponseData, Variables>(QUERY, options);
};
