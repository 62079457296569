import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { TagTypes, VideoStatus } from 'models';
import { LocalStorage } from 'utils/storage';
import { SetState } from 'utils/types';

import * as Styled from './CopyPasteTags.styles';

interface CopyPasteTagsProps {
  tags: string[];
  videoStatus?: string;
  tagsType: string;
  canUserUpdate?: boolean;
  setTags: SetState<string[]>;
  type: TagTypes;
}

export const CopyPasteTags: React.FC<CopyPasteTagsProps> = ({
  tags,
  videoStatus,
  tagsType,
  canUserUpdate,
  setTags,
  type,
}) => {
  const [hasUniqueTagsInStorage, setHasUniqueTagsInStorage] = useState(false);
  const [areTagsCopied, setAreTagsCopied] = useState(false);

  const checkHasUniqueTags = useCallback(() => {
    setAreTagsCopied(false);

    const tagsFromStorage = LocalStorage.getJSON(tagsType);
    if (tagsFromStorage?.length) {
      const theSameTags = tagsFromStorage.every((tag: string) => tags.includes(tag));
      setHasUniqueTagsInStorage(!theSameTags);
    }
  }, [tagsType, tags]);

  useEffect(() => {
    checkHasUniqueTags();
    window.addEventListener('storage', checkHasUniqueTags);

    return () => window.removeEventListener('storage', checkHasUniqueTags);
  }, [checkHasUniqueTags]);

  const handleCopyTags = () => {
    if (tags.length) {
      LocalStorage.set(tagsType, JSON.stringify(tags));
      setAreTagsCopied(true);
    }
  };

  const handlePasteTags = () => {
    const tagsToPaste = LocalStorage.getJSON(tagsType);
    setHasUniqueTagsInStorage(false);
    if (tagsToPaste?.length) {
      const uniqueTags = tagsToPaste.filter((tag: string) => !tags.includes(tag));
      if (uniqueTags.length) {
        uniqueTags.forEach((tag: string) => setTags((oldTags) => oldTags.concat(tag)));
      }
    }
  };

  const dataType = type === TagTypes.Internal ? 'categories' : 'tags';

  return canUserUpdate ? (
    <>
      {tags.length ? (
        <Styled.CopyPasteTags onClick={handleCopyTags} disabled={areTagsCopied}>
          {areTagsCopied ? `Copied ${dataType}` : `Copy ${dataType}`}
          <Styled.CopyPasteTagsIcon>
            <Copy className="copy-tag-icon" />
          </Styled.CopyPasteTagsIcon>
        </Styled.CopyPasteTags>
      ) : null}
      {hasUniqueTagsInStorage && videoStatus !== VideoStatus.Failed ? (
        <Styled.CopyPasteTags onClick={handlePasteTags}>
          Paste {dataType}
          <Styled.CopyPasteTagsIcon>
            <Copy className="copy-tag-icon" />
          </Styled.CopyPasteTagsIcon>
        </Styled.CopyPasteTags>
      ) : null}
    </>
  ) : null;
};
