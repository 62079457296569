import React from 'react';

import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { useCreateVideoCTAs } from 'hooks/mutation/useCreateVideoCTA';
import { useUpdateVideoCTAs } from 'hooks/mutation/useUpdateVideoCTA';
import { VideoDetailsNode } from 'models';

import { convertDataFromServer, convertDataToServer } from './helpers';
import { CTATimestamp } from './type';
import { useTimestampContext, VideoCTAPageContext } from './useTimestampContext';
import { VideoCTAPageMain } from './VideoCTAPageMain';
import { VideoCTAPageSidebar } from './VideoCTAPageSidebar';

interface Props {
  video: VideoDetailsNode;
}

export const VideoCTAPageView: React.FC<Props> = ({ video }) => {
  const value = useTimestampContext(convertDataFromServer(video.ctaTimestamps));

  const [createVideoCTAs] = useCreateVideoCTAs({ refetchQueries: ['GetVideo'] });
  const [updateVideoCTAs] = useUpdateVideoCTAs({ refetchQueries: ['GetVideo'] });

  const onSubmit = async (timestamps: CTATimestamp[]) => {
    if (!!video.ctaTimestamps.length) {
      await updateVideoCTAs({
        variables: {
          videoId: video.id,
          video_ctas: { timestamps: convertDataToServer(timestamps) },
        },
      });
    } else {
      await createVideoCTAs({
        variables: {
          videoId: video.id,
          video_ctas: { timestamps: convertDataToServer(timestamps) },
        },
      });
    }
  };

  return (
    <VideoCTAPageContext.Provider value={{ ...value, duration: video.duration }}>
      <ExtensibleSidebar
        sideContent={<VideoCTAPageSidebar videoId={video.id} />}
        mainContent={<VideoCTAPageMain video={video} onSubmit={onSubmit} />}
      />
    </VideoCTAPageContext.Provider>
  );
};
