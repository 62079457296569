import { Box, Button } from '@material-ui/core';

import { Dialog } from 'components/common/Dialog';

import { ModalProps } from './types';

import { ModalDescription } from './MonetizationModal.styles';

interface Props {
  heading: string;
  description: string | React.ReactNode;
}

export const InformationalModal: React.VFC<ModalProps & Props> = ({
  isOpen,
  onClose,
  heading,
  description,
}) => {
  return (
    <Dialog onClose={onClose} open={isOpen} heading={heading} width={680}>
      <ModalDescription>{description}</ModalDescription>
      <Box display="flex" flexDirection="row" justifyContent="center" mt="50px">
        <Button variant="contained" onClick={onClose} color="primary">
          Close
        </Button>
      </Box>
    </Dialog>
  );
};
