import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import styled from 'styled-components/macro';

import { SnackbarProvider } from 'components/Snackbar';
import { OrganizationLogoPreviewProvider } from 'context/OrgLogoPositionContext';
import { ChildrenProps } from 'models';
import { history } from 'utils/history';

import ErrorBoundary from './ErrorBoundary';
import { GlobalStyle } from './GlobalStyle';
import GraphqlProvider from './GraphqlProvider';
import { materialTheme } from './materialTheme';
import { UppyProvider } from './UppyProvider';

const ChildrenContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

const AppProviders = ({ children }: ChildrenProps) => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <OrganizationLogoPreviewProvider>
          <Router history={history}>
            <GraphqlProvider>
              <UppyProvider>
                <GlobalStyle />
                <ThemeProvider theme={materialTheme}>
                  <CssBaseline />
                  <SnackbarProvider>
                    <ChildrenContainer>{children}</ChildrenContainer>
                  </SnackbarProvider>
                </ThemeProvider>
              </UppyProvider>
            </GraphqlProvider>
          </Router>
        </OrganizationLogoPreviewProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default AppProviders;
