import { Box, Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { Spinner, Typography } from 'components/common';
import { useSearch } from 'components/common/Search/Search';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { Permission } from 'config/permissions';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { PlaylistsOrderBy } from 'models/Playlist';
import { URLS } from 'pages/urls';

import { PlaylistTable } from './PlaylistTable';
import { usePlaylists } from './usePlaylists';

import * as Styled from 'components/common/Table/Table.styles';

export function PlaylistsPage() {
  const { hasPermissions } = useUserPermissions();
  const canCreatePlaylist = hasPermissions([Permission.CreatePlaylist]);

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">Playlists</Typography>
          {canCreatePlaylist ? (
            <Button variant="contained" color="primary" component={Link} to={URLS.playlists.new}>
              Create playlist
            </Button>
          ) : null}
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <PlaylistListComponent />
        </BigCard>
      </Box>
    </MainLayout>
  );
}

function PlaylistListComponent() {
  const [pagination, dispatch] = React.useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.Playlists),
  );
  const { orderBy, sorting, setSorting } = useSorting<PlaylistsOrderBy>('-created');
  const [searchText, setSearchText] = useSearch('');

  const { loading, data } = usePlaylists({
    variables: {
      ...pagination,
      orderBy,
      search: searchText,
    },
  });

  // initial loading
  if (loading && !data?.playlists?.nodes) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (data?.playlists?.nodes) {
    const { nodes, totalCount, pageInfo } = data.playlists;

    return (
      <PlaylistTable
        playlistsLoading={loading} // subsequent loading
        playlists={nodes}
        totalCount={totalCount}
        pageInfo={pageInfo}
        dispatch={dispatch}
        sorting={sorting}
        setSorting={setSorting}
        onSearch={setSearchText}
      />
    );
  }

  return <div>Unexpected error</div>;
}
