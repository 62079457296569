import { Button as MaterialButton } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { TEXT_INPUT_PADDING } from './TimePanel.styles';

const Button = styled(MaterialButton)`
  && {
    height: 30px;
    border-radius: 4px;
    min-width: 0;
    padding: 0 ${TEXT_INPUT_PADDING}px;
    border: 1px solid #858492;
    font-size: 12px;
    font-weight: inherit;
    color: inherit;
  }

  .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 8px;
  }
`;

interface OwnProps {
  label?: string;
  icon?: React.ReactElement;
  onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export function TimelineEditorAction({ label, icon, onClick }: OwnProps) {
  return (
    <Button startIcon={icon} onClick={onClick}>
      {label}
    </Button>
  );
}
