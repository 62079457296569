import { Button, IconButton, Tooltip } from '@material-ui/core';
import React, { useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Remove } from 'assets/icons/trash.svg';
import { Link, Search, Spinner } from 'components/common';
import { DropdownButton } from 'components/common/DropdownButton';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import { Columns } from 'components/common/Wrapper/Layout';
import PaginationFooter from 'components/PaginationFooter';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { Table } from 'components/Table';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useDelteEndCard } from 'hooks/mutation/useDeleteEndCard';
import { useEndCardsQuery } from 'hooks/query';
import useElementSize from 'hooks/useElementSize';
import { EndCardResponse, EndCardsOrderBy, EndCardType } from 'pages/EndCard/types';
import { ActionContentWidthHandler } from 'pages/Organization/types';
import { generateCustomCodeEndCardEditLink, generateEndCardEditLink } from 'pages/urls';
import { formatDate } from 'utils/date';

import { EndCardLinks, EndCardTypes } from './constants';
import { EndCardEmptyData } from './EndCardEmptyData';

import * as Styled from 'components/common/Table/Table.styles';

export const EndCardsTab: React.FC<ActionContentWidthHandler> = ({ getActionContentWidth }) => {
  const { orderBy, sorting, setSorting } = useSorting<EndCardsOrderBy>('-created');
  const [searchEndCard, setSearchEndCard] = useState('');
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.EndCards),
  );

  const [deleteEndCard] = useDelteEndCard();

  const { elementRef } = useElementSize(getActionContentWidth);

  const { loading, data } = useEndCardsQuery({
    variables: {
      ...pagination,
      orderBy,
    },
  });

  const { pageInfo, totalCount, nodes: endCards = [] } = data?.endCards || {};

  const { pathname } = useLocation();
  const currentURL = {
    destinationURL: pathname,
    backButtonPlaceholder: 'end cards list',
  };

  const onSearch = (val: string) => {
    setSearchEndCard(val.toLocaleLowerCase());
  };

  const filterEndCards = (endCard: EndCardResponse) =>
    endCard.name.toLocaleLowerCase().startsWith(searchEndCard);

  const getEndCardEditLink = (endCard: EndCardResponse) =>
    endCard.origin === EndCardType.CUSTOM_JS
      ? generateCustomCodeEndCardEditLink(endCard.id)
      : generateEndCardEditLink(endCard.id);

  const isCustomEndCard = (endCard: EndCardResponse) => endCard.cardType === 'CUSTOM';

  const handleRemoveClick = (id: string) => () => {
    deleteEndCard({
      variables: {
        id,
      },
    });
  };

  if (loading) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (!endCards.length) {
    return <EndCardEmptyData />;
  }

  return (
    <Styled.TableWrapper>
      <LoadingOverlay isVisible={loading} />
      <Table>
        <Table.Head>
          <Table.HeadCell width="50%">
            <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="name">
              End card name
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="15%">
            <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="origin">
              Type
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="15%">
            <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="videosCount">
              Videos
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="15%">
            <SortInterface
              activeSorting={sorting}
              setSorting={setSorting}
              columnName="momentsCount"
            >
              Moments
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="30%">
            <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
              Created
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell>Edit</Table.HeadCell>
          <Table.HeadCell>Remove</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {endCards.filter(filterEndCards).map((endCard) => (
            <Table.Row key={endCard.id}>
              <Table.Cell>
                {isCustomEndCard(endCard) ? (
                  <Link
                    to={{
                      pathname: getEndCardEditLink(endCard),
                      state: currentURL,
                    }}
                  >
                    <strong>{endCard.name}</strong>
                  </Link>
                ) : (
                  <strong>{endCard.name}</strong>
                )}
              </Table.Cell>
              <Table.Cell>{!!endCard.origin ? EndCardTypes[endCard.origin] : 'Legacy'}</Table.Cell>
              <Table.Cell>{endCard.videosCount}</Table.Cell>
              <Table.Cell>{endCard.momentsCount}</Table.Cell>
              <Table.Cell>{formatDate(new Date(endCard.created))}</Table.Cell>
              <Table.Cell>
                {isCustomEndCard(endCard) && (
                  <Tooltip title="Edit" arrow placement="top">
                    <IconButton
                      color="inherit"
                      edge="end"
                      component={Link}
                      to={{
                        pathname: getEndCardEditLink(endCard),
                        state: currentURL,
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
              </Table.Cell>
              <Table.Cell>
                <Tooltip title="Remove" arrow placement="top">
                  <IconButton
                    color="inherit"
                    edge="end"
                    component={Button}
                    onClick={handleRemoveClick(endCard.id)}
                  >
                    <Remove />
                  </IconButton>
                </Tooltip>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <PaginationFooter
          totalCount={totalCount || endCards.length}
          visibleCount={endCards.length}
          dispatch={dispatch}
          pageInfo={pageInfo}
          tableType={PostsPerPage.EndCards}
        />
      </Table>
      <Styled.ActionsContainer ref={elementRef}>
        <Columns display="flex" alignItems="center">
          <Search placeholder="Search" onSearch={onSearch} />
          <DropdownButton dropdownLinks={EndCardLinks} />
        </Columns>
      </Styled.ActionsContainer>
    </Styled.TableWrapper>
  );
};
