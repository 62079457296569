import gql from 'graphql-tag';

import { MICROSITE_METADATA_FRAGMENT, SECTION_METADATA_FRAGMENT } from '.';
import { ERRORS_PART } from './_errors';

export const CREATE_MICROSITE_MUTATION = gql`
  ${MICROSITE_METADATA_FRAGMENT}
  ${SECTION_METADATA_FRAGMENT}

  mutation CreateMicrosite($microsite: CreateMicrositeInput!) {
    createMicrosite(microsite: $microsite) {
      microsite {
        ...MicrositeMetadata
        sections {
          ...SectionsMetadata
        }
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_MICROSITE_MUTATION = gql`
  ${MICROSITE_METADATA_FRAGMENT}

  mutation UpdateMicrosite($id: ID!, $microsite:  UpdateMicrositeInput!) {
    updateMicrosite(id: $id, microsite: $microsite) {
      microsite {
        ...MicrositeMetadata
      }
      ${ERRORS_PART}
    }
  }
`;
export const UPDATE_MICROSITE_SECTION_MUTATION = gql`
  ${SECTION_METADATA_FRAGMENT}

  mutation UpdateSections($id: ID!, $sections:  [UpdateSectionInput!]!) {
    updateSections(micrositeId: $id, sections: $sections) {
      microsite {
        id
        sections {
          ...SectionsMetadata
        }
      }
      ${ERRORS_PART}
    }
  }
`;
