import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

import { Spinner } from 'components/common';
import { BigCard } from 'components/common/Wrapper/Card';
import MainLayout from 'components/layouts/MainLayout';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { Permission } from 'config/permissions';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { useSafeState } from 'hooks/useSafeState';
import { WidgetPresetsOrderBy } from 'models';
import MissingMediaPage from 'pages/404';

import { CreatePresetModal } from './CreatePreset';
import { PresetTable } from './PresetTable';
import { useWidgetPresets } from './useWidgetPresets';

import * as Styled from 'components/common/Table/Table.styles';

export function PresetListPage() {
  const [isCreatePresetModalOpen, setIsCreatePresetModalOpen] = useSafeState(false);

  const { hasPermissions } = useUserPermissions();
  const canCreatePreset = hasPermissions([Permission.CreateWidgetPreset]);
  const canViewPresets = hasPermissions([Permission.ViewWidgetPreset]);

  if (!canViewPresets) {
    return <MissingMediaPage />;
  }

  return (
    <MainLayout>
      <Box p="40px 35px" lineHeight="normal">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h1">Widget Presets</Typography>
          {canCreatePreset ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsCreatePresetModalOpen(true)}
            >
              Create preset
            </Button>
          ) : null}
        </Box>
        <BigCard mt="40px" overflow="hidden">
          <PresetListComponent />
        </BigCard>
      </Box>
      <CreatePresetModal
        isOpen={isCreatePresetModalOpen}
        onClose={() => setIsCreatePresetModalOpen(false)}
      />
    </MainLayout>
  );
}

function PresetListComponent() {
  const [pagination, dispatch] = React.useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.WidgetPresets),
  );
  const { orderBy, sorting, setSorting } = useSorting<WidgetPresetsOrderBy>('-created');

  const { loading, data } = useWidgetPresets({
    variables: {
      ...pagination,
      orderBy,
    },
  });

  // initial loading
  if (loading && !data?.widgetPresets?.nodes) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (data?.widgetPresets?.nodes) {
    const { nodes, totalCount, pageInfo } = data.widgetPresets;

    return (
      <PresetTable
        presetsLoading={loading} // subsequent loading
        presets={nodes}
        totalCount={totalCount}
        pageInfo={pageInfo}
        dispatch={dispatch}
        sorting={sorting}
        setSorting={setSorting}
      />
    );
  }

  return <div>Unexpected error</div>;
}
