import { Box } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import {
  BackButton,
  FormLabel,
  InfoTooltip,
  TextInput,
  Toggle,
  Typography,
} from 'components/common';
import { generateVideoLink } from 'pages/urls';

import { PromoteContentSettingsProps, PromoteContentSidebarProps } from './types';

export const PromoteContentSidebar: React.FC<PromoteContentSidebarProps> = ({
  video,
  onSave,
  promoteContentSettings,
  togglePromoteObjects,
  togglePromoteAtStart,
  togglePromoteAtEnd,
  setPromoteObjectsLabel,
}) => {
  const { register, handleSubmit, reset, errors } = useForm<PromoteContentSettingsProps>({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const { promoteObjects, promoteAtStart, promoteAtEnd } = promoteContentSettings;

  const onSubmit = (data: PromoteContentSettingsProps) => {
    onSave(promoteObjects ? { ...data, promoteAtStart, promoteAtEnd } : null);
    reset(data);
  };

  const videoEditorUrl = generateVideoLink(video.id);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="external-content-settings">
      <BackButton label="Back to video editor" link={videoEditorUrl} />
      <Box m="22px 0 32px 0">
        <Typography variant="h1" color="textPrimary">
          Promo Cards
        </Typography>
      </Box>
      <InlineCheckbox>
        <FormLabel>
          Show Promo Button
          <InfoTooltip
            content={
              'When ON, a Promo button will be displayed within the Video Player, encouraging your watchers to explore some extra content you defined. Set the button’s label in the input below.'
            }
          />
        </FormLabel>
        <Toggle
          name="promoteObjects"
          checked={promoteObjects}
          color="primary"
          onChange={() => {
            togglePromoteObjects(!promoteObjects);
          }}
        />
      </InlineCheckbox>
      {promoteObjects ? (
        <>
          <TextInput
            label="Button Label"
            name="promotedObjectsLabel"
            placeholder="e.g. Products"
            defaultValue={video.promotedContentSettings?.promotedObjectsLabel}
            error={!!errors.promotedObjectsLabel}
            errorMessage={errors.promotedObjectsLabel?.message}
            mb="35px"
            maxLength={25}
            ref={register({ required: true })}
            onChange={(value) => {
              setPromoteObjectsLabel(value);
            }}
          />
          <InlineCheckbox>
            <FormLabel>
              Open Promo Cards on Intro
              <InfoTooltip content="When ON, during the first 5s of this video, defined Content will be presented to the user as an overlay on the video player." />
            </FormLabel>
            <Toggle
              name="promoteAtStart"
              checked={promoteAtStart}
              color="primary"
              onChange={() => {
                togglePromoteAtStart(!promoteAtStart);
              }}
            />
          </InlineCheckbox>
          <InlineCheckbox>
            <FormLabel>
              Open Promo Cards on Outro
              <InfoTooltip
                content={
                  'When ON, during the last 5s of this video, defined Content will be presented to the user as an overlay on the video player.'
                }
              />
            </FormLabel>
            <Toggle
              name="promoteAtEnd"
              checked={promoteAtEnd}
              color="primary"
              onChange={() => {
                togglePromoteAtEnd(!promoteAtEnd);
              }}
            />
          </InlineCheckbox>
        </>
      ) : null}
    </form>
  );
};

export const InlineCheckbox = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 15px;

  &:first-of-type {
    margin-top: 25px;
  }

  .checkbox-label {
    margin: 0;
  }
`;
