import { Box, BoxProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { AnyObject } from 'utils/types';

const Wrapper = styled(Box)`
  border-radius: 10px;
  background-color: ${materialTheme.palette.background.paper};
  box-shadow: 0 2px 57px 0 rgba(221, 222, 238, 0.33);
  overflow: hidden;
`;

const Title = styled.h3`
  color: #161a23;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.45px;
  line-height: 1.5;
  margin: 22px 30px 19px;
`;

interface CardProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  style?: AnyObject;
}

export const Card: React.FC<CardProps & BoxProps> = ({ children, title, style, ...boxProps }) => {
  return (
    <Wrapper style={style} {...boxProps}>
      {title && <Title>{title}</Title>}
      {children}
    </Wrapper>
  );
};

const BigWrapper = styled(Wrapper)`
  border-radius: 15px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.06);
`;

export const BigCard: React.FC<CardProps & BoxProps> = ({
  children,
  title,
  style,
  ...boxProps
}) => {
  return (
    <BigWrapper style={style} {...boxProps}>
      {title && <Title>{title}</Title>}
      {children}
    </BigWrapper>
  );
};
