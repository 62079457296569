import { lighten } from 'polished';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { pxOrString } from 'utils/styling';

export interface BadgeProps {
  backgroundColor?: string;
  color?: string;
  size?: 'small' | 'medium';
  width?: string | number;
  children?: React.ReactNode;
}

const defaultBackgroundColor = lighten(0.85, materialTheme.palette.text.primary);
const defaultColor = materialTheme.palette.text.primary;

export const Badge = styled.div<BadgeProps>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor || defaultBackgroundColor};
  border-radius: ${({ size }) => (size === 'small' ? '5px' : '10px')};
  color: ${({ color }) => color || defaultColor};
  display: flex;
  font-size: 10px;
  font-weight: bold;
  padding-top: ${({ size }) => (size === 'small' ? '3px' : '7px')};
  padding-bottom: ${({ size }) => (size === 'small' ? '3px' : '7px')};
  padding-left: ${({ size }) => (size === 'small' ? '8px' : '0')};
  padding-right: ${({ size }) => (size === 'small' ? '8px' : '0')};
  justify-content: center;
  letter-spacing: 0.36px;
  width: ${({ size, width }) => (size === 'small' ? 'auto' : width ? pxOrString(width) : '80px')};
  text-transform: uppercase;
`;
