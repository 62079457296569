import { Box, ButtonBase } from '@material-ui/core';
import { ellipsis } from 'polished';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const UploadButton = styled(ButtonBase)`
  && {
    height: 51px;
    width: 100px;
    padding: 6px;
    align-items: center;
    background: transparent;
    border: 2px dashed ${materialTheme.palette.grey[200]};
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 0;
    font-size: 12px;
    justify-content: center;
    margin: 0;
  }
`;

export const BoldAndCut = styled.div`
  ${ellipsis()};
  font-weight: 500;
`;

export const AcceptedFormats = styled(Box)`
  color: ${materialTheme.palette.grey[500]};
  font-size: 12px;
  margin-top: 8px;
`;

interface WrapperProps {
  $isInvalidExtension: boolean;
}

export const Wrapper = styled(Box)<WrapperProps>`
  ${({ $isInvalidExtension }) =>
    $isInvalidExtension &&
    css`
      ${UploadButton} {
        border-color: ${materialTheme.palette.error.main};
      }

      ${AcceptedFormats} {
        color: ${materialTheme.palette.error.main};
      }
    `}
`;
