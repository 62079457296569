import React, { useState } from 'react';

import { useCustomizeCarousel } from 'hooks/mutation';
import AfterSaveModal from 'pages/Carousel/AfterSaveModal';

import { EditPageView } from './EditCarouselView';
import { FormData, OwnProps } from './types';

export const EditCarouselController = ({ carousel }: OwnProps) => {
  const [customizeCarousel, { loading }] = useCustomizeCarousel();
  const [showCopyCodeModal, setShowCopyCodeModal] = useState(false);

  const handleSaveData = React.useCallback(
    ({
      title,
      description,
      moments,
      googleAnalyticsTrackingId,
      googleAnalytics4MeasurementId,
      ...data
    }: FormData) => {
      setShowCopyCodeModal(true);
      return customizeCarousel({
        variables: {
          id: carousel.id,
          carousel: {
            title: title,
            description: description,
            moments,
            googleAnalyticsTrackingId,
            googleAnalytics4MeasurementId,
          },
          carouselStyle: data,
        },
      });
    },
    [carousel.id, customizeCarousel],
  );

  return (
    <>
      <EditPageView
        pageTitle="Customize Widget"
        saveButtonText="Save"
        saveLoadingText={loading ? 'Saving…' : undefined}
        carouselData={carousel}
        onDataSave={handleSaveData}
        momentsLoading={loading}
      />
      <AfterSaveModal
        open={showCopyCodeModal}
        handleClose={() => setShowCopyCodeModal(false)}
        carousel={carousel}
      />
    </>
  );
};
