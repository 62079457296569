import React from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { PasswordInput, PasswordRequirementsNote } from 'components/common/TextInput';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { MIN_PASSWORD_LENGTH } from 'config/constants';
import { LegalAgreements } from 'pages/Register/LegalAgreements';
import { URLS } from 'pages/urls';
import { isMinLength, isNotEmptyString, isNotNumericOnly, validate } from 'utils/validation';

export interface FormData {
  name: string;
  password: string;
  terms: boolean;
  privacy: boolean;
}

interface Props {
  onSubmit: (data: FormData) => void;
  isSubmitting: boolean;
  isD2CPlugin: boolean;
}

export const VerifyUserForm: React.VFC<Props> = ({ onSubmit, isSubmitting, isD2CPlugin }) => {
  const { register, errors, handleSubmit, control } = useForm<FormData>({
    mode: 'onChange',
  });

  return (
    <form name="verify-user" onSubmit={handleSubmit(onSubmit)}>
      <Stack width="400px" mb="40px">
        <TextInput
          name="name"
          label="Full name"
          autoComplete="name"
          placeholder="Type your name here"
          ref={register({
            required: 'Required',
            validate: {
              empty: validate(isNotEmptyString, 'Full name cannot be empty'),
            },
          })}
          errorMessage={errors.name?.message}
          mb="0"
        />
        <Stack rowGap={16}>
          <PasswordRequirementsNote />
          <PasswordInput
            name="password"
            autoComplete="new-password"
            label="Password"
            placeholder="Type your new password here"
            ref={register({
              required: 'Required',
              validate: {
                minLength: validate(
                  isMinLength(MIN_PASSWORD_LENGTH),
                  `Minimum ${MIN_PASSWORD_LENGTH} characters required`,
                ),
                whitespace: validate(isNotEmptyString, 'Password cannot be empty'),
                numeric: validate(isNotNumericOnly, 'Password cannot be only numeric'),
              },
            })}
            errorMessage={errors.password?.message}
            disabled={isSubmitting}
            mb="0"
          />
        </Stack>
        <LegalAgreements
          control={control}
          errors={errors}
          termsOfUseUrl={isD2CPlugin ? URLS.termsOfUseD2C : URLS.termsOfUse}
        />
      </Stack>
      <Columns display="flex" justifyContent="center">
        <LoadingButton variant="contained" color="primary" type="submit" isLoading={isSubmitting}>
          Save
        </LoadingButton>
      </Columns>
    </form>
  );
};
