import { CREATE_BUTTON_CTA_MUTATION } from 'context/queries/buttonCTAMutations';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { ButtonCTAResponse, CreateButtonCTAInput } from 'pages/CTAButton/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  createButtonCta: Nullable<{
    buttonCta: Nullable<ButtonCTAResponse>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  button_cta: CreateButtonCTAInput;
}

interface UseCreateButtonCTA extends UseMutationOptions<ResponseData, Variables> {}

export const useCreateButtonCTA = (options: UseCreateButtonCTA = {}) => {
  return useMutation<ResponseData, Variables>(CREATE_BUTTON_CTA_MUTATION, {
    pathToErrors: 'createButtonCta.errors',
    ...options,
  });
};
