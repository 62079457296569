import React from 'react';

import { TermsOfUse } from './TermsOfUse';
import { TermsOfUseCSText } from './TermsOfUseCSText';

export const CreatorSuiteTermsOfUsePage: React.VFC = () => (
  <TermsOfUse>
    <TermsOfUseCSText />
  </TermsOfUse>
);
