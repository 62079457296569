import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { TextInput } from 'components/common';
import { isValidUrl, validate } from 'utils/validation';

interface Props {
  errors: FieldErrors;
  control: Control;
}

export const ButtonUrl: React.FC<Props> = ({ errors, control }) => {
  return (
    <Controller
      rules={{
        required: 'Button URL cannot be empty',
        validate: validate<string>((value) => isValidUrl(value), 'URL is in incorrect format'),
      }}
      render={({ onChange, ...props }) => (
        <TextInput
          {...props}
          onChange={onChange}
          onBlur={({ currentTarget }) => onChange(currentTarget.value.trim())}
          label="Button URL *"
          error={!!errors.url}
          errorMessage={errors.url?.message}
          placeholder="https://"
          mb="40px"
          info="URL"
          maxLength={200}
        />
      )}
      name="url"
      control={control}
    />
  );
};
