import { combineLatest, merge, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { mouseMoveEventsStreamUntilMouseUp } from 'utils/observables';

export const handleMouseHover = (event$: Observable<React.MouseEvent<HTMLDivElement>>) => {
  const enter$ = event$.pipe(
    filter((e) => e.type === 'mouseover'),
    map((event) => [
      event.currentTarget!.getBoundingClientRect().left,
      event.currentTarget!.getBoundingClientRect().width,
    ]),
  );

  const leave$ = event$.pipe(filter((e) => e.type === 'mouseout'));

  const positions$ = enter$.pipe(
    switchMap(mouseMoveEventsStreamUntilMouseUp),
    withLatestFrom(enter$),
    map(([clientX, [offsetLeft, width]]) => {
      return (clientX - offsetLeft) / width;
    }),
    distinctUntilChanged(),
  );

  return combineLatest(positions$, merge(enter$.pipe(mapTo(true)), leave$.pipe(mapTo(false))));
};
