import React from 'react';

import { Dialog } from 'components/common/Dialog';
import Spinner from 'components/common/Spinner';
import { Stack } from 'components/common/Wrapper/Layout';
import usePrevious from 'hooks/usePrevious';
import { DetailsWidgetPreset } from 'models';
import { Nullable } from 'utils/types';

import { EditPreset, FormData } from './EditPreset';
import { useExistingPreset } from './useExistingPreset';
import { useUpdateWidgetPreset } from './useUpdateWidgetPreset';

interface Props {
  presetId: string;
  onClose: () => void;
  isOpen: boolean;
}

export function EditPresetModal({ presetId, onClose, isOpen: isOpenFromProps }: Props) {
  const [isOpen, setIsOpen] = React.useState(isOpenFromProps);
  const wasOpen = usePrevious(isOpenFromProps);

  React.useEffect(() => {
    // render dialog to DOM based on props
    if (isOpenFromProps !== wasOpen && isOpenFromProps) {
      setIsOpen(isOpenFromProps);
    }
  }, [isOpenFromProps, wasOpen]);

  const handleExited = () => {
    // hide dialog from DOM based on dialog callback
    setIsOpen(false);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpenFromProps}
      heading="Edit Preset"
      width={800}
    >
      {
        // Render dialog conditionally to only fetch data when needed and refetch on repeated opens
        isOpen && <EditPresetModalInner presetId={presetId} onClose={onClose} />
      }
    </Dialog>
  );
}

interface InnerProps {
  presetId: string;
  onClose: () => void;
}

export function EditPresetModalInner({ presetId, onClose }: InnerProps) {
  const { data, loading: isLoading } = useExistingPreset({
    variables: { id: presetId },
  });
  const initialPreset = getInitialPreset(data?.widgetPresetById);
  const [updateWidgetPreset, { loading: isSubmitting }] = useUpdateWidgetPreset();

  const onSubmit = async (formData: FormData) => {
    if (!initialPreset) return;

    const name = formData.name.trim();
    const result = await updateWidgetPreset({
      variables: {
        id: presetId,
        widgetPreset: {
          name,
          googleAnalyticsTrackingId: formData.googleAnalyticsTrackingId.trim(),
          googleAnalytics4MeasurementId: formData.googleAnalytics4MeasurementId.trim(),
          endCardId: formData.endCardId,
        },
        style: {
          colorTheme: formData.colorTheme,
          mainColor: formData.mainColor,
        },
      },
    });

    if (result.data?.updateWidgetPreset?.widgetPreset?.id === presetId) {
      onClose();
    }
  };

  return isLoading ? (
    <Stack rowGap="20px" display="inline-block">
      <Spinner size="35px" />
      <p>Loading…</p>
    </Stack>
  ) : (
    <EditPreset initialData={initialPreset} onSubmit={onSubmit} isSubmitting={isSubmitting} />
  );
}

function getInitialPreset(preset?: Nullable<DetailsWidgetPreset>): undefined | FormData {
  if (!preset) return undefined;

  const { name, googleAnalyticsTrackingId, googleAnalytics4MeasurementId, endCard, style } = preset;
  const { colorTheme, mainColor } = style;

  return {
    name,
    googleAnalyticsTrackingId: googleAnalyticsTrackingId || '',
    googleAnalytics4MeasurementId: googleAnalytics4MeasurementId || '',
    endCardId: endCard?.id,
    colorTheme,
    mainColor,
  };
}
