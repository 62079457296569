import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';

import { TimeRange } from 'models';
import { msToSeconds } from 'utils/time';

export const useTimestampObservable = (momentSubject: BehaviorSubject<TimeRange>): TimeRange =>
  useObservable<TimeRange>(
    () =>
      momentSubject.pipe(
        distinctUntilChanged((prev, cur) => prev[0] === cur[0] && prev[1] === cur[1]),
      ),
    [0, 0],
  );

export const useVideoEvents = () => {
  const seekOnReady = React.useRef(0);
  const didUpdateOnReady = React.useRef(false);

  // This runs first. It will try to set `currentTime` of the video to initial value.
  // Setting `currentTime` might fail on Safari when executed in this event, but works
  // on every other browser.
  const onLoadedMetadata = React.useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (seekOnReady.current > 0) {
      event.currentTarget.currentTime = msToSeconds(seekOnReady.current);
    }
  }, []);

  // Runs when `currentTime` has changed. If it manages to run before `onLoadedData` then it means
  // that the value has been set correctly in `onLoadedMetadata` event (correct behavior).
  const onTimeUpdate = React.useCallback(() => {
    if (!didUpdateOnReady.current) {
      didUpdateOnReady.current = true;
    }
  }, []);

  // Try to set `currentTime` if previous attempts have failed. Only used as a fallback for Safari.
  const onLoadedData = React.useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (!didUpdateOnReady.current && seekOnReady.current > 0) {
      event.currentTarget.currentTime = msToSeconds(seekOnReady.current);
    }
  }, []);

  return { seekOnReady, onLoadedMetadata, onTimeUpdate, onLoadedData };
};
