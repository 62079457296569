import React from 'react';

import MemoToggle from 'components/common/Table/MemoToggle';
import { PublishableWidgetEmbed } from 'components/common/WidgetEmbedCode';
import { CheckboxCell, Table } from 'components/Table';
import { DeleteAction, ViewEditAction } from 'components/tables/TableActionCell';
import { AddToPlaylistAction } from 'components/tables/TableActionCell/AddToPlaylistAction';
import { ShareAction } from 'components/tables/TableActionCell/ShareAction';
import { Permission } from 'config/permissions';
import { ToggleMutateOptions } from 'hooks/mutation/_useToggleMutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { DashboardMomentNode } from 'models';
import { PlaylistContentType } from 'models/Playlist';
import { generateMomentLink } from 'pages/urls';
import { isDeepEqual } from 'utils/common';
import { formatDate } from 'utils/date';
import { Nullable } from 'utils/types';
import { canVideoBePublished, formatSource } from 'utils/videos';

import { MomentTitleCell } from './MomentTitleCell';

import * as Styled from '../DashboardTable.styles';

interface MomentTableRowProps {
  moment: DashboardMomentNode;
  isSelected: boolean;
  hasCheckboxCell: boolean;
  handleCheckboxClick: (id: string) => void;
  canToggleMoment: boolean;
  toggleMoment: (options: ToggleMutateOptions) => Promise<any>;
  canDeleteMoment: boolean;
  deleteMoment: (options: { variables: { id: string } }) => Promise<any>;
  setSharedMoment: (moment: Nullable<DashboardMomentNode>) => void;
  widgetApiKey?: string;
}

export const MomentTableRow = React.memo(function MomentTableRow({
  moment,
  isSelected,
  hasCheckboxCell,
  handleCheckboxClick,
  canToggleMoment,
  toggleMoment,
  canDeleteMoment,
  deleteMoment,
  setSharedMoment,
  widgetApiKey,
}: MomentTableRowProps) {
  const momentPageUrl = generateMomentLink(moment.video.id, moment.id);

  const { hasPermissions } = useUserPermissions();
  const canUserEditMoment = hasPermissions([Permission.ChangeMoment]);
  const canPublishVideo = hasPermissions([Permission.PublishVideo]);
  const canUserAddToPlaylist = hasPermissions([Permission.ChangePlaylist]);

  return (
    <Styled.Row
      key={moment.id}
      // added tabindex so it's focusable on ipad
      tabIndex={0}
    >
      {hasCheckboxCell && (
        <CheckboxCell
          checked={isSelected}
          onClick={() => handleCheckboxClick(moment.id)}
          color="secondary"
        />
      )}
      <Table.Cell>
        <MomentTitleCell moment={moment} />
      </Table.Cell>
      <Table.Cell>
        <Styled.VideoTitle>{moment.video.title}</Styled.VideoTitle>
      </Table.Cell>
      <Table.Cell>{formatSource(moment.source)}</Table.Cell>
      {canToggleMoment && (
        <Table.Cell>
          <MemoToggle
            id={moment.id}
            videoId={moment.video.id}
            checked={moment.visible}
            onChange={toggleMoment}
          />
        </Table.Cell>
      )}
      <Table.Cell>{formatDate(new Date(moment.created))}</Table.Cell>
      <Table.Cell>
        <ViewEditAction url={momentPageUrl} canUserChangeIt={canUserEditMoment} edge="start" />
        {moment.visible ? (
          <PublishableWidgetEmbed
            id={moment.id}
            videoId={moment.video.id}
            type="moment"
            videoStatus={moment.video.status}
            canUserPublishVideo={canPublishVideo}
            apiKey={widgetApiKey}
          >
            {({ showModal }) => {
              return <PublishableWidgetEmbed.TableAction showModal={showModal} edge="start" />;
            }}
          </PublishableWidgetEmbed>
        ) : null}
        {canUserAddToPlaylist && moment.visible && canVideoBePublished(moment.video.status) && (
          <AddToPlaylistAction
            item={{
              type: PlaylistContentType.Moments,
              id: moment.id,
              title: moment.title,
              link: momentPageUrl,
              isPublished: moment.isPublished,
              videoId: moment.video.id,
            }}
            edge="start"
          />
        )}
        {canDeleteMoment && (
          <DeleteAction
            type="moment"
            onDelete={() => deleteMoment({ variables: { id: moment.id } })}
            edge="start"
          />
        )}
        {moment.visible ? (
          <ShareAction onShareClick={() => setSharedMoment(moment)} edge="start" />
        ) : null}
      </Table.Cell>
    </Styled.Row>
  );
},
isDeepEqual);
