import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation DeleteWidgetPreset($id: ID!) {
    deleteWidgetPreset(id: $id) {
      deletedWidgetPresetId
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  deleteWidgetPreset: Nullable<{
    deletedWidgetPresetId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
}

interface UseDeleteWidgetPresetMutationOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export function useDeleteWidgetPreset(options?: UseDeleteWidgetPresetMutationOptions) {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'deleteWidgetPreset.errors',
    ...options,
  });
}
