import React from 'react';

import { env } from 'config/env';
import Sentry from 'utils/sentry';

class ErrorBoundary extends React.Component {
  // needed to parse unhandled rejection errors
  static getDerivedStateFromError() {
    return {};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (env.SEEEN_APP_ENV !== 'local') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo as any);
        const eventId = Sentry.captureException(error);
        Sentry.showReportDialog({
          eventId,
          lang: 'en',
        });
      });
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
