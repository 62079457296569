import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const LOGIN_USER_MUTATION = gql`
  mutation UserLogin(
    $email: String!
    $password: String!
    $organizationName: String
    $organizationId: ID
  ) {
    tokenAuth(
      email: $email
      password: $password
      organizationName: $organizationName
      organizationId: $organizationId
    ) {
      token
      ${ERRORS_PART}
    }
  }
`;

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordReset($email: String!, $organizationName: String, $organizationId: ID) {
    requestPasswordReset(
      email: $email
      organizationName: $organizationName
      organizationId: $organizationId
    ) {
      ok
      ${ERRORS_PART}
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    passwordReset(newPassword: $newPassword, token: $token) {
      ok
      ${ERRORS_PART}
    }
  }
`;

export const CHANGE_USER_EMAIL_MUTATION = gql`
  mutation UpdateUserEmail($token: String!) {
    updateUserEmail(token: $token) {
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_ACCOUNT_DETAILS = gql`
  mutation UpdateAccountDetails($accountDetails: UpdateAccountDetailsInput!) {
    updateAccountDetails(accountDetails: $accountDetails) {
      user {
        avatarUrl
        name
        email
        id
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_USER_AVATAR = gql`
  mutation DeleteAvatar {
    deleteAvatar {
      user {
        avatarUrl
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdatePassword($updatePassword: UpdatePasswordInput!) {
    updatePassword(updatePassword: $updatePassword) {
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_USER_EMAIL_REQUEST = gql`
  mutation UpdateEmailRequest($email: Email!) {
    updateEmailRequest(email: $email) {
      ${ERRORS_PART}
    }
  }
`;
