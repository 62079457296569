export function blobToBase64(blob: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = reject;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
}

export interface ImageDimensions {
  width: number;
  height: number;
}

export async function getImageDimensions(blob: Blob): Promise<ImageDimensions> {
  const base64 = await blobToBase64(blob);
  return new Promise((resolve) => {
    const image = new Image();
    image.src = base64;
    image.onload = function () {
      resolve({
        height: image.height,
        width: image.width,
      });
    };
  });
}

export const printImageDimensions = ({ width, height }: ImageDimensions) => `${width}x${height}px`;
