import React from 'react';

import { UploadDropBox } from 'components/common/UploadDropBox';
import { MAX_IMAGE_SIZE } from 'config/constants';
import { pushError } from 'context/globalStream';
import { getImageDimensions, ImageDimensions, printImageDimensions } from 'utils/files';
import { exceedsMaxAllowedFileSize, exceedsMaxImageDimensions } from 'utils/validation';

const passValidation = () => true;

interface Props extends React.ComponentPropsWithoutRef<typeof UploadDropBox> {
  maxImageDimensions: ImageDimensions;
}

export const ImageUploadDropBox: React.VFC<Props> = ({
  validateFile = passValidation,
  maxImageDimensions,
  ...props
}) => {
  const handleFileValidation = React.useCallback(
    async (file: File) => {
      if (!(await validateFile(file))) {
        return false;
      }

      if (exceedsMaxAllowedFileSize(MAX_IMAGE_SIZE, file.size)) {
        pushError({
          message: `Picture exceeds maximum file size (${MAX_IMAGE_SIZE} MiB).`,
        });
        return false;
      }

      const imageDimensions = await getImageDimensions(file);
      if (exceedsMaxImageDimensions(maxImageDimensions, imageDimensions)) {
        pushError({
          message: `Picture resolution (${printImageDimensions(
            imageDimensions,
          )}) exceeds maximum allowed size (${printImageDimensions(maxImageDimensions)}).`,
        });
        return false;
      }

      return true;
    },
    [maxImageDimensions, validateFile],
  );

  return <UploadDropBox {...props} validateFile={handleFileValidation} />;
};
