import { CUSTOMIZE_CAROUSEL_MUTATION, QUERY_CAROUSELS_BY_ID } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { CarouselStyleInput, CustomizeCarouselInput, ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateCarousel: Nullable<{
    carousel: Nullable<{
      id: string;
    }>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  carousel: CustomizeCarouselInput;
  carouselStyle: CarouselStyleInput;
}

export const useCustomizeCarousel = () => {
  return useMutation<ResponseData, Variables>(CUSTOMIZE_CAROUSEL_MUTATION, {
    pathToErrors: 'updateCarousel.errors',
    refetchQueries: ({ data }: { data: ResponseData }) => {
      const successId = data.updateCarousel?.carousel?.id;
      return successId
        ? ['getCarouselMoments', { query: QUERY_CAROUSELS_BY_ID, variables: { id: successId } }]
        : [];
    },
  });
};
