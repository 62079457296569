import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { IS_MOMENT_PUBLISHED_QUERY, IS_VIDEO_PUBLISHED_QUERY } from 'context/queries';
import { useSafeState } from 'hooks/useSafeState';
import { PlaylistContentType } from 'models/Playlist';
import { PublishModal } from 'pages/PublishVideo';

import { AddToPlaylistModal } from './AddToPlaylistModal';
import { Item } from './types';

interface Props {
  item: Item;
  edge?: 'start' | 'end';
}

export function AddToPlaylistAction({ item, edge }: Props) {
  const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useSafeState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useSafeState(false);

  const openPlaylistModal = () => setIsPlaylistModalOpen(true);
  const closePlaylistModal = () => setIsPlaylistModalOpen(false);

  const openPublishModal = () => {
    setIsPublishModalOpen(true);
    closePlaylistModal();
  };
  const closePublishModal = () => {
    setIsPublishModalOpen(false);
    openPlaylistModal();
  };

  return (
    <>
      <Tooltip title="Add to playlist" arrow placement="top">
        <IconButton color="inherit" edge={edge} onClick={openPlaylistModal}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <AddToPlaylistModal
        item={item}
        isOpen={isPlaylistModalOpen}
        onClose={closePlaylistModal}
        openPublishModal={openPublishModal}
      />
      <PublishModal
        open={isPublishModalOpen}
        onClose={closePublishModal}
        videoId={item.videoId}
        refetchQueries={
          item.type === PlaylistContentType.Moments
            ? [{ query: IS_MOMENT_PUBLISHED_QUERY, variables: { id: item.id } }]
            : [{ query: IS_VIDEO_PUBLISHED_QUERY, variables: { id: item.id } }]
        }
      />
    </>
  );
}
