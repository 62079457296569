import { Box, IconButton, makeStyles, Slider } from '@material-ui/core';
import { transparentize } from 'polished';
import React, { useCallback, useState } from 'react';
import screenfull from 'screenfull';

import { ReactComponent as Maximize } from 'assets/icons/maximize.svg';
import { ReactComponent as Minimize } from 'assets/icons/minimize.svg';
import { ReactComponent as Pause } from 'assets/icons/pause-circle.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as VolumeLow } from 'assets/icons/volume-1.svg';
import { ReactComponent as VolumeHigh } from 'assets/icons/volume-2.svg';
import { ReactComponent as VolumeMuted } from 'assets/icons/volume-x.svg';
import { materialTheme } from 'components/App/materialTheme';
import { Duration } from 'components/common';
import { VideoSettingsMenu } from 'components/VideoPlayer/VideoSettingsMenu';
import { SetValue } from 'hooks/useLocalStorage';

import { useVideoPlayerContext } from './../context';
import * as Styled from './ControlBarElements';
import {
  ButtonBox,
  ControlWrapper,
  Fullscreen,
  StopPlayButton,
  Timer,
  VolumeSlider,
} from './ControlBarElements';

export const STOP_PLAY_BUTTON_TEST_ID = 'stop-play-button-testid';

export type SetVolume = SetValue<number>;

interface ControlProps {
  playing: boolean;
  togglePlaying: () => void;
  duration: number;
  played: number;
  playerRef: React.RefObject<HTMLDivElement>;
  setVolume: SetVolume;
  volume: number;
}

const renderVolumeButton = (volume: number) => {
  if (volume === 0) {
    return <VolumeMuted />;
  } else if (volume > 0 && volume < 0.5) {
    return <VolumeLow />;
  } else {
    return <VolumeHigh />;
  }
};

const useSliderCss = makeStyles(() => ({
  root: {
    color: materialTheme.palette.common.white,
  },
  thumb: {
    '&:hover': {
      boxShadow: `0 0 0 8px ${transparentize(0.84, materialTheme.palette.common.white)}`,
    },
  },
  active: {
    boxShadow: `0 0 0 14px ${transparentize(0.84, materialTheme.palette.common.white)} !important`,
  },
}));

const ControlBar = ({
  playing,
  togglePlaying,
  duration,
  played,
  playerRef,
  setVolume,
  volume,
}: ControlProps) => {
  const [volumeRangeVisible, setVolumeRangeVisibility] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState(false);
  const { streamLevels } = useVideoPlayerContext();

  const handleFullscreen = useCallback(() => {
    const element = playerRef.current;
    if (screenfull.isEnabled && element) {
      setIsFullscreen(!isFullscreen);
      screenfull.toggle(element);
    }
  }, [playerRef, isFullscreen]);

  const handleChangeCommit = (volume: number) => {
    setTimeout(() => {
      setVolumeRangeVisibility(false);
    }, 750);
    setVolume(volume);
  };

  const sliderClasses = useSliderCss();

  return (
    <ControlWrapper>
      <ButtonBox>
        <StopPlayButton onClick={togglePlaying} data-testid={STOP_PLAY_BUTTON_TEST_ID}>
          {playing ? <Pause /> : <Play />}
        </StopPlayButton>
      </ButtonBox>
      <Box
        height="100%"
        ml="8px"
        display="flex"
        alignItems="center"
        onClick={() => setVolumeRangeVisibility(!volumeRangeVisible)}
        position="relative"
        color={materialTheme.palette.common.white}
      >
        {renderVolumeButton(volume)}
        <VolumeSlider>
          {volumeRangeVisible && (
            <Slider
              min={0}
              max={1}
              name="volume"
              orientation="vertical"
              step={0.01}
              value={volume}
              classes={sliderClasses}
              onChange={(e, value) =>
                typeof value === 'number' && setVolume && setVolume(value, false)
              }
              onChangeCommitted={(e, value) =>
                typeof value === 'number' && handleChangeCommit(value)
              }
            />
          )}
        </VolumeSlider>
      </Box>
      <Timer>
        <span>
          <Duration ms={played} /> / <Duration ms={duration} />
        </span>
      </Timer>
      <Styled.SettingsContainer>
        {streamLevels ? (
          <>
            <IconButton
              onClick={() => setShowSettings(!showSettings)}
              title="Change settings"
              aria-label="Change settings"
              color="inherit"
            >
              <Settings />
            </IconButton>
            <VideoSettingsMenu showSettings={showSettings} />
          </>
        ) : null}
      </Styled.SettingsContainer>
      <Fullscreen onClick={handleFullscreen}>
        {isFullscreen ? <Minimize /> : <Maximize />}
      </Fullscreen>
    </ControlWrapper>
  );
};

export default React.memo(ControlBar);
