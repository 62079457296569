import { Paper, TableBody, TableCell, TableContainer } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { Link, TooltipWrapper } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import PaginationFooter from 'components/PaginationFooter';
import { PostsPerPage } from 'components/PaginationFooter/constants';
import { Permission } from 'config/permissions';
import { PaginationAction } from 'context/pagination';
import { useDeleteCarousel } from 'hooks/mutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { CarouselI, PageInfo } from 'models';
import { generateCarouselLink } from 'pages/urls';
import { CarouselsTableSetSorting, CarouselsTableSorting } from 'pages/Widget/Carousels/types';
import ConfirmDeleteCarouselButton from 'pages/Widget/ConfirmDeleteCarouselButton';
import PublishButton from 'pages/Widget/PublishButton';
import { formatDate } from 'utils/date';
import { Nullable } from 'utils/types';

import { CarouselName, NameCell } from './CarouselsTable.styled';

import * as Styled from 'components/common/Table/Table.styles';

interface OwnProps {
  carouselsLoading: boolean;
  carousels: CarouselI[];
  totalCount: number;
  pageInfo: Nullable<PageInfo>;
  dispatch(value: PaginationAction): void;
  sorting: CarouselsTableSorting;
  setSorting: CarouselsTableSetSorting;
}

const CarouselsTable = ({
  carouselsLoading,
  carousels,
  totalCount,
  pageInfo,
  dispatch,
  sorting,
  setSorting,
}: OwnProps) => {
  const [deleteCarousel] = useDeleteCarousel();

  const handleDelete = React.useCallback(
    (carouselId: string) => {
      return deleteCarousel({
        variables: {
          id: carouselId,
        },
      });
    },
    [deleteCarousel],
  );

  const { hasPermissions } = useUserPermissions();
  const canUserDeleteCarousel = hasPermissions([Permission.DeleteCarousel]);

  return (
    <>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={carouselsLoading} />
        <TableContainer component={Paper}>
          <Styled.Table>
            <Styled.TableHead>
              <Styled.HeadRow>
                <NameCell>
                  <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="name">
                    Name
                  </SortInterface>
                </NameCell>
                <Styled.TitleCell>
                  <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="title">
                    Title
                  </SortInterface>
                </Styled.TitleCell>
                <TableCell align="center">
                  <SortInterface
                    activeSorting={sorting}
                    setSorting={setSorting}
                    columnName="momentsCount"
                  >
                    Moments
                  </SortInterface>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell>
                  <SortInterface
                    activeSorting={sorting}
                    setSorting={setSorting}
                    columnName="created"
                  >
                    Date Added
                  </SortInterface>
                </TableCell>
                <Styled.ActionsCell align="right">Actions</Styled.ActionsCell>
              </Styled.HeadRow>
            </Styled.TableHead>
            <TableBody>
              {carousels.map((row) => (
                <Styled.TableRow key={row.id}>
                  <NameCell>
                    <CarouselName to={generateCarouselLink(row.id)}>{row.name}</CarouselName>
                  </NameCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.momentsCount}</TableCell>
                  <TableCell>
                    <PublishButton carousel={row} />
                  </TableCell>
                  <Styled.DateCell>{formatDate(new Date(row.created))}</Styled.DateCell>
                  <Styled.ActionsCell align="right">
                    {canUserDeleteCarousel && (
                      <ConfirmDeleteCarouselButton carousel={row} onDelete={handleDelete} />
                    )}
                    <TooltipWrapper text="Edit">
                      <Link to={generateCarouselLink(row.id)}>
                        <Edit />
                      </Link>
                    </TooltipWrapper>
                  </Styled.ActionsCell>
                </Styled.TableRow>
              ))}
            </TableBody>
            <PaginationFooter
              totalCount={totalCount}
              visibleCount={carousels.length}
              pageInfo={pageInfo}
              dispatch={dispatch}
              tableType={PostsPerPage.Widgets}
            />
          </Styled.Table>
        </TableContainer>
      </Styled.TableWrapper>
    </>
  );
};

export default CarouselsTable;
