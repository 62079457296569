import { CREATE_MOMENT_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { CreateMomentInput, ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  createMoment: Nullable<{
    moment: Nullable<{
      id: string;
    }>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  videoId: string;
  handleCreateCompleted?: () => void;
  moment: CreateMomentInput;
}

const pathToErrors = 'createMoment.errors';

export const useCreateMoment = (handleCreateCompleted?: () => void) => {
  return useMutation<ResponseData, Variables>(CREATE_MOMENT_MUTATION, {
    pathToErrors,
    awaitRefetchQueries: true,
    refetchQueries: ['GetMomentsForVideo'],
    onCompleted: () => {
      handleCreateCompleted && handleCreateCompleted();
    },
  });
};
