import styled, { css } from 'styled-components/macro';

import { pxOrString, zIndex } from 'utils/styling';

export const Wrapper = styled.div`
  margin-top: 14px;
  height: 60px;
  position: relative;
`;

export interface BarProps {
  x: number;
  width: number;
  isDisabled?: boolean;
  newMoment?: boolean;
}

export const getBarTransform = (x: number) => `translateX(${pxOrString(x)})`;

export const Bar = styled.div.attrs<BarProps>(({ x, width }) => ({
  style: {
    transform: getBarTransform(x),
    width,
  },
}))<BarProps>`
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #212229;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;

  &:active {
    cursor: move;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}

  ${({ newMoment }) =>
    newMoment &&
    css`
      border: 2px solid #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      background: none;
      cursor: pointer;
    `}
`;

export const GrabBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 100%;
  z-index: ${zIndex.mEditor + 10};
  cursor: ew-resize;

  &[data-resizer='left'] {
    left: -8px;
  }

  &[data-resizer='right'] {
    right: -8px;
  }
`;
export interface MomentContentProps {
  isSelected: boolean;
}

export const MomentContent = styled.div<MomentContentProps>`
  display: flex;
  min-height: 64px;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid ${(props) => (props.isSelected ? '#fff' : '#393b45')};
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  transition: 0.25s ease-out;

  ${({ isSelected }) =>
    !isSelected
      ? css`
          &:hover {
            border: 2px solid rgba(255, 255, 255, 0.3);
          }
        `
      : null}
`;

export const MomentThumbnail = styled.img`
  width: 106px;
  height: 60px;
`;

export const MomentTitle = styled.h2`
  padding: 0 15px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
`;
