import { Typography } from '@material-ui/core';
import React from 'react';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';

import { BackButton, Select, TextInput } from 'components/common';
import { Stack } from 'components/common/Wrapper/Layout';
import { PlaylistContentType, PlaylistFilterBy, PlaylistOrdering } from 'models/Playlist';
import { noop } from 'utils/common';
import { isNotEmptyString, validate } from 'utils/validation';

import { FilterSection } from './FilterSection';

const CONTENT_TYPE_OPTIONS: { label: string; value: PlaylistContentType }[] = [
  { label: 'Videos Only', value: PlaylistContentType.Videos },
  { label: 'Moments Only', value: PlaylistContentType.Moments },
];

const ORDER_BY_OPTIONS: { label: string; value: PlaylistOrdering }[] = [
  { label: 'Most recently published', value: PlaylistOrdering.PublicationDateDescending },
  { label: 'Most viewed', value: PlaylistOrdering.ViewsDescending },
  { label: 'Alphabetical (title)', value: PlaylistOrdering.TitleAlphabetical },
  { label: 'Manual', value: PlaylistOrdering.Manual },
];

export interface FormData {
  name: string;
  contentType: PlaylistContentType;
  orderBy: PlaylistOrdering;
  filterBy: PlaylistFilterBy;
  filterValue: string;
}

interface Props {
  id: string;
  pageTitle: string;
  isManualMode: boolean;
  onSubmit: (data: FormData) => void;
  goBackLink: string;
  onOrderingChange?: (ordering: PlaylistOrdering) => void;
  onContentTypeChange?: (type: PlaylistContentType) => void;
}

export function PlaylistMetadataForm({
  id,
  pageTitle,
  isManualMode,
  onSubmit,
  goBackLink,
  onOrderingChange = noop,
  onContentTypeChange = noop,
}: Props) {
  const metadataForm = useFormContext<FormData>();

  const { register, errors, control, handleSubmit } = metadataForm;

  return (
    <FormProvider {...metadataForm}>
      <form id={id} name="playlist-metadata" onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <BackButton label="Back to playlists" link={goBackLink} />
          <Typography variant="h1" color="textPrimary">
            {pageTitle}
          </Typography>
          <TextInput
            label="Playlist name"
            name="name"
            placeholder="Write a name for your playlist…"
            ref={register({
              validate: validate(isNotEmptyString, 'Name cannot be empty'),
            })}
            error={!!errors.name}
            errorMessage={errors.name?.message}
            maxLength={128}
          />
          <Controller
            render={({ onChange, value, name, ref }) => {
              return (
                <Select
                  innerRef={ref}
                  id="content-type"
                  label="Playlist contains"
                  name={name}
                  value={value}
                  options={CONTENT_TYPE_OPTIONS}
                  onChange={({ currentTarget }) => {
                    onChange(currentTarget.value as PlaylistContentType);
                    onContentTypeChange(currentTarget.value as PlaylistContentType);
                  }}
                />
              );
            }}
            name="contentType"
            control={control}
          />
          <Controller
            render={({ onChange, value, name, ref }) => {
              return (
                <Select
                  innerRef={ref}
                  id="order-by"
                  label="Order by"
                  name={name}
                  value={value}
                  options={ORDER_BY_OPTIONS}
                  onChange={({ currentTarget }) => {
                    onChange(currentTarget.value as PlaylistOrdering);
                    onOrderingChange(currentTarget.value as PlaylistOrdering);
                  }}
                />
              );
            }}
            name="orderBy"
            control={control}
          />
          {!isManualMode && <FilterSection />}
        </Stack>
      </form>
    </FormProvider>
  );
}
