import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

import { Box } from '@material-ui/core';
import { DragDrop } from '@uppy/react';
import React from 'react';
import { filter, tap } from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';

import { Dialog } from 'components/common/Dialog';
import { Stack } from 'components/common/Wrapper/Layout';
import { useUppy } from 'context/uppy';
import { URLS } from 'pages/urls';

import { ProcessingPipelineDropdown } from '../ProcessingPipelineDropdown';

import { LegalNote } from 'components/common/Modal/Modal.styles';

interface OwnProps {
  open: boolean;
  onClose: () => void;
}

const VideoUploadModal = ({ open, onClose }: OwnProps) => {
  const { uppy, uploadStream, refMap, pipelineType, setPipelineType } = useUppy();

  useObservable(() =>
    uploadStream.pipe(
      filter((message) => message.type === 'action' && message.value === 'upload'),
      tap(onClose),
    ),
  );

  const blocked = Object.keys(refMap.current || {}).length !== 0;

  return (
    <Dialog open={open} onClose={onClose} heading="Upload video">
      {!blocked ? (
        <Stack rowGap="20px">
          <Box display="flex" justifyContent="center" width="100%">
            <ProcessingPipelineDropdown
              id="processing-pipeline"
              name="processing-pipeline"
              value={pipelineType}
              onChange={setPipelineType}
            />
          </Box>
          <DragDrop
            uppy={uppy}
            locale={{
              strings: {
                // Text to show on the droppable area.
                // `%{browse}` is replaced with a link that opens the system file selection dialog.
                dropHereOr: 'Drop here or %{browse}',
                // Used as the label for the link that opens the system file selection dialog.
                browse: 'browse',
              },
            }}
          />
          <LegalNote>
            By submitting your videos to Seeen, you acknowledge that you agree to Seeen’s{' '}
            <a href={URLS.termsOfUse} target="_blank" rel="noreferrer noopener">
              <strong>Terms of Service and Community Guidelines</strong>
            </a>
            . Please be sure not to violate others' copyright or privacy rights.{' '}
            <a href={URLS.privacyPolicy} target="_blank" rel="noreferrer noopener">
              <strong>Learn more</strong>
            </a>
            .
          </LegalNote>
        </Stack>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <div>You can upload only one video at a time</div>
        </Box>
      )}
    </Dialog>
  );
};

export default VideoUploadModal;
