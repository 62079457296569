import gql from 'graphql-tag';

import { ERRORS_PART } from 'context/queries/_errors';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  mutation CreateCustomerPortalSession($customerPortalSession: CreateCustomerPortalSessionInput!) {
    createCustomerPortalSession(customerPortalSession: $customerPortalSession) {
      customerPortalUrl
      ${ERRORS_PART}
    }
  }
`;

interface ResponseData {
  createCustomerPortalSession: Nullable<{
    customerPortalUrl: Nullable<string>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  customerPortalSession: {
    returnUrl: string;
  };
}

interface UseCreateCustomerPortalSessionQueryOptions
  extends UseMutationOptions<ResponseData, Variables> {}

export const useCreateCustomerPortalSession = (
  options?: UseCreateCustomerPortalSessionQueryOptions,
) => {
  return useMutation<ResponseData, Variables>(QUERY, {
    pathToErrors: 'createCustomerPortalSession.errors',
    ...options,
  });
};
