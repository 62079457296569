import { env } from 'config/env';
import { Microsite, TagType, TEMP_Microsite } from 'models';
import { Optional } from 'utils/types';

const SEEEN_DOMAIN = 'getseeen.ai';
const ORIGIN = env.SEEEN_APP_ENV === 'prd' ? SEEEN_DOMAIN : `stg.${SEEEN_DOMAIN}`;

export const getMicrositeDomain = (slug?: string) => (slug ? `${slug}.${ORIGIN}` : ORIGIN);

export const getMicrositeOrigin = (domain?: string) => `https://${domain}`;

// TODO: remove that with implementation of multiple tag inputs
export const temp_prepareData = (microsite?: Optional<Microsite>): TEMP_Microsite | undefined => {
  if (!microsite) {
    return undefined;
  }

  const sections = microsite.sections || [];

  return {
    ...microsite,
    sections: sections.map((section) => ({
      ...section,
      tag: section.internalTag ? section.internalTag : section.regularTag,
      tagType: section.internalTag ? TagType.internalTag : TagType.regularTag,
    })),
  };
};
