import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Unpublish } from 'assets/icons/unpublish.svg';
import { DashboardVideoNode, VideoStatus } from 'models';
import { UnpublishVideoModal } from 'pages/PublishVideo';

interface Props {
  video: DashboardVideoNode;
  edge?: 'start' | 'end';
}

export const UnpublishVideoAction: React.FC<Props> = ({ video, edge }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  return (
    <>
      {(video.status === VideoStatus.Published ||
        video.status === VideoStatus.Edited ||
        video.status === VideoStatus.Scheduled) && (
        <Tooltip
          title={video.status === VideoStatus.Scheduled ? 'Cancel scheduled publish' : 'Unpublish'}
          arrow
          placement="top"
        >
          <IconButton color="inherit" edge={edge} onClick={showModal}>
            <Unpublish />
          </IconButton>
        </Tooltip>
      )}
      <UnpublishVideoModal open={isModalVisible} onClose={hideModal} video={video} />
    </>
  );
};
