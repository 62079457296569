import { Box, Button } from '@material-ui/core';

import { FormLabel } from '../FormLabel';

import { Timestamp, TimestampWrapper } from './TimestampBlock.styles';

interface Props {
  title?: string;
  saveButtonText?: string;
  isEdit?: boolean;
  disabled?: boolean;
  hasCancelButton?: boolean;
  hasSaveButton?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
}

export const TimestampBlock: React.FC<Props> = ({
  children,
  title,
  saveButtonText,
  isEdit = false,
  disabled,
  hasCancelButton = false,
  hasSaveButton = false,
  onCancel,
  onSave,
}) => (
  <TimestampWrapper disabled={disabled}>
    {isEdit ? <FormLabel>{title}</FormLabel> : null}
    <Timestamp isEdit={isEdit} disabled={disabled}>
      {children}
      <Box display="flex" justifyContent="space-between">
        {hasCancelButton ? (
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        ) : null}
        {hasSaveButton ? (
          <Button variant="text" color="primary" onClick={onSave}>
            {saveButtonText}
          </Button>
        ) : null}
      </Box>
    </Timestamp>
  </TimestampWrapper>
);
