import { Box, Button } from '@material-ui/core';
import React, { useReducer } from 'react';

import { Link } from 'components/common';
import { useSearch } from 'components/common/Search/Search';
import { Columns } from 'components/common/Wrapper/Layout';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import BasePlayer from 'components/VideoPlayer/BasePlayer';
import { paginationReducer } from 'context/pagination';
import { useObjectsQuery } from 'hooks/query';
import { generateVideoLink } from 'pages/urls';
import ObjectsTableView from 'pages/Video/Content/ObjectsTab/ObjectsTable/ObjectsTableView';
import { getStreamURL } from 'utils/streaming';

import { PromoteContentMainProps } from './types';

export const PromoteContentMain = ({
  video,
  isLoading,
  promoteContentSettings,
}: PromoteContentMainProps) => {
  const sourceURL = getStreamURL(video.videoFileUrl, video.videoStreams);
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.PromoteObjects),
  );
  const [searchText, setSearchText] = useSearch('');

  const { loading, data } = useObjectsQuery({
    variables: {
      ...pagination,
      videoId: video.id,
      search: searchText,
      orderBy: 'startTimestamp,endTimestamp',
    },
  });

  const visibleProducts = data?.objects?.nodes?.filter((item) => item.promote && item.visible);

  return (
    <>
      <Columns alignItems="center" display="flex" justifyContent="flex-end" pb="20px">
        <Button
          component={Link}
          to={generateVideoLink(video.id)}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          type="submit"
          form="external-content-settings"
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </Columns>
      <Box borderRadius="5px 5px 15px 15px">
        <BasePlayer
          url={sourceURL}
          promoteObjectsList={visibleProducts}
          promoteContentSettings={promoteContentSettings || []}
        />
        <Box bgcolor="common.white" boxShadow={5} overflow="hidden" pt="102px">
          {data?.objects?.nodes ? (
            <ObjectsTableView
              objectsLoading={loading}
              objects={data?.objects?.nodes}
              totalCount={data?.objects?.totalCount}
              video={video}
              pageInfo={data?.objects?.pageInfo}
              dispatch={dispatch}
              onSearch={setSearchText}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
};
