import { UPDATE_BUTTON_CTA_MUTATION } from 'context/queries/buttonCTAMutations';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { ButtonCTAResponse, CreateButtonCTAInput } from 'pages/CTAButton/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateButtonCta: Nullable<{
    buttonCta: Nullable<ButtonCTAResponse>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  button_cta: Partial<CreateButtonCTAInput>;
}

interface UseUpdateButtonCTA extends UseMutationOptions<ResponseData, Variables> {}

export const useUpdateButtonCTA = (options: UseUpdateButtonCTA = {}) => {
  return useMutation<ResponseData, Variables>(UPDATE_BUTTON_CTA_MUTATION, {
    pathToErrors: 'updateButtonCta.errors',
    refetchQueries: ({ data }: { data: ResponseData }) =>
      data?.updateButtonCta?.buttonCta ? ['GetButtonCta'] : [],
    ...options,
  });
};
