import { Box, Button } from '@material-ui/core';

import { Dialog } from 'components/common/Dialog';

import { ModalProps } from './types';

import { ModalDescription } from './MonetizationModal.styles';

interface Props {
  onConfirm: () => void;
  heading: string;
  description: string;
  confirmationButtonText: string;
  cancelButtonText?: string;
}

export const ConfirmationModal: React.VFC<ModalProps & Props> = ({
  isOpen,
  onClose,
  onConfirm,
  heading,
  description,
  confirmationButtonText,
  cancelButtonText,
}) => {
  const handleConfirmClick = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={isOpen} heading={heading} width={680}>
      <ModalDescription>{description}</ModalDescription>
      <Box display="flex" flexDirection="row" justifyContent="center" mt="50px">
        <Box mr="18px">
          <Button variant="outlined" onClick={handleConfirmClick} color="secondary">
            {confirmationButtonText}
          </Button>
        </Box>
        <Button variant="contained" onClick={onClose} color="primary">
          {cancelButtonText || 'Cancel'}
        </Button>
      </Box>
    </Dialog>
  );
};
