export const zIndex = {
  modal: 10000,
  hover: 8000,
  player: 1200,
  mEditor: 5100,
};

/**
 * @example 10 => "10px"
 * @example "20%" => "20%"
 */
export function pxOrString(value: number | string) {
  return typeof value === 'number' ? `${value}px` : value;
}

const sansSerifFallback = "system-ui, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif";
export const TEXT_FONT = `Roboto, ${sansSerifFallback}`;
export const CONDENSED_FONT = `'Roboto Condensed', ${sansSerifFallback}`;

export const hexToRgb = (hex: string) => {
  if (!hex) return '';

  const sanitizedHex = hex.replace('#', '');

  const r = parseInt(sanitizedHex.substr(0, 2), 16);
  const g = parseInt(sanitizedHex.substr(2, 2), 16);
  const b = parseInt(sanitizedHex.substr(4, 2), 16);

  return `${r}, ${g}, ${b}`;
};
