import { QUERY_VIDEO_STATUS, UPDATE_VIDEO_MUTATION } from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { EditableVideoI, ErrorType, VideoMetadataFragment } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  updateVideo: Nullable<{
    video: Nullable<VideoMetadataFragment>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  id: string;
  video: EditableVideoI;
}

export const useUpdateVideo = (videoId?: string) => {
  return useMutation<ResponseData, Variables>(UPDATE_VIDEO_MUTATION, {
    pathToErrors: 'updateVideo.errors',
    refetchQueries: ({ data }: { data: VideoMetadataFragment }) => {
      return !!data && videoId ? [{ query: QUERY_VIDEO_STATUS, variables: { id: videoId } }] : [];
    },
  });
};
