import { Button } from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { LoadingButton, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import { pushMessage } from 'context/globalStream';
import { useSafeState } from 'hooks/useSafeState';
import { UserRole } from 'models';

import { RoleDropdown } from './RoleDropdown';
import { OrganizationMemberPick } from './useOrganizationMembers';
import { useUpdateOrganizationMember } from './useUpdateOrganizationMember';

import * as Styled from '../OrganizationTab/ChangeNameModal.styles';

interface FormData {
  role: UserRole;
}

interface Props {
  member: OrganizationMemberPick;
  onClose: () => void;
  isOpen: boolean;
  canRoleBeChanged: boolean;
}

export const UpdateMemberModal: React.VFC<Props> = ({
  member,
  onClose,
  isOpen,
  canRoleBeChanged,
}) => {
  const [isLoading, setIsLoading] = useSafeState(false);

  const [updateMember, , [errorMessage], resetErrorMessage] = useUpdateOrganizationMember();

  const { handleSubmit, formState, control } = useForm<FormData>({
    mode: 'onChange',
  });

  // Read the `formState` before render to subscribe to the form state proxy
  const { isDirty, isValid } = formState;

  const onSubmit = async ({ role }: FormData) => {
    setIsLoading(true);

    const { data } = await updateMember({
      variables: {
        id: member.id,
        organizationMember: {
          role,
        },
      },
    });

    if (data?.updateOrganizationMember?.organizationMember) {
      pushMessage({ message: 'Member has been successfully updated.' });
      onClose();
    } else {
      setIsLoading(false);
    }
  };

  const handleExited = () => {
    resetErrorMessage();
    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      open={isOpen}
      heading={`Edit member`}
      width={600}
    >
      <form name="invite-member" onSubmit={handleSubmit(onSubmit)}>
        <Stack display="flex" alignItems="center" flexDirection="column" mt="50px" rowGap={50}>
          {errorMessage ? (
            <Styled.ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
          ) : null}
          <Stack textAlign="left" width="400px">
            <TextInput name="name" label="Name" defaultValue={member.name} disabled mb="0" />
            <TextInput name="email" label="Email" defaultValue={member.email} disabled mb="0" />
            <Controller
              render={({ onChange, value, name, ref }) => {
                return (
                  <RoleDropdown
                    ref={ref}
                    id="member-role"
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={!canRoleBeChanged}
                  />
                );
              }}
              defaultValue={member.role}
              name="role"
              control={control}
            />
          </Stack>
          <Columns display="flex" flexDirection="row" justifyContent="center">
            <Button variant="outlined" onClick={onClose} color="secondary" type="button">
              Cancel
            </Button>
            <LoadingButton
              disabled={!isValid || !isDirty}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </LoadingButton>
          </Columns>
        </Stack>
      </form>
    </Dialog>
  );
};
