import { QUERY_OBJECTS } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { ObjectOrderBy, PageInfo, PaginationVariables, VideoObjectNode } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  objects: Nullable<{
    nodes: Nullable<VideoObjectNode[]>;
    totalCount: number;
    pageInfo: Nullable<PageInfo>;
  }>;
}

interface Variables extends PaginationVariables {
  orderBy?: ObjectOrderBy;
  search?: string;
  title?: string;
  videoId: string;
}

interface UseObjectsQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useObjectsQuery = (options: UseObjectsQueryOptions) => {
  return useQuery<ResponseData, Variables>(QUERY_OBJECTS, options);
};
