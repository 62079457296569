import { IconButton, InputBase, Select } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Chevron } from 'assets/icons/chevron-up.svg';
import { ReactComponent as FirstPage } from 'assets/icons/fast-forward.svg';
import { DispatchPagination } from 'context/pagination';
import { PageInfo } from 'models';
import { LocalStorage } from 'utils/storage';
import { Optional } from 'utils/types';

import { PostsPerPage, ROWS_PER_PAGE_OPTIONS } from './constants';

import * as Styled from 'components/common/Table/Table.styles';

interface FooterProps {
  children: React.ReactNode;
}

interface IconWrapperProps {
  rotation: string;
}

const IconWrapper = styled.div<IconWrapperProps>`
  transform: rotate(${({ rotation }) => rotation && rotation});
`;

function Footer({ children }: FooterProps) {
  return (
    <tfoot>
      <tr>
        <td colSpan={1000} style={{ padding: 0 }}>
          <Styled.TableFooter>{children}</Styled.TableFooter>
        </td>
      </tr>
    </tfoot>
  );
}

interface PaginationActionsProps {
  dispatch: DispatchPagination;
  pageInfo: Optional<PageInfo>;
}

function PaginationActions({ dispatch, pageInfo }: PaginationActionsProps) {
  const goToFirstPage = () => {
    dispatch({ type: 'first' });
  };
  const goToPreviousPage = () => {
    dispatch({ type: 'previous' });
  };
  const goToNextPage = () => {
    dispatch({ type: 'next' });
  };

  return (
    <Styled.PaginationActionsWrapper>
      <IconButton
        aria-label="first page"
        disabled={pageInfo?.pageNumber === 1}
        onClick={goToFirstPage}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        aria-label="previous page"
        disabled={!pageInfo?.hasPreviousPage}
        onClick={goToPreviousPage}
      >
        <IconWrapper rotation="270deg">
          <Chevron />
        </IconWrapper>
      </IconButton>
      <IconButton aria-label="next page" disabled={!pageInfo?.hasNextPage} onClick={goToNextPage}>
        <IconWrapper rotation="90deg">
          <Chevron />
        </IconWrapper>
      </IconButton>
    </Styled.PaginationActionsWrapper>
  );
}

export interface OwnProps {
  totalCount: number;
  visibleCount: number;
  pageInfo: Optional<PageInfo>;
  dispatch: DispatchPagination;
  tableType?: PostsPerPage;
}

function PaginationFooter({ totalCount, visibleCount, pageInfo, dispatch, tableType }: OwnProps) {
  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.currentTarget.value);
      dispatch({ type: 'update_size', value });
      if (tableType) LocalStorage.set(tableType, value);
    },
    [dispatch, tableType],
  );

  return (
    <Footer>
      <Styled.FooterResults>
        {totalCount > 0 ? `${visibleCount} of ${totalCount} results` : 'No results'}
      </Styled.FooterResults>
      <Styled.FooterPaginationWrapper>
        {ROWS_PER_PAGE_OPTIONS.length > 1 && (
          <Styled.FooterPageSize>
            <Styled.PaginationResultsLabel>Results per page:</Styled.PaginationResultsLabel>
            <Select
              input={<InputBase style={{ width: 'auto' }} />}
              value={pageInfo?.pageSize ?? ''}
              // @ts-ignore: poor types of @material-ui
              onChange={handleChangeRowsPerPage}
              inputProps={{ 'aria-label': 'rows per page' }}
              native
            >
              {ROWS_PER_PAGE_OPTIONS.map((rowsPerPageOption) => (
                <option key={rowsPerPageOption} value={rowsPerPageOption}>
                  {rowsPerPageOption}
                </option>
              ))}
            </Select>
          </Styled.FooterPageSize>
        )}
        {pageInfo && pageInfo.totalPages > 0 && (
          <Styled.FooterPageNumber>
            Page {pageInfo.pageNumber} of {pageInfo.totalPages}
          </Styled.FooterPageNumber>
        )}
        <PaginationActions dispatch={dispatch} pageInfo={pageInfo} />
      </Styled.FooterPaginationWrapper>
    </Footer>
  );
}

export default PaginationFooter;
