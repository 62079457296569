import styled from 'styled-components/macro';

import { Table } from 'components/Table';

export const Row = styled(Table.Row)`
  height: 80px;
`;

export const AvatarCell = styled.div`
  font-size: 0;
`;
