import { TableCellProps } from '@material-ui/core';
import React from 'react';

import { Columns } from 'components/common/Wrapper/Layout';
import { RouterLink } from 'utils/types';

import { DeleteAction } from './DeleteAction';
import { ViewEditAction } from './ViewEditAction';

import * as Styled from 'components/common/Table/Table.styles';

interface OwnProps {
  type: 'moment' | 'object';
  canUserChangeIt: boolean;
  editUrl: RouterLink;
  canRetry?: boolean;
  onRetry?: () => Promise<any>;
  canDuplicate?: boolean;
  onDuplicate?: () => Promise<any>;
  canDelete: boolean;
  onDelete: () => Promise<any>;
  customViewAction?: React.ReactNode;
}

// TODO: deprecate this component in favor of TableActions (displayed on hover)
const TableActionCell: React.FC<OwnProps & TableCellProps> = ({
  type,
  canUserChangeIt,
  editUrl,
  canDelete,
  onDelete,
  colSpan,
  align,
  customViewAction,
}) => {
  return (
    <Styled.ActionsCell align={align || 'right'} colSpan={colSpan || 1}>
      <Columns columnGap={5}>
        {!customViewAction ? (
          <ViewEditAction url={editUrl} canUserChangeIt={canUserChangeIt} edge="end" />
        ) : (
          customViewAction
        )}
        {canDelete && <DeleteAction onDelete={onDelete} type={type} edge="end" />}
      </Columns>
    </Styled.ActionsCell>
  );
};

export default TableActionCell;
