import { Box } from '@material-ui/core';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { LabelledCheckbox } from 'components/common/LabelledCheckbox';
import { URLS } from 'pages/urls';

const StyledLink = styled.a`
  font-weight: bold;
  color: ${materialTheme.palette.primary.main};
  text-decoration: underline;
`;

interface FormData {
  terms: boolean;
  privacy: boolean;
}

interface Props {
  control: Control<FormData>;
  errors: FieldErrors<FormData>;
  termsOfUseUrl: string;
}

export const LegalAgreements: React.VFC<Props> = ({ control, errors, termsOfUseUrl }) => {
  return (
    <Box>
      <Controller
        control={control}
        name="terms"
        defaultValue={false}
        rules={{ required: 'Required' }}
        render={({ name, onChange, ref, value }) => (
          <LabelledCheckbox
            name={name}
            value={value}
            label={
              <>
                I have read and accept the{' '}
                <StyledLink href={termsOfUseUrl} target="_blank" rel="noopener">
                  Terms and conditions
                </StyledLink>
                .
              </>
            }
            onChange={onChange}
            errorMessage={errors.terms?.message}
            ref={ref}
          />
        )}
      />
      <Controller
        control={control}
        name="privacy"
        defaultValue={false}
        rules={{ required: 'Required' }}
        render={({ name, onChange, ref, value }) => (
          <LabelledCheckbox
            name={name}
            value={value}
            label={
              <>
                I have read and accept the{' '}
                <StyledLink href={URLS.privacyPolicy} target="_blank" rel="noopener">
                  Privacy policy
                </StyledLink>
                .
              </>
            }
            onChange={onChange}
            errorMessage={errors.privacy?.message}
            ref={ref}
          />
        )}
      />
    </Box>
  );
};
