import { Box } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';

import { AddButton } from 'components/common/AddButton';
import {
  seekToMomentStart,
  source$,
  togglePlayback,
  useVideoMetadataObservable,
} from 'context/VideoMetadataStream';
import { Nullable } from 'utils/types';

import { EMPTY_TIMESTAMP_FIELD } from '../constants';
import { CTATimestamp } from '../type';
import { VideoCTAPageContext } from '../useTimestampContext';
import { TimestampForm } from './TimestampForm';
import { TimestampItem } from './TimestampItem';

interface Props {}

export const TimestampList: React.FC<Props> = () => {
  const { played } = useVideoMetadataObservable();
  const [currentPlayTimestampId, setCurrentPlayTimestampId] = useState<CTATimestamp['id']>('');
  const [currentTimestamp, setCurrentTimestamp] = useState<Nullable<CTATimestamp>>(null);
  const [currentEndTime, setCurrentEndTime] = useState(source$.getValue().duration);

  const {
    timestamps,
    updateTimestamps,
    updateCurrentCTAs,
    createCurrentTimestamp,
    removeCurrentTimestamp,
    hideCurrentTimestampForm,
  } = useContext(VideoCTAPageContext);

  const showCurrentTimestampForm = () => {
    setCurrentTimestamp(EMPTY_TIMESTAMP_FIELD);
  };

  const onEdit = (timestamp: CTATimestamp) => {
    setCurrentTimestamp(timestamp);
    updateCurrentCTAs(timestamp.ctas);
  };

  const onSubmit = (timestamp: CTATimestamp) => {
    updateTimestamps(timestamps.map((item) => (item.id === timestamp.id ? timestamp : item)));
    setCurrentTimestamp(null);
  };

  const onCreate = (timestamp: CTATimestamp) => {
    createCurrentTimestamp(timestamp);
    setCurrentTimestamp(null);
  };

  const onCancel = () => {
    hideCurrentTimestampForm();
    setCurrentTimestamp(null);
  };

  const startPlaying = (timestamp: CTATimestamp) => {
    setCurrentPlayTimestampId(timestamp.id);
    setCurrentEndTime(timestamp.ranges[1]);
    seekToMomentStart(timestamp.ranges[0]);
    updateCurrentCTAs(timestamp.ctas);

    if (!source$.getValue().playing) {
      togglePlayback();
    }
  };

  useEffect(() => {
    if (source$.getValue().playing && currentEndTime <= source$.getValue().played) {
      togglePlayback();
      seekToMomentStart(1);
      setCurrentPlayTimestampId('');
      updateCurrentCTAs([]);
    } else if (!source$.getValue().playing) {
      setCurrentEndTime(source$.getValue().duration);
      setCurrentPlayTimestampId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [played]);

  return (
    <>
      <Box mt="15px" mb="40px">
        <AddButton
          label="Add timestamp"
          onClick={showCurrentTimestampForm}
          disabled={!!currentTimestamp}
        />
      </Box>
      {currentTimestamp?.id === EMPTY_TIMESTAMP_FIELD.id ? (
        <TimestampForm item={currentTimestamp} onSubmit={onCreate} onCancel={onCancel} />
      ) : null}
      {timestamps.map((item) => {
        if (currentTimestamp?.id === item.id) {
          return (
            <TimestampForm
              key={item.id}
              item={currentTimestamp}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          );
        } else {
          return (
            <TimestampItem
              key={item.id}
              item={item}
              disabled={!!currentTimestamp}
              onRemove={() => removeCurrentTimestamp(item?.id)}
              onEdit={() => onEdit(item)}
              onStartPlaying={() => startPlaying(item)}
              isCurrentPlayTimestampId={item.id === currentPlayTimestampId}
            />
          );
        }
      })}
    </>
  );
};
