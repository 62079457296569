import React from 'react';

import { msToHMSString, msToSeconds } from 'utils/time';

interface OwnProps {
  ms: number;
  skipMilliseconds?: boolean;
}

const Duration = ({ ms, skipMilliseconds = true }: OwnProps) => {
  // https://www.w3.org/TR/2014/REC-html5-20141028/infrastructure.html#valid-duration-string
  return (
    <time dateTime={`P${Math.round(msToSeconds(ms))}S`}>
      {msToHMSString(ms, { skipMilliseconds })}
    </time>
  );
};

export default React.memo(Duration);
