import React from 'react';

import { Spinner } from 'components/common';
import { useSearch } from 'components/common/Search/Search';
import { useSorting } from 'context/sorting';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';

import { ActionContentWidthHandler } from '../types';
import { SocialMediaTable } from './SocialMediaTable';

import * as Styled from 'components/common/Table/Table.styles';

export const SocialMediaTab: React.FC<ActionContentWidthHandler> = ({ getActionContentWidth }) => {
  const [searchText, setSearchText] = useSearch('');
  const { orderBy, sorting, setSorting } = useSorting<any>('-created');
  const { loading, data } = useOrganizationQuery({
    variables: {
      search: searchText,
      orderBy,
    },
  });

  const socialMedia = data?.me?.organization?.socialMedia;

  if (loading && !socialMedia) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  return (
    <SocialMediaTable
      socialsLoading={loading}
      socials={socialMedia}
      setSorting={setSorting}
      sorting={sorting}
      onSearch={setSearchText}
      getActionContentWidth={getActionContentWidth}
    />
  );
};
