import { Tooltip } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { ConfirmationModal } from 'components/common/Modals';
import MemoToggle from 'components/common/Table/MemoToggle';

import {
  DEACTIVATION_MODAL_DEFAULT_DESCRIPTION,
  DEACTIVATION_MODAL_DEFAULT_HEADING,
  DEACTIVATION_MODAL_DESCRIPTION,
  DEACTIVATION_MODAL_HEADING,
  DISABLED_TOOLTIP_TITLE,
} from '../constants';
import { ToggleProps } from '../types';

interface Props {
  isDefaultTag: boolean;
}

export const ActiveToggle: React.VFC<ToggleProps & Props> = ({
  checked,
  disabled,
  onChange,
  isDefaultTag,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleToggleChange = useCallback(() => {
    if (checked) {
      setIsModalOpen(true);
    } else {
      onChange();
    }
  }, [checked, onChange]);

  return (
    <>
      <Tooltip title={disabled ? DISABLED_TOOLTIP_TITLE : ''} arrow placement="top">
        <span>
          <MemoToggle checked={checked} disabled={disabled} onChange={handleToggleChange} />
        </span>
      </Tooltip>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={onChange}
        heading={isDefaultTag ? DEACTIVATION_MODAL_DEFAULT_HEADING : DEACTIVATION_MODAL_HEADING}
        description={
          isDefaultTag ? DEACTIVATION_MODAL_DEFAULT_DESCRIPTION : DEACTIVATION_MODAL_DESCRIPTION
        }
        confirmationButtonText="Deactivate"
      />
    </>
  );
};
