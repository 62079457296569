import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Duplicate } from 'assets/icons/duplicate.svg';
import { LoadingAction } from 'components/tables/TableActionCell';
import { useSafeState } from 'hooks/useSafeState';

interface Props {
  onDuplicate: () => Promise<any>;
  edge?: 'start' | 'end';
}

export const DuplicateVideoAction: React.FC<Props> = ({ onDuplicate, edge }) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const handleDuplicate = React.useCallback(async () => {
    setIsLoading(true);
    await onDuplicate();
    setIsLoading(false);
  }, [onDuplicate, setIsLoading]);

  return isLoading ? (
    <LoadingAction />
  ) : (
    <Tooltip title="Duplicate" arrow placement="top">
      <IconButton color="inherit" edge={edge} onClick={handleDuplicate}>
        <Duplicate />
      </IconButton>
    </Tooltip>
  );
};
