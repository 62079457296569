import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';

import { BackButton, Link, TextInput, Typography } from 'components/common';
import { Columns } from 'components/common/Wrapper/Layout';
import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { URLS } from 'pages/urls';

import { CustomCodeEndCardPreview } from './EditCustomCodeEndCardPreview';
import { CustomCodeEndCardFormData, EditCustomCodeEndCardProps } from './types';

const FORM_ID = 'edit-custon-code-end-card';

export const EditCustomCodeEndCardView: React.FC<EditCustomCodeEndCardProps> = ({
  handleSave,
  endCard,
  isLoading,
}) => {
  const { handleSubmit, errors, register, watch } = useForm<CustomCodeEndCardFormData>({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const html = watch('customCode', endCard?.customCode);

  return (
    <ExtensibleSidebar
      sideContent={
        <form id={FORM_ID} onSubmit={handleSubmit(handleSave)}>
          <BackButton label="Back to End Cards" link={URLS.overlays.endCards} />
          <Box m="22px 0 32px 0">
            <Typography variant="h1" color="textPrimary">
              Custom code
            </Typography>
          </Box>
          <TextInput
            label="Template name*"
            name="name"
            placeholder="Type template name"
            defaultValue={endCard?.name}
            ref={register({ required: true })}
            error={!!errors?.name}
            errorMessage={errors.name?.message}
            maxLength={200}
          />
          <TextInput
            multiline
            label="Custom code*"
            name="customCode"
            placeholder="Type template name"
            defaultValue={endCard?.customCode}
            ref={register({ required: true })}
            error={!!errors?.customCode}
            errorMessage={errors.customCode?.message}
            rows={24}
          />
        </form>
      }
      mainContent={
        <>
          <Columns display="flex" alignItems="center" justifyContent="flex-end" pb="20px">
            {isLoading && <Typography variant="h6">{'Saving...'}</Typography>}
            <Button
              component={Link}
              to={URLS.overlays.endCards}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!!isLoading}
              color="primary"
              form={FORM_ID}
              type="submit"
            >
              Save
            </Button>
          </Columns>
          <CustomCodeEndCardPreview htmlContent={html} />
        </>
      }
    />
  );
};
