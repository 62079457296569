import React, { useState } from 'react';

import { CarouselI } from 'models';
import EmbedCarouselModal from 'pages/Widget/EmbedCarouselModal';

import * as Styled from './PublishButton.styled';

interface CreateCarouselModalButtonProps {
  carousel: CarouselI;
}

const PublishButton = ({ carousel }: CreateCarouselModalButtonProps) => {
  const [showEmbedCarouselModal, setShowEmbedCarouselModal] = useState(false);

  return (
    <>
      <Styled.PublishButton variant="contained" onClick={() => setShowEmbedCarouselModal(true)}>
        Get the code
      </Styled.PublishButton>
      <EmbedCarouselModal
        open={showEmbedCarouselModal}
        carousel={carousel}
        handleClose={() => setShowEmbedCarouselModal(false)}
      />
    </>
  );
};

export default PublishButton;
