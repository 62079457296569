export const PAGINATION_PART = `
  totalCount
  pageInfo {
    hasNextPage
    hasPreviousPage
    totalPages
    pageNumber
    pageSize
  }
`;
