import { OverlaysFontFamilyOption } from 'constants/fonts';
import { Nullable } from 'utils/types';

export interface CreateButtonCTAInput {
  name: string;
  url: string;
  text: {
    text: string;
    fontFamily: Nullable<OverlaysFontFamilyOption>;
    fontSize: string;
    fontColor: string;
  };
  background: {
    backgroundColor: string;
    backgroundOpacity: string;
  };
  border: {
    borderWidth: string;
    borderColor: string;
    borderRadius: string;
  };
  icon: {
    hasIcon: boolean;
    iconValue: Nullable<string>;
    iconColor: string;
  };
}

export type ButtonCTAResponse = { id: string; created: string } & CreateButtonCTAInput;

export interface OverlaysCTAButton {
  id: string;
  name: string;
  url: string;
  text: string;
  fontFamily: Nullable<OverlaysFontFamilyOption>;
  fontSize: string;
  fontColor: string;
  hasIcon: boolean;
  iconValue: Nullable<string>;
  iconColor: string;
  backgroundColor: string;
  backgroundOpacity: string;
  borderWidth: string;
  borderColor: string;
  borderRadius: string;
}

export type IconComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

export interface OverlaysIconOptions {
  label: string;
  value: string;
  Component: IconComponent;
  ShapeComponent: Nullable<IconComponent>;
}

export enum Icons {
  ArrowRight = 'ARROW_RIGHT',
  Bookmark = 'BOOKMARK',
  Cart = 'CART',
  CheckCircle = 'CHECK',
  ChevronRight = 'CHEVRON_RIGHT',
  Coffee = 'COFFEE',
  Discount = 'DISCOUNT',
  DollarSign = 'DOLLAR_SIGN',
  Gift = 'GIFT',
  Heart = 'HEART',
  InfoCircle = 'INFO',
  PlayCircle = 'PLAY',
  Share = 'SHARE',
  ShoppingBag = 'SHOPPING_BAG',
  Star = 'STAR',
}

export enum IconShapes {
  Circle = 'CIRCLE',
  Star = 'STAR',
  Plain = 'PLAIN',
}

export interface CTAButtonViewProps {
  isLoading: boolean;
  isCreated?: boolean;
  buttonData?: OverlaysCTAButton;
  handleSave: (formData: OverlaysCTAButton) => Promise<void>;
}
