import gql from 'graphql-tag';
import { unionWith } from 'ramda';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { useSafeState } from 'hooks/useSafeState';
import { VideoI } from 'models';
import { TranscriptionTokenType, VideoTranscriptionSegment } from 'models/VideoTranscription';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetVideoTranscription(
    $videoId: ID!
    $page: Int
    $pageSize: Int
    $startTimestampFrom: Float
    $endTimestampTo: Float
    $timestampsRangeFrom: Float
    $timestampsRangeTo: Float
    $tokenType: TranscriptionTokenType
    $speakerLabel: String
    $search: String
  ) {
    videoById(id: $videoId) {
      id
      transcription {
        id
        languageCode
        segments(
          speakerLabel: $speakerLabel
          timestampsRangeFrom: $timestampsRangeFrom
          timestampsRangeTo: $timestampsRangeTo
          page: $page
          pageSize: $pageSize
          search: $search
        ) {
          totalCount
          nodes {
            id
            startTimestamp
            endTimestamp
            speakerLabel
            tokens(
              tokenType: $tokenType
              startTimestampFrom: $startTimestampFrom
              endTimestampTo: $endTimestampTo
            ) {
              id
              startTimestamp
              endTimestamp
              token
              tokenType
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            totalPages
            pageNumber
            pageSize
          }
        }
      }
    }
  }
`;

const SEGMENT_PAGE_SIZE = 30;

interface ResponseData {
  videoById: Nullable<Pick<VideoI, 'id' | 'transcription'>>;
}

interface Variables {
  videoId: string;
  page?: number;
  pageSize?: number;
  /** milliseconds */
  startTimestampFrom?: number;
  /** milliseconds */
  endTimestampTo?: number;
  /** milliseconds */
  timestampsRangeFrom?: number;
  /** milliseconds */
  timestampsRangeTo?: number;
  tokenType?: TranscriptionTokenType;
  speakerLabel?: string;
  search?: string;
}

interface UseVideoTranscriptOptions extends UseQueryOptions<ResponseData, Variables> {
  variables: Variables;
}

export const useVideoTranscription = ({ variables, ...options }: UseVideoTranscriptOptions) => {
  const [page, setPage] = useSafeState(1);

  const [segments, setSegments] = useSafeState<VideoTranscriptionSegment[]>([]);
  const [searchMatchingSegmentIds, setSearchMatchingSegmentIds] = useSafeState<string[]>([]);

  const { search, ...restVariables } = variables;

  const transcriptionResponse = useQuery<ResponseData, Variables>(QUERY, {
    variables: {
      page,
      pageSize: SEGMENT_PAGE_SIZE,
      ...restVariables,
    },
    ...options,
    onCompleted(data) {
      const newSegments = (data?.videoById?.transcription?.segments.nodes || []).map((segment) => {
        return {
          ...segment,
          speakerLabel: formatSpeakerLabel(segment.speakerLabel),
        };
      });
      setSegments((oldSegments) => {
        return unionWith((a, b) => a.id === b.id, oldSegments, newSegments);
      });
    },
  });

  useQuery<ResponseData, Variables>(QUERY, {
    variables: {
      // Search through all already loaded pages.
      page: 1,
      pageSize: page * SEGMENT_PAGE_SIZE,
      search,
      ...restVariables,
    },
    skip: !search,
    onCompleted(data) {
      setSearchMatchingSegmentIds(
        data?.videoById?.transcription?.segments.nodes?.map(({ id }) => id) || [],
      );
    },
  });

  const segmentsWithMatching =
    searchMatchingSegmentIds.length > 0
      ? segments.map((segment) => {
          if (searchMatchingSegmentIds.includes(segment.id)) {
            return { ...segment, isSearchMatching: true };
          }
          return segment;
        })
      : segments;

  return {
    ...transcriptionResponse,
    transcription: transcriptionResponse.data?.videoById?.transcription,
    segments: segmentsWithMatching,
    loadPage: setPage,
  };
};

function formatSpeakerLabel(label: string) {
  const regex = /^spk_(\d+)$/;
  const match = label.match(regex);
  const number = match?.[1];
  return number ? `Speaker ${Number(number) + 1}` : label;
}
