import {
  DELETE_MOMENT_THUMBNAIL_MUTATION,
  DELETE_OBJECT_THUMBNAIL_MUTATION,
  DELETE_VIDEO_THUMBNAIL_MUTATION,
  GET_MOMENT_THUMBNAILS,
  GET_OBJECT_THUMBNAILS,
  GET_VIDEO_THUMBNAILS,
  QUERY_VIDEO_STATUS,
} from 'context/queries';
import { useMutation } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

interface Variables {
  id: string;
}

// Video

interface VideoResponseData {
  deleteVideoThumbnail: Nullable<{
    deletedVideoThumbnailId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

export const useDeleteVideoThumbnail = (videoId: string) => {
  return useMutation<VideoResponseData, Variables>(DELETE_VIDEO_THUMBNAIL_MUTATION, {
    pathToErrors: 'deleteVideoThumbnail.errors',
    refetchQueries: ({ data }: { data: VideoResponseData }) => {
      const isSuccess = !!data.deleteVideoThumbnail?.deletedVideoThumbnailId;
      return isSuccess ? [{ query: GET_VIDEO_THUMBNAILS, variables: { videoId } }] : [];
    },
    optimisticResponse: ({ id }) => {
      return {
        deleteVideoThumbnail: {
          deletedVideoThumbnailId: id,
          errors: [],
          __typename: 'DeleteVideoThumbnailMutation',
        },
      };
    },
  });
};

// Moment

interface MomentResponseData {
  deleteMomentThumbnail: Nullable<{
    deletedMomentThumbnailId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

export const useDeleteMomentThumbnail = (momentId: string, videoId: string) => {
  return useMutation<MomentResponseData, Variables>(DELETE_MOMENT_THUMBNAIL_MUTATION, {
    pathToErrors: 'deleteMomentThumbnail.errors',
    refetchQueries: ({ data }: { data: MomentResponseData }) => {
      const isSuccess = !!data.deleteMomentThumbnail?.deletedMomentThumbnailId;
      return isSuccess
        ? [
            { query: GET_MOMENT_THUMBNAILS, variables: { momentId } },
            { query: QUERY_VIDEO_STATUS, variables: { id: videoId } },
          ]
        : [];
    },
    optimisticResponse: ({ id }) => {
      return {
        deleteMomentThumbnail: {
          deletedMomentThumbnailId: id,
          errors: [],
          __typename: 'DeleteMomentThumbnailMutation',
        },
      };
    },
  });
};

// Object

interface ObjectResponseData {
  deleteObjectAnnotationThumbnail: Nullable<{
    deletedObjectAnnotationThumbnailId: Nullable<string>;
    errors: ErrorType[];
  }>;
}

export const useDeleteObjectThumbnail = (objectId: string) => {
  return useMutation<ObjectResponseData, Variables>(DELETE_OBJECT_THUMBNAIL_MUTATION, {
    pathToErrors: 'deleteObjectAnnotationThumbnail.errors',
    refetchQueries: ({ data }: { data: ObjectResponseData }) => {
      const isSuccess = !!data.deleteObjectAnnotationThumbnail?.deletedObjectAnnotationThumbnailId;
      return isSuccess ? [{ query: GET_OBJECT_THUMBNAILS, variables: { objectId } }] : [];
    },
    optimisticResponse: ({ id }) => {
      return {
        deleteObjectAnnotationThumbnail: {
          deletedObjectAnnotationThumbnailId: id,
          errors: [],
          __typename: 'DeleteObjectAnnotationThumbnailMutation',
        },
      };
    },
  });
};
