import gql from 'graphql-tag';

import { PAGINATION_PART } from './_pagination';
import { THUMBNAILS_PART } from './thumbnails';

export const MOMENT_METADATA_FRAGMENT = gql`
  fragment MomentMetadata on MomentType {
    description
    id
    thumbnailUrl
    title
    video {
      id
      status
      thumbnailUrl
      title
      publications {
        ... on SelfPublicationType {
          id
          publicUrl
          publishingTargetId
        }
      }
    }
    timeRange
    visible
    endCard {
      id
    }
    tags {
      value
    }
    internalTags {
      value
    }
  }
`;

export const QUERY_VIDEO_MOMENTS = gql`
  ${MOMENT_METADATA_FRAGMENT}

  query GetMomentsForVideo(
    $title: String
    $videoId: ID
    $search: String
    $page: Int
    $pageSize: Int
    $orderBy: String = ""
  ) {
    moments(
      title: $title
      videoId: $videoId
      search: $search
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
    ) {
      nodes {
        ...MomentMetadata
        created
        source
        timeRange
        isPublished
        ${THUMBNAILS_PART}
      }
      ${PAGINATION_PART}
    }
  }
`;

export const QUERY_MOMENT_BY_ID = gql`
  ${MOMENT_METADATA_FRAGMENT}

  query GetMoment($id: ID!) {
    momentById(id: $id) {
      ...MomentMetadata
      tags {
        value
      }
      internalTags {
        value
      }
      timeRange
      video {
        duration
        videoFileUrl
        status
        videoStreams {
          standard
          id
          manifestFileUrl
        }
      }
      endCard {
        id
      }
    }
  }
`;

export const MOMENTS_LIST_QUERY = gql`
  ${MOMENT_METADATA_FRAGMENT}

  query GetMoments($pageSize: Int!, $page: Int!, $orderBy: String, $search: String) {
    moments(pageSize: $pageSize, page: $page, orderBy: $orderBy, search: $search) {
      nodes {
        ...MomentMetadata
        created
        isPublished
        source
        video {
          id
          status
          title
          publications {
            ... on SelfPublicationType {
              id
              publishingTargetId
            }
          }
        }
      }
      ${PAGINATION_PART}
    }
  }
`;

export const PUBLIC_MOMENT_URL_QUERY = gql`
  query GetPublicMomentUrl($id: ID!, $publishingTargetId: ID!) {
    momentById(id: $id) {
      id
      publicMomentUrl(publishingTargetId: $publishingTargetId)
    }
  }
`;

export const IS_MOMENT_PUBLISHED_QUERY = gql`
  query IsMomentPublished($id: ID!) {
    momentById(id: $id) {
      id
      isPublished
    }
  }
`;
