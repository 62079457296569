import { MICROSITE_QUERY } from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { Microsite } from 'models';
import { Nullable } from 'utils/types';

interface ResponseData {
  micrositeById: Nullable<Microsite>;
}

interface Variables {
  id: string;
}

interface UseMicrositeQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useMicrositeQuery = (options?: UseMicrositeQueryOptions) => {
  return useQuery<ResponseData, Variables>(MICROSITE_QUERY, options);
};
