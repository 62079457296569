import { useState } from 'react';

import { DEFAULT_TIMESTAMP_LENGTH } from 'config/constants';
import { TimeRange } from 'models';
import { notEmpty } from 'utils/common';
import { ValidationError } from 'utils/errors';
import {
  checkOverlappingTimestamps,
  generateOverlappingErrorMessage,
  OverlappingPositions,
  OverlappingTimestampTypes,
  TimestampsTypes,
} from 'utils/overlapping';
import { hmsStringToMs, msToSeconds, timeRangeToHMSStrings, timeRangeToString } from 'utils/time';
import { Nullable } from 'utils/types';

enum InputValidationError {
  InvalidFormat,
  RangeOutOfBoundaries,
  EndSmaller,
  RangeTooSmall,
  Overlapping,
  Other,
}

interface UseInputValidationProps {
  videoDuration?: Nullable<number>;
  allTimestamps: TimestampsTypes[];
}

export const useTimestampInputValidation = ({
  videoDuration,
  allTimestamps,
}: UseInputValidationProps) => {
  const [startTimestampOverlap, setStartTimestampOverlap] = useState<boolean>(false);
  const [endTimestampOverlap, setEndTimestampOverlap] = useState<boolean>(false);
  const [overlappingError, setOverlappingError] = useState<string>('');
  const [error, setError] = useState<Nullable<InputValidationError>>();
  const [newTimeRange, setNewTimeRange] = useState<TimeRange>([0, DEFAULT_TIMESTAMP_LENGTH]);
  const [range, setRange] = useState(timeRangeToHMSStrings([0, DEFAULT_TIMESTAMP_LENGTH]));

  const updateNewTimeRange = (range: TimeRange) => {
    setNewTimeRange(range);
    setError(null);
    setStartTimestampOverlap(false);
    setEndTimestampOverlap(false);
    setOverlappingError('');
  };

  const handleOverlappingMoments = (overlappedMoments: OverlappingTimestampTypes[]) => {
    overlappedMoments.forEach((item) => {
      if (
        item.overlappingPosition === OverlappingPositions.Inside ||
        item.overlappingPosition === OverlappingPositions.Outside
      ) {
        setStartTimestampOverlap(true);
        setEndTimestampOverlap(true);
      }

      if (item.overlappingPosition === OverlappingPositions.Start) {
        setStartTimestampOverlap(true);
        setEndTimestampOverlap(false);
      }

      if (item.overlappingPosition === OverlappingPositions.End) {
        setEndTimestampOverlap(true);
        setStartTimestampOverlap(false);
      }
    });

    setOverlappingError(
      generateOverlappingErrorMessage(
        overlappedMoments as OverlappingTimestampTypes[],
        newTimeRange[0],
        newTimeRange[1],
      ),
    );

    return {
      error: InputValidationError.Overlapping,
    };
  };

  function isRangeOutOfBoundaries([start, end]: TimeRange, timeRange: TimeRange) {
    return (
      timeRange[0] === timeRange[1] ||
      timeRange.some((timestamp) => timestamp < start || timestamp > end)
    );
  }

  const validateInput = ({ newStart, newEnd }: { newStart?: string; newEnd?: string }) => {
    const newValue = [...range];
    if (newStart !== undefined) {
      newValue[0] = newStart;
    }
    if (newEnd !== undefined) {
      newValue[1] = newEnd;
    }

    let newTimeRange: TimeRange;

    try {
      newTimeRange = newValue.map(hmsStringToMs) as TimeRange;
    } catch (error) {
      if (error instanceof ValidationError) {
        return { error: InputValidationError.InvalidFormat };
      }
      return { error: InputValidationError.Other };
    }

    if (isRangeOutOfBoundaries([0, videoDuration || 0], newTimeRange)) {
      return { error: InputValidationError.RangeOutOfBoundaries };
    }

    if (newTimeRange[0] >= newTimeRange[1]) {
      return { error: InputValidationError.EndSmaller };
    }

    if (newTimeRange[1] - newTimeRange[0] < DEFAULT_TIMESTAMP_LENGTH) {
      return { error: InputValidationError.RangeTooSmall };
    }

    const overlappedMoments: OverlappingTimestampTypes[] = allTimestamps
      .map((timestamps: TimestampsTypes) =>
        checkOverlappingTimestamps(timestamps, newTimeRange[0], newTimeRange[1]),
      )
      .filter(notEmpty);

    if (overlappedMoments.length) {
      return handleOverlappingMoments(overlappedMoments);
    }

    updateNewTimeRange(newTimeRange);
    return {
      newTimeRange,
    };
  };

  const getErrorMessage = (error?: Nullable<InputValidationError>) => {
    if (error === InputValidationError.InvalidFormat) {
      return (
        <>
          Start/End timestamps need to be in valid time format (
          <code>hour:minute:second.milliseconds</code>
          ).
        </>
      );
    }
    if (error === InputValidationError.RangeOutOfBoundaries) {
      return `Start/End timestamps need to be in video duration range (
        ${timeRangeToString([0, videoDuration || 0], { separator: '-' })}).`;
    }
    if (error === InputValidationError.Overlapping) {
      return overlappingError;
    }
    if (error === InputValidationError.EndSmaller) {
      return 'Start timestamp needs to be smaller than End timestamp.';
    }
    if (error === InputValidationError.RangeTooSmall) {
      return `Provided range needs to be at least ${msToSeconds(
        DEFAULT_TIMESTAMP_LENGTH,
      )} seconds long.`;
    }
    return '';
  };

  const getNextAvailableTimeSlotStartTime = (timestamps: any[]) => {
    let suggestNextStartTime = 0;

    for (let i = 0; i < timestamps.length; i++) {
      const startTime = timestamps[i].ranges[0];
      const endTime = timestamps[i].ranges[1];
      const nextIndex = i + 1;
      const nextStartTime = timestamps?.[nextIndex]?.ranges?.[0];
      const nextEndTime = timestamps?.[nextIndex]?.ranges?.[1];

      if (startTime >= DEFAULT_TIMESTAMP_LENGTH && timestamps.length === 1) {
        // if current timestamp is further on timeline and its first timestamp
        suggestNextStartTime = 0;
        break;
      }

      if (startTime >= DEFAULT_TIMESTAMP_LENGTH && timestamps.length > 1 && !suggestNextStartTime) {
        // if current timestamp is further on timeline and its first timestamp
        suggestNextStartTime = 0;

        continue;
      }

      if (!nextStartTime && !suggestNextStartTime) {
        // if theres only one timestamp  suggest exdtime as new timestamp

        suggestNextStartTime = endTime;
        break;
      }

      if (nextStartTime - endTime >= DEFAULT_TIMESTAMP_LENGTH) {
        // if find 15s slot

        suggestNextStartTime = endTime;
        break;
      }

      if (nextStartTime - endTime < DEFAULT_TIMESTAMP_LENGTH) {
        // if no time

        suggestNextStartTime = nextEndTime;
        continue;
      }
    }
    return suggestNextStartTime;
  };

  return {
    startTimestampOverlap,
    endTimestampOverlap,
    error,
    setError,
    validateInput,
    range,
    setRange,
    errorMessage: getErrorMessage(error),
    getNextAvailableTimeSlotStartTime,
  };
};
