import gql from 'graphql-tag';

import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { AuthorizedServices, OAuthService } from 'models';
import { Nullable } from 'utils/types';

const QUERY = gql`
  query GetUserServices($service: OAuthService) {
    me {
      id
      authorizedServices(service: $service) {
        accountName
        service
      }
    }
  }
`;

interface ResponseData {
  me: {
    id: string;
    authorizedServices: Nullable<AuthorizedServices[]>;
  };
}

interface Variables {
  service?: OAuthService;
}

interface UseUserServicesQueryOptions extends UseQueryOptions<ResponseData, Variables> {}

export const useUserServicesQuery = (options: UseUserServicesQueryOptions) => {
  return useQuery<ResponseData, Variables>(QUERY, options);
};
