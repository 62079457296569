import { ImageDimensions } from 'utils/files';

// users

export const MIN_PASSWORD_LENGTH = 8;

// moments

export const MIN_TIMESTAMP_LENGTH = 5000; // ms
export const DEFAULT_TIMESTAMP_LENGTH = 5000; // ms

// object annotations

export const MIN_SEGMENT_LENGTH = 0; // ms
export const MIN_DISTANCE_BETWEEN_BLOCKS = 500; // ms
export const DEFAULT_SEGMENT_LENGTH = 3000; // ms

// thumbnails

export const MAX_THUMBNAILS_PER_VIDEO = 10;
export const MAX_THUMBNAILS_PER_MOMENT = 10;
export const MAX_THUMBNAILS_PER_OBJECT = 10;

// images

export const MAX_IMAGE_SIZE = 2.5; // MiB

export const MAX_MICROSITE_LOGO_RESOLUTION: ImageDimensions = { width: 1024, height: 1024 };
export const MAX_MICROSITE_FAVICON_RESOLUTION: ImageDimensions = { width: 256, height: 256 };
export const MAX_USER_AVATAR_RESOLUTION: ImageDimensions = { width: 1024, height: 1024 };

// microsites

export const MAX_MICROSITES_NUMBER = 1;
