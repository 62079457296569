import { OptionsObject as SnackbarOptions } from 'notistack';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

interface AppErrorBody extends SnackbarOptions {
  message: string;
  key?: string;
}

export interface AppError {
  type: 'error';
  value: AppErrorBody;
  id: string;
}

interface AppMessageBody extends SnackbarOptions {
  message: string;
  key?: string;
}

interface AppMessage {
  type: 'message';
  value: AppMessageBody;
  id: string;
}

// TODO: use some sort of a predictable key, so we can leverage "preventDuplicates" option of the snackbar provider
export type GlobalStream = AppError | AppMessage;

export const globalStream$ = new Subject<GlobalStream>();

export const pushMessage = (value: AppMessageBody) => {
  const id = value.key || uuidv4();
  globalStream$.next({
    type: 'message',
    value,
    id,
  });
  return id;
};

export const pushError = (value: AppErrorBody) => {
  const id = value.key || uuidv4();
  globalStream$.next({
    type: 'error',
    value,
    id,
  });
  return id;
};

if (process.env.NODE_ENV === 'development') {
  (window as any).pushMessage = pushMessage;
  (window as any).pushError = pushError;
}
