import React from 'react';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useVideoQuery } from 'hooks/query';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { navigateTo404 } from 'utils/navigation';

import { VideoMonetizationView } from './VideoMonetizationView';

interface PromoteContentRouter {
  videoId: string;
}

export const VideoMonetization = () => {
  const { videoId } = useParams<PromoteContentRouter>();
  const { loading: organizationLoading, data: organization } = useOrganizationQuery();
  const { isMonetizationEnabled, monetizationSettings } = organization?.me.organization || {};

  const { loading, data } = useVideoQuery({
    variables: { id: videoId },
    onCompleted: ({ videoById }) => {
      if (!videoById) {
        navigateTo404();
      }
    },
  });

  if (loading || organizationLoading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (data?.videoById) {
    return (
      <MainLayout>
        <VideoMonetizationView
          video={data.videoById}
          monetizationSettings={monetizationSettings}
          isMonetizationEnabled={!!isMonetizationEnabled}
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
};
