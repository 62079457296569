import {
  Box,
  Button,
  Checkbox,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';

import { BulkChanges, DeleteModal, Search } from 'components/common';
import ExpandableCell from 'components/common/Table/ExpandableCell';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import MemoToggle from 'components/common/Table/MemoToggle';
import PaginationFooter from 'components/PaginationFooter';
import { PostsPerPage } from 'components/PaginationFooter/constants';
import TableActionCell from 'components/tables/TableActionCell';
import { EditObject } from 'components/tables/TableActionCell/EditObject';
import { Permission } from 'config/permissions';
import { PaginationAction } from 'context/pagination';
import { QUERY_VIDEO_STATUS } from 'context/queries';
import { BulkResource } from 'context/queries/bulk';
import {
  useBulkDeleteMutation,
  useDeleteObject,
  useToggleObject,
  useUpdateObject,
} from 'hooks/mutation';
import { ToggleMutateOptions } from 'hooks/mutation/_useToggleMutation';
import { useUserPermissions } from 'hooks/query/useUserPermissions';
import { PageInfo, VideoDetailsNode, VideoObjectNode } from 'models';
import { CreateObjectModal } from 'pages/PromoteContent/Objects/CreateObject';
import { EditObjectModal } from 'pages/PromoteContent/Objects/EditObject';
import { generateObjectLink } from 'pages/urls';
import { isDeepEqual } from 'utils/common';
import { getThumbnailUrl } from 'utils/thumbnails';
import { Nullable } from 'utils/types';
import { canVideoAnnotationBeMutated } from 'utils/videos';

import * as Styled from 'components/common/Table/Table.styles';

interface OwnProps {
  objectsLoading: boolean;
  objects: VideoObjectNode[];
  totalCount: number;
  video: VideoDetailsNode;
  pageInfo: Nullable<PageInfo>;
  dispatch(value: PaginationAction): void;
  onSearch: (searchText: string) => void;
}

const ObjectsTableView = ({
  objectsLoading,
  objects,
  totalCount,
  video,
  pageInfo,
  dispatch,
  onSearch,
}: OwnProps) => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddObjectModal, setShowAddObjectModal] = useState(false);

  const handleCheckboxClick = React.useCallback(
    (id: string) => setSelected((prevSelected) => ({ ...prevSelected, [id]: !prevSelected[id] })),
    [],
  );
  const handleMultiCheckboxClick = () => {
    if (Object.keys(selected).length > 0) {
      setSelected({});
    } else {
      const payload = objects.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {});
      setSelected(payload);
    }
  };

  const selectedList = Object.keys(selected).filter((key) => selected[key]);
  const selectedLength = selectedList.length;

  const [deleteObject] = useDeleteObject({
    videoId: video.id,
    onCompleted: (data) => {
      if (data.deleteObjectAnnotation?.deletedObjectAnnotationId) {
        dispatch({ type: 'changing' });
      }
    },
  });

  const [bulkDelete, bulkDeletingLoading] = useBulkDeleteMutation(
    BulkResource.ObjectAnnotation,
    selectedList,
    ['GetObjects', { query: QUERY_VIDEO_STATUS, variables: { id: video.id } }],
    () => {
      setSelected({});
    },
  );

  const [toggleObject] = useToggleObject();

  const momentsLength = objects.length;

  const actionsAllowed = canVideoAnnotationBeMutated(video.status);

  const isLoading = objectsLoading || bulkDeletingLoading;

  const { hasPermissions } = useUserPermissions();
  const canUserCreateObject = hasPermissions([Permission.CreateObject]);
  const canUserToggleObject = hasPermissions([Permission.ChangeObject]);
  const canUserDeleteObject = hasPermissions([Permission.DeleteObject]);

  const hasCheckboxCell = canUserToggleObject || canUserDeleteObject;

  return (
    <>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={isLoading} />
        <TableContainer component={Paper}>
          <Styled.Table>
            <Styled.TableHead>
              <Styled.HeadRow>
                {hasCheckboxCell && (
                  <Styled.CheckboxCell>
                    <Checkbox
                      checked={momentsLength > 0 && selectedLength > 0}
                      onChange={handleMultiCheckboxClick}
                      color="primary"
                    />
                  </Styled.CheckboxCell>
                )}
                <Styled.ThumbnailCell />
                <Styled.TitleCell>Title</Styled.TitleCell>
                {canUserToggleObject && <TableCell>Promote</TableCell>}
                {canUserToggleObject && <TableCell>Visibility</TableCell>}
                <Styled.ActionsCell align="right">Actions</Styled.ActionsCell>
              </Styled.HeadRow>
            </Styled.TableHead>
            <TableBody>
              {objects.map((row) => (
                <ObjectTableRow
                  key={row.id}
                  row={row}
                  isSelected={selected[row.id] || false}
                  hasCheckboxCell={hasCheckboxCell}
                  handleCheckboxClick={handleCheckboxClick}
                  toggleObject={toggleObject}
                  deleteObject={deleteObject}
                />
              ))}
            </TableBody>
            <PaginationFooter
              totalCount={totalCount}
              visibleCount={objects.length}
              pageInfo={pageInfo}
              dispatch={dispatch}
              tableType={PostsPerPage.VideoObjects}
            />
          </Styled.Table>
        </TableContainer>
        <Styled.ActionsContainer>
          {selectedLength > 0 ? (
            <BulkChanges
              count={selectedLength}
              type={BulkResource.ObjectAnnotation}
              handleDeleteClick={() => setShowDeleteModal(true)}
              selected={selected}
              items={objects}
              refetchQueries={[
                'GetObjects',
                { query: QUERY_VIDEO_STATUS, variables: { id: video.id } },
              ]}
              canToggle={canUserToggleObject}
              canDelete={canUserDeleteObject}
            />
          ) : (
            <>
              <Search placeholder="Search objects" onSearch={onSearch} />
              {canUserCreateObject && (
                <Box ml="18px">
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!actionsAllowed}
                    onClick={() => setShowAddObjectModal(!showAddObjectModal)}
                  >
                    Create object
                  </Button>
                </Box>
              )}
            </>
          )}
        </Styled.ActionsContainer>
      </Styled.TableWrapper>
      {canUserDeleteObject && (
        <DeleteModal
          type="object"
          handleClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          count={selectedLength}
          handleDelete={bulkDelete}
        />
      )}
      <CreateObjectModal
        isOpen={showAddObjectModal}
        onClose={() => setShowAddObjectModal(!showAddObjectModal)}
        videoId={video.id}
      />
    </>
  );
};

interface ObjectTableRowProps {
  row: VideoObjectNode;
  isSelected: boolean;
  hasCheckboxCell: boolean;
  handleCheckboxClick: (id: string) => void;
  toggleObject: (options: ToggleMutateOptions) => Promise<any>;
  deleteObject: (options: { variables: { id: string } }) => Promise<any>;
}

const ObjectTableRow = React.memo(function ObjectTableRow({
  row,
  isSelected,
  hasCheckboxCell,
  handleCheckboxClick,
  toggleObject,
  deleteObject,
}: ObjectTableRowProps) {
  const thumbnailUrl = getThumbnailUrl(row.thumbnails, row.thumbnailId);
  const [showEditModal, setShowEditModal] = useState(false);

  const { hasPermissions } = useUserPermissions();
  const canUserChangeObject = hasPermissions([Permission.ChangeObject]);
  const canUserDeleteObject = hasPermissions([Permission.DeleteObject]);

  const [updateObject] = useUpdateObject({ videoId: row.video.id });

  const handleToggle = () => {
    updateObject({
      variables: {
        objectId: row.id,
        objectAnnotation: {
          category: row.category,
          promote: !row.promote,
        },
      },
    });
  };

  return (
    <Styled.TableRow key={row.id}>
      {hasCheckboxCell && (
        <Styled.CheckboxCell>
          <Checkbox
            checked={isSelected}
            onClick={() => handleCheckboxClick(row.id)}
            color="primary"
          />
        </Styled.CheckboxCell>
      )}
      <Styled.ThumbnailCell>
        <Styled.Thumbnail thumbnailUrl={thumbnailUrl} />
      </Styled.ThumbnailCell>
      <ExpandableCell>
        <ObjectTitle onClick={() => setShowEditModal(!showEditModal)}>
          <Styled.MomentTitle>{row.title}</Styled.MomentTitle>
          <br />
          <Styled.MomentSubtitle>{row.url}</Styled.MomentSubtitle>
        </ObjectTitle>
      </ExpandableCell>
      {canUserChangeObject && (
        <>
          <TableCell>
            <MemoToggle
              id={row.id}
              videoId={row.video.id}
              checked={row.promote}
              onChange={handleToggle}
            />
          </TableCell>
          <TableCell>
            <MemoToggle
              id={row.id}
              videoId={row.video.id}
              checked={row.visible}
              onChange={toggleObject}
            />
          </TableCell>
        </>
      )}
      <TableActionCell
        type="object"
        editUrl={generateObjectLink(row.video.id, row.id)}
        canUserChangeIt={canUserChangeObject}
        canDelete={canUserDeleteObject}
        onDelete={() => deleteObject({ variables: { id: row.id } })}
        customViewAction={
          <EditObject onClick={() => setShowEditModal(!showEditModal)} edge="end" />
        }
      />
      <EditObjectModal
        object={row}
        onClose={() => setShowEditModal(!showEditModal)}
        isOpen={showEditModal}
      />
    </Styled.TableRow>
  );
},
isDeepEqual);

const ObjectTitle = styled.button`
  text-align: left;
`;

export default ObjectsTableView;
