import React, { useCallback, useState } from 'react';

import { InformationalModal } from 'components/common/Modals';
import MemoToggle from 'components/common/Table/MemoToggle';

import { ToggleProps } from '../types';

export const DefaultToggle: React.VFC<ToggleProps> = ({ checked, disabled, onChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleToggleChange = useCallback(async () => {
    await onChange();
    setIsModalOpen(true);
  }, [onChange]);

  return (
    <>
      <MemoToggle checked={checked} disabled={disabled} onChange={handleToggleChange} />
      <InformationalModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        heading="Ad tag set as default"
        description="This ad tag is now the default for your account. Per-video settings have not been changed."
      />
    </>
  );
};
