import { LinearProgress, LinearProgressProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StrictModifiers } from '@popperjs/core';
import React from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

interface LinearProgressTypes extends LinearProgressProps {
  barColor?: string;
}

const popperOptions: Parameters<typeof usePopper>[2] = {
  placement: 'bottom-end',
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        padding: 0,
      },
    },
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: ({ popper }) => {
          return [popper.width / 2, 2];
        },
      },
    },
    {
      name: 'flip',
      enabled: false,
    },
  ] as StrictModifiers[],
};

const ProgressBar: React.VFC<LinearProgressTypes> = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      height: 6,
      borderRadius: 6,
      background: materialTheme.palette.grey[200],
    },
    bar: {
      borderRadius: 6,
      backgroundColor: props?.barColor || materialTheme.palette.primary.main,
    },
    bar1Determinate: {
      // The smooth progress transition between two values needs to be disabled, because the popper
      // instance can't be plugged inside every frame of css-based transition.
      // The only way to support animations would be to create custom logic and stop using the
      // LinearProgress component from `material-ui`.
      transitionDuration: '0s !important',
    },
  }));

  const classes = useStyles();

  if (props.variant === 'determinate') {
    return <DeterminateProgressBar {...props} classes={classes} />;
  }

  return <LinearProgress color="primary" classes={classes} {...props} />;
};

const DeterminateProgressBar: React.VFC<LinearProgressProps> = (props) => {
  const [wrapperElement, setWrapperElement] = React.useState<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes, update: drawPopper } = usePopper(
    referenceElement,
    popperElement,
    popperOptions,
  );

  React.useEffect(() => {
    const barElement = wrapperElement?.querySelector('.MuiLinearProgress-bar');
    if (barElement) {
      setReferenceElement(barElement as HTMLDivElement);
    }
  }, [wrapperElement]);

  const percentage = props.value || 0;

  // we are using useLayoutEffect here because it's synchronous and runs between renders and
  // therefore it is guaranteed to run in correct order, unlike useEffect which can be stalled
  // inside main thread
  React.useLayoutEffect(() => {
    if (drawPopper) {
      window.requestAnimationFrame(() => {
        drawPopper();
      });
    }
  }, [percentage, drawPopper]);

  return (
    <Wrapper ref={setWrapperElement}>
      <LinearProgress variant="determinate" color="primary" classes={props.classes} {...props} />
      <Percentage ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {Math.round(percentage)}%
      </Percentage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

const Percentage = styled.div`
  color: ${materialTheme.palette.grey[500]};
  font-size: 12px;
  font-weight: 500;
`;

export default ProgressBar;
