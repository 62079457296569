import React from 'react';

import defaultAvatar from 'assets/images/default-avatar.png';

import * as Styled from './Avatar.styles';

interface AvatarProps {
  url?: string;
  dropdownAvatar?: boolean;
  size?: number;
}

const Avatar = ({ dropdownAvatar, url, size }: AvatarProps) => (
  <Styled.Avatar dropdownAvatar={dropdownAvatar} url={url || defaultAvatar} size={size} />
);

export default Avatar;
