import { IconButton, Tooltip } from '@material-ui/core';
import React, { useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { Link, Search, Spinner } from 'components/common';
import { DropdownButton } from 'components/common/DropdownButton';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import { Columns } from 'components/common/Wrapper/Layout';
import PaginationFooter from 'components/PaginationFooter';
import { initialPaginationSettings, PostsPerPage } from 'components/PaginationFooter/constants';
import { Table } from 'components/Table';
import { paginationReducer } from 'context/pagination';
import { useSorting } from 'context/sorting';
import { useCTAsQuery } from 'hooks/query/useCTAsQuery';
import useElementSize from 'hooks/useElementSize';
import { ActionContentWidthHandler } from 'pages/Organization/types';
import { generateCTAButtonEditLink, generateCTAImageEditLink } from 'pages/urls';
import { formatDate } from 'utils/date';

import { CTALinks, CTATypes } from './constants';
import { CTAEmptyData } from './CTAEmptyData';
import { CTAItem, CTAItemType, CTAsOrderBy } from './types';

import * as Styled from 'components/common/Table/Table.styles';

export const CTAsTab: React.FC<ActionContentWidthHandler> = ({ getActionContentWidth }) => {
  const [searchCTA, setSearchCTA] = useState('');
  const { elementRef } = useElementSize(getActionContentWidth);
  const { orderBy, sorting, setSorting } = useSorting<CTAsOrderBy>('-created');
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPaginationSettings(PostsPerPage.CTA),
  );

  const { loading, data } = useCTAsQuery({
    variables: {
      ...pagination,
      orderBy,
      search: searchCTA,
    },
  });

  const { pageInfo, totalCount, nodes: ctas = [] } = data?.ctas?.items || {};

  const { pathname } = useLocation();
  const currentURL = {
    destinationURL: pathname,
    backButtonPlaceholder: 'CTAs list',
  };

  const onSearch = (val: string) => {
    setSearchCTA(val.toLocaleLowerCase());
  };

  const filterCTAs = (cta: CTAItem) => cta.name.toLocaleLowerCase().startsWith(searchCTA);

  const generateCTAEditLink = (id: CTAItem['id'], type: CTAItemType) =>
    CTAItemType.Button === type ? generateCTAButtonEditLink(id) : generateCTAImageEditLink(id);

  if (loading) {
    return (
      <Styled.TableSpinnerBox>
        <Spinner />
      </Styled.TableSpinnerBox>
    );
  }

  if (!ctas?.length) {
    return <CTAEmptyData />;
  }

  return (
    <Styled.TableWrapper>
      <LoadingOverlay isVisible={loading} />
      <Table>
        <Table.Head>
          <Table.HeadCell width="50%">
            <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="name">
              CTA name
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="15%">
            <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="type">
              Type
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="15%">
            <SortInterface
              activeSorting={sorting}
              setSorting={setSorting}
              columnName="used_in_videos"
            >
              Videos
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="15%">
            <SortInterface
              activeSorting={sorting}
              setSorting={setSorting}
              columnName="used_in_cards"
            >
              Cards
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell width="30%">
            <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
              Created
            </SortInterface>
          </Table.HeadCell>
          <Table.HeadCell>Edit</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {ctas.filter(filterCTAs).map((cta) => (
            <Table.Row key={cta.id}>
              <Table.Cell>
                <Link
                  to={{
                    pathname: generateCTAEditLink(cta.id, cta.type),
                    state: currentURL,
                  }}
                >
                  <strong>{cta.name}</strong>
                </Link>
              </Table.Cell>
              <Table.Cell>{CTATypes[cta.type]}</Table.Cell>
              <Table.Cell>{cta.usedInVideos || 'n/a'}</Table.Cell>
              <Table.Cell>{cta.usedInCards || 'n/a'}</Table.Cell>
              <Table.Cell>{formatDate(new Date(cta.created))}</Table.Cell>
              <Table.Cell>
                <Tooltip title="Edit" arrow placement="top">
                  <IconButton
                    color="inherit"
                    edge="end"
                    component={Link}
                    to={{
                      pathname: generateCTAEditLink(cta.id, cta.type),
                      state: currentURL,
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <PaginationFooter
          totalCount={totalCount || ctas.length}
          visibleCount={ctas.length}
          dispatch={dispatch}
          pageInfo={pageInfo}
          tableType={PostsPerPage.CTA}
        />
      </Table>
      <Styled.ActionsContainer ref={elementRef}>
        <Columns display="flex" alignItems="center">
          <Search placeholder="Search" onSearch={onSearch} />
          <DropdownButton dropdownLinks={CTALinks} />
        </Columns>
      </Styled.ActionsContainer>
    </Styled.TableWrapper>
  );
};
