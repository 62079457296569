import { END_CARD_QUERY } from 'context/queries';
import { useQuery } from 'hooks/useQuery';
import { EndCardResponse } from 'pages/EndCard/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  endCardById: Nullable<EndCardResponse>;
}

interface Variables {
  id?: string;
}

export const useEndCardQuery = (endCardId: string) => {
  return useQuery<ResponseData, Variables>(END_CARD_QUERY, {
    variables: {
      id: endCardId,
    },
    fetchPolicy: 'cache-first',
  });
};
