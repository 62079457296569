import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { materialTheme } from 'components/App/materialTheme';
import { FormLabel, InfoTooltip } from 'components/common';
import { Badge } from 'components/common/Badge';
import { CustomSelect } from 'components/common/CustomSelect';
import { VideoMonetization } from 'models';
import { Monetization } from 'pages/Organization/MonetizationTab/types';

interface Props {
  control: Control;
  defaultValue?: VideoMonetization['orgMonetization'];
  options?: Monetization[];
}

export const AdTagSelect: React.FC<Props> = ({ control, defaultValue, options = [] }) => {
  const getBadgeComponent = (optionDefault: boolean) => {
    if (!optionDefault) return null;

    return () => (
      <Badge size="small" backgroundColor={materialTheme.palette.text.primary} color="#fff">
        default
      </Badge>
    );
  };
  const modifiedOptions = options.map((option) => ({
    ...option,
    label: option.name,
    value: option.id,
    monetizationUrl: option.monetizationUrl,
    hasBadge: option.default,
    Component: getBadgeComponent(option.default),
  }));

  const defaultOption = modifiedOptions.find((option) => option.default);

  return (
    <>
      <FormLabel>
        Source of ads
        <InfoTooltip content="Select the ad tag that will be used to monetize the video. Choose default to use the organization default ad tag." />
      </FormLabel>
      <Controller
        render={(field) => <CustomSelect {...field} id="ad-tag" options={modifiedOptions} />}
        name="orgMonetization"
        control={control}
        defaultValue={defaultValue?.id || defaultOption?.value}
      />
    </>
  );
};
