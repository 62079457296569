import React from 'react';
import styled from 'styled-components/macro';

import { useEditorContext } from '../MicrositeEditorContext';
import { LayoutContainer } from './Layout';

export const Header: React.VFC = () => {
  const { logoUrl, color, title } = useEditorContext();

  return (
    <Wrapper $color={color}>
      <Container>
        {logoUrl ? <Logo src={logoUrl} alt="logo" /> : null}
        {title && <Title>{title}</Title>}
        <Menu>VIDEOS</Menu>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.header<{ $color: string }>`
  padding: 5px 0;
  color: #fff;
  background-color: ${({ $color }) => $color};
`;

const Container = styled(LayoutContainer)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  max-width: 200px;
  max-height: 80px;
`;

const Menu = styled.nav`
  padding: 24px 0;
  margin-left: auto;
`;

const Title = styled.h1`
  margin-left: 20px;
`;
