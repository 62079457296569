import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { FormLabel } from 'components/common';
import CustomColor from 'components/common/CustomColor';
import { NumberInput } from 'components/common/NumberInput';
import { MeasurementUnit } from 'components/common/NumberInput/types';
import { validateColor } from 'utils/validation';

import { validateBorderWidth } from '../helpers';

import { FormField } from 'components/common/TagsField/TagsField.styles';

interface Props {
  errors: FieldErrors;
  control: Control;
}

export const ButtonBorder: React.FC<Props> = ({ errors, control }) => {
  return (
    <>
      <FormLabel>Border</FormLabel>
      <FormField mb="40px">
        <Controller
          name="borderWidth"
          control={control}
          rules={{ validate: validateBorderWidth }}
          render={(field) => (
            <NumberInput
              {...field}
              measurement={MeasurementUnit.Pixel}
              label="Border width"
              error={!!errors.borderWidth}
              errorMessage={errors.borderWidth?.message}
              mb="40px"
              info="Border width"
              validationMessage="Max. 10px"
              max={10}
            />
          )}
        />
        <Controller
          name="borderColor"
          control={control}
          rules={{ validate: validateColor }}
          render={({ value, onChange }) => (
            <CustomColor
              onColorChange={onChange}
              selectedColor={value}
              label="Border color"
              mb="40px"
              error={!!errors.borderColor}
              errorMessage={errors.borderColor?.message}
            />
          )}
        />
        <Controller
          name="borderRadius"
          control={control}
          render={(field) => (
            <NumberInput
              {...field}
              measurement={MeasurementUnit.Pixel}
              label="Border radius"
              error={!!errors.borderRadius}
              errorMessage={errors.borderRadius?.message}
              info="Border radius"
              validationMessage="Set 99px for round corners"
            />
          )}
        />
      </FormField>
    </>
  );
};
