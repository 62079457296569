import {
  captureException,
  captureMessage,
  init,
  Severity,
  showReportDialog,
  withScope,
} from '@sentry/browser';

import { env } from 'config/env';

const environment = env.SEEEN_APP_SENTRY_ENV || env.SEEEN_APP_ENV;

export function configureSentry() {
  if (!env.SEEEN_APP_SENTRY_DSN) {
    if (environment !== 'local') {
      console.warn('Sentry service configuration is missing a DSN key.');
    }
    return;
  }

  init({
    dsn: env.SEEEN_APP_SENTRY_DSN,
    environment,
    release: env.SEEEN_APP_BUILD_VERSION,
    ignoreErrors: [
      // generic error that doesn't tell us anything
      /network error: failed to fetch/i, // chrome
      /network error: NetworkError when attempting to fetch resource/i, // firefox
    ],
    blacklistUrls: [
      // browser extensions
      /^chrome:\/\//i,
      /^(chrome|moz)-extension:\/\//i,
    ],
  });
}

export function setupOnlineStatusListener() {
  function trackOnlineStatus() {
    if (navigator.onLine) {
      console.info(`[Connection] Internet connection established`);
    } else {
      console.info(`[Connection] Internet connection lost`);
    }
  }

  window.addEventListener('online', trackOnlineStatus);
  window.addEventListener('offline', trackOnlineStatus);
}

// Re-export to preserve tree-shaking
const Sentry = {
  captureException,
  captureMessage,
  withScope,
  showReportDialog,
  Severity,
};

export default Sentry;
