import React from 'react';

import { isDeepEqual } from 'utils/common';

/**
 * React.useEffect with deep-checking dependencies.
 *
 * Inspired by https://github.com/kentcdodds/use-deep-compare-effect
 */
export function useDeepCompareEffect(callback: React.EffectCallback, dependencies?: any[]) {
  const memoizedDeps = React.useRef<any[]>();

  if (!isDeepEqual(dependencies, memoizedDeps.current)) {
    memoizedDeps.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(callback, memoizedDeps.current);
}
