import React, { useCallback } from 'react';

import {
  seekToMs,
  togglePlayback,
  useVideoLoadedFraction,
  useVideoMetadataObservable,
} from 'context/VideoMetadataStream';
import { MomentPlayerPickI } from 'models';

import ControlBar, { SetVolume } from './ControlBar';
import ProgressBar from './ProgressBar';
import { VideoControlsWrapper } from './VideoPlayerElements';

interface OwnProps {
  moments?: MomentPlayerPickI[];
  playerRef: React.RefObject<HTMLDivElement>;
  setVolume: SetVolume;
  volume: number;
}

const VideoControls = ({ moments = [], playerRef, setVolume, volume }: OwnProps) => {
  const { played, duration, playing } = useVideoMetadataObservable();
  const loadedFraction = useVideoLoadedFraction();

  const handleSeeking = useCallback(
    (seek: number) => {
      if (!duration) {
        return;
      }

      seekToMs(seek * duration);
    },
    [duration],
  );

  const playedFraction = duration > 0 ? played / duration : 0;

  return (
    <VideoControlsWrapper>
      <ProgressBar
        loadedFraction={loadedFraction}
        playedFraction={playedFraction}
        handleSeeking={handleSeeking}
        moments={moments}
        duration={duration}
      />
      <ControlBar
        playing={playing}
        togglePlaying={togglePlayback}
        played={played}
        duration={duration}
        playerRef={playerRef}
        setVolume={setVolume}
        volume={volume}
      />
    </VideoControlsWrapper>
  );
};

export default VideoControls;
