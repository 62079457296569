import { Button } from '@material-ui/core';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import { LoadingButton } from 'components/common';
import { SpinnerBox } from 'components/common/Spinner';
import { Columns } from 'components/common/Wrapper/Layout';
import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import MainLayout from 'components/layouts/MainLayout';
import { useDeleteMicrositeFavicon } from 'hooks/mutation/useDeleteMicrositeFavicon';
import { useDeleteMicrositeLogo } from 'hooks/mutation/useDeleteMicrositeLogo';
import { useUpdateMicrosite, useUpdateMicrositeSection } from 'hooks/mutation/useUpdateMicrosite';
import { useMicrositeQuery } from 'hooks/query/useMicrositeQuery';
import { TagType, TEMP_Microsite, UpdateMicrositeInput, UpdateMicrositeSectionInput } from 'models';
import { URLS } from 'pages/urls';

import { CustomizeMicrosite } from './CustomizeMicrosite';
import { temp_prepareData } from './helpers';
import { EditorContextProvider, useEditorContext } from './MicrositeEditorContext';
import { Preview } from './Preview';

interface RouteParams {
  micrositeId: string;
}

const FORM_ID = 'edit_microsite';

interface FormData extends TEMP_Microsite {}

export const EditMicrositePage: React.VFC = () => {
  const { micrositeId } = useParams<RouteParams>();
  const { loading, data } = useMicrositeQuery({
    variables: {
      id: micrositeId,
    },
  });

  const micrositeData = temp_prepareData(data?.micrositeById);
  if (loading || !micrositeData) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  return (
    <EditorContextProvider micrositeData={micrositeData}>
      <EditMicrositeForm />
    </EditorContextProvider>
  );
};

const EditMicrositeForm: React.VFC = () => {
  const { initialMicrositeData, logoUrl, faviconUrl, color } = useEditorContext();
  const micrositeForm = useForm<FormData>({
    defaultValues: initialMicrositeData,
    mode: 'onBlur',
    shouldFocusError: false,
  });
  const { handleSubmit } = micrositeForm;
  const [updateMicrosite, { loading: updateMicrositeIsLoading }] = useUpdateMicrosite();
  const [updateSection, { loading: updateSectionIsLoading }] = useUpdateMicrositeSection();
  const [deleteFavicon, { loading: deleteFaviconIsLoading }] = useDeleteMicrositeFavicon();
  const [deleteLogo, { loading: deleteLogoIsLoading }] = useDeleteMicrositeLogo();

  const isLoading =
    updateMicrositeIsLoading ||
    updateSectionIsLoading ||
    deleteFaviconIsLoading ||
    deleteLogoIsLoading;

  const onSubmit = async (formData: FormData) => {
    const sections = formData.sections.map(
      ({ tag, tagType, title, sortBy, contentType }): UpdateMicrositeSectionInput => ({
        title,
        sortBy,
        contentType,
        // with empty tag input value is '' (empty string)
        internalTag: tagType === TagType.internalTag && Boolean(tag) ? tag : null,
        regularTag: tagType === TagType.regularTag && Boolean(tag) ? tag : null,
      }),
    );

    const didLogoChange = logoUrl !== initialMicrositeData.logoUrl;
    const didFaviconChange = faviconUrl !== initialMicrositeData.faviconUrl;
    const microsite: UpdateMicrositeInput = {
      title: formData.title,
      description: formData.description,
      logoBase64: didLogoChange ? logoUrl : undefined,
      faviconBase64: didFaviconChange ? faviconUrl : undefined,
      mainColor: color,
      googleAnalyticsId: formData.googleAnalyticsId,
      googleAnalytics4Id: formData.googleAnalytics4Id,
      htmlCode: formData.htmlCode,
      momentsLocationTop: formData.momentsLocationTop,
    };

    updateSection({
      variables: {
        id: initialMicrositeData.id,
        sections,
      },
    });
    updateMicrosite({
      variables: {
        id: initialMicrositeData.id,
        microsite,
      },
    });
    if (didLogoChange && logoUrl === null) {
      deleteLogo({
        variables: {
          id: initialMicrositeData.id,
        },
      });
    }
    if (didFaviconChange && faviconUrl === null) {
      deleteFavicon({
        variables: {
          id: initialMicrositeData.id,
        },
      });
    }
  };

  return (
    <MainLayout>
      <FormProvider {...micrositeForm}>
        <ExtensibleSidebar
          sideContent={<CustomizeMicrosite formId={FORM_ID} onSubmit={handleSubmit(onSubmit)} />}
          mainContent={
            <Preview>
              <Columns display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to={URLS.microsite.root}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  form={FORM_ID}
                  isLoading={isLoading}
                >
                  Republish
                </LoadingButton>
              </Columns>
            </Preview>
          }
        />
      </FormProvider>
    </MainLayout>
  );
};
