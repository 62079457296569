import React from 'react';

import { Dialog } from 'components/common/Dialog';

import { WidgetEmbedCode } from './WidgetEmbedCode';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  type: 'video' | 'moment' | 'playlist';
  id: string;
  apiKey: string;
}

export function WidgetEmbedModal({ onClose, isOpen, type, id, apiKey }: Props) {
  return (
    <Dialog onClose={onClose} open={isOpen} heading="Embed Code" width={600}>
      <WidgetEmbedCode onCopy={onClose} type={type} id={id} apiKey={apiKey} />
    </Dialog>
  );
}
