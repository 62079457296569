import { REQUEST_PASSWORD_RESET_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { Nullable } from 'utils/types';

type Errors = 'AMBIGUOUS_LOGIN' | 'ACCOUNT_NOT_VERIFIED' | 'ACCOUNT_INACTIVE';

const errorMap: Record<Errors, string> = {
  AMBIGUOUS_LOGIN:
    'Multiple organizations found for the provided email and password.<br />Specify an organization you want to log in to.',
  ACCOUNT_NOT_VERIFIED:
    'Your account has not been verified yet.<br />A new verification email has been sent.',
  ACCOUNT_INACTIVE: 'Your account is inactive. Contact the support.',
};

interface ResponseData {
  requestPasswordReset: Nullable<{
    ok: boolean;
    errors: ErrorType[];
  }>;
}

interface Variables {
  email: string;
  organizationName?: string;
  organizationId?: string;
}

interface UseRequestPasswordResetOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useRequestPasswordReset = (options: UseRequestPasswordResetOptions = {}) => {
  return useMutation<ResponseData, Variables>(REQUEST_PASSWORD_RESET_MUTATION, {
    pathToErrors: 'requestPasswordReset.errors',
    errorMap,
    skipGlobalErrorHandling: true,
    ...options,
  });
};
