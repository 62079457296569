import { Box, Button } from '@material-ui/core';
import { differenceWith } from 'ramda';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
import { LoadingButton, Search } from 'components/common';
import { useSearch } from 'components/common/Search/Search';
import { WidgetEmbed } from 'components/common/WidgetEmbedCode';
import { BigCard } from 'components/common/Wrapper/Card';
import { Columns, Stack } from 'components/common/Wrapper/Layout';
import ExtensibleSidebar from 'components/layouts/ExtensibleSidebar';
import { useWidgetApiKey } from 'hooks/query/useWidgetApiKey';
import { VideoOrderBy } from 'models';
import {
  EditorPlaylist,
  PlaylistContentType,
  PlaylistFilterBy,
  PlaylistOrdering,
} from 'models/Playlist';
import { MaybePromise, SetState } from 'utils/types';

import { AddItemsToPlaylistModal } from './AddItemsToPlaylistModal';
import { FormData, PlaylistMetadataForm } from './PlaylistMetadataForm';
import { SortableItemTable } from './SortableItemTable';
import { Item } from './types';
import { useAutomaticPlaylistItems } from './useAutomaticPlaylistItems';

const FORM_ID = 'playlist-form';

export type { FormData };

const ORDER_BY_MAP: Record<PlaylistOrdering, VideoOrderBy> = {
  [PlaylistOrdering.Manual]: '',
  [PlaylistOrdering.PublicationDateAscending]: 'created',
  [PlaylistOrdering.PublicationDateDescending]: '-created',
  [PlaylistOrdering.TitleAlphabetical]: 'title',
  [PlaylistOrdering.TitleAlphabeticalReversed]: '-title',
  [PlaylistOrdering.ViewsAscending]: 'views',
  [PlaylistOrdering.ViewsDescending]: '-views',
};

interface Props {
  pageTitle: string;
  onFormSubmit: (data: FormData) => void;
  goBackLink: string;
  saveButtonText: string;
  isSaving: boolean;
  playlist?: EditorPlaylist;
  handleAddItemsToPlaylist: (itemIds: Item[]) => MaybePromise;
  manualItems: Item[];
  setManualItems: SetState<Item[]>;
}

export function PlaylistPageView({
  pageTitle,
  onFormSubmit,
  goBackLink,
  saveButtonText,
  isSaving,
  playlist,
  handleAddItemsToPlaylist,
  manualItems,
  setManualItems,
}: Props) {
  const [isAddItemsModalOpen, setIsAddItemsModalOpen] = React.useState(false);

  const form = useForm<FormData>({
    defaultValues: playlist
      ? {
          name: playlist.name,
          contentType: playlist.contentType,
          orderBy: playlist.orderBy,
          filterBy: playlist.filterBy,
          filterValue: playlist.filterValue ?? undefined,
        }
      : {
          name: '',
          contentType: PlaylistContentType.Videos,
          orderBy: PlaylistOrdering.PublicationDateDescending,
          filterBy: PlaylistFilterBy.InternalTag,
          filterValue: '',
        },
    mode: 'onBlur',
    // we want to keep saved filters in memory, even if they don't apply for selected ordering
    shouldUnregister: false,
  });

  const orderBy = form.watch('orderBy');
  const isManualMode = orderBy === PlaylistOrdering.Manual;
  const type = form.watch('contentType');
  const filterBy = form.watch('filterBy');
  const filterValue = form.watch('filterValue');

  const [searchText, setSearchText] = useSearch();

  const { items: automaticItems } = useAutomaticPlaylistItems({
    type,
    filterBy,
    filterValue,
    variables: {
      page: 1,
      pageSize: 20,
      orderBy: ORDER_BY_MAP[orderBy],
      search: searchText,
      isPublished: true,
    },
    skip: isManualMode,
  });

  const items = isManualMode ? manualItems : automaticItems;
  const filteredItems = filterItems(items, searchText);

  const [selectedItemIds, setSelectedItemIds] = React.useState<Record<string, boolean>>({});
  const onlySelectedIds = Object.keys(selectedItemIds).filter((key) => selectedItemIds[key]);

  const isSelectModeActive = isManualMode && onlySelectedIds.length > 0;

  const deleteSelectedItems = () => {
    setManualItems((oldItems) => {
      return differenceWith((item, id) => item.id === id, oldItems, onlySelectedIds);
    });
    setSelectedItemIds({});
  };

  const { widgetApiKey } = useWidgetApiKey();

  return (
    <ExtensibleSidebar
      sideContent={
        <FormProvider {...form}>
          <PlaylistMetadataForm
            id={FORM_ID}
            pageTitle={pageTitle}
            onSubmit={onFormSubmit}
            isManualMode={isManualMode}
            goBackLink={goBackLink}
            // clear manual items, so we don't accidentally end up with items with wrong content type
            onContentTypeChange={() => setManualItems([])}
            onOrderingChange={() => setManualItems([])}
          />
        </FormProvider>
      }
      mainContent={
        <Stack>
          <Columns display="flex" alignItems="center" justifyContent="flex-end" pb="20px">
            <Box mr="auto">
              {playlist ? (
                <WidgetEmbed id={playlist.id} type="playlist" apiKey={widgetApiKey}>
                  {({ showModal }) => {
                    return <WidgetEmbed.Button showModal={showModal} />;
                  }}
                </WidgetEmbed>
              ) : null}
            </Box>
            <Button component={Link} to={goBackLink} variant="outlined" color="secondary">
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              isLoading={isSaving}
              color="primary"
              form={FORM_ID}
              type="submit"
            >
              {saveButtonText}
            </LoadingButton>
          </Columns>

          {isManualMode ? null : (
            <Columns display="flex" alignItems="center" columnGap="8px">
              <Box flexShrink="0">
                <InfoCircle />
              </Box>
              <span>
                This is a preview of all published{' '}
                {type === PlaylistContentType.Moments ? 'moments' : 'videos'}. The actual list that
                will be displayed in a widget/microsite can be different, depending on the
                publishing target of the publication.
              </span>
            </Columns>
          )}

          {items.length > 0 ? (
            <BigCard>
              {isManualMode ? (
                <Box p="20px 30px" display="flex" alignItems="center">
                  <Search
                    placeholder="Search"
                    onSearch={setSearchText}
                    iconPlacement="left"
                    searchOnInput
                  />
                  <Columns ml="auto">
                    {isSelectModeActive ? (
                      <Button variant="contained" color="secondary" onClick={deleteSelectedItems}>
                        Delete
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsAddItemsModalOpen(true)}
                      >
                        Add New
                      </Button>
                    )}
                  </Columns>
                </Box>
              ) : null}
              <SortableItemTable
                items={filteredItems}
                setManualItems={setManualItems}
                isSortingEnabled={isManualMode}
                isSelectingEnabled={isManualMode}
                selectedItemIds={selectedItemIds}
                setSelectedItemIds={setSelectedItemIds}
              />
            </BigCard>
          ) : (
            <BigCard p="20px 30px">
              <Columns columnGap="20px" display="flex" alignItems="center">
                <span>This playlist is empty.</span>
                {isManualMode ? (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setIsAddItemsModalOpen(true)}
                  >
                    {type === PlaylistContentType.Moments ? 'Add First Moment' : 'Add First Video'}
                  </Button>
                ) : null}
              </Columns>
            </BigCard>
          )}
          <AddItemsToPlaylistModal
            isOpen={isAddItemsModalOpen}
            onClose={() => setIsAddItemsModalOpen(false)}
            type={type}
            handleAddItemsToPlaylist={handleAddItemsToPlaylist}
          />
        </Stack>
      }
    />
  );
}

function filterItems(items: Item[], _searchText = '') {
  const searchText = _searchText.trim().toLocaleLowerCase();
  if (!searchText) return items;

  return items.filter((item) => {
    return !!item.title.toLocaleLowerCase().match(searchText);
  });
}
