import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import { MAX_MICROSITES_NUMBER } from 'config/constants';
import { useCreateMicrosite } from 'hooks/mutation/useCreateMicrosite';
import { useUpdateMicrositeSection } from 'hooks/mutation/useUpdateMicrosite';
import { useMicrositeDashboardQuery } from 'hooks/query';
import {
  Microsite,
  MicrositeSectionOrdering,
  MicrositeSectionTypes,
  UpdateMicrositeSectionInput,
} from 'models';
import Footer from 'pages/MicroSite/Footer';
import { FlowStep, MicrositeLayout, MicrositeLayoutContent } from 'pages/MicroSite/MicrositeLayout';

import { generateMicrositeSuccessLink, URLS } from '../urls';
import { getMicrositeDomain } from './helpers';
import { SiteDetails } from './SiteDetails';

interface FormData extends Microsite {}

const getDefaultSectionData = (index: number): UpdateMicrositeSectionInput => ({
  title: `Section ${index}`,
  sortBy: MicrositeSectionOrdering.PublicationDate,
  contentType: MicrositeSectionTypes.VideosMoments,
});

export const CreateMicrositePage: React.VFC = () => {
  const [createMicrosite, { loading: isLoading }] = useCreateMicrosite();
  const [updateSection, { loading: updateSectionIsLoading }] = useUpdateMicrositeSection();
  const history = useHistory();

  const micrositeForm = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const { loading, data } = useMicrositeDashboardQuery();

  useEffect(() => {
    if ((data?.microsites?.nodes?.length || 0) >= MAX_MICROSITES_NUMBER) {
      history.replace(URLS.microsite.root);
    }
  }, [data, history]);

  if (loading) {
    return null;
  }

  const onSubmit = async (formData: FormData) => {
    const { data } = await createMicrosite({
      variables: {
        microsite: {
          title: formData.title,
          description: formData.description,
          domain: getMicrositeDomain(formData.domain),
          momentsLocationTop: formData.momentsLocationTop,
        },
      },
    });

    const id = data?.createMicrosite?.microsite?.id;

    if (id) {
      await updateSection({
        variables: {
          id: id,
          sections: [getDefaultSectionData(1), getDefaultSectionData(2), getDefaultSectionData(3)],
        },
      });
      history.push(generateMicrositeSuccessLink(id));
    }
  };

  return (
    <MainLayout>
      <FormProvider {...micrositeForm}>
        <form name="create-microsite" onSubmit={micrositeForm.handleSubmit(onSubmit)}>
          <MicrositeLayout
            currentStep={FlowStep.Details}
            footer={
              <Footer
                currentStep={FlowStep.Details}
                isLoading={isLoading || updateSectionIsLoading}
              />
            }
          >
            <MicrositeLayoutContent>
              <SiteDetails />
            </MicrositeLayoutContent>
          </MicrositeLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};
