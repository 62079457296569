import { Box } from '@material-ui/core';
import React from 'react';

import { Search } from 'components/common';
import LoadingOverlay from 'components/common/Table/LoadingOverlay';
import SortInterface from 'components/common/Table/SortInterface';
import PaginationFooter from 'components/PaginationFooter';
import { Table } from 'components/Table';
import { PaginationAction } from 'context/pagination';
import { SetSorting, Sorting } from 'context/sorting';
import { useWidgetApiKey } from 'hooks/query/useWidgetApiKey';
import { PageInfo } from 'models';
import { DashboardPlaylist, PlaylistsOrderBy } from 'models/Playlist';
import { Nullable } from 'utils/types';

import { PlaylistTableRow } from './PlaylistTableRow';

import * as Styled from 'components/common/Table/Table.styles';

interface Props {
  playlistsLoading: boolean;
  playlists: DashboardPlaylist[];
  totalCount: number;
  pageInfo: Nullable<PageInfo>;
  dispatch: (value: PaginationAction) => void;
  sorting: Sorting<PlaylistsOrderBy>;
  setSorting: SetSorting<PlaylistsOrderBy>;
  onSearch: (searchText: string) => void;
}

export function PlaylistTable({
  playlistsLoading,
  playlists,
  totalCount,
  dispatch,
  pageInfo,
  sorting,
  setSorting,
  onSearch,
}: Props) {
  const { widgetApiKey } = useWidgetApiKey();
  return (
    <div>
      <Box p="20px 30px">
        <Search placeholder="Search playlists" onSearch={onSearch} iconPlacement="left" />
      </Box>
      <Styled.TableWrapper>
        <LoadingOverlay isVisible={playlistsLoading} />
        <Styled.TableHorizontalScroll>
          <Table fullWidth>
            <Table.Head>
              <Table.HeadCell width="100%">
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="name">
                  Name
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>Content Type</Table.HeadCell>
              <Table.HeadCell>Ordering</Table.HeadCell>
              <Table.HeadCell>
                <SortInterface activeSorting={sorting} setSorting={setSorting} columnName="created">
                  Date Created
                </SortInterface>
              </Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {playlists.map((playlist) => {
                return (
                  <PlaylistTableRow
                    key={playlist.id}
                    playlist={playlist}
                    widgetApiKey={widgetApiKey}
                  />
                );
              })}
            </Table.Body>
            <PaginationFooter
              totalCount={totalCount}
              visibleCount={playlists.length}
              pageInfo={pageInfo}
              dispatch={dispatch}
            />
          </Table>
        </Styled.TableHorizontalScroll>
      </Styled.TableWrapper>
    </div>
  );
}
