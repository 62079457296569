import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';

export const ProfileButton = styled.button`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 2px solid rgb(32, 36, 44);
  border-radius: 15px;
  transition: 0.25s ease-out;

  &:focus,
  &:hover {
    outline: none;
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

interface ProfileWrapperProps {
  miniNavbar?: boolean;
}

export const ProfileWrapper = styled.div<ProfileWrapperProps>`
  max-width: 90px;
  text-align: left;
  display: ${({ miniNavbar }) => (miniNavbar ? 'none' : 'block')};
  margin: ${({ miniNavbar }) => (miniNavbar ? '0 4px 0 0' : '0 0 0 15px')};
  flex-grow: 1;
`;

interface UsernameProps {
  darkColor?: boolean;
}

export const Username = styled.div<UsernameProps>`
  color: ${({ darkColor }) => (darkColor ? '#000' : '#fff')};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const OrganizationName = styled.div`
  font-size: 12px;
  color: #fff;
  padding-top: 3px;
  opacity: 0.5;
  letter-spacing: 0.3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface ExpandDropdownProps {
  dropdownVisible: boolean;
  miniNavbar?: boolean;
}

export const ExpandDropdown = styled.div<ExpandDropdownProps>`
  display: ${({ miniNavbar }) => (miniNavbar ? 'none' : 'flex')};
  justify-content: flex-start;
  margin-left: 25px;

  .dropdown-icon {
    color: #fff;
    width: 13px;
    height: 13px;
    transform: ${({ dropdownVisible }) => (dropdownVisible ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;

export const UserDropdown = styled.div`
  box-shadow: 0px 2px 60px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  border-radius: 15px;
  width: 250px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const DropdownList = styled.ul`
  margin-top: 15px;

  .dropdown-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    font-weight: 500;
  }

  .dropdown-link-logout {
    color: #e73c3c;
  }
`;

interface DropdownItemProps {
  isLogout?: boolean;
}

export const DropdownItem = styled.li<DropdownItemProps>`
  border-bottom: 1px red;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${({ isLogout }) => (!isLogout ? materialTheme.palette.common.black : '#E73C3C')};
  cursor: pointer;
  letter-spacing: 0.3px;

  .dropdown-link-label {
    display: inline-block;
    transition: 0.25s ease-out;
  }

  &:hover .dropdown-link-label {
    transform: translateX(3px);
  }

  svg {
    margin-right: 8px;
    min-width: 21px;
  }
`;
