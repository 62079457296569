import { Box, Button } from '@material-ui/core';
import React from 'react';

import { Spinner, TextInput } from 'components/common';
import { Dialog } from 'components/common/Dialog';
import { useD2CApiKeyQuery } from 'hooks/query';

import * as Styled from './InstallModal.styles';

interface InstallModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const InstallModal: React.VFC<InstallModalProps> = ({ isOpen, onClose }) => (
  <Dialog onClose={onClose} open={isOpen} heading={`Install Dialog-to-Clip`} width={600}>
    <Styled.InstallStepsList>
      <Styled.InstallStepItem>
        Click the button below to go to the Adobe Exchange.
      </Styled.InstallStepItem>
      <Styled.InstallStepItem>
        Select “Install Now” to download and install the Dialog-to-Clip plugin.
      </Styled.InstallStepItem>
      <Styled.InstallStepItem>
        Open Premiere Pro and launch the plugin {`(Window->Extensions->Dialog-to-Clip)`}. Enter your
        API key when prompted.
        <ApiKeyInput />
      </Styled.InstallStepItem>
      <Styled.InstallStepItem>
        Need more help?
        <a
          href="https://intercom.help/getseeen/en/articles/5247293-dialog-to-clip-faq"
          target="_blank"
          rel="noreferrer noopener"
        >
          <strong> Try our FAQ.</strong>
        </a>
      </Styled.InstallStepItem>
    </Styled.InstallStepsList>
    <Box p="20px">
      <Button
        href="https://exchange.adobe.com/creativecloud.details.106683.dialog-to-clip.html"
        target="_blank"
        rel="noreferrer noopener"
        variant="contained"
        color="primary"
      >
        Go to Adobe Exchange
      </Button>
    </Box>
  </Dialog>
);

// separate component prevents initial (on modal init) request
const ApiKeyInput: React.VFC = () => {
  const { loading, data } = useD2CApiKeyQuery({
    variables: {
      pageSize: 1,
      page: 1,
      changingCount: 1,
      revoked: false,
    },
  });

  if (loading) {
    return (
      <Box pt="35px" textAlign="center">
        <Spinner />
      </Box>
    );
  }

  const apiKey = data?.d2cApiKeys?.nodes?.[0]?.key;

  if (!apiKey) {
    return null;
  }

  return (
    <Box pr="40px" pt="15px">
      <TextInput name="example-api-key" value={apiKey} copyable readOnly />
    </Box>
  );
};
