import { Box, Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Retry } from 'assets/icons/refresh-cw.svg';

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 15px;
  text-transform: capitalize;
`;

interface OwnProps {
  onRetry: () => void;
}

const FailInfo = ({ onRetry }: OwnProps) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="300px"
    px="40px"
  >
    <Title>Video processing failed</Title>
    <Button onClick={onRetry} variant="contained" color="primary" startIcon={<Retry />}>
      Retry processing
    </Button>
  </Box>
);

export default FailInfo;
