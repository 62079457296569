import {
  GET_MOMENT_THUMBNAILS,
  GET_OBJECT_THUMBNAILS,
  GET_VIDEO_THUMBNAILS,
} from 'context/queries';
import { useQuery, UseQueryOptions } from 'hooks/useQuery';
import { MomentI, ObjectI, VideoI } from 'models';
import { Nullable } from 'utils/types';

// VIDEOS

interface VideoResponse {
  videoById: Nullable<Pick<VideoI, 'id' | 'thumbnailId' | 'thumbnails'>>;
}

interface VideoVariables {
  videoId: string;
}

interface UseVideoThumbnailsQueryOptions extends UseQueryOptions<VideoResponse, VideoVariables> {}

export function useVideoThumbnailsQuery(options: UseVideoThumbnailsQueryOptions) {
  const results = useQuery<VideoResponse, VideoVariables>(GET_VIDEO_THUMBNAILS, options);
  return {
    ...results,
    thumbnails: results.data?.videoById?.thumbnails || [],
  };
}

// MOMENTS

interface MomentResponse {
  momentById: Nullable<Pick<MomentI, 'id' | 'thumbnailId' | 'thumbnails'>>;
}

interface MomentVariables {
  momentId: string;
}

interface UseMomentThumbnailsQueryOptions
  extends UseQueryOptions<MomentResponse, MomentVariables> {}

export function useMomentThumbnailsQuery(options: UseMomentThumbnailsQueryOptions) {
  const results = useQuery<MomentResponse, MomentVariables>(GET_MOMENT_THUMBNAILS, options);
  return {
    ...results,
    thumbnails: results.data?.momentById?.thumbnails || [],
  };
}

// OBJECTS

interface ObjectResponse {
  objectById: Nullable<Pick<ObjectI, 'id' | 'thumbnailId' | 'thumbnails'>>;
}

interface ObjectVariables {
  objectId: string;
}

interface UseObjectThumbnailsQueryOptions
  extends UseQueryOptions<ObjectResponse, ObjectVariables> {}

export function useObjectThumbnailsQuery(options: UseObjectThumbnailsQueryOptions) {
  const results = useQuery<ObjectResponse, ObjectVariables>(GET_OBJECT_THUMBNAILS, options);
  return {
    ...results,
    thumbnails: results.data?.objectById?.thumbnails || [],
  };
}
