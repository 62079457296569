import { cover, ellipsis } from 'polished';
import styled, { css } from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { Table } from 'components/Table';

export const TitleCell = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: ${materialTheme.palette.grey[900]};
  overflow: hidden;

  // allow content to be taller than thumbnail (40px) without breaking flow
  height: 60px;
  margin: -10px 0;
`;

export const EllipsisParent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 200px;
`;

export const EllipsisContent = styled.div`
  ${ellipsis()};
  width: 100%;
`;

const cellContent = css`
  ${cover()};
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

export const Content = styled.div`
  ${cellContent};
`;

// `Content` is a subject of hover interaction and can be hidden, this component won't be
export const NonDisappearingContent = styled.div`
  ${cellContent};
`;

export const Actions = styled.div`
  ${cellContent};
`;

// Elements need to be hidden with `opacity`/`pointer-events`, because `visibility` doesn't
// work in Safari 13 when hovering. See https://stackoverflow.com/a/36574854
const hideElement = css`
  opacity: 0;
  pointer-events: none;
`;

const showElement = css`
  opacity: 1;
  pointer-events: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  ${Actions} {
    ${hideElement};
  }

  ${Content} {
    ${showElement};
  }
`;

export const Row = styled(Table.Row)`
  height: 80px;

  &:hover,
  &:focus {
    background-color: ${materialTheme.palette.background.default};
  }
`;

export const VideoTitle = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  width: 100%;
  max-width: 350px;
`;
