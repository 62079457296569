import gql from 'graphql-tag';

import { ERRORS_PART } from './_errors';

export const CAPTION_FRAGMENT = gql`
  fragment CaptionsFragment on VideoCaptionType {
    captionFileUrl
    id
    languageCode
    name
    source
  }
`;

export const REFETCH_VIDEO_CAPTIONS = gql`
  ${CAPTION_FRAGMENT}

  query RefetchVideoCaptions($videoId: ID!) {
    videoById(id: $videoId) {
      captions {
        ...CaptionsFragment
      }
      id
      status
    }
  }
`;

export const CREATE_VIDEO_CAPTIONS = gql`
  ${CAPTION_FRAGMENT}

  mutation CreateVideoCaption($videoCaption: CreateVideoCaptionInput!, $videoId: ID!) {
    createVideoCaption(videoCaption: $videoCaption, videoId: $videoId) {
      caption {
        ...CaptionsFragment
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_VIDEO_CAPTIONS = gql`
  ${CAPTION_FRAGMENT}

  mutation UpdateVideoCaption($id: ID!, $videoCaption: UpdateVideoCaptionInput!) {
    updateVideoCaption(id: $id, videoCaption: $videoCaption) {
      caption {
        ...CaptionsFragment
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_VIDEO_CAPTIONS = gql`
  mutation DeleteVideoCaption($id: ID!) {
    deleteVideoCaption(id: $id) {
      deletedVideoCaptionId
      ${ERRORS_PART}
    }
  }
`;
