import { Except, LiteralUnion } from 'type-fest';

import { TimeRange, VideoI } from 'models';

import { ThumbnailsPartI } from './ThumbnailI';

export enum AnnotationSource {
  User = 'USER',
  System = 'SYSTEM',
}

export interface CreateObjectInput {
  title: string;
  url?: string;
  thumbnailBase64?: string;
  category: ObjectAnnotationCategory;
}

export type UpdateObjectInput = Partial<
  Except<CreateObjectInput, 'thumbnailBase64'> & {
    thumbnailId: string;
    promote: boolean;
  }
>;

export interface ObjectI extends CreateObjectInput, ThumbnailsPartI {
  id: string;
  source: AnnotationSource;
  created: string;
  visible: boolean;
  video: VideoI;
  url: string;
  timeRange: TimeRange;
  promote: boolean;
}

export interface SegmentBlockInput {
  timestamp: number;
  start: BlockPoint;
  width: number;
  height: number;
  indicator?: BlockPoint;
}

export interface ObjectSegmentInput {
  startTimestamp: number;
  endTimestamp: number;
  visible?: boolean;
  blocks: SegmentBlockInput[];
}

export interface ObjectSegment extends ObjectSegmentInput {
  created: string;
  id: string;
  visible: boolean;
  source: AnnotationSource;
  blocks: ObjectBlock[];
}

export interface ObjectBlock extends SegmentBlockInput {
  source: AnnotationSource;
  indicator: BlockPoint;
}

export interface BlockPoint {
  x: number;
  y: number;
}

export type ObjectMetadataFragment = Pick<
  ObjectI,
  'created' | 'category' | 'id' | 'timeRange' | 'title' | 'source' | 'url' | 'visible' | 'promote'
> & {
  video: Pick<VideoI, 'id'>;
} & ThumbnailsPartI;

export type VideoObjectNode = ObjectMetadataFragment;

export type ObjectDetailsNode = ObjectMetadataFragment & {
  video: Pick<VideoI, 'duration' | 'videoFileUrl' | 'status'>;
};

export type BlockPositionProps = Pick<ObjectBlock, 'height' | 'width' | 'start' | 'indicator'>;

/**
 * Order string can be one (or a combination, joined with `,`) of given values.
 *
 * `column` -> ascending,
 * `-column` -> descending
 */
export type ObjectOrderBy = LiteralUnion<
  | '' // empty means default order
  | 'id'
  | '-id'
  | 'title'
  | '-title'
  | 'videoTitle'
  | '-videoTitle'
  | 'source'
  | '-source'
  | 'startTimestamp'
  | '-startTimestamp'
  | 'endTimestamp'
  | '-endTimestamp'
  | 'created'
  | '-created',
  string
>;

export enum ObjectAnnotationCategory {
  Product = 'PRODUCT',
  Person = 'PERSON',
  Location = 'LOCATION',
  Other = 'OTHER',
}

export interface UnsavedSegments {
  added: ObjectSegment[];
  updated: ObjectSegment[];
  deleted: string[];
}
