import { IconButton, Tooltip } from '@material-ui/core';
import { cover, ellipsis } from 'polished';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { Columns } from 'components/common/Wrapper/Layout';
import { Table } from 'components/Table';
import { DeleteAction } from 'components/tables/TableActionCell';
import { DashboardWidgetPreset } from 'models';
import { formatDate } from 'utils/date';

import { useDeleteWidgetPreset } from './CreatePreset/useDeleteWidgetPreset';
import { EditPresetModal } from './EditPreset';

interface Props {
  preset: DashboardWidgetPreset;
}

export function PresetTableRow({ preset }: Props) {
  const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
  const [deleteWidgetPreset] = useDeleteWidgetPreset({
    refetchQueries: ['GetWidgetPresets'],
  });

  return (
    <Table.Row>
      <Table.Cell>
        <EllipsisParent>
          <EllipsisContent>
            <Name onClick={() => setIsEditModalVisible(true)}>{preset.name}</Name>
          </EllipsisContent>
        </EllipsisParent>
      </Table.Cell>
      <Table.Cell>{preset.googleAnalyticsTrackingId}</Table.Cell>
      <Table.Cell>{preset.endCard?.name}</Table.Cell>
      <Table.Cell>{formatDate(new Date(preset.created))}</Table.Cell>
      <Table.Cell>
        <Columns columnGap={-7}>
          <EditAction onClick={() => setIsEditModalVisible(true)} />
          <DeleteAction
            type="preset"
            onDelete={() => deleteWidgetPreset({ variables: { id: preset.id } })}
            customModalDescription="Are you sure you want to delete this preset? Remember to also remove or regenerate widgets which used it, as this action cannot be undone."
          />
        </Columns>
      </Table.Cell>
      <EditPresetModal
        isOpen={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        presetId={preset.id}
      />
    </Table.Row>
  );
}

const EllipsisParent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 200px;
`;

const EllipsisContent = styled.div`
  ${cover()};
  display: flex;
  align-items: center;
`;

const Name = styled.button`
  ${ellipsis()};
  font-weight: 500;
`;

const EditAction: React.VFC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Tooltip title="Edit" arrow placement="top">
      <IconButton color="inherit" onClick={onClick}>
        <Edit />
      </IconButton>
    </Tooltip>
  );
};
