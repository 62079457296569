import { path as R_path } from 'ramda';
import fastCompare from 'react-fast-compare';

import { AnyObject, Optional } from './types';

/**
 * @example
 * ```ts
 * const a = { b: { c: 42 } };
 * path('b', a); // => { c: 42 }
 * path('b.c', a); // => 42
 * path('x.y', a); // => undefined
 * path('x.y', undefined); // => undefined
 * ```
 */
export function path<Result>(pathToSearch: string, obj?: AnyObject) {
  return obj && (R_path(pathToSearch.split('.'), obj) as Result | undefined);
}

export function pick<Object, Keys extends keyof Object>(
  obj: Object,
  keys: Keys[],
): Pick<Object, Keys> {
  return keys.reduce((newObj, key) => {
    newObj[key] = obj[key];
    return newObj;
  }, {} as Pick<Object, Keys>);
}

export const isDeepEqual = fastCompare;

export function noop() {}

export function stopPropagation<TEvent extends React.SyntheticEvent>(
  callback: (event: TEvent) => void,
): typeof callback {
  return (event) => {
    event.stopPropagation();
    callback(event);
  };
}

export function capitalize(str: string) {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
}

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export function deepFreeze<T extends Record<string, any>>(object: T): T {
  if (Object.isFrozen(object)) {
    return object;
  }

  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name];

    // @ts-ignore
    object[name] = value && typeof value === 'object' ? deepFreeze(value) : value;
  }

  return Object.freeze(object);
}

export function attachGetParams(url: string, params: Record<string, string>) {
  const urlObject = new URL(url);
  Object.keys(params).forEach((key) => {
    urlObject.searchParams.append(key, params[key]);
  });
  return urlObject.toString();
}

export function notEmpty<TValue>(value: Optional<TValue>): value is TValue {
  return value !== null && value !== undefined;
}

export function reorderArray<T extends any[]>(list: T, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export const extractNumbers = (inputValue: string | number): number => {
  const regex = /[^0-9-]|-$/g;
  const numbersOnly = String(inputValue).replace(regex, '');
  const number = Number(numbersOnly);
  return number || 0;
};
