import { makeStyles } from '@material-ui/core';

import { EMPTY_INPUT_VALUE } from 'components/common/NumberInput/constants';
import { extractNumbers } from 'utils/common';
import { hexToRgb } from 'utils/styling';

import {
  ICON_OPTIONS,
  ICONS,
  MAX_BORDER_WIDTH,
  MAX_FONT_SIZE,
  MIN_BACKGROUND_OPACITY,
  MIN_FONT_SIZE,
  SHAPE_ICON,
  SHAPE_LABEL,
} from './constants';
import {
  CreateButtonCTAInput,
  Icons,
  IconShapes,
  OverlaysCTAButton,
  OverlaysIconOptions,
} from './types';

export const validateTextFontSize = (value: string) =>
  MIN_FONT_SIZE <= extractNumbers(value) && MAX_FONT_SIZE >= extractNumbers(value);

export const validateBackgroundOpacity = (value: string) =>
  MIN_BACKGROUND_OPACITY <= extractNumbers(value);

export const validateBorderWidth = (value: string) => MAX_BORDER_WIDTH >= extractNumbers(value);

const formatIconOption = (shape: IconShapes, label: string, name: Icons) => {
  return {
    label: `${label} ${SHAPE_LABEL[shape]}`,
    value: `${name}_${shape}`,
    Component: ICONS[name],
    ShapeComponent: SHAPE_ICON[shape],
  };
};

export const getAllIconOptions = () => {
  const options: OverlaysIconOptions[] = [];

  ICON_OPTIONS.forEach(({ label, name }) => {
    const option = [
      formatIconOption(IconShapes.Plain, label, name),
      formatIconOption(IconShapes.Circle, label, name),
      formatIconOption(IconShapes.Star, label, name),
    ];
    options.push(...option);
  });

  return options;
};

const convertNumericValue = (value: string) => (value === EMPTY_INPUT_VALUE ? 0 : value);

const convertBackgroundColorValue = (bgValue: string, opacityValue: string) =>
  `rgba(${hexToRgb(bgValue)}, ${extractNumbers(opacityValue) / 100})`;

export const useStyles = (fields: OverlaysCTAButton) =>
  makeStyles(() => ({
    root: {
      backgroundColor: convertBackgroundColorValue(
        fields.backgroundColor,
        fields.backgroundOpacity,
      ),
      borderWidth: convertNumericValue(fields.borderWidth),
      borderColor: fields.borderColor,
      borderRadius: convertNumericValue(fields.borderRadius),
      borderStyle: 'solid',
      color: fields.fontColor,
      fontFamily: fields.fontFamily?.fontFamily,
      fontWeight: fields.fontFamily?.fontWeight,
      fontSize: convertNumericValue(fields.fontSize),
      padding: fields.iconValue ? '8px 20px 8px 8px' : '14px 20px',

      '&:hover': {
        backgroundColor: convertBackgroundColorValue(
          fields.backgroundColor,
          fields.backgroundOpacity,
        ),
      },
    },
  }));

export const convertButtonDataToServer = (data: OverlaysCTAButton, name?: string) => {
  const button_cta: Partial<CreateButtonCTAInput> = {
    url: data.url,
    text: {
      text: data.text,
      fontSize: data.fontSize,
      fontColor: data.fontColor,
      fontFamily: data.fontFamily,
    },
    background: {
      backgroundColor: data.backgroundColor,
      backgroundOpacity: data.backgroundOpacity,
    },
    border: {
      borderWidth: data.borderWidth,
      borderColor: data.borderColor,
      borderRadius: data.borderRadius,
    },
    icon: {
      hasIcon: data.hasIcon,
      iconValue: data.iconValue,
      iconColor: data.iconColor,
    },
  };
  if (name !== data.name) {
    button_cta.name = data.name;
  }

  return button_cta;
};

export const convertButtonDataFromServer = (fields: CreateButtonCTAInput) => {
  return Object.entries(fields).reduce((acc, [key, value]) => {
    if (['background', 'border', 'icon', 'text'].includes(key)) {
      return { ...acc, ...JSON.parse(value) };
    } else {
      return { ...acc, [key]: value };
    }
  }, {}) as OverlaysCTAButton;
};
