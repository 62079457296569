import { CREATE_END_CARD_MUTATION } from 'context/queries';
import { useMutation, UseMutationOptions } from 'hooks/useMutation';
import { ErrorType } from 'models';
import { CreateEndCardInput, EndCardResponse } from 'pages/EndCard/types';
import { Nullable } from 'utils/types';

interface ResponseData {
  createEndCard: Nullable<{
    endCard: Nullable<EndCardResponse>;
    errors: ErrorType[];
  }>;
}

interface Variables {
  endCard: CreateEndCardInput;
}

interface UseCreateEndCardOptions extends UseMutationOptions<ResponseData, Variables> {}

export const useCreateEndCard = (options: UseCreateEndCardOptions = {}) => {
  return useMutation<ResponseData, Variables>(CREATE_END_CARD_MUTATION, {
    pathToErrors: 'createEndCard.errors',
    ...options,
  });
};
