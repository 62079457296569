import { Controller } from 'react-hook-form';

import { HORIZONTAL_BUTTONS, VERTICAL_BUTTONS } from './const';
import { AlignmentInputProps, HorizontalAlignment, VerticalAlignment } from './types';

import { RadioBackground, RadioWrapper, Wrapper } from './AlignmetInput.styles';

const AlignmentInput: React.FC<AlignmentInputProps> = ({
  control,
  fieldName,
  defaultHorizontalValue,
  defaultVerticalValue,
}) => {
  return (
    <Wrapper>
      <Controller
        defaultValue={defaultVerticalValue || VerticalAlignment.Center}
        control={control}
        name={`${fieldName}.vertical`}
        render={({ onChange, ref, value }) => (
          <>
            {VERTICAL_BUTTONS.map((button, i) => (
              <RadioWrapper key={i}>
                <input
                  name={button.direction}
                  value={button.value}
                  type="radio"
                  onChange={() => onChange(button.value)}
                  checked={value === button.value}
                  ref={ref}
                />
                <RadioBackground />
                {button.icon}
              </RadioWrapper>
            ))}
          </>
        )}
      />
      <Controller
        defaultValue={defaultHorizontalValue || HorizontalAlignment.Center}
        control={control}
        name={`${fieldName}.horizontal`}
        render={({ onChange, ref, value }) => (
          <>
            {HORIZONTAL_BUTTONS.map((button, i) => (
              <RadioWrapper key={i}>
                <input
                  name={button.direction}
                  value={button.value}
                  type="radio"
                  onChange={() => onChange(button.value)}
                  checked={value === button.value}
                  ref={ref}
                />
                <RadioBackground />
                {button.icon}
              </RadioWrapper>
            ))}
          </>
        )}
      />
    </Wrapper>
  );
};

export default AlignmentInput;
