import styled from 'styled-components/macro';

import { materialTheme } from 'components/App/materialTheme';
import { pxOrString } from 'utils/styling';

interface TableProps {
  fullWidth?: boolean;
}

export const Table = styled.table<TableProps>`
  border-collapse: collapse;
  ${(props) => props.fullWidth && 'width: 100%'};
`;

export const Head = styled.tr`
  background-color: ${materialTheme.palette.background.default};
`;

export const Body = styled.tbody`
  background-color: #fff;
`;

export const Row = styled.tr`
  height: 48px;
  border-bottom: 1px solid ${materialTheme.palette.background.default};
  font-size: 14px;
  line-height: 1.5;
`;

interface CellProps {
  align?: 'left' | 'center' | 'right';
}

export const Cell = styled.td<CellProps>`
  white-space: nowrap;
  text-align: ${({ align = 'left' }) => align};
  padding-right: 15px;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }

  @media (min-width: 1300px) {
    padding-right: 25px;

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }
  }
`;

interface HeadCellProps {
  /** If percentages are used, they must add up to 100% total across the whole table width. */
  width?: number | string;
}

export const HeadCell = styled(Cell).attrs(() => ({
  as: 'th',
}))<HeadCellProps>`
  height: 39px;
  ${({ width }) => width !== undefined && `width: ${pxOrString(width)}`};
  color: #656678;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 1.45;
  text-transform: uppercase;
`;
