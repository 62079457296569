import React from 'react';

import { FormLabel } from 'components/common';

import * as Styled from './MainColors.styles';

export const COLORS = ['#F00223', '#F002BB', '#0702F0', '#F0BB02', '#02F0A8'];

interface ColorRadioProps extends MainColorProps {
  color: string;
}

const ColorRadio: React.VFC<ColorRadioProps> = ({ color, selectedColor, onColorChange }) => {
  return (
    <Styled.InputWrapper>
      <Styled.Color color={color} />
      <Styled.ColorInput
        type="radio"
        color={color}
        name="mainColor"
        value={color}
        id={color}
        onChange={(e) => onColorChange(e.target.value)}
        checked={color === selectedColor}
      />
      <Styled.ColorLabel htmlFor={color} />
    </Styled.InputWrapper>
  );
};

interface MainColorProps {
  selectedColor?: string;
  onColorChange: (color: string) => void;
}

export const MainColors: React.VFC<MainColorProps> = ({ selectedColor, onColorChange }) => (
  <>
    <FormLabel>Main color</FormLabel>
    <Styled.MainColorsSelectionWrapper>
      {COLORS.map((color) => (
        <ColorRadio
          color={color}
          key={color}
          onColorChange={onColorChange}
          selectedColor={selectedColor}
        />
      ))}
    </Styled.MainColorsSelectionWrapper>
  </>
);
