import gql from 'graphql-tag';

import { MOMENT_METADATA_FRAGMENT } from '.';
import { ERRORS_PART } from './_errors';
import { THUMBNAILS_PART } from './thumbnails';

export const CREATE_MOMENT_MUTATION = gql`
  ${MOMENT_METADATA_FRAGMENT}

  mutation CreateMoment($videoId: ID!, $moment: CreateMomentInput!) {
    createMoment(videoId: $videoId, moment: $moment) {
      moment {
        ...MomentMetadata
        ${THUMBNAILS_PART}
      }
      ${ERRORS_PART}
    }
  }
`;

export const UPDATE_MOMENT_MUTATION = gql`
  ${MOMENT_METADATA_FRAGMENT}

  mutation UpdateMoment($id: ID!, $moment: UpdateMomentInput!) {
    updateMoment(id: $id, moment: $moment) {
      moment {
        ...MomentMetadata
        ${THUMBNAILS_PART}
      }
      ${ERRORS_PART}
    }
  }
`;

export const TOGGLE_MOMENT_MUTATION = gql`
  mutation ToggleMoment($action: ToggleAction!, $id: ID!) {
    toggleMoment(action: $action, id: $id) {
      moment {
        id
        video {
          id
        }
        visible
      }
      ${ERRORS_PART}
    }
  }
`;

export const DELETE_MOMENT_MUTATION = gql`
  mutation DeleteMoment($id: ID!) {
    deleteMoment(id: $id) {
      deletedMomentId
      ${ERRORS_PART}
    }
  }
`;
