import React from 'react';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useShareForwardedRef } from 'hooks/useShareForwardedRef';
import { stopPropagation } from 'utils/common';

import * as Styled from './InlineTextInput.styles';

interface InlineTextInputProps extends React.ComponentPropsWithoutRef<'input'> {
  value: string;
  onSave: (newValue: string) => boolean;
}

const InlineTextInput = React.forwardRef<HTMLInputElement, InlineTextInputProps>(
  ({ value: initialValue, onSave, onKeyDown, disabled, ...inputProps }, ref) => {
    const inputRef = useShareForwardedRef(ref);
    const [value, setValue] = React.useState(initialValue);
    const [isEditing, setEditing] = React.useState(false);

    React.useEffect(() => {
      if (disabled) {
        return;
      }

      const element = inputRef.current;
      if (element) {
        element.disabled = !isEditing;
        if (isEditing) {
          element.focus();
        }
      }
    }, [disabled, inputRef, isEditing]);

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Styled.Wrapper
        isEditing={isEditing}
        disabled={disabled}
        onClick={stopPropagation(() => {
          if (disabled) {
            return;
          }
          setEditing(true);
        })}
      >
        <Styled.Content isEditing={isEditing}>
          <Styled.InlineInput
            ref={inputRef}
            type="text"
            value={value}
            size={14}
            maxLength={12}
            readOnly={disabled}
            onChange={(event) => setValue(event.currentTarget.value)}
            onKeyDown={stopPropagation((event) => {
              if (onKeyDown) {
                onKeyDown(event);
              }
              if (event.key === 'Escape') {
                setEditing(false);
              }
            })}
            {...inputProps}
          />
          {isEditing && (
            <>
              <Styled.ActionButton
                variant="contained"
                size="small"
                color="secondary"
                onClick={stopPropagation(() => {
                  setValue(initialValue);
                  setEditing(false);
                })}
              >
                <CloseIcon />
              </Styled.ActionButton>
              <Styled.ActionButton
                variant="contained"
                size="small"
                color="primary"
                onClick={stopPropagation(() => {
                  const saved = onSave(value);
                  if (saved) {
                    setEditing(false);
                  }
                })}
              >
                <CheckIcon />
              </Styled.ActionButton>
            </>
          )}
        </Styled.Content>
        {!isEditing && <EditIcon className="edit-icon" />}
      </Styled.Wrapper>
    );
  },
);

export default React.memo(
  InlineTextInput,
  (prevProps, nextProps) =>
    prevProps.value === nextProps.value && prevProps.disabled === nextProps.disabled,
);
