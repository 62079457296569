import 'videojs-contrib-quality-levels';
import './skin.css';

import { ExternalContentSlider } from '@entertainment-ai/react-component-library';
import React, { useEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import { QualityLevel, QualityLevelList } from 'videojs-contrib-quality-levels';

import { useOrganizationLogoPreview } from 'context/OrgLogoPositionContext';
import {
  togglePlayback,
  updateSource,
  useVideoMetadataObservable,
  videoMetadataActions$,
} from 'context/VideoMetadataStream';
import { useOrganizationQuery } from 'hooks/query/useOrganizationQuery';
import { usePlayerSize } from 'hooks/usePlayerSize';
import { PromoteContentSettingsTypes, VideoLogoPositionType, VideoObjectNode } from 'models';
import { CTATimestampCtas } from 'pages/VideoCTA/type';
import { VideoOverlays } from 'pages/VideoCTA/VideoOverlays';
import { normalizedPromoteContent } from 'utils/promoteContent';
import { secondsToMs } from 'utils/time';

import { useVideoPlayerContext } from './context';
import { useVideoEvents } from './hooks';
import { LoadingSpinner } from './LoadingSpinner';
import { PlayerWrapper } from './VideoPlayerElements';

interface ProgressUpdateI {
  playedSeconds: number;
  loaded: number;
}

interface OwnProps {
  url?: string;
  volume: number;
  promoteObjectsList?: VideoObjectNode[];
  promoteContentSettings?: PromoteContentSettingsTypes;
  setVolume: (volume: number) => void;
  ctas: CTATimestampCtas[];
  isLogoEnabled: boolean;
  logoPosition?: VideoLogoPositionType;
}

export interface HlsLevel extends QualityLevel {
  id: string;
  label: string;
}

export interface HlsQualityList extends QualityLevelList {
  selectedIndex_: number;
  levels_: HlsLevel[];
}
interface QualityLevelsFn {
  (): HlsQualityList;
  VERSION: string;
}

export interface ExtendedVideoJsPlayerProps extends VideoJsPlayer {
  qualityLevels: QualityLevelsFn;
}

const WATERMARK_POSITIONS = {
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
  TOP_RIGHT: 'top-right',
  TOP_LEFT: 'top-left',
};

const VideoPlayer = React.forwardRef(
  ({ url, volume, promoteObjectsList, promoteContentSettings, ctas, setVolume }: OwnProps, ref) => {
    const playerRef = useRef<HTMLVideoElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [customVolume, setCustomVolume] = useState<number>(volume);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [aspectRatio, setAspectRatio] = useState(1);
    const { seek, playing } = useVideoMetadataObservable();
    const { onLoadedMetadata, onTimeUpdate, onLoadedData } = useVideoEvents();
    const { handleLoaded, videoPlaybackRate } = useVideoPlayerContext();
    const { data } = useOrganizationQuery();
    const organization = data?.me?.organization;

    const {
      orgLogoPosition: orgLogoPreviewPosition,
      isLogoEnabled: isOrgLogoEnabled,
    } = useOrganizationLogoPreview();

    const previousPlayingRef = useRef<Boolean | null>(null);

    const handleProgress = ({ playedSeconds, loaded: loadedFraction }: ProgressUpdateI) => {
      videoMetadataActions$.next({
        type: 'progress',
        value: {
          played: secondsToMs(playedSeconds),
          loadedFraction,
        },
      });
      setCurrentTime(playedSeconds);
    };

    const onPlayPause = () => {
      if (playerRef.current) {
        const currentVideoTime = playerRef.current.currentTime;
        updateSource({
          played: secondsToMs(currentVideoTime),
        });
      }
    };

    const handleEnded = () => {
      updateSource({
        playing: false,
      });
    };

    useEffect(() => {
      // Initializing the Video.js player
      if (!playerRef.current) return;
      const player: VideoJsPlayer = videojs(playerRef.current, {
        responsive: true,
        fluid: true,
        controlBar: {
          volumePanel: {
            inline: false,
          },
        },
      });

      player.on('play', onPlayPause);
      player.on('pause', onPlayPause);
      player.on('waiting', () => setIsLoading(true));
      player.on('canplay', () => setIsLoading(false));
      player.on('loadedmetadata', onLoadedMetadata);
      player.on('loadeddata', onLoadedData);
      player.on('ended', handleEnded);
      player.on('timeupdate', () => {
        const currentVideoTime = player.currentTime();
        const buffered = player.buffered();

        if (buffered.length > 0) {
          const loadedFraction = buffered.end(0) / player.duration();
          handleProgress({
            playedSeconds: currentVideoTime,
            loaded: loadedFraction,
          });
        }
        onTimeUpdate();
      });

      player.on('loadedmetadata', () => {
        const aspectRatio = player.videoWidth() / player.videoHeight();
        setAspectRatio(aspectRatio);
      });

      player.on('keydown', (event: React.KeyboardEvent) => {
        event.preventDefault();
        let newVolume: number;
        switch (event.key) {
          case ' ':
            if (player.paused()) {
              player.play();
              updateSource({
                playing: true,
              });
            } else {
              player.pause();
              updateSource({
                playing: false,
              });
            }
            break;
          case 'ArrowRight':
            player.currentTime(player.currentTime() + 10);
            break;
          case 'ArrowLeft':
            player.currentTime(player.currentTime() - 10);
            break;
          case 'ArrowUp':
            newVolume = player.volume() + 0.1;
            if (newVolume + 0.1 >= 1) {
              setCustomVolume(1);
            } else if (newVolume + 0.1 < 1) {
              setCustomVolume(newVolume);
            }
            break;
          case 'ArrowDown':
            newVolume = player.volume() - 0.1;
            if (newVolume - 0.1 <= 0) {
              setCustomVolume(0);
            } else if (newVolume - 0.1 > 0) {
              setCustomVolume(newVolume);
            }
            break;
          case 'm':
            player.muted(!player.muted());
            if (player.muted()) {
              setIsMuted(true);
            } else {
              setIsMuted(false);
            }
            break;
          default:
            break;
        }
      });
      player.volume(volume);
      player.ready(() => handleLoaded(player as ExtendedVideoJsPlayerProps));

      if (organization?.logoUrl) {
        // @ts-ignore
        player?.watermark({
          file: organization.logoUrl,
          xrepeat: 0,
          opacity: 0.8,
        });
      }

      if (url) {
        player.src({
          src: url,
        });
      }

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isMuted) {
        setVolume(0);
      } else {
        setVolume(customVolume);
      }
    }, [customVolume, isMuted, setVolume]);

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.playbackRate = videoPlaybackRate;
      }
    }, [videoPlaybackRate]);

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.volume = volume;
      }
    }, [volume]);

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.currentTime = seek / 1000;
      }
    }, [seek]);

    useEffect(() => {
      if (playing !== previousPlayingRef.current && playerRef.current) {
        if (playing) {
          playerRef.current.play();
        } else {
          playerRef.current.pause();
        }
        previousPlayingRef.current = playing;
      }
    }, [playing]);

    useEffect(() => {
      const watermarkElement = document.querySelector('.vjs-watermark') as HTMLElement;

      if (!watermarkElement) return;
      if (!isOrgLogoEnabled) {
        watermarkElement.style.display = 'none';
      } else {
        watermarkElement.style.display = 'block';
      }
    }, [isOrgLogoEnabled]);

    useEffect(() => {
      const watermarkElement = document.querySelector('.vjs-watermark');
      if (watermarkElement) {
        const existingPositionClass = Array.from(watermarkElement.classList).find((className) =>
          className.startsWith('vjs-watermark-'),
        );

        if (existingPositionClass) {
          watermarkElement.classList.remove(existingPositionClass);
        }

        watermarkElement.classList.add(
          `vjs-watermark-${WATERMARK_POSITIONS[orgLogoPreviewPosition || 'BOTTOM_RIGHT']}`,
        );
      }
    }, [orgLogoPreviewPosition, playerRef]);

    useEffect(() => () => videoMetadataActions$.next({ type: 'player-unmount' }), []);

    const wrapperRef = React.useRef<HTMLDivElement>(null);
    usePlayerSize(wrapperRef);

    React.useImperativeHandle(ref, () => playerRef.current);

    return (
      <PlayerWrapper
        ref={wrapperRef}
        style={aspectRatio < 1 ? { maxWidth: '450px' } : { maxWidth: '100%' }}
      >
        <LoadingSpinner isVisible={isLoading} />
        <div data-vjs-player onClick={togglePlayback}>
          <video ref={playerRef} className="video-js" preload="auto" />
        </div>
        {promoteContentSettings?.promoteObjects && promoteObjectsList?.length ? (
          <ExternalContentSlider
            objects={normalizedPromoteContent(promoteObjectsList)}
            callToActionTitle={promoteContentSettings.promotedObjectsLabel}
            showAtStart={promoteContentSettings.promoteAtStart}
            showAtEnd={promoteContentSettings.promoteAtEnd}
            isPlaying={playing}
            currentTime={currentTime}
            duration={playerRef.current?.duration || 0}
            isEnded={currentTime === playerRef.current?.duration}
          />
        ) : null}
        {ctas?.length ? <VideoOverlays ctas={ctas} /> : null}
      </PlayerWrapper>
    );
  },
);

export default React.memo(VideoPlayer);
