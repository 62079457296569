import React from 'react';
import { Prompt } from 'react-router-dom';

import { VideoStatus } from 'models';

interface EditedVideoPromptProps {
  status?: VideoStatus;
}

/*
  This prompt will block going out video editor and descendants editors 
  (objects, moments) ONLY WHEN video status is set to EDITED.

  When we are going between video/moments/objects inside one video, 
  this notification doesn't appear.

  TODO: better handling video URL
*/

export const EditedVideoPrompt: React.FC<EditedVideoPromptProps> = ({ status }) => {
  return (
    <Prompt
      when={status === VideoStatus.Edited}
      message={({ pathname }) => {
        return pathname.startsWith('/video/')
          ? true
          : 'You have unpublished video changes. Are you sure to leave this site?';
      }}
    />
  );
};
