import React from 'react';

export enum SortingDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface Sorting<Columns extends string> {
  column: Columns;
  direction: SortingDirection;
}

export type SetSorting<Columns extends string> = (sorting: Sorting<Columns>) => void;

const getSortingDirection = (order: string) =>
  order.startsWith('-') ? SortingDirection.Descending : SortingDirection.Ascending;

/**
 * Utility hook that translates API `orderBy` string into `Sorting` object and vice versa.
 */
export function useSorting<OrderBy extends string>(defaultOrder: OrderBy) {
  const [orderBy, setOrderBy] = React.useState<OrderBy>(defaultOrder);

  const sorting = React.useMemo<Sorting<OrderBy>>(
    () => ({
      column: orderBy.replace('-', '') as OrderBy,
      direction: getSortingDirection(orderBy),
    }),
    [orderBy],
  );

  const setSorting = React.useCallback<SetSorting<OrderBy>>((sorting) => {
    const direction = sorting.direction === SortingDirection.Descending ? '-' : '';
    setOrderBy((direction + sorting.column) as OrderBy);
  }, []);

  return { orderBy, sorting, setSorting };
}
