import React from 'react';
import { useEventCallback } from 'rxjs-hooks';

import { mouseDownEventCallback } from 'components/Ruler/Ruler.utils';
import { useEvent } from 'hooks/useEvent';
import { TimeRange } from 'models';

import Graduation from './Graduation/Graduation';

import * as Styled from './Ruler.styles';

export const TEST_ID = {
  ruler: 'ruler',
};

interface OwnProps {
  rulerRange: TimeRange;
  videoDuration: number;
  onRulerRangeChange: (rulerRange: TimeRange) => void;
  onRulerClick: (positionPx: number) => void;
  rulerWidth: number;
  lockPositionToBoundaries: (position: number) => number;
  rulerPadding: number;
}

export const Ruler: React.FC<OwnProps> = ({
  rulerRange,
  videoDuration,
  onRulerRangeChange,
  onRulerClick,
  rulerWidth,
  lockPositionToBoundaries,
  rulerPadding,
}) => {
  const mouseDownPosition = React.useRef<number>();

  const [attachMoveHandlers] = useEventCallback(mouseDownEventCallback, rulerRange, [
    rulerRange,
    videoDuration,
    onRulerRangeChange,
    onRulerClick,
  ]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    attachMoveHandlers(event);

    const { left } = event.currentTarget.getBoundingClientRect();
    mouseDownPosition.current = event.clientX - left;
  };

  useEvent({ current: document }, 'mouseup', () => {
    if (mouseDownPosition.current === undefined) {
      return;
    }
    onRulerClick(lockPositionToBoundaries(mouseDownPosition.current));
    mouseDownPosition.current = undefined;
  });

  // "click" event needs to be discarded to prevent duplicated mouseup callback
  useEvent({ current: document }, 'mousemove', () => {
    mouseDownPosition.current = undefined;
  });

  return (
    <Styled.Body data-testid={TEST_ID.ruler} onMouseDown={handleMouseDown}>
      <Graduation
        rulerWidth={rulerWidth}
        start={rulerRange[0]}
        end={rulerRange[1]}
        videoDuration={videoDuration}
        rulerPadding={rulerPadding}
      />
    </Styled.Body>
  );
};
