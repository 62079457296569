import { QUERY_CAROUSELS_BY_ID } from 'context/queries';
import { useQuery } from 'hooks/useQuery';
import { CarouselI } from 'models';
import { navigateTo404IfEmpty } from 'utils/navigation';
import { Nullable } from 'utils/types';

interface ResponseData {
  carousels: Nullable<{
    nodes: Nullable<CarouselI[]>;
  }>;
}

interface Variables {
  id: string;
}

export const useCarouselQuery = (carouselId: string) => {
  return useQuery<ResponseData, Variables>(QUERY_CAROUSELS_BY_ID, {
    variables: { id: carouselId },
    onCompleted: ({ carousels }) => {
      navigateTo404IfEmpty(carousels?.nodes);
    },
  });
};
