import React from 'react';

import { ButtonCTAResponse } from 'pages/CTAButton/types';
import { ImageCTAResponse } from 'pages/CTAImage/types';
import { CTAItemType } from 'pages/Overlays/CTAsTab/types';

import { CTATimestampCtas } from './type';
import { VideoOverlaysButton } from './VideoOverlaysButton';
import { VideoOverlaysImage } from './VideoOverlaysImage';

interface Props {
  ctas: CTATimestampCtas[];
}

export const VideoOverlays: React.FC<Props> = ({ ctas }) => {
  return (
    <>
      {ctas.map(({ id, type, width, styles, config: { position, animation } }, order) => {
        if (
          !styles ||
          position?.calculatedLeft === undefined ||
          position?.calculatedTop === undefined
        ) {
          return null;
        }

        return type === CTAItemType.Button ? (
          <VideoOverlaysButton
            key={id}
            styles={styles as ButtonCTAResponse}
            position={position}
            animation={animation}
            zIndex={ctas.length - order + 3}
          />
        ) : (
          <VideoOverlaysImage
            key={id}
            imageWidth={width}
            styles={styles as ImageCTAResponse}
            position={position}
            animation={animation}
            zIndex={ctas.length - order + 3}
          />
        );
      })}
    </>
  );
};
