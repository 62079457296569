import { Button } from '@material-ui/core';
import React from 'react';

import { FormLabel, TextInput } from 'components/common';
import { Stack } from 'components/common/Wrapper/Layout';
import { useVideoPublishStatusQuery } from 'hooks/query/useVideoPublishStatusQuery';
import { usePolling } from 'hooks/usePolling';
import { formatDateTime, isFutureDate } from 'utils/date';

import { HandlePollResponse, PublishStatusWithMeta } from './PublishVideo.utils';

import * as Styled from './PublishVideo.styles';

interface VideoPublishedProps {
  videoId: string;
  publishStatuses: PublishStatusWithMeta[];
  onClose?: () => void;
  handlePollResponse: HandlePollResponse;
}

export function VideoPublished({
  videoId,
  publishStatuses,
  onClose,
  handlePollResponse,
}: VideoPublishedProps) {
  const { scheduledPublishDate } = publishStatuses[0];
  let isScheduledPublishDateFuture: boolean = false;
  let formattedPublishDate: string = '';
  if (typeof scheduledPublishDate === 'string') {
    isScheduledPublishDateFuture = isFutureDate(scheduledPublishDate);
    if (scheduledPublishDate !== '') {
      formattedPublishDate = formatDateTime(new Date(scheduledPublishDate));
    }
  }

  const pendingTargets = publishStatuses.filter((status) => {
    if (isScheduledPublishDateFuture) return false;
    return status.ok && status.isInProgress;
  });

  const { startPolling, stopPolling } = useVideoPublishStatusQuery({
    targets: pendingTargets,
    variables: { videoId },
    onCompleted: (data) => {
      handlePollResponse(data, pendingTargets);
    },
  });

  usePolling({
    isEnabled: pendingTargets.length > 0,
    startPolling,
    stopPolling,
    interval: 3000,
  });

  return (
    <>
      <Styled.Center>
        <Stack rowGap={30}>
          <Stack rowGap={20}>
            <Styled.Title>
              {isScheduledPublishDateFuture ? 'Video will be published!' : 'Video Published!'}
            </Styled.Title>
            {isScheduledPublishDateFuture && (
              <Styled.Subtitle>Scheduled date: {formattedPublishDate}</Styled.Subtitle>
            )}
            <Styled.Subtitle>
              {isScheduledPublishDateFuture ? 'Pages:' : 'Share links:'}
            </Styled.Subtitle>
          </Stack>
          <Stack rowGap={38} width="100%" textAlign="left" component={Styled.PlatformList}>
            {publishStatuses.map((status) => {
              return (
                <StatusHandler
                  status={status}
                  isScheduledPublishDateFuture={isScheduledPublishDateFuture}
                  key={`publish-status-${status.id}`}
                />
              );
            })}
          </Stack>
        </Stack>
      </Styled.Center>
      {onClose && (
        <Styled.ActionWrapper>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Close
          </Button>
        </Styled.ActionWrapper>
      )}
    </>
  );
}

const StatusHandler: React.FC<{
  status: PublishStatusWithMeta;
  isScheduledPublishDateFuture: boolean;
}> = ({ status, isScheduledPublishDateFuture }) => {
  if (status.ok) {
    if (status.isInProgress) {
      return (
        <StatusEntry label={status.label}>
          {isScheduledPublishDateFuture ? '' : 'Loading…'}
        </StatusEntry>
      );
    }

    if (status.shareLink) {
      return (
        <TextInput
          copyable
          value={status.shareLink}
          name={status.id}
          label={status.label}
          readOnly
        />
      );
    }

    return (
      <StatusEntry label={status.label}>
        Publishing succeeded, but the video link was not provided.
      </StatusEntry>
    );
  }

  return <StatusEntry label={`${status.label} - Failed`}>{status.errorMessage}</StatusEntry>;
};

const StatusEntry: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <li>
      <FormLabel as="p">{label}</FormLabel>
      <Styled.StatusText>{children}</Styled.StatusText>
    </li>
  );
};
