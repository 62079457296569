import React from 'react';
import { useParams } from 'react-router-dom';

import { SpinnerBox } from 'components/common/Spinner';
import MainLayout from 'components/layouts/MainLayout';
import { useVideoMomentsQuery, useVideoQuery } from 'hooks/query';
import { navigateTo404 } from 'utils/navigation';

import { MomentsEditorWrapper } from './context';
import MomentsPageView from './MomentsPageView';

interface RouteParams {
  videoId: string;
}

export const MomentsPage = () => {
  const { videoId } = useParams<RouteParams>();
  const { loading, data } = useVideoQuery({
    variables: { id: videoId },
    onCompleted: ({ videoById }) => {
      if (!videoById) {
        navigateTo404();
      }
    },
  });

  const { data: dataMoments } = useVideoMomentsQuery({
    variables: {
      videoId,
      pageSize: 999,
      orderBy: 'startTimestamp,endTimestamp',
    },
  });

  if (loading) {
    return (
      <MainLayout>
        <SpinnerBox />
      </MainLayout>
    );
  }

  if (data?.videoById) {
    const moments = dataMoments?.moments?.nodes || [];
    return (
      <MainLayout>
        <MomentsEditorWrapper allowEditing={true} videoId={videoId}>
          <MomentsPageView video={data.videoById} moments={moments} />
        </MomentsEditorWrapper>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div>ERROR</div>
    </MainLayout>
  );
};
