import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

interface LinkProps {
  disabled?: boolean;
}

export default styled(Link)<LinkProps>`
  text-decoration: none;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;
